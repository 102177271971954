<template>
	<div>
		<div v-if="flowNode && flowNode.nodeType==='subflow'" class="parent-bar">
			<!-- :{{flowNode.nodeRule.subFlow.flowName}} -->
			{{flowNode.name}} @ {{flowVersion.flowName}}[{{taskItem.flowInsId}}]
		</div>
		<container :model="pageModel" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />


	</div>
</template>

<script>
	import FlowUtil from '../../../../../plugins/flowUtil.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		props: {

			versionId: { //指定版本号
				default: 0
			},
			debug: {
				type: Boolean,
				default: false
			}
		},
		emits: ['cancel', 'success', 'error'],
		data() { //有任务号为审批，有流程实例号没任务号为查看，有流程类型号没有实例号与任务号为创建
			return {
				flowVersion: null,
				flowModel: null,
				flowNode: null,
				flowIns: null,
				taskItem: null,

				pageModel: null,

				startNode: null, //根据此节点获取主表信息

			}
		},

		methods: {
			dataLoadRecall(form, project, data) {
				let userId = this.flowNode.task.userId
				if (userId) {
					//	FlowUtil.accessFilter(this.flowNode.accessGroup, form, userId, project, data)
				}

			},
			toSubmitFlow() {

			},
			onTaskChange(user, taskItem) {
				this.$emit('taskchange', user, taskItem)
			},
			onNodeClose(node) {
				this.$emit('nodeclose', node)
			},
			onNodeOpen(node) {
				this.$emit('nodeopen', node)
			},
			onSuccess(result) {
				this.$emit('success', result)
			},
			onError(err) {
				this.$emit('error', err)
			},
			onCancel(flowInsId, flowNode) {
				this.$emit('cancel', flowInsId)
			},

			loadPage(dataId = null) {
				this.flowModel = null
				this.flowNode = null
				this.flowIns = null
				this.taskItem = null
				let formDataId = dataId || this.model.dataId
				let flowId = this.model.flowId || 0
				let insId = this.model.insId || 0
				let taskId = this.model.taskId || 0
				if (flowId == 0 && insId == 0 && taskId == 0) {
					if (!this.isedit) {
						this.$logic.tip.error('参数错误，流程类型ID、流程实例ID、流程任务ID需要至少指定一个')
					}

					return
				}

				this.$logic.http.get(true, '/api/flow/getflowins/', {
						flowClassId: flowId,
						versionId: this.versionId,
						flowInsId: insId, //'10376000000000055', //
						taskId: taskId,
						dbSourceId: this.project.dbSourceId,
					},
					res => {
						let flowIns = res.data.data
						this.flowIns = flowIns
						let flowDef = this.$logic.http.initFlowVersion(flowIns.versionItem, this.elementConfig, !this
							.isedit)
						this.flowModel = flowDef.flowModel
						this.flowVersion = flowDef.flowVersion
						let taskIns = flowIns.taskItem || {
							id: 0,
							nodeId: 0,
							flowInsId: insId
						}
						this.taskItem = taskIns


						this.startNode = flowDef.startNode
						//this.startNode.task = taskIns
						if (taskIns.id == 0) {
							this.flowNode = flowDef.startNode
						} else {
							let nodeKey = taskIns.nodeKey
							let node = this.$logic.arrayUtil.itemByKey(this.flowModel.items, 'id', nodeKey)
							if (!node) {
								this.$logic.tip.error('当前任务所在的流程节点已失效')
								this.onCancel(0)
								return
							}
							this.flowNode = node
						}
						this.flowNode.task = taskIns


						if (this.flowNode.nodeType === 'subflow') { //子流程节点加载子流程表单
							this.$logic.http.flowModelLoad(this.elementConfig, !this.isedit, this.project, this
								.flowNode
								.nodeRule
								.subFlow
								.flowId, 0,
								subRes => {
									//console.log(res.startNode)
									//子流程传递的参与就是表单内容和表单Id不一样，其它参数与其它节点一致
									//创建节点副本替换表单内容，以兼容flowSumit组件内部引用表单的需要
									/* let node = {
										...flowNode
									} */
									this.flowNode.nodeForm = subRes.startNode.nodeForm

									this.loadFormPage(this.flowModel, this.flowVersion, flowIns, this.flowNode,
										subRes
										.startNode
										.nodeForm, 0)
								})

						} else {
							let flowForm = flowDef.flowForms[this.flowNode.formId]
							this.flowNode.nodeForm = flowForm
							//let formId = dataId || taskIns.flowInsId || 0
							formDataId = formDataId || taskIns.flowInsId || 0
							this.loadFormPage(this.flowModel, this.flowVersion, this.flowIns, this.flowNode, this
								.flowNode.nodeForm, formDataId)

						}
					})


			},
			loadFormPage(flowModel, flowVersion, flowIns, node, nodeForm, formId) {
				let formParamName = 'formId'
				FlowUtil.accessFilter(this.flowNode.accessGroup, nodeForm, this.flowNode.task.userId, this.project, {})
				let dataForm = this.$logic.pageUtil.getElements(nodeForm.items, '.form', true)
				if (dataForm.length > 0) {
					let form = dataForm[0]
					formParamName = form.idParam
					form._dataLoadRecall = this.dataLoadRecall //回调函数在表单组件数据加载后执行
					form.accessGroups = nodeForm.accessGroups //权限设置传递给内部表单，在子页面容器上将失去与nodeForm的关系
					//制单人始终不可输入
					let users = this.$logic.pageUtil.getElements(form.items, '.userdialog', false)
					for (let obj of users) {
						if (obj.fieldId && obj.fieldId.fieldName && obj.fieldId.fieldName.toLowerCase() ==
							'edituserid') {
							obj.status = 'disabled'
							if (node && node.nodeType == 'start') { //提交的节点可写入，否则不可写
								obj.save = 'Y'
							} else {
								obj.save = 'N'
							}
						}
					}

					//申请人、申请部门只有在发起的节点才有效，否则禁用
					for (let obj of users) {
						if (obj.fieldId && obj.fieldId.fieldName && obj.fieldId.fieldName.toLowerCase() ==
							'applyuserid') {
							if (node && node.nodeType == 'start') {
								obj.save = 'Y'
								obj.status = ''
							} else {
								obj.save = 'N'
								obj.status = 'disabled'
							}

						}
					}
					let orgs = this.$logic.pageUtil.getElements(form.items, '.userorg', false)
					for (let obj of orgs) {
						if (obj.fieldId && obj.fieldId.fieldName && obj.fieldId.fieldName.toLowerCase() ==
							'applyorgid') {
							if (node && node.nodeType == 'start') {
								obj.save = 'Y'
								obj.status = ''
							} else {
								obj.save = 'N'
								obj.status = 'disabled'
							}

						}
					}

					//发起时间始终不允许写入

					let timeObjs = this.$logic.pageUtil.getElements(form.items, '.datetime', false)
					for (let obj of timeObjs) {
						if (obj.fieldName && obj.fieldName.fieldName && obj.fieldName.fieldName.toLowerCase() ==
							'flowapplytime') {
							obj.status = 'disabled'
							if (node && node.nodeType == 'start') {
								obj.save = 'Y'
							} else {
								obj.save = 'N'

							}

						}
					}


				} else {
					this.$logic.tip.error('流程页面中存在表单容器')
					return
				}
				//console.log(flowIns, node, nodeForm)
				let es = this.$logic.pageUtil.getElements(nodeForm.items, '.flowsubmit', true)
				if (es.length > 0) {
					let submitComp = es[0]
					submitComp._debugMode = this.debug
					submitComp._flowModel = flowModel
					submitComp._flowIns = flowIns
					submitComp._node = node
					submitComp._onSuccess = this.onSuccess
					submitComp._onError = this.onError
					submitComp._onCancel = this.onCancel
				}
				let forms = this.$logic.pageUtil.getElements(nodeForm.items, '.form', true)

				let flowInsId = flowIns.id
				if ((flowIns.id == 0) && formId > 0) { //由数据生成流程,没有流程实例的情况下将所有绑定表单字段的元素加标识，在表单容器数据加载的时候不写入数据
					let fields = 'applyuserid,applyusername,applyorgid,applyorgpath,edituserid,editusername,flowapplytime'
						.toLowerCase().split(',')
					let sk = [].concat(nodeForm.items)
					while (sk.length > 0) {
						let obj = sk.pop()
						if (obj.isInput) { //如果是表单输入元素，检查是否绑定了
							//console.log(obj)
							for (let key in obj) {
								let param = obj[key]
								if (param && (typeof param === 'object') && param.fieldName) {
									if (fields.indexOf(param.fieldName.toLowerCase()) > -1) {
										obj.$flowInit = true //标识，加载时不写入
										break
									}

								}
							}





						}
						if (obj.items) {
							for (let sub of obj.items) {
								sk.push(sub)
							}

						}
					}

				}

				let content = nodeForm.items[0] //编辑区容器
				let formParams = { //函数调用方式加载表单组件容器内容
					flowDefId: flowVersion.flowId,
					flowVersionId: flowVersion.versionId,
					flowVersionCode: flowVersion.versionCode,
					//$flowInsId: flowIns.id, //传递给表单容器流程实例ID，如果值为0，数据加载时不覆盖写入发起人，发起部门，制单人的默认值
					//flowId: formId, //表单上的ID参数
					//formId: formId,
				}
				formParams[formParamName] = formId
				this.pageModel._loadContent(content, formParams)


			}

		},
		watch: {
			'model.taskId'(newValue, oldValue) {
				this.loadPage()
			}
		},
		mounted() {
			this.loadPage()
		},
		created() {
			this.pageModel = this.elementConfig.create('subpage', this.model, this.page.formData)
			this.pageModel.name = '流程页面'
		}
	}
</script>

<style scoped>
	.submit-bar {
		display: flex;
		justify-content: center;
	}

	.parent-bar {
		border-left: 5px solid #409EFF;
		background-color: #f8f8f8;
		padding: 10px 20px;
	}
</style>