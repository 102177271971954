import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'

let cs=[
{key:'title',text:'表格行属性'},

{key:'trH',text:'行高',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'trBC',text:'背景颜色',type:'colorA',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
// {key:'trH',text:'斑马色',type:'text',init:'#F8F8F8',target:'',bursh:true,bind:true,tip:'',list: null},

]

let tr= {
	data:{
		type:'tr',width:'100%',height:''
	},
	delete:'alignV,minW,minH,overH,overV,paddingT,paddingB,paddingL,paddingR,',
	hide:'',
	base:cs ,
	special :[].concat(special),
	action:[]
}

export default tr