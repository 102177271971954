export default {
	emits: ['success'],
	props: {
		home: {
			required: true,
			type: String,
			default: 'img'
		},
		apiUrl: {
			type: String,
			default: null
		},
		dataItem: { //传入用户业务数据，用于上传后返回信息对应到是哪个数据
			type: Object,
			default: null
		},
		project: {
			type: Object,
			default: null
		},
		showSuccess: {
			type: String,
			default: '上传成功'
		}
	},
	data() {
		return {
			selectedFiles: [],
			uploading: false,
			fileList: [],
			sysTick: new Date().getTime(),
			sn: 0 //用于调用方更新图片缓存
		}
	},
	methods: {
		onSuccess(response, file, fileList) {
			this.sysTick = new Date().getTime()
			this.uploading = false
			this.selectedFiles = []
			let uploadObj = this.$refs.upload || this.$refs.uploadObj //兼容两种不同的名称
			uploadObj.clearFiles()
			if (response.code == 0) {
				this.$emit('success', this.dataItem, response, file, fileList)
				this.sn++
				if (this.showSuccess) {
					this.$logic.tip.success(this.showSuccess)
				}

				this.fileList = fileList //response.data
			} else {
				uploadObj.abort()
				this.$logic.tip.error('上传失败 ' + response.info || '')
			}

		},
		onError(err, file, fileList) {
			this.sysTick = new Date().getTime()
			this.uploading = false
			this.selectedFiles = []
			let uploadObj = this.$refs.upload || this.$refs.uploadObj
			uploadObj.clearFiles()
			let info = '' + err
			let x = info.indexOf('http')
			if (x > 0) {
				info = info.substring(0, x)
			}
			this.$logic.message.message('上传失败', '上传异常：' + info)

		},
		beforeUpload(file) {

			/* 			if (this.uploadData.folderPath && this.uploadData.folderPath.length > 1) {
							this.uploading = true
							return true
						} else {
							this.$logic.tip.error("未指定文件目录")
							return false
						}
			 */

			this.uploading = true
			return this.uploading
		},
		onExceed(files) {
			this.uploading = false
			this.$logic.message.message('上传失败', '文件超出上传限制')
		},
	},
	computed: {
		uploadUrl() {
			let url = this.$logic.http.baseUrl + (this.apiUrl ? this.apiUrl : '/api/editor/uploadfile/')

			return url
		},
		folderPath() { //调用方覆盖此方法
			return '/'
		},
		reList() { //上传完后是否返回当前文件夹下的文件列表,如需返回，调用方覆盖此方法
			return false
		},
		fileSaveName() { //如果需要指定上传到服务器的文件名，需覆盖此方法，否则服务器自动命名
			return ''
		},
		uploadData() {
			let user = this.$logic.getUser()

			let data = {
				appId: this.project ? this.project.id : 0,
				//appId: this.project.id,
				reList: this.reList,
				fileHome: this.home,
				saveName: this.fileSaveName,
				$$unitGroup: user.unitGroup,
				$$unitId: user.unitId,
				$$userId: user.id,
				$$tick: this.sysTick,
				folderPath: this.folderPath //保存到服务器的位置,调用方必须提供一个计算属性：folderPath
			}
			data.$$serial = this.$logic.http.getToken(data)
			//console.log(data)
			return data
		},
		urlBase() {
			return this.$logic.http.baseUrl
		}


	},
}