import {
	ElMessage
} from 'element-plus'


class Tip {
	#time = 0
	constructor() {
		this.time = 4500
	}
	success(message, time) {
		this.showMessage('success', message, time)
	}
	info(message, time) {
		this.showMessage('info', message, time)
	}
	warning(message, time) {
		this.showMessage('warning', message, time)
	}
	error(message, time) {
		this.showMessage('error', message, time)
		//ElMessage.error(message)
	}

	showMessage(type, message, time,title) {
		let tm = time || this.time 
		ElMessage({
			title:title || '',
			message: message,
			type: type,
			duration: tm
		})
	}
}
export default new Tip()