<template>

	<treelistdialog ref="dialog" :node-list="treeNodes" :query-result="queryData" :data-text="model.textPath"
		:text-field="model.textField" :reset="model.dialogReset=='Y' && !isedit" :size="model.size"
		:title="model.dialogTitle" :icon="dialogIcon" :disabled="model.status=='disabled'"
		:width="model.inputOption.tree.width" :height="model.inputOption.tree.height" :split-char="model.splitChar"
		:before-oK="beforeOk" @open="dialogOpen" @choose="itemChoose" @select="nodeSelect" @query="toQuery"
		@focus="onFocus" @blur="onBlur" />


</template>

<script>
	import {
		nextTick
	} from 'vue'
	import TreeListDialog from '../../../../comm/treedialog/TreeListDialog.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				treeNodes: [],
				dataResult: null,
				queryData: {}
			}
		},

		methods: {
			toQuery(param) {

				if (this.isedit && false) {

				} else {
					let sql = this.model.inputOption.treedata.sql
					if (sql && sql.length > 10) { //如果有效SQL
						sql = sql.replace(/\$id/g, param.id).replace(/\$keyword/g, param.keyWord)
						sql = this.$logic.db.getParamSql(sql, this.page.$params || {}, this.page)
						this.queryData.dataList = []
						let sourceId = this.model.inputOption.sourceId == 0 ? this.project.dataSource.id : this.model
							.inputOption.sourceId
						this.$logic.http.sqlQuery(sourceId, sql, [], {
							isJson: false,
							size: 1000, //应加个配置
						}).then(res => {
							this.queryData.dataList = res.data.data.dataList
							this.doEvent({
								eventName: 'queryafter',
								node: param,
								heads: this.model.inputOption.treedata.tableStyle.heads,
								dataList: this.queryData.dataList,
							}, '$comp_queryAfter')
						}).catch(err => {

							this.$logic.tip.error('数据请求异常：' + (err.info || err))
						})


					}
				}
			},
			initUser() {
				let model = this.model
				if (!this.isedit && model.type == 'userdialog') {
					if (this.model.initType == 'user') {
						let rs = {}
						let user = this.$logic.clone.deepClone(this.$logic.getUser())
						let orgs = this.$logic.arrayUtil.getTreeNodePath(this.project.orgTree, user.orgId)

						if (orgs.length > 0) {
							let org = orgs[orgs.length - 1]
							model.nodeId = org.id
							model.nodeText = org.label

							model.idPath = org.id
							model.itemId = user.id
							model.itemText = user.userName
							if (model.format === 'path') {
								let pathText = ''
								let pathId = ''
								for (let org of orgs) {
									if (pathText.length > 0) {
										pathText = pathText + model.splitChar
										pathId = pathId + model.splitChar
									}
									pathText = pathText + org.label
									pathId = pathId + org.id
								}
								model.textPath = pathText + model.splitChar + user.userName
								model.idPath = pathId + model.splitChar + user.id
							} else {
								model.textPath = user.userName
							}
							this.$nextTick(() => { //延迟加载，防止目标对象还没初始化
								this.doLinkUpdate()
							})
							/* setTimeout(() => {
								 
							}, 200)
 */
							this.doEvent({ //自动初始化的事件，不带参数值，区分选择的事件
								eventName: 'after',
								result: null,
							}, '$comp_after')
						} else {
							this.$logic.tip.error('无效的用户组织关系数据，用户ID：' + user.id + ',组织ID：' + user.orgId)
						}

					}
				}
			},
			doLinkUpdate() {
				this.linkUpdate('nodeId')
				this.linkUpdate('nodeText')
				this.linkUpdate('textPath')
				this.linkUpdate('idPath')
				this.linkUpdate('itemId')
				this.linkUpdate('itemText')
			},
			nodeSelect(result) {
				this.dataResult = result
				let model = this.model
				if (model.idField && model.textField) {

					model.nodeId = result.treeNode.id
					model.nodeText = result.treeNode.label
					model.textPath = model.format === 'path' ? result.treeNode.textPath + model.splitChar + result
						.listItem[model.textField] : result.listItem[model.textField]
					model.idPath = result.treeNode.idPath

					model.itemId = result.listItem[model.idField]
					model.itemText = result.listItem[model.textField]

					this.doLinkUpdate()

					//给页面组件传值
					for (let item of 'id,label,pid,textPath,idPath'.split(',')) {
						let objId = model.inputOption.tree[item]
						if (objId) {
							let target = this.page.$(objId)
							if (!target || target.isInput !== 'S') {
								continue
							}
							if (target.$value) {
								target.$value(this.dataResult.treeNode[item])
							}
						}
					}
					for (let item of model.inputOption.treedata.tableStyle.heads) {
						if (item.col) {
							let target = this.page.$(item.col)
							if (!target || target.isInput !== 'S') {
								continue
							}
							if (target.$value) {
								target.$value(this.dataResult.listItem[item.field])
							}
						}
					}

					this.onChange(result)
					this.doEvent({
						eventName: 'after',
						result: result
					}, '$comp_after')
				} else {
					this.$logic.tip.error('未设置返回查询结果的ID字段名称或文本字段名称')
				}

			},
			itemChoose(nodeObj) {

				if (!this.isedit) {
					this.doEvent({
						eventName: 'itemchoose',
						node: nodeObj
					}, '$comp_choose')
				}

			},
			beforeOk(result) {
				if (!this.isedit) {
					let ck = this.doEvent({
						eventName: 'before',
						result: result
					}, '$comp_before')
					return ck
				} else {
					return true
				}
			},
			dialogOpen() {
				if (!this.model.textField || this.model.textField.length < 1) {
					this.$logic.tip.error('未在属性中设置文本值字段名称')
					/* this.$nextTick(() => {
						this.$refs.dialog.hide()
					}) */
					return
				}
				if (this.isedit && false) {

				} else { //先加载树节点，
					let sql = this.model.inputOption.tree.sql
					//console.log(sql)
					if (sql && sql.length > 10) { //如果有效SQL
						this.treeNodes = []
						this.$refs.dialog.show()
						let sourceId = this.model.inputOption.sourceId == 0 ? this.project.dataSource.id : this.model
							.inputOption.sourceId
						sql = this.$logic.db.getParamSql(sql, this.page.$params || {}, this.page)
						this.$logic.http.sqlQuery(sourceId, sql, [], {
							isJson: true
						}).then(res => {
							this.treeNodes = res.data.data.dataList

							this.doEvent({
								eventName: 'dialogopen',
								nodes: this.treeNodes
							}, '$comp_open')
						}).catch(err => {
							this.$logic.tip.error('数据请求异常：' + (err.info || err))
						})
					} else {
						this.doEvent({
							eventName: 'dialogopen',
							dataResult: this.queryResult
						}, '$comp_open')
					}


				}
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return this.dataResult
					/* return {
						itemId: model.itemId,
						itemText: model.itemText
					} */

				} else { //支持完整对象赋值或仅数据对象赋值
					let dataObj = null
					if ('listItem' in value) {
						dataObj = value.listItem
						this.dataResult = value
					} else {
						dataObj = value
						this.dataResult = {
							treeNode: null,
							listItem: value
						}
					}
					if ('itemId' in dataObj) {
						model.itemId = dataObj.itemId
					} else if ('itemText' in dataObj) {
						model.itemText = dataObj.itemText
					}

				}
			}

		},
		computed: {
			dialogIcon() {
				let icon = this.model.dialogIcon
				if (this.model.type === 'userdialog') {
					if (this.model.status === 'disabled' && icon == 'far fa-user-check') {
						icon = 'far fa-user-lock'
					}
				}

				return icon
			}
			/* 			queryResult() {
							let model = this.model
							if (typeof(model.queryResult) == 'string') {
								try {
									return JSON.parse(model.queryResult)
								} catch (ex) {
									this.$logic.tip.error('查询列表数据转换异常')
									return {
										dataList: []
									}
								}

							} else {
								return model.queryResult
							}

						}, */
		},
		created() {
			this.treeNodes = this.getDataList(this.model.listData)
			this.queryData = this.model.inputOption.treedata.tableStyle //JSON.parse(this.model.queryResult)
			this.queryData.dataList = []
			if (!this.isedit) { //正式环境清除测试数据
				this.model.$value = this.$value
				this.model.$validate = this.validate
				//this.model.queryResult.dataList=[]
				//this.model.itemText=''

				if (this.model.type = 'userdialog') {
					this.model.$init = this.initUser
					if (this.model.initType == 'user') {
						this.initUser()
					}
				}


			}
		},
		components: {
			treelistdialog: TreeListDialog
		},
	}
</script>

<style scoped>

</style>