import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let fonts=[].concat(font)
let layouts=options.getConfigs(layout,'display,alignH,alignV,height,paddingL,paddingR,backColor,borderS,borderL,borderT,borderR,borderB,borderColor,borderRadius')
 
let compSet=[
	{key:'title',text:'组件区样式'},
   {key:'autoWidth',text:'响应式布局',type:'autowidth',init:{xs:"calc(100% - 150px)",sm:"calc(100% - 150px)",md:"calc(100% - 150px)",lg:"calc(100% - 150px)"},target:null,bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
 //  {key:'alignV',text:'垂直对齐',type:'select',init:'center',target:'alignItems',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:options.alignsV},
  
]

 
 
let formComp= {
	data:{
		type:'formcomp',alignV:'center'
	},
	delete:'height,',
	hide:'display,flexD,flexW,position',
	base:compSet.concat(layouts).concat(fonts),
	special :[],
	action:[]
}

export default formComp