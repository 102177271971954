let 	decideTypes= [

					{
						key: 'QS',
						title: '接收当前工作任务,只需要一个人处理的情况下,移除到达其他人的任务项',
						name: '签收',
						public:false
					},
					{
						key: 'ZJ',
						title: '当前工作任务转交给指定的一个用户,本人不再处理此项工作',
						name: '转交',
						public:false
					},
					/* 					{  //预留
											key: 'JP',
											title: '增加一个或多个用户参与处理,加签人员处理完成后由本人处理',
											name: '前置加签'
										}, */
					{
						key: 'JQ',
						title: '增加一个或多个用户参与处理,加签人员处理完成后由本人处理',
						name: '前置加签',
						public:false
					},
					{
						key: 'JH',
						title: '只可增加一个用户,在本人处理完成后由此用户处理,表决权交给后置加签人',
						name: '后置加签',
						public:false
					},

					{
						key: 'JY',
						title: '增加一个或多个用户参与查阅,用户可发表意见,不可进行业务操作不影响流程走向',
						name: '传阅',
						public:false
					},
					{
						key: 'TS',
						title: '退回上一步流程节点,并终止当前节点',
						name: '退回上一步'
					},
					{
						key: 'TF',
						title: '退回到流程发起位置,由申请人终止或修改后重新发起',
						name: '退回发起人'
					},
					{
						key: 'TL',
						title: '跳转历史节点,退回到流程之前审批过的节点',
						name: '流程回辙'
					},
					{
						key: 'TR',
						title: '跳转到流程中的任意节点位置',
						name: '流程转送'
					},
					{
						key: 'ZZ',
						title: '直接终止此流程,置为作废状态',
						name: '终止流程'
					},

				]
export default decideTypes