<template>
	<!-- <work-flow v-if="model.type=='flow'" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" /> -->

	<flow-designer v-if="model.type=='flow'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<flow-module v-else-if="model.type=='flowmodule'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<flow-submit v-else-if="model.type=='flowsubmit'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<flow-page v-else-if="model.type=='flowpage'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
</template>

<script>
	//import WorkFlow from './WorkFlow.vue'
	import Designer from './Designer.vue'
	import FlowModule from './FlowModule.vue'
	import FlowSubmit from './FlowSubmit.vue'
	import FlowPage from './FlowPageComp.vue'

	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				superComp: true
			}
		},

		methods: {

		},
		components: {
			//	workFlow: WorkFlow,
			flowDesigner: Designer,
			flowModule: FlowModule,
			flowSubmit: FlowSubmit,
			flowPage: FlowPage

		},
		created() {


		}
	}
</script>

<style scoped>

</style>