export default {

	getFormatText(typeGroup, dataType, format, value) {
		let cfg = format[typeGroup]
		if (cfg && value) {
			for (let act of cfg.action) {
				if (act === 'trim') {
					value = value.trim()
				} else if (act === 'low') {
					value = value.toLowerCase()
				} else if (act === 'up') {
					value = value.toUpperCase()
				}
			}
			if (format.before) {
				value = format.before + value
			}
			if (format.after) {
				value = value + format.after
			}
			return value
		} else {
			return value
		}
	},
	numFormat: new Intl.NumberFormat('en-US'),
	digitUppercase(n) {
		var fraction = ['角', '分'];
		var digit = [
			'零', '壹', '贰', '叁', '肆',
			'伍', '陆', '柒', '捌', '玖'
		];
		var unit = [
			['元', '万', '亿'],
			['', '拾', '佰', '仟']
		];
		var IsNum = Number(n);
		if (!isNaN(IsNum)) {
			var head = n < 0 ? '欠' : '';
			n = Math.abs(n);
			var s = '';
			for (var i = 0; i < fraction.length; i++) {
				s += (digit[Math.floor(n * 100 / 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
			}
			s = s || '整';
			n = Math.floor(n);
			for (var i = 0; i < unit[0].length && n > 0; i++) {
				var p = '';
				for (var j = 0; j < unit[1].length && n > 0; j++) {
					p = digit[n % 10] + unit[1][j] + p;
					n = Math.floor(n / 10);
				}
				s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
			}
			return head + s.replace(/(零.)*零元/, '元')
				.replace(/(零.)+/g, '零')
				.replace(/^整$/, '零元整');
		} else {
			return "";
		}

	},
	getFormatNum(typeGroup, dataType, format, value) {
		let vs = value
		let cfg = format[typeGroup]

		if (cfg && (value || value === 0 || value === '0')) {
			let decimal = cfg.decimal === null || cfg.decimal === '' ? -1 : cfg.decimal
			if (cfg.change === 'rmb') {
				decimal = 2
			}

			if (decimal > -1) {
				let p = 10 ** decimal				
				value = Math.trunc(value * p + 0.5)
				value = value / p

			}
			if (cfg.change === 'permil') {
				vs = this.numFormat.format(value)
			} else if (cfg.change === 'rmb') {
				vs = this.digitUppercase(value)
			} else if (decimal > -1) {
				vs = value.toFixed(cfg.decimal || 0)
			}
			if (format.before) {
				vs = format.before + vs
			}
			if (format.after) {
				vs = vs + format.after
			}
			return vs
		} else {
			return value
		}
	},
	getFormatTime(typeGroup, dataType, format, value) {
		let rs = value
		let cfg = format[typeGroup]
		//console.log(typeGroup, dataType, format, cfg, value, typeof(value))
		if (value) {
			let date = null

			if (dataType == 'datetime') {
				if (typeof(value) === 'number') {
					date = new Date(parseInt(value))
				} else if (typeof(value) === 'string' && value.indexOf(' ') < 1) { //文本型数值
					date = new Date(parseInt(value))
				} else {
					return rs
				}
				let year = date.getFullYear();
				let month = (1 + date.getMonth()).toString().padStart(2, '0');
				let day = date.getDate().toString().padStart(2, '0');
				let hours = date.getHours().toString().padStart(2, '0');
				let minutes = date.getMinutes().toString().padStart(2, '0');
				let seconds = date.getSeconds().toString().padStart(2, '0');
				rs = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			} else if (dataType == 'date') {

			} else if (dataType == 'time') {

			}

			return rs
		} else {
			return value
		}
	},
	getResult(code, message, data) {
		return {
			code: code,
			message: message,
			data: data
		}
	},
	depend(app) {
		this.app = app
		this.message.depend(app)
	},
	isArray(o) {

		return Object.prototype.toString.call(o) == '[object Array]';

	},
	message: {

		success(message) {
			this.app.$message({
				message: message,
				type: 'success',
				showClose: true,
				offset: '100'
			})
		},
		error(message) {
			this.app.$message({
				message: message,
				type: 'error',
				showClose: true,
				offset: '100'
			})
		},
		warning(message) {
			this.app.$message({
				message: message,
				type: 'warning',
				showClose: true,
				offset: '100'
			})
		},
		info(message) {
			this.app.$message({
				message: message,
				showClose: true,
				offset: '100'
			})
		},
		depend(app) {
			this.app = app
		}
	},
	chart: {
		getGradient(type, color, deep1, deep2) { //radial,linear
			if (typeof color != "string") {
				return color
			}
			let colors = this.getRgbas(color, deep1, deep2)
			switch (type) {
				case "linear":
					return {
						type: type,
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [{
								offset: 0,
								color: colors[0] // 0% 处的颜色
							}, {
								offset: 1,
								color: colors[1] // 100% 处的颜色
							}],
							global: false // 缺省为 false
					}
					break
				case "radial":
					return {
						type: 'radial',
							x: 0.5,
							y: 0.5,
							r: 0.5,
							colorStops: [{
								offset: 0,
								color: colors[0] // 0% 处的颜色
							}, {
								offset: 1,
								color: colors[1] // 100% 处的颜色
							}],
							global: false // 缺省为 false
					}
					break
			}

		},
		getRgbas(color, deep1, deep2) { //#AABBCC
			let rgbColor = '' + parseInt(color.substr(1, 2), 16) + "," + parseInt(color.substr(3,
				2), 16) + "," + parseInt(color.substr(5, 2), 16)
			let colors = ['rgba(' + rgbColor + ',' + deep1 + ')', 'rgba(' + rgbColor + ',' + deep2 + ')']
			return colors
		},
		fillSet(option, configs, item, group) {
			for (let config of configs) {
				if (!config.target) { //没有指定option对象属性的不是此类设置项跳过
					continue
				}
				if (group && config.group != group) { //如指定了组，过滤掉非此组的数据
					continue
				}
				let value = item[config.key]
				if (value === "true") {
					value = true
				} else if (value === "false") {
					value = false
				} else if ((value + "").indexOf('[') > -1) { //如果值是数组字符串，转换为数组
					value = JSON.parse(value)
				}

				let props = config.target.split('.') //为每一层属性注入值
				let obj = option
				let length = props.length - 1
				for (let i = 0; i < length; i++) { //只截止倒数第二属性创建对象，最后一个是属性
					let prop = props[i]
					if (!obj[prop]) { //如果没有此属性，增加属性
						obj[prop] = {}
					}
					obj = obj[prop]
				}
				let pk = props[length]
				if (("" == value || value == null) && (
						config.tag == 'color' || config.tag == "number")) { //如果颜色类型值为空清除颜色
					delete obj[pk]
				} else {
					if (config.tag == 'number') {
						value = Number(value)
					}
					obj[pk] = value
				}

			}
		}
	},
	rgb16ToHsl(colore) { //#ffaa00
		let x = colore.indexOf("#")
		if (x > -1) {
			colore = colore.substring(x + 1)
		}
		let r = parseInt(colore.substring(0, 2), 16)
		let g = parseInt(colore.substring(2, 4), 16)
		let b = parseInt(colore.substring(5), 16)
		return this.rgbToHsl(r, g, b)
	},
	rgbToHsl(r, g, b) {
		r = r / 255;
		g = g / 255;
		b = b / 255;

		var min = Math.min(r, g, b);
		var max = Math.max(r, g, b);
		var l = (min + max) / 2;
		var difference = max - min;
		var h, s, l;
		if (max == min) {
			h = 0;
			s = 0;
		} else {
			s = l > 0.5 ? difference / (2.0 - max - min) : difference / (max + min);
			switch (max) {
				case r:
					h = (g - b) / difference + (g < b ? 6 : 0);
					break;
				case g:
					h = 2.0 + (b - r) / difference;
					break;
				case b:
					h = 4.0 + (r - g) / difference;
					break;
			}
			h = Math.round(h * 60);
		}
		s = Math.round(s * 100); //转换成百分比的形式
		l = Math.round(l * 100);
		return [h, s, l];
	},
	getDateTime(times) {
		let parts = this.getTimeParts(times)
		return parts[0] + " " + parts[2]
	},
	getDate(times) {
		let parts = this.getTimeParts(times)
		return parts[0]
	},
	getTime(times) {
		let parts = this.getTimeParts(times)
		return parts[2]
	},
	getDateWeekTime(times) {
		let parts = this.getTimeParts(times)
		return parts[0] + " 星期" + parts[1] + " " + parts[2]
	},

	getTimeParts(times) {
		//定义一个日期对象;
		var dateTime = new Date(times);
		//获得系统年份;
		var year = dateTime.getFullYear();
		//获得系统月份;
		var month = dateTime.getMonth() + 1;
		//获得系统当月分天数;
		var day = dateTime.getDate();
		//获得系统小时;
		var hours = dateTime.getHours();
		//获得系统分钟;
		var minutes = dateTime.getMinutes();
		//获得系统秒数;
		var second = dateTime.getSeconds();
		//获得系统星期几;
		var dayCycle = dateTime.getDay();
		//使用数组更改日期样式;
		var dayCycleArray = ["日", "一", "二", "三", "四", "五", "六"];
		for (var i = 0; i < 7; i++) {
			if (dayCycle == i) {
				//将dayCycleArray的数赋值到系统星期几里面中去;
				dayCycle = dayCycleArray[i];
			}
		}
		month < 10 ? month = '0' + month : month;
		hours < 10 ? hours = '0' + hours : hours;
		minutes < 10 ? minutes = '0' + minutes : minutes;
		second < 10 ? second = '0' + second : second;
		//打印完整的系统日期;
		let parts = []
		let dateStr = year + '-' + month + '-' + day
		let weekStr = dayCycle
		let timeStr = hours + ':' + minutes + ':' + second
		parts.push(dateStr, weekStr, timeStr)
		return parts;
	},
	confirm(message, recall, cancel) {
		this.app.$confirm(message, '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning',
			closeOnClickModal: false
		}).then(() => {
			recall()
		}).catch(() => {
			if (cancel) {
				this.message.warning(cancel)
			}
		});
	},
	prompt(message, recall, cancel) {
		this.app.$prompt(message, '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			closeOnClickModal: false,
		}).then(({
			value
		}) => {
			recall(value)
		}).catch(() => {
			if (cancel) {
				this.message.warning(cancel)
			}
		})



	}


}