import options from '../../../../../public/options.js'
import mapConfig from '../mapConfig.js'
 let initData=JSON.stringify( [
 	['名称', '类型', '颜色'],
 	['北京', 'area', '#ff0000'],
 	['江苏', 'area','#ffff00'],
 	['青海', 'area', '#00ff00'],
 	['新疆', 'area', '#0000ff'],
 	['台湾', 'area', '#ff00ff']
 ]).replaceAll('],','],\r\n')
let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'

let base = mapConfig.base
let special = [


].concat(mapConfig.special).concat([
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 
])
let action = mapConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_mapArea',
		chartClan: 'map',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'width,height,seriesType,manualSeries,dataOption',
	base:[].concat(base) ,
	special: special,
	action: mapConfig.action.timer.concat(mapConfig.action.comm)
}
export default cfg
