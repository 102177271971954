import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 

let base=[
	{key:'title',text:'文档生成器'}, 
	{key:'editorMode',text:'编辑器模式',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'编辑与内容呈现模式状态切换',list:options.YON},
	{key:'leftWidth',text:'目录宽度',type:'text',init:'300px',target:'--doc-left-width',bursh:true,bind:true,tip:'',list:null},	 
    {key:'title',text:'文档默认边距'}, 
	{key:'paddingL',text:'文档左边距',type:'text',init:'5px',target:null,bursh:true,bind:true,tip:'',list:null},	 
	{key:'paddingR',text:'文档右边距',type:'text',init:'5px',target:null,bursh:true,bind:true,tip:'',list:null},	 
	{key:'paddingT',text:'文档上边距',type:'text',init:'5px',target:null,bursh:true,bind:true,tip:'',list:null},	 
	{key:'paddingB',text:'文档下边距',type:'text',init:'5px',target:null,bursh:true,bind:true,tip:'',list:null},	 

	{key:'modelTree',text:'文档目录',type:'json',init:[{id:0,label:'文档目录',children:[],model:null}],target:null,bursh:true,bind:true,tip:'',list:null},	 
//modelTree 为所有层次型子容器的公共名称，用于统一数据加载时初始化处理
]

let book= {
	data:{
		type:'book',width:'100%',height:'100%',
	},
	delete:'',
	hide:'modelTree,',
	base: base.concat(font),
	special: [],
	action:[]
}

export default book