import util from '../util.js'
import options from '../../../../public/options.js'

let list=[
	{key:'title',text:'提示框属性' },
	{key:'tooltipShow',text:'显示提示框',type:'switch',init:'Y',path:'tooltip.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'tooltipBackgroundColor',text:'背景颜色',type:'colorA',init:null,path:'tooltip.backgroundColor',bursh:true,bind:true,tip:'',list:null},
	{key:'tooltipBorderColor',text:'边框颜色',type:'color',init:null,path:'tooltip.borderColor',bursh:true,bind:true,tip:'',list:null},
//	{key:'tooltipTextFormat',text:'内容模板',type:'area',init:'{a}\\\n{b}:{c}\\\n{d}',path:'tooltip.formatter',bursh:true,bind:true,tip:'可组合使用此变量：{a}系列名{b}数据名{c}数据值{d}百分比',list:null},
//	{key:'tooltipTriger',text:'内容模板',type:'text',init:'item',path:'tooltip.trigger',bursh:true,bind:true,tip:null,list:null},


 
]//.concat([])

export default {list}
