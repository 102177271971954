<template>
	<div v-show="isedit">
		<!-- <el-input v-model="codeValue" @click="qywxScan" type="text"></el-input> -->
		<i style="font-size:20px;" class="far fa-comments"></i>
	</div>

</template>

<script>
	import * as ww from '@wecom/jssdk'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				registed: false,


				qywxConfig: {}

			}
		},
		mounted() {

		},
		methods: {

			checkRegist() {
				if (!this.registed) {
					this.$logic.tip.error('企业微信未完成注册，不能调用此功能')
					return false
				}
				return true
			},
			qywxScan(sendTo) {
				if (!this.checkRegist()) {
					return
				}
				let rs = {
					code: 1,
					info: '',
					data: ''
				}
				ww.scanQRCode({
					needResult: true,
					scanType: [this.model.scanType],
					success:(res)=> {
						//alert(JSON.stringify(res))
						rs.code = res.errCode
						rs.info = res.errMsg
						rs.data = res.resultStr
						let valueTo = sendTo || this.model.scanTo
						if (valueTo) { //设置了回填
							let obj = this.$logic.pageUtil.getElementById(this.page.formData, valueTo)
							if (obj) {
								if (obj.$value) {
									obj.$value(rs.data)									
								} else {
									this.$logic.tip.error('扫码结果回填的组件必须是表单输入型组件')
								}
							} else {
								this.$logic.tip.error('扫码结果回填的组件不存在')
							}

						}

					},
					fail:(result)=> {
						rs.code = res.errCode
						rs.info = res.errMsg

					},
					complete:(result)=> {
						this.doEvent({
							eventName: 'scanSuccess',
							result: rs
						}, '$comp_scanFinish')

					}
				})




			},

			wwRegist() {
				let url = window.location.href
				this.$logic.http.get(true, '/api/client/qywx/getjssignature/', {
					url: url,
					apiKey: this.model.apiKey
				}, res => {
					this.qywxConfig = res.data.data
					this.qywxConfig.appId = parseInt(this.qywxConfig.appId)
					this.qywxConfig.timestamp = parseInt(this.qywxConfig.timestamp)

					ww.register({
						corpId: this.qywxConfig.corpId,
						agentId: this.qywxConfig.appId,
						jsApiList: this.qywxConfig.jsApiList,
						getConfigSignature: this.getConfigSignature,
						getAgentConfigSignature: this.getAgentConfigSignature
					})
					this.registed = true
				}, err => {
					this.$logic.tip.error('初始化微信终端失败，' + err.info)

				})



			},
			async getConfigSignature(url) { //url是当前地址栏的地址

				// 根据 url 生成企业签名
				// 生成方法参考 https://developer.work.weixin.qq.com/document/14924

				return {
					timestamp: this.qywxConfig.timestamp, //是否数值型 ，需确认
					nonceStr: this.qywxConfig.nonceStr,
					signature: this.qywxConfig.signatureCorp
				}
			},

			async getAgentConfigSignature(url) {

				// 根据 url 生成应用签名，生成方法同上，但需要使用应用的 jsapi_ticket
				return {
					timestamp: this.qywxConfig.timestamp, //是否数值型 ，需确认
					nonceStr: this.qywxConfig.nonceStr,
					signature: this.qywxConfig.signatureAgent
				}
			},
			getQyWx() {
				return ww
			},

		},
		created() {
			if (!this.isedit) {
				//this.model.qiYeWeiXin = ww
				this.model.$getQyWx = this.getQyWx
				this.model.$scan = this.qywxScan
				this.wwRegist()

			}


		}
	}
</script>

<style scoped>

</style>