import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let types=options.toOptions('无=none,求和=sum,平均=avg,最大值=max,最小值=min,行数=rows')
let cs=[
{key:'title',text:'表尾单元格属性'},
{key:'totalType',text:'汇总类型',type:'select',init:'none',target:'',bursh:true,bind:true,tip:'',list: types},
//{key:'totalCol',text:'汇总列名',type:'text',init:'',target:'',bursh:true,bind:true,tip:'默认为同列',list: null},
{key:'decimal',text:'小数位数',type:'number',init:'2',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'setTo',text:'联动赋值',type:'text',init:'',target:'',bursh:false,bind:false,tip:'页面目标组件的ID值,如#8,将汇总值设置到表单元素,动态联动变化',list: null},

{key:'beforeText',text:'前置文本',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'total',text:'汇总值',type:'num',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'afterText',text:'后置文本',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'alignH',text:'横向对齐',type:'select',init:'center',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
//{key:'tdV',text:'纵向对齐',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
{key:'totalColor',text:'汇总值颜色',type:'color',init:'',target:'--total-font-color',bursh:true,bind:true,tip:'',list: null},
{key:'totalSize',text:'汇总值字号',type:'text',init:'',target:'--total-font-size',bursh:true,bind:true,tip:'',list: null},

{key:'cellBC',text:'背景颜色',type:'colorA',init:'',target:'backgroundColor',bursh:true,bind:true,tip:'',list: null},

]

let td= {
	data:{
		type:'listfoot'
	},
	delete:'',
	hide:'total',
	base:cs.concat(font) ,
	special :[],
	action:[]
}

export default td