import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 

let base=[
	{key:'title',text:'文本'},
	compBase.mode,
	{key:'value',text:'内容',type:'area',init:'文本',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
    {key: 'autoCapital',text: '金额转大写',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'srcNum',text: '金额数值',type: 'num',init: null,	target: '',	bursh: true,bind: true,tip: '',list: null},
//    {key: 'unit',text: '金额单位',type: '元',init: null,	target: '',	bursh: true,bind: true,tip: '',list: null},

]

let label= {
	data:{
		type:'label',
	},
	delete:'',
	hide:'mode',
	base: base.concat(font).concat(compBase.base),
	special: extend.badge.concat(extend.menu).concat(extend.tip),
	action:options.getCommonEvents().concat([compBase.menuClick]).concat([
		//options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default label