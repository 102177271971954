import util from '../util.js'
import options from '../../../../public/options.js'

let list=[
	{key:'title',text:'工具栏属性' },
	{key:'toolboxShow',text:'显示工具栏',type:'switch',init:'Y',path:'toolbox.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'toolOrient',text:'排列方向',type:'select',init:'horizontal',path:'toolbox.orient',bursh:true,bind:true,tip:'',list:options.toOptions('水平方向=horizontal,垂直方向=vertical')},
	{key:'toolboxBorderColor',text:'边框颜色',type:'color',init:options.initColor.text.dark,path:'toolbox.borderColor',bursh:true,bind:true,tip:'',list:null},
	{key:'toolboxAlign',text:'水平对齐',type:'select',init:'right',path:'toolbox.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	{key:'toolboxVerticalAlign',text:'垂直对齐',type:'select',init:'top',path:'toolbox.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
/* 	{key:'toolboxLeft',text:'左侧偏移',type:'number',init:null,path:'toolbox.left',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:null},
	{key:'toolboxRight',text:'右侧偏移',type:'number',init:null,path:'toolbox.right',bursh:true,bind:true,tip:'绝对定位方式右侧定位',list:null},
	{key:'toolboxTop',text:'顶部偏移',type:'number',init:null,path:'toolbox.top',bursh:true,bind:true,tip:'绝对定位方式顶定位',list:null},
	{key:'toolboxBottom',text:'底部偏移',type:'number',init:null,path:'toolbox.bottom',bursh:true,bind:true,tip:'绝对定位方式底部定位',list:null},
 */
 	{key:'toolboxFeatureZoomShow',text:'缩放按钮',type:'switch',init:'N',path:'toolbox.feature.dataZoom.show',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:options.YON},
 	{key:'toolboxFeatureRestoreShow',text:'还原按钮',type:'switch',init:'N',path:'toolbox.feature.restore.show',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:options.YON},
	{key:'toolboxFeatureDataViewShow',text:'预览按钮',type:'switch',init:'N',path:'toolbox.feature.dataView.show',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:options.YON},
	{key:'toolboxFeatureSaveAsImageShow',text:'下载按钮',type:'switch',init:'N',path:'toolbox.feature.saveAsImage.show',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:options.YON},
//	{key:'toolboxFeatureMagicTypeShow',text:'切换按钮',type:'switch',init:'Y',path:'toolbox.feature.magicType.show',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:options.YON},
	{key:'toolboxIconColor',text:'按钮颜色',type:'color',init:options.initColor.text.dark,path:'toolbox.iconStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
				
]//.concat([])

export default {list}
