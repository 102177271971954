<template>

	<!-- 圆型四角 -->


	<!-- 	<svg class="svg-container">

		<path fill="transparent" stroke-width="2" stroke="#2e6099" :d="boxPath"></path>

	</svg> -->
	<div class="svg-container" style="padding: 4px 0px 5px 4px;">
		<div class="outbox" :style="boxStyle">

		</div>
	</div>

	<div class="svg-container">
		<svg v-for="block in ['datav_left-top','datav_left-bottom','datav_right-top','datav_right-bottom']"
			:class="block+' datav_block'" :style="hornStyle">
			<defs>
				<filter id="borderr-box-12-filterId-d282aa69e2114a8ca435f4021b6b121a" height="150%" width="150%"
					x="-25%" y="-25%">
					<feMorphology operator="dilate" radius="1" in="SourceAlpha" result="thicken"></feMorphology>
					<feGaussianBlur in="thicken" stdDeviation="2" result="blurred"></feGaussianBlur>
					<feFlood flood-color="rgba(124,231,253,0.7)" result="glowColor">
						<animate attributeName="flood-color" :values="boxPointColor" dur="3s" begin="0s"
							repeatCount="indefinite"></animate>
					</feFlood>
					<feComposite in="glowColor" in2="blurred" operator="in" result="softGlowColored"></feComposite>
					<feMerge>
						<feMergeNode in="softGlowColored"></feMergeNode>
						<feMergeNode in="SourceGraphic"></feMergeNode>
					</feMerge>
				</filter>
			</defs>


			<path stroke-width="2" fill="transparent" stroke-linecap="round"
				filter="url(#borderr-box-12-filterId-d282aa69e2114a8ca435f4021b6b121a)"
				d="M 20 5 L 15 5 Q 5 5 5 15 L 5 20" :stroke="color2" data-v-2ea67b54=""></path>
		</svg>
	</div>



</template>

<script>
	export default {
		props: {
			color1: {
				type: String,
				default: '#4fd2dd'
			},
			color2: {
				type: String,
				default: '#235fa7'
			},
			panelWidth: {
				type: Number,
				default: 200
			},
			panelHeight: {
				type: Number,
				default: 100
			},
			maxHornWidth: {
				type: Number,
				default: 150
			},
			maxHornHeight: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {

			}
		},

		methods: {

		},
		computed: {
			/* boxPath() {
				let o = this.panelWidth || 50
				let r = this.panelHeight || 50
				let d = `M15 5 L ${o - 55} 5 Q ${o - 5} 5, ${o - 5} 15
				        L ${o - 5} ${r - 55} Q ${o - 5} ${r - 5}, ${o - 55} ${r - 5}
				        L 15, ${r - 5} Q 5 ${r - 5} 5 ${r - 55} L 5 15
				        Q 5 5 15 5
				      `
				console.log(d)
				return d
			}, */


			boxPointColor() {
				let color = this.$logic.color.hexToRgbArray(this.color2)
				color = `rgba(${color},0.7);rgba(${color},0.3);rgba(${color},0.7);`

				return
			},
			boxStyle() {
				return {
					borderColor: this.color1
				}
			},
			hornStyle() {
				/* 	let minSize = this.panelWidth > this.panelHeight ? this.panelHeight : this.panelWidth
					let hornSize=minSize/2 */
				let hornHeight = this.panelHeight / 2 - 10 //留点空隙
				let hornWidth = this.panelWidth / 2
				/* if (hornHeight > this.maxHornHeight) {
					hornHeight = this.maxHornHeight
				} */
				if (hornWidth > this.maxHornWidth) {
					hornWidth = this.maxHornWidth
				}
				let css = {
					'--horn-width': hornWidth + 'px',
					'--horn-height': hornHeight + 'px'
				}
				return css

			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.svg-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		pointer-events: none;

		/* border:solid 1px #ffffff; */
	}

	.outbox {
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		border-radius: 10px;
		border: solid 2px #ffffff;
		pointer-events: none;
	}

	.stroke-width2 {
		stroke-width: 2px;
		fill: none;
	}

	.stroke-width5 {
		stroke-width: 5px;
		fill: none;
	}

	.datav_block {
		width: var(--horn-width);
		height: var(--horn-height);
		position: absolute;
	}


	.datav_right-top {
		right: 0px;
		transform: rotateY(180deg);
	}

	.datav_left-bottom {
		bottom: 0px;
		transform: rotateX(180deg);
	}

	.datav_right-bottom {
		right: 0px;
		bottom: 0px;
		transform: rotateX(180deg) rotateY(180deg);
	}
</style>