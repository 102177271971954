 
import options from  '../../../../public/options.js'
import echartConfig from '../echartConfig.js'
let base=[].concat( echartConfig.base)
 
let polar=[
/* 	{key:'title',text:'圆型坐标系'},
	 {key:'polarLeft',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'polarLeft',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'polarRight',text:'半径',type:'text',init:'5',path:'polar.radius',bursh:true,bind:true,tip:'数值绝对长度或百分比相对长度以水平垂直方向中最小值为基准',list:null},
 */
//	{key: 'polarRadius',text: '半径大小',type: 'text',init: ['20%','75%'],path: 'polar.radius',bursh: true,bind: true,tip: '百分比,相对长度以水平垂直方向中最小值为基准',list: null},
//	{key: 'coordinateSystem',text: '坐标类型',type: 'text',init: 'polar',path: '_series.coordinateSystem',bursh: true,bind: true,tip: '',list: null},
//	{key: 'polarStartAngle',text: '起始角',type: 'number',init: 90,path: 'angleAxis.startAngle',bursh: true,bind: true,tip: '',list: null},
//	{key: 'polarMax',text: '最大值',type: 'number',init: 200,path: 'angleAxis.max',bursh: true,bind: true,tip: '',list: null},

//	{key: 'polarRadiusAxis',text: '径向轴类型',type: 'text',init: 'category',path: 'radiusAxis.type',bursh: true,bind: true,tip: '',list: null},

//加避免无数据加载时不能执行到optionFilter方法，默认初始化一个初始值
//   {key: 'polarCenterTemp',text: '中心X,Y',type: 'text',init: ['50%','50%'],path: 'polar.center',bursh: true,bind: true,tip: '直接输入数值绝对定位或百分比相对定位',list: null},
	 {key:'seriesPieColorBy',text:'取色方式',type:'select',init:'data',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},

	 {key:'polarCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'polarCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'polarRadiusI',text:'空心圆半径',type:'text',init:'20%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	 {key:'polarRadiusO',text:'外圆半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},


]
/* for(let i=0;i<polar.length;i++){ //坐标系插入基本属性中
	base.splice(i+4,0,polar[i])
} */
options.insertBaseConfig(base,polar)
let action={timer: echartConfig.timerEvents,comm:echartConfig.commEvents}
 let initData=JSON.stringify( [
 	['日期', 'A类', 'B类', 'C类'],
 	['周一', 50, 150, 70],
 	['周二', 150, 80, 130],
 	['周三', 90, 130, 150],
 	['周四', 110, 100, 60],
 	['周五', 70, 140, 120]
 ]).replaceAll('],','],\r\n')
let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
 let special=[
	 
	{key: 'polarStartAngle',text: '起始角',type: 'number',init: 90,path: 'angleAxis.startAngle',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarAxisLineShow',text: '显示圆周线',type: 'switch',init: 'Y',path: 'angleAxis.axisLine.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'polarAxisLineWidth',text: '圆周线粗细',type: 'number',init: 1,path: 'angleAxis.axisLine.lineStyle.width',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarAxisLineColor',text: '圆周线颜色',type: 'color',init: options.initColor.text.dark,path: 'angleAxis.axisLine.lineStyle.color',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarAxisMax',text: '最大值',type: 'number',init: null,path: 'angleAxis.max',bursh: true,bind: true,tip: '',list: null},

	{key: 'polarAxisLabelShow',text: '显示标签',type: 'switch',init: 'Y',path: 'angleAxis.axisLabel.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key:'polarAxisLabelFontSize',text:'标签字号',type:'number',init:'12',path:'angleAxis.axisLabel.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'polarAxisLabelFontFamily',text:'标签字体',type:'select',init:'',path:'angleAxis.axisLabel.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'polarAxisLabelFontColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'angleAxis.axisLabel.color',bursh:true,bind:true,tip:'',list:null},

	{key: 'polarAxisTickShow',text: '显示刻度线',type: 'switch',init: 'Y',path: 'angleAxis.axisTick.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'polarAxisTickLength',text: '刻度线长度',type: 'number',init: 5,path: 'angleAxis.axisTick.length',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarAxisTickColor',text: '刻度线颜色',type: 'color',init: options.initColor.line.dark,path: 'angleAxis.axisTick.lineStyle.color',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarAxisTickWidth',text: '刻度线粗细',type: 'number',init: 1,path: 'angleAxis.axisTick.lineStyle.width',bursh: true,bind: true,tip: '',list: null},

	{key: 'polarSplitLineShow',text: '显示分隔线',type: 'switch',init: 'Y',path: 'angleAxis.splitLine.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'polarSplitLineColor',text: '分隔线颜色',type: 'color',init: options.initColor.line.dark,path: 'angleAxis.splitLine.lineStyle.color',bursh: true,bind: true,tip: '',list: null},
	{key: 'polarSplitLineWidth',text: '分隔线粗细',type: 'number',init: 1,path: 'angleAxis.splitLine.lineStyle.width',bursh: true,bind: true,tip: '',list: null},

	{key: 'polarSplitAreaShow',text: '显示分隔区',type: 'switch',init: 'N',path: 'angleAxis.splitArea.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'polarSplitAreaColor',text: '分隔区颜色',type: 'colorB',init: [],path: 'angleAxis.splitArea.areaStyle.color',bursh: true,bind: true,tip: '',list: null},


	{key: 'polarRadiusAxis',text: '径向轴类型',type: 'select',init: 'category',path: 'radiusAxis.type',bursh: true,bind: true,tip: '',list: options.toOptions('数值型=value,类目型=category,时间型=time')},

	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 
	 
	 
 ]
 
export default {base,special,action}



 