<template>
	<div v-show="layerShow" class="layer">
		<div>
			<div><i class="fad fa-spinner fa-pulse" style="font-size: 30px;margin-bottom: 10px;"></i></div>
			<div>{{info}}</div>
		
		</div>
		<div class="closebotton">
			<i class="fas fa-times"  @click="layerClose"></i>
		</div>

	</div>
</template>

<script>
	export default {//放在全局页面，通过$logic.cover.show控制
		props: {
			info: {
				type: String,
				default: '数据请求中...'
			},
			clickClose: {
				type: Boolean,
				default: false
			},
			show: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {

			}
		},

		methods: {
			layerClose(){
				this.$logic.showLayer(false)
				//this.$store.commit('setCoverLayerShow', false)
			}

		},
		computed:{
			layerShow(){
				return this.$store.state.coverLayer.show
			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.layer {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left:0px;
		top:0px;
		width: 100%;
		height: 100%;
		font-size: 20px;
		text-align: center;
		color: #ffffff;
		background-color: #000000;
		opacity: 0.5;
		z-index: 16777271;
	}
	.closebotton{
		position: absolute;
		right: 20px;
		top: 10px;
		font-size: 18px;
		color: #efefef;
		cursor: pointer;
	}
	.closebotton:hover{
		font-size: 20px;
		color: #ff0000;
	}
</style>
