import axios from "axios";
class MyUploadAdapter {
	constructor(loader, logic, appId) {
		this.loader = loader;
		this.$logic = logic
		this.appId = appId
	}
	/* setLogicUtil(logic){
	 
  } */

	async upload() {
		let rs = {
			default: null
		}
		const data = new FormData();
		data.append("file", await this.loader.file);
		await this.$logic.http.post(true, '/api/editor/uploadfile/', {
			appId: this.appId,
			fileHome: 'editfile',
			folderPath: '/a/',
			editorFile: true
		}, data).then(res => {
			//如果是开发模式地址带服务器头，生产系统只需相对地址
			let baseUrl = this.$logic.http.devServer || ''
			for (let item of res.data.data) {
				rs.default = baseUrl + item.url
			}
			this.$logic.tip.success('已成功上传服务器')
		}).catch(err => {
			console.log(err)
			this.$logic.tip.error('文件上传失败')
		})

		return rs

		/* 	return {
				default: 'https://upload.jianshu.io/users/upload_avatars/3840312/4e01f465-b498-41fb-bf00-b8a6277a73d1.jpg',
			}; */
	}
}

export default MyUploadAdapter;