<template>
	<div class="top">
		<div class="notextoverflow" style="padding-left: 10px;">
			<i v-if="icon !=null" :class="'titleicon '+(icon.indexOf('fa')==0 || icon.indexOf('iconfont')==0 ?icon:'fal fa-'+icon)"></i>
			<span >
				{{title}}
			</span>
			
		</div>
		<div class="rightbody"  >
			<slot></slot>
		</div>
	
	</div>
</template>

<script>

	export default {
		props:["title","icon"],
		data() {
			return {

			}
		},

		methods: {

		},
		created() {


		}
	}
</script>

<style scoped>
.top{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 36px;
	width:calc(100% - 2px) ;
	padding-left: 0px;
	font-weight: 200;
	color: #75777c;
	font-size: 14px;
	background-color: #f4f4f5;
	border: #dcdcdc solid 1px;
	border-left: #FFFFFF solid 1px ;
	line-height: 32px;
}
.titleicon{
	font-size: 18px;
	margin-right: 5px;
}
.rightbody{
	padding-right: 10px;
	display: flex;
	align-items: center;
}
:deep(.el-button--small){/* 最小号按钮调高一点 */
	padding: 12px 10px;
}
</style>
