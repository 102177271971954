let getCloneConfig = (keyPre, pathPre, configs) => {
	let rs = []
	for (let item of configs) {
		let newItem = {
			...item,
			key: keyPre + item.key
		}
		if ('path' in item) {
			newItem.path = pathPre + item.path
		}
		rs.push(newItem)
	}
	return rs
}

export default {
	getCloneConfig
}
