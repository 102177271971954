<template>
	<pageview :model="pageModel.formData" :project="project" :page="pageModel" :host="null"
		:isedit="false" :datas="formData" />
</template>

<script>
	import config from './config/config.js'
	//	import Block from './comp/form/layout/Block.vue'
	import Container from './comp/form/layout/Container.vue'
	//import Page from './comp/form/layout/sub/Page.vue' //一定PAGE
	export default {
		//props: ['pageModel','project'],
		props: {
			pageModel: {
				type: Object,
				default: null
			},
			project: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				formData: {},

			


			}
		},

		methods: {
			

		},
		/* computed: {
			pageObj() {
				return this.pageItem || this.pageModel
			}
		}, */
		components: {
			//block: Block,
			pageview: Container
			//pageview: Page
		},
		created() {

		}
	}
</script>

<style src="../../css/page.css" scoped>

</style>