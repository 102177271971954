<template>
	<template v-if="model.type=='label'">
		<span @click="mouseClick" :style="compStyle">{{model.value}}</span>
	</template>
	<template v-else-if="model.type=='link'">
		<el-link @click="mouseClick" :type="model.color" :target="!isedit && model.target =='_blank'?'_blank':''"
			:href="isedit ?'javascript:void(0);': model.url" :disabled="model.enabled=='N'"
			:underline="model.under=='Y'" :style="compStyle">
			<i v-if="model.iconBefore" :class="model.iconBefore"
				:style="{color:model.iconColor,marginRight:model.iconGap,fontSize:model.iconSize}"></i>
			{{model.value}}
			<i v-if="model.iconAfter" :class="model.iconAfter"
				:style="{color:model.iconColor,marginLeft:model.iconGap,fontSize:model.iconSize}"></i>
		</el-link>
	</template>
	<template v-else-if="model.type=='icon'">
		<span @click="mouseClick" class="labelrow" :style="compStyle">
			<i v-if="model.value" :class="model.value +' '+ model.rotate"></i>
			{{model.text}}
		</span>
	</template>
	<template v-else-if="model.type=='image'">
		<div :class="imgClass" :style="imgStyle">
			<template v-if="imgUrl && imgUrl.length>0">
				<template v-for="item in imgUrl">
					<el-image @click="mouseClick" class="labelrow" :style="compStyle" :src="item.url" :fit="model.fit"
						:preview-src-list="!isedit && model.view=='Y'?vieImgs:[]">
						<template #error>
							<div class="image-slot">
								<i class="fad fa-image"></i>
								<!-- <div>无效图片</div> -->
							</div>
							<!-- <div v-else>
						
					</div> -->
						</template>
					</el-image>
				</template>

			</template>
		</div>

	</template>
	<template v-else-if="model.type=='button' || model.type=='submit' || model.type=='dayin'">

		<el-button v-if="model.mode=='S'" @click="mouseClick($event,null)" :type="model.color" :plain="model.plain=='Y'"
			:round="model.round=='Y'" :circle="model.circle=='Y'" :loading="model.loading=='Y'" :size="model.size"
			:closable="model.closable=='Y'" :link="model.link=='Y'" :disabled="model.enabled=='N'"  
			:style="compStyle">
			<i v-if="model.loading=='N' && model.ico" :class="model.ico"></i>

			{{model.value}}
		</el-button>
		<el-button-group v-else-if="model.mode=='M'" :size="model.size" :style="compStyle">
			<template v-for="(item,index) in dataList">
				<el-button @click="mouseClick($event,item)" :type="item.type" :plain="model.plain=='Y'"
					:round="model.round=='Y'" :circle="model.circle=='Y'" :loading="item.loading=='Y'"
					:link="model.link=='Y'" :disabled="item.enabled=='N'">
					<i v-if="item.loading=='N' && item.icon" :class="item.icon"></i>
					{{item.text}}
				</el-button>
			</template>
		</el-button-group>


	</template>
	<template v-else-if="model.type=='tag' && model.mode=='S'">
		<el-tag @click="mouseClick($event,null)" @close="tagClose(null)" :type="model.color" :size="model.size"
			:effect="model.effect" :style="compStyle">
			<i v-if="model.ico" :class="model.ico"></i>
			{{model.value}}
		</el-tag>
	</template>
	<span v-else-if="model.type=='tag' && model.mode=='M'">
		<template v-for="(data,index) in dataList">
			<el-tag @click="mouseClick($event,data)" @close="tagClose($event,data)" :type="data.type" :size="model.size"
				:closable="model.closeAble=='Y'" :effect="model.effect" :style="compStyle">
				<i v-if="data.icon" :class="data.icon"></i> {{data.text}}
			</el-tag>
		</template>
		<template v-if="model.addAble=='Y'">
			<el-tag @click="addNewTag" :type="model.color" :size="model.size" :effect="model.effect"
				style="cursor: pointer;">
				<i class="far fa-plus"></i>
			</el-tag>
		</template>

	</span>



	<el-divider v-else-if="model.type=='divider'" :direction="model.direction" :border-style="model.border"
		:content-position="model.position" :style="{'--divider-bgcolor':model.bgColor,'border-color': model.lineColor}">
		<div class="dividercontent" :style="compStyle">

			<i v-if="model.leftIcon" :class="model.leftIcon"></i>
			<span style="margin-left: 5px;margin-right: 5px;">{{model.text}}</span>
			<i v-if="model.rightIcon" :class="model.rightIcon"></i>

		</div>
	</el-divider>




</template>

<script>
	import formBase from '../../../formbase.js'
	import CommEvent from '../../../commevent.js'
	import logicUtil from '../../../../../plugins/logicutil.js'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {
				baseUrl: null,


			}
		},

		methods: { // ,  
			addNewTag() {
				if (this.isedit) {
					return
				}
				let newItem = this.doEvent({
					eventName: 'addbefore',
				}, '$comp_addBefore')
				if (newItem && typeof(newItem) == 'object') { //返回有效元素后加入列表
					this.model.listData.push(newItem)
					this.doEvent({
						eventName: 'addafter',
					}, '$comp_addAfter', {
						listItem: newItem
					})
				} else {
					//this.$logic.tip.error('新项创建前置函数中未返回新元素对象，创建失败')
				}

			},
			mouseClick(e, el) {

				if (!this.isedit) {
					let model = this.model
					if ('toggle' in this.model) { //状态轮换值
						if (this.model.$set) { //原因要查
							this.model.$set('toggle', this.model.toggle === 'Y' ? 'N' : 'Y')
						} else {
							console.log(this.model)
							this.setData('toggle', this.model.toggle === 'Y' ? 'N' : 'Y')
						}

					}
					this.openDialog() //弹出对话框,在commevent.js中定义
					if (model.type === 'submit') {
						let formId = model.formId
						if (formId == null || formId.length < 1) {
							this.$logic.tip.error('未指定表单ID')
							return
						}
						//let form = this.page.$(formId)
						let form = this.$logic.pageUtil.getElementById(this.page.formData, formId)
						if (!form) {
							this.$logic.tip.error('不存在的表单容器，请确认提交按钮中设置的表单ID：' + formId)
							return
						}

						let param = {
							formId: formId,
							//_this: this,
							recall: null
							/* () => {
								if (model.tell === 'Y') {
									this.$logic.tip.success(model.tip)
								}
								if (model.menuTo && model.menuTo.length > 0) {
									let pPage = this.host && this.host.parentPage
									if (!pPage) {
										this.$logic.tip.error('非子页面的表单，不支持自动菜单页面转向')
										return
									}

									pPage.$menuTo(model.menuTo, true, null, null)
								} else if (model.goto && model.goto.length > 1) {
									this.page.$to(model.goto)
									//alert('转向到：'+model.goto)
								}
							} */
						}
						/* let check = this.page.$formValidate(formId)
						if (!check.success) {
							this.$logic.tip.error('表单数据验证未通过，请核验填写的数据')
							return
						}
						if (model.ask === 'Y') {
							this.$logic.message.confirm('确认', model.message, () => { 
								form._doFormSubmit(param)
							})
						} else {
							form._doFormSubmit(param)
						} */
						form.$submit()

					} else if (model.type === 'dayin') {
						let printId = (model.printId || '').trim()
						if (printId.length < 2) {
							this.$logic.tip.error('没有设置打印容器ID，请以此格式：#123')
							return
						}
						if (printId.indexOf('#') !== 0) {
							printId = '#' + printId
						}
						let obj = this.page.$(model.printId)
						if (!obj) {
							this.$logic.tip.error('不存在的打印容器ID，请确认并以此格式：#123')
							return
						}
						if (model.ask === 'Y') {
							this.$logic.message.confirm('确认', model.message, () => {
								obj.$print()
							})
						} else {
							obj.print()
						}
					} else {
						let ex = el ? {
							listItem: el
						} : null
						this.doEvent(e, '$event_click', ex)
						//如果是关联了流程操作的组件点击,处理流程操作逻辑:

						if (model.flowPage) {

							let pageObj = this.$logic.pageUtil.getElementById(this.page.formData, model.flowPage)

							if (!pageObj) {
								this.$logic.tip.error('链接中指定了流程页面对象，但ID无效')
							} else {
								let flowId = this.datas && model.flowIdParam ? this.datas[model.flowIdParam] : null
								//console.log(flowId,model.flowIdParam,this.datas[model.flowIdParam])
								//console.log(model.flowOpt,flowId)
								if (model.flowOpt == 'view') {
									if (flowId) {
										if (pageObj.$toView) {
											pageObj.$toView(flowId)
										}
									} else {
										this.$logic.tip.error('链接中自动执行流程查看，但获取绑定的流程实例ID数据无效')
									}
								} else if (model.flowOpt == 'task') {
									//console.log(flowId,pageObj.$toTask)
									if (flowId) {
										if (pageObj.$toTask) {
											pageObj.$toTask(flowId)
										}
									} else {
										this.$logic.tip.error('链接中自动打开流程任务，但获取绑定的任务实例ID数据无效')
									}
								} else if (model.flowOpt == 'start') {
									if (flowId) {
										if (pageObj.$toStart) { //从业务数据创建流程
											pageObj.$toStart(flowId)
										}
									} else {
										if (pageObj.$toCreate) {
											pageObj.$toCreate()
										}
									}
								} else if (model.flowOpt == 'cancel') {
									if (flowId) {
										if (pageObj.$cancelFlow) {
											pageObj.$cancelFlow(flowId, true)
										}
									} else {
										this.$logic.tip.error('链接中自动终止流程，但获取绑定的流程实例ID数据无效')
									}
								}
							}

						}
						if (model.qwObj) {
							let qwObj = this.$logic.pageUtil.getElementById(this.page.formData, model.qwObj)
							if (qwObj) {
								let qwFunc = qwObj['$' + model.qwOpt]
								if (qwFunc) {
									qwFunc()
								} else {
									this.$logic.tip.error('设置了触发企微功能，但是功能不存在')
								}

							} else {
								this.$logic.tip.error('设置了触发企微功能，但是企微组件不存在')
							}

						}
						//如果是链接被点击,处理链接逻辑: 
						this.toLink()
					}
				}

			},

			tagClose(e, el) {
				if (!this.isedit) {
					let check = this.doEvent(e, '$comp_closebefore', {
						listItem: el
					})
					if (check === false) {
						return
					} else {
						for (let i = 0; i < this.model.listData.length; i++) {
							let item = this.model.listData[i]
							if (item === el) {
								this.model.listData.splice(i, 1)
							}
						}
						this.doEvent(e, '$comp_closeafter', {
							listItem: el
						})
					}

				}
			},
			toCapital() {
				let model = this.model
				if (this.isedit || model.autoCapital != 'Y') {
					return
				}
				let value = model.srcNum
				if (value === null || value === '') {
					this.setData('value', '')
				} else {
					//	model.value = logicUtil.digitUppercase(value)
					this.setData('value', logicUtil.digitUppercase(value))
				}


			}

		},
		watch: {
			'model.srcNum'(newValue, oldValue) {
				this.toCapital()
			}
		},
		computed: {
			/* buttonStyle() {
				let css = {
					...this.compStyle
				}
				if ('--custom-button-width' in css) {
					css.width = css['--custom-button-width']
				}
				if ('--custom-button-height' in css) {
					css.height = css['--custom-button-height']
				}
				console.log(css)
				return css

			}, */
			imgClass() {
				let css = ['img-box']
				if (this.model.rotate) {
					css.push(this.model.rotate)
				}
				if (this.model.value) {

				} else { //没有图加空框
					//css.push('img-box-empty')
				}

				return css
			},


			imgStyle() {
				let css = {}
				css['--rotate-time'] = (this.model.seconds || 4) + 's'
				return css
			},
			vieImgs() {
				let list = []
				for (let item of this.imgUrl) {
					list.push(item.url)
				}
				return list
			},
			imgUrl() {
				let model = this.model
				let list = []
				if (!model.value) {
					return list
				}
				if (Array.isArray(model.value)) { //文件上传组件的值

					for (let item of model.value) {
						list.push({
							url: this.baseUrl + item.url
						})
					}

				} else {
					if (model.value.indexOf('[') > -1) { //数组
						list = JSON.parse(model.value)
					} else {
						list.push({
							url: model.value
						})
					}
				}
				for (let item of list) {
					if (item.url.indexOf('/img/') == 0) {

					} else if (item.url.indexOf('data:') == 0) { //图片数据

					} else {
						if (item.url.indexOf(':')) { //完整网址忽略

						} else {
							item.url = this.baseUrl + item.url
						}


					}
				}
				return list

				/* 	if (model.value) {
						if (model.value.indexOf('/img/') == 0) {
							return model.value
						} else if (model.value.indexOf('data:') == 0) { //图片数据
							return model.value
						} else {
							return this.baseUrl + model.value
						}
						//return model.value.indexOf('/img/') == 0 ? model.value : this.baseUrl + model.value
					} else {
						return null
					} */

			}


		},


		created() {
			if (!this.isedit) {
				if (this.model.listData && typeof(this.model.listData) == 'string') {
					this.model.listData = JSON.parse(this.model.listData)
				}
				if (this.model.type === 'label') {
					this.toCapital()
				}

			}
			this.baseUrl = this.$logic.http.baseUrl


		},

	}
</script>

<style scoped>
	.labelrow {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.image-slot {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #efefef;
		font-size: 20px;
	}

	.dividercontent {
		display: flex;
		align-items: center;

	}

	:deep(.el-divider__text) {
		background-color: var(--divider-bgcolor);
	}

	:deep(.el-button.is-link) {
		margin-left: 5px;
		margin-right: 5px;
		border-left: 0px;
		border-right: 0px;
	}


	/* 	@keyframes rotate {
	    100% {
	        transform: rotate(360deg);
	    }
	} */

	.img-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.img-box-empty {
		border: solid 1px #cccccc;
		border-radius: 5;
	}


	:deep(.el-button) {
		width: var(--custom-button-width);
		height: var(--custom-button-height);
	}
</style>