<template>
	<div v-if="isedit" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave" @mouseup="mouseUp"
		:style="layoutstyle  " :class="cloneCss">
		<template v-for="item in model.items">
			<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="bindData" />
			<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />
		</template>
	</div>
	<template v-if="isedit ">
		<div v-for="(data,idx) in dataList" :style="layoutstyle  " :class="isedit?'cloneitem':''">
			<template v-for="item in model.items">
				<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
					:isedit="isedit" :datas="data" />
				<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="data" />
			</template>
		</div>
	</template>
	<template v-else>
		<div v-for="(cloneItem,idx) in cloneItems" @click="mouseClick" :style="layoutstyle  "
			:class="isedit?'cloneitem':''">
			<template v-for="item in cloneItem.model.items">
				<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
					:isedit="isedit" :datas="cloneItem.data" />
				<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="cloneItem.data" />

			</template>
		</div>

	</template>


</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [ layoutBase],//containerBase,
		data() {
			return {
				bindData: null,
				cloneItems: [],

			}
		},

		methods: {
			setCloneItems() {
				let items = []
				//向上查找父容器，如果最近的一个父容器中有列表数据，使用父容器中的数据，没有就使用自身的
				let list = this.dataList
				let id = new Date().getTime()
				for (let data of list) {
					let obj = this.$logic.clone.deepClone(this.model, 'parent')
					//设置对象的parent关系
					this.$logic.clone.setParent(obj)
					items.push({
						model: obj,
						data: data
					})
				}
				this.cloneItems = items

			},
			$setList(data) {//接受来自列表类组件的传值
				this.model.dataList = data
			}
		},
		computed: {
			/* 			cloneItems() {
							let items = []
							let list = this.dataList
							let id = new Date().getTime()
							for (let data of list) {
								let obj = this.$logic.clone.deepClone(this.model, 'parent')
								//设置对象的parent关系
								if (obj.items) {
									let sk = [obj]
									while (sk.length > 0) {
										let node = sk.pop()
										node.id = id++
										if (node.items) {
											for (let sb of node.items) {
												sb.parent = node
												sk.push(sb)
											}
										}
									}
								}
								items.push({
									model: obj,
									data: data
								})
							}
							return items
						}, */
			dataList() {
				let rs = []
				let ds = this.model.dataList
				if (typeof(ds) === 'string') {
					try {
						rs = JSON.parse(ds)

					} catch (ex) {
						this.$logic.tip.error('错误的JSON格式数据')

					}
				} else {
					rs = ds
				}
				return rs
			},
			cloneCss() {
				let css = this.layoutcss
				if (this.isedit && this.model != this.currentElement) {
					css.push('clonetemp')
				}
				return css
			},
		},
		watch: {
			'model.dataList': function(newV, oldV) {
				this.setCloneItems()
			},
		},
		created() {
			this.createInit()
			if (!this.isedit) {
				this.setCloneItems()
				this.model.$setList = this.$setList
			}


		}
	}
</script>

<style scoped>
	.clonetemp {
		border: dashed 1px #ff557f !important;
	}

	.cloneitem {
		border: dashed 1px #ff55ff !important;

	}
</style>