import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'

 let dataConfig=compBase.getDataConfig()
 let validate=compBase.getValidate() 
 validate.validateSet.exclude='input'
 
 let base = [
	{key: 'title',text: '图标输入框'	},
	{key: 'value',text: '图标类型',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},

compBase.status,   

	{key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 

]

let iconinput = {
	data: {
		type: 'iconinput',isInput:'S'//,marginT:'0px',marginB:'0px'
	},
	delete: '',
	hide: 'width',
	base: base.concat(compBase.base),
	special: extend.tip,
	action:  options.getEvents(null,'$event','$event_mousemove,$event_mouseover,$event_scroll').concat(options.getEvents(null,'$hook'))//.concat([compBase.menuClick])
}

export default iconinput
