import font from '../../public/font.js'
import layout from '../../public/layout.js'
let cs=[
{key:'title',text:'子页面属性'},
{key:'url',text:'链接页面',type:'page',init:'',target:'',bursh:true,bind:true,tip:'页面Key值为系统内页面后可跟?a=1&b=2这种网址参数',list: null},
{key:'_content',text:'页面内容',type:'text',init:null,target:'',bursh:true,bind:true,tip:'优先级高于url',list: null},

//{key:'pageContent',text:'页面内容',type:'text',init:'Y',target:'',bursh:true,bind:true,tip:'如果此属性有值，忽略url，直接呈现页面内容,主要用于装配工作流页面',list: null},
// {key:'loading',text:'加载状态',type:'text',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
]

let events=[
	 {key: '$comp_loadAfter',text: '页面加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '页面内容加载后触发',list: null	},
  ]
  
let subpage= {
	data:{
		type:'subpage',position:'relative',width:'100%',height:'100%',alignV:'flex-start',minH:'100px',minW:'100px',overH:'auto',overV:'auto',marginT:'0px',marginB:'0px',marginL:'0px',marginR:'0px',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'',
	hide:'display,flexD,flexW,position',
	base:cs.concat(layout).concat(font) ,
	special: [],
	action:events
}

export default subpage