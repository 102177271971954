import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
let datas =JSON.stringify( [ 
	/* {id:1,title:'数据1',color:'#ff0000',icon:'far fa-user',iconcolor:'#00ff00',timestart:'2024-05-10 10:09:20',timeend:'2024-05-22 12:09:20'},
	{id:2,title:'数据2',color:'#ffff00',icon:'far fa-user',iconcolor:'#f00f00',timestart:'2024-05-11 10:09:20',timeend:'2024-05-11 12:09:20'},
	{id:3,title:'数据3',color:'#0000ff',icon:'far fa-user',iconcolor:'#ff00ff',timestart:'2024-05-10 10:09:20',timeend:'2024-05-20 10:09:20'}
	 */
	 {"id":1,"title":"数据1","color":"#ff0000","icon":"far fa-alarm-clock","iconcolor":"#00ff00","timestart":"2024-05-10 10:09:20","timeend":"2024-05-22 12:09:20"},{"id":2,"title":"数据2","color":"#1E90FF","icon":"far fa-baseball","iconcolor":"#f00f00","timestart":"2024-05-02 10:09:20","timeend":"2024-05-02 12:09:20"},{"id":3,"title":"数据3","color":"#0000ff","icon":"far fa-bath","iconcolor":"#ff00ff","timestart":"2024-05-15 10:09:20","timeend":"2024-05-17 10:09:20"}
])
let base = [
	{key: 'title',text: '日历'	},
 
/* {key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
 */
 
{key: 'todayTime',text: '当前时间',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'monthStart',text: '月开始时间',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'monthEnd',text: '月结束时间',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'calendarStart',text: '日历开始',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'calendarEnd',text: '日历结束',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	

{key: 'titleShow',text: '显示标题栏',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'titleHeight',text: '标题栏高度',type: 'text',init: '40px',	target: '--calendar-title-height',bursh:true,bind:true,tip: '',list: null},	
{key: 'titleBgColor',text: '标题栏背景色',type: 'colorA',init: '',	target: '--calendar-title-bgcolor',bursh:true,bind:true,tip: '',list: null},	
{key: 'titleColor',text: '标题栏字色',type: 'color',init: '#303133',	target: '--calendar-title-color',bursh:true,bind:true,tip: '',list: null},	
{key: 'titleSize',text: '标题栏字号',type: 'text',init: '18px',	target: '--calendar-title-size',bursh:true,bind:true,tip: '',list: null},	

{key: 'calendarText',text: '日历文本',type: 'text',init: '日历文本',	target: '',bursh:true,bind:true,tip: '',list: null},	


{key: 'buttonShow',text: '显示按钮',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: null},	
//{key: 'buttonFold',text: '按钮折叠',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'buttonMode',text: '按钮模式',type: 'select',init: 'button',	target: '',bursh:true,bind:true,tip: '',list: options.toOptions('按钮排列=button,按钮叠=fold,下拉选择=down')},	
{key: 'buttonType',text: '按钮风格',type: 'select',init: 'default',	target: '',bursh:true,bind:true,tip: '',list: compBase.colorTypes},	

{key: 'onlyMonth',text: '只看月内',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: null},	



{key: 'borderColor',text: '边框线颜色',type: 'colorA',init: '#f8f8f8',	target: '--calendar-border-color',bursh:true,bind:true,tip: '',list: null},	


{key: 'weekBgColor',text: '星期背景色',type: 'colorA',init: '#f8f8f8',	target: '--week-bg-color',bursh:true,bind:true,tip: '',list: null},	
{key: 'weekColor',text: '星期字颜色',type: 'color',init: '#303133',	target: '--week-color',bursh:true,bind:true,tip: '',list: null},	
{key: 'weekSize',text: '日期字号',type: 'text',init: '18px',	target: '--week-font-size',bursh:true,bind:true,tip: '',list: null},	


{key: 'rowHeight',text: '网格行高度',type: 'text',init: '85px',	target: '--row-height',bursh:true,bind:true,tip: '',list: null},	
{key: 'title',text: '日期项样式' },
{key: 'showItems',text: '装载数据项',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '如果不启用，日期垂直方向居中',list: null},	
{key: 'dataMax',text: '数据项上限',type: 'number',init: 0,	target: '',bursh:true,bind:true,tip: '日期格内最大的数据项行数，超过上限将不可见一般用于固定行高，0表示不限制，行高随着数据自动增长',list: null},	

{key: 'dateH',text: '水平位置',type: 'select',init: 'center',	target: '--date-align',bursh:true,bind:true,tip: '',list: options.toOptions('左侧=left,中间=center,右侧=right')},	
//{key: 'dateV',text: '垂直位置',type: 'text',init: 'center',	target: '',bursh:true,bind:true,tip: '',list: null},	
//{key: 'badgeMode',text: '徽标模式',type: 'select',init: '',	target: '',bursh:true,bind:true,tip: '当日期项数据属性badge有值时显示徽标',list: options.toOptions('不启用=,数字=num,文本=text,圆点=dot')},	

{key: 'dateSize',text: '日期字号',type: 'text',init: '18px',	target: '--date-font-size',bursh:true,bind:true,tip: '',list: null},	
{key: 'currentColor',text: '当前格背景',type: 'colorA',init: '#ecf5ff',	target: '--calendar-current-color',bursh:true,bind:true,tip: '',list: null},	

{key: 'inColor',text: '月内字颜色',type: 'color',init: '#303133',	target: '--date-color-in',bursh:true,bind:true,tip: '',list: null},	
{key: 'inBgColor',text: '月内背景色',type: 'colorA',init: '#FFFFFF',	target: '--date-bgcolor-in',bursh:true,bind:true,tip: '',list: null},	

{key: 'outColor',text: '月外字颜色',type: 'color',init: '#909399',	target: '--date-color-out',bursh:true,bind:true,tip: '',list: null},	
{key: 'outBgColor',text: '月外背景色',type: 'colorA',init: 'rgba(252, 252, 252, 1)',	target: '--date-bgcolor-out',bursh:true,bind:true,tip: '',list: null},	


{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 

/* 
{key: 'showIcon',text: '显示图标',type: 'select',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'active',text: '默认选项',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'collapse',text: '是否折叠',type: 'select',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'backColor',text: '背景颜色',type: 'colorA',init: '#0b0927',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'textColor',text: '文本颜色',type: 'color',init: '#f4f4f5',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'activeColor',text: '选中文本色',type: 'color',init: '#ff9538',	target: '',bursh:true,bind:true,tip: '',list: null},	
 *///{key: 'borderColor',text: '侧边框颜色',type: 'color',init: '#e6e6e6',	target: '',bursh:true,bind:true,tip: '',list: null},	


]

let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'id',label:'数据ID',type:'text',default:'',list:[],field:'',width:'8%',unique:false},
	{key:'title',label:'数据项标题',type:'text',default:'',list:[],field:'',width:'14%',unique:true},
	{key:'color',label:'数据项颜色',type:'color',default:'',list:[],field:'',width:'14%',unique:false},
	{key:'icon',label:'图标',type:'icon',default:'',list:[],field:'',width:'14%',unique:false},
	{key:'iconcolor',label:'图标颜色',type:'color',default:'',list:[],field:'',width:'14%',unique:false},
	{key:'timestart',label:'开始时间',type:'datetime',default:'',list:[],field:'',width:'14%',unique:false},
	{key:'timeend',label:'结束时间',type:'datetime',default:'',list:[],field:'',width:'',unique:false},
	
 
]
let special=[
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	
	{key: 'listData',text: '标记组数据',type: 'jsonArray',init: datas,	target: '',	bursh:true,bind:true,tip: '',attribute:'dataOption'},//treeMap:{id:'key',label:'text',icon:''},dataMap: {key:'唯一标识',text:'按钮文本',type:'外观样式'}
	
]
 
 let events=[
	 {key: '$comp_dateclick',text: '日期点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '日期单元格被点击时触发,如果启用不看本月内',list: null	},
	 {key: '$comp_dataclick',text: '数据项点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '用户数据项被点击时触发',list: null	},
	 {key: '$comp_rangechanged',text: '期间切换后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '年、月改变后触发',list: null	},
	 {key: '$comp_dataloaded',text: '数据加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '请求的数据加载完成后触发',list: null	},

	// {key: '$comp_expand',text: '菜单展开',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单展开时触发',list: null	},
	// {key: '$comp_collapse',text: '菜单折叠',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单折叠时触发',list: null	},
 ]
  
let calendar = {
	data: {
		type: 'calendar',
	},
	delete: '',
	hide: 'width,height,dataOption',
	base: base,//.concat(compBase.base),
	//属性名以is开头的，以switch组件呈现，以color为属性名的，以颜色选择框方式呈现
	special: special,
	action: events
}

export default calendar
