<template>
	<!-- <div :style="compStyle" style="background-color: red;">
signpad
	</div> -->
	<sign-pad ref="signPad" @click="model.tipShow='N'" @sign="sign" :option="padOption" :allow-edit="model.allowEdit==='Y'"
		:padWidth="model.padWidth" :padHeight="model.padHeight" :designMode="isedit"></sign-pad>
</template>

<script>
	import SignPad from '../../../../comm/input/SignPad.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				signData: null
			}
		},

		methods: {
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值					 
					return this.signData
				} else {
					this.signData = value
					if (this.$refs.signPad) {
						this.$refs.signPad.setData(value)
					} else { //没有渲染完成时延时处理
					/* 	setTimeout(() => {
							console.log('YYYYYYYYYYYYYYY')
							this.$refs.signPad.setData(value)
						}, 50000)
 */
					}

				}
			},
			sign(data) {
				if (this.isedit) {
					return
				}
				this.signData = data
				this.doEvent({
					eventName: 'sign',
					signData: data
				}, '$comp_sign')
			},
			updateUserSign() {
				this.$logic.http.post(true, '/api/unit/manage/usersign/', {
					opration: 'set'
				}, {

					signData: this.signData
				}, res => {
					this.$refs.signPad.setData(res.data.data)
					this.signData = res.data.data
					this.$logic.tip.success('个人电子签名设置成功')

				})
			},
			loadUserSignData() {
				this.$logic.http.post(true, '/api/unit/manage/usersign/', {
					opration: 'get'
				}, {

					signData: null
				}, res => {
					this.$refs.signPad.setData(res.data.data)
					this.signData = res.data.data
				})
			}

		},
		computed: {
			padOption() {
				let model = this.model
				let option = {
					dotSize: model.maxWidth, // 3, //点的大小（在屏幕上点击留下的点的大小，单位为像素）。
					minWidth: model.minWidth, //  0.5, //线的最小宽度（单位为像素，默认值为0.5）。
					maxWidth: model.maxWidth, //  2.5, //线的最大宽度（单位为像素，默认值为2.5）。
					throttle: model.throttle, //  16, //节流（每次绘制两个点之间的时间，单位为毫秒，默认值为16，注意设置过大会导致绘制卡顿）。
					minDistance: model.minDistance, //  5, //最小距离（每次绘制两个点之间的最小距离，单位为像素，默认值为5）。
					backgroundColor: model.backgroundColor, //  '#000', //背景色（默认为黑色# 000）。
					penColor: model.penColor, //  '#fff', //线条颜色（默认为白色#fff）。
					velocityFilterWeight: model.velocityFilterWeight, //  0.9, //根据速度控制线的粗细（默认值为0.7）。
				}
				return option
			}
		},
		components: {
			signPad: SignPad
		},
		created() {
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
				this.model.$updateUserSign = this.updateUserSign
				this.model.$loadUserSign = this.loadUserSignData
				if (this.model.userInit === 'Y') {
					this.loadUserSignData()
				}
			}

		}
	}
</script>

<style scoped>

</style>