import clone from '../../../../../plugins/deepClone.js'
import options from '../../public/options.js'
import treeDiaolog from './TreeViewDialog.js'
let orgDiaolog = clone.deepClone(treeDiaolog)

orgDiaolog.data.type = 'orgdialog'
//orgDiaolog.hide=orgDiaolog.hide 
for (let item of orgDiaolog.base) {
	item.text = item.text.replace('节点', '组织')
	if (item.key === 'inputOption') {
		let init = item.init
		init.seted = true
		init.sourceId = -1 //主数据库
		init.tree.sql = options.orgSql
	}
}


export default orgDiaolog