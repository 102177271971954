class FormUtil { //表单相关操作工具
	//#$logic = null

	setLogic(logic) {
		this.$logic = logic
	}
	//基于配置式的表单数据校验,传入数据对象与校验规则，可对每项检测结果作回调，回调参数：isOk,field,reg,返回true继续检查，返回false不继续
	//校验规则格式: [{field: 'appItem.dbPort',regs: [{lenMin: 1,info: '数据库服务器端口不允许为空'}],
	dataCheck(dataObj, regs, reCall) {
		let result = {
			success: false,
			info: '',
			items: []
		}
		let isOk = true
		if (!dataObj || !regs) {
			//this.$logic.tip.error('空表单数据或空检验规则')
			result.info = '空表单数据或空检验规则'
			return result
		}
		for (let item of regs) {
			let field = item.field
			let fieldName = item.name || field
			if (!field) {
				//this.$logic.tip.error('表单数据检验规则错误，未指定数据属性')
				result.info = '表单数据检验规则错误，未指定数据属性'
				return result
			}
			//支持多级属性
			let props = field.split('.')
			let data = null
			let obj = dataObj

			for (let prop of props) {
				if (prop in obj) {
					data = obj[prop]
					obj = data
				} else {
					result.info = '表单数据错误，不存在的属性：' + fieldName + '[' + field + ']'
					return result
				}

			}
			if (data == null) {
				//this.$logic.tip.error('表单数据错误，不存在的属性：' + fieldName + '[' + field + ']')

				//continue
				result.info = '表单数据错误，属性：' + fieldName +' 值为空'
				return result
			}
			//	console.log(field+'='+data)
			for (let reg of item.regs) {
				let checked = true
				let info = null
				if (checked && reg.lenMin && data.length < reg.lenMin) {
					checked = false
					info = reg.info || fieldName + '字符长度不得小于' + reg.lenMin
				}
				if (checked && reg.lenMax && data.length > reg.lenMax) {
					checked = false
					info = reg.info || fieldName + '字符长度不得大于' + reg.lenMax
				}
				let nd = data
				let min = reg.min
				let max = reg.max
				if (checked && (reg.min || reg.max)) { //判断比较值类型,数值型只支持整数
					if ((typeof reg.min) == 'number' || (typeof reg.max) == 'number') {
						nd = parseInt(data)
						if (isNaN(nd)) {
							checked = false
							info = fieldName + '为非法数值'
						}
					} else if (reg.min instanceof Date || reg.max instanceof Date) {
						nd = new Date(nd.replace(/-/g, '/')).getTime()
						if (reg.min) { //统一转化成数据处理
							min = new Date(min.replace(/-/g, '/')).getTime()
						}
						if (reg.max) {
							max = new Date(max.replace(/-/g, '/')).getTime()
						}
					}
				}
				if (checked && reg.min) { //只支持数值\字符串类型\日期型
					if (nd < min) {
						checked = false
						info = reg.info || fieldName + '值不得小于' + reg.min
					}
				}
				if (checked && reg.max) {
					if (nd > max) {
						checked = false
						info = reg.info || fieldName + '值不得大于' + reg.max
					}
				}

				if (!checked) {
					isOk = false
					result.items.push({
						field: field,
						name: fieldName,
						info: info
					})
					result.info = info
					if (reCall) {
						if (!reCall(isOk, field, reg)) {
							return result
						}
					}
				}
				
			}
		}
		result.success = isOk
		return result
	}


}

export default new FormUtil()
