<template>
	<div :ref="'chart_'+model.id" style="height: 100%;width: 100%;">

	</div>
</template>

<script>
	import echartBaes from '../echartsBase.js'
	export default {
		mixins: [echartBaes],
		data() {
			return {
				//	mapArea:{}


			}
		},

		methods: {
			doUpdate(data) { //覆盖公共方法

				let dataSet = null
				if ('heads' in data) { //接口数据
					let head = []
					for (let item of data.heads) {
						head.push(item.label)
					}
					dataSet = head.concat(data.dataList)
				} else { //内置数据
					dataSet = data
				}

				this.updateOption(data)


			},
			optionFilter(option) { //处理种类组件特殊的配置项
				let model = this.model
				let series = option.series
				let dataSource = option.dataset.source
				let points = []
				let waves = []
				let pins = []
				let lines = []

				if (!option.dataset.source || option.dataset.source[0].length < 2) { //空数据不处理
					option.series = null
					return
				}

				if (this.mapArea[model.mapArea]) { //地图存在才加载
					//option.series = this.$logic.clone.deepClone(option._series)
					option.geo = this.$logic.clone.deepClone(option._geo)
					option.series = []
					for (let i = 1; i < dataSource.length; i++) {
						let row = dataSource[i]
						if (row.length > 6 && (row[1] === 'point' || row[1] === 'wave' || row[1] === 'pin')) { //加载点数据
							let list = row[1] === 'point' ? points : row[1] === 'wave' ? waves : pins
							list.push({
								name: row[0],
								value: [row[5], row[6], row[4], row[3], row[2]] //附加大小，颜色
							})
						} else if (row.length > 8 && (row[1] === 'line')) {
							lines.push({
								coords: [
									[row[5], row[6]], // 起点
									[row[7], row[8]] // 终点 // 如果 polyline 为 true 还可以设置更多的点     
								],
								color: row[2]
							})
						}

					}
					if (model.mapColorBy === 'data') {
						let fs = this.mapArea[model.mapArea]
						let regions = []
						let c = 0
						for (let item of fs.features) {
							regions.push({
								name: item.properties.name,
								itemStyle: {
									areaColor: model.color[c % model.color.length],

								}
							})
							c++
						}

						for (let i = 1; i < dataSource.length; i++) {
							let row = dataSource[i]
							if (row.length > 2 && row[1] === 'area') {
								let idx = -1
								for (let j = 0; j < regions.length; j++) {
									if (regions[j].name === row[0]) {
										idx = j
										break
									}
								}
								if (idx > -1) { //一定是存在的名称，不需要处理没找到的情况，没找到的是为效名称直接忽略
									regions[idx].itemStyle.areaColor = row[2]
								}
							}
						}
						option.geo.regions = regions
					} else {
						option.geo.regions = []
					}
					//////////////////////加载各类元素
					if (lines.length > 0) {
						let line = this.getNewSeries(option, lines, 'lines', 'circle')
					}
					if (points.length > 0) {
						let point = this.getNewSeries(option, points, 'scatter', 'circle')
					}
					if (pins.length > 0) {
						let pin = this.getNewSeries(option, pins, 'scatter', 'pin')
					}
					if (waves.length > 0) {
						let wave = this.getNewSeries(option, waves, 'effectScatter', 'circle')
					}


				} else {
					option.series = null //跳过渲染
					delete option.geo
					//console.log('!show')
					return
				}

				switch (model.type) {
					case 'chart_mapPoint':


						break
					case 'chart_mapLine':

						break
				}


			},
			getNewSeries(option, data, type, symbol) {
				let point = this.$logic.clone.deepClone(option._point)
				point.type = type
				point.symbol = symbol
				point.data = data
				if ('lines' === type) {
					for (let key in option._line) { //合并属性
						point[key] = option._line[key]
					}
					point.lineStyle.color = this.setPointColor
				} else {
					point.itemStyle.color = this.setPointColor
					point.symbolSize = this.setPointSize
				}
				option.series.push(point)
				return point
			},
			setPointColor(param) {
				let color = param.data.color || param.data.value[4] || this.model.pointColor
				return color
			},
			setPointSize(param) {
				let size = param[3] || this.model.pointSize
				return size
			}

		},
		computed: {
			mapArea() {
				return this.$store.state.mapArea
			}
		},

		created() {
			this.registerMap(this.model.mapArea)


		}
	}
</script>

<style scoped>

</style>
