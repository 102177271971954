<template>
	<div class="content">
		<div class="button-bar">
			<template v-for="item in fileGroupSet">
				<el-tag :type="item.key==group?'success':'info'" @click="group=item.key"
					class="group-item">{{item.label}}</el-tag>
			</template>
		</div>
		<div class="file-content">
			<div class="file-list">
				<template v-for="item in fileGroup.list">
					<div class="imgblock" @click="fileClick(item)">
						<img :src="item.url" :class="item===fileItem?'imgitem filesel':'imgitem'">
					<!-- 	<div class="imglabel" :title="item.fileName"> {{item.fileName}}</div> -->
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['filesel'],
		data() {
			return {
				fileItem: null,
				group: 'bg',
				groupMap: {},
				fileGroupSet: [{
						key: 'bg',
						label: '大屏背景',
						size: 14
					},
					{
						key: 'bt',
						label: '标题背景',
						size: 4
					},
					{
						key: 'bk',
						label: '边框背景',
						size: 1
					},

				]

			}
		},

		methods: {
			cancelSel() {
				this.fileItem = null
			},
			fileClick(item) {
				this.fileItem = item
				this.$emit('filesel', item)
			}
		},
		computed: {
			fileGroup() {
				return this.groupMap[this.group]
			}

		},
		created() {
			let groups = this.fileGroupSet

			for (let group of groups) {
				this.groupMap[group.key] = group
				let list = []
				for (let i = 1; i <= group.size; i++) {
					let fn = '' + i
					fn = '0000'.substring(0, 2 - fn.length) + fn
					list.push({
						fileName: fn,
						url: '/img/share/background/' + group.key + fn + '.png'
					})
				}
				group.list = list
			}



		}
	}
</script>

<style scoped>
	.button-bar {
		height: 30px;
		/* 	background-color: #f8f8f8; */
	}

	.group-item {
		margin: 4px;
		cursor: pointer;
	}

	.file-content {
		height: calc(100% - 30px - 2px);
		padding: 5px;
		border: solid 1px #cccccc;
		overflow: auto;
	}

	.file-list {
		display: flex;
		flex-wrap: wrap;
		display: flex;
		flex-wrap: wrap;
		width: 100%;

	}

	.imgblock {
		/* 	background-color: #f4f4f5; */
		position: relative;
		width: calc(25% - 20px);
		border: solid 1px #cccccc;
		min-width: 190px;
		margin: 3px;
		padding: 5px;
	}

	.imgitem {
		border-radius: 5px;
		width: 100%;
		height: 150px;
		cursor: pointer;
		object-fit: contain;
	}

	.imgitem:hover {
		box-shadow: 2px 2px 8px 1px #353535;
		border: solid 1px yellow;
	}
	.imglabel {
		text-align: center;
		height: 25px;
		line-height: 25px;
		margin-top: 2x;
		overflow: hidden;
		cursor: pointer;
	}
	.filesel {
		box-shadow: 2px 2px 8px 1px #353535;
		border: solid 1px yellow;

	}
</style>