<template>

	<!-- 方型四角 -->

	<svg class="svg-container">
		<path fill="transparent" :d="boxPath" :stroke="color1"></path>
		<path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9"
			:stroke="color1"></path>
		<path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" :stroke="color2"></path>
		<path fill="transparent" :d="boxPath2" :stroke="color2"></path>
	</svg>



</template>

<script>
	export default {
		props: {
			color1: {
				type: String,
				default: '#4fd2dd'
			},
			color2: {
				type: String,
				default: '#235fa7'
			},
			panelWidth: {
				type: Number,
				default: 200
			},
			panelHeight: {
				type: Number,
				default: 100
			},
			maxHornWidth: {
				type: Number,
				default: 150
			},
			maxHornHeight: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {

			}
		},

		methods: {

		},
		computed: {
			boxPath2() {
				let r = this.panelWidth
				let e = this.panelHeight
				let path = `M ${r - 5} ${e - 30} L ${r - 5} ${e - 5} L ${r - 30} ${e - 5}`

				return path
			},
			boxPath() {
				let r = this.panelWidth
				let e = this.panelHeight
				let path = `
                  M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
                  L ${r - 20} 10 L ${r - 5} 25
                  L ${r - 5} ${e - 5} L 20 ${e - 5}
                  L 5 ${e - 20} L 5 20
                `

				return path
			},
			hornStyle() {
				/* 	let minSize = this.panelWidth > this.panelHeight ? this.panelHeight : this.panelWidth
					let hornSize=minSize/2 */
				let hornHeight = this.panelHeight / 2 - 10 //留点空隙
				let hornWidth = this.panelWidth / 2
				/* if (hornHeight > this.maxHornHeight) {
					hornHeight = this.maxHornHeight
				} */
				if (hornWidth > this.maxHornWidth) {
					hornWidth = this.maxHornWidth
				}
				let css = {
					'--horn-width': hornWidth + 'px',
					'--horn-height': hornHeight + 'px'
				}
				return css

			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.svg-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		pointer-events: none;
	}

	.stroke-width2 {
		stroke-width: 2px;
		fill: none;
	}

	.stroke-width5 {
		stroke-width: 5px;
		fill: none;
	}

	.datav_block {
		width: var(--horn-width);
		height: var(--horn-height);
		position: absolute;
	}


	.datav_right-top {
		right: 0px;
		transform: rotateY(180deg);
	}

	.datav_left-bottom {
		bottom: 0px;
		transform: rotateX(180deg);
	}

	.datav_right-bottom {
		right: 0px;
		bottom: 0px;
		transform: rotateX(180deg) rotateY(180deg);
	}
</style>