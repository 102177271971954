import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
	{label:'选项一',value:'A',disabled:'N'},
	{label:'选项二',value:'B',disabled:'N'},
	{label:'选项三',value:'C',disabled:'Y'},
	{label:'选项四',value:'D',disabled:'N'}, 
	 
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'选项文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'disabled',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'',unique:false},
 
]
let styles=compBase.toOptions('圆点=A,按钮=B')
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'
let base = [
	{key: 'title',text: '单选按钮'	},

	{key: 'value',text: '选项值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'label',text: '选项文本',type: 'text',init: '',	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
 
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key: 'style',text: '形状样式',type: 'select',init: 'A',	target: '',	bursh: true,bind: true,tip: '',list: styles},	
    {key: 'border',text: '是否带边框',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	compBase.status2,

//	{key: 'color',text: '选中文本色',type: 'color',init: '#FFFFFF',	target: '',	bursh: true,bind: true,tip: '只在按钮样式时生效',list: options.YON},	
//	{key: 'fill',text: '选中背景色',type: 'color',init: '#409EFF',	target: '',	bursh: true,bind: true,tip: '只在按钮样式时生效',list: options.YON},	

{key: 'title',text: '样式定制'	},


   {key:'itemHeigth',text:'选项高度',type:'text',init:'32px',target:'--radio-item-height',	bursh: true,bind: true,tip:'默认值：24px',list:null},
   {key:'itemGap',text:'间距',type:'text',init:'32px',target:'--radio-item-margin-right',	bursh: true,bind: true,tip:'默认值：24px',list:null},

   {key:'inputWidth',text:'圆点宽度',type:'text',init:'14px',target:'--radio-input-width',	bursh: true,bind: true,tip:'默认值：24px',list:null},
   {key:'inputHeight',text:'圆点高度',type:'text',init:'14px',target:'--radio-input-height',	bursh: true,bind: true,tip:'默认值：24px',list:null},

//   {key:'inputColor',text:'圆点颜色',type:'color',init:null,target:'--el-border-color',	bursh: true,bind: true,tip:'',list:null},
   {key:'inputBorder',text:'圆点边框',type:'text',init:null,target:'--el-border',	bursh: true,bind: true,tip:'默认值：1px solid #dcdfe6',list:null},
   {key:'inputBgColor',text:'圆点背景色',type:'colorA',init:null,target:'--el-fill-color-blank',	bursh: true,bind: true,tip:'',list:null},
   {key:'inputHoverColor',text:'当前选项色',type:'color',init:null,target:'--el-color-primary',	bursh: true,bind: true,tip:'',list:null},

   {key:'labelSize',text:'标签字号',type:'text',init:null,target:'--el-font-size-base',	bursh: true,bind: true,tip:'默认值：14px',list:null},
   {key:'labelColor',text:'标签颜色',type:'color',init:null,target:'--el-text-color-regular',	bursh: true,bind: true,tip:'',list:null},



]

let radio = {
	data: {
		type: 'radio',isInput:'S',
	},
	delete: '',
	hide: 'dataOption',
	base: base.concat(compBase.base),
	special:extend.tip.concat( [
		{key: 'dataItem',text: '当前数据项',type: 'text',init: {},	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
		
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '选项数据',type: 'jsonArray',init: datas,	target: '',	bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},]),
	action:  options.getEvents(null,'$event_click,$event_change').concat([
		options.listLoadAfterEvents.dataListEvent,
		//options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default radio

