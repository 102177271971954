<template>


	<div :style="compStyle" style="overflow: auto;" @click="headSelect=null">
		<reportquery ref="queryRef" v-show="model.allowQuery==='Y'" @query="toQuery" @setorder="setOrder" :page="page"
			:project="project" :get-custom-sql="getCustomSql" @buttonclick="buttonClick" :query-set="model.dataSet"
			:auto-query="model.autoQuery" :params="urlParams" :allow-advance="model.advanceQuery==='Y'"
			:allow-head="false" :allow-order="model.allowOrder==='Y'" label-align="right"
			:item-width="model.queryItemWidth" :buttons="buttons" :fold-button="model.foldButton==='Y'"
			:isedit="isedit" />

		<table class="treetable" border="1">
			<tablehead v-if="model.headCustom==='Y' " @reset="headReset" init-bgcolor="#f0f0f0"
				:init-cols="initHeadCols" :table-host="model" :deep="0" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas"></tablehead>

			<tr v-else class="tabletop">
				<td class="node-arae">
					<div>目录</div>
				</td>
				<template v-for="head in headDataCols">
					<td @click.stop="headClick(head)"
						:style="{width:head.width,minWidth:head.minWidth || model.colMinWidth,fontFamily:head.fontName,fontSize:head.fontSize,color:head.fontColor,fontWeight:head.fontBold,backgroundColor:head.bgColor}">
						<div :class="headSelect===head?'current-col':''">{{head.label}}</div>

					</td>
				</template>

			</tr>
			<tr v-if="isedit " class="">
				<td class="col-disable">
					<div>模板行 </div>
				</td>
				<td v-for="head in headDataCols" :class="head.mode==='custom'?'col-design':'col-disable'">
					<container v-if="head.mode==='custom' && head.panel" :model="head.panel" :project="project"
						:page="page" :host="host" :isedit="isedit" :datas="null" />

					<div v-else>
						#{{head.mode==='text'?'文本':head.mode==='num'?'数值':head.mode==='time'?'时间':'未知'}}
					</div>
				</td>
			</tr>
			<template v-for="row in rows" :key="row.id">
				<tr v-show="row.show" :class="row.split?'row row-split':'row'" :style="{height:row.show?'':'0px'}">
					<td class="cell node" :style="{paddingLeft:row.left}">
						<i v-if="row.fold" @click="toggleRow(row,row.folded)"
							:class="'fold fas fa-'+(row.folded ?'caret-right':'caret-down')"></i>
						<a @click="nodeClick(row)" class="celllink node-text" href="javascript:void(0)">
							{{row.node.label}}
						</a>
					</td>
					<template v-for="col in row.cols">
						<td v-show="row.show" :align="col.hAlign" :valign="model.dataV" class="cell">
							<container v-if="! row.node.empty && col.head.mode==='custom' && row.node.$cellModel"
								:model="row.node.$cellModel[col.head.label]" :project="project" :page="page"
								:host="host" :isedit="isedit" :datas="row.node.data" />
							<a v-else @click="dataClick(row,col)" class="celllink data" href="javascript:void(0)">
								{{col.label}}
							</a>
						</td>
					</template>




				</tr>
			</template>
		</table>

	</div>
</template>

<script>
	import treeDataInit from './TreeDataInit.js'
	import ReportQuery from './ReportQuery.vue'
	import TableHead from './TableHead.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],

		data() {
			return {
				headSelect: null,
				deep: 1,
				rows: [],
				urlParams: {},
				nodeKeys: ['id', 'pid', 'label'],
				initHead: {
					colTexts: [{
							label: '金额',
							fieldName: 'price',
							typeGroup: 'num',
							mode: 'num',
							totalType: 'sum'
						},
						{
							label: '利润',
							fieldName: 'count',
							typeGroup: 'num',
							mode: 'num',
							totalType: 'sum'
						},
						{
							label: '成本',
							fieldName: 'total',
							typeGroup: 'num',
							mode: 'num',
							totalType: 'sum'
						},
						{
							label: '税金',
							fieldName: 'pay',
							typeGroup: 'num',
							mode: 'num',
							totalType: 'sum'
						}
					],
					colFields: ['price', 'count', 'total', 'pay', 'ex0', 'ex1', 'ex2', 'ex3', 'ex4', 'ex5', 'ex6', 'ex7',
						'ex8', 'ex9'
					]
				},
				dataitems: treeDataInit

			}
		},

		methods: {
			buttonClick(button) {
				this.doEvent({
					eventName: 'buttonClick',
					button: button,
					dataList: this.rows
				}, '$comp_buttonClick')
			},
			getCustomSql(normalMap = {}) { //查询组件的回调值，固定查询和虚拟查询项
				if (this.isedit) {
					return null
				} else {
					return this.doEvent({
						eventName: 'getSql',
						params: normalMap
					}, '$comp_getSql')
				}
			},
			dataClick(row, col) {
				let data = {
					row: row.node.data,
					field: col.field,
					value: row.node.data[col.field],
					text: col.label
				}
				if (!this.isedit) {
					this.doEvent({
						eventName: 'dataClick',
						data: data
					}, '$comp_dataClick')
				}

			},
			nodeClick(row) {
				let data = row.node.data
				if (!this.isedit) {
					this.doEvent({
						eventName: 'nodeClick',
						data: data
					}, '$comp_nodeClick')
				}
			},
			headClick(head) {
				if (this.isedit) {
					head.parent = this.model
					this.headSelect = head
					this.setCurrentElement(head)
				}


			},
			sqlUpdate() {
				this.callQuery()
			},
			callQuery(params) {

				if (params && typeof(params) === 'object') { //传参
					for (let key in params) {
						this.urlParams[key] = params[key]
					}
				}
				this.$refs.queryRef.getQuery()
			},
			toQuery(sql, params) {
				if (this.isedit) {
					this.setTreeTable(this.dataitems)
					return
				} else {
					if (sql == null || sql.length < 5) {
						return
					}
				}
				let ds = this.model.dataSet
				let option = {
					isJson: true,
					total: false,
					size: 10000,
					pageNo: 0,
					showLayer: true
				}
				this.$logic.http.sqlQuery(this.dbSourceId, sql, params, option).then(res => {


					let dataList = res.data.data.dataList
					this.setTreeTable(dataList)

				}).catch(err => {
					let info = err.info
					this.$logic.tip.error('数据查询处理异常：' + info)
				})


			},
			setOrder(list) {
				this.model.dataSet.orderItems = list
			},
			setSplitColor() { //设置班马线颜色
				let split = false
				for (let row of this.rows) {
					row.split = false
					if (row.show) {
						split = !split
						row.split = split
						/* if(split){
							row.bgcolor=this.model.lineColor
						}else{
							row.bgcolor=''
						} */
					}
				}
			},
			toggleRow(row,
				folded) { //查找到当前节点，顺序向下找子节点全设置为隐藏，第一个直接子节点设置为显示,通过隐藏所有下属单元格元素后设置行高度为0的方式实现隐藏，行直接display:none为导致跨行单元格错乱

				let show = folded
				let node = row.node
				row.folded = !folded //状态取反
				//子元素全部置为不可见
				let stack = [].concat(node.children)
				while (stack.length > 0) {
					let nd = stack.pop()
					nd.row.folded = true
					nd.row.show = false
					for (let cd of nd.children) {
						stack.push(cd)
					}
				}
				//设置第一级的可见性
				for (let nd of node.children) {
					nd.row.show = show
				}
				this.setSplitColor()
			},
			getTreeTable(tree, filds) {
				let indent = this.model.indent ? this.model.indent : 20

				let rows = []
				let parents = {}
				let stack = [].concat(tree.root)
				stack.reverse()
				while (stack.length > 0) {
					let node = stack.pop()
					for (let j = node.children.length - 1; j > -1; j--) { //反向入栈，按原来的顺序取出
						stack.push(node.children[j])
					}
					let deep = 0
					let pn = node.parent
					while (pn) {
						deep++
						pn = pn.parent
					}

					let row = {
						id: node.id,
						node: node,
						left: (5 + deep * indent) + 'px',
						fold: node.children.length > 0,
						folded: true, //是否折叠
						show: false,
						split: false, //分隔行
						cols: []
					}
					node.row = row

					for (let head of filds) { //根据数据属性生成数据区单元格
						let field = head.fieldName
						let col = {
							field: field,
							head: head,
							label: null //this.formatNum(node.data[fild.fieldName], false), //格式化数据
						}
						if (head.mode === 'custom' && head.panel) {
							let panel = this.$logic.clone.deepClone(head.panel, ['parent'])
							this.$logic.clone.setParent(panel)
							col.panel = panel
							col.label = node.data[field] || null

							//console.log(col.label)
						} else {
							if (head.mode === 'num') {
								col.label = this.formatNum(node.data[field], false)
							} else {
								col.label = node.data[field]
							}
						}
						row.cols.push(col)
					}
					for(let i=0;i<this.headDataCols.length;i++){//从右向左，将对齐方式传进数据单元格
						let head=this.headDataCols[this.headDataCols.length-i-1]
						let col=row.cols[row.cols.length-i-1]
						col.hAlign=head.hAlign
					}
					rows.push(row)
				}
				//根节点设置显示
				for (let nd of tree.root) {
					nd.row.show = true
				}
				return rows
			},

			getTree(dataList, fields) { //必须包含id,pid,属性
				let tree = {
					deep: 0,
					root: [],
					leaf: [],
					dataBag: null
				}
				let dataBag = {}
				let list = [] //用数据确保数据项顺序
				for (let node of dataList) {
					let data = {
						...node
					}
					let empty = true
					for (let col of fields) {
						let field = col.fieldName
						if (col.mode === 'num') {}
						if (node[field] == null) { //无值置为空，避免与0值混淆
							data[field] = null
						} else {
							data[field] = node[field]
							if (col.mode === 'num' && typeof(data[field]) === 'string') { //如果类型为字符型，值一定是整数型
								data[field] = parseInt(data[field])
							}
							if (col.mode !== 'custom') { //如果存在不是自定义类型节点时才认为不是空节点
								empty = false
							}

						}

					}

					/* 	if (!has) {
							data = {}
						} */
					let item = {
						type: 'N',
						id: '' + node.id,
						pid: '' + node.pid,
						label: node.label,
						parent: null,
						empty: empty, //当前节点是否含有数据
						data: data,
						leafCount: 0,
						layer: 0,
						children: []
					}
					dataBag[item.id] = item
					list.push(item)
				}
				//构建树
				for (let item of list) {
					let parent = dataBag[item.pid]
					if (parent) {
						item.parent = parent
						parent.children.push(item)
					} else {
						tree.root.push(item)
					}
				}
				//增加虚拟节点,如果本节点非叶子节点且自带数据在此下增加一个同名子节点，ID取负
				let isTotal = this.model.total === 'Y'
				let exNodes = [] //新增的节点，最后要加入list
				if (isTotal) {
					for (let node of list) {
						if (node.children.length > 0 && !node.empty) {
							let cn = {
								id: '-' + node.id,
								pid: '' + node.id,
								label: node.label + '*',
								parent: node,
								data: {
									...node.data
								},
								leafCount: 0,
								layer: 0,
								children: []
							}
							for (let key in node.data) { //父节点值置0
								if (typeof(node.data[key]) === 'number') {
									node.data[key] = 0
								}
							}
							node.children.push(cn)
							exNodes.push(cn)
						}
						if (node.children.length > 0) {
							node.total = null
						}

					}
					list = list.concat(exNodes)
					for (let node of list) {
						if (node.children.length > 0) { //对每个叶子节点自下向上累计
							continue
						}
						let pnode = node.parent
						while (pnode) {
							this.dataTotal(pnode.data, node.data, fields)
							pnode = pnode.parent
						}
					}

				}
				//提取自定义列，并生成事件

				let panels = []
				let heads = []
				for (let head of fields) { //取出自定义列

					if (head.mode === 'custom' && head.panel && head.panel.type) {
						panels.push(head.panel)
						heads.push(head)
					}

				}
				if (!this.isedit) { //运行模式创建事件
					this.$logic.http.initModel(this.elementConfig, panels, true, this.page)
				}
				let filtDataList = []
				//设置层级
				let deep = 0
				for (let item of list) {
					for (let head of heads) { //对每行生成自定义列的克隆对象
						let panel = this.$logic.clone.deepClone(head.panel, ['parent'])
						this.$logic.clone.setParent(panel)
						if (!item.$cellModel) {
							item.$cellModel = {}
						}
						item.$cellModel[head.label] = panel
					}
					if (item.children.length > 0) {
						if (item.empty) {
							for (let head of fields) {
								if (item.data[head.fieldName] != null) { //只要至少有一个值，即为非空节点
									item.empty = false //如果存在子节点
									break
								}
							}
						}
					} else {
						if (!item.empty) {
							filtDataList.push(item.data) //非空数据的叶子节点加入集合
						}
						// 如果当前节点是叶子节点，向上对所有祖先节点依次累计加1 		
						let pnode = item.parent
						while (pnode) {
							pnode.leafCount++
							pnode = pnode.parent
						}

					}
					let p = item
					while (p) {
						item.layer++
						if (deep < item.layer) {
							deep = item.layer
						}
						p = p.parent
						if (item.layer > 20) { //检测数据循环指向防止死循环
							alert('检测到树型数据循环引用，请核查数据')
							return {
								deep: 0,
								root: []
							}
						}
					}

				}
				tree.dataBag = dataBag
				tree.deep = deep
				if (!this.isedit) {
					this.doEvent({
						eventName: 'filter',
						data: filtDataList
					}, '$comp_filter')
				}
				return tree
			},

			dataTotal(totalData, leafData, fields) {
				for (let col of fields) {
					if (col.totalType === 'none') { //|| col.mode !== 'num'
						continue
					}
					let field = col.fieldName
					let value1 = totalData[field]
					let value2 = leafData[field]
					if (typeof(value2) !== 'number') { //忽略非数值数据
						if (col.totalType === 'sum') {//求和只支持数值
							continue
						}

					}
					if (value1 == null) {
						totalData[field] = value2
						continue
					}
					switch (col.totalType) {
						case 'sum':

							totalData[field] = value1 + value2
							break
						case 'max':
							if (value1 < value2) {
								totalData[field] = value2
							}
							break
						case 'min':
							if (value1 > value2) {
								totalData[field] = value2
							}
							break
					}

				}
			},


			headReset(config) {
				this.model.headConfig = config
			},
			setRecall(dataSet) {
				let keys = {
					id: 0,
					pid: 0,
					label: 0
				}

				for (let head of dataSet.headItems) {
					let field = head.fieldName.toLocaleLowerCase()
					if (field in keys) {
						head.fieldName = field //转小写
						keys[field] = 1
					} else if (!(field in keys) && head.typeGroup !== 'num' && head.mode !== 'custom') {
						//this.$logic.tip.error('SQL结果中的字段：' + head.fieldName + ' 必须为数值类型或自定义类型')
						//return false
					}
				}
				for (let key in keys) {
					if (keys[key] < 1) {
						this.$logic.tip.error('SQL结果中必须包含字段：[ ' + key + ' ]，如：select id,pid,lable,total from tablename')
						return false
					}
				}

				for (let key in dataSet) {
					this.model.dataSet[key] = dataSet[key]
				}
				this.setTreeTable(this.dataitems)
				return true
			},
			setTreeTable(dataitems) {

				let fields = []
				if (this.isedit) {
					/* let last = this.headDataCols.length > 0 ? this.headDataCols.length : this
						.initHead.colTexts.length */
					for (let i = 0; i < this.headDataCols.length; i++) {
						fields.push({
							fieldName: this.initHead.colFields[i],
							typeGroup: 'num',
							mode: this.headDataCols[i].mode,
							totalType: this.headDataCols[i].totalType //'sum'
						})
					}

				} else {
					for (let head of this.headDataCols) {
						fields.push(head)

					}
				}


				let tree = this.getTree(dataitems, fields)
				let rows = this.getTreeTable(tree, fields) //
				this.rows = rows
				this.deep = tree.deep
				this.setSplitColor()
			},
			initTempatePanel() { //初始化自定义单元格内的模板元素
				for (let head of this.headDataCols) {
					if (head.panel) { //如果存在有效的容器对象
						let sk = [head.panel]
						while (sk.length > 0) {
							let p = sk.pop()
							for (let item of p.items || []) {
								item.parent = p
								sk.push(item)
							}
						}

					}
				}
			}
		},
		computed: {
			dbSourceId() {
				return this.model.dataSet.dataSourceId ? this.model.dataSet.dataSourceId : this.project.dataSource.id
			},
			initHeadCols() {
				return [{
					label: '目录'
				}].concat(this.headDataCols)
			},
			headDataCols() {
				let heads = []
				if (this.model.dataSet.headItems.length > 0) {
					for (let head of this.model.dataSet.headItems) {
						if (this.nodeKeys.indexOf(head.fieldName) <
							0 && head.mode !== 'hide') { //&& head.typeGroup === 'num'
							heads.push(head)
						}
					}
				} else {
					heads = [].concat(this.initHead.colTexts)

				}

				return heads
			},
			buttons() {
				let bs = []
				try {
					let data = JSON.parse(this.model.buttons)
					for (let item of data) {
						if (item.key && ',query,super,head,order,'.indexOf(',' + item.key + ',') > -1) {
							let key = item.key + 'Button'
							if (this.model[key] === 'Y') {
								bs.push(item)
							}
						} else {
							if (this.model.allowButton == 'Y') {
								bs.push(item)
							}
						}
					}

				} catch (ex) {
					this.$logic.tip.error('报表按钮数据定义格式错误：' + ex)
				}
				return bs

			},


		},
		watch: {
			'model.total'(newValue, oldValue) {
				if (this.isedit) {
					this.setTreeTable(this.dataitems)
				}
			},
			'model.indent'(newValue, oldValue) {
				if (this.isedit) {
					this.setTreeTable(this.dataitems)
				}
			}
		},
		components: {
			tablehead: TableHead,
			reportquery: ReportQuery,
		},
		created() {
			if (this.isedit) {
				this.initTempatePanel()
				this.model.setRecall = this.setRecall
			} else {
				this.urlParams = this.page.$params || {}
				this.model.$query = this.callQuery //程序调用点击查询按钮
				this.model.$sqlUpdate = this.sqlUpdate
			}
		}
	}
</script>

<style scoped>
	.treetable {
		min-width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.cell {
		padding-left: 5px;
		padding-right: 5px;
	}

	.node-arae {
		width: var(--node-area-width);
		min-width: var(--node-area-minwidth);
	}

	.node {
		font-family: var(--node-font-name);
		font-size: var(--node-font-size);
		font-weight: var(--node-font-weight);
		background-color: var(--node-back-color);
	}

	.node:hover {
		background-color: var(--hover-color);
	}

	.node-text {
		color: var(--node-font-color);
	}

	.data {
		font-family: var (--data-font-name);
		font-size: var(--data-font-size);
		font-weight: var(--data-font-weight);
		background-color: var(--data-back-color);
		color: var(--data-font-color);
	}

	.total {
		font-weight: bold;
	}

	.row {
		height: var(--row-height);

	}

	.row-split {
		background-color: var(--line-color);
	}

	/* 	.row:nth-child(odd) {
		background-color: var(--line-color);
	}
 */
	.row:hover {
		background-color: var(--hover-color);
	}

	.celllink {
		text-decoration: none;
	}

	.celllink:hover {
		text-decoration: underline;
	}

	.fold {
		font-size: 20px;
		margin-right: 5px;
		cursor: pointer;
		color: var(--node-icon-color);
	}

	.tabletop {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		background-color: #e7e7e7;
	}

	.tabletop>td {
		height: var(--head-height);
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;
		cursor: pointer;

	}

	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.current-col {
		background: linear-gradient(#ffdddd, #FF4500, #ffdddd);
		color: #ffffff;
	}

	.col-design {
		height: 50px;
		cursor: pointer;
		border: dashed 1px #cccccc;
	}

	.col-disable {
		height: 50px;
		background-color: #f8f8f8;
		cursor: not-allowed;
		border: solid 1px #cccccc;
		text-align: center;
	}
</style>