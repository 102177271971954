import fileexplore from './file/FileExplore.vue'
import logictree from './treeview/TreeView.vue'
import treeChoose from './treeview/TreeChoose.vue'
import codeeditor from './codeeditor/CodeEditor.vue'
import logicdialog from './dialog/Dialog.vue'
import logicdrawer from './dialog/Drawer.vue'
import TitleBar from './titlebar/TitleBar.vue'
import inputcolor from './input/color/InputColor.vue'
import inputsuggest from './input/suggest/InputSuggest.vue'
import iconfont from './icon/FontAwesome.vue'
import runing from './runing/Runing.vue'
import dataeditor from './dataeditor/DataEditor.vue'
import tableField from './tablefield/TableField.vue'
//import layoutBlock from '../form/comp/form/layout/Block.vue'
import imgupload from './img/ImgUpload.vue'
import Container from '../form/comp/form/layout/Container.vue'
/* const logicReg={
	install(Vue){
		Vue.component("titlebar",TitleBar)	
	}
} */

const logicReg = (app) => {
	app.component("titlebar", TitleBar)
	app.component("fileexplore", fileexplore)
	app.component("imgupload", imgupload)
	app.component("logictree", logictree)
	app.component("treechoose", treeChoose)
	app.component("codeeditor", codeeditor)
	app.component("logicdialog", logicdialog)
	app.component("logicdrawer", logicdrawer)
	app.component("inputcolor", inputcolor)
	app.component("inputsuggest", inputsuggest)
	app.component("iconfont", iconfont)
	app.component("runing", runing)
	app.component("dataeditor", dataeditor)
	app.component("tablefield", tableField)
//	app.component("layoutblock", layoutBlock) //循环型组件注册为全局组件，避免报错： xxx,compilerOptions.isCustomElement
	app.component("Container", Container)
}
export default logicReg