<template :class="baseStyle">
	<!-- 	<tr v-for="row in head.items" class="noselect">
		<td v-for="col in row.items" @click.stop="tdMouseClick(col)" @mousedown.stop="tdMouseDown(cell)"
			@mouseover.stop="tdMouseHover(cell)" @mouseup.stop="tdMouseUp(cell)">
			{{col.type}}
		</td>
	</tr> -->


	<tr v-for=" row in  head.items" :ref="'row'+row.id" @click.stop="tdMouseClick(row)"
		:class="isedit?  currentElement==row? 'layouttr noselect trselect': 'layouttr noselect' : ''"
		:style="{height: (row.trH ? row.trH.indexOf('0')==0? null:row.trH :null) || head.trH,backgroundColor: row.trBC || head.backColor,fontFamily:head.fontName,fontSize:head.fontSize=='0' ?'':head.fontSize,color:head.fontColor,fontWeight:head.fontBold,textDecoration:head.fontLine,fontStyle:head.fontItalic,lineHeight:head.fontHeight}">
		<template v-if="deep>0 && row.$idx==0 ">
			<!-- <td v-for="col in autoLefts" :style="{width:col.width,backgroundColor:col.bgColor}" :rowspan="col.rowSpan"
				:align="col.hAlign" :valign="col.vAlign">{{col.label}}</td> -->
			<td :colspan="deep" :style="{width:leftWidth,backgroundColor:autoLefts[0].bgColor}" :rowspan="autoLefts[0].rowSpan"
				:align="autoLefts[0].hAlign" :valign="autoLefts[0].vAlign">目录</td>

		</template>

		<td v-for="cell in row.items" :align="cell.tdH || head.tdH" :valign="cell.tdV || cell.parent.parent.tdV"
			:class="(isedit? currentElement==cell?'layouttd tdcurrent': 'layouttd' : '') + (cell.sel ? ' tdsel':'')"
			:style="{width:cell.tdW,padding:cell.tdP || head.tdP,fontFamily:cell.fontName,fontSize:cell.fontSize=='0' ?'':cell.fontSize,color:cell.fontColor,fontWeight:cell.fontBold,textDecoration:cell.fontLine,fontStyle:cell.fontItalic,lineHeight:cell.fontHeight,backgroundColor:cell.tdBC || row.trBC || head.backColor}"
			:rowspan="cell.span[0][1] - cell.span[0][0]+1" :colspan="cell.span[1][1] - cell.span[1][0]+1"
			@click.stop="tdMouseClick(cell)" @mousedown.stop="tdMouseDown(cell)" @mouseover.stop="tdMouseHover(cell)"
			@mouseup.stop="tdMouseUp(cell)">
			<span v-if="cell.tdT ">{{cell.tdT}}</span>

			<template v-for="item in cell.items">
				<!-- 	
				<formelement v-else-if="',base,wrap,'.indexOf(item.group)>-1" :head="item" :isedit="isedit"
					:datas="datas">
				</formelement>
	 -->
			</template>
		</td>
	</tr>
</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		emits: ['reset'],
		props: {
			deep: {
				type: Number,
				default: 3
			},
			tableHost: {
				type: Object,
				default: null
			},
			initCols: {
				type: Array,
				default: []
			},
			initBgcolor: {
				type: String,
				default: '#f8f8f8'
			},
			leftWidth: {
				type: String,
				default: '200px'
			},


		},
		data() {
			return {
				head: {
					type: 'head',
					items: []
				},
				tdStart: null,

			}
		},

		methods: {

			tdSelClear() {
				//全部置为未选状态
				let table = this.head
				this.$store.commit('setTableBox', null)
				for (let row of table.items) {
					for (let col of row.items) {
						col.sel = false
					}
				}
			},
			tdMouseDown(cell) {
				if (this.isedit) {
					this.tdStart = cell
					if (this.tableBox) {
						this.tdSelClear()
					}
				}
			},
			tdMouseHover(cell) { //单元格框选
				if (this.isedit && this.tdStart) {
					this.tdAreaSel(this.tdStart, cell)

				}

			},
			tdMouseUp(cell) {
				if (this.isedit) {
					/* 	if (this.newComp.show) { //创建或拖动组件
							this.mouseUp(null, cell)
						} */
					this.tdStart = null //只要是鼠标弹起即取消框选状态
				}
			},
			tdMouseClick(cell) {
				if (this.isedit) {
					this.$store.commit('setElement', cell)
				}
			},
			tdAreaSel(start, end) { //区域框选
				if (start == null || end == null) {
					return
				}
				let table = this.head
				//不论从哪个方向开始框选，一定是个标准的矩形区域，从起始和终止单元格中分别取出最大行号、最小行号、最小列号、最大列号，比对落在此范围内的单元格设置标记
				//先假定从上往下框选
				let row1 = start.span[0][0] //单元格的起始最小行
				let row2 = end.span[0][1] //单元格截止的最大行					
				if (row1 > end.span[0][0]) { //起始行大于截止行是从下往上框选的
					row1 = end.span[0][0]
					row2 = start.span[0][1]
				}
				//假定从左往右选
				let col1 = start.span[1][0]
				let col2 = end.span[1][1]
				if (col1 > end.span[1][0]) { //如果是从右往左的方向
					col1 = end.span[1][0]
					col2 = start.span[1][1]
				}
				this.tdSelClear()
				let selBox = {
					table,
					row1,
					row2,
					col1,
					col2
				}
				this.$store.commit('setTableBox', selBox)
				//设置已选状态
				for (let i = row1; i <= row2; i++) { //跨行合并的单元格只要上部落在行区间内即可，如只有底部落在区间内不丢弃
					let row = table.items[i]
					for (let col of row.items) {
						let c1 = col.span[1][0]
						if (c1 >= col1 && c1 <= col2) { //只需要起始位置在区间内即被选中（如果中间有个较长的合并单元格，另一头超出了区间也被选中，框选区域合并操作时须注意不允许作合并操作）
							col.sel = true
						}
					}
				}


			},
			initRows(reset) {
				let head = null
				if (this.tableHost.headConfig && !reset) {
					head = this.tableHost.headConfig
					head.parent = this.tableHost
					for (let row of head.items) {
						row.parent = head
						for (let col of row.items) {
							col.parent = row
						}
					}
					this.head = head
					return
				}

				let config = this.elementConfig
				let rowSize = 1
				//let colSize=4
				let cols = this.initCols
				head = config.create('head', null, this.page.formData)
				head.backColor = this.initBgcolor

				head.items = []
				head.parent = this.tableHost
				for (let i = 0; i < rowSize; i++) {
					let row = config.create('tr', head, this.page.formData)
					row.$idx = i

					for (let j = 0; j < cols.length; j++) {
						let td = config.create('td', row, this.page.formData)
						td.sel = false
						td.span = [
							[i, i],
							[j, j]
						]
						td.tdT = cols[j].label ? cols[j].label : cols[j]
					}

				}
				this.head = head
			},
			setOffLocation() {
				setTimeout(() => {
					let h = 0
					for (let row of this.head.items) {
						let rId = 'row' + row.id
						let item = this.$refs[rId]
						item.style.top = h + 'px'
						h = h + item.offsetHeight
					}
				}, 500)
			},
		},

		computed: {
			autoLefts() {

				let cells = []
				let head = this.head
				let deep = this.deep

				if (deep < 1) {
					return cells
				}
				let lefts = head.leftHeads
				if (typeof(lefts) == 'string') {
					try {
						lefts = JSON.parse(lefts)
					} catch (ex) {
						lefts = []
						this.$logic.tip.error('动态列数据配置错误')
					}

				}

				for (let i = 0; i < deep; i++) {
					let cell = {
						rowSpan: head.items.length,
						label: '',
						width: null,
						vAlign: null,
						hAlign: null,
						bgColor: ''
					}
					if (i < lefts.length) {
						let col = lefts[i]
						cell.width = col.width
						cell.label = col.label
						cell.hAlign = col.hAlign
						cell.vAlign = col.vAlign
						cell.bgColor = col.bgColor
						/* 	if(i==lefts.length-1 && leafType=='span'){//如果是最后一列并且
								
							} */
					}
					cells.push(cell)
				}
				return cells
			},
			tableBox() {
				return this.$store.state.tableBox
			},
		},
		watch: {
			head: {
				deep: true,
				handler(nv, ov) {
					if (this.isedit) {
						this.$emit('reset', nv)
					}

				}
			},
			initCols(nv, ov) {

				this.initRows(true)
			},
			/* '$store.state.resizeTick'() {
				this.setOffLocation()
			} */
		},
		mounted() {
			this.setOffLocation()
		},
		created() {
			this.initRows(false)


		}
	}
</script>

<style scoped>
	.trselect {

		background-color: #ffffd7 !important;
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.layouttr {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
	}

	.layouttr:hover {

		background-color: #ffffd7;
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.layouttd:hover {
		background-color: #ffff7f !important;
	}

	.tdcurrent {
		border-color: #ffaa00;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
	}

	.tdsel {
		background-color: #ff00ff !important;
	}

	.tbselect {
		border-color: #ffaa00 !important;
		box-shadow: 0px 0px 0px 2px #FFD700 inset;
		/* 	border-color: #ff00ff;
		box-shadow: 0px 0px 0px 2px #ffaaff inset; */
	}
</style>
