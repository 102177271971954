import {
	createStore
} from 'vuex'

const store = createStore({
	state() {
		return {
			user: null,
			mapArea: {},
			resizeTick: 0,
			coverLayer: {
				show: false
			}, //遮罩层，组件在App.vue内
			userSet: {
				showPlan: true,
				showMessage: true
			},
			element: {},
			newComp: {
				show: false
			},
			hoverElement: {},
			mouserInbody: false, //鼠标是否在编辑区内，用于判断是否减去边框尺寸
			editElementObj: {
				parent: null,
				element: null,
				act: ''
			}, //新创建的元素,设计器监听如有变化表示已有新元素产生,通知Dom树更新数据
			tableBox: null //表格框选
		}
	},
	mutations: {
		setUser(state, user) {
			if (user) {
				let preUser = state.user
				state.user = user 
				if (user.id) { // || user.id === 0 && !preUserid为0表示访客,访客的情况下，如果当前不存在登录用户信息，写入，否则只变更存中的信息
					localStorage.setItem("$$user", JSON.stringify(user))
				}


			} else {
				state.user = null
				localStorage.removeItem("$$user")
			}

		},
		setMapArea(state, areaPoint) {
			state.mapArea[areaPoint.name] = areaPoint.data
		},
		setCoverLayerShow(state, show) {
			state.coverLayer.show = show
		},
		setTableBox(state, box) {
			state.tableBox = box
		},
		setUserSet(state, obj) {
			state.userSet[obj.key] = obj.value
		},
		setEditElementObj(state, elObj) {
			state.editElementObj = elObj
		},
		setMouserInbody(state, tag) {
			state.mouserInbody = tag
		},
		setHoverElement(state, el) {
			state.hoverElement = el
		},
		clearCreateStatus(state) {
			state.newComp.show = false
		},
		setElement(state, el) {
			state.element = el
		},
		setNewComp(state, el) {
			state.newComp = el
		},
		updateResizeTick(state) {
			state.resizeTick = new Date().getTime()
		}

	},
	actions: {},
	modules: {}
})
export default store