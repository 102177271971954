<template>
	<div :class="withBorder?'sign-pad':'sign-pad no-border'" :style="padImg">
		<img @click="toEdit(true)" class="sign-img" :src="imgData" />
		<div v-show="isEdit" class="sign-edit">
			<div :style="signEdit">
				<div class="sign-write" @dblclick="padCancel">
					<canvas ref="pad" class="pad-canvas"></canvas>
				</div>
				<div class="sign-tool">
					<el-button type="primary" @click="padClear">
						<i class="fad fa-eraser"></i>
						清除</el-button>
					<el-button type="default" @click="padCancel">
						<i class="fas fa-times"></i>
						取消</el-button>
					<el-button type="success" @click="padOk">
						<i class="fas fa-check"></i>
						确定</el-button>
				</div>


			</div>

		</div>
	</div>

</template>

<script>
	import SignaturePad from 'signature_pad'
	import {
		computed
	} from 'vue'
	const signOption = {
		dotSize: 3, //点的大小（在屏幕上点击留下的点的大小，单位为像素）。
		minWidth: 0.5, //线的最小宽度（单位为像素，默认值为0.5）。
		maxWidth: 2.5, //线的最大宽度（单位为像素，默认值为2.5）。
		throttle: 16, //节流（每次绘制两个点之间的时间，单位为毫秒，默认值为16，注意设置过大会导致绘制卡顿）。
		minDistance: 5, //最小距离（每次绘制两个点之间的最小距离，单位为像素，默认值为5）。
		backgroundColor: '#000', //背景色（默认为黑色# 000）。
		penColor: '#fff', //线条颜色（默认为白色#fff）。
		velocityFilterWeight: 0.9, //根据速度控制线的粗细（默认值为0.7）。
	}
	export default {
		props: {
			option: {
				type: Object,
				default: signOption
			},
			allowEdit: {
				type: Boolean,
				default: true
			},
			padWidth: {
				type: String,
				default: '90vw'
			},
			padHeight: {
				type: String,
				default: '90vh'
			},
			designMode: { //是否开发设计模式
				type: Boolean,
				default: false
			},
			signData: {
				type: String,
				default: null
			},
			withBorder: {
				type: Boolean,
				default: true
			}

		},
		emits: ['sign'],
		data() {
			return {
				signObj: null,
				signImgData: null,
				isEdit: false,


			}
		},

		methods: {
			setData(data) {
				this.signImgData = data
			},
			padOk() {
				if (this.signObj) {
					let data = this.signObj.toDataURL('image/png', 1) //整个带背景的图片
					/* 
					let svg = this.signObj.toDataURL('image/svg+xml')//只带图形自身的图片，透明背景色
					let datas = JSON.stringify(this.signObj.toData())					
					console.log(data.length)
					console.log(svg.length)
					console.log(datas) */
					//let data =  this.signObj.toDataURL('image/svg+xml')
					this.signImgData = data
					this.isEdit = false
					if (this.signObj.isEmpty() || this.signObj.toData().length < 1) { //检测是否无签名，isEmpty为false 有可能是空内容
						data = ''
					}
					this.$emit('sign', data)
				}

			},
			padCancel() {
				this.isEdit = false
			},
			padClear() {
				if (this.signObj) {
					this.signObj.clear()
				}
			},
			toEdit(check) {
				if (check && this.designMode) {
					return
				}
				if (this.allowEdit) {
					this.isEdit = true
					if (!this.signObj) {
						this.$nextTick(() => {
							this.createPad()
							if (this.signImgData) {
								this.signObj.fromDataURL(this.signImgData)
							}
						})

					} else {
						this.$nextTick(() => {//延时变更确保dom正常状态下作处理
							this.reSetCanvas() //重新设置画布，防止屏幕发生变化，如竖屏转横屏 
							if (this.signImgData) {
								this.signObj.fromDataURL(this.signImgData)
							}
						})
					}
				}
			},
			reSetCanvas() {
				let _canvas = this.$refs.pad
				const ratio = Math.max(window.devicePixelRatio || 1, 1)
				_canvas.width = _canvas.offsetWidth * ratio;
				_canvas.height = _canvas.offsetHeight * ratio;
				_canvas.getContext("2d").scale(ratio, ratio);
				return _canvas

			},
			createPad() {
				let option = this.writeOption
				let _canvas = this.reSetCanvas()

				this.signObj = new SignaturePad(_canvas, option)
			},
			destroySignaturePad() {
				let signaturePad = this.signObj
				if (!signaturePad) {
					return
				}
				// 清除画布内容  
				signaturePad.clear()

				// 如果有事件监听器，解除它们  
				signaturePad.onBegin = null
				signaturePad.onEnd = null

				// 解除对 canvas 元素的引用   
				signaturePad.canvas = null

				// 将 Signature Pad 实例设置为 null，以便垃圾回收  
				this.signObj = null
				this.isEdit = false
			}


		},
		computed: {
			imgData() {
				return this.signImgData || this.signData ||
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAD0lEQVR42mNkYGD4DwADhgGAWjR9awAAAABJRU5ErkJggg=='
			},
			signEdit() {
				let css = {
					width: this.padWidth,
					height: this.padHeight,
					backgroundColor: this.writeOption.backgroundColor,
					border: 'solid 1px #cccccc',
					borderRadius: '5px',
				}
				return css
			},
			padImg() {
				let css = {
					backgroundColor: this.writeOption.backgroundColor
				}
				if (this.allowEdit) {
					css.cursor = 'pointer'
				} else {
					css.cursor = 'not-allowed'
				}
				return css

			},
			writeOption() {
				let option = {
					...this.option
				}
				for (let key in signOption) {
					if (!(key in option)) {
						options[key] = signOption[key]
					}
				}
				return option
			}
		},
		watch: {
			option(nv, ov) {
				if (this.signObj) { //重置
					//this.destroySignaturePad()
					//this.createPad()
					let cfg = this.writeOption
					for (let key in cfg) { //重置属性

						this.signObj[key] = cfg[key]
					}
				}

			}
		},
		components: {

		},
		unmounted() {
			this.destroySignaturePad()
		},
		created() {



		}
	}
</script>

<style scoped>
	.sign-pad {
		width: 100%;
		height: 100%;
		min-height: 30px;
		border: solid 1px #cccccc;
		border-radius: 5px;
	}

	.no-border {
		border: 0px;
	}

	.sign-img {
		width: 100%;
		height: 100%;
		border: 0px;
		object-fit: scale-down;
	}



	.sign-edit {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 200;
		background-color: rgba(200, 200, 200, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;

	}



	.sign-write {
		/* 笔画偏移问题
		display: block; */
		height: calc(100% - 40px);
	}

	.sign-tool {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		background-color: rgba(200, 200, 200, 0.5);
	}

	.pad-canvas {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		cursor: crosshair;

	}
</style>