import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
let base=[
	
//	{key:'rectWidth',text:'宽度',type:'text',init:'100%',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
//	{key:'minW',text:'最小宽度',type:'text',init:'20px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
//	{key:'rectHeidth',text:'高度',type:'text',init:'100%',target:'',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
//	{key:'minH',text:'最小高度',type:'text',init:'20px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
//{key:'borderRadius',text:'圆角大小',type:'text',init:'',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 

 {key:'borderSize',text:'边框粗细',type:'text',init:'2px',target:'--border-size',bursh:true,bind:true,tip:'边框线粗，象素',list:null},
 {key:'borderColor',text:'边框颜色',type:'colorA',init:'#00ffff',target:'--border-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
 {key:'shadowColor',text:'边框光影色',type:'color',init:'#00ffff',target:'',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
 {key:'shadowSize',text:'光影大小',type:'number',init:'10',target:'',bursh:true,bind:true,tip:'阴影扩散距离',list:null},
 {key:'twinkling',text:'光影闪动',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'duration',text:'闪动频率(S)',type:'number',init:'0.5',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'backColor',text:'背景颜色',type:'colorA',init:null,target:'--back-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
 
 
 
 	{key:'title',text:'小球属性'},
	{key:'roundTime',text:'旋转周期(S)',type:'number',init:'2',target:'',bursh:true,bind:true,tip:'光影旋转一周的时间（秒）',list:null},
	{key:'ballSize',text:'小球半径',type:'text',init:'10px',target:'--ball-size',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},

	{key:'ballBackColor',text:'小球颜色',type:'colorA',init:'#ffffff',target:'--ball-back-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	{key:'ballBorderColor',text:'小球边框色',type:'color',init:'#F2F6FC',target:'--ball-border-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	{key:'ballLineSize',text:'小球边粗细',type:'text',init:'2px',target:'--ball-line-size',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},

	{key:'ballShadowColor',text:'小球光影色',type:'color',init:'#78FFCD',target:'--ball-shadow-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	{key:'ballShadowSize',text:'光影大小',type:'text',init:'10px',target:'--ball-shadow-size',bursh:true,bind:true,tip:'阴影扩散距离',list:null},







	

   // {key:'borderRadius',text:'圆角大小',type:'text',init:'6px',target:'--border-radius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 
	//{key:'flowSpeed',text:'流速',type:'number',init:80,target:'',bursh:true,bind:true,tip:'1-100之间',list:null},
	


	
	
]
let panel= {
	data:{
		type:'panel_dzxy',
	},
	delete:'',
	hide:'width,height,borderRadius',
	base:base,//layout.concat(font) ,
	special :[],
	action:[]//options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default panel