<template>
	<actorSet ref="actorSet" @ok="actorSetOk" :project="project" />
	<span v-if="objList && objList.length>0" @click="toSetActor" class="items">
		<el-tag v-for="obj in objList" :type="obj.isObj?'success':''" effect="dark" size="default" style="margin: 5px;"
			:title="obj.tip">
			{{obj.name}}

		</el-tag>
	</span>
	<el-link  v-else @click="toSetActor" class="items" type="primary">{{emptyText}}</el-link>

</template>

<script>
	import {
		computed
	} from 'vue'
	import ActorSet from './ActorSet.vue'
	export default {
		props: {
			project: {
				type: Object,
				default: {}
			},
			accessName: { //授权名称
				type: String,
				default: '权限参与人'
			},
			dataItem: { //授权对象所有的对象
				type: Object,

			},
			paramName: { //授权对象所在的属性名称
				type: String,
				default: 'actorItems'
			},
			emptyText: {
				type: String,
				default: '全部'
			}
		},
		data() {
			return {

			}
		},

		methods: {
			actorSetOk(item, target) {
				this.dataItem[this.paramName] = item.actorItems
			},
			toSetActor(row, key) {

				let item = {
					groupName: this.accessName,
					actorItems: this.objList,
					target: {}
				}
				this.$refs.actorSet.toSetActor(item)
			},
		},
		computed: {
			objList() {
				let items = null
				if (this.dataItem && this.paramName) {
					items = this.dataItem[this.paramName]
				}
				if (items) {
					return items
				} else {
					return []
				}
			}
		},
		components: {
			actorSet: ActorSet
		},
		created() {


		}
	}
</script>

<style scoped>
	.items {
		cursor: pointer;
	}
</style>