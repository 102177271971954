<template>

	<!-- el-tooltip 之外放一层DIV，否则会报警告： Runtime directive used on component with non-element root node. The directives will not function as intended
 -->
	<!-- <div v-if="model.tipAllow=='Y' " :style="container" @click.stop="mouseClick" @mouseenter.stop="mouseEnter"
		@mouseleave.stop="mouseLeave" @mouseup="mouseUp" @mousedown.stop="mouseDown">
		</div> -->

	<template v-if="model.tipAllow=='Y' ">
		<el-tooltip :visible="model.tipShow=='Y'" :content="model.tipContent" :effect="model.tipEffect"
			:placement="model.tipPosition">

			<simplecomp v-if="'label,link,tag,icon,image,button,submit,dayin,divider,'.indexOf( model.type)>-1 "
				:model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<qrcode v-else-if="model.type=='qr'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<barcode v-else-if="model.type=='barcode'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<qrread v-else-if="model.type=='qrread'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<signpad v-else-if="model.type=='signpad'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<checkcode v-else-if="model.type=='imgcode' || model.type=='smscode'" :model="model" :project="project"
				:page="page" :host="host" :isedit="isedit" :datas="datas" />

			<inputtext
				v-else-if="'input,hidden,text,textselect,area,datetime,date,dateR,time,timeR,sliderS,sliderM,number,iconinput,colorinput,'.indexOf( model.type)>-1"
				:model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<!-- 	<inputnumber v-else-if="model.type=='number'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" /> -->
			<inputselect v-else-if="'radio,check,selectS,selectM,switch'.indexOf( model.type)>-1" :model="model"
				:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<inputtransfer v-else-if="'transfer'== model.type" :model="model" :project="project" :page="page"
				:host="host" :isedit="isedit" :datas="datas" />
			<inputupload v-else-if="'upload'== model.type" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<treedialog v-else-if="model.type=='treedialog' || model.type=='orgdialog'" :model="model"
				:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<treelistdialog v-else-if="model.type=='treelist' || model.type=='userdialog'" :model="model"
				:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<userorg v-else-if="model.type=='userorg'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<usercurrent v-else-if="model.type=='usercurrent'" :model="model" :project="project" :page="page"
				:host="host" :isedit="isedit" :datas="datas" />
			<suggest v-else-if="model.type=='suggest'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<excelimport v-else-if="'excelimport'== model.type || 'fileimport'== model.type" :model="model"
				:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
			<imgupload v-else-if="'imgupload'== model.type" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
			<div v-else>
				未知元素{{model.type}}
			</div>



		</el-tooltip>
	</template>

	<!-- <div v-else :style="container" @click.stop="mouseClick" @mouseenter.stop="mouseEnter" @mouseleave.stop="mouseLeave"
		@mouseup="mouseUp" @mousedown.stop="mouseDown">
		</div> -->
	<template v-else>
		<simplecomp v-if="'label,link,tag,icon,image,button,submit,dayin,divider,'.indexOf( model.type)>-1 "
			:model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<qrcode v-else-if="model.type=='qr'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<barcode v-else-if="model.type=='barcode'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<qrread v-else-if="model.type=='qrread'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<signpad v-else-if="model.type=='signpad'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<checkcode v-else-if="model.type=='imgcode' || model.type=='smscode'" :model="model" :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="datas" />

		<inputtext
			v-else-if="'input,hidden,text,textselect,area,datetime,date,dateR,time,timeR,sliderS,sliderM,number,iconinput,colorinput,'.indexOf( model.type)>-1"
			:model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<!-- 	<inputnumber v-else-if="model.type=='number'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" /> -->
		<inputselect v-else-if="'radio,check,selectS,selectM,switch'.indexOf( model.type)>-1" :model="model"
			:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<inputtransfer v-else-if="'transfer'== model.type" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<inputupload v-else-if="'upload'== model.type" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<treedialog v-else-if="model.type=='treedialog' || model.type=='orgdialog'" :model="model" :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="datas" />
		<treelistdialog v-else-if="model.type=='treelist' || model.type=='userdialog'" :model="model" :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="datas" />
		<userorg v-else-if="model.type=='userorg'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<usercurrent v-else-if="model.type=='usercurrent'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<suggest v-else-if="model.type=='suggest'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<timetask v-else-if="model.type=='timetask'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<excelimport v-else-if="'excelimport'== model.type || 'fileimport'== model.type" :model="model"
			:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<imgupload v-else-if="'imgupload'== model.type" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<div v-else>
			未知{{model.type}}元素
		</div>
	</template>

</template>

<script>
	import formBase from '../../../formbase.js'
	import simpleComp from './SimpleComp.vue'
	import inputText from './InputText.vue'
	//import inputNumber from './InputNumber.vue'
	import inputSelect from './InputSelect.vue'
	import transfer from './Transfer.vue'
	import inputUpload from './InputUpload.vue'
	import TreeDialog from './TreeDialog.vue'
	import TreeListDialog from './TreeListD.vue'
	import UserOrg from './UserOrg.vue'
	import UserCurrent from './UserCurrent.vue'
	import Suggest from './Suggest.vue'
	import QrCode from './QrCode.vue'
	import BarCode from './BarCode.vue'
	import QrRead from './QrRead.vue'
	import SignPad from './SignPad.vue'
	import CheckCode from './CheckCode.vue'
	import TimeTask from './TimeTask.vue'
	import ExcelImport from './ExcelImport.vue'
	import ImgUpload from './ImgUpload.vue'

	export default {
		mixins: [formBase],
		data() {
			return {
				superComp: true
			}
		},

		methods: {
			init() { //清空数据, 获取组件定义配置项，取出数据库字段属性关联的字段的初始值
				let fieldMap = {}
				for (let item of this.elementConfig.objs[this.model.type].configs) {
					if (item.type === 'field') {
						fieldMap[item.attribute] = true
					}
				}
				for (let item of this.elementConfig.objs[this.model.type].configs) {
					if (fieldMap[item.key]) {
						this.model[item.key] = item.init
						if (this.model.$set) {
							this.model.$set(item.key, item.init)
						}
						/*if (item.init) {
							
							 if (typeof(item.init) == 'object') {
								this.model[item.key] = this.$logic.clone.deepClone(item.init)
							} */
					}

				}

			},
			mouseUp() {
				this.createObj(this.newComp, this.model)
			}

		},
		watch: {
			'model.tipShow'(newValue, oldValue) {
				if (newValue === 'Y') { //显示状态的时候
					let tipTime = this.model.tipTime
					if (tipTime > 0 && tipTime <= 1000) {
						setTimeout(() => {
							this.setData('tipShow', 'N')
						}, 1000 * tipTime)
					}

				}
			}
		},
		components: {
			checkcode: CheckCode,
			qrcode: QrCode,
			barcode: BarCode,
			qrread: QrRead,
			signpad: SignPad,
			excelimport: ExcelImport,
			simplecomp: simpleComp,
			inputtext: inputText,
			//	inputnumber: inputNumber,
			inputselect: inputSelect,
			inputtransfer: transfer,
			inputupload: inputUpload,
			treedialog: TreeDialog,
			treelistdialog: TreeListDialog,
			userorg: UserOrg,
			usercurrent: UserCurrent,
			suggest: Suggest,
			timetask: TimeTask,
			imgupload: ImgUpload,


		},
		computed: {
			/* 		container() {
						let css = this.baseStyle
						if (this.isedit) { //设计模式忽略本层的宽度高度，填满外层容器,防止与父组件的属性重叠，产生加倍结果
							let css2 = {
								margin: '0px',
							}
							if (css.width) {
								css2.width = '100%'
							}
							if (css.height) {
								css2.height = '100%'
							}
							css = css2

						}
						return css
					} */
		},


		created() {
			if (this.model.isInput) {
				//this.model.$init = this.init() //特殊的子组件自行写init方法，在子组件的created事件中替换
				//console.log(  this.model.value)
			}


		}
	}
</script>

<style scoped>

</style>