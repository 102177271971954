<template>

	<!-- 动感地带 -->
	<svg v-for="block in ['datav_left-top','datav_left-bottom','datav_right-top','datav_right-bottom']"
		:class="block+' datav_block'" :style="hornStyle">
		<polygon :fill="color1"
			points="6,66 6,18 12,12 18,12 24,6 27,6 30,9 36,9 39,6 84,6 81,9 75,9 73.2,7 40.8,7 37.8,10.2 24,10.2 12,21 12,24 9,27 9,51 7.8,54 7.8,63">
			<animate attributeName="fill" :values="color1+';'+color2+';'+color1" dur="0.5s" begin="0s"
				repeatCount="indefinite"></animate>
		</polygon>


		<polygon :fill="color2" points="27.6,4.8 38.4,4.8 35.4,7.8 30.6,7.8">
			<animate attributeName="fill" :values="color2+';'+color1+';'+color2" dur="0.5s" begin="0s"
				repeatCount="indefinite"></animate>
		</polygon>

		<polygon :fill="color1" points="9,54 9,63 7.2,66 7.2,75 7.8,78 7.8,110 8.4,110 8.4,66 9.6,66 9.6,54">
			<animate attributeName="fill" :values="color1+';'+color2+';transparent'" dur="1s" begin="0s"
				repeatCount="indefinite"></animate>
		</polygon>

	</svg>
</template>

<script>
	export default {
		props: {
			color1: {
				type: String,
				default: '#4fd2dd'
			},
			color2: {
				type: String,
				default: '#235fa7'
			},
			panelWidth: {
				type: Number,
				default: 200
			},
			panelHeight: {
				type: Number,
				default: 100
			},
			maxHornWidth: {
				type: Number,
				default: 150
			},
			maxHornHeight: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {

			}
		},

		methods: {

		},
		computed: {
			hornStyle() {
				/* 	let minSize = this.panelWidth > this.panelHeight ? this.panelHeight : this.panelWidth
					let hornSize=minSize/2 */
				let hornHeight = this.panelHeight / 2 - 10 //留点空隙
				let hornWidth = this.panelWidth / 2
				/* if (hornHeight > this.maxHornHeight) {
					hornHeight = this.maxHornHeight
				} */
				if (hornWidth > this.maxHornWidth) {
					hornWidth = this.maxHornWidth
				}
				let css = {
					'--horn-width': hornWidth + 'px',
					'--horn-height': hornHeight + 'px'
				}
				return css

			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.datav_block {
		width: var(--horn-width);
		height: var(--horn-height);
		position: absolute;
		pointer-events: none;
	}
 
	.datav_right-top {
		right: 0px;
		transform: rotateY(180deg);
	}

	.datav_left-bottom {
		bottom: 0px;
		transform: rotateX(180deg);
	}

	.datav_right-bottom {
		right: 0px;
		bottom: 0px;
		transform: rotateX(180deg) rotateY(180deg);
	}
</style>