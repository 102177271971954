import compBase from '../../public/comp.js'
import options from '../../public/options.js'
 

let base=[
{key:'title',text:'流程提交'},
	{key: 'title',text: '提交确认'	},
	{key: 'ask',text: '提交前确认',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '提交前是否开启对话确认',list: null},
	{key: 'message',text: '确认提示',type: 'text',init: '是否确定提交',	target: '',	bursh: true,bind: true,tip: '提交前的对话确认提示消息',list: null},
	{key: 'tell',text: '提交后提示',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '提交后是否提示成功信息',list: null},
	{key: 'tip',text: '成功提示',type: 'text',init: '已成功提交',	target: '',	bursh: true,bind: true,tip: '提交成功后的提示消息',list: null},

	{key: 'title',text: '提交按钮样式'	},
	{key: 'value',text: '按钮文本',type: 'text',init: '提交',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'ico',text: '图标',type: 'icona',init: 'far fa-check',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'color',text: '颜色风格',type: 'select',init: 'success',target: '', bursh: true,bind: true,tip: '',list: compBase.colorTypes},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
	{key: 'link',text: '链接样式',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'plain',text: '朴素样式',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'round',text: '是否圆角',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'circle',text: '是否圆形',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'loading',text: '加载中状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	

{key: 'dataTo',text: '审批传值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '审批记录传送给另一个列表组件，组件列的数据名称设置为审批记录数据的属性名称，属性名称参见开发指南，数据加载完成后触发，多组件ID请用逗号分隔，格式：e1,e2,e3',list: null},
{key: 'lastTo',text: '终审绑定',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '各节点的最后审批记录数据集合绑定到指定的容器,通过流程节点ID引用如node1.userName',list: null},

{key: 'printerId',text: '打印容器',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '指定流程信息所在的打印容器ID，实现定制打印，设置此属性自动出现打印按钮',list: null},

{key: '_node',text: '流程节点',type: 'text',init: null,	target: '',	bursh: true,bind: true,tip:'',list:null},// options.toOptions('默认=,卡片=card,带边框卡片=border-card') 
{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
//{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},
//{key:'minH',text:'最小高度',type:'text',init:'200px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'_debugMode',text:'调试模式',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'_flowIns',text:'流程实例',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},

/*
{key:'labelShow',text:'显示标签',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelText',text:'标签文本',type:'text',init:'条件',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelColor',text:'标签颜色',type:'color',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'label',text:'标签对象',type:'json',init:{positionX:0,positionY:0,style:{}},target:'',bursh:true,bind:true,tip:'',list: null},
{key:'condition',text:'路径条件',type:'text',init:'',target:'',bursh:true,bind:true,tip:'Javascript语法，逻辑表达式',list: null}, 	
{key:'positionX',text:'节点X位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'positionY',text:'节点Y位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
 */

]
let events=[
/* 	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]

 
let flowsubmit= {
	data:{
		type:'flowsubmit', 
	},
	delete:'',
	hide:'$point,width,height,_node,_debugMode,_flowIns',
	base:base,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default flowsubmit