<template>

	<div ref="pageRef" v-show="model.show !=='none'" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
		@mouseup="mouseUp" :style="layoutstyle" :class="layoutcss">
		<template v-if="!isedit || pageMode=='page'">
			<template v-for="item in model.items">
				<template v-if="item.group==='layout'">
					<container v-if="item.type.indexOf('modal_')!=0" :model="item" :project="project" :page="page"
						:host="host" :isedit="isedit" :datas="bindData" />
				</template>

				<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />

			</template>
		</template>


		<template v-if="!isedit || pageMode=='hide'">

			<template v-for="item in model.items">
				<container v-if="item.type.indexOf('modal_')===0" :model="item" :project="project" :page="page"
					:host="host" :isedit="isedit" :datas="bindData" />
			</template>
		</template>


	</div>


</template>

<script>
	import FlowUtil from '../../../../../../plugins/flowUtil.js'
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		props: {
			pageMode: {
				type: String,
				default: 'page'
			}
		},
		emits: ['reload'],
		data() {
			return {
				bindData: null,

				pageItem: null,
				//pageLoading: false
			}
		},

		methods: {
			$reload(url = null, act = null) {
				let host = this.model.$hostElement
				if (host && !act) { //如果是子页面且没有指定行为，直接在子页面加载
					if (url) {
						host.$set('url', url)
					} else {
						host.$fresh()
					}

				} else {
					if (!url) { //没有url刷新当前页面
						location.reload();
						return
					}
					let x = url.indexOf('?')
					let pageKey = x > 0 ? url.substring(0, x) : url

					let pageParams = this.$logic.http.getRequestParam(null, url)

					if (true || act === 'redirect' || act === '_blank') { //地址转向，采用重定向方式，以实现浏览器的后退功能
						this.$logic.http.get(true, '/api/dev/form/getformcode/', {
							appId: this.page.appId,
							pageKey: pageKey,
							verionCode: this.page.appVersion
						}, res => {
							let formCode = res.data.data
							let to = '/run/' + formCode
							if (x > 0) {
								to = to + url.substring(x) //带参数
							}

							if (act === '_blank') {
								window.open(to)
							} else {
								window.location.href = to
							}


						})


					} else { //页面内容替换,需要处理数据初始化和事件太麻烦废弃此方法，
						/* let params = {
							appId: this.page.appId,
							formKey: pageKey,
							appVersion: this.page.appVersion
						}
								this.$logic.http.pageLoad(this.project, config, null, params, res => {
									this.pageLoading = true
									setTimeout(() => {
										this.bindData=null
										this.pageItem = res
										this.pageItem.$params = pageParams //子页面地址中的参数替换浏览器网址中的参数
										this.pageItem.formData._util_page.$params = pageParams //为编程用的page对象设置参数
										this.pageLoading = false
									}, 10)

								}, err => {
									this.pageLoading = false
									this.$logic.tip.error('加载失败 ' + err.info)
								}, false) */
					}

				}
			},
			flushWindowSize() {
				if (this.isedit) {
					return
				}

				let pageDom = this.$refs.pageRef
				if (pageDom) {
					let width = pageDom.offsetWidth
					let height = pageDom.offsetHeight

					this.setData('realWidth', width)
					this.setData('realHeight', height)
					this.doEvent({
						eventName: 'sizeChange',
						width: width,
						height: height
					}, '$comp_sizeChange')
				}


			},
			setPageScalce() { //设置是否允许页面缩放

				let host = this.model.$hostElement
				if (this.isedit || host) { //不是顶级页面不用处理
					return
				}
				let maxScale = this.model.scaleSize
				var meta = document.querySelector('meta[name="viewport"]');

				// 如果meta标签不存在，则创建一个  
				if (!meta) {
					meta = document.createElement('meta');
					meta.setAttribute('name', 'viewport');
					document.head.appendChild(meta);
				}

				// 设置viewport的content属性  
				meta.setAttribute('content',
					'width=device-width, initial-scale=1.0, maximum-scale=' + maxScale + ', user-scalable=yes');
			}

		},
		computed: {
			/* hasModal() {
				for (let item of this.model.items) {
					if (item.type.indexOf('modal_') === 0) {
						return true
					}
				}
				return false
			} */
		},
		mounted() {
			this.$nextTick(() => {
				this.flushWindowSize()
				setTimeout(() => { //加一点延迟，确保对象已实例化
					let user = this.$logic.getUser()
					FlowUtil.accessFilter(null, this.model, user.id, this.project)
					if (!this.isedit) {
						this.doEvent({
							eventName: 'mount'
						}, '$hook_mount')
					}
					//this.flushWindowSize()
				}, 50)

			})

		},
		/* 		beforeCreate(){
					
				
				}, */
		watch: {
			'$store.state.resizeTick'(nv, ov) { //APP.vue中设置window.onresize监听事件
				this.flushWindowSize()
			},
		},

		created() {
			this.model.$reload = this.$reload
			this.model.user = this.$logic.getSimpleUser()
			this.createInit()
			this.setPageScalce()

		}
	}
</script>

<style scoped>

</style>