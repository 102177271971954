import formBase from '../../../../formbase.js'
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import options from '../../../../config/public/options.js';


export default {
	mixins: [formBase],
	props: {
		dataSet: null,


	},
	data() {
		return {
			chartDom: null,
			catchOption: null,
			catchDataSet: [],

		}
	},

	methods: {
		registerMap(areaName) {
			if (this.mapArea[areaName]) { //如果已经存在不重复注册,直接重绘
				//this.updateOption()
				return
			}
			this.$logic.http.get(true, '/api/ds/getmapfile/', {
				areaName: areaName,
				tag: 'area'
			}, res => {
				this.$logic.http.get(true, '/api/ds/getmapfile/', {
					areaName: areaName,
					tag: 'point'
				}, rs => {
					echarts.registerMap(areaName, res.data)
					this.$store.commit('setMapArea', {
						name: areaName,
						data: rs.data
					})
					this.updateOption()


					if (this.isedit) {
						this.$logic.tip.success('已加载地图：' + areaName)
					}
				})

			})
		},


		updateOption(dataSet = null) {
			if (!this.catchOption) {
				this.catchOption = this.getNewOption()
			}
			let option = this.catchOption
			if (dataSet) {
				this.catchDataSet = dataSet
			}
			let ds = this.catchDataSet
			if (ds.length < 1) { //无效数据不处理
				return
			}
			option.dataset = {
				source: ds
			}
			let head = ds[0]

			let seriesList = []

			if (this.model.manualSeries === 'Y') { //不自动创建系列
				this.optionFilter(option) //各组件自行处理特殊配置值
			} else {
				for (let i = 1; i < head.length; i++) {
					let series = this.$logic.clone.deepClone(option._series)
					series.name = head[i]
					seriesList.push(series)
				}

				option.series = seriesList

				if (seriesList.length > 0) { //有效数据情况下处理
					this.optionFilter(option) //各组件自行处理特殊配置值
				}
			}
			if (option.series == null) { //系列为空表示不渲染
				return
			}
			//console.log(option)

			/* 	if(this.isedit){//开发模式下全部覆盖
					this.chartDom.setOption(option,true)
				}else{
					this.chartDom.setOption(option,{ replaceMerge: ['series','dataset'] })
				} */
			//this.chartDom.setOption(option,true)
			let keys = []
			for (let key in option) {
				if (typeof(option[key]) == 'object' && (!'length' in option[key])) { //所有非数组型对象作更新
					keys.push(key)
				}
			}

			//this.chartDom.setOption(option)
			//图表先要被实例化且不能全部替换模式更新数据，否则leged点击时会异常
			this.chartDom.setOption(option, {
				replaceMerge: keys
			})



		},
		optionFilter(option) { //处理种类组件特殊的配置项
			console.log('各组件需覆盖此方法')
		},
		doUpdate(data) {
			//BaseElement元素的公共计时器方法获取到数据投递给ChartComp的flushData方法（将此方法引用绑定给model对象，父子组件共享model属性），具体组件覆盖flushData实现自己的数据构建
			console.log('各组件需覆盖此方法')
		},
		loadInitData() {
			let data = null
			try {
				let ts = typeof(this.model.initData)
				data = ts === 'string' ? JSON.parse(this.model.initData) : this.model.initData
			} catch (ex) {
				this.$logic.tip.error('数据格式错误：' + ex.message)
				return
			}
			this.doUpdate(data)


		},
		getNewOption() {
			let option = {}
			let configs = this.getConfigs()
			for (let item of configs) {
				let obj = option
				if ('path' in item) {

					let ps = item.path.split('.')
					let param = ps[ps.length - 1] //默认指向最后一个具体属性值，如果是子对象属性会逐层被替换
					for (let i = 0; i < ps.length - 1; i++) { //最后一项是数值不作处理
						let part = ps[i]
						if (i < ps.length - 1) { //不是最后一项时创建前面的子对象

						}
						if (part) {
							if (!(part in obj)) { //不存在的子对象即创建
								let sub = {}
								obj[part] = sub
							}
							obj = obj[part]
						} else {
							alert('图表配置项错误')
							param = null
							return null
						}
					}

					if (item.type === 'switch' && item.list[0].value === 'Y') { //如果是是否类型转成布尔类型,否则直接输出
						obj[param] = this.model[item.key] === 'Y'
					} else if (this.model[item.key] === 'false') {
						obj[param] = false
					} else {
						let value = this.model[item.key]
						if (value !== null && !(item.type !== 'text' && value === '')) {
							if (item.type === 'number' && (typeof(value) === 'string') && value.length > 0) {
								value = parseFloat(value) //数字类型定义值转数值
							}
							obj[param] = value


						} else {
							if ((value === null || value === '') && item.init !== null) { //如果清除了当前内容，采用默认值
								value = item.init
								obj[param] = value
							} else { //不加到配置
								//	console.log(item.key, value, item.init)
								//	obj[param] =null
							}
							//空值啥也不干
						}
						if (this.model.darkMode === 'Y') { //如果值是定义的明暗模式值，自动作替换
							if (value === options.initColor.text.light) {
								obj[param] = options.initColor.text.dark
							} else if (value === options.initColor.line.light) {
								obj[param] = options.initColor.line.dark
							}
						} else {
							if (value === options.initColor.text.dark) {
								obj[param] = options.initColor.text.light
							} else if (value === options.initColor.line.dark) {
								obj[param] = options.initColor.line.light
							}
						}

					}

				}
			}
			//加避免含有极坐标无数据加载时不能执行到optionFilter方法，默认初始化一个初始值
			if ('polarCenterX' in this.model && this.model.type !== 'chart_radar' && this.model.type !==
				'chart_gauge' && this.model.type !== 'chart_funnel' && this.model.type !== 'chart_water') {
				option.polar = {
					center: ['50%', '50%']
				}
			}
			return option
		},
		resizeComp() {
			let obj = this.$refs['chart_' + this.model.id]
			if (obj && obj.clientWidth && obj.clientHeight) { //可视的情况下才触发重画
				if (this.chartDom && !this.chartDom.isDisposed()) {
					this.chartDom.resize()
					this.updateOption()
				}
			}

		},
		mouseClick(param) {
			//console.log(this)
			this.doEvent({
				eventName: 'click',
				param: param
			}, '$comp_click')
		},
		dblClick(param) {
			this.doEvent({
				eventName: 'dblclick',
				param: param
			}, '$comp_dblclick')
		},
		mouseOver(param) {
			this.doEvent({
				eventName: 'mouseover',
				param: param
			}, '$comp_mouseover')
		},
		mouseOut(param) {
			this.doEvent({
				eventName: 'mouseout',
				param: param
			}, '$comp_mouseleave')
		}

	},
	computed: {


	},
	watch: {
		'model': {
			deep: true,
			handler(nv, ov) { //所有的组件都会被触发
				if (this.isedit && this.isCurrent) { //编辑模式下对当前元素有效
					this.catchOption = this.getNewOption()
					setTimeout(() => { //新起线程执行，避免属性设置编辑时卡顿
						this.updateOption()
					}, 10)

				}
			}

		},
		'$store.state.resizeTick'() { //APP.vue中设置window.onresize监听事件
			this.resizeComp()

		},
		'model.mapArea'(newValue, oldValue) {
			setTimeout(() => { //新起线程执行，避免属性设置编辑时卡顿
				this.registerMap(newValue)
			}, 10)
		},
		'model.initData'(newValue, oldValue) {
			this.loadInitData()
		},
		dataSet(newValue, oldValue) {
			if (!this.chartDom || this.chartDom.isDisposed()) {
				return
			}

			if (newValue) { //有值成功，null为数据请求失败
				this.chartDom.hideLoading()
				this.doUpdate(newValue)
			} else {
				this.chartDom.showLoading()
			}

		}
	},
	mounted() {
		setTimeout(() => { //延迟生成图例，防止当前窗口没来得及初始化,图表渲染尺寸偏差
			let model = this.model
			let container = this.$refs['chart_' + model.id];
			this.chartDom = echarts.init(container)
			this.chartDom.setOption({
				backgroundColor: ''
			})
			//注册事件
			this.chartDom.on('click', this.mouseClick)
			this.chartDom.on('dblclick', this.dblClick)
			this.chartDom.on('mouseover', this.mouseOver)
			this.chartDom.on('mouseout', this.mouseOut)
			//先执行一次设置参数再更新数据，不然点击图例会报异常
			this.updateOption([
				[]
			])

			if (!this.isedit && model.loadAllow === 'Y') { //开启了自动加载时初始化时显示加载状态
				this.chartDom.showLoading()
			} else { //设置本地数据	
				if (model.initData) {
					//this.doUpdate(JSON.parse(model.initData))
					this.loadInitData()
				}

			}
		}, 300)


	},

	beforeUnmount() {
		if (this.chartDom) {
			this.chartDom.dispose()
		}
	},

}