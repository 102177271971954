import options from "./options.js"
import comp from "./comp.js"
let popTypes=[{label:'左键单击',value:'click'},{label:'鼠标悬停',value:'hover'},{label:'右键单击',value:'contextmenu'}]
let badgeTypes=[{label:'数字',value:'num'},{label:'文本',value:'text'},{label:'圆点',value:'dot'}]
let sizes=[{label:'较大',value:'large'},{label:'较小',value:'default'},{label:'最小',value:'small'}]
let datas =JSON.stringify( [
		{key:'item1',label:'菜单1',icon:'far fa-home-lg-alt',color:null,disable:'N', divided:'N' },
		{key:'item2',label:'菜单2',icon:'far fa-analytics',color:'#FF4500',disable:'N', divided:'N'},
		{key:'itme3',label:'分隔线',icon:'',color:null,disable:'N', divided:'Y'},
		{key:'item4',label:'不可用状态',icon:'far fa-file-chart-line',color:null,disable:'Y', divided:'N' },
	 	 
])
let badge = [

{key:'title',text:'徽标提示功能'},
{key:'allowBadge',text:'启用徽标',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list:options.YON},
{key:'badgeType',text:'徽标类型',type:'select',init:'num',target:'',bursh:true,bind:true,tip:'',list:badgeTypes},
{key:'badgeValue',text:'徽标内容',type:'text',init:'100',target:'',bursh:true,bind:true,tip:'',list:null},
{key:'maxValue',text:'最大数值',type:'number',init:'99',target:'',bursh:true,bind:true,tip:'',list:null},
{key:'colorType',text:'颜色类型',type:'select',init:'danger',target:'',bursh:true,bind:true,tip:'',list:comp.colorTypes2},
{key:'topOff',text:'顶部偏移量',type:'text',init:'10px',target:'',bursh:true,bind:true,tip:'',list:null},
{key:'rightOff',text:'右侧偏移量',type:'text',init:'20px',target:'',bursh:true,bind:true,tip:'',list:null},

]
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'key',label:'菜单标识',type:'text',default:'',list:[],field:'',width:'16%',unique:true},
	{key:'label',label:'菜单文本',type:'text',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'icon',label:'菜单图标',type:'icon',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'color',label:'菜单颜色',type:'color',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'disable',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'16%',unique:false},
	{key:'divided',label:'分割线',type:'switch',default:'N',list:[],field:'',width:'16%',unique:false},
]
let menu=[
	
	{key:'title',text:'弹出菜单'},
	{key:'allowMenu',text:'启用菜单',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'popType',text:'弹出方式',type:'select',init:'click',target:'',bursh:true,bind:true,tip:'',list:popTypes},
	{key:'clickHide',text:'点击后隐藏',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'menuSize',text:'外观大小',type:'select',init:'default',target:'',bursh:true,bind:true,tip:'',list:sizes},
	{key:'menuOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	
//	{key: 'listData',text: '按钮组数据',type: 'jsonArray',init: datas,	target: '',bursh: true,bind: true,tip: '',attribute:'inputOption' ,treeMap:{id:'key',label:'text',icon:'icon'},dataMap: {key:'唯一标识',text:'按钮文本',icon:'图标',type:'外观样式',enabeld:'可用状态',loading:'加载状态'}},
	
	{key:'menuData',text:'菜单数据',type:'jsonArray',init:datas,target: '',bursh:true,bind:true,tip: '',attribute:'menuOption'},//,treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',label:'菜单项文本',icon:'图标',color:'颜色',disable:'禁用状态',divided:'是否分隔线'}
	
	
]
let tip=[
	{key:'title',text:'气泡提示'},
	{key:'tipAllow',text:'启用气泡',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	//{key:'tipShowTrigger',text:'弹出方式',type:'select',init:'click',target:'',bursh:true,bind:true,tip:'',list:popTypes},
	//{key:'tipHideTrigger',text:'点击后隐藏',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'tipPosition',text:'出现位置',type:'select',init:'top',target:'',bursh:true,bind:true,tip:'',list:options.TipPositions},
	{key:'tipContent',text:'提示内容',type:'text',init:'提示内容',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'tipShow',text:'显示气泡',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:options.YON}, 
	{key:'tipEffect',text:'气泡样式',type:'select',init:'dark',target:'',bursh:true,bind:true,tip:'',list:options.toOptions('深色=dark,浅色=light')},
	{key:'tipTime',text:'持续时间(S)',type:'number',init:0,target:'',bursh:true,bind:true,tip:'持续显示气泡的时间,单位秒，0表示一直显示，到效值1到1000以内，将在设定时间之后自动关闭显示',list:null},
	//在BaseComp.Vue中监听tipShow的变化实现延迟关闭
]

let dialog=[
	{key:'title',text:'弹出对话框'},
	{key:'dialogId',text:'对话框ID',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list:null},
	
]
let targetTypes=[{label:'默认',value:''},{label:'地址栏转向',value:'redirect'},	{label:'弹出新窗口',value:'_blank'}]

let link=[
	{key:'title',text:'链接导航'},
	
	{key: 'url',text: '链接地址',type: 'area',init: '',	target: '',	bursh:true,bind:true,tip: '当前应用系统内页面Key,可带参数，如：list?id=1&type=2',list: null},//或外部网址
	{key: 'target',text: '链接行为',type: 'select',init: '',	target: '',	bursh:true,bind:false,tip: '如果需要浏览器的后退功能，使用地址栏转向,子页面中的链接忽略地址栏的设置',list: targetTypes},
	{key: 'containerId',text: '容器ID',type: 'text',init: '',	target: '',	bursh:true,bind:true,tip: '在指定的子页面容器内加载此链接，如果指定子页面容器，链接行为的设置失效',list: null},
	
]

let flow=[
	{key:'title',text:'工作流交互'},
	{key:'flowPage',text:'流程组件ID',type:'text',init:'',target:'',bursh:true,bind:false,tip:'当前页面上流程页面组件元素ID',list:null},
	{key:'flowIdParam',text:'ID字段名',type:'text',init:'',target:'',bursh:true,bind:false,tip:'绑定数据对象的ID字段，根据操作功能类型，为流程实例ID、流程任务ID，业务数据ID',list:null},	
	{key:'flowOpt',text:'操作功能',type:'select',init:'view',target:'',bursh:true,bind:false,tip:'',list:options.toOptions('流程查看=view,流程发起=start,处理任务=task,终止流程=cancel')},
	
	
]

let qywx=[
		{key:'title',text:'企微功能调用'},
		{key:'qwObj',text:'企微组件ID',type:'text',init:'',target:'',bursh:true,bind:false,tip:'',list:null},
		{key:'qwOpt',text:'操作功能',type:'select',init:'scan',target:'',bursh:true,bind:false,tip:'',list:options.toOptions('扫一扫=scan')},
		
		
]


export default {badge,menu,tip,dialog,flow,qywx,link}