
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
    {label:'选项一',value:'A',disabled:'N'},
	{label:'选项二',value:'B',disabled:'N'},
	{label:'选项三',value:'C',disabled:'Y'},
	{label:'选项四',value:'D',disabled:'N'}, 
	 
])
let styles=compBase.toOptions('方框=A,按钮=B')
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'

let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'选项文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'disabled',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'',unique:false},
 
]
let base = [
	{key: 'title',text: '下拉列表'	},

	{key: 'value',text: '选项值',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
	{key: 'label',text: '选项文本',type: 'text',init: '',	target: '',bursh: true,bind: true,read:true,tip: '',list: null},

	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

    {key: 'multiple',text: '是否多选',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
    {key: 'max',text: '最多选择数',type: 'number',init: '0',	target: '',	bursh: true,bind: false,tip: '值为0不限制数量',list: null},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key: 'tip',text: '空值提示',type: 'text',init: '请选择',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},
	{key: 'filtable',text: '启用过滤',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
   
   compBase.status2,

   {key: 'title',text: '自定义样式'	},
    {key:'compHeight',text:'自定义高度',type:'text',init:null,target:'--el-component-size',	bursh: true,bind: true,tip:'默认34px',list:null},
  
    {key:'bgColor',text:'背景颜色',type:'colorA',init:null,target:'--el-fill-color-blank',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},
    {key:'bgDisabledColor',text:'禁用背景色',type:'colorA',init:null,target:'--el-disabled-bg-color',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},

   {key:'borderColor',text:'边框颜色',type:'colorA',init:null,target:'--el-border-color',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},
   {key:'focusColor',text:'焦点边框色',type:'colorA',init:null,target:'--el-select-input-focus-border-color',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},
   {key:'disabledColor',text:'禁用边框色',type:'colorA',init:null,target:'--el-disabled-border-color',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},
   {key:'hoverColor',text:'悬停边框色',type:'colorA',init:null,target:'--el-select-border-color-hover',	bursh: true,bind: true,tip:'设置为rgba(255,255,255,0)即无边框',list:null},

   {key:'tColor',text:'提示语颜色',type:'colorA',init:null,target:'--el-text-color-placeholder',	bursh: true,bind: true,tip:'',list:null},
   {key:'arrowColor',text:'箭头颜色',type:'colorA',init:null,target:'--el-select-input-color',	bursh: true,bind: true,tip:'',list:null},
   {key:'arrowSize',text:'箭头大小',type:'text',init:null,target:'--el-select-input-font-size',	bursh: true,bind: true,tip:'默认14px',list:null},
 
   //{key:'itemColor',text:'选项文本色',type:'colorA',init:null,target:'--el-text-color-regular',	bursh: true,bind: true,tip:'默认14px',list:null},
  // {key:'itemDisabledColor',text:'禁用文本色',type:'colorA',init:null,target:'--el-disabled-text-color',	bursh: true,bind: true,tip:'默认14px',list:null},
  // {key:'itemSize',text:'选项字号',type:'text',init:null,target:'--el-font-size-base',	bursh: true,bind: true,tip:'默认14px',list:null},
 

]

let selectS = {
	data: {
		type: 'selectS',isInput:'S',
	},
	delete: '',
	hide: 'multiple,width,max,dataOption',//inputType
	base: base.concat(compBase.base),
	special: extend.tip.concat([
		//{key:'inputOption',text:'选项',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:'inputType'},
		//{key:'inputType',text:'输入方式',type:'text',init:'dataarray',target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'dataItem',text: '当前数据项',type: 'text',init: {},	target: '',bursh: true,bind: true,tip: '',read:true,list: null},
		
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:''},
		
		{key: 'listData',text: '选项数据',type: 'jsonArray',init: datas,	target: '',	bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},
		
		]),
	action:  options.getEvents(null,'$event_click,$event_change').concat([
		options.listLoadAfterEvents.dataListEvent,
		//options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default selectS
 