<template>
	<div class="doc-container" :style="docStyle">
		<div class="doc-left"> 
			<logictree :nodes="folderNodes" bar-height="33px" :allow-search="true" edit="add,rename,delete"
				:allow-edit="model.editorMode=='Y'" :allowDrag="false" :expanded="null" :expand-click="false"
				@init="treeInit" @nodeclick="nodeClick" @add="nodeAdd" @rename="nodeRename" @delete="nodeDelete">

			</logictree>

		</div>
		<div class="doc-right">
			<titlebar :title="nodeObj.textPath" icon="far fa-folder-open">
				<div @click="fullview=!fullview" class="iconbutton">
					<i :class="fullview?'far fa-compress-wide' : 'far fa-expand-wide'"></i>
				</div>

			</titlebar>
			<div class="doc-body">

				
					<container :model="nodeObj.data.model" :project="project" :page="page" :host="host"
						:isedit="isedit" :datas="datas" />
				
				<div v-if="! isedit" class="doc-foot">
					<div class="doc-foot-pre">
						<i class="fad fa-arrow-alt-to-left" style="font-size: 18px;margin-right: 5px;"></i>

						<el-link v-if="folderFoot.pre" @click="gotoPage(folderFoot.pre)" :underline="false"
							type="primary">上一篇：{{folderFoot.pre.label}}</el-link>
						<el-link v-else type="default" disabled>无</el-link>


					</div>
					<div class="doc-foot-next">
						<el-link v-if="folderFoot.next" @click="gotoPage(folderFoot.next)" :underline="false"
							type="primary">下一篇：{{folderFoot.next.label}}</el-link>
						<el-link v-else type="default" disabled>无</el-link>
						<i class="fad fa-arrow-alt-to-right" style="font-size: 18px;margin-left: 5px;"></i>
					</div>
				</div>
			</div>



		</div>

	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
//	import layoutBlock from '../layout/Block.vue'
	//import Block from '../../../LayoutBlock.vue'
	export default {
		mixins: [formBase],
		data() {
			return {
				fullview: false,
				folderFoot: {
					pre: null,
					next: null
				},
				folderList: [],
				folderNodes: [],
				nodeObj: {
					data: {
						model: null
					}
				},
				treeObj: null,

			}
		},

		methods: {
			gotoPage(node) {
				this.treeObj.ref.nodeClick(node.id)
			},
			flushFolderList() {
				let list = []
				let sk = [].concat(this.folderNodes)
				while (sk.length > 0) {
					let node = sk.pop()
					list.push(node)
					if (node.children && node.children.length > 0) {
						for (let i = node.children.length - 1; i > -1; i--) {
							sk.push(node.children[i])
						}
					}

				}
				this.folderList = list
			},
			createDoc() {
				let config = this.elementConfig
				let doc = config.create('row', null, this.page.formData)
				doc.name = '文档容器'
				doc.height = '100%'
				doc.alignV = 'flex-start'


				//	model.backColor = '#000000'
				doc.parent = this.model
				return doc
			},
			setEditorMode(nodeObj) {
				//设置页内元素的可编辑状态
				if (!nodeObj.data.model) {
					return
				}
				//设置样式
				let keys =
					'paddingL,paddingR,paddingT,paddingB,fontName,fontSize,fontColor,fontBold,fontLine,fontItalic,fontHeight'
				let doc = nodeObj.data.model
				for (let key of keys.split(',')) {
					doc[key] = this.model[key]
				}
				//设置编辑模式
				let sk = [].concat(nodeObj.data.model.items)
				while (sk.length > 0) {
					let el = sk.pop()
					if (el.group == 'doc' && ('editorMode' in el) && el.editorMode != this.model.editorMode) {
						el.editorMode = this.model.editorMode
					} 
					if (el.items) {
						for (let nd of el.items) {
							sk.push(nd)
						}
					}
				}
			},
			nodeClick(nodeObj) {
				if (this.nodeObj.data === nodeObj.data) {
					return
				}

				if (this.isedit) {
					this.$store.commit('setElement', nodeObj.data.model)
				} else {
					nodeObj.data.model.height = 'auto'
					nodeObj.data.model.minH = 'calc(100% - 30px - ' + this.model.paddingT + ' - ' + this.model.paddingB +
						' )'
				}
				this.setEditorMode(nodeObj)
			


				//上下翻页
				let pre = null
				let next = null
				for (let i = 0; i < this.folderList.length; i++) {
					let node = this.folderList[i]
					if (node === nodeObj.data) {
						//向上推有效内容节点
						for (let j = i - 1; j > -1; j--) {
							let p = this.folderList[j]
							if (p.model.items.length > 0) {
								pre = p
								break
							}
						}
						//pre=i>0? this.folderList[i-1]:null

						//向下推有效内容节点
						for (let j = i + 1; j < this.folderList.length; j++) {
							let p = this.folderList[j]
							if (p.model.items.length > 0) {
								next = p
								break
							}
						}
						//next=i<this.folderList.length-1? this.folderList[i+1]:null
						break
					}
				}
				this.folderFoot.pre = pre
				this.folderFoot.next = next
				this.nodeObj = nodeObj
				let items=nodeObj.data.model.items
				nodeObj.data.model.items=[]
				setTimeout(()=>{//下一周期输出，VUE一个BUG
					nodeObj.data.model.items=items
				},100)
				

			},
			nodeAdd(nodeObj, newName) {

				/* let model = config.creat('row', null)
				model.name = '文档容器'
				model.height = '100%'
				model.parent = this.model */
				let doc = this.createDoc()
				let nodeData = {
					id: 'n' + new Date().getTime(),
					label: newName,
					children: [],
					model: doc
				}
				nodeObj.data.children.push(nodeData)
				this.flushFolderList()


			},
			nodeRename(nodeObj, newName) {
				nodeObj.data.label = newName

			},
			nodeDelete(nodeObj) {
				let pData = nodeObj.node.parent.data
				for (let i = 0; i < pData.children.length; i++) {
					let data = pData.children[i]
					if (data.id == nodeObj.data.id) {
						pData.children.splice(i, 1)
						break
					}
				}
				this.flushFolderList()


			},
			treeInit(treeObj) {
				this.treeObj = treeObj
			}
		},
		computed: {
			docStyle() {
				let css = {
					'--doc-left-width': this.fullview ? '1px' : this.model.leftWidth
				}
				return css
			}
		},
		watch: {
			'model.editorMode': function(newValue, oldValue) {
				this.setEditorMode(this.nodeObj)
			}
		},

		components: {
		//	layoutBlock: layoutBlock,
		},
		mounted() {
			if (this.folderList.length > 0) {
				this.gotoPage(this.folderList[0])
			} else {
				this.treeObj.ref.nodeClick('0')
			}

		},
		created() { 
			let config = this.elementConfig
			this.folderNodes = this.model.modelTree
			if (!this.folderNodes[0].model) {
				let config = this.elementConfig
				let doc = this.createDoc()
				this.folderNodes[0].model = doc
			}
			//文档类初始化所有子元素的父节点属性与初始化数据
			/* 	 */
			this.nodeObj.data.model = this.folderNodes[0].model //初始化根节点
		 	this.flushFolderList()
			if (!this.isedit ) { //在编辑模式执行变更操作这句会引发属性栏组件异常
				this.model.editorMode = 'N'
			}
			
		}
	}
</script>

<style scoped>
	.doc-container {
		width: 100%;
		height: calc(100% - 0px);
		min-height: 100px;
		display: flex;

	}

	.doc-left {
		width: calc(var(--doc-left-width) - 2px);
		height: calc(100% - 2px);
		border: solid 1px #cccccc;
		overflow-y: auto;
		overflow-x: auto; 
	}

	.doc-right {
		width: calc(100% - var(--doc-left-width) - 4px);
		height: calc(100% - 1px);
		border-right: solid 1px #cccccc;
		border-bottom: solid 1px #cccccc;
		overflow: auto;
	}

	.iconbutton {
		height: 30px;
		padding: 0px 8px;
		border: solid 1px #cccccc;
		border-radius: 5px;
		background-color: #f2f2f2;
		font-size: 18px;
		cursor: pointer;
	}

	.iconbutton:hover {
		background-color: #ffffff;
	}

	.doc-body {
		width: 100%;
		height: calc(100% - 38px);

		overflow: auto;
	}

	.doc-foot {
		width: 100%;
		height: 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 18%;
		border-top: solid 1px #cccccc;
		background-color: #f2f2f2;
	}

	.doc-foot-pre {
		width: calc(50% - 5px);
		padding-left: 5px;
	}

	.doc-foot-next {
		text-align: right;
		width: calc(50% - 5px);
		padding-right: 5px;
	}
</style>
