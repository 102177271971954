<template>
	<htmlbook v-if="model.type=='book' " :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />
	<ckeditor v-else-if="model.type.indexOf('ck')==0" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<div v-else>未知文档元素{{model.type}}</div>

 
</template>

<script>
	import HtmlBook from './HtmlBook.vue'
	import CKeditor from './CkEditor.vue'
	//--import CKeditor from './CKDodument.vue'
	//--import ckInline from './CKInline.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		props: ["model", "datas", "isedit"],
		data() {
			return {
				superComp: true
			}
		},

		methods: {

		},
		components: {
			htmlbook: HtmlBook,
			ckeditor: CKeditor
			//--	ckclassic: ckClassic,
			//--	ckinline: ckInline
		},
		created() {


		}
	}
</script>

<style scoped>

</style>