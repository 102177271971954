import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 //,{label:'内嵌容器',value:'iframe'},{label:'页面容器',value:'subpage'},
//let targetTypes=[{label:'默认',value:''},{label:'地址栏转向',value:'redirect'},	{label:'弹出新窗口',value:'_blank'}]
let base = [
	{key: 'title',text: '链接'	},
	compBase.mode,
	{key: 'value',text: '文本',type: 'text',init: '链接文本',	target: '',bursh:true,bind:true,tip: '',list: null},
	{key: 'iconBefore',text: '前置图标',type: 'icona',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},
    {key: 'iconAfter',text: '后置图标',type: 'icona',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},
    {key: 'iconGap',text: '图标间距',type: 'text',init: '5px',	target: '',bursh:true,bind:true,tip: '',list: null},
    {key: 'iconColor',text: '图标颜色',type: 'color',init: null,	target: '',bursh:true,bind:true,tip: '',list: null},
    {key: 'iconSize',text: '图标大小',type: 'text',init: '14px',	target: '',bursh:true,bind:true,tip: '',list: null},
 
/* 	{key: 'url',text: '链接地址',type: 'area',init: '',	target: '',	bursh:true,bind:true,tip: '内部容器Id或外部网址',list: null},
	{key: 'target',text: '链接行为',type: 'select',init: '',	target: '',	bursh:true,bind:false,tip: '如果需要浏览器的后退功能，使用地址栏转向,子页面中的链接忽略地址栏的设置',list: targetTypes},
	{key: 'containerId',text: '容器ID',type: 'text',init: '',	target: '',	bursh:true,bind:true,tip: '在指定的子页面容器内加载此链接，如果指定子页面容器，链接行为的设置失效',list: null},
 */
].concat(extend.link).concat([
   compBase.color,
	{key: 'under',text: '自动划线',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},
     compBase.enabled,
	 {key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	 
])


let link = {
	data: {
		type: 'link',
	},
	delete: '',
	hide: 'mode',
	base: base.concat(font).concat(compBase.base),
	special: extend.badge.concat(extend.menu).concat(extend.tip),

	action:  options.getCommonEvents().concat([compBase.menuClick]).concat([
	//	options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(extend.dialog).concat(extend.flow).concat(extend.qywx).concat(options.getEvents(null,'$hook'))
}

export default link
