<template>
	<div v-show="model.show !='none'" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
		 :style="layoutstyle" :class="layoutcss">
		<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="bindData" />
		<container :model="model.items[1]" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="bindData" />

	</div>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [ layoutBase],
		data() {
			return { 
				bindData: null,
			}
		},

		methods: {

		},
		created() {
this.createInit()

		}
	}
</script>

<style scoped>
 
</style>