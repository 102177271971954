import options from './options.js'
let bold=[{label:'默认',value:''},{label:'常规',value:'normal'},{label:'加粗',value:'bold'}]
let italic=[{label:'默认',value:''},{label:'常规',value:'normal'},{label:'斜体',value:'italic'}]
let decoration=[{label:'默认',value:''},{label:'无',value:'none'},{label:'下划线',value:'underline'},{label:'删除线',value:'line-through'},{label:'闪烁',value:'blink'},{label:'上划线',value:'overline'}]
/* let fonts=[
	{label:'默认',value:''},	{label:'宋体',value:'SimSun'},{label:'黑体',value:'SimHei'},
{label:'微软雅黑',value:'Microsoft YaHei'},{label:'微软正黑',value:'Microsoft JhengHei'},
{label:'楷体',value:'KaiTi'},{label:'仿宋',value:'FangSong'},{label:'新宋体',value:'NSimSun'},
{label:'仿宋_GB2312',value:'FangSong_GB2312'},{label:'楷体_GB2312',value:'KaiTi_GB2312'},
{label:'新细明体',value:'PMingLiU'},{label:'细明体',value:'MingLiU'},{label:'标楷体',value:'DFKai-SB'}
]
let size=[{label:'默认',value:''}]
for(let i=10;i<90;i++){
	size.push({label:i+'号',value:i+'px'})
} */
let font = [
{key:'title',text:'字体样式'},
{key:'fontName',text:'字体',type:'select',init:'',target:'fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
{key:'fontSize',text:'字号',type:'select',init:'',target:'fontSize',bursh:true,bind:true,tip:'',list:options.fontSizes},	
{key:'fontColor',text:'颜色',type:'color',init:'',target:'color',tip:'',bursh:true,bind:true,list:null},	
{key:'fontBold',text:'粗体',type:'select',init:'',target:'fontWeight',bursh:true,bind:true,tip:'',list:bold},
{key:'fontLine',text:'修饰线',type:'select',init:'',target:'textDecoration',tip:'',bursh:true,bind:true,list:decoration},	
{key:'fontItalic',text:'斜体',type:'select',init:'',target:'fontStyle',tip:'',bursh:true,bind:true,list:italic},	
{key:'fontHeight',text:'行高',type:'text',init:'',target:'lineHeight',tip:'',bursh:true,bind:true,list:null},	
]
export default font