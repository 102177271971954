import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
let base=[
	
//	{key:'rectWidth',text:'宽度',type:'text',init:'100%',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
//	{key:'minW',text:'最小宽度',type:'text',init:'20px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
//	{key:'rectHeidth',text:'高度',type:'text',init:'100%',target:'',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
//	{key:'minH',text:'最小高度',type:'text',init:'20px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
//{key:'borderRadius',text:'圆角大小',type:'text',init:'',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 

 {key:'borderSize',text:'边框粗细',type:'text',init:'2px',target:'--border-size',bursh:true,bind:true,tip:'边框线粗，象素',list:null},
 {key:'borderColor',text:'边框颜色',type:'color',init:'#00ffff',target:'--border-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
{key:'capeColor',text:'顶角颜色',type:'color',init:'#2CD5FF',target:'--cape-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
//{key:'capeColore',text:'顶角颜色2',type:'color',init:'#2CD5FF',target:'--cape-colore',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
{key:'capeSize',text:'顶角大小',type:'text',init:'20px',target:'--cape-size',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
{key:'capeLine',text:'顶角线粗细',type:'text',init:'4px',target:'--cape-line',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},

{key:'shadowColor',text:'边框光影色',type:'color',init:'#00ffff',target:'',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
 {key:'shadowSize',text:'光影大小',type:'number',init:'0',target:'',bursh:true,bind:true,tip:'阴影扩散距离',list:null},
 {key:'twinkling',text:'光影闪动',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'duration',text:'闪动频率(S)',type:'number',init:'0.5',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'backColor',text:'背景颜色',type:'colorA',init:'',target:'--back-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
 
 






	

   // {key:'borderRadius',text:'圆角大小',type:'text',init:'6px',target:'--border-radius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 
	//{key:'flowSpeed',text:'流速',type:'number',init:80,target:'',bursh:true,bind:true,tip:'1-100之间',list:null},
	


	
	
]
let panel= {
	data:{
		type:'panel_sjjq',
	},
	delete:'',
	hide:'width,height,borderRadius',
	base:base,//layout.concat(font) ,
	special :[],
	action:[]//options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default panel