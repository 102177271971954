import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let fonts=[].concat(font)/* ,alignH */
let layouts=options.getConfigs(layout,'display,alignV,width,paddingL,paddingR,paddingT,paddingB,backColor,borderS,borderL,borderT,borderR,borderB,borderColor,borderRadius')

let lableSet=[
	{key:'title',text:'标签区样式'},
 	{key:'labelText',text:'标签文本',type:'text',init:'标签名称',target:'',bursh:true,bind:true,list:null},
  {key:'labelStar',text:'加星',type:'select',init:'N',target:'',bursh:true,bind:true,list:options.YON},
   {key:'autoAlign',text:'水平对齐',type:'autoalign',init:{xs:"flex-start",sm:"flex-start",md:"flex-end",lg:"flex-end"},target:null,bursh:true,bind:true,tip:'flex-start、flex-end、center、space-between、space-around',list:null},
   
   {key: 'iconBefore',text: '前置图标',type: 'icona',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},
   {key: 'iconAfter',text: '后置图标',type: 'icona',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},
   {key: 'iconGap',text: '图标间距',type: 'text',init: '5px',	target: '',bursh:true,bind:true,tip: '',list: null},
   {key: 'iconColor',text: '图标颜色',type: 'color',init: null,	target: '',bursh:true,bind:true,tip: '',list: null},
   {key: 'iconSize',text: '图标大小',type: 'text',init: '14px',	target: '',bursh:true,bind:true,tip: '',list: null},
   
 
]

 
let formlabel= {
	data:{
		type:'formlabel',alignH:'flex-end',alignV:'center',paddingT:'0px',paddingB:'0px'
	},
	delete:'',
	hide:'contentD,display',
	base:lableSet.concat(layouts).concat(fonts),
	special :[],
	action:[]
}

export default formlabel