
import options from "./options.js"
import DbConfig from '../../../../plugins/logicDB.js'
let toOptions=(ex)=>{
	let items=[]
	let parts=ex.split(',')
	for(let part of parts){
		let x=part.indexOf('=')
		let item={label:part.substring(0,x),value:part.substring(x+1)}
		items.push(item)
	}
	return items
}
let modes=[
	{label:'单元素',value:'S'},	{label:'元素组',value:'M'},
]
let statusTs=[
	{label:'正常',value:''},	{label:'只读',value:'read'},	{label:'禁用',value:'disabled'}
]
let statusOs=[
	{label:'正常',value:''},	{label:'禁用',value:'disabled'}
]

let borderStyles=[
	{label:'水平',value:'horizontal'},{label:'垂直',value:'vertical'}
]

let textTypes=toOptions('文本=text,数字=number,密码=password')//,电话=tel,邮件=email,网址=url
let colorTypes=[{label:'默认',value:'default'},{label:'理性蓝',value:'primary'},{label:'清纯绿',value:'success'},{label:'沉稳黄',value:'warning'},{label:'辣目红',value:'danger'},{label:'低调灰',value:'info'}]

let colorTypes2=[{label:'默认',value:'default'},{label:'理性蓝',value:'primary'},{label:'清纯绿',value:'success'},{label:'沉稳黄',value:'warning'},{label:'辣目红',value:'danger'},{label:'低调灰',value:'info'}]
let compTypes=[{label:'复合组件',value:'comp'},{label:'朴素组件',value:'simple'}]
let sizes=[{label:'大号',value:'large'},{label:'中号',value:'default'},{label:'小号',value:'small'}]
let mode={key:'mode',text:'模式',type:'select',init:'S',target:'', bursh: true,bind: false,tip:'单元素：静态的单个元素，多元素：根据Json数据动态生成的多个元素',list:modes}
let enabled={key:'enabled',text:'可用状态',type:'switch',init:'Y',target:'', bursh: true,bind: true,tip:'',list:options.YON}
let status={key:'status',text:'状态',type:'select',init:'',target:'', bursh: true,bind: true,tip:'read:只读，disabled:禁用,空字符:正常',list:statusTs}
let status2={key:'status',text:'状态',type:'select',init:'',target:'', bursh: true,bind: true,tip:'disabled:禁用,空字符:正常可用',list:statusOs}

let color=  {key: 'color',text: '颜色风格',type: 'select',init: 'primary',target: '', bursh: true,bind: true,tip: '',list: colorTypes}
let menuClick= {key: '$comp_menuclick',text: '菜单点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '赋能设置中启用菜单功能后生效',list: null	}
let closeBefore={key: '$comp_closebefore',text: '点击关闭前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '关闭前执行，如返回值为false阻止关闭',list: null	}
let closeAfter={key: '$comp_closeafter',text: '点击关闭后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '关闭后执行',list: null	}
let addBefore={key: '$comp_addBefore',text: '新项创建前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '关闭后执行',list: null	}
let addAfter={key: '$comp_addAfter',text: '新项创建后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '关闭后执行',list: null	}



/* let FieldConfigs=[
//{key:'title',text:'自动数据库交互'},	

{key:'dataAuto',text:'自动建字段',type:'switch',init:'N',target:'alignContent',tip:'',bursh:true,bind:false,list:options.YON},	
{key:'fieldText',text:'字段别名',type:'text',init:'',target:'',tip:'',bursh:false,bind:false,list:null},	
{key:'fieldName',text:'字段名称',type:'text',init:'',target:'',bursh:false,bind:false,tip:'数据库表中字段名称',list:null},	 
{key:'fieldType',text:'字段类型',type:'text',init:'',target:'',bursh:true,bind:false,tip:'数据库表中字段类型',list:DbConfig.fieldOptions},	 
{key:'fieldLen',text:'最大长度',type:'number',init:'255',target:'',bursh:true,bind:false,tip:'与数据字段长度一致',list:null},	 
{key:'minLen',text:'最小长度',type:'number',init:'0',target:'',bursh:true,bind:false,tip:'0不限制最小长度',list:null},	 

{key:'fieldTail',text:'小数位数',type:'text',init:'2',target:'',bursh:true,bind:false,tip:'数据库表中数值型数据小数位数',list:null},	 

{key:'dataDefault',text:'默认值',type:'text',init:'',target:'',tip:'初始化内容',bursh:false,bind:false,list:null},	
{key:'allowNull',text:'允许空值',type:'select',init:'Y',target:'',bursh:true,bind:false,tip:'数据库表中字段是否允许空值',list:options.YON},	 
{key:'fieldUnique',text:'全局唯一值',type:'select',init:'N',target:'',bursh:true,bind:false,tip:'数据库表中字段是否允许重复值',list:options.YON},	 


{key:'fieldReal',text:'实体字段名',type:'text',init:'',target:'',bursh:false,bind:false,tip:'数据库表中已存在的字段名称',list:null},	 
{key:'fieldCount',text:'字段数量',type:'number',init:'1',target:'',bursh:true,bind:false,tip:'只在数据类型为小数时生效',list:null},	 

]
 */
//一个组件可能有多个值，如日期区间组件,所有的单值组件返值属性名默认采用value,默认字段属性名为fieldName
//attributeValue指向返回值属性名,attributeField 指向字段属性名，fieldName字段名，unionType,refuseNull 这三个从数据表对话框中回写; check多值类型暂时以逗号隔的字符串返回，后期可扩展增加设置返回值方式，数组还是逗号隔的字符串
let getDataConfig=(attributes=[['value','fieldName']])=>{
	/* let rs=[]
	for(let item of attributes){
		rs.push({attributeValue:item[0],attributeField:item[1],fieldName:null,fieldType:null,unionGroup:null,unionName:null,refuseNull:null }) //
	}
	return rs */
	return {fieldName:null,fieldType:null,unionGroup:null,unionName:null,refuseNull:null }
}
 //组件中有多值的情况，分别对应每个值设置一个校验规则,param参数指定对应哪个值
let getValidate=(defaultType='text')=>{
	let validateSet={triggers:['submit'],info:null,position:'top',exclude:''}//exclude排除事件，有的组件如radio没有focus事件//change,input,blur
	let express={
		seted:false,
		type:defaultType,//string,int,num,datetime,date,time
		range:{min:null,max:null},	//字符串为长度，数据值为数据大小，时间日期为日期毫秒值大小
		baseInfo:'',//基本规则验证失败后的提示
		reg:{ //内容格式正则表达式	 //内置 电话号码|手机号|邮箱|身份证|年月日时分秒|年月日|时分秒|自定义
		     type:'',
			 regexp:'',
			 regInfo:''
		 },
		 customExpress:'',
		 customInfo:'' 
		 
		
	}
	 
	return {validateSet:validateSet, validateRule:express}
}
 
let HideFields='dataCfg,fieldText,fieldType,fieldTail,dataDefault,allowNull,fieldUnique,fieldReal,fieldCount'



let base=[
	//{key:'title',text:'元素宽度'},
	{key:'title',text:'相邻元素间距'},//5px 5px 2px 0px
	{key:'marginT',text:'外上间距',type:'text',init:'0px',target:'marginTop',bursh: true,bind: true,tip:'象素、百分比',list:null},
	{key:'marginB',text:'外下间距',type:'text',init:'0px',target:'marginBottom',bursh: true,bind: true,tip:'象素、百分比',list:null},	
	{key:'marginL',text:'外左间距',type:'text',init:'0px',target:'marginLeft',bursh: true,bind: true,tip:'象素、百分比',list:null},	
	{key:'marginR',text:'外右间距',type:'text',init:'0px',target:'marginRight',bursh: true,bind: true,tip:'象素、百分比',list:null},	
		 
]
let special=[]
let action=[]

let appendDbConfig=(srcConfig,index)=>{
/* 	let idx= 1
	if(index){
		idx=index
	}else if(index==0){
		idx=0
	}
	
	for(let i=FieldConfigs.length-1;i>-1;i-- ){
		let item=FieldConfigs[i]
		srcConfig.splice(idx,0,item) 
	} */
}
export default {mode,enabled,status,status2,sizes,colorTypes:colorTypes,colorTypes2:colorTypes2,compTypes:compTypes,color,textTypes,toOptions,base:base,special:special,action:action,HideFields,appendDbConfig,getDataConfig,getValidate,menuClick,closeBefore,closeAfter,addBefore,addAfter}