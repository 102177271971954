import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
let borders=options.toOptions('类型1=item1,类型2=item2,类型3=item3,类型4=item4,类型4=item4,类型5=item5,类型6=item6,类型7=item7,类型8=item8,类型9=item9,测试1=rainbow')
let base=[
	 
	{key:'color1',text:'边框主色',type:'color',init:'#4fd2dd',target:'borderColor',bursh:true,bind:true,tip:'边框颜色',list:null},	 
 	{key:'color2',text:'边框从色',type:'color',init:'#235fa7',target:'borderColor',bursh:true,bind:true,tip:'边框颜色',list:null},	 
	
	
	
]
let dvbox1= {
	data:{
		type:'dv_box1',
	},
	delete:'',
	hide:'display,flexD,flexW,position',
	base:base,//layout.concat(font) ,
	special :[],
	action:[]//options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default dvbox1