import dataClone from '../../../plugins/deepClone.js'
import options from './public/options.js'

import Event from './public/event.js'


import font from "./public/font.js"

import base from './public/base.js'
import page from './form/layout/page.js'

import form from './form/layout/form.js'
import table from './form/layout/table.js'
import tabpage from './form/layout/tabpage.js'
import tabitem from './form/layout/tabitem.js'
import tr from './form/layout/tr.js'
import td from './form/layout/td.js'
import clone from './form/layout/clone.js'
import subpage from './form/layout/subpage.js'
import leftright from './form/layout/leftright.js'
import topbottom from './form/layout/topbottom.js'

import autocol from './form/layout/autocol.js'
import row from './form/layout/row.js'
import col from './form/layout/col.js'
import print from './form/layout/print.js'

import dialog from './form/layout/modal/dialog.js'
import drawer from './form/layout/modal/drawer.js'
//import panel from './form/layout/panel.js'
import panel_lgyc from './form/layout/panel/panel_lgyc.js'
import panel_wryd from './form/layout/panel/panel_wryd.js'
import panel_mlqq from './form/layout/panel/panel_mlqq.js'
import panel_fkgsc from './form/layout/panel/panel_fkgsc.js'
import panel_dzxy from './form/layout/panel/panel_dzxy.js'
import panel_sjjq from './form/layout/panel/panel_sjjq.js'
//DataV组件
import dv_box1 from './form/layout/datav/dvbox1.js'
import dv_box7 from './form/layout/datav/dvbox7.js'
import dv_box11 from './form/layout/datav/dvbox11.js'
import dv_box12 from './form/layout/datav/dvbox12.js'
import dv_box13 from './form/layout/datav/dvbox13.js'


//import formrow from './form/layout/formrow.js'
//import formitem from './form/layout/formitem.js'
import formfield from './form/layout/formfield.js'
import formlabel from './form/layout/formlabel.js'
import formcomp from './form/layout/formcomp.js'

import label from './form/base/label.js'
import link from './form/base/link.js'
import tag from './form/base/tag.js'
import icon from './form/base/icon.js'
import iconinput from './form/base/iconinput.js'
import colorinput from './form/base/colorinput.js'
import button from './form/base/button.js'
import submit from './form/base/submit.js'
import pringButton from './form/base/print.js'
import image from './form/base/image.js'
import imgupload from './form/base/imgupload.js'
import input from './form/base/input.js'
import hidden from './form/base/hidden.js'
import text from './form/base/textbox.js'
import textSelect from './form/base/textSelect.js'
import area from './form/base/textarea.js'
import number from './form/base/number.js'
import radio from './form/base/radio.js'
import check from './form/base/check.js'
import selectS from './form/base/selectS.js'
import selectM from './form/base/selectM.js'
import switchE from './form/base/switch.js'
import datetime from './form/base/datetime.js'
import dt from './form/base/date.js'
//import dtr from './form/base/dater.js'
import tm from './form/base/time.js'
//import tmr from './form/base/timer.js'
import sliderS from './form/base/sliderS.js'
import sliderM from './form/base/sliderM.js'
import transfer from './form/base/transfer.js'
import divider from './form/base/divider.js'
import upload from './form/base/upload.js'
import imgcode from './form/base/checkcode.js'
import smscode from './form/base/smscode.js'

import qrcode from './form/base/qrcode.js'
import qrRead from './form/base/qrRead.js'
import barcode from './form/base/barcode.js'
import treedialog from './form/base/TreeViewDialog'
import treelist from './form/base/TreeListDialog'
import suggest from './form/base/suggest.js'
import timetask from './form/base/timetask.js'
import orgdialog from './form/base/orgtreedialog.js'
import userdialog from './form/base/orguserdialog.js'
import userorg from './form/base/userorg.js'
import usercurrent from './form/base/usercurrent.js'
import signpad from './form/base/signpad.js'
import excelImport from './form/base/excelImport.js'
import fileImport from './form/base/fileImport.js'


///////////////////////////////////////////////////////Wrap
import bread from './form/wrap/breadcrumb.js'
import menu from './form/wrap/menu.js'
//import list from './form/wrap/list.js'
import inputlist from './form/wrap/inputlist.js'
import inputhead from './form/wrap/inputhead.js'
import listfoot from './form/wrap/listfoot.js'

import treeview from './form/wrap/treeview.js'
import calendar from './form/wrap/calendar.js'

//报表组件
import tableHead from './form/report/sub/tablehead.js'
import reportCol from './form/report/reportcol.js'
import commTable from './form/report/commtable.js'
import treeTable from './form/report/treetable.js'
import foldTable from './form/report/foldtable.js'

//图表组件
import wordratate from './form/chart/part/WordRotate.js'
import chartBar from './form/chart/echarts/grid/comp/chartbar.js'

import chartBarC from './form/chart/echarts/grid/comp/chartbarC.js'
import chartBarH from './form/chart/echarts/grid/comp/chartbarH.js'
import chartBarB from './form/chart/echarts/grid/comp/chartbarB.js'
import chartLine from './form/chart/echarts/grid/comp/chartline.js'
import chartBarLine from './form/chart/echarts/grid/comp/chartbarline.js'
import chartPie from './form/chart/echarts/polar/comp/chartpie.js'
import chartPieS from './form/chart/echarts/polar/comp/chartpieS.js'
import chartPieM from './form/chart/echarts/polar/comp/chartpieM.js'
import chartBarR from './form/chart/echarts/polar/comp/chartbarR.js'

import chartRadar from './form/chart/echarts/polar/comp/chartradar.js'
import chartGauge from './form/chart/echarts/polar/comp/chartgauge.js'
import chartFunnel from './form/chart/echarts/polar/comp/chartfunnel.js'

import mapArea from './form/chart/echarts/map/comp/maparea.js'
import mapPoint from './form/chart/echarts/map/comp/mappoint.js'
import mapLine from './form/chart/echarts/map/comp/mapline.js'
import chartWater from './form/chart/echarts/polar/comp/chartwater.js'

//DOC组件
import book from './form/doc/book.js'
import ckclasic from './form/doc/ckedit_classic.js'
import ckinline from './form/doc/ckedit_inline.js'
import ckballoon from './form/doc/ckedit_balloon.js'
import ckdocument from './form/doc/ckedit_document.js'

//流程组件
import workFlow from './form/flow/workflow.js'
import flowNode from './form/flow/flownode.js'
import flowLine from './form/flow/flowline.js'
import flowForm from './form/flow/flowform.js'
import flowModule from './form/flow/flowmodule.js'
import flowSubmit from './form/flow/flowsubmit.js'
import flowPage from './form/flow/flowpage.js'


//业务组件
import gantt from './form/custom/gantt.js'
import pm from './form/custom/pm.js'

import qywx from './form/action/qiyeweixin.js'

class Config {
	#fontConfig = null
	#objs = null;
	#maxId = 0;
	#comps = null
	#compMap = null
	constructor() {
		this.fontConfig = font
		this.objs = {};
		this.maxId = 0;
		this.comps = {
			layout: [
				['row', 'browser', '行容器'],
				['col', 'columns', '列容器'],
				['form', 'arrow-square-up', '表单容器'],
				['formfield', 'columns', '表单域'],
				['table', 'th-list', '表格容器'],
				['tabpage', 'window', '标签页容器'],
				['tabitem', 'window-maximize', '标签项', false],
				['subpage', 'external-link-alt', '页面容器'],
				['clone', 'object-ungroup', '模板容器'], //自举容器

				['leftright', 'arrows-alt-h', '左右分栏'],
				['topbottom', 'arrows-alt-v', '上下分栏'],


				['autocol', 'phone-laptop', '响应式容器'],
				['print', 'print', '打印容器'],

				['tr', 'rectangle-wide', '表格行', false], //
				['td', 'border-style-alt', '单元格', false],
				//['formrow', 'browser', '表单行'],
				//['formitem', 'columns', '表单项'],
				['formlabel', 'columns', '标签区', false],
				['formcomp', 'columns', '元素区', false],

				['abs', 'crosshairs', '自由容器', false],
				['paint', 'draw-polygon', '画布容器', false],

				['iframe', 'anchor', '内嵌容器', false],

				['modal_dialog', 'window-restore', '弹出对话框', false],
				['modal_drawer', 'chevron-square-right', '抽屉对话框', false],


				['dv_box1', 'window', '动感地带', false],
				['dv_box7', 'window', '棱角犀利', false],
				['dv_box12', 'window', '棱角圆润', false],
				//['dv_box11', 'window', '主题鲜明', false],  
				['dv_box13', 'window', '凹凸有致', false],

				['panel_lgyc', 'window', '流光溢彩', false],
				['panel_wryd', 'window', '温柔一刀', false],
				['panel_mlqq', 'window', '魔力圈圈', false],
				['panel_fkgsc', 'window', '疯狂过山车', false],
				['panel_dzxy', 'window', '斗转星移', false],
				//['panel_sjjq', 'window', '棱角分明', false],



			],

			base: [
				['label', 'font', '文本'],
				['button', 'square', '常规按钮'],
				['submit', 'external-link-square-alt', '提交按钮'],
				['dayin', 'print', '打印按钮'],
				['text', 'text', '单行文本框'],
				['input', 'edit', '原生输入框'],
				['area', 'text-size', '多行文本框'],
				['number', 'calculator-alt', '数字输入框'],
				['radio', 'circle', '单选框'],
				['check', 'check-square', '复选框'],
				['datetime', 'calendar-day', '日期时间'],
				['date', 'calendar-star', '日期'],
				//['dateR', 'calendar-week', '日期区间值'],
				['time', 'clock', '时间'],
				//['timeR', 'alarm-clock', '时间区间值'],
				['switch', 'toggle-on', '开关选择'],
				['selectS', 'ballot-check', '下拉单选'],
				['selectM', 'ballot-check', '下拉多选'],
				['textselect', 'angle-down', '下拉文本框'],
				['transfer', 'list-ul', '穿梭多选框'],
				['image', 'image', '图片'],
				['imgupload', 'camera', '图片上传'],
				['tag', 'tag', '标记'],
				['link', 'link', '链接'],
				['icon', 'flower-tulip', '图标'],
				['iconinput', 'hand-holding-medical', '图标输入框'],
				['colorinput', 'palette', '颜色输入框'],
				['sliderS', 'sliders-h-square', '滑块单值'],
				['sliderM', 'sliders-h', '滑块区间值'],
				['divider', 'page-break', '分隔线'],
				['upload', 'file-upload', '文件组上传'],
				//['filelist', 'files-medical', '文件列表'],
				['suggest', 'chevron-down', '联想输入'],
				['userdialog', 'user', '用户选择'],
				['usercurrent', 'user-check', '当前用户'],
				['userorg', 'street-view', '用户组织'],
				['orgdialog', 'users-class', '组织树选择'],
				['treedialog', 'folder', '树节点选择'],
				['treelist', 'folder-tree', '树列表选择'],



				['hidden', 'expand-wide', '隐藏数据域'],
				['qr', 'qrcode', '二维码'],
				['barcode', 'barcode', '条形码'],
				['qrread', 'barcode-read', '万能扫码'],
				['imgcode', 'shield-check', '图形验证码'],
				['signpad', 'user-edit', '手写签字板'],
				['smscode', 'router', '手机验证码'],
				//['media', 'video', '媒体播放器'],
				['timetask', 'alarm-clock', '定时器'],
				['excelimport', 'file-excel', 'Excel导入'],
				['fileimport', 'file-upload', '文件导入'],

			],
			wrap: [
				['bread', 'bread-loaf', '面包屑'],
				['menu', 'layer-group', '导航菜单'],
				//['list', 'table', '填报表格'],
				['inputlist', 'table', '子表编辑器'],
				['inputhead', 'heading', '表格列', false],
				['listfoot', 'heading', '表格列尾', false],

				['treeview', 'folder-tree', '树型'],
				['calendar', 'calendar-star', '日历'],

			],
			report: [
				['commtable', 'fad fa-table', '通用报表'],
				['foldtable', 'fas fa-chevron-down', '折叠报表'],
				['subtable', 'fad fa-table', '主从报表', false],
				['head', 'grip-lines', '表头', false],
				['reportcol', 'grip-lines', '表头列', false],
				['treetable', 'ball-pile', '树型报表'],
			],
			chart: [

				['part_time', 'fad fa-clock', '日期时间', false],
				['part_weather', 'fad fa-sun-cloud', '天气状态', false],
				['part_flct', 'fad fa-bring-front', '风铃词条'],

				['chart_bar', 'fad fa-chart-bar', '柱形图'],
				['chart_barR', 'fad fa-circle-notch ', '环形柱图'],
				['chart_barH', 'fad fa-chart-bar fa-rotate-90', '横向柱图'],
				['chart_barB', 'fad fa-align-center', '双向柱形图'],
				['chart_barC', 'fad fa-capsules', '胶囊堆叠图'],
				['chart_line', 'fad fa-chart-area', '曲线图'],
				['chart_barline', 'fad fa-analytics', '柱线图'],
				['chart_pie', 'fad fa-chart-pie-alt', '单组饼图'],
				//['chartPieRose', 'fad fa-chart-pie-alt', '玫瑰饼图'],
				['chart_pieS', 'fad fa-circle-notch', '单组环图'],
				['chart_pieM', 'fad fa-bullseye-pointer', '多组环图'],
				['chart_radar', 'fad fa-dharmachakra', '雷达图'],
				['chart_gauge', 'fad fa-tachometer-alt-average', '仪表图'],
				['chart_funnel', 'fad fa-filter', '漏斗图'],
				['chart_mapArea', 'fad fa-globe-asia', '区域地图'],
				['chart_mapPoint', 'fad fa-map-marker-alt', '标注地图'],
				['chart_mapLine', 'fad fa-plane', '飞线地图'],
				['chart_water', 'fad fa-water-rise', '水位图'],
				/* 		['chartPicBarS', 'fad fa-mountains', '单组图柱'],
				['chartPicBarM', 'fad fa-mountains', '多组图柱'],
				
				['chartprocess', 'fad fa-battery-half', '进度池'],
				['partTable', 'fad fa-scroll', '轮播列表'],
 */


			],
			doc: [
				['book', 'book-reader', '文档设计器'],
				['ckclassic', 'file-signature', '常规编辑器'],
				['ckinline', 'file-signature', '隐形编辑器'],
				['ckballoon', 'file-signature', '飘浮编辑器'],
				['ckdocument', 'file-signature', '文档编辑器'],

			],
			struct: [
				['struct', 'network-wired', '组态'],
			],
			flow: [
				['flow', 'people-arrows', '流程设计器'],
				['flownode', 'people-arrows', '流程节点', false],
				['flowline', 'horizontal-rule', '流程路径', false],
				['flowform', 'file-alt', '流程页面', false],
				['flowsubmit', 'arrow-from-bottom', '流程提交', false],
				['flowmodule', 'tasks', '流程模块'],
				['flowpage', 'file-user', '流程页面'],
			],
			custom: [
				['gantt', 'sliders-h', '甘特图', false],
				['pm', 'project-diagram', '项目管理', false],

			],
			action: [
	            ['qywx', 'comments', '企微扫码'],
			],

		}
		let compMap = {
			page: ['page', '', '页面', 'layout']
		}
		for (let group in this.comps) {
			let items = this.comps[group]
			for (let item of items) {
				if (item.length > 3) { //如果有扩展属性的，扩展属性移到group后面
					item.splice(3, 0, group)
				} else {
					item.push(group)
				}

				compMap[item[0]] = item
			}

		}
		this.compMap = compMap
	}
	reg(obj) {
		let data = {
			...base
		}
		//obj['base']=[].concat(obj['base'])
		//固定加一个name属性
		obj['base'].splice(0, 0, {
			key: 'name',
			text: '名称',
			type: 'text',
			init: '',
			target: '',
			extend: false,
			tip: ''
		}, {
			key: 'show',
			text: '显示方式',
			type: 'select',
			init: 'show',
			target: '',
			bursh: true,
			bind: true,
			tip: 'show:正常显示，none:隐藏（消除），hidden:隐藏（占位）',
			list: options.showTypes
		}, )
		obj.hide = ',fieldCount,fieldReal,menuOption,' + obj.hide + ',' //字段数量域始终不显示

		if (obj.delete) { //过滤掉在当前类型元素中配置的删除项
			obj.delete = ',' + obj.delete + ','
			for (let part of ['base', 'special', 'action']) {
				let list = obj[part]
				for (let i = list.length - 1; i > -1; i--) {
					let key = ',' + list[i].key + ','
					if (obj.delete.indexOf(key) > -1) {
						list.splice(i, 1)
					}
				}
			}
		}
		let configs = obj.base.concat(obj.special).concat(obj.action)
		for (let config of configs) {
			data[config.key] = config.init
		}
		for (let key in obj.data) { //对象类型指定的属性覆盖初始属性
			data[key] = obj.data[key]
		}
		this.objs[data.type] = { //保留处理后的对象模板
			data: data,
			obj: obj,
			configs: configs
		}

	}
	create(objType, parent, page) {

		if (!this.objs[objType]) {
			//alert('未注册的元素类型：'+objType)
			return null
		}
		let dataDef = this.objs[objType].data
		let data = {
			//...this.objs[objType].data
		}

		for (let key in dataDef) {
			let v = dataDef[key]
			if (v && typeof(v) == 'object') {
				data[key] = dataClone.deepClone(v)
			} else {
				data[key] = v
			}
		}
		if (objType == 'page') {
			data.maxId = 1
			data.id = 'e' + 1
		} else {
			page.maxId++
			data.id = 'e' + page.maxId
			if (objType.indexOf('modal_') === 0) { //模式窗口元素的父对象是页面
				parent = page
			}
		}

		let compMapItem = this.compMap[objType]
		data.icon = compMapItem[1]
		data.name = compMapItem[2]
		data.group = compMapItem[3]
		if (data.group == 'layout') {
			data.items = []
		} else {
			data.items = null
		}
		data.$DataMap = null //属性值与数据键的映射关系
		data.parent = parent
		if (parent != null && parent.items != null) {
			parent.items.push(data)
		}
		Event.initEvents(data) //初始化事件对象
		return data
	}
	getDef(objType) {
		let item = this.objs[objType]
		if (item) {
			return item.obj
		} else {
			return {}
		}

	}


}
let config = new Config()
config.reg(page)

config.reg(form)
config.reg(table)
config.reg(tr)
config.reg(td)
config.reg(tabpage)
config.reg(tabitem)
config.reg(clone)
config.reg(subpage)
config.reg(leftright)
config.reg(topbottom)

config.reg(autocol)
config.reg(col)
config.reg(row)
config.reg(print)
config.reg(dialog)
config.reg(drawer)
config.reg(panel_lgyc)
config.reg(panel_wryd)
config.reg(panel_mlqq)
config.reg(panel_fkgsc)
config.reg(panel_dzxy)
config.reg(panel_sjjq)
config.reg(dv_box1)
config.reg(dv_box7)
config.reg(dv_box11)
config.reg(dv_box12)
config.reg(dv_box13)

//config.reg(formrow)
//config.reg(formitem)
config.reg(formfield)
config.reg(formlabel)
config.reg(formcomp)


config.reg(label)
config.reg(tag)
config.reg(link)
config.reg(icon)
config.reg(iconinput)
config.reg(colorinput)
config.reg(button)
config.reg(submit)
config.reg(pringButton)
config.reg(image)
config.reg(imgupload)
config.reg(input)
config.reg(hidden)
config.reg(text)
config.reg(textSelect)
config.reg(area)
config.reg(number)
config.reg(radio)
config.reg(check)
config.reg(selectS)
config.reg(selectM)
config.reg(switchE)
config.reg(datetime)
config.reg(dt)
//config.reg(dtr)
config.reg(tm)
//config.reg(tmr)
config.reg(sliderS)
config.reg(sliderM)
config.reg(transfer)
config.reg(divider)
config.reg(upload)
config.reg(imgcode)
config.reg(smscode)
config.reg(qrcode)
config.reg(barcode)
config.reg(qrRead)
config.reg(treedialog)
config.reg(treelist)
config.reg(suggest)
config.reg(timetask)
config.reg(orgdialog)
config.reg(userdialog)
config.reg(userorg)
config.reg(usercurrent)
config.reg(signpad)
config.reg(excelImport)
config.reg(fileImport)

config.reg(bread)
config.reg(menu)
//config.reg(list)
config.reg(inputlist)
config.reg(inputhead)
config.reg(listfoot)
config.reg(treeview)
config.reg(calendar)

//Report
config.reg(tableHead)
config.reg(reportCol)
config.reg(commTable)
config.reg(treeTable)
config.reg(foldTable)


//Chart
config.reg(wordratate)
config.reg(chartBar)
config.reg(chartBarR)
config.reg(chartBarC)
config.reg(chartBarH)
config.reg(chartBarB)
config.reg(chartLine)
config.reg(chartBarLine)
config.reg(chartPie)
config.reg(chartPieS)
config.reg(chartPieM)

config.reg(chartRadar)
config.reg(chartGauge)
config.reg(chartFunnel)

config.reg(mapArea)
config.reg(mapPoint)
config.reg(mapLine)
config.reg(chartWater)

///DOC
config.reg(book)
config.reg(ckclasic)
config.reg(ckinline)
config.reg(ckballoon)
config.reg(ckdocument)

//flow
config.reg(workFlow)
config.reg(flowNode)
config.reg(flowLine)
config.reg(flowForm)
config.reg(flowPage)
config.reg(flowModule)
config.reg(flowSubmit)


//Custom
config.reg(gantt)
config.reg(pm)

//Action
config.reg(qywx)
export default config