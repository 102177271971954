class Bolanshi {
	constructor() {
		this.charPrior = {
			'+': 1,
			'-': 1,
			'%': 2,
			'*': 3,
			'/': 4,
			'(': 5,
			')': 5
		}
	}
	getSubPart(charts, start) { //获取最后层括号内的内容,start为(所在位置
		let stack = [charts[start]]
		let rs = []
		for (let i = start + 1; i < charts.length; i++) {
			let char = charts[i]
			if (char == '(') {
				stack.push('(')
			} else if (char == ')') {
				stack.pop()
				if (stack.length == 0) {
					return rs
				}
			}
			rs.push(char)
		}
		return null

	}
	comparePrior(a, b) { //后面的值与前面的比大小 
		let p1 = a ? this.charPrior[a] : 0
		let p2 = b ? this.charPrior[b] : 0
		return p2 > p1 ? 1 : p2 == p1 ? 0 : -1
	}
	//a+(max(a*b,a+c)/d)/sum(a,b,c)
	addParam(paramItem, list, vars) {
		let param = paramItem.toUpperCase() //变量名为大写，列名
		let type = 'param'
		//if (/^[a-zA-Z]+$/.test(param)) { //如果是全字母，即为变量名
		if (/^[A-Z][A-Z0-9_]*$/.test(param)) { //如果是字母开头且只能字母加数字下划线，即为变量名
			type = 'var'
			vars[param] = param
		}
		let item = {
			type: type,
			content: param
		}
		list.push(item)

	}
	getNiBolanshi(express, vars) {
		let keyWords = ',+-*/()%'
		let result = []
		let varList = []
		let words = [] //  a+( max (a*b,a+c)/d)/sum   (a,b,c)
		let word = '' //第一步分解出各词组,计算符为一个词组结束符, (左侧有单词表示是函数
		//依次取每个字符，直到遇到运算符，如果是（表示函数名，否则是一个单词，如果是函数，取出整个函数放到子分支
		let chars = typeof(express) == 'string' ? express.replace(/\s+/g, '').split('') : express //去掉所有空白 
		let part = null
		let i = -1
		while (i < chars.length - 1) {
			i++
			let char = chars[i]
			if (keyWords.indexOf(char) > -1) { //遇到分隔符分析
				if (char == '(') {
					part = this.getSubPart(chars, i)
					let subWords = this.getNiBolanshi(part, vars)
					if (word) { //存在word为函数名，找到函数整块
						let func = { //单词加入集合
							type: 'func',
							name: word, //.toUpperCase(), //函数名转大写,用于统一匹配
							items: subWords
						}
						words.push(func)
						word = ''

					} else { //括号运算块
						words.push({ //单词加入集合
							type: 'sub',
							items: subWords
						})
					}
					i = i + part.length + 1 //跳过结尾的）
					//console.log(part)
				} else { //运算符的情况下
					if (word == '' && part == null) { //如果之前没有单词语法为错误
						//console.log(words)
						throw new Error('语法错误，运算符前没有运算项,第' + i + '位置' + char)
					} else {
						if (word) { //运算符前累加的单词取出来,如果运算符前是子串，则word为空值

							this.addParam(word, words, vars)
							word = ''
						}

						words.push({ //运算符加入集合
							type: 'opt',
							char: char
						})
					}

				}



			} else {
				part = null
				word = word + char
			}

		}
		if (word) { //剩余单词加入集合

			this.addParam(word, words, vars)
		}
		//根据词列表转化波兰式
		let stack = []
		for (let item of words) {
			if (item.type == 'opt') { //运算符作处理			
				if (item.char == ',') { //逗号直接加入结果
					result.push(item)
				} else { //运算符作优先级处理
					while (stack.length > 0) {
						let pre = stack[stack.length - 1]
						//console.log(pre.char,item.char,this.comparePrior(pre, item.char))
						if (this.comparePrior(pre.char, item.char) > 0) { //优先级高的入栈
							break
						} else {
							result.push(stack.pop())
						}
					}
					stack.push(item)
				}
			} else { //参数\函数、局部表达式 直接加入结果
				result.push(item)
			}

		}
		while (stack.length > 0) { //栈中残留的取出
			result.push(stack.pop())
		}
		return result
	}
	getNiBolanshiValue(bolanshi, vars, decimal) { //表达式，变量集合，精度
		let bls = [].concat(bolanshi) //操作一个波兰式副本
		if (bls.length < 0) {
			return null
		} else if (bls.length == 1) {
			return this.getItemValue(bls[0], vars, decimal)
		}
		for (let i = 0; i < bls.length; i++) {
			let item = bls[i]
			if (item.type == 'opt') {
				let p1 = null
				let p2 = bls[i - 1] //第二个操作数一定是在计算符左边
				let first = -1
				for (let j = i - 2; j > -1; j--) {
					if (bls[j]) { //向前查找第一个有效元素取出即为第一个操作数
						first = j
						break
					}
				}
				if (first < 0) {
					throw new Error('无效计算表达式，' + item.type + '前的操作数不存在')
				}
				p1 = bls[first]
				let v1 = this.getItemValue(p1, vars, decimal)
				let v2 = this.getItemValue(p2, vars, decimal)
				let value = 0
				switch (item.char) {
					case '+':
						value = v1 + v2
						break
					case '-':
						value = v1 - v2
						break
					case '%':
						value = v1 % v2
						break
					case '*':
						value = v1 * v2
						break
					case '/':
						value = v1 / v2
						break
				}
				//计算完后， 此三个表达式结果形成一个表达式成员， 替换到操作符位置， 交给后面处理
				bls[first] = null //已经计算过的中间结果清除
				bls[i - 1] = null
				bls[i] = {
					type: 'param',
					content: value
				}


			}
		}
		//表达式最后一项的结果为计算结果
		return bls[bls.length - 1].content
	}
	getItemValue(item, vars, decimal) {
		let value = 0
		if (item.type == 'var') {
			let val = vars[item.content]
			if (!(item.content in vars) || val == null || val === '') {//空值按0处理
				//throw new Error('数据成员' + item.content + '为空值')
			} else {
				if (typeof(val) == 'string') {
					value = parseFloat(val) //取变量值
				} else if (typeof(val) == 'number') {
					value = val
				} else {
					throw new Error('数据成员' + item.content + '为不支持的数据类型：' + typeof(val))
				}
			}

		} else if (item.type == 'param') { //取常量值
			value = parseFloat(item.content)
			if (isNaN(value)) {
				throw new Error('' + item.content + '不是有效数值')
			}
		} else if (item.type == 'sub') { //局部表达式或函数
			value = this.getNiBolanshiValue(item.items, vars, decimal)
		} else if (item.type == 'func') {
			let values = []
			let isExpress = true
			for (let pitem of item.items) {//如果存在逗号分隔符为参数对象，否则为子表达式对象
				if (pitem.type == 'opt' && pitem.char == ',') {
					isExpress = false
					break
				}
			}
			let pv = null
			if (isExpress) {//函数参数是子表达式
				pv = this.getNiBolanshiValue(item.items, vars, decimal)
				values.push(pv)
			} else {
				for (let pitem of item.items) {
					if (pitem.type == 'opt' && pitem.char == ',') {
						continue
					}
					pv = this.getItemValue(pitem, vars, decimal)
					values.push(pv)
				}
			}			
			value = eval(item.name + '(values)')
		} else {
			throw new Error('未知类型操作数：' + item.type)
		}
		return value
	}


}
export default new Bolanshi()
