import button from './button.js'
import deepClone from '../../../../../plugins/deepClone.js'
let sb=deepClone.deepClone(button)
sb.data.type='dayin'
sb.data.ico='far fa-print'
sb.data.value='打印'
sb.data.color='primary'
sb.hide='mode,listData'
sb.base[0].title='打印按钮'
for(let i=sb.action.length-1;i>-1;i--){//移除鼠标点击相关的事件
	let item=sb.action[i] 
	if('$event_click,$event_dblclick,$event_mousedown,$event_mouseup'.indexOf(item.key)>-1){
		sb.action.splice(i,1)
	}
}
let bases=[
	{key: 'title',text: '打印按钮'	},
	{key: 'printId',text: '打印容器ID',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '指定用于提交的表单',list: null},
	{key: 'ask',text: '提交前确认',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '提交前是否开启对话确认',list: null},
	{key: 'message',text: '确认提示',type: 'text',init: '是否确定打印',	target: '',	bursh: true,bind: true,tip: '提交前的对话确认提示消息',list: null},
/* 	{key: 'tell',text: '打印后提示',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '提交后是否提示成功信息',list: null},
	{key: 'tip',text: '成功提示',type: 'text',init: '已成功打印',	target: '',	bursh: true,bind: true,tip: '提交成功后的提示消息',list: null},
 */	
]
let actions=[
/* 	{key: 'title',text: '打印事件'	},
 	{key: '$comp_printBefore',text: '打印前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '可进行打印前的处理（如打印次数控制），如返回false将阻止打印',list: null	},
	{key: '$comp_printAfter',text: '打印后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '打印完成后的处理(如打印行为记录)',list: null	},
 */
]
sb.base=bases.concat(sb.base)
sb.action= actions.concat(sb.action)

export default sb
