import barH from './chartbarH.js'
import clone from '../../../../../../../../plugins/deepClone.js'
import options from '../../../../../public/options.js'
 let initData=JSON.stringify( [
 	
	['日期', 'A类'],
	['周一', -110],
	['周二', 120],
	['周三', -150],
	['周四', 140],
	['周五', -70],
	['周六', 70]
 ]).replaceAll('],','],\r\n')

let bar = clone.deepClone(barH)
bar.special.concat([
	
])
bar.data.color=options.chartColor.bar6r
bar.data.type='chart_barB'
bar.data.yAxisShow='N'
bar.data.xAxisPosition='top'
bar.data.xAxisSplitLineType='dashed'
bar.data.yAxisSplitLineShow='N'
bar.data.xAxisName=''
bar.data.seriesBarColorBy='data'
bar.data.legendShow='N'
bar.data.gridRight=30
bar.data.initData=initData

export default bar