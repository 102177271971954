<template>


	<el-tabs v-model="tabName" style="height:100%;">

		<el-tab-pane name="share">
			<template #label>
				<span class="tab-label">
					<i class="far fa-share-alt"></i>
					<span>共享资源</span>
				</span>
			</template>
			<filesource ref="shareFile" @filesel="shareFileSel" />
		</el-tab-pane>

		<el-tab-pane name="project">
			<template #label>
				<span class="tab-label">
					<i class="far fa-cubes"></i>
					<span>项目资源</span>
				</span>
			</template>
			<div class="contentflex">
				<div class="dialogfolder">
					<logictree ref="logicTree" :nodes="foldNodes" :allow-edit="true" :expand-click="false"
						@init="treeInit" @nodeclick="nodeClick" @add="nodeAdd" @rename="nodeRename"
						@delete="nodeDelete"></logictree>

				</div>
				<div class="dialogfiles">
					<div class="fileshead">
						<div style="overflow: hidden;width: calc(100% - 75px);">
							{{foldObj.textPath}}
						</div>
						<div style="width: 75px;">
							<el-upload v-if="foldObj && foldObj.idPath && foldObj.idPath.length>1 " ref="upload"
								:action="uploadUrl" :data="uploadData" :before-upload="beforeUpload" :multiple="false"
								:limit="1" :file-list="selectedFiles" :on-exceed="onExceed" :on-success="onSuccess"
								:on-error="onError" :show-file-list="false">
								<el-button v-show="this.uploadData.folderPath" :loading="uploading" size="small"
									type="primary">
									<i v-show="!uploading" class="far fa-arrow-alt-up"></i>
									上传
								</el-button>

							</el-upload>
						</div>
					</div>
					<div class="filelist">
						<div style="display: flex;flex-wrap: wrap;width: 100%;">
							<template v-for="item in fileList">

								<div class="imgblock">

									<img :src="this.urlBase+item.url" @click="fileSelect(item)"
										:class="item===fileItem?'imgitem filesel':'imgitem'">

									<div class="imglabel" @click.stop="fileEdit(item)" :title="item.fileName"><i
											class="far fa-edit" />{{item.fileName}}</div>
									<div class="topbar"><i class="far fa-times" @click.stop="fileDelete(item)" /> </div>
								</div>


							</template>
						</div>

					</div>
				</div>
			</div>
		</el-tab-pane>

	</el-tabs>

</template>

<script>
	import FileSource from './FileSource.vue'
	import logicTree from '../treeview/TreeView.vue'
	import fileUpload from './upload.js'
	export default {
		mixins: [fileUpload],

		emits: ['init', 'select'],
		//props: ['project'], //fileUpload中
		data() {
			return {
				tabName: 'share',
				treeObj: null,
				foldNodes: [],
				foldObj: {},
				/* 	uploading: false,
					fileList:[], */
				fileItem: null

			}
		},

		methods: {
			shareFileSel(item) {
				this.fileItem = item
				this.$emit('select', item)
			},
			fileSelect(item) {

				if (this.$refs.shareFile) {
					this.$refs.shareFile.cancelSel()
				}

				this.fileItem = item
				this.$emit('select', item)
			},
			fileEdit(item) {
				let fname = item.fileName
				let x = fname.lastIndexOf('.')
				if (x > -1) {
					fname = fname.substring(0, x)
				}
				this.$logic.message.input('修改', {
					info: '请输入新的文件名称,不允许修改文件类型扩展名不用输入',
					init: fname
				}, (newName) => {
					if (newName && newName.length > 0) {
						let x = item.fileName.lastIndexOf('.')
						newName = newName + item.fileName.substring(x)
						this.$logic.http.post(true, '/api/editor/renamefile/', {
							appId: this.project.id,
							fileUrl: item.url,
							newName: newName
						}, null, res => {
							item.fileName = newName
							this.$logic.tip.success('文件名修改成功')
						})
					} else {
						this.$logic.tip.info('没有输入有效的文件名称')
					}

				})
			},
			fileDelete(item) {
				this.fileSelect(null)
				this.$logic.message.confirm('提示', '是否确定删除此文件', () => {
					this.$logic.http.post(true, '/api/editor/deletefile/', {
						appId: this.project.id,
						reList: true,
						fileUrl: item.url
					}, null, res => {
						this.fileList = res.data.data
						this.$logic.tip.success('删除成功')
					})
				})
			},
			onSuccess(response, file, fileList) {
				this.sysTick = new Date().getTime()
				this.uploading = false
				this.selectedFiles = []
				this.$refs.upload.clearFiles()
				if (response.code == 0) {
					this.sn++
					this.$logic.tip.success('上传成功')
					this.fileList = response.data
				} else {
					this.$refs.upload.abort()
					this.$logic.tip.error('上传失败 ' + response.info || '')
				}

			},
			beforeUpload(file) {

				if (this.uploadData.folderPath && this.uploadData.folderPath.length > 2) {
					this.uploading = true
					return true
				} else {
					this.$logic.tip.error("无效的文件目录")
					return false
				}


				return this.uploading
			},
			/* 			
						onError(err, file, fileList) {
							this.uploading = false
							this.$refs.upload.clearFiles()
							let info=''+err
							let x=info.indexOf('http')
							if(x>0){
								info=info.substring(0,x)
							}
							this.$logic.message.message('上传失败','上传异常：'+info)
							 
						},
						
						onExceed(files) {
							this.uploading = false
							this.$logic.message.message('上传失败','文件超出上传限制') 
						}, */
			nodeAdd(parentObj, newName) {
				this.$logic.http.post(true, '/api/editor/createfolder/', {
					appId: this.project.id,
					parentPath: parentObj.idPath,
					newFolder: newName,
					fileHome: this.home
				}, null, res => {
					this.foldNodes = res.data.data
					setTimeout(() => {
						this.$refs.logicTree.nodeClick(res.data.info)
						//this.treeObj.ref.setCurrentKey(res.data.info)
					}, 1000)

					this.$logic.tip.success('创建成功')

				})
			},
			nodeRename(nodeObj, newName) {
				this.$logic.http.post(true, '/api/editor/renamefolder/', {
					appId: this.project.id,
					fileHome: this.home,
					folderId: nodeObj.data.id,
					newName: newName
				}, null, res => {
					nodeObj.data.label = newName
					this.$logic.tip.success('修改成功')

				})
			},
			nodeDelete(nodeObj) {
				this.$logic.http.post(true, '/api/editor/deletefolder/', {
					appId: this.project.id,
					fileHome: this.home,
					folderPath: nodeObj.idPath,
					folderId: nodeObj.data.id

				}, null, res => {
					this.foldNodes = res.data.data
					setTimeout(() => {
						//this.treeObj.ref.setCurrentKey(nodeObj.node.parent.data.id)
						this.$refs.logicTree.nodeClick(nodeObj.node.parent.data.id)
					}, 1000)
					this.$logic.tip.success('删除成功')

				})
			},
			loadFiles() {
				if (this.foldObj == null) {
					this.$logic.tip.error('未指定文件目录')
				}
				this.fileList = []
				if (this.foldObj.data.id == '') {
					//this.$logic.tip.warning('请选择文件目录')
					return
				}
				this.$logic.http.get(true, '/api/editor/listfiles/', {
					appId: this.project.id,
					fileHome: this.home,
					folderPath: this.foldObj.idPath
				}, res => {
					this.fileList = res.data.data
				})
			},
			nodeClick(nodeObj) {
				this.foldObj = nodeObj
				this.loadFiles()
			},
			treeInit(treeObj) {
				this.treeObj = treeObj
			},
			loadData() {
				this.fileSelect(null)
				this.$logic.http.get(true, '/api/editor/getfoldertree/', {
					appId: this.project.id,
					fileHome: this.home,
				}, res => {
					this.foldNodes = res.data.data
				})
			}

		},
		computed: {
			/* 			uploadUrl() {
						 let url= this.$logic.http.baseUrl + '/api/editor/uploadfile/'
							 
						 return url
						},
						uploadData() {
							 let user = this.$logic.getUser() 
							
							let data = {
								reList:true,
								fileHome: this.home,
								$$unitGroup: user.unitGroup,
								$$unitId: user.unitId,
								$$userId: user.id,
								folderPath: this.foldObj.idPath+'/'					
							}
							return data
						},
						urlBase(){
							return  this.$logic.http.baseUrl
						} */
			folderPath() { //覆盖的upload.js方法
				return this.foldObj.idPath + '/'
			},
			reList() { //上传完后返回当前文件夹下的文件列表,覆盖upload.js方法
				return true
			},

		},
		components: {
			logictree: logicTree,
			filesource: FileSource,
		},
		created() {
			let obj = {
				loadData: this.loadData
			}
			this.$emit('init', obj)
			this.loadData()

		}
	}
</script>

<style scoped>
	.dialogfolder {
		height: 100%;
		min-height: calc(40vh);
		width: 300px;
		margin-right: 0px;
		background-color: #FFFFFF;
		overflow-y: scroll;
		overflow-x: auto;
	}

	.dialogfiles {
		height: 100%;
		min-height: calc(40vh);
		width: calc(100% - 300px - 2px);
		background-color: #FFFFFF;
		/* 	border: #dcdcdc solid 1px; */

		overflow: auto;
	}

	.fileshead {
		display: flex;
		justify-content: space-between;
		height: 28px;
		width: calc(100% - 12px);
		padding-left: 10px;
		font-weight: 200;
		color: #75777c;
		font-size: 16px;
		background-color: #f4f4f5;
		border: #dcdcdc solid 1px;
		line-height: 26px;
	}

	.filelist {
		padding: 5px;
		height: calc(100% - 45px);
		border: #dcdcdc solid 1px;
		overflow-y: scroll;
	}

	.imgblock {
		/* 	background-color: #f4f4f5; */
		position: relative;
		width: calc(25% - 20px);
		border: solid 1px #cccccc;
		min-width: 190px;
		margin: 3px;
		padding: 5px;
	}

	.imgitem {
		border-radius: 5px;
		width: 100%;
		height: 150px;
		cursor: pointer;
		object-fit: contain;
	}

	.imgitem:hover {
		box-shadow: 2px 2px 8px 1px #353535;
		border: solid 1px yellow;
	}

	.filesel {
		box-shadow: 2px 2px 8px 1px #353535;
		border: solid 1px yellow;

	}

	.imglabel {
		text-align: center;
		height: 25px;
		line-height: 25px;
		margin-top: 2x;
		overflow: hidden;
		cursor: pointer;
	}

	.imglabel:hover {
		/* border: solid 1px #CCCCCC; */
		box-shadow: 0px 0px 0px 1px #CCCCCC inset;
		background-color: #f8f8f8;
	}

	.topbar {
		position: absolute;
		z-index: 100;
		top: 10px;
		right: 15px;
		color: #FFD700;
		font-size: 20px;
		cursor: pointer;
		padding: 2px;
	}

	.topbar:hover {
		color: red;
	}

	.tab-label i {
		font-size: 16px;
		margin-right: 5px;
	}

	:deep(.el-tabs__content) {
		height: calc(100% - 60px);
	}

	:deep(.el-tab-pane) {
		height: 100%;
	}
</style>