<template>
	<template v-if="model">
		<lg-div v-if="model.type==='row' || model.type==='col' || model.type==='formcomp'  || model.type==='autocol' "
			:model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<form-field v-else-if="model.type==='formfield' " :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<form-label v-else-if="model.type==='formlabel' " :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-form v-else-if="model.type==='form' " :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-table v-else-if="model.type=='table'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-tab v-else-if="model.type=='tabpage'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />

		<lg-part v-else-if="model.type=='leftright' || model.type=='topbottom'" :model="model" :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="datas" />
		<sub-page v-else-if="model.type=='subpage'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-clone v-else-if="model.type=='clone'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-print v-else-if="model.type=='print'" :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-panel v-else-if="model.type.indexOf('panel')==0 " :model="model" :project="project" :page="page"
			:host="host" :isedit="isedit" :datas="datas" />
		<datav v-else-if="model.type.indexOf('dv_')==0 " :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-page v-else-if=" model.type==='page' " :model="model" :project="project" :page="page" :host="host"
			:isedit="isedit" :datas="datas" />
		<lg-dialog v-else-if=" model.type==='modal_dialog' ||  model.type==='modal_drawer'" :model="model"
			:project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		<!--
	 
 
 
 
		<template v-else-if="model.type.indexOf('panel')==0 ">
			<panel v-show="model.show !='none'" :model="model" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="dataBag" />
		</template>
		<template v-else-if="model.group=='layout' && model.type!='formitem'" v-show="model.show !='none'">


		</template>
		<div v-else>未知容器{{model.type}}</div>
		<template v-for="item in model.items">
			<container :model="model" :project="project" :page="page" :host="host" :isedit="isedit" :datas="datas" />
		</template> -->
	</template>
</template>

<script>
	//	import containerBase from './containerBase.js'
	import layoutBase from './sub/layoutBase.js'
	import Div from './sub/Div.vue'
	import Form from './sub/Form.vue'
	import FormField from './sub/FormField.vue'
	import FormLabel from './sub/FormLabel.vue'
	import Part from './sub/Part.vue' //上下、左右分隔容器
	import Table from './sub/Table.vue'
	import Tab from './sub/Tab.vue'
	import SubPage from './sub/SubPage.vue'
	import Clone from './sub/Clone.vue'
	import Panel from './Panel.vue'
	import DataV from './datav/DataV.vue'
	import Print from './sub/Print.vue'
	import Page from './sub/Page.vue'
	import Dialog from './sub/Dialog.vue'


	export default {

		mixins: [layoutBase],
		//name: 'block',
		emits: ['reload'],
		data() {
			return {
				//compGroups: ',base,wrap,doc,report,chart,struct,custom,action',
				//superComp: true,

			}
		},

		methods: {
			

		},
		computed: {

		},
		watch: {

		},
		components: {
			lgDiv: Div,
			lgForm: Form,
			formField: FormField,
			formLabel: FormLabel,
			lgPart: Part,
			lgTable: Table,
			lgTab: Tab,
			subPage: SubPage,
			lgClone: Clone,
			lgPrint: Print,
			lgPanel: Panel,
			datav: DataV,
			lgPage: Page,
			lgDialog: Dialog,



		},
		mounted() {
			this.initMethod()
		},
		created() {
			let model = this.model
			model.$set = this.setData
			model.$get = this.getData



		}
	}
</script>

<style>

</style>