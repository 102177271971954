import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input'
let base = [
	{key: 'title',text: '数值输入框'	},
	
	{key: 'value',text: '当前值',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:options.YON},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
	
	{key: 'min',text: '最小值',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'max',text: '最大值',type: 'number',init: '100',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'step',text: '增量步长',type: 'number',init: '1',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'strict',text: '倍数限定',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '控制输入的数值必须是增量步长的倍数',list: options.YON},
	{key: 'precision',text: '小数位数',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'controls',text: '按钮控制',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'controlsP',text: '按钮位置',type: 'select',init: '',	target: '',	bursh: true,bind: true,tip: '',list:[{label:'前后',value:''},{label:'右侧',value:'right'}]},

	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
 {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
 {key:'minW',text:'最小宽度',type:'text',init:'100px',target:'minWidth',	bursh: true,bind: true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
 
 {key: 'tip',text: '提示信息',type: 'text',init: '请输入',	target: '',	bursh: true,bind: true,tip: '',list: null},
 compBase.status,

]
	

let numb = {
	data: {
		type: 'number',isInput:'S',
	},
	delete: 'fontName,fontColor,fontBold,fontItalic,fontHeight',
	hide: '',
	base: base.concat(font).concat(compBase.base),
	special: extend.tip,
	action: options.getEvents(null,'$event_click,$event_change,$event_focus,$event_blur').concat(options.getEvents(null,'$hook'))
}

export default numb
