import options from '../../public/options.js'
import ckedit from './ckeditor.js'
import deepClone from '../../../../../plugins/deepClone.js'
let classicItems=[
	{key:'_sourceEditing',text:'HTML源码',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
]
let classic=deepClone.deepClone(ckedit)
classic.data.type='ckclassic'
classic.special=classic.special.concat(classicItems)
export default classic

