import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let orders=[{label:'保持数据源顺序',value:'original'},{label:'底部添加',value:'push'},{label:'顶部添加',value:'unshift'}]
let datas =JSON.stringify( [
	{label:'周一',value:1,disabled:'N'},
	{label:'周二',value:2,disabled:'N'},
	{label:'周三',value:3,disabled:'Y'},
	{label:'周四',value:4,disabled:'N'}, 
	{label:'周五',value:5,disabled:'N'}, 
	{label:'周六',value:6,disabled:'Y'}, 
	{label:'周日',value:7,disabled:'N'}, 
	
	 
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'选项文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'disabled',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'',unique:false},
 
]
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate('array') 
validate.validateSet.exclude='input,blur'
let base = [
	{key: 'title',text: '列表选项'	},
	
	{key: 'value',text: '已选中值',type: 'json',init: [],	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

	{key: 'titleL',text: '左侧标题',type: 'text',init: '待选项',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'titleR',text: '右侧标题',type: 'text',init: '已选项',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'filter',text: '是否可搜索',type: 'select',init: 'Y',	target: '',bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'order',text: '排列方式',type: 'select',init: 'push',target: '',	bursh: true,bind: false,tip: '',list: orders},
	{key: 'width',text: '列表宽度',type: 'text',init: 'calc(100% - 10px)',	target: 'width',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'height',text: '列表高度',type: 'text',init: '350px',	target: 'height',	bursh: true,bind: true,tip: '',list: options.YON},
    compBase.status2,


]

let trans = {
	data: {
		type: 'transfer',isInput:'L',width:'100%'
	},
	delete: '',
	hide: 'dataOption',
	base: base.concat(compBase.base),
	special:  extend.tip.concat([
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '选项数据',type: 'jsonArray',init: datas,	target: '',	bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},]),
	action: options.getEvents(null,'$event_click,$event_change').concat(options.getEvents(null,'$hook'))
}

export default trans
