import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'

let base=[
	{key:'title',text:'定时器'},
	{key: 'run',text: '启用定时',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '只有开启状态下才生效',list: null},
	{key:'timeStatus',text:'时态信息',type:'text',init:'',target:'',bursh: true,bind: true,tip:'',list:null},
	{key:'currentTime',text:'当前时间',type:'text',init:'',target:'',bursh: true,bind: true,read:true,tip:'',list:null},

	{key:'timeEnd',text:'目标时间点',type:'text',init:'',target:'',bursh: true,bind: true,read:true,tip:'',list:null},
	{key:'percentage',text:'进度百分比',type:'text',init:'',target:'',bursh: true,bind: true,read:true,tip:'',list:null},
	{key:'percentStop',text:'停止后进度',type:'select',init:'none',target:'',bursh: true,bind: true,tip:'',list:[{label:'当前值',value:'none'},{label:'归零',value:'bottom'},{label:'到顶',value:'top'}]},	 
	
	{key:'leftIcon',text:'左侧图标',type:'icona',init:'far fa-alarm-clock',target:'',bursh: true,bind: true,tip:'',list:null},
	{key:'textBefore',text:'前置文本',type:'text',init:'',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key:'textAfter',text:'后置文本',type:'text',init:'',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key:'title',text:'定时任务'},
	{key:'timeType',text:'定时方式',type:'select',init:'interval',target:'',bursh: true,bind: true,tip:'时间间隔[interval]:每隔一定时间触发任务，倒计时[countdown]：设定时长递减为0时触发',list:[{label:'时间间隔',value:'interval'},{label:'倒计时',value:'countdown'}]},	 
	{key: 'timeLong',text: '定时长度',type: 'number',init: 60,target: '',bursh: true,bind: true,tip: '每隔指定的秒数后执行一次,大于0时定时器生效',list: null	},
	{key: 'timeUnit',text: '时间单位',type: 'select',init: 'M',target: '',bursh: true,bind: true,tip: '',list: [{label:'秒(S)',value:'S'},{label:'分钟(M)',value:'M'},{label:'小时(H)',value:'H'}]},
	{key:'taskLoop',text:'循环定时',type:'switch',init:'Y',target:'',bursh: true,bind: true,tip:'当前定时任务完成后是否继续重新开始',list:null},	 
	{key:'firstDo',text:'首次触发',type:'switch',init:'N',target:'',bursh: true,bind: true,tip:'是否启动时即触发一次任务到达的事件',list:null},
	
]
let action=[

	{
		key: '$timer_befor',
		text: '任务执行前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '定时任务前执行，返回false将取消本次任务，执行顺序在数据请求前，内部应使用同步方法',
		list: null
	},
	{key: '$timer_task',	text: '任务执行',type: 'event',	init: 'N',	target: '',	bursh: true,	bind: false,	tip: '',		list: null},
	{key: '$timer_taskstart',	text: '定时器开启',type: 'event',	init: 'N',	target: '',	bursh: true,	bind: false,	tip: '',		list: null},
	{key: '$timer_taskstop',	text: '定时器关闭',type: 'event',	init: 'N',	target: '',	bursh: true,	bind: false,	tip: '',		list: null},

]

let divider= {
	data:{
		type:'timetask',
	},
	delete:'',
	base: base.concat(font).concat(compBase.base),
	special :[],
	action:action.concat(options.getEvents(null,'$hook'))
}

export default divider