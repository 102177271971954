<template>
	<div v-if="allowSearch || allowEdit" class="nodebar" :style="{'--bar-height':barHeight}">
		<div v-if="allowSearch" class="nodesearch" :style="searchWidth">
			<el-input v-model="nodeText" size="small" clearable placeholder="名称查找">
				<template #prepend><i class="fal fa-search"></i></template>
			</el-input>
		</div>
		<div v-if="allowEdit" class="nodeedit" :style="buttonWidth">
			<el-button-group size="small">
				<el-button v-if="edit.indexOf('add')>-1" @click="toAddNode" type="success"><i class="fal fa-plus" />
				</el-button>
				<el-button v-if="edit.indexOf('rename')>-1" @click="toRenameNode" type="primary"><i
						class="fal fa-edit" /></el-button>
				<el-button v-if="edit.indexOf('delete')>-1" @click="toDeleteNode" type="danger"><i
						class="fal fa-minus" /></el-button>
			</el-button-group>

		</div>
	</div>
	<el-tree ref="tree" :data="nodes" :show-checkbox="allowCheck" node-key="id" :highlight-current="true"
		:expand-on-click-node="expandClick" :default-expand-all="expandAll" :default-checked-keys="checked"
		:default-expanded-keys="expandNode" :check-strictly="checkStrictly" :draggable="allowDrag"
		:allow-drag="onAllowDrag" :allow-drop="onAllowDrop" :filter-node-method="filterNode" @node-click="onNodeClick"
		@node-drop="onNodeDrop" @check="checkChange"  >
		<template #default="{ node, data }">
			<div class="nodeitem" @dblclick="onNodeDblClick(data,node)" :style="{color:data.color}">
				<i v-if="data.icon" :class="data.icon"></i>
				<span>{{data.label}}</span>
			</div>
		</template>
	</el-tree>


</template>

<script>
	import {
		Edit,
		Plus,
		Delete
	} from '@element-plus/icons-vue'
	export default {
		props: {
			nodes: {
				type: Array,
				default: []
			},
			oneRoot: {
				type: Boolean,
				default: true
			},
			/* search: {
				type: Boolean,
				default: true
			}, */
			splitChar: {
				type: String,
				default: '/'
			},
			checkStrictly: { //是否解除勾选时上下级联动
				type: Boolean,
				default: false
			},
			edit: {
				type: String,
				default: 'add,rename,delete'
			},
			allowCheck: {
				type: Boolean,
				default: false
			},
			allowDrag: {
				type: Boolean,
				default: false
			},
			expandAll: {
				type: Boolean,
				default: false
			},
			expandClick: {
				type: Boolean,
				default: false //点击节点标签是否作展开/收缩
			},
			checked: { //节点ID数组
				type: Array,
				default: []
			},
			expanded: {
				type: Array,
				default: null
			},
			accordion: { //同一级只打开一个节点
				type: Boolean,
				default: false
			},
			barHeight: {
				type: String,
				default: 'auto'
			},
			allowSearch: {
				type: Boolean,
				default: false
			},
			allowEdit: {
				type: Boolean,
				default: false
			},
			deleteEmpty: {
				type: Boolean,
				default: true
			},
			autoDo: {
				type: Boolean,
				default: true
			}
		},
		emits: ['nodeclick', 'nodedblclick', 'nodedrop', 'checkchange', 'init', 'add', 'rename', 'delete'],

		data() {
			return {
				currentObj: null,
				nodeText: '',
				treeObj: {
					ref: null,
					allowDrag: () => {
						return true
					},
					allowDrop: () => {
						return true
					}
				},
			}
		},

		methods: {

			toAddNode() {
				if (this.nodes.length>0 && this.oneRoot && this.currentObj == null) {
					this.$logic.tip.warning('请选择新节点所在的父节点')
					return
				}
				let obj = this.currentObj
				if (this.autoDo) {
					this.$logic.message.input('新建', '即将在' + obj.textPath + '下新建节点，请输入新节点名称', (newName) => {
						if (newName && newName.length > 0) {
							newName = newName.trim()
							if (newName.length < 1) {
								this.$logic.tip.warning('未输入新节点名称')
							} else {
								this.$emit('add', obj, newName)
							}
						} else {
							this.$logic.tip.warning('未输入新节点名称')
						}
					})
				} else {
					this.$emit('add', obj, null)
				}

			},
			toRenameNode() {
				if (this.currentObj == null) {
					this.$logic.tip.warning('请选择需修改的节点')
					return
				}
				let obj = this.currentObj
				if (this.autoDo) {
					this.$logic.message.input('修改', {
						info: '请输入新节点名称',
						init: obj.data.label
					}, (newName) => {
						if (newName && newName.length > 0) {
							newName = newName.trim()
							if (newName.length < 1) {
								this.$logic.tip.warning('未输入新节点名称')
							} else {
								this.$emit('rename', obj, newName)
							}
						} else {
							this.$logic.tip.warning('未输入新节点名称')
						}
					})
				} else {
					this.$emit('rename', obj, null)
				}
			},
			toDeleteNode() {
				if (this.currentObj == null) {
					this.$logic.tip.warning('请选择需要删除的节点')
					return
				}
				let obj = this.currentObj
				/* if (this.nodes && this.nodes.length > 0 && obj.data === this.nodes[0]) {
					this.$logic.tip.warning('不允许删除根节点')
					return
				}
 */
				if (this.deleteEmpty && obj.data.children && obj.data.children.length > 0) {
					this.$logic.tip.warning('当前节点含有子节点，不允许删除')
					return
				}
				this.$logic.message.confirm('确认', '是否确定删除[' + obj.data.label + ']', () => {
					this.$emit('delete', obj)
				})
			},
			/* checkChange( node, nodeCheck, childrenCheck) {
				this.$emit('checkchange', node, nodeCheck, childrenCheck)
			}, */
			getCheckedAllNodes() { //不含半选状态节点
				return this.$refs.tree.getCheckedNodes(false, false)
			},
			getCheckedLeafNodes() {
				return this.$refs.tree.getCheckedNodes(true, false)
			},
			getCheckedTopNodes() { //node数据中必须含有parentId值
				let keys = this.$refs.tree.getCheckedKeys(false, false)
				let nodes = this.$refs.tree.getCheckedNodes(false, false)
				let keyMap = {}
				for (let key of keys) {
					keyMap[key] = true
				}
				let rs = []
				for (let node of nodes) { //如果当前节点的父节点Id不包含在keyMap中，即为顶级节点
					if (node.parentId in keyMap || node.pid in keyMap || node.pId in keyMap) {
						continue
					}
					rs.push(node)
				}
				return rs
			},
			checkChange(node, checkedObj) {
				let keys = checkedObj.checkedKeys
				let checks = checkedObj.checkedNodes
				let topChecks = [] //被勾选的顶层节点集合
				let nodeMap = {}
				let leafChecks = [] //被勾选的叶子节点集合
				for (let item of checks) {
					if (item.children == null || item.children.length < 1) {
						leafChecks.push(item)
					}
					let p1 = item
					let p2 = p1.parent
					while (p2) {
						if (keys.indexOf(p2.id) < 0) { //不存在表示已找到顶
							break
						}
						p1 = p2
						p2 = p2.parent
					}
					if (nodeMap[p1.id]) { //排除重复

					} else {
						topChecks.push(p1)
						nodeMap[p1.id] = p1
					}
				}
				checkedObj.topCheckNodes = topChecks
				checkedObj.leafCheckNodes = leafChecks
				this.$emit('checkchange', node, checkedObj)
			},
			onNodeDrop(draggingNode, targetNode, type, event) {
				this.$emit('nodedrop', draggingNode, targetNode, type, event)
			},
			nodeClick(key) { //API调用此函数模拟鼠标点击选择节点 
				setTimeout(() => {
					if (this.$refs.tree) {
						let node = this.$refs.tree.getNode(key)
						if (node) {
							this.$refs.tree.setCurrentNode(node.data)
							this.onNodeClick(node.data, node, null)
						}
					}
				}, 200)


			},
			getNodeObj(data, node, obj) {
				let nodes = []
				let p = node || this.$refs.tree.getNode(data) //如果没有传入node，根据数据获取
				let textPath = ""
				let idPath = ""
				while (p) {
					if (p.level > 0) {
						textPath = this.splitChar + p.data.label + textPath
						idPath = this.splitChar + p.data.id + idPath
						nodes.unshift(p)
					}
					p = p.parent
				}

				if (idPath.length > 1) { //去掉前导分隔符
					idPath = idPath.substring(this.splitChar.length)
					textPath = textPath.substring(this.splitChar.length)
				}
				let datas = {
					data,
					node,
					obj,
					nodes,
					textPath,
					idPath
				}
				return datas
			},
			onNodeDblClick(data, node) {
				let nodeObj = this.getNodeObj(data, node, null)
				this.$emit('nodedblclick', nodeObj)
			},
			onNodeClick(data, node, obj) {


				this.currentObj = this.getNodeObj(data, node, obj)
				this.$emit('nodeclick', this.currentObj)

			},

			filterNode(value, data) {
				if (!value) return true
				return data.label.indexOf(value) > -1
			},
			onAllowDrag(node) {
				let rs = this.treeObj.allowDrag(node)

				return rs
			},
			onAllowDrop(draggingNode, dropNode, type) { //type:'prev'、'inner' 和 'next'
				let rs = this.treeObj.allowDrop(draggingNode, dropNode, type)

				return rs
			}
		},
		watch: {
			nodeText(nv, ov) {
				this.$refs.tree.filter(nv)
			},
			nodes(nv, ov) {
				this.currentNode = null

			},
			checked(nv, ov) {
				this.$refs.tree.setCheckedNodes(nv)

			}


		},
		computed: {
			searchWidth() {
				let as = this.edit.split(',')
				let bw = 45 * as.length
				return {
					width: 'calc(100% - ' + 40 * as.length + 'px' + ')'
				}
			},
			buttonWidth() {
				let as = this.edit.split(',')
				let css = {
					width: 40 * as.length + 'px'
				}
				//console.log(css)
				return css
			},
			expandNode() {
				let nodes = null
				if (this.expanded && this.expanded.length > 0) {
					nodes = this.expanded
				} else if (this.nodes && this.nodes.length > 0) {
					nodes = [this.nodes[0].id]
				}
				return nodes
			}
		},
		mounted() {
			this.treeObj.ref = this.$refs.tree
			this.treeObj.ref.nodeClick = this.nodeClick
			this.$emit('init', this.treeObj)
		},
		created() {

		}
	}
</script>

<style scoped>
	.nodebar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: var(--bar-height);
		/* 	border-bottom: solid 1px #CCCCCC; */
		box-shadow: 0px 2px 5px 0px #CCCCCC;
		border-radius: 5px 5px 0px 0px;
		background-color: #F8F8F8;
		padding: 2px;
		margin-bottom: 10px;

	}

	.nodesearch {
		/* width: calc(100% - 130px); */
		min-width: 100px;
	}

	.nodeedit {
		min-width: 45px;
	}

	.nodeitem {
		display: flex;
		align-items: center;
	}

	.nodeitem i {
		margin-right: 5px;
	}

	:deep(.el-tree-node>.el-tree-node__children) {
		overflow: visible !important;
	}
</style>