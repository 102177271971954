<template>
	<el-upload v-show="model.allowUpoad==='Y'" style="margin-bottom: 10px;" ref="upload"
		:drag="model.drag=='Y' && model.mode=='area'" :action="uploadUrl" :multiple="model.multiple=='Y'" :data="params"
		:name="model.fileN" :show-file-list="false" :accept="acceptTypes" :thumbnail-mode="false" :file-list="[]"
		list-type="text" :auto-upload="model.auto=='Y'" :limit="toInt(model.limit)" :on-preview="onPreview"
		:on-remove="onRemove" :on-success="onSuccess" :on-error="onError" :on-progress="onProgress"
		:on-change="onChange" :before-upload="beforeUpload" :before-remove="beforeRemove" :on-exceed="onExceed">
		<template v-if="model.mode=='button'">
			<div class="uploadbutton">
				<el-button :loading="isLoading" :size="model.size" type="primary">
					<i v-show="!isLoading" class="far fa-arrow-alt-up"></i>
					上传
				</el-button>
				<div v-show=" isLoading" style="width:calc(100% - 110px) ; padding: 10px;">
					<el-progress :text-inside="true" :stroke-width="24" :percentage="uploadPercent" status="success" />

				</div>
				<span v-show=" ! isLoading && model.tipS=='Y'" class="uptip">
					{{uploadTip}}
				</span>
			</div>
		</template>
		<template v-else>
			<el-icon v-show=" isLoading" class="el-icon--upload is-loading">
				<loading />
			</el-icon>
			<el-icon v-show="! isLoading" class="el-icon--upload">
				<upload-filled />

			</el-icon>
			<div v-show="isLoading" style="width:calc(100% - 20px) ; padding: 10px;">
				<el-progress :text-inside="true" :stroke-width="24" :percentage="uploadPercent" status="success" />
			</div>

			<div v-show="! isLoading" class="el-upload__text">
				文件拖到此处 <em>或点击这里选择文件上传</em>
			</div>
			<div v-show="! isLoading && model.tipS=='Y'" class="uptip">
				{{uploadTip}}
			</div>
		</template>


	</el-upload>
	<el-table v-if="model.fileList==='Y'" :data="fileList" stripe border style="width: 100%;">
		<el-table-column prop="fileName" label="文件名">
			<template #default="scope">
				<el-link @click="fileClick(scope.row)" :href_="scope.row.url"
					target="_blank">{{ scope.row.fileName }}</el-link>
				<!-- <a :href="scope.row.url" target="_blank">
				<span style="margin-left: 10px">{{ scope.row.fileName }}</span>
				</a> -->
			</template>
		</el-table-column>
		<el-table-column prop="humanSize" label="大小" width="120px" />
		<el-table-column prop="createTime" label="上传时间" width="180px" />
		<el-table-column v-if="model.allowDelete==='Y'" prop="" label="删除" width="120px">
			<template #default="scope">
				<el-button @click="toDeleteFile(scope.row)" type="danger" icon="CloseBold" size="small">删除</el-button>
			</template>
		</el-table-column>

	</el-table>
	<div v-show="currentFile.show" class="file-view">
		<div class="file-info">
			<div>
				{{currentFile.name}}
				<a :href="currentFile.url" class="file-down">
					<i class="far fa-download"></i>
					下载</a>
			</div>
			<div style="padding-right: 20px;cursor:pointer;font-size: 20px;">
				<i class="far fa-times" @click="currentFile.show=false"></i>
			</div>
		</div>
		<div class="file-content">
			<iframe v-if="currentFile.url" :src="currentFile.url" class="file-frame"></iframe>

		</div>

	</div>

</template>

<script>
	/* 
	元素加载时获取服务器端生成到formData中的formId，
	上传的文件自带ID号保存到后台数据库，文件信息数据表中有表单ID字段，同时含saved字段默认为0表单保存后更新为1
	超过N天saved仍为0的数据将被清理(每次上传后启动文件清理的线程)，以清除垃圾文件
	后台固定使用group/unit/form/file/year/month/作为文件地址前缀
	 */

	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				uploadPercent: 0,
				acceptTypes: '',
				uploadList: [],
				isLoading: false,
				currentFile: {
					show: false
				},
				sysTick: new Date().getTime(),
			}
		},

		methods: {
			fileClick(file) {

				let url = this.$logic.http.baseUrl + (file.url ? file.url : '/upload/' + file.localPath)
				let currentFile = this.currentFile
				currentFile.name = file.fileName
				currentFile.url = url
				currentFile.show = true

			},
			toDeleteFile(item) {
				this.$logic.message.confirm('确认', '是否确定删除：' + item.fileName, () => {
					let idx = -1
					let c = 0
					for (let file of this.uploadList) {
						if (file === item) {
							idx = c
							break
						}
						c++
					}
					if (item.id > 0) { //
						item.fileTag = -1
						/* 						if (this.model.dbTable && this.model.idField) {//改为表单提交后一起处理
													this.$logic.http.post(true, '/api/editor/formdeletfile/', {
														appId: this.project.id,
														tableName: this.model.dbTable,
														idField: this.model.idField,
													}, item, res => {
														//this.uploadList = res.data.data
														if (idx > -1) {
															this.uploadList.splice(idx, 1)
														}
														this.$logic.tip.success('删除成功')
													})
												} else {
													this.$logic.tip.success('未定义此文件上传组件的表名、字段名，无法删除')
												} */
					} else { //未保存的直接删除	
						if (idx > -1) {
							this.uploadList.splice(idx, 1)
						}

					}
				})
			},
			onPreview(file) { //点击文件列表中已上传的文件时的钩子	function(file)	—	—

			},
			onRemove(file, fileList) { //文件列表移除文件时的钩子	function(file, fileList)	—	—

			},
			onSuccess(response, file, fileList) { //文件上传成功时的钩子	function(response, file, fileList)	—	—
				this.sysTick = new Date().getTime()
				this.showLoading(false)
				if (response.code == 0) {
					this.$logic.tip.success('上传成功')
					for (let item of response.data) {
						this.uploadList.push(item)
					}
				} else {
					this.$logic.tip.success('上传失败 ' + response.info || '')
				}
			},
			onError(err, file, fileList) { //文件上传失败时的钩子	function(err, file, fileList)	—	—
				this.sysTick = new Date().getTime()
				this.showLoading(false)
				let info = '' + err
				let x = info.indexOf('http')
				if (x > 0) {
					info = info.substring(0, x)
				}
				this.$logic.message.message('上传失败', '上传异常：' + info)
			},
			onProgress(event, file, fileList) { //文件上传时的钩子	function(event, file, fileList)	—	—
				this.uploadPercent = parseInt(event.percent * 100) / 100
				//console.log(event)
			},
			onChange(file, fileList) { //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用	function(file, fileList)	—	—

			},
			beforeUpload(file, fileList) { //上传文件之前的钩子，参数为上传的文件。 若返回 false 或者返回 Promise 且被 reject，则终止上传。	function(file)	—	—
				this.uploadPercent = 0
				let fields =
					'idField=ID字段,pidField=关联ID,fileNameField=文件名,localPathField=文件路径,fileSizeField=文件大小,createTimeField=创建时间,creatorIdField=上传人,groupNameField=文件组'
					.split(',')
				for (let item of fields) {
					let ps = item.split('=')
					let key = ps[0]
					let label = ps[1]
					let field = this.model[key]
					if (!field.fieldName) {
						this.$logic.tip.error('未设置' + label + '所在的数据库表字段,无法上传文件')
						return false
					}
				}

				this.showLoading(true)
			},
			beforeRemove(file,
				fileList
			) { //	删除文件之前的钩子，参数为上传的文件和文件列表。 若返回 false 或者返回 Promise 且被 reject，则终止删除。	function(file, fileList)	—	—

			},
			onExceed(files, fileList) { //文件超出个数限制时的钩子	function(files, fileList)	—	-
				this.$logic.tip.warning('超出批量上传最大数量：' + this.model.limit + ',当前数量:' + files.length)
			},
			showLoading(isShow) {
				this.isLoading = isShow
				if (isShow) {
					this.$logic.showLayer(true)
				} else {
					this.$refs.upload.clearFiles() //这句会引发上传组件不执行onSuccess 和onError事件,在上传之前不能调用
					this.$logic.showLayer(false)
				}

			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return this.uploadList
				} else {
					if (Array.isArray(value)) {
						this.uploadList = value
					} else {
						this.$logic.tip.error('文件列表只接受数组类型数据赋值')
					}

				}
			},
			$load(formId) {
				this.loadFileList(formId)
			},
			loadFileList(formId) {
				let model = this.model
				if (!model.idParam) {
					this.$logic.message.message('错误', model.name + '的关联参数名未设置')
					return
				}
				let pid = this.page.$params[model.idParam]
				if (pid === null || pid === '') {
					this.$logic.message.message('错误', model.name + '未获取到关联参数值，请确认该页面地址参数名与设置值[' + model.idParam + ']一致')
					return
				}
				if (parseInt(pid) < 1) { //无效ID值不查询数据
					return
				}
				pid = formId || pid
				let params = [pid]
				let sql = 'select ' + model.idField.fieldName + ' as id, ' +
					model.pidField.fieldName + ' as pid, ' +
					model.fileNameField.fieldName + ' as fileName, ' +
					model.localPathField.fieldName + ' as localPath, ' +
					model.fileSizeField.fieldName + ' as fileSize, ' +
					model.createTimeField.fieldName + ' as createTime, ' +
					model.creatorIdField.fieldName + ' as creatorId '
				/* if (model.groupNameField.fieldName) {
					sql = sql + ',' + model.groupNameField.fieldName + ' as groupName '
				} */
				sql = sql + ' from ' + model.dbTable + ' where ' + model.pidField.fieldName + '=?'
				/* if (model.groupNameField.fieldName && model.groupNameField.fieldName.length>0) {
				
				} + */
				sql = sql + ' and ' + model.groupNameField.fieldName + '=?' //固定增加组条件
				params.push(model.groupName)
				' order by ' + model.idField.fieldName
				this.$logic.http.sqlQuery(this.project.dataSource.id, sql, params, {
					isJson: true
				}).then(res => {
					this.uploadList = res.data.data.dataList
					let kb = 1024;
					let mb = 1024 * 1024;
					let gb = 1024 * 1024 * 1024;
					for (let item of this.uploadList) {
						//设置时间格式：'1716471622000'毫秒数转换createTime
						if (item.createTime && item.createTime.length && item.createTime.length == 13) {
							let dt = new Date(parseInt(item.createTime))
							item.createTime = this.$logic.date.getString(dt)
						}

						let fileSize = item.fileSize
						if (!fileSize) {
							continue
						}
						if (fileSize >= gb) {
							let gp = Math.round(fileSize * 100.0 / gb)
							item.humanSize = (gp / 100) + "GB";
						} else if (fileSize >= mb) {
							let mp = Math.round(fileSize * 100.0 / mb)
							item.humanSize = (mp / 100) + "MB";
						} else {
							let kp = Math.round(fileSize * 100.0 / kb)
							item.humanSize = (kp / 100) + "KB";
						}
					}
					this.dataTo(model.dataTo, this.uploadList)
					this.doEvent({
						eventName: 'loadafter',
						files: this.uploadList
					}, '$comp_loadAfter')
				}).catch(err => {
					this.$logic.tip.error('数据请求异常：' + err.info)
				})

			}


		},
		computed: {
			uploadUrl() {
				return this.$logic.http.baseUrl + '/api/editor/formupload/'
			},
			uploadTip() {
				let tip = ''
				let model = this.model
				let types = this.model.accept
				let len = this.toInt(model.len)
				if (types.length > 0) {
					tip = '仅限：' + types + '类型文件'
				} else {
					tip = '可上传任何类型'
				}
				tip = tip + ',' + len + 'M以内'
				return tip
			},
			params() {
				let user = this.$logic.getUser()

				let param = {
					//	formId: this.data.formId,
					groupName: this.model.groupName,
					appId: this.project.id,
					$$unitGroup: user.unitGroup,
					$$unitId: user.unitId,
					$$userId: user.id,
					$$tick: this.sysTick,

				}
				param.$$serial = this.$logic.http.getToken(param)
				return param
			},
			fileList() {
				let list = []
				for (let item of this.uploadList) {
					if (item.fileTag !== -1) {
						list.push(item)
					}
				}
				return list
			}

		},
		created() {
			//this.onSuccessTest=this.onSuccess 
			if (this.isedit) {
				let tm = new Date().toLocaleString()
				this.uploadList = [{
					id: 0,
					groupName: '',
					fileType: 'doc',
					fileName: 'Word文件1',
					humanSize: '0.5M',
					createTime: tm
				}, {
					id: 0,
					groupName: '',
					fileType: 'xlsx',
					fileName: 'Excel文件2',
					humanSize: '10M',
					createTime: tm
				}, {
					id: 0,
					groupName: '',
					fileType: 'jpg',
					fileName: 'Jpg文件3',
					humanSize: '20M',
					createTime: tm
				}, {
					id: 0,
					groupName: '',
					fileType: 'rar',
					fileName: 'Rar文件4',
					humanSize: '50M',
					createTime: tm
				}]

			} else {
				/* this.$logic.http.get(true,'/api/editor/formgetfiles/',{formId:this.data.formId},res=>{
				this.uploadList = res.data.data
				}) */
				this.model.$value = this.$value
				this.model.$load = this.$load
				let model = this.model
				let info = ''
				if (!model.dbTable) {
					info = info + ',数据库表名'
				}
				let fields =
					'idField=Id字段,pidField=关联Id字段,fileNameField=文件名字段,localPathField=路径字段,fileSizeField=大小字段,createTimeField=时间字段,creatorIdField=上传人字段'
					.split(',')

				for (let item of fields) {
					let x = item.indexOf('=')
					let key = item.substring(0, x)
					let label = item.substring(x + 1)
					let fn = model[key].fieldName
					if (fn == null || fn == '') {
						info = info + ',' + label
					}
				}
				if (info.length > 0) {
					this.$logic.message.message('错误', '未设置文件上传组件必要的属性,' + info)
				} else {
					this.loadFileList()
				}


			}
			//console.log(this.data.formId)

		}
	}
</script>

<style scoped>
	.uploadbutton {
		width: 100%;
		display: flex;
		align-items: center;

	}

	.uptip {
		font-size: 14px;
		margin-left: 10px;
		margin-top: 10px;
	}

	.filetype {
		width: 30px;
		height: 20px;
	}

	.file-view {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 10000;
		background-color: #ececec;
	}

	.file-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 30px;
		color: #ffffff;
		background-color: #000000;
	}

	.file-down {
		margin-left: 20px;
		color: #ffffff;

	}

	.file-content {
		width: 100%;
		height: calc(100% - 30px);
	}

	.file-frame {
		width: 100%;
		height: 100%;
	}

	:deep(.el-upload) {
		width: 100%;
	}

	:deep(table) {
		background: linear-gradient(#fcfcfc, #f5f5f5, #fcfcfc);
	}

	:deep(thead th) {
		background: linear-gradient(#fcfcfc, #f5f5f5, #fcfcfc);
	}

	:deep(th .cell) {
		text-align: center;
	}

	:deep(.el-table__row .el-table__cell) {
		padding: 5px 0px;
	}
</style>