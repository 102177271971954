import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'

let base=[
	{key:'title',text:'分割线'},
	{key:'direction',text:'方向',type:'select',init:'horizontal',target:'',bursh: true,bind: true,tip:'',list:options.Directions},	 
	{key:'leftIcon',text:'左侧图标',type:'icona',init:'far fa-star',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key:'text',text:'文本内容',type:'text',init:'分割线',target:'',bursh: true,bind: true,tip:'',list:null},	 
    {key:'position',text:'内容位置',type:'select',init:'center',target:'',bursh: true,bind: true,tip:'',list:options.LCRS},	
	{key:'bgColor',text:'背景颜色',type:'colorA',init:'rgba(255, 255, 255, 1)',target:'',bursh: true,bind: true,tip:'',list:null},	 

    {key:'rightIcon',text:'右侧图标',type:'icona',init:'',target:'',bursh: true,bind: true,tip:'',list:null},	 
	{key:'border',text:'线条类型',type:'select',init:'solid',target:'',bursh: true,bind: true,tip:'',list:options.BorderType},	
	{key:'lineColor',text:'线条颜色',type:'color',init:'#dcdfe6',target:'',bursh: true,bind: true,tip:'',list:null},	

	{key: 'width',text: '宽度',type: 'text',init: 'calc(100% - 0px)',	target: 'width',	extend: false,tip: '',list: options.YON},

]

let divider= {
	data:{
		type:'divider',marginT:'0px',marginB:'0px',marginL:'0px',marginR:'0px'
	},
	delete:'',
	hide:'width',
	base: base.concat(font).concat(compBase.base),
	special :[],
	action:[]
}

export default divider