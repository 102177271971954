<template>
	<navigation v-if="model.type=='menu'" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />
	<!-- 	<tableList v-else-if="model.type=='list'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" /> -->
	<inputlist v-else-if="model.type=='inputlist'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<!-- 	<treetable v-else-if="model.type=='treetable'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" /> -->
	<bread v-else-if="model.type=='bread'" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />
	<treeview v-else-if="model.type=='treeview'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<calendar v-else-if="model.type=='calendar'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<div v-else>未知高级元素</div>
</template>

<script>
	import Bread from './Bread.vue'
	import Menu from './Menu.vue'
	//import List from './List.vue'
	import InputList from './InputList.vue'
	//import TreeTable from './TreeTable.vue'
	import formBase from '../../../formbase.js'
	import TreeView from './TreeView.vue'
	import Calendar from './Calendar.vue'

	export default {
		mixins: [formBase],
		//props: ["model", "datas", "isedit"],
		data() {
			return {
				superComp: true
			}
		},

		methods: {

		},
		created() {


		},
		components: {
			bread: Bread,
			navigation: Menu,
			//tableList: List,
			inputlist: InputList,
			//	treetable: TreeTable,
			treeview: TreeView,
			calendar: Calendar

		},

	}
</script>

<style scoped>

</style>