<template>

	<div class="editor" :style="{'--minHeight':minheight}">
		<Codemirror v-model:value="codeValue" :options="editOption" border placeholder="" class="editor"
			:style="{fontSize:fontsize,'--code-line-hight':lineheight,'--code-font-weight':fontweight}"
			@change="codeEditorChange" />
		<el-select v-if="themetool" v-model="editorTheme" placeholder="编辑器外观主题" @change="changeTheme"
			style="float: right;margin-top: -50px;margin-right: 20px;width:160px" size="small">
			<el-option v-for="item in thems" :label="item.label" :value="item.name"></el-option>
		</el-select>
	</div>
</template>

<script>
	//引入语言,配置后生效

	import 'codemirror/mode/javascript/javascript.js'
	import 'codemirror/mode/css/css.js'
	import 'codemirror/mode/xml/xml.js'
	import 'codemirror/mode/python/python.js'
	import 'codemirror/mode/shell/shell.js'
	import 'codemirror/mode/sql/sql.js'
	import 'codemirror/mode/vue/vue.js'
	import 'codemirror/mode/stylus/stylus.js'
	import 'codemirror/mode/yaml/yaml.js'
	import 'codemirror/mode/nginx/nginx.js'
	import 'codemirror/mode/htmlmixed/htmlmixed.js'

	// 引入主题,配置后生效
	import 'codemirror/theme/cobalt.css' //cobalt 浅蓝中亮
	import 'codemirror/theme/colorforth.css' //colorforth 黑底蓝序号高亮
	import 'codemirror/theme/darcula.css' //darcula 灰黑亚光
	import 'codemirror/theme/eclipse.css' //eclipse 白底灰序号中亮
	import 'codemirror/theme/idea.css' //idea  白底灰序号中亮
	import 'codemirror/theme/liquibyte.css' //liquibyte 黑底灰序号高亮
	import 'codemirror/theme/mbo.css' //mbo 黑灰底灰序号 亚光
	import 'codemirror/theme/midnight.css' //midnight 蓝底中亮
	import 'codemirror/theme/night.css' //night 深蓝高亮
	import 'codemirror/theme/rubyblue.css' //rubyblue 深蓝浅蓝序号高亮
	import 'codemirror/theme/shadowfox.css' //shadowfox 深灰黑序号中亮
	import 'codemirror/theme/solarized.css' //solarized 白底灰序号中亮
	import 'codemirror/theme/ssms.css' //ssms 白底黄序号分隔线中亮
	import 'codemirror/theme/twilight.css' //twilight 黑底灰序号亚光
	import 'codemirror/theme/xq-dark.css' //xq-dark 深蓝中亮
	import 'codemirror/theme/yeti.css' //yeti 灰色亚光

	//支持代码折叠
	import 'codemirror/addon/fold/foldgutter.css'
	import 'codemirror/addon/fold/foldcode.js'
	import 'codemirror/addon/fold/foldgutter.js'
	import 'codemirror/addon/fold/brace-fold.js'
	import 'codemirror/addon/fold/comment-fold.js'
	import 'codemirror/mode/swift/swift.js'

	import thems from './thems.js'

	export default {
		props: {
			modelValue: {
				type: String
			},
			mode: {
				type: String,
				default: 'text/javascript'
			},
			theme: {
				type: String,
				default: 'liquibyte'
			},
			readonly: {
				type: Boolean,
				default: false
			},
			fontsize: {
				type: String,
				default: '28px'
			},
			fontweight: {
				type: String,
				default: '100'
			},
			lineheight: {
				type: String,
				default: '120%'
			},
			themetool: {
				type: Boolean,
				default: false
			},
			minheight: {
				type: String,
				default: '100px'
			},
			tick: { //触发刷新DOM，在具有隐藏/显示的容器中设置此属性
				default: 0
			}
		}, //
		data() {
			return {
				thems: thems,
				codeValue: '',
				editorTheme: "",
				editOption: {
					mode: "text/javascript", // Language mode
					theme: "dracula", // Theme
					lineNumbers: true, // Show line number
					smartIndent: true, // Smart indent
					indentUnit: 2, // The smart indent unit is 2 spaces in length
					foldGutter: true, // Code folding
					styleActiveLine: true, // Display the style of the selected row
				},
			}
		},

		methods: {
			codeEditorChange(value) {
				this.$emit('update:modelValue', value)
			},
			editorInit() {
				this.codeValue = this.modelValue ? (this.modelValue == '?' ?
					`
	//参数：mode,theme,code,readonly,fontsize,themtool
	//mode:语言模式，默认javascript，选项：javascript,java,sql,css,vue,xml,html,nginx,python,shell
	//theme:外观主题，默认：darcula，选项：cobalt,colorforth,darcula,eclipse,idea,liquibyte,mbo,midnight,night,rubyblue,shadowfox,solarized,ssms,twilight,xq-dark,yeti,zenburm
	//v-model 双向绑定code内容
	//readonly:是否只读，默认false
	//fontsize:字号，默认auto,格式:12px、16px等
	//themtool:是否显示主题设置工具，默认true
	` :
					this.modelValue) : ''
				//this.editMode = "text/" + (this.mode ? this.mode.toLowerCase() : "sql")
				if (this.theme) {
					this.editorTheme = this.theme
				}
				//没有直接提供的java模式，经试验采用text/x-java有效
				let mode = this.mode ? (this.mode == "java" ? "text/x-java" : this.mode) : "text/javascript"
				mode = mode == "html" ? "text/html" : mode
				this.editOption = this.options ? this.options : {
					tabSize: 2, // 缩进格式
					theme: this.editorTheme, // 指定主题，对应主题库 JS 需要提前引入
					lineNumbers: true, // 是否显示行号
					//指定语言类型,如果需要编辑和显示其他语言,需要import语言js然后修改此配置
					mode: mode,
					line: true,
					lineWrapping: true, //代码折叠
					foldGutter: true,
					gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
					styleActiveLine: true, // 高亮选中行
					//是否为只读,如果为"nocursor" 不仅仅为只读 连光标都无法在区域聚焦
					readOnly: this.readonly ? this.readonly : false,
					autofocus: true,
					smartIndent: true, //自动缩进
					matchBrackets: true, //括号匹配
					autoCloseBrackets: true, //自动括号补全
					hintOptions: {
						completeSingle: true // 当匹配只有一项的时候是否自动补全
					}
				}
				/*
					indentUnit: integer 缩进单位，值为空格数，默认为2 。
					
					smartIndent: boolean 自动缩进，设置是否根据上下文自动缩进（和上一行相同的缩进量）。默认为true。
					
					tabSize: integer tab字符的宽度，默认为4 。
					
					indentWithTabs: boolean 在缩进时，是否需要把 n*tab宽度个空格替换成n个tab字符，默认为false 。
					
					electricChars: boolean 在输入可能改变当前的缩进时，是否重新缩进，默认为true （仅在mode支持缩进时有效）。
					
					specialChars: RegExp 需要被占位符(placeholder)替换的特殊字符的正则表达式。最常用的是非打印字符。默认为：/[\u0000-\u0019\u00ad\u200b-\u200f\u2028\u2029\ufeff]/。
					
					specialCharPlaceholder: function(char) → Element 这是一个接收由specialChars选项指定的字符作为参数的函数，此函数会产生一个用来显示指定字符的DOM节点。默认情况下，显示一个红点（•），这个红点有一个带有前面特殊字符编码的提示框。
					
					rtlMoveVisually: boolean Determines whether horizontal cursor movement through right-to-left (Arabic, Hebrew) text is visual (pressing the left arrow moves the cursor left) or logical (pressing the left arrow moves to the next lower index in the string, which is visually right in right-to-left text). The default is false on Windows, and true on other platforms.（这段完全不晓得搞啥子鬼）
					
					keyMap: string 配置快捷键。默认值为default，即 codemorrir.js 内部定义。其它在key map目录下。
					
					extraKeys: object 给编辑器绑定与前面keyMap配置不同的快捷键。
					
					lineWrapping: boolean 在长行时文字是换行(wrap)还是滚动(scroll)，默认为滚动(scroll)。
					
					lineNumbers: boolean 是否在编辑器左侧显示行号。
					
					firstLineNumber: integer 行号从哪个数开始计数，默认为1 。
					
					lineNumberFormatter: function(line: integer) → string 使用一个函数设置行号。
					
					gutters: array 用来添加额外的gutter（在行号gutter前或代替行号gutter）。值应该是CSS名称数组，每一项定义了用于绘制gutter背景的宽度（还有可选的背景）。为了能明确设置行号gutter的位置（默认在所有其它gutter的右边），也可以包含CodeMirror-linenumbers类。类名是用于传给setGutterMarker的键名(keys)。
					
					fixedGutter: boolean 设置gutter跟随编辑器内容水平滚动（false）还是固定在左侧（true或默认）。
					
					scrollbarStyle: string 设置滚动条。默认为”native”，显示原生的滚动条。核心库还提供了”null”样式，此样式会完全隐藏滚动条。Addons可以设置更多的滚动条模式。
					
					coverGutterNextToScrollbar: boolean 当fixedGutter启用，并且存在水平滚动条时，在滚动条最左侧默认会显示gutter，当此项设置为true时，gutter会被带有CodeMirror-gutter-filler类的元素遮挡。 inputStyle: string 选择CodeMirror处理输入和焦点的方式。核心库定义了textarea和contenteditable输入模式。在移动浏览器上，默认是contenteditable，在桌面浏览器上，默认是textarea。在contenteditable模式下对IME和屏幕阅读器支持更好。
					
					readOnly: boolean|string 编辑器是否只读。如果设置为预设的值 “nocursor”，那么除了设置只读外，编辑区域还不能获得焦点。
					
					showCursorWhenSelecting: boolean 在选择时是否显示光标，默认为false。
					
					lineWiseCopyCut: boolean 启用时，如果在复制或剪切时没有选择文本，那么就会自动操作光标所在的整行。
					
					undoDepth: integer 最大撤消次数，默认为200（包括选中内容改变事件） 。
					
					historyEventDelay: integer 在输入或删除时引发历史事件前的毫秒数。
					
					tabindex: integer 编辑器的tabindex。
					
					autofocus: boolean 是否在初始化时自动获取焦点。默认情况是关闭的。但是，在使用textarea并且没有明确指定值的时候会被自动设置为true。
					
					dragDrop: boolean 是否允许拖放，默认为true。
					
					allowDropFileTypes: array 默认为null。当设置此项时，只接收包含在此数组内的文件类型拖入编辑器。文件类型为MIME名称。
					
					cursorBlinkRate: number 光标闪动的间隔，单位为毫秒。默认为530。当设置为0时，会禁用光标闪动。负数会隐藏光标。
					
					cursorScrollMargin: number 当光标靠近可视区域边界时，光标距离上方和下方的距离。默认为0 。
					
					cursorHeight: number 光标高度。默认为1，也就是撑满行高。对一些字体，设置0.85看起来会更好。
					
					resetSelectionOnContextMenu: boolean 设置在选择文本外点击打开上下文菜单时，是否将光标移动到点击处。默认为true。
					
					workTime, workDelay: number 通过一个假的后台线程高亮 workTime 时长，然后使用 timeout 休息 workDelay 时长。默认为200和300 。（完全不懂这个功能是在说啥）
					
					pollInterval: number 指明CodeMirror向对应的textarea滚动（写数据）的速度（获得焦点时）。大多数的输入都是通过事件捕获，但是有的输入法（如IME）在某些浏览器上并不会生成事件，所以使用数据滚动。默认为100毫秒。
					
					flattenSpans: boolean 默认情况下，CodeMirror会将使用相同class的两个span合并成一个。通过设置此项为false禁用此功能。
					
					addModeClass: boolean 当启用时（默认禁用），会给每个标记添加额外的表示生成标记的mode的以cm-m开头的CSS样式类。例如，XML mode产生的标记，会添加cm-m-xml类。
					
					maxHighlightLength: number 当需要高亮很长的行时，为了保持响应性能，当到达某些位置时，编辑器会直接将其他行设置为纯文本(plain text)。默认为10000，可以设置为Infinity来关闭此功能。
					
					viewportMargin: integer 指定当前滚动到视图中内容上方和下方要渲染的行数。这会影响到滚动时要更新的行数。通常情况下应该使用默认值10。可以设置值为Infinity始终渲染整个文档。注意：这样设置在处理大文档时会影响性能。
				
					
					
					*/
				//this.changeMode("text/x-java")
				//this.changeMode("text/html")

				//	this.$forceUpdate()

			},
			changeTheme() {
				this.editOption.theme = this.editorTheme
			},
			changeMode(val) {
				this.editOption.mode = val
			}
		},
		watch: {
			modelValue(nv, ov) {
				this.codeValue = nv
			},
			tick(nv) { //触发刷新DOM，在具有隐藏/显示的容器中设置此属性
				let cs = this.codeValue
				this.codeValue = 'loading...'
				setTimeout(() => {
					this.codeValue = cs
				}, 100) 
			}

		},
		mounted() {
			this.editorInit()
		},
		created() {

		}
	}
</script>

<style scoped>
	.editor {
		height: 100%;
		min-height: val(--minHeight);
		
	}

	:deep(.CodeMirror-line span) {
		/* font-weight: val(--code-font-weight);
		line-height: val(--code-line-hight); */
		font-weight: 100;
		line-height:130%;
		
	}

	:deep(.codemirror-container) {
		height: 100% !important;
	}

	:deep(.vue-codemirror) {
		height: 100%;
	}
</style>
