import options from '../../../../../public/options.js'
import initColor from '../../initColor.js'
import mapArea from './maparea.js'
 let initData=JSON.stringify( [
 	['名称', '类型', '颜色','大小','数值','经度','纬度'],
 	['黑龙江', 'area', '#ffff00',null,,null,null,null],
 	['江苏', 'area','#ffff00',null,null,null,null],
 	['吉林', 'area', '#00ff00',null,null,null,null],
 	['云南', 'area', '#0000ff',null,null,null,null],
 	['台湾', 'area', '#ff00ff',null,null,null,null],
	['北京', 'pin', '#ff0000',40,50,116.2529,39.5420], 
	['南京', 'point','#ff0000',15,60,118.767413,32.041544], 
	['西宁', 'point', '#ffff00',20,70,101.778916,36.623178], 
	['乌鲁木齐', 'wave', '#ff00ff',20,80,87.617733,43.792818], 
	['长沙', 'pin', '#0000ff',30,90,112.982279,28.19409],
	['拉萨', 'wave', '#00ff00',15,90,91.132212,29.660361],
 ]).replaceAll('],','],\r\n')
 
let base = mapArea.base
let special =[
	{key:'title',text:'地图标记'},
	{key:'seriesTypePoint',text:'图形类型',type:'text',init:'effectScatter',path:'_point.type',bursh:true,bind:true,tip:'',list:null},
//	{key:'seriesTypePoint',text:'图形类型',type:'text',init:'effectScatter',path:'_point.type',bursh:true,bind:true,tip:'',list:null},
	{key:'coordinateSystemPoint',text:'图形类型',type:'text',init:'geo',path:'_point.coordinateSystem',bursh:true,bind:true,tip:'',list:null},
	{key:'encodePoint',text:'图形类型',type:'number',init:2,path:'_point.encode.value',bursh:true,bind:true,tip:'',list:null},
	{key:'pointColor',text:'标记颜色',type:'colorA',init:'#fff000',path:'_point.itemStyle.color',bursh:true,bind:true,tip:'数据中指定的颜色优先于此设置',list:null},
	{key:'pointSize',text:'标记大小',type:'number',init:10,path:'_point.symbolSize',bursh:true,bind:true,tip:'数据中指定的尺寸大小优先于此设置',list:null},
	
	{key: 'pointLabelShow',text: '显示标签',type: 'switch',init: 'Y',path: '_point.label.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key:'pointLabelFontSize',text:'标签字号',type:'number',init:'12',path:'_point.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'pointLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_point.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'pointLabelFontColor',text:'标签颜色',type:'color',init:'#000000',path:'_point.label.color',bursh:true,bind:true,tip:'',list:null},
	{key:'pointLabelFontPosition',text:'标签位置',type:'select',init:'top',path:'_point.label.position',bursh:true,bind:true,tip:'',list:options.chartPosition},
	{key: 'pointLabelRotate',text: '标签旋转',type: 'number',init: 0,path: '_point.label.rotate',bursh: true,bind: true,tip: '',list: null},
	{key:'title',text:'动感光波'}, 
	{key:'pointEffectColor',text:'波纹颜色',type:'color',init:null,path:'_point.rippleEffect.color',bursh:true,bind:true,tip:'',list:null},
    {key:'pointEffectPeriod',text:'波动周期',type:'number',init:4,path:'_point.rippleEffect.period',bursh:true,bind:true,tip:'',list:null},
    {key:'pointEffectScale',text:'放大比例',type:'number',init:5,path:'_point.rippleEffect.scale',bursh:true,bind:true,tip:'',list:null},
	{key:'pointEffectBrushType',text:'波纹类型',type:'select',init:'fill',path:'_point.rippleEffect.brushType',bursh:true,bind:true,tip:'',list:options.toOptions('空心圈=stroke,实心圈=fill')},
	{key:'pointEffectNumber',text:'波纹数量',type:'number',init:3,path:'_point.rippleEffect.number',bursh:true,bind:true,tip:'',list:null},

	
].concat().concat(mapArea.special)
let action = mapArea.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_mapPoint',initData:initData,mapLabelShow:'N',
		 mapAreaBorderWidth:1, color: initColor.mapPoint,
		chartClan: 'map',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'width,height,seriesTypePoint,coordinateSystemPoint,encodePoint,manualSeries,dataOption',
	base:[].concat(base) ,
	special: special,
	action: [].concat(action)
}
export default cfg
