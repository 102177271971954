import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
import Clone from '../../../../../plugins/deepClone.js'


let events=[
/* 	{key:'title',text:'组件业务事件'},
	{key: '$comp_open',text: '对话框弹出',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '对话框弹出前',list: null	},
	{key: '$comp_nodeclick',text: '节点点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '点击节点触发',list: null	},
	{key: '$comp_before',text: '选择完成前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '完成选择前触发，返回false阻止对话框选择，必须使用同步方法',list: null	},
	{key: '$comp_after',text: '选择完成后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '完成选择后触发',list: null	},
	 */
]
let dataConfig=compBase.getDataConfig()
let configText= Clone.deepClone(dataConfig)

let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'

let ruleId= validate.validateRule
let ruleText= Clone.deepClone(ruleId) 


let base = [
{key: 'title',text: '用户组织'	},
{key: 'userId',text: '用户ID',type: 'text',init: null,	target: '',	bursh: true,bind: true,tip: '此属性值与用户选择组件自动联动或运行时程序设置，改变用户ID值将重置此用户的组织关系列表'},
{key: 'orgId',text: '组织ID',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '当前用户的所在组织ID'},
{key: 'orgText',text: '组织名称',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '当前组织名称，如保存至数据库(某些场景可能需要记录原始数据)，以此数据库加载的为准，否则使用系统组织名称'},
{key: 'format',text: '名称格式',type: 'select',init: 'path',	target: '',	bursh: true,bind: true,tip:'',list: options.toOptions('组织名称=name,组织路径=path') },
{key: 'splitChar',text: '层级分隔符',type: 'text',init: '/',	target: '',	bursh: true,bind: true,tip: ''},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
{key: 'fieldId',text: 'ID字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'orgId'},
{key: 'fieldText',text: '名称字段',type: 'field',init: configText,	target: '',	bursh: true,bind: false,tip: '',attribute:'orgText'},

{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
{key: 'validateId',text: 'ID校验',type: 'validate',init: ruleId,	target: '',	bursh: true,bind: false,tip: '',attribute:'orgId'},
//{key: 'validateText',text: '文本校验',type: 'validate',init: ruleText,	target: '',	bursh: true,bind: false,tip: '',attribute:'nodeText'},
{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},



	 compBase.status2,
{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 


]
 
  
let userorg = {
	data: {
		type: 'userorg',isInput:'M',
	},
	delete: '',
	hide: 'width',
	base: base.concat(compBase.base),
	special:extend.tip.concat( [
		
		 ]),

	action:events.concat( options.getEvents(null,'$event_click,$event_change').concat(options.getEvents(null,'$hook')))
}

export default userorg
