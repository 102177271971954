import md5 from './md5.js'

class Security {
	getMd5(str, ex) {
		let rs = md5(str)
		if (ex) {
			rs = md5(rs + ex)
		}
		return rs
	}

	getBase64(data) {
		return btoa(unescape(encodeURIComponent(data)))
	}

	fromBase642(data) {


		let cs = data.split('');
		for (let i = 1; i < cs.length; i = i + 2) {
			let c = cs[i];
			cs[i] = cs[i - 1];
			cs[i - 1] = c;
		}
		let rs = cs.join('')
		rs = rs.replace(/@/g, "+")
		rs = rs.replace(/\$/g, "/")
		rs = rs.replace(/_/g, "=")
		rs=this.fromBase64(rs)
		return rs


	}

	fromBase64(data) {
		return decodeURIComponent(escape(atob(data)))
	}
	encodeSql(sql) {		
		let rs = '$' + this.getBase64(sql)
		rs = rs.replace(/\+/g, "@")
		rs = rs.replace(/\//g, "$")
		rs = rs.replace(/=/g, "_")
		return rs
	}
}

export default new Security()