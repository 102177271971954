<template>
	<logicdialog @ok="iconDialogOk" @init="iconDialogInit" @close="iconDialogClose" title="图标选择" icon="fad fa-acorn">
	<div class="content">
		<el-tabs v-model="iconType" type="card">
			<el-tab-pane label="常规" name="regulars"></el-tab-pane>
			<el-tab-pane label="实体" name="solids"></el-tab-pane>
			<el-tab-pane label="空心" name="lights"></el-tab-pane>
			<el-tab-pane label="双色" name="duotones"></el-tab-pane>
			<el-tab-pane label="品牌商标" name="brands"></el-tab-pane>
		</el-tabs>
		<div class="search">查找：
			<div class="serchbox">
				<el-input v-model="iconKey" size="default" placeholder="请输入关键字" prefix-icon="Search" clearable  />
			</div>
			共<span>{{datas.length}}</span>个图标
		</div>

		<div class="items">
			<template v-for="item in datas">
				<div @click.stop="iconSelect(item)" :class="item==iconSel?'iconitem iconsel' : 'iconitem'">
					<i :class="iconhead+item"></i>
					<div class="label">{{item}}</div>
				</div>

			</template>
		</div>
	</div>
	</logicdialog>
</template>

<script>
	//提取资源文件夹svgs下的各分类下的文件名，以此数据生成所有图标
	import iconDatas from './fonts.js'
	export default {
		props:{
	/* 		showModal:{
				type:Boolean,
				default:false
			} */
		},
		emits:['select','init'],
		data() {
			return {
				iconKey: '',
				iconType: 'regulars',
				iconDialog:null,
				iconSel:'',
				 
			}
		},

		methods: {
			hide(){
				this.iconDialog.visible=false
			},
			show(){
				this.iconDialog.visible=true
			},
			iconDialogClose(){
				this.iconSel=''
			},
			iconSelect(icon){
				this.iconSel=icon
			},
			iconDialogOk(){
				if(this.iconSel.length>0){
					this.$emit('select','fa' + this.iconType.substring(0, 1) +' fa-'  + this.iconSel)
				}else{
					this.$logic.tip.error('未选择图标')
				}
				
			},
			iconDialogInit(dialog){
				this.iconDialog=dialog
				dialog.width = '900px'
				dialog.minHeight = '80vh'
				dialog.maxHeight = '80vh'
				dialog.fullHeight = true
				
				this.$emit('init',this.iconDialog)
				
			}

		},
		watch: {
			 
		},
		computed: {
			datas() {
				let ds=iconDatas[this.iconType]
				let key=this.iconKey
				if(key.length>0){
					let list=[]
					for(let item of ds){
						if(item.indexOf(key)>-1){
							list.push(item)
						}
					}
					return list
				}else{
					return ds
				}
				
			},
			iconhead() {
				return 'icon fa' + this.iconType.substring(0, 1) + ' fa-'
			}
		},
		created() {
		

		}
	}
</script>

<style scoped>
	.search {
		width:calc(100% - 20px) ;
		display: flex;
		align-items: center;
		padding-left: 20px;
	}

	.serchbox {
		width: 250px;
		margin-right: 20px;
	}

	.items {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		width:100%;
		height:calc(100% - 100px) ;
		overflow: auto;

	}

	.iconitem {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		margin: 5px;
		width: 80px;
		height: 80px;
		border: solid 1px #CCCCCC;
		background-color: #efefef;
		border-radius: 10px;
		cursor: pointer;

	}

	.iconitem:hover {
		background-color: #f8f8f8;
	}
.iconsel{
	background-color: #ffffd7;
}
	.icon {
		font-size: 25px;
	}

	.label {
		width: 100%;
		padding: 5px;
		text-align: center;
		font-size: 12px;
	}
</style>
