import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
let base = [
	{key: 'title',text: '多行文本框'	},
	{key: 'value',text: '文本内容',type: 'text',init: '',	target: '',		bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:options.YON},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
	 
	 
//	{key: 'size',text: '大小样式',type: 'select',init: 'large',target: '',	extend: false,tip: '',list: compBase.sizes},
    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 
    {key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
	{key: 'length',text: '限定字数',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
    {key: 'tip',text: '提示信息',type: 'text',init: '请输入',	target: '',	bursh: true,bind: true,tip: '',list: null},
//	{key: 'minRows',text: '最小行数',type: 'number',init: '5',	target: '',	extend: false,tip: '',list: null},
//	{key: 'maxRows',text: '最大行数',type: 'number',init: '5',	target: '',	extend: false,tip: '',list: null},

//	 {key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    compBase.status, 
  {key: 'title',text: '样式定制'	},
  {key:'bgColor',text:'背景颜色',type:'colorA',init:'#ffffff',target:'--el-fill-color-blank',bursh: true,bind: true,tip:'',list:null},	 
  {key:'bdColor',text:'边框颜色',type:'colorA',init:'#dcdfe6',target:'--el-border-color',bursh: true,bind: true,tip:'',list:null},	 
  {key:'fontColor',text:'文本颜色',type:'colorA',init:'#606266',target:'--el-text-color-regular',bursh: true,bind: true,tip:'',list:null},	 
  
{key:'borderRadius',text:'圆角大小',type:'text',init:'4px',target:'--el-border-radius-base',bursh: true,bind: true,tip:'',list:null},	 
 
   // compBase.statusTs
]
	

let area = {
	data: {
		 type: 'area',isInput:'S',marginL:'2px',marginR:'0px' /**/
	},
	delete: 'fontName,fontBold,fontItalic,fontHeight',
	hide: 'width,height',
	base: base.concat(font).concat(compBase.base),
	special: extend.tip,
	action:  options.getEvents(null,'$event','$event_mousemove,$event_mouseover,$event_scroll').concat(options.getEvents(null,'$hook'))
}

export default area
