import pie from './chartpie.js'
import clone from '../../../../../../../../plugins/deepClone.js'
import options from '../../../../../public/options.js'
let pieS =clone.deepClone(pie)
pieS.data.type='chart_pieS'
pieS.data.polarRadiusI='20%'
pieS.data.seriesPieRoseType='radius'
pieS.data.color=options.chartColor.bar5
pieS.hide=pieS.hide.replace(',seriesPieRadiusI','')

export default pieS