<template>
	<div v-if="root" class="datatable-type notextoverflow">
		<i :class="'iconfont icon-'+(dbIcon[dbType])"></i>
		{{dbType}} : {{dbName}}
	</div>
	<template v-for="table in tables">
		<div class="datatable-item linear">
			<div class="datatable-folder">
				<i @click="table.show=!table.show"
					:class="'datatable-body-arrow fas fa-caret-'+(table.show?'down':'right')"></i>
			</div>
			<div class="datatable-name">
				<div @click="setCurrentElement(table.el,'base')"
					:class="element==table.el?'datatable-text notextoverflow datatable-body-sel':'datatable-text notextoverflow'">
					<i class="fas fa-table"></i>
					{{table.tableText || '未命名数据表'}}
				</div>
				<div class="datatable-button">
					<i @click="setFieldShow(table,false)" class="far fa-folder" title="全部折叠"></i>
					<i @click="setFieldShow(table,true)" class="far fa-folder-open" title="全部展开"></i>
				</div>
			</div>


		</div>
		<div v-show="table.show" class="datatable-body">
			<div class="datatable-body-row">
				<template v-if="table.autoCreate">
					<div class="datatable-label">别名：</div>
					<input type="text" @change="updateData(table,'tableText')" v-model="table.tableText"
						class="input-text" />
				</template>
				<template v-else>
					<span style="color: #ff5500;">
						<i class="fad fa-info-circle"
							style="font-size:18px;margin-left: 5px;margin-right: 5px;"></i>未开启自动建表
					</span>
				</template>
			</div>
			<div class="datatable-body-row">
				<div class="datatable-label">表名：</div>
				<input type="text" @change="updateData(table,'tableName')" v-model="table.tableName"
					class="input-text" />
			</div>

			<template v-for="field in table.fields">
				<div class="datatable-body-title">
					<i @click="field.show=!field.show"
						:class="'datatable-body-arrow fas fa-caret-'+(field.show?'down':'right')"></i>
					<span @click="setCurrentElement(field.el,'special')"
						:class="element==field.el?'datatable-body-sel notextoverflow':' notextoverflow'">{{ field.fieldText || '未命名字段*'}}</span>

				</div>
				<div v-show="field.show " style="width: 100%;">
					<div class="datatable-body-row">
						<div class="datatable-label">别名：</div>
						<input type="text" @change="updateData(field,'fieldText')" v-model="field.fieldText"
							class="input-text" />
					</div>
					<div class="datatable-body-row">
						<div class="datatable-label">字段名：</div>
						<input type="text" @change="updateData(field,'fieldName')" v-model="field.fieldName"
							class="input-text" />
					</div>
					<div class="datatable-body-row ">
						<div class="datatable-label selectrow">类型：</div>
						<div class="datatable-info selectrow">
							<el-select @change="updateData(field,'fieldType')" v-model="field.fieldType" size="small"
								placeholder="请选择">
								<el-option-group v-for="group in fieldOptions" :key="group.label" :label="group.label">
									<el-option v-for="item in group.options" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-option-group>

							</el-select>

						</div>
					</div>
					<div class="datatable-body-row">
						<div class="datatable-label">长度：</div>
						<input type="number" @change="updateData(field,'fieldLen')" v-model="field.fieldLen"
							class="input-text" />
					</div>
					<div v-if="fieldMap[field.fieldType] && fieldMap[field.fieldType].unionName=='num'"
						class="datatable-body-row">
						<div class="datatable-label">小数位：</div>
						<input type="number" @change="updateData(field,'fieldTail')" v-model="field.fieldTail"
							class="input-text" />
					</div>
					<div v-show="false" class="datatable-body-row">
						<div class="datatable-label">默认值：</div>
						<input type="text" @change="updateData(field,'dataDefault')" v-model="field.dataDefault"
							class="input-text" />

					</div>
					<div class="datatable-body-row">
						<div class="datatable-label">允许空：</div>
						<div class="datatable-info">

							<el-switch @change="updateData(field,'allowNull')" v-model="field.allowNull" inline-prompt
								width="50px" active-color="#13ce66" inactive-color="#C0C4CC" active-text="是"
								inactive-text="否" active-value="Y" inactive-value="N" size="small" />
						</div>
					</div>
					<div class="datatable-body-row">
						<div class="datatable-label">唯一值：</div>
						<div class="datatable-info">
							<el-switch @change="updateData(field,'fieldUnique')" v-model="field.fieldUnique"
								width="50px" inline-prompt active-color="#13ce66" inactive-color="#C0C4CC"
								active-text="是" inactive-text="否" active-value="Y" inactive-value="N" size="small" />
						</div>
					</div>
				</div>

			</template>
			<tablefield v-if="table.tables && table.tables.length>0" :tables="table.tables" :element="element"
				:root="false" />
		</div>
	</template>
</template>

<script>
	import dbConfig from '../../../plugins/logicDB.js'
	export default {
		name: 'tablefield',
		props: {
			dbType: {
				type: String
			},
			dbName: {
				type: String
			},
			/* fieldTypes: {
				type: Array
			}, */
			fieldOptions: {
				type: Array
			},
			fieldMap: {
				type: Object
			},
			tables: {
				type: Array
			},
			element: {
				type: Object
			},
			root: {
				type: Boolean
			}
		},
		emits: ['select', 'change'],
		data() {
			return {
				//fieldOptions: dbConfig.fieldOptions,
				//fieldTypeOptions: [],
				//fieldMap: {},
				dbIcon: {
					MySql: 'mysql',
					PostgreSql: 'postgresql',
					SqlServer: 'microsoft',
					Oracle: 'Oracle'
				}

			}
		},

		methods: {
			updateData(obj, key) {
				if ('fieldType,fieldLen'.indexOf(key) > -1) { //设置默认长度
					let typeDef = this.fieldMap[obj.fieldType]
					if (typeDef.dataLenth > 0) {
						obj.fieldLen = typeDef.dataLenth
					}
				}
				this.$emit('change', obj.el, key, obj[key])
			},
			setFieldShow(table, isOpen) {
				for (let item of table.fields) {
					item.show = isOpen
				}
				for (let item of table.tables) {
					item.show = isOpen
				}
			},
			setCurrentElement(obj, tag) {
				this.$emit('select', obj, tag)
			},
			/* 	dataInit() {
					let options = []
					let fieldMap = {}
					for (let typeGroup of this.fieldTypes) {
						let group = {
							label: typeGroup.groupText,
							options: []
						}
						for (let item of typeGroup.items) { 
							let field = {
								label: item.typeText,
								value: item.typeName
							}
							group.options.push(field)
							fieldMap[item.typeName] = field
						}
						options.push(group)
					}

					this.fieldTypeOptions = options
					this.fieldMap = fieldMap
				} */

		},
		watch: {
			element(nv, ov) { //关闭其它表，打开当前组件所在的表
				let formEl = null
				if (nv.type == 'form') {
					formEl = nv
				} else {
					let parent = nv.parent
					while (parent) {
						if (parent.type == 'form') {
							formEl = parent
							break
						}
						parent = parent.parent
					}
				}
				if (formEl == null) {
					return
				}
				for (let table of this.tables) {
					if (table.el === formEl) {
						table.show = true
						//打开当前字段
						if ('fieldName' in nv) { //如果是表单数据元素
							for (let field of table.fields) {
								field.show = field.el === nv
							}
						}
					} else {
						table.show = false
					}


				}
			}
		},
		computed: {

		},
		created() {
			//	this.dataInit()
			//	console.log(this.fieldMap)

		}
	}
</script>

<style scoped>
	.datatable-type {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 15px);
		margin-left: 2px;
		padding: 5px;
		font-size: 14px;
		font-weight: bold;
		color: #409eff;
		border: solid 1px #d3d3d3;
		background-color: #f9f9f9;

	}

	.datatable-item {
		display: flex;
		width: calc(100% - 10px);
		padding: 5px;


	}

	.datatable-folder {
		width: 20px;
		text-align: center;
	}

	.datatable-name {
		display: flex;
		justify-content: space-between;
		width: calc(100% - 20px);
	}

	.datatable-text {
		cursor: pointer;
		width: calc(100% - 45px);
	}

	.datatable-text:hover {
		color: #409EFF;
	}

	.datatable-button {
		text-align: right;
		width: 45px;
	}

	.datatable-button i {
		margin-left: 3px;
		cursor: pointer;
		color: #303133;
	}

	.datatable-button i:hover {
		color: #409eff;
	}

	.datatable-body {
		padding-left: 0px;
		width: calc(100% - 0px);
	}

	.datatable-body-row {
		display: flex;
		align-items: center;
		padding: 1px 1px 1px 1px;
		margin-top: 2px;
		font-size: 13px;

	}

	.datatable-body-title {
		display: flex;
		align-items: center;
		padding: 1px 1px 1px 15px;
		border: solid 1px #d3d3d3;
		background-color: #f9f9f9;
		margin-top: 2px;

	}

	.datatable-body-title span {
		cursor: pointer;
	}

	.datatable-body-title span:hover {
		color: #409EFF;
	}

	.datatable-body-arrow {
		font-size: 18px;
		margin-right: 5px;
		cursor: pointer;
	}

	.datatable-body-sel {
		color: #409EFF;
	}

	.datatable-label {
		width: 60px;
		text-align: right;
		background-color: #f9f9f9;
		border: solid 1px #d3d3d3;
		margin-right: 2px;
		padding-top: 2px;
		padding-bottom: 2px;
		height: 20px;
		line-height: 20px;
	}

	.datatable-info {
		width: calc(100% - 72px);
		border: solid 1px #d3d3d3;
		padding-top: 2px;
		padding-left: 5px;
		padding-bottom: 2px;
		height: 20px;
		line-height: 20px;
	}

	.input-text {
		font-size: 14px;
		height: 22px;
		width: calc(100% - 72px);
		border: solid 1px #d3d3d3;
		outline: none;
	}

	.input-text:focus {
		border: 1px solid #0086B3;
		/* background-color: #FFFFD7; */
	}

	.selectrow {
		height: 30px;
		line-height: 30px;
	}

	.switchrow {
		height: 20px;
		line-height: 20px;
	}
</style>
