import compBase from '../../public/comp.js'
import options from '../../public/options.js'



let base=[
{key:'title',text:'流程模块'},

{key: 'tabType',text: 'Tab类型',type: 'select',init: '',	target: '',	bursh: true,bind: true,tip:'',list: options.toOptions('默认=,卡片=card,带边框卡片=border-card') },

{key:'add',text:'显示创建',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'todo',text:'显示待办',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'apply',text:'显示我发起',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'exist',text:'显示我参与',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'doing',text:'显示进行中',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'finish',text:'显示已完成',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'cancel',text:'显示已取消',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'title',text:'自定义模块名称'},
{key:'addLabel',text:'创建',type:'text',init:'创建',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'todoLabel',text:'待办',type:'text',init:'待办',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'applyLabel',text:'我发起',type:'text',init:'我发起',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'existLabel',text:'我参与',type:'text',init:'我参与',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'doingLabel',text:'进行中',type:'text',init:'进行中',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'finishLabel',text:'已完成',type:'text',init:'已完成',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'cancelLabel',text:'已取消',type:'text',init:'已取消',target:'',bursh:false,bind:false,tip:'',list: null},


{key:'flowId',text:'流程类型Id',type:'text',init:0,target:'',bursh:false,bind:false,tip:'',list: null},
{key:'flowName',text:'流程名称',type:'text',init:'',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'flowPath',text:'绑定流程',type:'flowselect',init:'未设置',target:'',bursh:false,bind:false,tip:'',list: null},


{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},
{key:'minH',text:'最小高度',type:'text',init:'200px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

/*{key:'objClass',text:'对象类型',type:'text',init:'line',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'fromId',text:'起点ID',type:'number',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'toId',text:'终点ID',type:'number',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'points',text:'连接点集合',type:'json',init:[],target:'',bursh:true,bind:true,tip:'',list: null},
{key:'path',text:'线条路径',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'selected',text:'已选中状态',type:'text',init:false,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'lineColor',text:'线条颜色',type:'color',init:null,target:'',bursh:true,bind:true,tip:'',list: null},

{key:'labelShow',text:'显示标签',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelText',text:'标签文本',type:'text',init:'条件',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelColor',text:'标签颜色',type:'color',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'label',text:'标签对象',type:'json',init:{positionX:0,positionY:0,style:{}},target:'',bursh:true,bind:true,tip:'',list: null},
{key:'condition',text:'路径条件',type:'text',init:'',target:'',bursh:true,bind:true,tip:'Javascript语法，逻辑表达式',list: null}, 	
{key:'positionX',text:'节点X位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'positionY',text:'节点Y位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
 */

]
let events=[
/* 	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]

 
let flowmodule= {
	data:{
		type:'flowmodule', 
	},
	delete:'',
	hide:'flowId,flowName,width,height',
	base:base,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default flowmodule