<template>
	<pageview v-if="pageModel && pageModel.id>0" :project="projectModel" :page-model="pageModel" />
	<runing v-else :label-size="30" :icon-size="60" :message="msg" :error="error"></runing>
</template>

<script>
	import config from '../../components/form/config/config.js'
	import PageView from '../../components/form/PageView.vue'
	export default {
		data() {
			return {
				msg: '系统加载中...',
				error: null,
				pageModel: {
					id: 0
				},
				projectModel: {}

			}
		},

		methods: {
			loadApplication() {
				let formCode = this.$route.params.formCode
				//console.log(this.$route.params.formCode)
				if (!formCode) {
					this.$router.push({ //暂不启用此功能
						name: 'Login'
					})
					return
				}

				//检测是否有appId参数，如有直接获取实例，否则提取当前网址获取实例,如果没有获取到返回桌面
				//let appKey = this.getRequestParam('appKey') || ''
				/* 	let appKey = this.$logic.http.getRequestParam('appKey') || ''
					//提取出网址，域名+[端口号]
					let url = window.location.href
					let x = url.indexOf('?')
					if (x > 0) {
						url = url.substring(0, x)
					}
					x = url.lastIndexOf('/')
					if (x == url.length - 1) {
						url = url.substring(0, x)
					}
					
					let appId = this.$route.params.appId
					let version = this.$route.params.formVersion
					if (version == null) {
						version = 0
					} */
				/* this.$logic.http.get(true, '/api/dev/loadappinfo/', {
					appKey: appKey,
					appUrl: url,
					appId: appId,
					formId: 0,
					formVersion: version
				}, */

				this.$logic.http.get(true, '/api/dev/loadrunapp/', {
						formCode: formCode
					},
					res => {
						this.projectModel = res.data.data
						document.title = this.projectModel.fullName
						//console.log(this.projectModel )
						//构建登录信息 
						let level = 10000
						let user = this.$logic.getUser()
						if (this.projectModel.needLogin === 0) {
							/* if (!user || !('id' in user)) {
								this.$logic.setTemplateUser(this.projectModel.unitGroup, this.projectModel.unitId)
								//this.$logic.tip.success('已登录')
							} */
							this.$logic.setTemplateUser(this.projectModel.unitGroup, this.projectModel.unitId)

						} else {
							if (!user || !user.id) {
								this.$logic.tip.error('请先登录系统')
								this.$router.push('/login?to=/run/' + formCode)
								return
							} else { //验证用户单位有效性 
								let unitItem = user.unitItem
								if (unitItem && unitItem.unitSerial && unitItem.unitSerial.length > 66) {
									let serial = unitItem.unitSerial
									//截止日期带时间，注册信息不带时间，需去时间尾巴
									let expiration = unitItem.expiration.substring(0, unitItem.expiration.indexOf(' '))
									let baseInfo = unitItem.sysMode + unitItem.unitLevel + unitItem.unitCode + unitItem
										.unitName + unitItem.unitShort +
										unitItem.addModule + unitItem.userSize + expiration +
										unitItem.sysTitle + unitItem.sysShort + unitItem.sysLogo + unitItem.support;

									let versin = serial.substring(0, 2)
									let checkCode1 = serial.substring(2, 34)
									let checkCode2 = this.$logic.encoder.md5(baseInfo)
									if (checkCode1 == checkCode2) {
										level = unitItem.unitLevel
									} else {
										level = 10
									}

								} else {
									level = 10
								}

							}

						}
						this.pageModel = this.projectModel.formBoot
						let pageObj = this.$logic.http.parsePage(config, this.projectModel.formBoot, true, this
							.projectModel)
						let exInfo = ''
						if (level < 10000) {
							level = '' + level
							let levelMap = this.$logic.regInfo
							if (level in levelMap) {
								exInfo = '' + levelMap[level] + '-'
							} else {
								exInfo = '未注册-'
							}
						}

						document.title = exInfo + this.pageModel.formName
					}, err => {
						//console.log(err)
						this.error = err.info
						this.$logic.tip.error(this.error)
						/* if (err.code > 1) {
						
						} else {
							
						} */

						//this.$router.push({	name: 'DeskTop'		})
					})


			},
			/*getRequestParam(param) {

				 	let url = decodeURI(window.location.search)
					let params = {}
					if (url.indexOf('?') > -1) {
						url = url.substr(1)
						let ps = url.split('&')
						for (let i = 0; i < ps.length; i++) {
							let part = ps[i].split('=')
							params[part[0]] = part[1]
						}
					}
					return params[param] 
			}
*/

		},
		components: {
			pageview: PageView
		},
		created() {
			this.loadApplication()


		}
	}
</script>

<style scoped>

</style>