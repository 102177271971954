import compBase from '../../public/comp.js'
import options from '../../public/options.js'



let base=[
{key:'title',text:'项目管理'},

{key:'projectName',text:'项目名称',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},

//{key:'datas',text:'数据集合',type:'text',init:null,target:'',bursh:false,bind:false,tip:'',list:null},	
{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},

{key: 'items',text: '分栏',type: 'json',init:null,	target: '',	bursh: true,bind: true,tip: '',list: null}
	

]
let events=[
	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},

]






/* let base=[
	{key:'title',text:'条形码'},
 

].concat(compBase.base)
 */
let pm= {
	data:{
		type:'pm',
	},
	delete:'',
	hide:'items',
	base:base,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default pm