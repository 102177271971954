<template>
	<div class="qrcontent" :style="style">
		<div class="qrcode" ref="qrcode"></div>
		<div class="logocontent" :style="logoContent" v-show="model.logoV=='Y'">
			<div class="imgcontent" :style="logoStyle">
				<img v-show="src !=null" class="logo" :src="src" border="0px" />
			</div>

		</div>
	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	import QRCode from 'qrcodejs2-fix'//'qrcodejs2'
	export default {
		mixins: [formBase],
		data() {
			return {

			}
		},
		methods: {
			getImage() {
				let qrcodeEle = this.$refs.qrcode //document.getElementById("qrcode")
				let cvs = qrcodeEle.querySelector('canvas')
				let qrcodeimg = cvs.toDataURL('image/png')
				return qrcodeimg
			},

			qrcode() {
				setTimeout(() => { //延时处理，以防表单元素还未生成
					let model = this.model
					let obj = this.$refs.qrcode
					if (!obj) {
						return
					}
					obj.innerHTML = ''
					let qrcode = new QRCode(this.$refs.qrcode, {
						width: model.size,
						height: model.size,
						text: model.value, // 二维码的内容
						colorDark: model.colorF, // 二维码的颜色
						colorLight: model.colorB,
						correctLevel: QRCode.CorrectLevel[model
							.correctLevel] //QRCode.CorrectLevel.H//二维码 容错等级 L M Q H
					})

				}, 100)
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return model.value
				} else {
					model.value = value
				}
			}
		},
		computed: {
			style() {
				let model = this.model
				let bs = this.baseStyle
				let css = {
					width: model.size + 'px',
					height: model.size + 'px',
					border: model.borderT + ' ' + model.borderS + 'px ' + model.borderB
				}
				return {
					...css,
					...bs
				}
			},
			logoContent() {
				let model = this.model
				let css = {
					width: model.size + 'px',
					height: model.size + 'px',
				}
				return css
			},
			logoStyle() {
				let model = this.model
				let size = this.imgSize
				let css = {
					width: size + 'px',
					height: size + 'px',
					borderRadius: model.logoR,
					border: 'solid ' + model.logoL + 'px ' + model.logoC
				}
				return css
			},
			src() {
				let model = this.model
				if (model.logoF && model.logoF.length > 0) {
					let url = this.$logic.http.baseUrl + model
						.logoF //this.isedit ? this.$logic.http.baseUrl + model.logoF : model.logoF
					return url
				} else {
					return null
				}
			},
			imgSize() { //此计算属性替代深度监听，响应属性变化重画图形 
				let model = this.model

				/* 	for (let key in model) { 
						if(key.indexOf('$')==0){
							continue
						}
						console.log('let '+key+'=model.'+key)
					} */
				let value = model.value
				let size = model.size
				let correctLevel = model.correctLevel
				let colorF = model.colorF
				let colorB = model.colorB
				let borderB = model.borderB
				let borderS = model.borderS
				let borderT = model.borderT
				let logoV = model.logoV
				let logoF = model.logoF
				let logoS = model.logoS
				let logoR = model.logoR
				let logoC = model.logoC
				let logoL = model.logoL
				let marginT = model.marginT
				let marginB = model.marginB
				let marginL = model.marginL
				let marginR = model.marginR
				let icon = model.icon

				let imgSize = model.logoS
				if (logoV === 'Y') {
					let QrSize = model.size || 10
					let sizeMap = {
						L: 5,
						M: 10,
						Q: 20,
						H: 25
					} //比允许值略小，确保案例
					let p = imgSize * imgSize * 100 / (QrSize * QrSize)
					let per = sizeMap[correctLevel] || 5
					if (p > per) {
						imgSize = parseInt(Math.sqrt(QrSize * QrSize * per / 100))
						setTimeout(() => { //新线程里弹出，否则会报错
							this.$logic.tip.error('图片尺寸占比已超过二维码允许的容错比例，请设置在' + imgSize + '以内')
						}, 50)
					}
				}
				this.qrcode()
				return imgSize
			}
		},
		/* 		watch: { // 深度监听严重影响性能，采用计算属性规避此问题
					model: {
						deep: true,
						handler: function(newV, oldV) {
							this.qrcode()
		 
						}
					}
				},
		 */
		mounted() {
			//	this.qrcode()


		},

		created() {
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
			}

		}
	}
</script>

<style scoped>
	.qrcontent {
		position: relative;
	}

	.qrcode {
		width: 100%;
		height: 100%;
	}

	.logocontent {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0px;
		top: 0px;
		z-index: 100;
	}

	.imgcontent {
		background-color: #FFFFFF;
		overflow: hidden;
	}

	.logo {
		width: 100%;
		height: 100%;
		border: 0px;
	}
</style>