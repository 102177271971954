import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
	//['文本','图标', '样式','状态' ], 
	{key:'create',text:'创建', icon:'far fa-plus',type:'success',enabled:'Y',loading:'N'},
	{key:'edit',text:'编辑', icon: 'far fa-edit',type: 'primary',enabled:'Y',loading:'N' },
	{key:'delete',text:'删除', icon: 'far fa-delete',type: 'danger' ,enabled:'Y',loading:'N'},
	{key:'info',text:'详情', icon: 'far fa-file-search',type: 'default',enabled:'Y',loading:'N' },
	{key:'upload',text:'上传', icon: 'far fa-upload', type:'info',enabled:'Y',loading:'N'  },
	//{key:'download',text:'下载', icon: 'Download',type: 'text', enabled:'Y',loading:'N' },
])
let base = [
	{key: 'title',text: '按钮样式'	},
	compBase.mode,
	{key: 'value',text: '文本',type: 'text',init: '按钮文本',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'ico',text: '图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
    compBase.color,
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
	{key: 'link',text: '链接样式',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'plain',text: '朴素样式',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'round',text: '是否圆角',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'circle',text: '是否圆形',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
	{key: 'loading',text: '加载中状态',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
    compBase.enabled,
	{key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
/*     {key: 'title',text: '样式定制'	},
	{key:'btWidth',text:'按钮宽度',type:'text',init:'',target:'--custom-comp-width',bursh: true,bind: true,tip:'',list:null},	 
    {key:'btHeight',text:'按钮高度',type:'text',init:'',target:'--custom-comp-height',bursh: true,bind: true,tip:'',list:null},	 

暂不启用
 
  {key:'bgColor',text:'背景颜色',type:'colorA',init:'',target:'--el-button-bg-color',bursh: true,bind: true,tip:'',list:null},	 
  {key:'textColor',text:'文本颜色',type:'color',init:'',target:'--el-button-text-color',bursh: true,bind: true,tip:'',list:null},	 
  {key:'bdColor',text:'边框颜色',type:'colorA',init:'',target:'--el-button-border-color',bursh: true,bind: true,tip:'',list:null},	 
  {key:'hoverColor',text:'悬停背景色',type:'colorA',init:'',target:'--el-button-hover-bg-color',bursh: true,bind: true,tip:'',list:null},	 

*/


] 
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'key',label:'按钮标识',type:'text',default:'',list:[],field:'',width:'16%',unique:true},
	{key:'text',label:'按钮文本',type:'text',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'icon',label:'按钮图标',type:'icon',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'type',label:'按钮样式',type:'select',default:'',list:compBase.colorTypes,field:'',width:'16%',unique:false},
	{key:'enabled',label:'可用状态',type:'switch',default:'Y',list:[],field:'',width:'16%',unique:false},
	{key:'loading',label:'加载状态',type:'switch',default:'N',list:[],field:'',width:'16%',unique:false},
]
 
let botton = {
	data: {
		type: 'button',
	},
	delete: '',
	hide: 'dataOption',
	base: base.concat(compBase.base).concat([
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		
		{key: 'listData',text: '按钮组数据',type: 'jsonArray',init: datas,	target: '',bursh: true,bind: true,tip: '',attribute:'dataOption'},// ,treeMap:{id:'key',label:'text',icon:'icon'},dataMap: {key:'唯一标识',text:'按钮文本',icon:'图标',type:'外观样式',enabeld:'可用状态',loading:'加载状态'}
		
	]),
	special:extend.badge.concat(extend.menu).concat(extend.tip).concat( [
		
	]),
	action: options.getCommonEvents().concat([
		options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat([compBase.menuClick]).concat(extend.link).concat(extend.dialog).concat(extend.flow).concat(extend.qywx).concat(options.getEvents(null,'$hook')),
	
	
}

export default botton
