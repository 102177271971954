<template>
	<div style="display: flex;align-items: center;">
		<i v-if="eyeDropper " class="fad fa-eye-dropper dropper-icon" @click="getScreenColor" title="选择屏幕颜色,按Esc键可取消选择"></i>

		<el-color-picker v-model="color" @change="colorChange" :predefine="predefineColors" :size="size"
			:show-alpha="alpha" />
	</div>

</template>

<script>
	export default {
		props: {
			modelValue: {
				type: String
			},
			alpha: { //是否RGB方式
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'large'
			}
		},
		data() {
			return {
				color: null,
				predefineColors: [
					'#409EFF', '#67C23A', '#E6A23C', '#F56C6C', '#FFD700', '#FAFAD2', '#ff4500', '#ff8c00', '#90ee90',
					'#00ced1', '#1e90ff', '#c71585',
					'#303133', '#606266', '#909399', '#C0C4CC', '#DCDFE6', '#E4E7ED', '#EBEEF5', '#F2F6FC',
					'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)',
					'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)', '#c7158577',
				],
			}
		},

		methods: {
			colorChange(c) {
				this.$emit('update:modelValue', c)
			},
			async getScreenColor() { //
				let color = await this.$logic.color.getScreenColor(this.alpha)
				if (color != null) {
					this.$emit('update:modelValue', color)
				}

			}
		},
		computed: {
			eyeDropper() {
				/* let eyeDropper = null
				if (!('EyeDropper' in window)) {
					return null
				}
				eyeDropper = new EyeDropper()
				return eyeDropper */
				return ('EyeDropper' in window)
			}

		},
		watch: {
			modelValue(newValue, oldValue) {
				this.color = newValue
			}
		},
		created() {
			this.color = this.modelValue

		}
	}
</script>

<style scoped>
	.dropper-icon {
		font-size: 16px;
		margin: 0px 5px;
		cursor: pointer;
	}

	.dropper-icon:hover {
		color: #409EFF;
	}
</style>