<template>
	<gantt v-if="model.type=='gantt'" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />
	<project v-else-if="model.type=='pm'" :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />

</template>

<script>
	import Gantt from './Gantt.vue'
	import Project from './Project.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				superComp: true
			}
		},

		methods: {

		},
		components: {
			gantt: Gantt,
			project: Project,

		},
		created() {


		}
	}
</script>

<style scoped>

</style>