import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import button from './button.js'
import deepClone from '../../../../../plugins/deepClone.js'
let buttonBase=deepClone.deepClone(button.base)
let special=deepClone.deepClone(button.special)
for(let i=buttonBase.length-1;i>-1;i--){
	let item=buttonBase[i]
	if( ',mode,dataOption,listData'.indexOf(item.key)>-1){
		buttonBase.splice(i,1)
	}
 
}

let base=[
	{key: 'title',text: 'Excel导入'	},
//	{key: 'allowExcel',text: '启用导入',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip:'',list: null },
	{key: 'codeType',text: '编码格式',type: 'select',init: 'UTF-8',	target: '',	bursh: true,bind: true,tip:'文件编码格式',list: options.toOptions('UTF-8=UTF-8,UTF-16=UTF-16,GBK/GB2312=GBK,ANSI=ANSI') },
	{key: 'autoJson',text: '解析为JSON',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip:'读取文件内容后自动解析为JSON数据，数据加载后事件中获取的参数为解析后的数据',list: null },
//	{key: 'importSet',text: '导入设置',type: 'importset',init: {sourceId:0,sheets:[],recall:'N'},	target: '',	bursh: true,bind: true,tip: '自动加载当前用户姓名，一般不需要设置默认值'},
	
	
].concat(buttonBase)

let fileImport = {
	data: {
		type: 'fileimport',ico:'far fa-file-upload',value:'文件导入'
	},
	delete: '',
	hide: '',
	base: base.concat(compBase.base),
	special: special,//{key: 'jsonData',text: 'JSON数据',type: 'json',init: datas,	target: '',	extend: false,tip: '',list: null},
	action: [
		{key:'title',text:'组件业务事件'},
		{key: '$comp_uploadBefore',text: '导入上传前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '返回false将阻止上传，如果需要向服务器做数据交互处理务必使用同步方法',list: null	},
		
		{key: '$comp_loadAfter',text: '数据加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '适用于上传文本文件，在上传成功后返回整个文件的内容',list: null	},
	//	{key: '$comp_importAfter',text: '导入完成后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '如果导入设置中不启用回调模式，将自动进行数据库写入操作，数据库执行成功后触发此事件',list: null	},
		
	].concat(options.getEvents(null,'$hook'))
}

export default fileImport
