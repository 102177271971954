import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import layout from '../../public/layout.js'

let base = [
	{key: 'title',text: '验证码'	},	
	{key: 'value',text: '验证码内容',type: 'text',init: '',	target: 'height',bursh: true,bind: true,tip: '只读属性：value，可程序调用$draw([newCode])方法重新生成',list: null},
	
	{key: 'charLength',text: '字符长度',type: 'number',init: '4',	target: '',bursh: true,bind: true,tip: '',list: null},
	{key: 'fontSize',text: '字体大小',type: 'number',init: '23',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'caseMix',text: '大小写混合',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},

//	{key: 'width',text: '元素宽度',type: 'text',init: '100px',	target: 'width',bursh: true,bind: true,tip: '',list: null},
//	{key: 'height',text: '元素高度',type: 'text',init: '28px',	target: 'height',bursh: true,bind: true,tip: '',list: null},

]
let codeCfgs=options.getConfigs(layout,"width,height,borderS,borderL,borderT,borderR,borderB,borderColor,marginL,marginT,marginR,marginB")

let events=[	
	{key: '$comp_change',text: '验证码变化',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '验证码发生变化后触发',list: null	},
] 

let imgcode = {
	data: {
		type: 'imgcode',isInput:'S',width:'100px',height:'28px',marginT:'0px',marginB:'0px',marginL:'5px',
		marginR:'0px',borderL:'1px',borderT:'1px',borderR:'1px',borderB:'1px'
	},
	delete: '',
	hide: 'alignH,alignV,overH,overV',
	base: base.concat(codeCfgs),
	special: [],
	action: events.concat(options.getEvents(null,'$hook'))
}

export default imgcode
