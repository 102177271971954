<template>
	<div ref="datavBox" class="datav_container" @click="mouseClick"  :style="layoutstyle">
		<borderbox1 v-if="model.type==='dv_box1'" :panel-width="boxWidth" :panel-height="boxHeight"
			:color1="model.color1" :color2="model.color2" />

		<borderbox7 v-else-if="model.type==='dv_box7'" :panel-width="boxWidth" :panel-height="boxHeight"
			:color1="model.color1" :color2="model.color2" />
		<borderbox11 v-else-if="model.type==='dv_box11'" :panel-width="boxWidth" :panel-height="boxHeight"
			:color1="model.color1" :color2="model.color2" />
		<borderbox12 v-else-if="model.type==='dv_box12'" :panel-width="boxWidth" :panel-height="boxHeight"
			:color1="model.color1" :color2="model.color2" />
		<borderbox13 v-else-if="model.type==='dv_box13'" :panel-width="boxWidth" :panel-height="boxHeight"
			:color1="model.color1" :color2="model.color2" />
		<!-- 	
		<borderbox2 v-if="start" class="container">双边相框</borderbox2>
		
		<borderbox8 v-if="start" class="container">流光转动</borderbox8>
		<borderbox9 v-if="start" class="container">离散四边</borderbox9>
		<borderbox10 v-if="start" class="container">光影四角</borderbox10>
		<borderbox11 v-if="start" class="container">主题明确</borderbox11>
		<borderbox12 v-if="start" class="container">圆润四角</borderbox12>
		 	<borderbox13 v-if="start" ref="box" class="container">凹凸有致</borderbox13>
		 -->
		<!-- <div class="svg-container">

		</div> -->
		<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
			:datas="bindData" />


	</div>

</template>

<script>
	import Box1 from './box/Box1.vue'
	import Box7 from './box/Box7.vue'
	import Box11 from './box/Box11.vue'
	import Box12 from './box/Box12.vue'
	import Box13 from './box/Box13.vue'
	import layoutBase from '../sub/layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: {},
				boxWidth: 100,
				boxHeight: 100
			}
		},

		methods: {
			setBoxSize() {
				let box = this.$refs.datavBox
				this.boxWidth = box.offsetWidth;
				this.boxHeight = box.offsetHeight;
			}
		},
		components: {
			borderbox1: Box1,
			borderbox7: Box7,
			borderbox11: Box11,
			borderbox12: Box12,
			borderbox13: Box13,
			/* 	borderbox2: BorderBox2,
				borderbox7: BorderBox7,
				borderbox8: BorderBox8,
				borderbox9: BorderBox9,
				borderbox10: BorderBox10,
			 */
		},
		computed: {

		},
		watch: {
			'$store.state.resizeTick'() { //APP.vue中设置window.onresize监听事件
				this.setBoxSize()
			},
		},
		mounted() {
			this.setBoxSize()
		},
		created() {



		}
	}
</script>

<style scoped>
	.datav_container {
		width: 100%;
		height: 100%;
		min-width: 50px;
		min-height: 50px;
		position: relative;
	}

	/* 
	.svg-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		pointer-events: none;
		z-index: 0;
	} */
</style>