import options from '../../../../../public/options.js'
import gridConfig from '../gridConfig.js'
let base = gridConfig.base
let special = [
	 {key:'title',text:'线条系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'line',path:'_series.type',bursh:true,bind:true,tip:'',list:null},


//	 {key:'seriesLineColorBy',text:'取色方式',type:'select',init:'series',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesLineStep',text:'阶梯线形式',type:'select',init:'none',path:'_series.step',bursh:true,bind:true,tip:'',list:options.toOptions('无阶梯=none,起点拐角=start,中间点拐角=middle,终点拐角=end')},
	{key:'seriesLineWidth',text:'线条粗细',type:'number',init:2,path:'_series.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
	{key:'seriesLineType',text:'线条类型',type:'select',init:'solid',path:'_series.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	{key:'seriesLineSmooth',text:'平滑度',type:'number',init:0.5,path:'_series.smooth',bursh:true,bind:true,tip:'0到1之前的小数',list:null},
	
	{key:'seriesLineAreaIsFill',text:'区域填充',type:'select',init:'none',path:'_series.areaStyle.isFill',bursh:true,bind:true,tip:'',list:options.toOptions('不填充=none,全部填充=all,首个填充=first,最后填充=last')},
//	{key:'seriesLineAreaOrigin',text:'填充方向',type:'select',init:'auto',path:'_series.areaStyle.origin',bursh:true,bind:true,tip:'象数值',list:options.toOptions('自动=auto,起始位置=start,结束位置=end')},
	 {key:'xAxisAxisBoundaryGap',text:'两侧留白',type:'switch',init:'Y',path:'xAxis.boundaryGap',bursh:true,bind:true,tip:'',list:options.YON},
	 
	 
	 {key:'seriesLineLabelShow',text:'显示标签',type:'switch',init:'N',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesLineLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesLineLabelRotate',text:'旋转角度',type:'number',init:0,path:'_series.label.rotate',bursh:true,bind:true,tip:'',list:null},
	 
/* 	 {key:'seriesLineWidth',text:'柱条宽度',type:'text',init:null,path:'_series.LineWidth',bursh:true,bind:true,tip:'可指定柱条宽度，直接输入数值为象素值，支持百分比如10%',list:null},
	 {key:'seriesLineborderRadius',text:'柱条圆角',type:'number',init:3,path:'_series.itemStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesLineborderWidth',text:'描边粗细',type:'number',init:1,path:'_series.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesLineborderColor',text:'描边颜色',type:'color',init:null,path:'_series.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},


	 {key:'seriesLineStack',text:'柱条堆叠',type:'select',init:'none',bursh:true,bind:true,tip:'',list:options.toOptions('不堆叠=none,全部堆叠=all,第一个之后=after,最后一个之前=befor')},

	 {key:'seriesRealtimeSort',text:'实时排序',type:'switch',init:'N',path:'_series.realtimeSort',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesShowBackground',text:'启用背景色',type:'switch',init:'N',path:'_series.showBackground',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBackgroundColor',text:'柱条背景色',type:'colorA',init:'rgba(180, 180, 180, 0.2)',path:'_series.backgroundStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderColor',text:'背景描边色',type:'colorA',init:null,path:'_series.backgroundStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderType',text:'描边线类型',type:'select',init:'solid',path:'_series.backgroundStyle.borderType',bursh:true,bind:true,tip:'',list:options.BorderType},
	 {key:'seriesBackgroundBorderWidth',text:'描边线粗细',type:'number',init:0,path:'_series.backgroundStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderRadius',text:'圆角大小',type:'number',init:0,path:'_series.backgroundStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
 */


].concat(gridConfig.special)
let action = gridConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_line',color:options.chartColor.bar3,seriesLineAreaIsFill:'first',
		chartClan: 'grid',xAxisAxisBoundaryGap:'N'
	},
	delete: '',
	hide: 'width,height,seriesType,dataOption',
	base:[].concat(base) ,
	special: special,
	action: gridConfig.action.timer.concat(gridConfig.action.comm)
}
export default cfg
