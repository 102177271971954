import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'


let rotateTypes=[{label:'无',value:''},{label:'平滑旋转',value:'fa-spin'},{label:'步进旋转',value:'fa-pulse'}]
let base = [
	{key: 'title',text: '图标'	},
	{key: 'value',text: '图标类型',type: 'icona',init: 'far fa-star',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'text',text: '后置文本',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'rotate',text: '旋转方式',type: 'select',init: '',	target: '',	bursh: true,bind: true,tip: '""/fa-spin/fa-pulse',list: rotateTypes},
	{key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	

]

let icon = {
	data: {
		type: 'icon'//,marginT:'0px',marginB:'0px'
	},
	delete: '',
	hide: 'mode',
	base: base.concat(font).concat(compBase.base),
	special: extend.badge.concat(extend.menu).concat(extend.tip),
	action:  options.getCommonEvents().concat([compBase.menuClick]).concat([
		//options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default icon
