import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'
let base=[
	{key:'title',text:'文档编辑器'}, 
	
	{key:'editorMode',text:'编辑器模式',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'编辑与内容呈现模式状态切换',list:options.YON},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	
	{key:'editAble',text:'可编辑状态',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'是否可编辑状态的切换',list:options.YON},
	 
	{key:'width',text:'编辑器宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list:null},	 
    {key:'height',text:'编辑器高度',type:'text',init:'300px',target:'height',bursh:true,bind:true,tip:'',list:null},
 {key:'value',text:'编辑内容',type:'area',init:null,target:'',bursh:true,bind:true,tip:'当前编辑器中的HTML内容',list:null},
  {key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
  {key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
  {key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
  {key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
  
]
let toolBar=[
		{key:'title',text:'工具栏按钮'}, 
		{key:'_undo',text:'撤销',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_redo',text:'重做',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_fontSize',text:'字体大小',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_fontFamily',text:'字体类型',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_fontColor',text:'字体颜色',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_fontBackgroundColor',text:'文字背景色',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_heading',text:'段落标题',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_link',text:'链接',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_insertImage',text:'图片',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_mediaEmbed',text:'媒体播放器',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_insertTable',text:'表格',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_specialCharacters',text:'特殊字符',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_codeBlock',text:'代码块',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_blockQuote',text:'引用块',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_bold',text:'加粗',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_italic',text:'斜体',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_underline',text:'下划线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_strikethrough',text:'删除线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_subscript',text:'下标',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_superscript',text:'上标',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_bulletedList',text:'无序列表',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_numberedList',text:'有序列表',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_outdent',text:'减少缩进',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		{key:'_indent',text:'增加缩进',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},

]

let heditor= {
	data:{
		type:'ckeditor',isInput:'S',
	},
	delete:'',
	hide:'',
	base: base.concat(font),
	special: [].concat(toolBar),
	action:[]
}

export default heditor