<template>
	
	<template v-if="model.showInput==='Y'">

		<el-input v-model="codeValue" @change="onChange" type="text"
			:placeholder="model.showDevice==='Y'?deviceTip:'请扫描'" :size="model.size"
			:prefix-icon="model.showInput == 'Y'?'Camera':null" :disabled="model.status=='disabled'" :readonly="false"
			:clearable="true" :style="compStyle">

			<template #append>
				<!-- 			<el-button @click="toScan" :disabled="!deviceStatus && false">
					<i :class="model.ico"></i>
					{{model.label}}
				</el-button>
				<div class="device-sel">
					<el-dropdown trigger="click" @command="deviceSelect">
						<span class="dropdown-link">
							<i class="fas fa-chevron-down"></i>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<template v-for="item in deviceList">
									<el-dropdown-item :command="item" :disabled="item === selectedDevice && false">
										<i v-if="item === selectedDevice " class="far fa-check"
											style="margin-right: 5px;"></i>
										<i v-else style="margin-right: 25px;"></i>
										{{item.label}}</el-dropdown-item>
								</template>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
 -->
				<el-button-group :size="model.size" :type="model.color" :plain="model.plain=='Y'"
					:round="model.round=='Y'" :link="model.link=='Y'">
					<el-button @click="toScan" :disabled="!deviceStatus" :loading="model.loading=='Y'">
						<i :class="model.ico"></i>
						{{model.label}}
					</el-button>
					<el-button :disabled="!deviceStatus" class="device-button">
						<el-dropdown trigger="click" @command="deviceSelect">
							<div class="menu-span"><i class="fas fa-chevron-down"></i></div>

							<template #dropdown>
								<el-dropdown-menu ref="deviceMenu">
									<template v-for="item in deviceList">
										<el-dropdown-item :command="item" :disabled="item === selectedDevice && false">
											<i v-if="item === selectedDevice " class="far fa-check"
												style="margin-right: 5px;"></i>
											<i v-else style="margin-right: 25px;"></i>
											{{item.label}}</el-dropdown-item>
									</template>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</el-button>


				</el-button-group>
			</template>

		</el-input>
	</template>
	<template v-else>
		<el-button-group :size="model.size" :type="model.color" :plain="model.plain=='Y'" :round="model.round=='Y'"
			:link="model.link=='Y'">
			<el-button @click="toScan" :disabled="!deviceStatus" :loading="model.loading=='Y'">
				<i :class="model.ico"></i>
				{{model.label}}
			</el-button>
			<el-button :disabled="!deviceStatus" class="device-button">
				<el-dropdown trigger="click" @command="deviceSelect">
					<div class="menu-span"><i class="fas fa-chevron-down"></i></div>

					<template #dropdown>
						<el-dropdown-menu ref="deviceMenu">
							<template v-for="item in deviceList">
								<el-dropdown-item :command="item" :disabled="item === selectedDevice && false">
									<i v-if="item === selectedDevice " class="far fa-check"
										style="margin-right: 5px;"></i>
									<i v-else style="margin-right: 25px;"></i>
									{{item.label}}</el-dropdown-item>
							</template>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</el-button>


		</el-button-group>
	</template>
	<div v-show="scanRun" class="scan-read" @click="toQrStop">
		<div class="scan-tool">
			<div>
				<i v-if="model.scanType==='qr'" class="far fa-qrcode"></i>
				<i v-else-if="model.scanType==='bar'" class="far fa-barcode-read"></i>

				<span v-if="model.scanLoop==='Y'" class="scan-mode">连续扫码</span>
				<span v-else class="scan-mode">单次扫码</span>
				<template v-if="model.scanType==='qr'">
					<span style="margin: 0px 10px;">灯光</span>
					<el-switch @click.stop="1+2" v-model="qrTorch" />
				</template>
			</div>
			<div>
				<span>点击退出扫描</span>
				<!-- <span>
					<i class="fas fa-times" @click="scanStop"></i>
					退出扫描
				</span> -->

			</div>

		</div>
		<div class="scan-video">
			<!-- <video v-if="model.scanType==='bar'" ref="video" :id="videoId" class="video-item"
				style="border: 1px solid gray"></video> -->

			<div v-if="model.scanType==='bar'" ref="video" :id="videoId" class="video-item"
				style="border: 1px solid gray"></div>

			<qrstream v-else-if="scanRun && model.scanType==='qr'" ref="qrstream" :camera="selectedDevice.deviceId"
				:torch="qrTorch" @decode="deQrCode" @init="initQr"></qrstream>
		</div>
	</div>

</template>

<script>
	//浏览器自带的功能以后研究 https://www.zhangxinxu.com/wordpress/2023/01/js-parse-barcode-qrcode-barcodedetector/
	//修改vue3-qr-reader.common.js,去掉所有console.warn
	import {
		QrStream,
		QrCapture,
		QrDropzone
	} from 'vue3-qr-reader'
	import Quagga from 'quagga'
	/* 	import {
			BrowserMultiFormatReader,
			MultiFormatReader,
			BarcodeFormat
		} from '@zxing/library' */

	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				codeReader: null,
				codeValue: '',
				deviceList: [],
				selectedDevice: null,
				videoId: null,
				deviceStatus: false,
				deviceInfo: null,
				deviceTip: '请扫码',
				lastValue: null, //防止同一码重复扫描
				scanRun: false,
				qrTorch: false, //是否打开灯光

				//barReader: null
			}
		},

		methods: {
			
			
			
			
			toQrStop() {
				/* let ins = this.$refs.qrstream
				for (let key in ins) {
					console.log(key, typeof(ins[key]), ins[key])
				} */

				/* 				let ins = this.$refs.qrstream.cameraInstance
								console.log('stop', ins)
								for (let key in ins) {
									console.log(key, typeof(ins[key]), ins[key])
								}
								ins.stop()
								this.$refs.qrstream.init()
								 */
				//this.$refs.qrstream.cameraInstance.startScanning()
				//this.$refs.qrstream.pauseFrame()
				//this.$refs.qrstream.stopScanning()
				if (this.model.scanType === 'bar') {
					Quagga.stop()
				}
				this.scanRun = false
			},
			deQrCode(res, format) {
				let model = this.model
				let info = model.scanType === 'qr' ? '' : ':' + res + '类型：' + format
				if (this.lastValue === res) {
					this.$logic.tip.error('重复扫描' + info + ',已忽略')
					return
				}
				this.lastValue = res


				this.codeValue = res;
				this.dataTo(this.model.dataTo, this.codeValue)
				let doNext = this.doEvent({
					eventName: 'scanafter',
					value: this.codeValue,
				}, '$comp_scanAfter')
				if (this.model.scanLoop === 'Y') {
					if (doNext === true) {
						this.$logic.tip.success('扫描' + info + '成功，请继续扫码')
						/* 	if (model.scanType === 'bar') { //条码类型扫描完成后暂停3秒重新开启
								Quagga.stop()
								setTimeout(() => {
									Quagga.start()
								}, 3000)

							} */
					} else {
						this.toQrStop()
					}
				} else {
					this.toQrStop()
				}


				this.onChange(this.codeValue) //主动调用触发事件
			},
			toScan() {
				if (this.isedit) { //开发模式不扫码
					return
				}
				this.lastValue = null
				this.scanRun = true
				if (this.model.scanType === 'bar') {
					this.quaggaInit()
					//this.barReader.start()
				}

			},
			quaggaInit() {
				let model = this.model
				let readers = []
				let types = 'code_128,ean,ean_8,code_39,code_39,codabar,upc,upc_e,i2of5,2of5,code_93'.split(',')
				for (let key of types) {
					if (model[key] === 'Y') {
						readers.push(key + '_reader')
					}
				}
				if (readers.length < 1) {
					this.$logic.tip.error('至少需要选择一种扫码类型')
					return
				}
				let screenWidth = document.documentElement.clientWidth
				let screenHeight = document.documentElement.clientHeight
				let _this = this
				Quagga.init({
					inputStream: {
						type: "LiveStream",
						target: this.$refs.video,
						deviceId: this.selectedDevice.deviceId,
						constraints: {
							/* width: {
								min: 640
							},
							height: {
								min: 480
							}, */
							width: screenWidth - 10,
							height: screenHeight - 50,
							aspectRatio: {
								min: 1,
								max: 100
							},
							facingMode: "environment" // or user
						}
					},
					locator: {
						patchSize: "medium",
						halfSample: true
					},
					numOfWorkers: 2,
					frequency: 10,
					decoder: {
						/* readers: [{
							format: "code_128_reader",
							config: {}
						}], */
						readers: readers,
						/* ['code_128_reader',
							 'ean_reader',
							'ean_8_reader',
							'code_39_reader',
							'code_39_vin_reader',
							'codabar_reader',
							'upc_reader',
							'upc_e_reader',
							'i2of5_reader',
							'2of5_reader',
							'code_93_reader'
						], */
					},
					locate: true
				}, function(err) {
					if (err) {
						_this.deviceTip = '扫码初始化错误：' + err
						_this.$logic.tip.error(_this.deviceTip)
						return
					}

					Quagga.onProcessed(function(result) {
						var drawingCtx = Quagga.canvas.ctx.overlay,
							drawingCanvas = Quagga.canvas.dom.overlay;

						if (result) {
							if (result.boxes) {
								drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")),
									parseInt(drawingCanvas.getAttribute("height")));
								result.boxes.filter(function(box) {
									return box !== result.box;
								}).forEach(function(box) {
									Quagga.ImageDebug.drawPath(box, {
										x: 0,
										y: 1
									}, drawingCtx, {
										color: "green",
										lineWidth: 2
									});
								});
							}

							if (result.box) {
								Quagga.ImageDebug.drawPath(result.box, {
									x: 0,
									y: 1
								}, drawingCtx, {
									color: "#00F",
									lineWidth: 2
								});
							}

							if (result.codeResult && result.codeResult.code) {
								Quagga.ImageDebug.drawPath(result.line, {
									x: 'x',
									y: 'y'
								}, drawingCtx, {
									color: 'red',
									lineWidth: 3
								});
							}
						}
					});

					Quagga.onDetected(function(result) {
						let code = result.codeResult.code
						let format = result.codeResult.format
						_this.deQrCode(code, format)


					});


					Quagga.start();
				})
 
				
			},
			deviceSelect(item) {
				this.selectedDevice = item
				this.toScan()
			},
			initQr(promis) {
				promis.then(res => {

				}).catch(err => {
					this.deviceTip = '二维码扫描初始化失败'
				})
			},
			scanInit() {
				/* 	let codeReader = new BrowserMultiFormatReader() //使用zxing检测摄像头
					codeReader.getVideoInputDevices()
						.then((videoInputDevices) => {
							this.deviceList = videoInputDevices
							if (videoInputDevices.length > 0) {
								this.selectedDevice = videoInputDevices[0]
								//this.selectedDeviceId = .deviceId
								this.deviceTip = videoInputDevices[0].label
								this.deviceStatus = true
							}
							console.log(this.deviceList)


						})
						.catch((err) => {
							this.deviceInfo = '相机设备获取失败，必须htts协议下访问，参见错误：' + err
							this.deviceTip = '相机设备获取失败'
							this.$logic.tip.error(this.deviceInfo)
						}) */
				Quagga.CameraAccess.enumerateVideoDevices()
					.then((videoInputDevices) => {
						//条码扫描类型加载摄像头列表
						this.deviceList = videoInputDevices
						if (videoInputDevices.length > 0) {
							this.selectedDevice = videoInputDevices[0]
							//this.selectedDeviceId = .deviceId
							this.deviceTip = videoInputDevices[0].label
							this.deviceStatus = true
						}

					}).catch((err) => {
						this.deviceInfo = '相机设备获取失败，必须https协议下访问，参见错误：' + err
						this.deviceTip = '相机设备获取失败'
						this.$logic.tip.error(this.deviceInfo)
					})
				this.videoId = 'video' + new Date().getTime()
				let model = this.model

				if (model.scanType === 'qr') { //二维码类型采用前置、后置摄像头方式
					this.deviceList = []
					this.deviceList.push({
						deviceId: 'auto',
						label: '自动选取摄像头'
					})
					this.deviceList.push({
						deviceId: 'rear',
						label: '选取后置摄像头'
					})
					this.deviceList.push({
						deviceId: 'front',
						label: '选取前置摄像头'
					})
					this.selectedDevice = this.deviceList[0]

				}
			},

			//以下方法是zxing相关方法，实测扫码灵敏度太低已弃用
			/* 		scanStop() {
						this.$refs.video.pause()				
						this.codeReader.reset()
						this.scanRun = false
					},
					deviceSelect(item) {
						this.selectedDevice = item
						this.toScan()
					},
					stopScan(tag) {
						if (tag == 1) {
							this.$refs.video.pause()
						} else if (tag == 2) {
							this.$refs.video.play()
						} else if (tag == 3) {
							this.codeReader.reset()
						}
					},
					toScan() {
						for (let key in this.codeReader) {
							if (key.indexOf('stop') < 0 || true) {
								//continue
							}
							console.log(key, typeof(this.codeReader[key])) //,this.codeReader[key]
						}
						this.lastValue = null
						this.scanRun = true
						this.codeReader.decodeFromInputVideoDeviceContinuously(this.selectedDevice.deviceId, this.videoId, (
							result,
							err) => {
							if (result) {
								console.log('Y', lastValue, result);
								if (lastValue == result.text) { //重复扫描不作处理
									return
								}
								this.$refs.video.pause()
								lastValue = result.text
								this.codeValue = result.text;
								this.dataTo(this.model.dataTo, this.codeValue)
								let doNext = this.doEvent({
									eventName: 'scanafter',
									value: this.codeValue,
								}, '$comp_scanAfter')
								if (this.model.scanLoop === 'Y') {
									if (doNext === true) {
										this.$refs.video.play()
									} else {
										this.scanStop()
										return false
									}
								} else {
									this.scanStop()
									return false
								}


							}
							if (err && !(err)) {
								this.$logic.tip.error('扫描出错：' + err)
								//console.error(err);
								this.scanRun = false
								return false
							}
						})
					},
					scanInit() {
						this.videoId = 'video' + new Date().getTime()
						this.codeReader = new BrowserMultiFormatReader()
						this.codeReader.getVideoInputDevices()
							.then((videoInputDevices) => {
								console.log(videoInputDevices)
								this.deviceList = videoInputDevices
								if (videoInputDevices.length > 0) {
									this.selectedDevice = videoInputDevices[0]
									//this.selectedDeviceId = .deviceId
									this.deviceTip = videoInputDevices[0].label
									this.deviceStatus = true
								}


							})
							.catch((err) => {
								this.deviceInfo = '相机设备获取失败，必须htts协议下访问，参见错误：' + err
								this.deviceTip = '相机设备获取失败'
								this.$logic.tip.error(this.deviceInfo)
							})
					} */


			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值							 
					return this.codeValue

				} else {
					this.codeValue = value

				}
			}
		},
		computed: {
			/* 	inputStyle() {
					let model = this.model
					let css = this.compStyle
					if (model.showInput == 'Y') {
						css.width='100%'
					}else{
						css.width='0px'
					}
					console.log(css)
					return css
				}, */
		},
		components: {
			qrstream: QrStream
		},
		/* 		mounted() {
					this.$nextTick(() => {
						console.log(this.$refs.qrstream)
					})

				}, */
		created() {
			if ('BarcodeDetector' in window) {
				// 创建检测器
				const barcodeDetector = new BarcodeDetector({
					// formats 是检测的条码格式类型
					formats: ['qr_code']
				});
				console.log(barcodeDetector)
			}
			this.scanInit()
		}
	}
</script>

<style scoped>
	.scan-read {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0px;
		left: 0px;
		z-index: 1000;
		background-color: #000000;
	}

	.scan-tool {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 40px;
		color: #ffffff;
	}

	.scan-video {
		height: calc(100% - 40px);
	}

	.video-item {
		/* 	text-align: center; */
		position: relative;
		width: 100%;
		height: 100%;
	}



	.device-sel {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		/* padding-left: 5px; */
		border-left: solid 1px #ffffff;
		margin-left: 20px;
	}

	.dropdown-link {
		margin: 5px;
	}

	.device-button {
		padding: 0px 0px;
	}

	.menu-span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		min-height: 24px;
	}

	.scan-mode {
		margin: 0px 5px;
	}

	/* 
	.qr-input {
		width: calc(100% - 30px);
	}

	.qr-button {
		width: 25px;
		padding-left: 5px;
	}
	 */
</style>
<style>
	.video-item .drawingBuffer {
		position: absolute;
		left: 0px;
		top: 0px;
	}
</style>