import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'
let fits=[{label:'全图自由缩放',value:'fill'},{label:'全图等比缩放',value:'contain'},{label:'等比缩放填充',value:'cover'},{label:'原始大小超出剪裁',value:'none'},{label:'向下拉伸',value:'scale-down'},]
 let dataConfig1=compBase.getDataConfig()
  let dataConfig2=compBase.getDataConfig()
 let validate=compBase.getValidate() 
 validate.validateSet.exclude='input,blur,change'
let base = [
	{key: 'title',text: '图片上传'	},
	//compBase.mode,
//	{key: 'value',text: '图片地址',type: 'file',init: '/img/filetypes/bmp.png',	target: '',	bursh: true,bind: true,tip: '',list: null},
/*	{key: 'view',text: '开启预览',type: 'select',init: 'Y',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
 	{key: 'rotate',text: '开启旋转N',type: 'select',init: '',	target: '',bursh: true,bind: true,tip: '不旋转=,顺时针旋转=rotate-forward,逆时针旋转=rotate-reverse',list: options.rotateTypes},
  	{key: 'seconds',text: '转速(秒)',type: 'number',init: '4',	target: '',bursh: true,bind: true,tip: '',list: null},
  
	{key:'borderS',text:'边框线型',type:'select',init:'none',target:'borderStyle',bursh:true,bind:true,tip:'边框线粗，象素',list:options.BorderType},
    {key:'borderL',text:'左边框',type:'text',init:'1px',target:'borderLeftWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderT',text:'上边框',type:'text',init:'1px',target:'borderTopWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderR',text:'右边框',type:'text',init:'1px',target:'borderRightWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderB',text:'下边框',type:'text',init:'1px',target:'borderBottomWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderColor',text:'边框颜色',type:'color',init:'#cccccc',target:'borderColor',bursh:true,bind:true,tip:'边框颜色',list:null},	 
    {key:'borderRadius',text:'圆角大小',type:'text',init:'0px',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 
 */ 
 // {key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
  	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
  	{key: 'urlField',text: '保存字段',type: 'field',init: dataConfig1,	target: '',	bursh: true,bind: false,tip: '图片地址保存的字段',attribute:'value'},
 // 	{key: 'infoField',text: '扩展字段',type: 'field',init: dataConfig2,	target: '',	bursh: true,bind: false,tip: '图片扩展信息保存的字段',attribute:'value'},

 {key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
 {key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '使用数组类型检验',attribute:'value'},
 
	{key: 'allowCamera',text: '允许拍照',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '仅适用于移动端操作时生效',list: options.YON},
    {key: 'allowSelect',text: '允许选取',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '是否允许选取本地相册文件',list: options.YON},
    {key: 'allowMulti',text: '允许多文件',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'allowCut',text: '允许剪裁',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'allowCut',text: '允许编辑',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'showName',text: '显示文件名',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key: 'showBox',text: '显示外边框',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
 {key: 'imgWidth',text: '图片项宽度',type: 'text',init: '80px',	target: '--img-width',bursh: true,bind: true,tip: '',list: null},
 {key: 'imgHeight',text: '图片项高度',type: 'text',init: '60px',	target: '--img-height',bursh: true,bind: true,tip: '',list: null},
 
   {key: 'fit',text: '适配方式',type: 'select',init: 'contain',	target: '',bursh: true,bind: true,tip: '',list: fits},
 
 {key: 'maxWidth',text: '压缩宽度',type: 'number',init: 0,	target: '',bursh: true,bind: true,tip: '当图片尺寸过大时可进行等比压缩至最大的宽度，0表示不压缩尺寸',list: null},
   {key: 'quality',text: '压缩质量',type: 'number',init: 0,	target: '',bursh: true,bind: true,tip: '1到100之间的数值，100为最高的质量，0表示不改变图片质量,适应降低图片质量可明显减少图片文件的大小',list: null},


//   {key: 'imgUrl',text: '图片地址',type: 'text',init: '',	target: '',	bursh: true,bind: true,read:true,tip: '图片地址，多个文件以;分隔',list: null},
  {key: 'imgList',text: '图片集合',type: 'text',init: [],	target: '',	bursh: true,bind: true,read:true,tip: '图片集合',list: null},


  compBase.status2, 
   
	{key: 'width',text: '宽度',type: 'text',init: '100%',	target: 'width',bursh: true,bind: true,tip: '',list: null},
//	{key: 'minHeight',text: '最小高度',type: 'text',init: '80px',	target: 'min-height',bursh: true,bind: true,tip: '',list: null},

]

let events=[
	{key: '$comp_uploadBefore',text: '图片上传前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '上传前触发，如有返回false 阻止上传',list: null	},
	{key: '$comp_uploadAfter',text: '图片上传后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '上传完成后触发',list: null	},
	
]
let imgUpload = {
	data: {
		type: 'imgupload',isInput:'S',
	},
	delete: '',
	hide: 'width,minHeight,allowCut,imgInfo',
	base: base.concat(compBase.base),
	special: extend.badge.concat(extend.tip).concat(extend.menu).concat(extend.tip),
	action:  options.getCommonEvents().concat(events).concat([
	//	options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default imgUpload