import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
//dataName指向属性名，fieldName字段名，unionType,refuseNull 这三个从数据表对话框中回写; check多值类型暂时以逗号隔的字符串返回，后期可扩展增加设置返回值方式，数组还是逗号隔的字符串
 
let validate=compBase.getValidate() 

let datas =JSON.stringify( [
    { value:'选项一',icon:'' },
	{ value:'选项二',icon:'' },
	{ value:'选项三',icon:'' },
	{ value:'选项四',icon:'' }, 
	 
]) 
let dataConfig=compBase.getDataConfig()
 
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'40%',unique:true},
    {key:'icon',label:'图标',type:'icon',default:'',list:[],field:'',width:'40%',unique:false},
]


let base = [
	{key: 'title',text: '下拉文本框'	},
	{key: 'value',text: '文本内容',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
//	{key: 'dataCfg',text: '数据配置',type: 'datacfg',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',list: compBase.textTypes},

	{key: 'inType',text: '输入类型',type: 'select',init: 'text',	target: '',	bursh: true,bind: false,tip: '',list: compBase.textTypes},
//	{key: 'compType',text: '组件类型',type: 'select',init: 'comp',target: '',	bursh: true,bind: false,tip: '',list: compBase.compTypes},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
	{key: 'clearSpace',text: '自动去空格',type: 'select',init: 'none',target: '',	bursh: true,bind: true,tip: '',list:options.toOptions('不作处理=none,去除两边空格=side,去除全部空格=all')},
	 
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 
   // {key:'minW',text:'最小宽度',type:'text',init:'100px',target:'minWidth',	bursh: true,bind: true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
	//{key: 'length',text: '限定字数',type: 'number',init: '0',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'editAllow',text: '允许输入',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否允许手工输入',list: null},
	{key: 'trigger',text: '下拉触发',type: 'select',init: 'hover',target: '',bursh: true,bind: true,tip: '出现下拉选项的动作',list: options.toOptions('鼠标悬停=hover,鼠标点击=click')},

    {key: 'tip',text: '提示信息',type: 'text',init: '输入或选择',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'iconIH',text: '内置前图标',type: 'icon',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
//    {key: 'iconIT',text: '内置后图标',type: 'icon',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
 //   {key: 'iconOH',text: '外置前图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
//    {key: 'textOH',text: '外置前文本',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null}, 
//    {key: 'iconOT',text: '外置后图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
//    {key: 'textOT',text: '外置后文本',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},
 
	 {key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
  	 compBase.status,
  
    {key: 'title',text: '样式定制'	}, 
 
    {key:'bgColor',text:'背景色',type:'colorA',init:null,target:'--el-fill-color-blank',	bursh: true,bind: true,tip:'默认值：24px',list:null},
    {key:'borderColor',text:'边框颜色',type:'color',init:null,target:'--el-border-color',	bursh: true,bind: true,tip:'默认值：24px',list:null},
    {key:'textColor',text:'文字颜色',type:'color',init:null,target:'--el-text-color-regular',	bursh: true,bind: true,tip:'默认值：24px',list:null},
    {key:'iconColor',text:'图标颜色',type:'color',init:null,target:'--el-text-color-placeholder',	bursh: true,bind: true,tip:'默认值：24px',list:null},
 
]
	
//compBase.appendDbConfig(base,1)
let textselect = {
	data: {
		type: 'textselect',isInput:'S',fieldType:'varchar',marginL:'2px',marginR:'0px'
	},
	delete: 'fontName,fontColor,fontBold,fontItalic,fontHeight',
	hide: 'inType,width,'+compBase.HideFields,
	base: base.concat(font).concat(compBase.base),
	special: extend.tip.concat(extend.badge).concat([
 		
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null,attribute:''},
		
		{key: 'listData',text: '选项数据',type: 'jsonArray',init: datas,	target: '',	bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},
		
	]),
	action:  options.getEvents(null,'$event','$event_mousemove,$event_mouseover,$event_scroll').concat([compBase.menuClick]).concat(options.getEvents(null,'$hook'))
}

export default textselect
