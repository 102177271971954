 
import options from  '../../../../public/options.js'
import echartConfig from '../echartConfig.js'
let base=[].concat( echartConfig.base)
 
let map=[

//	 {key:'polarRadiusI',text:'空心圆半径',type:'text',init:'20%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
//	 {key:'polarRadiusO',text:'外圆半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	 {key:'mapColorBy',text:'取色方式',type:'select',init:'data',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},

	 {key:'mapArea',text:'地图区域',type:'maparea',init:'中国',path:'_geo.map',bursh:true,bind:true,tip:'',list:null},
//	 {key:'mapCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
//	 {key:'mapCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'seriesMapLeft',text:'水平对齐',type:'select',init:'center',path:'_geo.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	 {key:'seriesMapTop',text:'垂直对齐',type:'select',init:'middle',path:'_geo.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
	 {key:'seriesMapLeftPx',text:'左边距离',type:'number',init:null,path:'_geo.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	 {key:'seriesMapTopPx',text:'顶部距离',type:'number',init:null,path:'_geo.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},


]

options.insertBaseConfig(base,map)
let action={timer: echartConfig.timerEvents,comm:echartConfig.commEvents}

 let special=[
	 {key: 'title',text: '地图'},

	 {key: 'manualSeries',text: '手动创建系列',type: 'text',init: 'Y',bursh: true,bind: true,tip: '由组件生成系列',list: null},
	 
	{key: 'mapRoam',text: '缩放漫游',type: 'switch',init: 'Y',path: '_geo.roam',bursh: true,bind: true,tip: '',list: options.YON},
  	{key: 'mapRoom',text: '缩放比',type: 'number',init: 1.2,path: '_geo.zoom',bursh: true,bind: true,tip: '',list: null},
    {key: 'mapSelectedMode',text: '选择模式',type: 'select',init: 'single',path: '_geo.selectedMode',bursh: true,bind: true,tip: '',list: options.toOptions('关闭选择=none,单选=single,多选=multiple')},
	{key:'mapAreaColor',text:'区域颜色',type:'colorA',init:'#eeeeee',path:'_geo.itemStyle.areaColor',bursh:true,bind:true,tip:'如果为按数据项取色模式将覆盖此颜色',list:null},
//	{key: 'polarAxisTickShow',text: '显示边界线',type: 'switch',init: 'Y',path: '_geo.itemStyle.borderColor',bursh: true,bind: true,tip: '',list: options.YON},
	//{key: 'polarAxisTickLength',text: '边界线取色',type: 'select',init: 5,bursh: true,bind: true,tip: '',list: options.toOptions('固定颜色=fix,跟随区域取色=area')},
	{key: 'mapAreaBorderColor',text: '边界线颜色',type: 'color',init: '#cccccc',path: '_geo.itemStyle.borderColor',bursh: true,bind: true,tip: '',list: null},
	{key: 'mapAreaBorderWidth',text: '边界线粗细',type: 'number',init: 0,path: '_geo.itemStyle.borderWidth',bursh: true,bind: true,tip: '',list: null},
	{key: 'mapAreaShadowBlur',text: '阴影大小',type: 'number',init: 0,path: '_geo.itemStyle.shadowBlur',bursh: true,bind: true,tip: '',list: null},
	{key: 'mapAreaShadowColor',text: '阴影颜色',type: 'color',init: '#000000',path: '_geo.itemStyle.shadowColor',bursh: true,bind: true,tip: '',list: null},
	{key: 'mapAreaShadowOffsetX',text: '阴影偏距X',type: 'number',init: 0,path: '_geo.itemStyle.shadowOffsetX',bursh: true,bind: true,tip: '',list: null},
	{key: 'mapAreaShadowOffsetY',text: '阴影偏距Y',type: 'number',init: 0,path: '_geo.itemStyle.shadowOffsetY',bursh: true,bind: true,tip: '',list: null},


	{key: 'mapLabelShow',text: '显示标签',type: 'switch',init: 'Y',path: '_geo.label.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key:'mapLabelFontSize',text:'标签字号',type:'number',init:'12',path:'_geo.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'mapLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_geo.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'mapLabelFontColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_geo.label.color',bursh:true,bind:true,tip:'',list:null},
//	{key:'mapLabelFontPosition',text:'标签位置',type:'select',init:'inside',path:'_geo.label.position',bursh:true,bind:true,tip:'',list:options.chartPosition},

	{key: 'mapLabelRotate',text: '标签旋转',type: 'number',init: 0,path: '_geo.label.rotate',bursh: true,bind: true,tip: '',list: null},




	
	 
 ]
 
export default {base,special,action}



 