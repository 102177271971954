import {
	ElMessageBox
} from 'element-plus'

class Message {
	message(title, content, recall) {
		ElMessageBox.alert(content, title, {
			confirmButtonText: '确定',
			callback: (action) => {
				if (recall) {
					recall(action)
				}
			},
		})
	}
	confirm(title, content, okRecall, cancelRecall) {
		ElMessageBox.confirm(
				content,
				title, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}
			)
			.then(() => {
				if (okRecall) {
					okRecall()
				}
			})
			.catch(() => {
				if (cancelRecall) {
					cancelRecall()
				}
			})
	}
	input(title, content, okRecall, cancelRecall) { //content如果是字符串类型为提示信息，如对象类型，含初始值
		let initValue = null
		let info = content
		if (typeof(content) === 'object') {
			initValue = content.init
			info = content.info
		}
		ElMessageBox.prompt(info, title, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValue: initValue

			})
			.then(({
				value
			}) => {
				if (okRecall) {
					okRecall(value)
				}
			})
			.catch(() => {
				if (cancelRecall) {
					cancelRecall()
				}
			})
	}
}
export default new Message()