import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
import echartConfig from '../../echartConfig.js'
import initColor from '../../initColor.js'
let initData=JSON.stringify( [
 	['名称','周一','周二','周三'],
 	['数值',0.40,0.60,0.80],
	 

 ]).replaceAll('],','],\r\n')
let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
let base = [].concat( echartConfig.base)
let polar=[
 	{key:'polarCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'polarCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'seriesWaterRadius',text:'半径大小',type:'text',init:'60%',path:'_series.radius',bursh:true,bind:true,tip:'',list:null},

/*//	{key:'polarRadiusI',text:'雷达内半径',type:'text',init:'0%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	{key:'polarRadiusO',text:'仪表盘半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
 */
 //{key:'waterCenterX',text:'中心位置X',type:'text',init:'0',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
 //{key:'waterCenterY',text:'中心位置Y',type:'text',init:'40%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
 

]
options.insertBaseConfig(base,polar)
 
let special = [
	 {key:'title',text:'水位图系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'liquidFill',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesPieColorBy',text:'取色方式',type:'select',init:'data',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
 
    {key:'seriesWaveAnimation',text:'是否波动',type:'switch',init:'Y',path:'_series.waveAnimation',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesWaterAmplitude',text:'振幅',type:'text',init: '5%',path:'_series.amplitude',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesWaterOpacity',text:'波浪透明度',type:'number',init: 0.99,path:'_series.itemStyle.opacity',bursh:true,bind:true,tip:'',list:null},
   {key:'seriesWaterOutlineShow',text:'显示外边框',type:'switch',init:'Y',path:'_series.outline.show',bursh:true,bind:true,tip:'',list:options.YON},
    {key:'seriesWaterBorderDistance',text:'边距',type:'number',init:0,path:'_series.outline.borderDistance',bursh:true,bind:true,tip:'',list:null},
    {key:'seriesWaterShap',text:'外边框形状',type:'select',init:'circle',path:'_series.shape',bursh:true,bind:true,tip:'',list:options.chartSymbol},
 	 {key:'seriesWaterOutBorderColor',text:'外边框颜色',type:'color',init:'#156ACF',path:'_series.outline.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesWaterOutBorderWidth',text:'外边框粗细',type:'number',init:5,path:'_series.outline.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
     {key:'seriesWaterShadowBlur',text:'阴影大小',type:'number',init:0,path:'_series.outline.itemStyle.shadowBlur',bursh:true,bind:true,tip:'',list:null},
 	 {key:'seriesWaterShadowColor',text:'阴影颜色',type:'color',init:'#000000',path:'_series.outline.itemStyle.shadowColor',bursh:true,bind:true,tip:'',list:null},

 
	 {key:'seriesWaterBackColor',text:'背景颜色',type:'colorA',init:'rgba(255, 255, 255, 0)',path:'_series.backgroundStyle.color',bursh:true,bind:true,tip:'',list:null},
 	 {key:'seriesWaterBackBorderColor',text:'内边框颜色',type:'color',init:null,path:'_series.backgroundStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
 	 {key:'seriesWaterBorderWidth',text:'内边框粗细',type:'number',init:0,path:'_series.backgroundStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},


    {key:'seriesWaterLabelShow',text:'显示标签',type:'switch',init:'Y',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesWaterLabelFontSize',text:'标签字号',type:'number',init:16,path:'_series.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesWaterLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_series.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'seriesWaterLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesWaterLabelPosition',text:'标签位置',type:'select',init:'inside',path:'_series.label.position',bursh:true,bind:true,tip:'',list:options.toOptions( '内部=inside,左侧=left,右侧=right,顶部=top,底部=bottom')},

/* 	{key:'seriesGaugeTitleCenterX',text:'中心位置X',type:'text',init:'0',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'seriesGaugeTitleCenterY',text:'中心位置Y',type:'text',init:'40%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
    {key:'seriesGaugeDetailShow',text:'显示数值',type:'switch',init:'Y',path:'_series.detail.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesGaugeDetailFontSize',text:'数值字号',type:'number',init:25,path:'_series.detail.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesGaugeDetailFontFamily',text:'数值字体',type:'select',init:'',path:'_series.detail.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'seriesGaugeDetailColor',text:'数值颜色',type:'color',init:options.initColor.text.dark,path:'_series.detail.color',bursh:true,bind:true,tip:'',list:null},
	// {key:'seriesGaugeDetailCenter',text:'数值位置',type:'number',init:15,path:'_series.offsetCenter',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesGaugeDetailCenterX',text:'位置偏移X',type:'text',init:'0',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'seriesGaugeDetailCenterY',text:'位置偏移Y',type:'text',init:'70%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},

 */
	  
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 

]//.concat(polarConfig.special)
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_water',legendShow:'N',color: initColor.water,
		chartClan: 'polar'
	},
	delete: '',
	hide: 'width,height,seriesType,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
}
export default cfg
