<template>
	<div class="fieldcontent" :style="style">
		<div class="left" :title="textPath">
			<input type="text"  style="padding-left:11px;" :value="dataText" :placeholder="placeholder" @focus="onFocus" @blur="onBlur" readonly
				class="input-text" />
			<i v-show="! disabled && isFocus" @click="clearResult" class="fal fa-times-circle button-icon"
				style="margin-left: -32px;" title="清除当前已选择的数据"></i>
		</div>
		<div class="righ" @click.stop="toSelectTree">
			<i v-if="disabled" :class="icon "></i>
			<i v-else :class="icon + ' button-icon'"></i>
		</div>
		<logicdialog @ok="treeListDialogOk" @init="treeDialogInit" :title="title" :icon="icon">
			<div class="contentflex">

				<div class="treeleft">
					<treeview :nodes="nodes" :allow-search="true" :allow-edit="false" :allow-check="false"
						:allow-drag="false" :expand-all="false" :expand-click="false" :expanded="expandedNodes"
						:accordion="false" bar-height="34px" :split-char="splitChar" @nodeclick="nodeClick"
						@init="treeInit">
					</treeview>

				</div>
				<div class="treeright">
					<titlebar :title="nodeObj.textPath" icon="far fa-hand-point-right">
						<div style="width: 200px;display: flex;align-items: center; ">
							<div style="width: 150px;margin-right: 5px;">
								<el-input v-model="keyWord" placeholder="名称关键字查找" clearable size="default" />
							</div>
							<div>
								<el-button @click="toQuery" type="success" size="small">
									<i class="far fa-search"></i>
								</el-button>
							</div>

						</div>
					</titlebar>
					<div style="height: calc(100% - 40px);overflow: auto;margin-top: 1px;">
						<table border="1" class="tablelist" cellspacing="0" cellpadding="0">
							<tr class="tabletop">
								<td align="center" class=" colfirst">
									<div>#</div>

								</td>
								<template v-for="col in dataSet.heads">
									<td v-if="col.show !== false" :style="{minWidth:col.width?col.width:'100px'}">
										<div>
											{{col.label}}
										</div>
									</td>
								</template>
							</tr>

							<tr v-for="(row,i) in dataSet.dataList" @click="toSelect(row)"
								:class="row===selectItem?'trbody trselect': 'trbody'">
								<td class="tdhead colfirst">{{i+1}}</td>
								<template v-for="(col,j) in dataSet.heads">
									<td v-if="col.show !==false" :align="col.align">
										{{row[j]}}
									</td>
								</template>

							</tr>
						</table>
					</div>
				</div>
			</div>

		</logicdialog>
	</div>

</template>

<script>
	import Treeview from '../treeview/TreeView.vue'
	export default {
		props: {
			size: {
				type: String,
				default: 'default'
			},
			width: {
				type: String,
				default: '60%'
			},
			height: {
				type: String,
				default: '80vh'
			},
			title: {
				type: String,
				default: '树选择'
			},
			icon: {
				type: String,
				default: 'far fa-folder-open'
			},
			placeholder: {
				type: String,
				default: '请选择'
			},
			textPath: { //路径文本内容
				type: String,
				default: ''
			},
			splitChar: {
				type: String,
				default: '/'
			},
			reset: {
				type: Boolean,
				default: true
			},
			nodeList: {
				type: Array,
				default: []
			},
			expandedNodes: {
				type: Array,
				default: []
			},

			disabled: {
				type: Boolean,
				default: false
			},
			beforeOK: {
				type: Function,
				default: null
			},
			rowHeight: {
				type: String,
				default: '30px'
			},
			textField: {
				type: String,
				default: null

			},
			queryResult: {
				type: Object,
				default: null
			},
			dataText: {
				type: String,
				default: ''
			}




		},
		emits: ['select', 'focus', 'blur', 'choose', 'open', 'query'],
		data() {
			return {
				treeDialog: null,
				treeObj: {

				},
				nodeObj: {
					textPath: ''
				},
				result: {
					treeNode: {
						textPath: ''
					},
					listItem: {},
					//text: ''

				},
				dataSet: {
					heads: [{
						label: '',
						fieldName: '',
						with: '100px'
					}],
					dataList: []
				},
				keyWord: '',
				selectItem: null,
				isFocus: false


			}
		},

		methods: {
			toQuery() {
				let param = this.getNodeResult()
				param.keyWord = this.keyWord
				this.$emit('query', param)
			},

			clearResult() {
				let node = {
					id: null,
					pId: null,
					label: '',
					textPath: '',
					idPath: '',
					node: null,
					parent: null,
					level: [],
				}
				this.result = {
					treeNode: node,
					listItem: {},

				}
				this.$emit('select', this.result)

			},
			onFocus() {
				this.isFocus = true
				this.$emit('focus')
			},
			onBlur() {
				setTimeout(() => { //下一个时间生效，否则清除的按钮被隐藏发生不了点击事件
					this.isFocus = false
				}, 1000)

				this.$emit('blur')
			},
			nodeClick(nodeObj) {
				this.nodeObj = nodeObj
				this.selectItem = null
				this.toQuery()

			},
			treeInit(treeObj) {
				this.treeObj = treeObj
			},
			toSelect(row) {
				this.selectItem = row
				let rs = this.getDataResult()
				if (rs === null) {
					return
				}
				this.$emit('choose', rs)
			},
			toSelectTree() {
				if (this.disabled) {
					return
				}

				if (this.reset) {
					this.nodeObj = {
						textPath: ''
					}
					this.selectItem = null
					this.dataSet.dataList = []

				}

				this.$emit('open', this)
				//this.show()

			},
			show() {
				this.treeDialog.visible = true
			},
			hide() {
				this.treeDialog.visible = false
			},
			getDataResult() {
				let rs = null
				if (!this.textField) {
					this.$logic.tip.error('未设置回显文本值字段名称')
					return rs
				}
				if (!this.nodeObj || !this.nodeObj.nodes) {
					this.$logic.tip.error('未选择树节点')
					return rs
				}

				if (this.selectItem) {
					let node = this.getNodeResult()
					let row = {}
					let c = 0
					for (let col of this.queryResult.heads) {
						row[col.field || col.fieldName] = this.selectItem[c] //兼容以前的字段名称
						//row[col.label] = this.selectItem[c]//考虑到后期做多语言，使用标题文本存在问题
						c++
					}
					rs = {
						treeNode: node,
						listItem: row,
						//text: row[this.textField]
					}


				} else {
					this.$logic.tip.error('未选择任何一项列表数据')
				}

				return rs
			},
			getNodeResult() {
				let ls = []
				if (this.nodeObj.nodes) {
					for (let node of this.nodeObj.nodes) {
						ls.push(node.data)
					}
				}
				//console.log(this.nodeObj)
				let parent = ls.length > 1 ? ls[ls.length - 2] : null
				let result = {
					id: this.nodeObj.data.id,
					label: this.nodeObj.data.label,
					pid: parent ? parent.id : null,
					textPath: this.nodeObj.textPath,
					idPath: this.nodeObj.idPath,
					node: this.nodeObj.data,
					parent: parent,
					level: ls,
				}
				return result
			},
			treeListDialogOk(dialog) {

				if (this.nodeObj.data && this.selectItem) {
					let result = this.getDataResult()
					if (result === null) {
						return
					}
					if (this.beforeOK) { //回调前置检查
						let ck = this.beforeOK(result)
						if (ck === false) { //明确返回false才阻止选择
							return
						}
					}

					this.result = result

					this.$emit('select', result)
					this.hide()
				} else {
					this.$logic.tip.error('您未选择任何一个分类下的数据')
				}

			},
			treeDialogInit(dialog) {
				this.treeDialog = dialog
				dialog.width = this.width
				dialog.height = this.height
				//dialog.fullHeight = true
			}
		},
		watch: {
			queryResult(nv, ov) {
				this.selectItem = null
				this.dataSet = nv
			}
		},
		computed: {

			nodes() {
				let rs = []
				let nodeMap = {}
				let list = this.nodeList
				for (let data of list) {
					nodeMap[data.id] = {
						id: data.id,
						parent: null,
						label: data.label,
						icon: data.icon,
						color: data.color,
						children: [],
						data: data.data
					}
				}
				for (let item of list) {
					let key = item.id
					let pkey = 'pId' in item ? item.pId : item.pid
					let node = nodeMap[key]

					let pnode = nodeMap[pkey]
					if (pnode) {
						node.parent = pnode
						pnode.children.push(node)
					} else {
						rs.push(node)
					}
				}
				//console.log(rs)
				return rs

			},
			style() {
				let css = {
					height: this.size == 'large' ? '40px' : this.size == 'small' ? '22px' : '32px'
				}
				css['--buton-icon-size'] = this.size == 'large' ? '18px' : this.size == 'small' ? '14px' : '16px'

				css['--head-height'] = this.rowHeight
				css['--row-height'] = this.rowHeight
				return css
			}
		},
		created() {
			if (this.queryResult) {
				this.dataSet = this.queryResult
			}


		},
		components: {
			treeview: Treeview
		},
	}
</script>

<style scoped>
	.fieldcontent {
		display: flex;
		align-items: center;
		width: 100%;
		height: 35px;
		min-width: 100px;

	}

	.left {
		width: calc(100% - 43px);
		height: calc(100% - 2px);
		padding-left: 3px;
	}

	.righ {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: calc(100% - 3px);
		border: solid 1px #cccccc;
		border-left: 0px;
		border-radius: 0px 4px 4px 0px;
		background-color: #f0f0f0;
		color: #303133;
	}

	.input-text {
		width: calc(100% - 6px);
		height: calc(100% - 1px);
		border-radius: 4px 0px 0px 4px;
		padding: 0px 2px;
		text-align: left;
		direction: rtl;
	}

	.button-icon {
		cursor: pointer;
		font-size: var(--buton-icon-size);
	}

	.button-icon:hover {
		color: #409eff;
	}

	.treeleft {
		width: 300px;
		height: calc(100% - 2px);
		overflow: auto;
		border: solid 1px #cccccc;
	}

	.treeright {
		width: calc(100% - 300px);
		height: calc(100% - 2px);
		overflow: auto;
		border: solid 1px #cccccc;
		border-left: 0px;
	}

	.tablelist {
		min-width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.tabletop {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		background-color: #e7e7e7;
	}

	.tabletop>td {
		height: var(--head-height);
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;
		cursor: pointer;

	}

	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.tdhead {
		text-align: center;
		background-color: #eaeaea;

		border-left: solid 1px #EEEEEE;
		border-top: solid 1px #EEEEEE;
		border-right: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.colfirst {
		width: 50px;
		box-shadow: 1px 0px #dfdfdf;
	}

	.headcell {
		position: relative;
		width: 100%;
		height: 100%;
		/* display: flex;
		justify-content: space-between;
		align-items: center; */
		text-align: center;

	}

	.trbody {
		height: var(--row-height);
	}

	.trbody:nth-child(odd) {
		background-color: #fafafa;
	}

	.trbody:hover {
		background-color: #F2F6FC;
	}

	.trbody>td {
		padding: 0px 5px;
	}

	.trselect {
		background-color: #fff7c7 !important;
	}
</style>