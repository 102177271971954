import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
import extend from '../../public/extend.js'
let base=layout.concat(font)
let cfg= {key:'innerText',text:'文本内容',type:'text',init:null,target:null,bursh:true,bind:true,tip:'容器中插入文本内容',list:null}

base.splice( 0,0,cfg)
let row= {
	data:{
		type:'row',width:'100%',height:'',alignV:'flex-start',minH:'20px',overH:'visible',overV:'visible',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'',
	hide:'display,flexW,position',//width,minW',
	base:base ,
	special :[].concat(special),
	action:[
		{key:'enabled',text:'可用状态',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		
	].concat( options.getEvents(null,'$event_click,$event_mousedown,$event_mouseup,$event_mouseleave,$event_mouseover',null)).concat(extend.link) //,$event_dblclick,$event_mouseenter,$hook,'$event_change,$event_input'
}

export default row