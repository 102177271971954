import {
	createRouter,
	createWebHistory
} from 'vue-router'
import store from '/src/store' //使用全局数据
import Application from '../views/desk/Application.vue'
import Login from '../views/login/Login.vue'

const routes = [{
		path: '/',
		redirect: '/login/',
		name: 'Default',
		//	component: Application // () => import(  '../views/desk/Application.vue'),
	},
	{
		path: '/run/:formCode',
		//redirect:'/login/',
		name: 'Application',
		component: Application // () => import(  '../views/desk/Application.vue'),
	},
	{
		path: '/appimport/:appKey',
		name: 'AppImport',
		component: () => import('../views/desk/AppImport.vue'),
	},
	{
		path: '/sys/:appId/:formVersion', //版本号不必输
		name: 'ApplicationRun',
		component: Application
	},
	{
		path: '/login/',
		name: 'Login',
		component: Login // () => import('../views/login/Login.vue'),
	},
	{
		path: '/formdev/:formId',
		name: 'FormDev',
		component: () => import('../views/dev/form/FormDev.vue'),
	},

	{
		path: '/formview/:formId',
		name: 'FormView',
		component: () => import('../components/form/FormView.vue'),
	},
	{
		path: '/flowdev/:flowId',
		name: 'FlowDev',
		component: () => import('../views/dev/flow/FlowDev.vue'),
	},
	{
		path: '/flowrun/:flowId',
		name: 'FlowRun',
		component: () => import('../views/dev/flow/FlowRun.vue'),
	},
	{
		path: '/platform',
		redirect: '/platform/desk',
		name: 'DeskTop',
		component: () => import( /* webpackChunkName: "about" */ '../views/desk/DeskTop.vue'),
		children: [{
				path: '/platform/desk',
				name: 'DeskBody',
				component: () => import( /* webpackChunkName: "about" */ '../views/desk/DeskBody.vue')
			},
			{
				path: '/platform/dev/:groupId?',
				name: 'AppDev',
				meta: {
					devCapacity: 'dev'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/AppDev.vue'),
				children: []

			},
			{
				path: '/platform/data',
				name: 'DataManage',
				meta: {
					adminCapacity: 'data'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/data/DataManage.vue'),
				children: []

			},
			{
				path: '/platform/org',
				name: 'OrgManage',
				meta: {
					adminCapacity: 'org'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/Org.vue'),
				children: []

			},
			{
				path: '/platform/role',
				name: 'RoleManage',
				meta: {
					adminCapacity: 'role'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/Role.vue'),
				children: []

			},
			{
				path: '/platform/appaccess',
				name: 'AppAccess',
				meta: {
					adminCapacity: 'app'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/AppAccess.vue'),
				children: []

			},
			{
				path: '/platform/syspopedom',
				name: 'SysPopedom',
				meta: {
					adminCapacity: 'popedom'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/SysPopedom.vue'),
				children: []

			},
			{
				path: '/platform/unitsaudit',
				name: 'UnitsAudit',
				/* meta: {
					adminCapacity: 'popedom'
				}, */
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/UnitAudit.vue'),
				children: []

			},
			{
				path: '/platform/unitset',
				name: 'UnitSet',
				meta: {
					adminCapacity: 'popedom'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/UnitSet.vue'),
				children: []

			},
			{
				path: '/platform/info',
				name: 'SysInfo',
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/SysInfo.vue'),
				children: []

			},
			{
				path: '/platform/station',
				name: 'StationManage',
				component: () => import( /* webpackChunkName: "about" */ '../views/dev/manage/Station.vue'),
				children: []

			},
			{
				path: '/platform/dev/design/:appId',
				name: 'AppDesign',
				component: () => import('../views/dev/AppDesign.vue')
			},
		]
	},


	{
		path: '/:pathMatch(.*)*',
		component: Login // Application
	},
]

const router = createRouter({
	history: createWebHistory(''), //process.env.BASE_URL
	routes
})

let getToNext = () => {
	let url = ''
	let to = window.location.href
	if (to && to.length > 8 && to.indexOf('http') == 0) {
		let part = to.substring(8)
		//判断如果最多只有一个/就不转向，有两级//才转向
		let la = part.length
		let lb = part.replace(/\//g, '').length
		if (la - lb > 1) {
			url = encodeURIComponent(to)
		} else {

		}

	} else {
		url = encodeURIComponent(to)
	}
	return url


}

router.beforeEach((to, from, next) => {
	if (to.name == "Login" || to.name == "AppImport" || to.name=='Application') { //|| to.name.indexOf("View") > -1
		next()
	} else {
		let nt = (new Date()).getTime()
		//当前时间必须大于登录时间防止时间回调,8小时,如8小时内有操作顺延8小时
		let timeLong = 86400000 / 24 * 8 //超时时间
		let user = null
		let isStorage = false
		if (store.state.user) {
			user = store.state.user
		} else {
			let us = localStorage.getItem("$$user")
			if (us && us.length > 1) {				
				user = JSON.parse(us)
				isStorage = true
			}
		}
		
		if (user) {

			if ( nt >= user.loginTime && nt < user.endTime) { //true ||永不过期
				let dis = user.endTime - nt
				user.endTime = nt + timeLong

				if (dis>3600 * 1000) {//间隔超过一小时保存一下 //isStorage本地数据加载的重新加到内存中
					store.commit("setUser", user)
					//this.$logic.saveUser(user)
				}
				/* if (isStorage || (nt - user.loginTime > 86400000 / 24 * 1)) { //超过1个小时，数据中存一次对象
					user.loginTime = nt
					localStorage.setItem('$$user', JSON.stringify(user))
				} */
				let check = true //检验权限
				if (to.meta && user.userType != 3) { //根管理员有所有权限
					if (to.meta.adminCapacity) {
						check = (user.adminCapacity && user.adminCapacity[to.meta.adminCapacity])
					} else if (to.meta.devCapacity) {
						check = (user.devCapacity) // && user.devCapacity[to.meta.devCapacity]
					}
				}
				if (to.name == "Application") { //系统系统加载的场景直接过
					next()
					return
				}
				if (!check) {
					next({
						name: 'SysInfo'
					})
				} else {
					next()
				}


			} else {
				//console.log(user.loginTime,user.endTime,nt)
				//alert("登录超时，请重新登录")
				next("/login?to=" + getToNext()) //encodeURIComponent(window.location.href)
			}
		} else {
			next("/login?to=" + getToNext()) //encodeURIComponent(window.location.href)
		}

	}
})

export default router