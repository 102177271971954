import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'

let cs=[
{key:'title',text:'单元格属性'},
{key:'tdM',text:'单元格模式',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list: options.toOptions('常规=,可编辑=edit')},
{key:'tdT',text:'文本',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdW',text:'列宽',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdP',text:'内间距',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdH',text:'横向对齐',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
{key:'tdV',text:'纵向对齐',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
{key:'tdBC',text:'背景颜色',type:'colorA',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
]

let td= {
	data:{
		type:'td',width:'100%',height:''
	},
	delete:'alignV,minW,minH,overH,overV,paddingT,paddingB,paddingL,paddingR,',
	hide:'',
	base:cs.concat(font) ,
	special :[].concat(special),
	action:[]
}

export default td