import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
import echartConfig from '../../echartConfig.js'
let initData=JSON.stringify( [
 	['数值名称','当前值', '最大值', '最小值'],
 	['速度',65, 100, 0],

 ]).replaceAll('],','],\r\n')
let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
let base = [].concat( echartConfig.base)
let polar=[
	{key:'polarCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'polarCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
//	{key:'polarRadiusI',text:'雷达内半径',type:'text',init:'0%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	{key:'polarRadiusO',text:'仪表盘半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},

]
options.insertBaseConfig(base,polar)
 
let special = [
	 {key:'title',text:'仪表盘系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'gauge',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
//	 {key:'seriesPieColorBy',text:'取色方式',type:'select',init:'data',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesGaugeStartAngle',text:'起始角',type:'number',init:225,path:'_series.startAngle',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesGaugeEndAngle',text:'终止角',type:'number',init:-45,path:'_series.endAngle',bursh:true,bind:true,tip:'',list:null},
     {key:'seriesGaugeClockwise',text:'顺时针方向',type:'switch',init:'Y',path:'_series.clockwise',bursh:true,bind:true,tip:'',list:options.YON},
 {key:'gaugePointerLength',text:'指针长度',type:'text',init:'60%',path:'_series.pointer.length',bursh:true,bind:true,tip:'象数值',list:null},
 {key:'gaugePointerWidth',text:'指针宽度',type:'number',init:6,path:'_series.pointer.width',bursh:true,bind:true,tip:'象数值',list:null},
 {key:'gaugePointerColor',text:'指针颜色',type:'colorA',init:null,path:'_series.pointer.itemStyle.color',bursh:true,bind:true,tip:'',list:null},
 
	  {key:'seriesGaugeMax',text:'最大值',type:'number',init:null,path:'_series.max',bursh:true,bind:true,tip:'如果数据源中含有最大值，以数据中的为准',list:null},
 	 {key:'seriesGaugeMin',text:'最小值',type:'number',init:null,path:'_series.min',bursh:true,bind:true,tip:'如果数据源中含有最小值，以数据中的为准',list:null},

    {key:'seriesGaugeTitleShow',text:'显示标题',type:'switch',init:'Y',path:'_series.title.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesGaugeTitleFontSize',text:'标题字号',type:'number',init:16,path:'_series.title.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesGaugeTitleFontFamily',text:'标题字体',type:'select',init:'',path:'_series.title.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'seriesGaugeTitleColor',text:'标题颜色',type:'color',init:options.initColor.text.dark,path:'_series.title.color',bursh:true,bind:true,tip:'',list:null},
	// {key:'seriesGaugeTitleCenter',text:'标题位置',type:'number',init:15,path:'_series.offsetCenter',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesGaugeTitleCenterX',text:'中心位置X',type:'text',init:'0',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'seriesGaugeTitleCenterY',text:'中心位置Y',type:'text',init:'40%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
    {key:'seriesGaugeDetailShow',text:'显示数值',type:'switch',init:'Y',path:'_series.detail.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesGaugeDetailFontSize',text:'数值字号',type:'number',init:25,path:'_series.detail.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesGaugeDetailFontFamily',text:'数值字体',type:'select',init:'',path:'_series.detail.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'seriesGaugeDetailColor',text:'数值颜色',type:'color',init:options.initColor.text.dark,path:'_series.detail.color',bursh:true,bind:true,tip:'',list:null},
	// {key:'seriesGaugeDetailCenter',text:'数值位置',type:'number',init:15,path:'_series.offsetCenter',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesGaugeDetailCenterX',text:'位置偏移X',type:'text',init:'0',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	{key:'seriesGaugeDetailCenterY',text:'位置偏移Y',type:'text',init:'70%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},



{key:'gaugeAxisLineShow',text:'显示轴线',type:'switch',init:'Y',path:'_series.axisLine.show',bursh:true,bind:true,tip:'',list:options.YON},
   	{key:'gaugeAxisLineColor',text:'轴线颜色',type:'colorS',init:[[1,options.initColor.line.dark]],path:'_series.axisLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
 	{key:'gaugeAxisLineWidth',text:'轴线粗细',type:'number',init:5,path:'_series.axisLine.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
 	{key:'gaugeAxisLineShadowColor',text:'阴影颜色',type:'colorA',init:options.initColor.line.dark,path:'_series.axisLine.lineStyle.shadowColor',bursh:true,bind:true,tip:'',list:null},
 	{key:'gaugeAxisLineShadowBlur',text:'阴影大小',type:'number',init:0,path:'_series.axisLine.lineStyle.shadowBlur',bursh:true,bind:true,tip:'象数值',list:null},


  {key:'gaugeSplitNumber',text:'分割数量',type:'number',init:5,path:'_series.splitNumber',bursh:true,bind:true,tip:'象数值',list:null},
  {key:'gaugeSplitLineShow',text:'显示分隔线',type:'switch',init:'Y',path:'_series.splitLine.show',bursh:true,bind:true,tip:'',list:options.YON},
  {key:'gaugeSplitLineLength',text:'刻度长度',type:'number',init:8,path:'_series.splitLine.length',bursh:true,bind:true,tip:'象数值',list:null},
  {key:'gaugeSplitLineWidth',text:'分隔线粗细',type:'number',init:2,path:'_series.splitLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
  {key:'gaugeSplitLineColor',text:'分隔线颜色',type:'color',init:options.initColor.line.dark,path:'_series.splitLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
  {key:'gaugeSplitLineDistance',text:'偏移距离',type:'number',init:0,path:'_series.splitLine.distance',bursh:true,bind:true,tip:'',list:null},
 
 	 {key:'gaugeAxisTickShow',text:'显示刻度',type:'switch',init:'Y',path:'_series.axisTick.show',bursh:true,bind:true,tip:'',list:options.YON},
 	{key:'gaugeAxisTickLength',text:'刻度长度',type:'number',init:5,path:'_series.axisTick.length',bursh:true,bind:true,tip:'象数值',list:null},
 	{key:'gaugeAxisTickWidth',text:'刻度粗细',type:'number',init:1,path:'_series.axisTick.lineStyle.width',bursh:true,bind:true,tip:'象数值',list:null},
 	{key:'gaugeAxisTickColor',text:'刻度颜色',type:'colorA',init:options.initColor.line.dark,path:'_series.axisTick.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
 {key:'gaugeAxisTickDistance',text:'偏移距离',type:'number',init:0,path:'_series.axisTick.distance',bursh:true,bind:true,tip:'',list:null},
  
 
 	 {key:'gaugeAxisTickLabelShow',text:'刻度标签',type:'switch',init:'N',path:'_series.axisLabel.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'gaugeAxisTickLabelColor',text:'刻度标签色',type:'color',init:options.initColor.text.dark,path:'_series.axisLabel.color',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'gaugeAxisTickLabelRotate',text:'刻度标签角',type:'number',init:0,path:'_series.axisLabel.rotate',bursh:true,bind:true,tip:'',list:null},
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 

]//.concat(polarConfig.special)
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_gauge',polarAxisLineShow:'N',legendShow:'N',color:options.chartColor.gaugePoint,
		chartClan: 'polar', initData:initData,gaugeAxisLineColor:options.chartColor.gaugeLine
	},
	delete: '',
	hide: 'width,height,seriesType,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
}
export default cfg
