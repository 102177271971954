<template>

	<el-tabs v-model="tabValue" type="border-card" class="tabs-body" @tab-change="tabChange">
		<el-tab-pane name="info">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-project-diagram tab-icon"></i>
					<span>项目信息</span>
				</span>
			</template>
			<container v-if="model.items" :model="model.items[0]" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" />
		</el-tab-pane>

		<el-tab-pane name="gantt">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-sliders-h tab-icon"></i>
					<span>项目任务</span>
				</span>
			</template>
			<gantt v-if="model.items" :model="model.items[1]" :project="project" :page="page" :host="host"
				:isedit="isedit" :datas="datas" :tick="ganttTick" @click.stop="objClick(model.items[1])" />
			<!-- <base-element  v-if="model.items" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" />	 -->
		</el-tab-pane>
		<el-tab-pane name="flow">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-chart-network tab-icon"></i>
					<span>作业流程</span>
				</span>
			</template>
			<task-flow :node-list="nodeList" />
		</el-tab-pane>
		<el-tab-pane name="time">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-clock tab-icon"></i>
					<span>时间线</span>
				</span>
			</template>
			信息
		</el-tab-pane>
		<el-tab-pane name="doc">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-books tab-icon"></i>
					<span>项目档案</span>
				</span>
			</template>
			信息
		</el-tab-pane>
		<el-tab-pane name="team">
			<template #label>
				<span class="custom-tabs-label">
					<i class="far fa-user-friends tab-icon"></i>
					<span>项目团队</span>
				</span>
			</template>
			信息
		</el-tab-pane>
		<el-tab-pane name="form">
			<template #label>
				<span class="custom-tabs-label">
					<i class="fab fa-wpforms tab-icon"></i>
					<span>业务定制</span>
				</span>
			</template>
			信息
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	import Gantt from './Gantt.vue'
	import TaskFlow from './TaskFlow.vue'

	import initData from '../../../config/form/custom/ganttInitData.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				ganttTick: 0,
				tabValue: 'info',
				nodeList:[]
			}
		},

		methods: {
			objClick(el) {
				this.$store.commit('setElement', el)
			},
			tabChange(value) {
				if (value === 'gantt') {
					this.ganttTick = new Date().getTime()
				}
			},
			projectInit() {
				let model = this.model
				if (!model.items) {
					let items = []
					let config = this.elementConfig
					let info = config.create('row', this.model, this.page.formData)
					info.height = '100%'
					items.push(info)
					let gantt = config.create('gantt', this.model, this.page.formData)
					gantt.height = "calc(100% - 2px)"
					gantt.width = "calc(100% - 2px)"
					items.push(gantt)

					for (let item of items) {
						item.$hold = model.id
					}
					model.items = items


				} else {

				}

			}

		},
		components: {
			gantt: Gantt,
			taskFlow: TaskFlow,

		},
		created() {
			this.projectInit()
			if (this.isedit) {
				this.nodeList = this.$logic.clone.deepClone(initData)

			}

		}
	}
</script>

<style scoped>
	.tabs-body {
		height: calc(100% - 2px) !important;
	}

	.tab-icon {
		margin-right: 5px;
		font-size: 18px;
	}

	:deep(.el-tabs__item) {
		padding: 0px 20px !important;
	}
</style>