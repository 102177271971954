<template>
	<div v-show="model.show !='none'" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
		@mouseup="parentMouseUp" :style="formStyle" :class="layoutcss">
		<el-tabs v-model="model.tabValue" :addable="model.addable=='Y'" class="tabpage" @tab-add="tabAdd"
			@tab-click="tabClick">
			<el-tab-pane v-for="(tab,index) in model.items" :key="tab.key" :name="tab.key">
				<template #label>
					<div :class="'tabheaditem '+(model.tabType=='card'? tab.key==model.tabValue ? ' tabs-card tabs-card-sel ':' tabs-card ':tab.key==model.tabValue?' tabs-label tabs-label-sel ':' tabs-label ') "
						:style="{marginLeft:index>0?'0px':model.firstPadding}">

						<el-badge v-if="tab.allowBadge=='Y'" :type="tab.colorType" :is-dot="tab.badgeType=='dot'"
							:value="tab.badgeType=='num'?parseInt(tab.badgeValue): tab.badgeValue"
							:max="parseInt(tab.maxValue)"
							:style="{'--badge-top-offset':tab.topOff,'--badge-right-offset':tab.rightOff}">
							<span>
								<i v-if="model.showIcon=='Y'" :class="'tabpageicon '+tab.icon" />
								<span>{{tab.label}}</span>
								<i v-if="model.closable=='Y'" @click.stop="tabClose(tab,index)"
									:class="'tabclose far fa-times'" />
							</span>
						</el-badge>
						<span v-else>
							<i v-if="model.showIcon=='Y'" :class="'tabpageicon '+tab.icon" />
							<span>{{tab.label}}</span>
							<i v-if="model.closable=='Y'" @click.stop="tabClose(tab,index)"
								:class="'tabclose far fa-times'" />
						</span>

					</div>

				</template>
				<div class="tab_body" @mouseup="tabMouseUp($event,tab)">

					<template v-if="tab.url">
						<template v-if="isPage(tab)">
							<container v-if="tab.url.length>1 && tab.subPage " :model="tab.subPage" :project="project"
								:page="page" :host="host" :isedit="isedit" :datas="bindData" />
						</template>
						<template v-else>
							外链
						</template>

					</template>
					<template v-else>

						<template v-for="item in tab.items">
							<container v-if="item.group=='layout'" :model="item" :project="project" :page="page"
								:host="host" :isedit="isedit" :datas="bindData" />
							<base-element v-else :model="item" :project="project" :page="page" :host="host"
								:isedit="isedit" :datas="bindData" />


						</template>
					</template>
				</div>

			</el-tab-pane>

		</el-tabs>
	</div>

</template>

<script>
	//	import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
				tabIdx: 0,
				tabPageItems: [],
			}
		},

		methods: {
			tabClick(tab, evt) { //pane: TabsPaneContext, ev: Event 
				let item = this.model.items[tab.index]
				if (this.isedit) {
					let _this = this
					setTimeout(() => {
						_this.setCurrentElement(item)
					}, 300)
				}

			},

			setTabSubPage(tab, reload) {

				if (tab.url && tab.url.length > 1 && tab.url.indexOf('/') < 0) { //内部页面
					let url = tab.url
					let subPage = tab.subPage
					if (subPage) {
						subPage.url = url
					} else {
						subPage = this.getConfig().create('subpage', null, this.page.formData)
						subPage.parent = tab
						subPage.url = url
						tab.subPage = subPage

					}
					if (reload) {
						/* if (url.indexOf('?') > -1) {
							subPage.url = url + '&$timestamp=' + new Date().getTime()
						} else {
							subPage.url = url + '?&$timestamp=' + new Date().getTime()
						} */
						if (subPage.$fresh) { //手工执行一次刷新 
							subPage.$fresh()
						}

					}


				}
			},
			initTabPageItems() {
				let model = this.model

				if (model.items && model.items.length > 0) {

				} else { //根据默认参数生成子页
					let config = this.getConfig()
					let list = this.jsonDataList
					let items = []
					for (let data of list) {
						this.tabIdx++
						let item = config.create('tabitem', model, this.page.formData)
						for (let key in data) {
							item[key] = data[key]
						}
						item.key = 'tab' + this.tabIdx

						items.push(item)
					}
					model.items = items
				}
				for (let item of model.items) { //设置子页面容器 
					this.setTabSubPage(item)
				}

				this.tabPageItems = model.items

			},
			fresh() {
				let tab = null
				for (let item of this.model.items) {
					if (item.key == this.model.tabValue) {
						tab = item
						break
					}
				}
				if (tab && tab.url) {
					this.setTabSubPage(tab, true)
				}
			},
			tabClose(tab, index) {
				let items = this.model.items
				if (items.length < 2) {
					this.$logic.tip.error('至少需保留一个标签页')
					return
				}
				if (typeof(tab) == 'string') {
					let key = tab
					tab = {
						key: key
					}
				}
				let idx = index
				if (typeof(index) === 'undefined') {
					idx = -1
					for (let i = 0; i < items.length; i++) {
						let item = items[i]
						if (item.key == tab.key) {
							idx = i
							break
						}
					}
				}
				if (idx > -1) {
					items.splice(idx, 1)
					if (this.model.tabValue == tab.key) {
						this.model.tabValue = items[0].key

					}
				} else {
					this.$logic.tip.error('关闭的标签项不存在')
				}


			},
			tabAdd() {
				let tab = this.getConfig().create('tabitem', this.model, this.page.formData)
				let key = null
				let tag = true
				while (tag) { //查找是否有重名的
					tag = false
					this.tabIdx++
					key = 'tab' + this.tabIdx
					for (let item of this.tabPageItems) {
						if (item.key == key) {
							tag = true
							break
						}
					}
				}

				tab.key = key

			},
			tabMouseUp(e, tab) {
				if (this.isedit) {
					if (this.newComp.show) { //创建或拖动组件
						if (tab.url && tab.url.length > 0) {
							this.newComp.show = false
							this.$logic.tip.error('不允许在已设置了链接地址属性的标签页中创建页面元素')
						} else {
							this.mouseUp(e, tab)

						}

					}

				}
			},
			parentMouseUp() { //主子关系的容器中不允许在主元素中创建元素
				if (this.isedit) {
					if (this.newComp.show) { //创建或拖动组件
						this.newComp.show = false
						this.$logic.tip.error('请在子容器中创建元素')
					}
					this.tdStart = null //只要是鼠标弹起即取消框选状态
				}

			},
			isPage(tab) {
				if (tab.url.indexOf('/') > -1) { //链接
					return false
				} else {
					return true
				}
			},
		},
		computed: {

			formStyle() {
				let model = this.model
				let css = this.layoutstyle
				css['--min-tab-height'] = 'calc(' + model.minH + ' - 40px - ' + model.tabPaddingT + ')'
				css['--tab-body-height'] = 'calc(100% - ' + model.borderWidth + ' - ' + model.tabPaddingT +
					' - 1px )' //多减1象素，防止panel层多出滚动条
				css['--tab-body-width'] = 'calc(100%  - ' + model.borderWidth + ' - ' + model.borderWidth + ' - ' +
					model.tabPaddingLR + ' - ' + model.tabPaddingLR + ' - 1px)'
				css['--tab-page-radius'] = '0px 0px ' + model.pageRadius + ' ' + model.pageRadius
				if (model.showBorder != 'Y') {
					css['--tab-border-width'] = '0px'

				}
				if (model.linear == 'Y') {
					let bgColor = model.selBackColor
					let color = this.$logic.color.getLightColor(bgColor, 0.5)
					css['--tag-card-sel-back-l'] = 'linear-gradient(0deg,' + color + ',' + model.selBackColor + ',' +
						color + ')'
					color = this.$logic.color.getLightColor(model.cardBackColor, 0.5)
					css['--tab-card-back-color-l'] = 'linear-gradient(0deg,' + color + ',' + model.cardBackColor +
						',' + color + ')'

				} else {
					css['--tag-card-sel-back-l'] = 'none'
					css['--tab-card-back-color-l'] = 'none'

				}
				return css
			},

			/* 
			采用页面选择时触发事件处理
						'model.items': function(newV, oldV) {
							let model = this.model
							//设计模式下检测TabUrl是否发生过变化,设计时响应url的输入变化
							if (newV) {
								let tab = null
								for (let item of newV) {
									if (item.key == model.tabValue) {
										tab = item
										break
									}
								}
								if (tab && tab.url) {
									this.setTabSubPage(tab)
								}
							}
							 
						} */
		},
		watch: {
			'model.tabValue': function(newV, oldV) {
				let tab = null
				for (let item of this.model.items) {
					if (item.key == newV) {
						tab = item
						break
					}
				}
				if (tab && tab.url) {
					this.setTabSubPage(tab)
				}
				this.linkUpdate('tabValue')

			}
		},
		created() {
			this.createInit()
			if (!this.isedit) {
				this.model.$close = this.tabClose
				this.model.$fresh = this.fresh
			}
			this.initTabPageItems()

		}
	}
</script>

<style scoped>
	/* TAB组件的内部样式在当前组件内不生效,在PAGE组件中定义,先消除内部原有样式 */
	.tabpage {
		width: 100%;
		height: 100%;
	}



	.tabpageicon {
		margin-left: 0px;
		margin-right: 5px;
	}

	.tabclose {
		margin-left: 5px;
		cursor: pointer;

	}

	.tabclose:hover {
		color: #FF0000;
	}

	.tab_body {
		min-height: var(--min-tab-height);
		height: var(--tab-body-height);
		width: var(--tab-body-width);
		padding-top: var(--tab-padding-top);
		padding-left: var(--tab-padding-lr);
		padding-right: var(--tab-padding-lr);
		overflow: auto;
		display: flex;
		align-content: flex-start;
		flex-wrap: wrap;
		border-style: solid;
		border-width: var(--tab-border-width);
		border-color: var(--tab-border-color);
		border-radius: var(--tab-page-radius);

		border-top: 0px;


	}

	.tabheaditem {

		margin-right: var(--tab-label-margin);
		padding-left: var(--tab-label-padding);
		padding-right: var(--tab-label-padding);
		color: var(--tab-label-label-color);
		height: 38px;
		line-height: 38px;

	}

	.tabs-label {
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-bottom-color: rgba(255, 255, 255, 0);


	}

	.tabs-label-sel {
		border-bottom-color: var(--tab-sel-line-color);
		color: var(--tab-sel-label-color);
	}


	.tabs-card {
		border: solid 1px #cccccc;
		border-radius: 10px 10px 0px 0px;
		background-color: var(--tab-card-back-color);
		background-image: var(--tab-card-back-color-l);
		height: 37px;
		line-height: 37px;
	}

	.tabs-card-sel {
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-bottom-color: var(--tab-sel-line-color);
		color: var(--tab-sel-label-color);
		background-color: var(--tab-sel-back-color);
		background-image: var(--tag-card-sel-back-l);
		/* background-image: linear-gradient(0deg, blue, green 40%, red); */

	}




	/* 以下是为tabpage组件定义的内部样式,在Block这种递归容器里不能生效 */
	:deep(.el-tabs__header) {
		margin-bottom: 1px;
	}

	:deep(.el-tabs__content) {

		height: calc(100% - 40px);
		padding: 0px 0px 0px 0px;

	}

	:deep(.el-tab-pane) {
		height: 100%;
		overflow-y: auto;
		overflow-x: auto;

	}

	/* 去除组件自带的间距，在BLOC中控制间距 */
	:deep(.el-tabs__item) {
		padding: 0px 0px;
		/* line-height:35px; */
	}

	:deep(.el-tabs__new-tab) {
		color: var(--el-color-primary);
		background-color: #f4f4f5;
		font-size: 16px;
	}

	:deep(.el-tabs__active-bar) {
		background-color: rgba(255, 255, 255, 0);
	}

	:deep(.el-badge__content) {
		top: var(--badge-top-offset);
		right: calc(var(--badge-right-offset) + var(--el-badge-size)/ 2);
	}
</style>