import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'


let base = [
	{key: 'title',text: '企微扫码'	},
    {key: 'apiKey',text: '应用KEY',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 //   {key: 'title',text: '扫一扫'	},   
    {key: 'scanType',text: '扫码类型',type: 'select',init: 'qrCode',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('二维码=qrCode,条形码=barCode')},
    {key: 'scanTo',text: '扫码回填',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 
 
	]
	
let actions=[
	{key: '$comp_scanFinish',text: '扫码完成',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '从返回的code值判断成功或失败',list: null	},
	//{key: '$comp_scanError',text: '扫码失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
	
	
]
	
let qiYeWeiXin = {
	data: {
		type: 'qywx',
	},
	delete: '',
	hide: '',
	base: base,//.concat(compBase.base)
	special: [],
	action:  options.getCommonEvents().concat(actions).concat(options.getEvents(null,'$hook'))
}

export default qiYeWeiXin