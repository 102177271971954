<template>

	<div @click="mouseClick" v-show="model.show !='none'" @mouseover="mouseOver" @mouseleave="mouseLeave"
		@mouseup="mouseUp" :style="layoutstyle " :class="layoutcss">

		<template v-if="(model.url &&  model.url.indexOf('://')<0 ||  contentLoad)  && subModel.formData">
			<container :model="subModel.formData" :project="project" :page="subModel"
				:host="{element:model,parentPage:page}" :isedit="isedit" :datas="bindData" />
		</template>
		<div v-if="isedit" class="pageface">
			<i class="fas fa-external-link"></i>
		</div>
		<runing :show="subPageLoading" :label-size="30" :icon-size="60"></runing>
	</div>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'


	export default {
		mixins: [layoutBase],
		data() {
			return {
				contentLoad: false,
				bindData: null,
				subModel: {
					formData: null
				},
				subPageLoading: false,
			}
		},

		methods: {

			fresh() {
				this.loadPage()
			},
			loadPage() {
				let srcUrl = this.model.url ? this.model.url.trim() : null
				if (!srcUrl) { // 没有设置url不作处理
					return
				}

				let x = srcUrl.indexOf('#') //内部地址加载内容，外部链接直接 页面区处理
				if (x == 0) {
					/* this.$logic.tip.error('系统内部页面地址格式：#key?param1=1&param2=2')
					return */
					srcUrl = srcUrl.substring(1) //#开头
				}
				this.subPageLoading = true
				let url = srcUrl
				x = url.indexOf('?')
				let formKey = x > 0 ? url.substring(0, x) : url

				let pageParams = this.$logic.http.getRequestParam(null, url)

				let params = {
					appId: this.page.appId,
					appVersion: this.page.appVersion,
					formKey: formKey
				}

				this.$logic.http.pageLoad(this.project, this.getConfig(), null, params, res => {
					this.subPageLoading = false
					this.subModel = {} //刷新页面
					setTimeout(() => { //放在不同进程中处理，避免缓存不刷新页面
		
						res.$params = pageParams //子页面地址中的参数替换浏览器网址中的参数
						res.formData._util_page.$params = pageParams //为编程用的page对象设置参数
						if (!this.isedit) { //运行时设置，防止引用类型属性不能保存
							res.formData.$hostElement = this
								.model //设置子页面容器给子页面对象，可以this.$host()获取容器
							setTimeout(() => {
								this.doEvent({
									eventName: 'loadAfter',
									page: this.subModel.$,
								}, '$comp_loadAfter')
							}, 50)
						}
						this.subModel = res
					}, 50)

				}, err => {
					this.subPageLoading = false
					this.subModel = {}
					this.$logic.tip.error('加载失败 ' + err.info)
				}, this.isedit)
			},
			loadContent(content, params = {}) { //装载局部页面模型内容,params与网址中的参数叠加
				//每次调用函数重新加载一次页面，清空页面内容


				let subModel = {
					formData: content
				}

				if (!content) {
					this.contentLoad = false
					return
				}

				let url = window.location.href
				let urlParams = this.$logic.http.getRequestParam(null, url)
				if (params) {
					for (let key in params) {
						urlParams[key] = params[key]
					}
				}
				this.$logic.http.parsePage(this.getConfig(), subModel, !this.isedit, this.project)
				subModel.$params = urlParams
				if (!this.isedit) {
					subModel.formData.$hostElement = this.model
				}
				subModel.formData._util_page.$params = subModel.$params //为编程用的page对象设置参数
				if (this.subModel.formData) { //如果上次有内容，异步设值，使页面内容刷新
					this.subModel = {
						formData: null
					}
					setTimeout(() => {
						this.subModel = subModel
						setTimeout(() => {
							this.doEvent({
								eventName: 'loadAfter',
								page: this.subModel.$,
							}, '$comp_loadAfter')
						}, 50)
					}, 10)
				} else {
					this.subModel = subModel
				}


				this.contentLoad = true
			},
			getSubPage() {
				return this.subModel ? this.subModel.formData._util_page : null
			}


		},
		watch: {
			'model.url': function(newV, oldV) {
				if (newV && newV.length > 0) {
					this.loadPage()
				} else {
					this.$logic.tip.error('无效的页面地址')
				}
			},

		},

		created() {
			this.createInit()
			if (!this.isedit) {
				this.model.$fresh = this.fresh
				this.model.$getPage = this.getSubPage
			}
			this.model._loadContent = this.loadContent //系统内部作调用，动态装配局部页面内容
			this.loadPage()
		}
	}
</script>

<style scoped>
	.pageface {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		color: #cccccc;
		/* box-shadow: 0px 0px 5px 0px #cccccc inset ; */
		/* border: 1px #cccccc dotted; */
		background-color: rgba(193, 193, 193, 0.01);

	}

	.pageface:hover {
		background-color: rgba(245, 245, 207, 0.5);
	}
</style>