<template>
	<orgdialog :org-node="roleNode" :tree-nodes="treeNodes" :placeholder="placeholder" @select="nodeSelect" :node-sql="roleSql"
		icon="far fa-user-tag" dialog-title="角色选择" />


</template>

<script>
	import options from '../../form/config/public/options.js'
	import Orgdialog from './OrgDialog.vue'
	export default {
		props: {
			treeNodes: {
				type: Array,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			},
			dialogWidth: {
				type: String,
				default: '800px'
			},
			dialogHeight: {
				type: String,
				default: '80vh'
			},
			placeholder: {
				type: String,
				default: '请选择角色'
			},
			roleNode: {
				type: Object,
				default: {
					text: '',
					textPath: '',
					id: '',
					idPath: ''
				}
			}
		},
		emits: ['select'],
		data() {
			return {

			}
		},

		methods: {
			nodeSelect(node) {
				this.$emit('select', node)
			}
		},
		computed: {
			roleSql() {
				return options.roleSql
			}
		},
		components: {
			orgdialog: Orgdialog
		},
		created() {


		}
	}
</script>

<style scoped>

</style>