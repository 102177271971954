<template>
	<div v-show="model.show !='none'" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
		:style="layoutstyle" :class="layoutcss">
		<span v-if="model.labelStar=='Y'" class="labelstar">*</span>


		<i v-if="model.iconBefore" :class="model.iconBefore"
			:style="{color:model.iconColor,marginRight:model.iconGap,fontSize:model.iconSize}"></i>
		<span>{{model.labelText}}</span>
		<i v-if="model.iconAfter" :class="model.iconAfter"
			:style="{color:model.iconColor,marginLeft:model.iconGap,fontSize:model.iconSize}"></i>


	</div>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'

	export default {
		mixins: [layoutBase],
		data() {
			return {
				bindData: null,
			}
		},

		methods: {

		},
		created() {
			this.createInit()

		}
	}
</script>

<style scoped>
	.labelstar {
		font-size: 16px;
		color: #ff0000;
	}
</style>