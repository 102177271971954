import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
let listTypes=[{label:'列表信息',value:'text'},{label:'图片',value:'picture'},{label:'图片卡片',value:'picture-card'}]
let models=[{label:'区域拖拽',value:'area'},{label:'按钮点击',value:'button'}]
let datas =JSON.stringify( 
{
	id:0,
	tag:'${1}',
	name:'abc${2}123'
}
)
let idConfig=compBase.getDataConfig()
let pidConfig=compBase.getDataConfig()
let nameConfig=compBase.getDataConfig()
let sizeConfig=compBase.getDataConfig()
let pathConfig=compBase.getDataConfig()
let timeConfig=compBase.getDataConfig()
let userConfig=compBase.getDataConfig()
let groupConfig=compBase.getDataConfig()
let base = [
	{key: 'title',text: '文件上传'	},
	
//	{key: 'action',text: '上传地址',type: 'text',init: '',	target: '',	extend: false,tip: '',list: null},
//	{key: 'action',text: '存储根目录',type: 'text',init: 'file',	target: '',	extend: false,tip: '',list: null},

	{key: 'mode',text: '上传方式',type: 'select',init: 'button',	target: '',	 bursh: true,bind: false,tip: '',list: models},	
	{key: 'allowUpoad',text: '允许上传',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'allowDelete',text: '允许删除',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'fileList',text: '显示列表',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    {key:'idParam',text:'表单ID参数',type:'text',init:'formId',target:null,bursh:true,bind:true,tip:'如果是表单下的子表数据，需指定表单ID值的参数名（区分大小写），默认参数名为formId',list:null},
    {key:'groupName',text:'文件组命名',type:'text',init:'',target:null,bursh:true,bind:true,tip:'同一页面可能存在多个文件上传组件，用于区分属于哪一组文件数据的标识，默认为空不分组',list:null},
	{key: 'dataTo',text: '组件传值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '文件列表数据给其它组件传值，例如向另一个表格传值，数据加载完成后触发，多组件ID请用逗号分隔，格式：#1,#2,#3',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key:'dbTable',text:'数据库表名',type:'table',init:'',target:'',bursh:true,bind:false,tip:'指定文件信息保存的数据库表',list: null},
	{key: 'idField',text: 'Id字段',type: 'field',init: idConfig,	target: '',	bursh: true,bind: false,tip: '属性：id，指定文件信息表的ID字段(自增长型)，必须设置',attribute:'id',host:'dbTable'},
	{key: 'pidField',text: '关联Id字段',type: 'field',init: pidConfig,	target: '',	bursh: true,bind: false,tip: '属性：pid，一般用于保存表单ID值，指定用于保存文件关联的父表ID的字段，必须设置',attribute:'pid',host:'dbTable'},
	{key: 'fileNameField',text: '文件名字段',type: 'field',init: nameConfig,	target: '',	bursh: true,bind: false,tip: '属性：fileName，指定用于保存文件名的数据表字段，必须设置',attribute:'fileName',host:'dbTable'},
	{key: 'localPathField',text: '路径字段',type: 'field',init: pathConfig,	target: '',	bursh: true,bind: false,tip: '属性：localPath，指定用于保存文件所在路径的数据表字段，必须设置',attribute:'localPath',host:'dbTable'},
	{key: 'fileSizeField',text: '大小字段',type: 'field',init: sizeConfig,	target: '',	bursh: true,bind: false,tip: '属性：fileSize，指定用于保存文件大小的数据表字段（建议长整型字段），必须设置',attribute:'fileSize',host:'dbTable'},
	{key: 'createTimeField',text: '时间字段',type: 'field',init: timeConfig,	target: '',	bursh: true,bind: false,tip: '属性：createTime，指定用于保存文件上传时间的数据表字段，必须设置',attribute:'createTime',host:'dbTable'},
	{key: 'creatorIdField',text: '上传人字段',type: 'field',init: userConfig,	target: '',	bursh: true,bind: false,tip: '属性：creatorId，指定用于保存上传人ID的数据表字段，必须设置',attribute:'creatorId',host:'dbTable'},
    {key: 'groupNameField',text: '文件组字段',type: 'field',init: groupConfig,	target: '',	bursh: true,bind: false,tip: '属性：groupName，指定文件列表组名的字段(文本型)，必须设置',attribute:'groupName',host:'dbTable'},
		
	{key: 'size',text: '按钮大小',type: 'select',init: 'default',target: '',	bursh: true,bind: false,tip: '',list: compBase.sizes},
	
	{key: 'auto',text: '自动上传',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'multiple',text: '允许批量',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'limit',text: '批量文件数',type: 'number',init: '1',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'drag',text: '启用拖拽',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'accept',text: '允许的类型',type: 'text',init: 'jpg,jpeg,png,gif,rar,zip,doc,docx,xls,xlsx,ppt,pptx,pdf',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	{key: 'len',text: '最大(M)',type: 'number',init: '100',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	

	{key: 'tipS',text: '显示提示',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
//	{key: 'whoedit',text: '修改权限',type: 'select',init: 'Y',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	

//	{key: 'param',text: '上传参数',type: 'area',init: datas,	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'fileN',text: '文件参数名',type: 'text',init: 'file',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'width',text: '元素宽度',type: 'text',init: '100%',	target: 'width',	bursh: true,bind: false,tip: '',list: null},


	//{key: 'showL',text: '已上传列表',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	
	//{key: 'listT',text: '列表类型',type: 'select',init: 'text',	target: '',	bursh: true,bind: false,tip: '',list: listTypes},	
 //	{key: 'thumb',text: '显示缩略图',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},	

  //  compBase.status, 	


]

let upload = {
	data: {
		type: 'upload',
	},
	delete: '',
	hide: 'auto,drag,fileN,multiple,limit,width',
	base: base.concat(compBase.base),
	special: [	],//{key: 'jsonData',text: 'JSON数据',type: 'json',init: datas,	target: '',	extend: false,tip: '',list: null},
	action: [
		{key:'title',text:'组件业务事件'},
		{key: '$comp_loadAfter',text: '文件加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '对话框弹出前',list: null	},
		
	].concat(options.getEvents(null,'$hook'))
}

export default upload
