import pie from './chartpie.js'
import clone from '../../../../../../../../plugins/deepClone.js'
import options from '../../../../../public/options.js'
let pieM =clone.deepClone(pie)
pieM.data.type='chart_pieM'
pieM.data.polarRadiusI='20%'
pieM.data.seriesPieRadiusS='5%'
pieM.data.seriesPieRoseType='area'
pieM.data.color=options.chartColor.bar5
pieM.hide=pieM.hide.replace(',seriesPieRadiusI','')
pieM.hide=pieM.hide.replace(',seriesPieRadiusS','')
export default pieM