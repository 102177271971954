import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
import Clone from '../../../../../plugins/deepClone.js'
let datas =JSON.stringify( [
	  {label:'5℃',value:5,color:'#00aaff'},
	  {label:'20℃',value:20,color:'#00aa7f'},
	  {label:'40℃',value:40,color:'#ffaa00'},
	  {label:'80℃',value:80,color:'#aa0000'},
	
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'刻度值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'刻度文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'color',label:'刻度颜色',type:'color',default:'#00aaff',list:[],field:'',width:'',unique:false},
 
]
let dataConfig=compBase.getDataConfig()
let dataConfig2=Clone.deepClone(dataConfig)
let validate=compBase.getValidate('int') 
validate.validateSet.exclude='input,blur'
let rule1=validate.validateRule
let rule2=Clone.deepClone(validate.validateRule)  

let base = [
	{key: 'title',text: '水平滑块'	},
	
	{key: 'valueS',text: '起始值',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'valueE',text: '终止值',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldNameS',text: '起始值字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'valueS'},
	{key: 'fieldNameE',text: '终止值字段',type: 'field',init: dataConfig2,	target: '',	bursh: true,bind: false,tip: '',attribute:'valueE'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateS',text: '起始值校验',type: 'validate',init: rule1,	target: '',	bursh: true,bind: false,tip: '',attribute:'valueS',name:'起始值'},
	{key: 'validateE',text: '终止值校验',type: 'validate',init: rule2,	target: '',	bursh: true,bind: false,tip: '',attribute:'valueE',name:'终止值'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

	{key: 'min',text: '允许最小值',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'max',text: '允许最大值',type: 'number',init: '100',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'step',text: '增量步长',type: 'number',init: '1',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'vertical',text: '是否竖向',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'range',text: '滑块模式',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: [{label:'单值',value:'N'},{label:'范围',value:'Y'}]},
	{key: 'vHeight',text: '竖向高度',type: 'text',init: '150px',	target: '',	bursh: true,bind: true,tip: '',list: [{label:'单值',value:'N'},{label:'范围',value:'Y'}]},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',bursh: true,bind: true,tip: '',list: compBase.sizes},
	{key: 'mark',text: '显示标签',type: 'select',init: 'Y',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'tooltip',text: '显示提示',type: 'select',init: 'Y',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'input',text: '显示输入框',type: 'select',init: 'N',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'sizeI',text: '输入框大小',type: 'select',init: 'default',target: '',bursh: true,bind: true,tip: '',list: compBase.sizes},
	{key: 'stops',text: '显示间断点',type: 'select',init: 'N',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
 	{key: 'width',text: '元素宽度',type: 'text',init: 'calc(100% - 15px)',	target: 'width',bursh: true,bind: true,tip: '',list: options.YON},
    compBase.status2
]
	

let sliderM = {
	data: {
		type: 'sliderM',isInput:'M',marginL:'15px'
	},
	delete:'',// 'fontName,fontColor,fontBold,fontItalic,fontHeight',
	hide: 'range,width,dataOption',
	base: base.concat(font).concat(compBase.base),
	special: extend.tip.concat([
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '刻度数据',type: 'jsonArray',init: datas,	target: '',bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},]),
	action: options.getEvents(null,'$event_change').concat([
		options.listLoadAfterEvents.dataListEvent,
	//	options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default sliderM
