import event from './event.js'
import options from "./options.js"

let displays=[{label:'弹性布局(Flex)',value:'flex'},{label:'常规布局',value:''}]
let directs=[{label:'从左向右',value:'row'},{label:'从上到下',value:'column'},{label:'右到左',value:'row-reverse'},{label:'从下到上',value:'column-reverse'}]
let wraps=[{label:'允许换行',value:'wrap'},{label:'不允许换行',value:'nowrap'}]
//let alignsH=[{label:'左侧',value:'flex-start'},{label:'居中',value:'center'},{label:'右侧',value:'flex-end'},{label:'两端',value:'space-between'},{label:'等间距',value:'space-around'}]
//let alignsV=[{label:'顶部',value:'flex-start'},{label:'中间',value:'center'},{label:'底部',value:'flex-end'}]
let imgModes=[{label:'等比缩放填充',value:'cover'},{label:'自由拉伸填充',value:'fill'},{label:'原始大小',value:'no-repeat'},{label:'XY平铺',value:''},{label:'X平铺',value:'repeat-x'},{label:'Y平铺',value:'repeat-y'}]
let scroll=[{label:'自动',value:'auto'},{label:'始终',value:'scroll'},{label:'不滚动可溢出',value:'visible'},{label:'不滚动不溢出',value:'hidden'}]
//let alignContents=[{label:'顶部',value:'flex-start'},{label:'中间',value:'center'},{label:'底部',value:'flex-end'},{label:'两端',value:'space-between'},{label:'等间距',value:'space-around'},{label:'拉伸',value:'stretch'}]
let positions=[{label:'默认',value:''},{label:'相对定位',value:'relative'},{label:'绝对定位',value:'absolute'},{label:'固定窗口定位',value:'fixed'}]
//let contentAligns=options.toOptions('横向从左向右=row,横向从右向左=row-reverse,纵向从上往下=column,纵向从下往上=column-reverse')
 

let layout = [
//{key:'show',text:'显示方式',type:'select',init:'show',target:'position',bursh:true,bind:true,tip:'show:正常显示，none:隐藏（消除），hidden:隐藏（占位）',list:options.showTypes},
{key:'title',text:'布局样式'},
{key:'display',text:'布局方式',type:'select',init:'flex',target:'display',bursh:true,bind:true,tip:'',list:displays},
{key:'flexD',text:'排版方式',type:'select',init:'row',target:'flex-direction',bursh:true,bind:true,tip:'设定内部元素横向与纵向的排列方向，将对水平和垂直方向发生转置，具体规则参考CSS Flex布局的flex-direction属性',list:directs},
{key:'flexW',text:'允许换行',type:'select',init:'wrap',target:'flex-wrap',bursh:true,bind:true,tip:'布局类型为flex时生效,是否允许内容内元素自动换行',list:wraps},
{key:'position',text:'定位方式',type:'select',init:'',target:'position',bursh:true,bind:true,tip:'相对定位类型上下左右位置属性失效，绝对定位位置相对于本容器，固定定位位置相对于浏览器',list:positions},
//{key:'contentD',text:'排版方向',type:'select',init:'flex-start',target:'alignContent',tip:'主轴垂直方向时垂直方向对齐，否则为水平方向对齐',bursh:true,bind:true,list:options.alignContents},	
//{key:'contentAlign',text:'排版方式',type:'select',init:'row',target:'',tip:'',bursh:true,bind:true,list:options.alignContents},	

{key:'alignH',text:'水平对齐',type:'select',init:'flex-start',target:'justifyContent',bursh:true,bind:true,tip:'主轴方向为横向时水平方向对齐，否则为纵向方向对齐',list:options.alignsH},
//alignItems
{key:'alignV',text:'垂直对齐',type:'select',init:'flex-start',target:'alignContent',tip:'主轴垂直方向时垂直方向对齐，否则为水平方向对齐',bursh:true,bind:true,list:options.alignContents},	

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'minW',text:'最小宽度',type:'text',init:'0px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'minH',text:'最小高度',type:'text',init:'0px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'overH',text:'水平滚动',type:'select',init:'visible',target:'overflowX',tip:'容器内水平方向溢出时',bursh:true,bind:true,list:scroll},	
{key:'overV',text:'垂直滚动',type:'select',init:'visible',target:'overflowY',tip:'容器内垂直方向溢出时',bursh:true,bind:true,list:scroll},	
{key:'backColor',text:'背景色',type:'colorA',init:null,target:'backgroundColor',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},	 
{key:'backImg',text:'背景图片',type:'file',init:'',target:null,bursh:true,bind:true,tip:'如设置了背景图片将覆盖背景颜色',list:null},	
{key:'backMode',text:'背景模式',type:'select',init:'cover',target:null,bursh:true,bind:true,tip:'默认拉伸平铺填满容器',list:imgModes},	 
{key:'opacity',text:'透明度',type:'text',init:'',target:'opacity',bursh:true,bind:true,tip:'透明度0到1之间的小数值',list:null},	 

{key:'borderS',text:'边框线型',type:'select',init:'solid',target:'borderStyle',bursh:true,bind:true,tip:'无边框:none,实线:solid,虚线:dashed,点线:dotted,双实线:double,立体凸线:outset,立体凹线:inset',list:options.BorderType},	
{key:'borderL',text:'左边框',type:'text',init:'0px',target:'borderLeftWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderT',text:'上边框',type:'text',init:'0px',target:'borderTopWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderR',text:'右边框',type:'text',init:'0px',target:'borderRightWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderB',text:'下边框',type:'text',init:'0px',target:'borderBottomWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderColor',text:'边框颜色',type:'color',init:'#cccccc',target:'borderColor',bursh:true,bind:true,tip:'边框颜色',list:null},	 
{key:'borderRadius',text:'圆角大小',type:'text',init:'',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 

{key:'marginL',text:'外左间距',type:'text',init:'0px',target:'marginLeft',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'marginT',text:'外上间距',type:'text',init:'0px',target:'marginTop',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'marginR',text:'外右间距',type:'text',init:'0px',target:'marginRight',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'marginB',text:'外下间距',type:'text',init:'0px',target:'marginBottom',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'paddingL',text:'内左边距',type:'text',init:'5px',target:'paddingLeft',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'paddingT',text:'内上边距',type:'text',init:'5px',target:'paddingTop',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'paddingR',text:'内右边距',type:'text',init:'5px',target:'paddingRight',bursh:true,bind:true,tip:'象素、百分比',list:null},		
{key:'paddingB',text:'内下边距',type:'text',init:'5px',target:'paddingBottom',bursh:true,bind:true,tip:'象素、百分比',list:null},	
//{key:'left',text:'左边距离',type:'text',init:'0px',target:'left',bursh:true,bind:true,tip:'绝对定位与固定定位时生效',list:null},	
//{key:'top',text:'顶端距离',type:'text',init:'0px',target:'top',bursh:true,bind:true,tip:'绝对定位与固定定位时生效',list:null},	
//{key:'right',text:'右边距离',type:'text',init:'0px',target:'right',bursh:true,bind:true,tip:'绝对定位与固定定位时生效',list:null},	
//{key:'bottom',text:'底边距离',type:'text',init:'0px',target:'bottom',bursh:true,bind:true,tip:'绝对定位与固定定位时生效',list:null},	
//{key:'zIndex',text:'层次级别',type:'number',init:'0',target:'zIndex',bursh:true,bind:true,tip:'元素层叠顺序，值大的在顶部，绝对定位与固定定位时生效',list:null},	
 {key: 'ede',text: '偏转角度',type: 'number',init: 0,	target: '',bursh: true,bind: true,tip: '',list: null},
{key: 'rotate',text: '开启旋转N',type: 'select',init: '',	target: '',bursh: true,bind: true,tip: '不旋转=,顺时针旋转=rotate-forward,逆时针旋转=rotate-reverse',list: options.rotateTypes},
  {key: 'seconds',text: '转速(秒)',type: 'number',init: 0,	target: '',bursh: true,bind: true,tip: '',list: null},


]
export default layout
