 class Ajax {
 	constructor() {

 	}

//
 	get(url, param, sucess, error) {
 		var xmlHttp = window.XMLHttpRequest ? new XMLHttpRequest() : ActiveXObject("Microsoft.XMLHTTP")
 		//ActiveXObject("microsoft.XMLHttp")为了兼容IE5和IE6
 		xmlHttp.onreadystatechange = () => { //每当readyState的属性改变时，就会调用该函数
 			if (xmlHttp.readyState === 4) {
 				// readyState存有的XMLHttpRequest的状态从0到4发生变化。 
 				// 0：请求未初始化 
 				// 1：服务器连接已建立 
 				// 2：请求已接收 
 				// 3：请求处理中 
 				// 4：请求已完成，且响应已就绪 
 				if (xmlHttp.status === 200) { //status将状态返回为数字（例如，“Not Found”为404，“OK”为200）
 					// xmlHttp.responseText是以字符串形式返回获取到的内容
 					//console.log(xmlHttp.responseText);//这个是js字符串类型，需要转换为json对象
 					let data = JSON.parse(xmlHttp.responseText)
 					sucess(data)
 					// console.log(data)
 				} else {
 					// console.log("获取失败")
 					if (error) {
 						// console.log(xmlHttp)
 						error(xmlHttp.status)
 					}
 				}
 			}
 		}
 		// open(method,url,async)	
 		//     规定请求的类型、URL 以及是否异步处理请求。

 		//     method：请求的类型；'GET' 或 'POST'

 		//     url：文件在服务器上的位置

 		//     async：true（异步）或 false（同步）

 		// send(string)	
 		//     将请求发送到服务器。

 		//     string：仅用于 POST 请求

 		url = this.getUrl(url, param)
 		xmlHttp.open('GET', url, false);
 		xmlHttp.send();
 	}

 	post(url, param, data, sucess, error) {
 		var xmlHttp = window.XMLHttpRequest ? new XMLHttpRequest() : ActiveXObject("Microsoft.XMLHTTP")
 		
		xmlHttp.onreadystatechange = () => { //每当readyState的属性改变时，就会调用该函数
 			if (xmlHttp.readyState === 4) {
 				if (xmlHttp.status === 200) { //status将状态返回为数字（例如，“Not Found”为404，“OK”为200）
 					// xmlHttp.responseText是以字符串形式返回获取到的内容
 					//console.log(xmlHttp.responseText);//这个是js字符串类型，需要转换为json对象
 					let data = JSON.parse(xmlHttp.responseText)
 					sucess(data)
 					// console.log(data)
 				} else {
 					// console.log("获取失败")
 					if (error) {
 						// console.log(xmlHttp)
 						error(xmlHttp.status)
 					}
 				}
 			}
 		}

 		url = this.getUrl(url, param)
 		xmlHttp.open('POST', url, false);
		xmlHttp.setRequestHeader('Content-Type','application/json')
 		let ds = JSON.stringify(data)
 		xmlHttp.send(ds);
 	}


 	getUrl(url, param) {
 		if (param) {
 			if (url.indexOf('?') < 1) {
 				url = url + '?'
 			}
 			let withJoin = url.indexOf('&') > 0
 			for (let key in param) {
 				if (withJoin) {
 					url = url + '&'
 				} else {
 					withJoin = true
 				}
 				url = url + key + '=' + encodeURIComponent(param[key])
 			}
 		}
 		return url
 	}

 }


 export default new Ajax()