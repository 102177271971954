<template>
	<div ref="container" :style="compStyle" style="overflow: auto;position: relative; ">
		<table class="treetable" border="1">


			<tr ref="headRow" class="tabletop">
				<td class="node-arae">
					<div ref="firstCol" style="display: flex;justify-content: space-between;align-items: center;">
						<span> </span>
						<span>任务/计划</span>
						<div>
							<i class="fas fa-plus-square tool-icon"></i>
							<!-- 	<i class="fas fa-check-square tool-icon"></i> -->
						</div>
					</div>
				</td>
				<template v-for="(head,index) in model.items">
					<td @click.stop="headClick(head)"
						:style="{width:head.width,minWidth:head.minWidth || model.colMinWidth,fontFamily:head.fontName,fontSize:head.fontSize,color:head.fontColor,fontWeight:head.fontBold,backgroundColor:head.bgColor}">
						<div :class="headSelect===head?'tabletop-div current-col':'tabletop-div'">{{head.label}}

						</div>

					</td>
				</template>
				<template v-for="point in timeSplits.points">
					<td :ref="'time'+point.index" style="min-width:30px;text-align: center; ">{{point.label}} </td>
				</template>
			</tr>
			<!-- 			<tr v-if="isedit " class="">
				<td class="col-disable">
					<div>模板行 </div>
				</td>
				<td v-for="head in model.items" :class="head.mode==='custom'?'col-design':'col-disable'">
					<container v-if="head.mode==='custom' && head.panel" :model="head.panel" :project="project"
						:page="page" :host="host" :isedit="isedit" :datas="null" />

					<div v-else>
						#{{head.typeGroup==='text'?'文本':head.typeGroup==='num'?'数值':head.typeGroup==='time'?'时间':'未知'}}
					</div>
				</td>
			</tr> -->
			<template v-for="(row,rowIdx) in taskRows" :key="row.id">
				<tr :ref="'row'+row.node.id" v-show="row.show" :class="row.split?'row row-split':'row'"
					:style="{height:row.show?'':'0px'}">
					<td class="cell task-node" :style="{paddingLeft:row.left}">

						<!-- 	<i v-if="row.fold" @click="toggleRow(row,row.folded)"
							:class="'fold fas fa-'+(row.folded ?'caret-right':'caret-down')"></i> -->
						<div class="task-item">
							<a @click="nodeClick(row)" class="celllink node-text" href="javascript:void(0)">
								{{row.node.label}}
							</a>
							<span class="task-tool">
								<i class="fas fa-plus-square tool-icon"></i>
								<i v-if="row.node.leafCount===0" class="fas fa-minus-square tool-icon"></i>
							</span>
						</div>



					</td>
					<template v-for="col in row.cols">

						<td v-show="row.show"
							:style="col.head.$name==='taskStatus'?{backgroundColor:taskStatus[col.label].color}:null"
							:align="model.dataH" :valign="model.dataV" class="cell">

							<template v-if="col.head.$name==='taskStatus'">
								<div class="task-status" :style="taskStatus[col.label].color?null:{color:'unset'}">
									{{taskStatus[col.label].label}}
								</div>

							</template>
							<template v-else>
								<container v-if="! row.node.empty && col.head.mode==='custom' && row.node.$cellModel"
									:model="row.node.$cellModel[col.head.label]" :project="project" :page="page"
									:host="host" :isedit="isedit" :datas="row.node.data" />
								<a v-else @click="dataClick(row,col)" class="celllink data" href="javascript:void(0)">
									{{col.label}}
								</a>
							</template>

						</td>
					</template>

					<template v-for="(point,timeIdx) in timeSplits.points">
						<td :class="rowIdx===0 && timeIdx===0?'time-td':null">

							<template v-if="rowIdx===0 && timeIdx===0">
								<div ref="panelContainer" class="plan-panel">
									<div class="plan-bars">
										<template v-for="bar in planBars">
											<div class="plan-bar-main" :style="bar.style">

											</div>
											<div class="plan-bar-sub1" :style="bar.planBar.style">

											</div>
											<div class="plan-bar-sub2" :style="bar.realBar.style">

											</div>
										</template>
									</div>
									<svg ref="panelCanvas" class="plan-canvas">
										<defs>
											<marker id="triangle" markerUnits="strokeWidth" markerWidth="5"
												markerHeight="4" refX="0" refY="2" orient="auto">
												<path d="M 0 0 L 5 2 L 0 4 z" stroke="#c71585" />
											</marker>
										</defs>
										<template v-for="task in taskRows">
											<template v-if="task.$planPaths">
												<path v-for="line in task.$planPaths" :d="line" stroke="#c71585"
													stroke-width="2" fill="none" style="marker-end: url(#triangle);" />
											</template>

										</template>

									</svg>



								</div>
							</template>

						</td>
					</template>


				</tr>
			</template>
		</table>

	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		props: {
			tick: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				//	taskNodes: [],
				taskRows: [],
				headSelect: null,
				timeStart: 0,
				timeEnd: 0,
				planBars: [],
				taskStatus: {
					wait: {
						label: '未开始',
						color: ''
					},
					start: {
						label: '进行中',
						color: ''
					},
					finish: {
						label: '已完成',
						color: 'rgba(103, 194, 58, 0.7)'
					},
					late: {
						label: '超期完成',
						color: 'rgba(255, 69, 0, 0.7)'
					},
					over: { //进行中且已超出计划时间
						label: '已超期',
						color: 'rgba(199, 21, 133, 0.7)'
					}
				}

			}
		},

		methods: {
			headClick(col) {
				if (this.isedit) {
					this.headSelect = col
					this.setCurrentElement(col)
				}

			},
			getTreeTable(tree, filds) {
				let indent = this.model.indent ? this.model.indent : 20

				let rows = []
				let parents = {}
				let stack = [].concat(tree.root)
				stack.reverse()
				while (stack.length > 0) {
					let node = stack.pop()
					//提取全局最小时间、最大时间

					for (let j = node.children.length - 1; j > -1; j--) { //反向入栈，按原来的顺序取出
						stack.push(node.children[j])
					}


					let row = {
						id: node.id,
						node: node,
						fold: node.children.length > 0,
						folded: true, //是否折叠
						show: true,
						split: false, //分隔行
						cols: []
					}
					node.row = row
					/* 					let planStart = ''
										let realStart = ''
										let planFinish = ''
										let realFinish = '' */
					for (let field of filds) { //根据数据属性生成数据区单元格
						/* 						if (field.$name === 'planstart') {
													planStart = node.data[field.fieldName]
												} else if (field.$name === 'realstart') {
													realStart = node.data[field.fieldName]
												} else if (field.$name === 'planfinish') {
													planFinish = node.data[field.fieldName]
												} else if (field.$name === 'realfinish') {
													realFinish = node.data[field.fieldName]
												}
						 */
						let col = {
							field: field.fieldName,
							head: field,
							label: node.data[field
								.fieldName] //this.formatNum(node.data[fild.fieldName], false), //格式化数据
						}
						row.cols.push(col)
					}
					let deep = 0
					let pn = node.parent
					while (pn) {
						deep++
						//逐层向上覆盖最大值与最小值


						pn = pn.parent
					}
					row.left = (5 + deep * indent) + 'px',


						rows.push(row)
				}
				//根节点设置显示
				for (let nd of tree.root) {
					nd.row.show = true
				}

				return rows
			},
			autoNumber(tree) {
				let stack = [].concat(tree.root)
				while (stack.length > 0) {
					let node = stack.pop()
					let idx = 1
					for (let sub of node.children) {
						let num = node.num ? node.num + '.' + idx : idx
						sub.num = num
						sub.label = num + ' ' + sub.label
						idx++
						stack.push(sub)
					}
				}
			},

			getTree(nodeList, fields) {

				let tree = {
					deep: 0,
					root: [],
					leaf: [],
					dataBag: null
				}
				let nowTime = this.$logic.date.toDateTimeString(new Date())
				let statusCol = null
				for (let col of fields) {
					if (col.$name === 'taskStatus') {
						statusCol = col


						break
					}
				}

				let dataBag = {}
				let list = [] //用数据确保数据项顺序

				for (let node of nodeList) {
					let data = {
						...node
					}
					for (let col of fields) {
						let field = col.fieldName
						if (col.$name) { //如果是内置列，设置内置属性,方便使用
							data['$' + col.$name] = field && field.length > 0 ? data[field] : null
						}

						if (node[field] == null) { //无值置为空，避免与0值混淆
							data[field] = null
						} else {
							data[field] = node[field]
							if (col.mode === 'num' && typeof(data[field]) === 'string') { //如果类型为字符型，值一定是整数型
								data[field] = parseInt(data[field])
							}

						}

					}

					/* 	if (!has) {
							data = {}
						} */
					let item = {
						type: 'N',
						id: '' + node.id,
						pid: '' + node.pid,
						label: node.label,
						parent: null,
						data: data,
						leafCount: 0,
						layer: 0,
						children: []
					}
					dataBag[item.id] = item
					list.push(item)
				}
				//构建树
				for (let item of list) {
					let parent = dataBag[item.pid]
					if (parent) {
						item.parent = parent
						parent.children.push(item)
					} else {
						tree.root.push(item)
					}
				}
				/* 				//增加虚拟节点,如果本节点非叶子节点且自带数据在此下增加一个同名子节点，ID取负
								let isTotal = this.model.total === 'Y'
								let exNodes = [] //新增的节点，最后要加入list
								if (isTotal) {
									for (let node of list) {
										if (node.children.length > 0 && !node.empty) {
											let cn = {
												id: '-' + node.id,
												pid: '' + node.id,
												label: node.label + '*',
												parent: node,
												data: {
													...node.data
												},
												leafCount: 0,
												layer: 0,
												children: []
											}
											for (let key in node.data) { //父节点值置0
												if (typeof(node.data[key]) === 'number') {
													node.data[key] = 0
												}
											}
											node.children.push(cn)
											exNodes.push(cn)
										}
										if (node.children.length > 0) {
											node.total = null
										}

									}
									list = list.concat(exNodes)
									for (let node of list) {
										if (node.children.length > 0) { //对每个叶子节点自下向上累计
											continue
										}
										let pnode = node.parent
										while (pnode) {
											this.dataTotal(pnode.data, node.data, fields)
											pnode = pnode.parent
										}
									}

								}
								
								 */
				//提取自定义列，并生成事件

				let panels = []
				let heads = []
				for (let head of fields) { //取出自定义列

					if (head.mode === 'custom' && head.panel && head.panel.type) {
						panels.push(head.panel)
						heads.push(head)
					}

				}
				if (!this.isedit) { //运行模式创建事件
					this.$logic.http.initModel(this.elementConfig, panels, true, this.page)
				}
				let filtDataList = []

				//设置层级
				let deep = 0
				for (let item of list) {
					/* 				for (let head of heads) { //对每行生成自定义列的克隆对象
										let panel = this.$logic.clone.deepClone(head.panel, ['parent'])
										this.$logic.clone.setParent(panel)
										if (!item.$cellModel) {
											item.$cellModel = {}
										}
										item.$cellModel[head.label] = panel
									} */
					item.layer = 1

					let pnode = item.parent
					while (pnode) {
						item.layer++
						if (item.children.length === 0) { //如果是叶子节点，上级统计值加一
							pnode.leafCount++
						}

						if (deep < item.layer) { //取最大深度
							deep = item.layer
						}

						pnode = pnode.parent
						if (item.layer > 30) { //检测数据循环指向防止死循环
							alert('检测到树型数据循环引用，请核查数据')
							console.log(item)
							return {
								deep: 0,
								root: []
							}
						}
					}
					//如果当前是叶子节点，逐级覆盖上级节点的开始时间和完成时间,计划开始和计划完成时间有值，实际开始和实际完成时间不一定有值,只有当所有下级节点的实际值有值了才有值，因此先算实际值，然后再作比对
					this.setNodeStauts(statusCol, item, nowTime)
					if (item.leafCount === 0) { //如果是叶子节点，上级统计值加一
						pnode = item.parent
						while (pnode) {
							//取到子节点中的最大值和最小值
							let planStart = null,
								planFinish = null,
								realStart = null,
								realFinish = null
							let isStart = false,
								checkFinish = true
							for (let sub of pnode.children) {
								let data = sub.data
								//计划值确保没有空值
								if (planStart == null || planStart > data.$planStart) { //取小值
									planStart = data.$planStart
								}
								if (planFinish == null || planFinish < data.$planFinish) { //取大值
									planFinish = data.$planFinish
								}
								//实际值开始时间有可能空值，取非空的最小时间，只要有一个有值就行
								if (data.$realStart != null) {
									isStart = true
									if (realStart == null || realStart > data.$realStart) {
										realStart = data.$realStart
									}
								}

								//实际完成时间,子节点全部有值的才有值否则为空
								if (data.$realFinish == null) {
									checkFinish = false
								} else {
									if (realFinish == null || realFinish < data.$realFinish) {
										realFinish = data.$realFinish
									}
								}
							}
							if (!checkFinish) {
								realFinish = null
							}
							pnode.data.$planStart = planStart
							pnode.data.$planFinish = planFinish
							pnode.data.$realStart = realStart
							pnode.data.$realFinish = realFinish
							//替换结果数据值

							for (let col of fields) {
								let field = col.fieldName

								if (col.$name) { //如果是内置列，设置内置属性,方便使用
									if (field && field.length > 0) {
										pnode.data[field] = pnode.data['$' + col.$name]
										//	console.log(field,col.$name,pnode.data['$' + col.$name])
									}

								}

							}
							this.setNodeStauts(statusCol, pnode, nowTime)
							pnode = pnode.parent
						}
					}
					//设置后继节点
					let toTasks = []
					if (item.data.toTasks && item.data.toTasks.length > 0) {

						for (let tid of item.data.toTasks.split(',')) {
							let nextitem = dataBag[tid]
							if (nextitem && nextitem != item) { //禁止自循环
								toTasks.push(nextitem)
							}
						}
					}
					item.$toTasks = toTasks

				}
				if (list.length > 0) {
					let data = list[0].data

					let ps = data.$planStart && data.$planStart.length > 0 ? data.$planStart : ''
					let rs = data.$realStart && data.$realStart.length > 0 ? data.$realStart : ''
					let pf = data.$planFinish && data.$planFinish.length > 0 ? data.$planFinish : ''
					let rf = data.$realFinish && data.$realFinish.length > 0 ? data.$realFinish : ''
					let timeStartS = ps > rs ? rs : ps
					let timeEndS = pf > rf ? pf : rf
					this.timeStart = timeStartS.length > 0 ? new Date(timeStartS).getTime() : 0
					this.timeEnd = timeEndS.length > 0 ? new Date(timeEndS).getTime() : 0


				}



				tree.dataBag = dataBag
				tree.deep = deep

				return tree

			},
			setNodeStauts(statusCol, node, dateTime) {

				let data = node.data
				let status = 'wait'
				if (data.$realStart && data.$realStart.length > 0) {
					status = 'start'
				}
				if (data.$realFinish && data.$realFinish.length > 0) {
					status = 'finish'
					if (data.$realFinish > data.$planFinish) {
						status = 'late'
					}
				} else {
					if (dateTime > data.$planFinish) {
						status = 'over'
					}
				}
				data[statusCol.fieldName] = status
				data.$color = this.taskStatus[status].color

			},
			drawTaskTime() {
				let rows = this.taskRows
				let model = this.model

				//计算面板尺寸
				let panel = this.$refs.panelContainer

				if (!panel) {
					return
				}
				let points = this.timeSplits.points
				let firstCol = this.$refs.firstCol.getBoundingClientRect()
				let firstPlanCol = this.$refs['time0'].getBoundingClientRect()
				let lastPlanCol = this.$refs['time' + (points.length - 1)].getBoundingClientRect()
				let panelRect = panel.getBoundingClientRect()
				let offsetX = panelRect.left - firstCol.left
				let headRow = this.$refs.headRow.getBoundingClientRect()
				let container = this.$refs.container.getBoundingClientRect()
				panel.style.width = (lastPlanCol.left + lastPlanCol.width - firstPlanCol.left) + 'px'
				panel.style.height = (this.$refs.container.scrollHeight - headRow.height) + 'px'
				//生成时间条
				let headOffY = headRow.top + headRow.height
				let planOffX = this.$refs.time0.getBoundingClientRect().left

				let barHeight = 24
				let bars = []
				for (let row of rows) {
					let rowObj = this.$refs['row' + row.node.id]
					let rowRect = rowObj.getBoundingClientRect()
					//let rowHeight = rowRect.height

					let timeStart = row.node.data.$planStart > row.node.data.$realStart ? row.node.data.$realStart : row
						.node.data.$planStart
					let timeEnd = row.node.data.$planFinish < row.node.data.$realFinish ? row.node.data.$realFinish : row
						.node.data.$planFinish

					let timeStampStart = new Date(timeStart).getTime()
					let timeStampEnd = new Date(timeEnd).getTime()
					let bar = this.getPlanBar(row, rowRect.top, rowRect.height, points, timeStampStart, timeStampEnd,
						planOffX, headOffY,
						barHeight, 'borderColor')
					let planTimeStart = row.node.data.$planStart
					let planTimeEnd = row.node.data.$planFinish
					let planStampStart = new Date(planTimeStart).getTime()
					let planStampEnd = new Date(planTimeEnd).getTime()

					let subSpace = 0
					let subBarHeight = barHeight / 2 - 1 //barHeight / 2 - subSpace - subSpace / 2
					let barPlan = this.getPlanBar(row, rowRect.top + subSpace + 2, rowRect.height / 2, points,
						planStampStart,
						planStampEnd, planOffX, headOffY, subBarHeight, null)

					let realTimeStart = row.node.data.$realStart ? row.node.data.$realStart : planTimeStart
					let realTimeEnd = row.node.data.$realFinish ? row.node.data.$realFinish : planTimeEnd
					let realStampStart = new Date(realTimeStart).getTime()
					let realStampEnd = new Date(realTimeEnd).getTime()
					let barReal = this.getPlanBar(row, rowRect.top + subBarHeight + 1 * 2 + 2, rowRect.height / 2,
						points, realStampStart,
						realStampEnd, planOffX, headOffY, subBarHeight, 'backgroundColor')
					bar.planBar = barPlan
					bar.realBar = barReal
					row.$planBar = bar
					bars.push(bar)
					//console.log(item.data.toTasks, item.$toTasks) 
					//console.log(row)

				}
				this.planBars = bars

				//画线
				for (let row of rows) {
					//console.log(row)
					if (row.node.$toTasks && row.node.$toTasks.length > 0) {
						let paths = []
						for (let next of row.node.$toTasks) {
							let bar = row.$planBar
							let toBar = next.row.$planBar

							let x1 = bar.x2 + 15
							let y1 = bar.y2
							let y2 = bar.y2 + bar.h / 2 + 6 // bar.y2 + (toBar.y1 - bar.y2) / 2
							let x2 = toBar.x1 - 25
							let x3 = toBar.x1 - 12 //为箭头在侧预留几个象素

							let path = 'M ' + bar.x2 + ',' + bar.y2 + ' L ' + x1 +
								',' + y1

							if (bar.x2 > toBar.x1) {
								path = path + ' ' + x1 + ',' + y2 + ' ' + x2 + ',' + y2 + ' ' + x2 + ',' + y2 + ' ' + x2 +
									',' + toBar.y1 + ' ' + x3 + ',' + toBar.y1
							} else {
								path = path + ' ' + x1 + ',' + toBar.y1 + ' ' + x3 + ',' + toBar.y1
							}

							paths.push(path)

						}
						row.$planPaths = paths

					}

				}


			},
			getPlanBar(row, rowTop, rowHeight, points, timeStampStart, timeStampEnd, planOffX, headOffY, barHeight,
				colorType) {

				let p1 = -1,
					p2 = -1
				for (let i = 0; i < points.length; i++) {

					let point = points[i]
					if (point.start >= timeStampStart && p1 < 0) {
						p1 = i
					}
					if (point.end >= timeStampEnd && p2 < 0) {
						p2 = i
					}

				}

				let col1Rect = this.$refs['time' + p1].getBoundingClientRect()
				let left = col1Rect.left - planOffX
				let col2Rect = this.$refs['time' + p2].getBoundingClientRect()
				let width = col2Rect.left + col2Rect.width - planOffX - left
				let top = (rowTop - headOffY + (rowHeight - barHeight) / 2)
				let style = {

					left: left + 'px',
					top: top + 'px',
					width: width + 'px',
					height: barHeight + 'px',
				}

				if (colorType) {
					style[colorType] = row.node.data.$color
				}

				let bar = {
					x1: left,
					y1: top + barHeight / 2, //柱条中心位置
					x2: left + width,
					y2: top + barHeight / 2,
					h: barHeight,
					w: width,
					style: style
				}
				return bar
			},
			loadData() {
				let model = this.model

				if (this.isedit) {
					if (model.items === null) { //初始化默认列
						let config = this.elementConfig
						let initCols = []
						let initHeads =
							'计划开始时间=planStart,计划完成时间=planFinish,实际开始时间=realStart,实际完成时间=realFinish,当前进度=taskProgress,状态=taskStatus,负责人=leaderName,验收人=checkerName'
							.split(',')
						for (let col of initHeads) {
							let item = config.create('reportcol', this.model, this.page.formData)
							let x = col.indexOf('=')
							item.label = col.substring(0, x)
							item.$name = col.substring(x + 1) //基本列具有此属性							
							item.fieldName = col.substring(x + 1).toLowerCase()

							if ('planstart,planfinish,realstart,realfinish'.indexOf(item.fieldName) > -1) {
								item.typeGroup = 'time'
							} else if (item.fieldName.indexOf('name') > -1) {
								item.typeGroup = 'text'
							} else {
								item.typeGroup = 'num'
							}
							initCols.push(item)
						}
						model.items = initCols
					}


					let tree = this.getTree(model.taskNodes, model.items)
					this.autoNumber(tree)
					this.taskRows = this.getTreeTable(tree, model.items)
					setTimeout(() => {
						this.drawTaskTime()
					}, 500)
					//console.log(this.taskRows)
				} else { //数据库取数据

				}

			}


		},
		computed: {
			timeSplits() { //获取时间间隔数组
				let model = this.model
				let rowHeight = model.headHeight
				rowHeight = model.trH
				let rs = {
					unitSize: 1,
					points: []
				}
				let size = (this.timeEnd - this.timeStart) / (1000) //先算出秒数 
				let timeUnit = 84000 //时间单位，默认天
				//得到时间区间大小
				if (model.planType === 'hour') {
					timeUnit = 3600
				} else if (model.palnType === 'minute') {
					timeUnit = 60
				}
				size = size / timeUnit

				let pointMax = model.maxPoints ? model.maxPoints : 50
				let unitSize = size / pointMax
				unitSize = unitSize < 1 ? 1 : parseInt(unitSize + 0.5)
				if (model.unitSize && (size / model.unitSize < pointMax)) { //如果指定了时间段单位且在有效范围内使用指定值
					unitSize = model.unitSize
				}
				pointMax = parseInt(size / unitSize + 1) //实际最大时间点个数

				let points = []
				for (let i = 0; i < pointMax; i++) {
					let point = {
						index: i,
						start: this.timeStart + i * timeUnit * unitSize * 1000,
						end: this.timeStart + (i + 1) * timeUnit * unitSize * 1000,
						label: i
					}
					points.push(point)
				}
				rs.unitSize = timeUnit
				rs.points = points
				setTimeout(() => {
					this.drawTaskTime()
				}, 200)
				return rs
			}
		},
		watch: {
			tick(newValue, oldValue) {
				setTimeout(() => {
					this.drawTaskTime()
				}, 500)


			}
		},
		created() {
			this.loadData() 

		}
	}
</script>

<style scoped>
	.treetable {
		min-width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.cell {
		padding-left: 5px;
		padding-right: 5px;
	}

	.tool-icon {
		font-size: 18px;
		margin-left: 5px;
		margin-right: 5px;
		color: #606266;
	}

	.node-arae {
		position: sticky;
		position: -webkit-sticky;
		left: 0;
		top: 0;
		z-index: 100;
		width: var(--node-area-width);
		min-width: var(--node-area-minwidth);
	}

	.task-node {
		position: sticky;
		position: -webkit-sticky;
		left: 0;
		z-index: 99;
		box-shadow: 1px 0px 10px 1px #cccccc;
		font-family: var(--node-font-name);
		font-size: var(--node-font-size);
		font-weight: var(--node-font-weight);
		background-color: var(--node-back-color);
		/* 	background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5); */

		--task-tool: none;
	}

	.task-node:hover {
		--task-tool: show;
		background-color: var(--hover-color);
	}

	.task-item {
		display: flex;
		justify-content: space-between;
	}

	.task-tool {
		display: var(--task-tool);

	}

	.node-text {
		color: var(--node-font-color);
	}

	.data {
		font-family: var (--data-font-name);
		font-size: var(--data-font-size);
		font-weight: var(--data-font-weight);
		background-color: var(--data-back-color);
		color: var(--data-font-color);
	}

	.total {
		font-weight: bold;
	}

	.row {
		height: var(--row-height);


	}

	.row-split {
		background-color: var(--line-color);
	}

	/* 	.row:nth-child(odd) {
		background-color: var(--line-color);
	}
 */
	.row:hover {
		background-color: var(--hover-color);
	}

	.celllink {
		text-decoration: none;
	}

	.celllink:hover {
		text-decoration: underline;
	}

	.task-status {
		color: #ffffff;
	}

	.fold {
		font-size: 20px;
		margin-right: 5px;
		cursor: pointer;
		color: var(--node-icon-color);
	}

	.tabletop {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		z-index: 100;
		background-color: #e7e7e7;
	}

	.tabletop>td {
		height: var(--head-height);
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;
		cursor: pointer;

	}

	.tabletop-div {

		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;
		overflow: visible;

	}

	.current-col {
		background: linear-gradient(#ffdddd, #FF4500, #ffdddd);
		color: #ffffff;
	}

	.col-design {
		height: 50px;
		cursor: pointer;
		border: dashed 1px #cccccc;
	}

	.col-disable {
		height: 50px;
		background-color: #f8f8f8;
		cursor: not-allowed;
		border: solid 1px #cccccc;
		text-align: center;
	}

	.time-td {
		position: relative;

	}

	.plan-panel {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 10px;
		height: 10px;
		z-index: 0;

	}

	.plan-canvas {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%; 
		z-index: 0;
	}

	.plan-bars {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 10;
	}


	.plan-bar-main {
		position: absolute;
		border-radius: 4px;
		border: solid 0px rgba(30, 144, 255, 1);
		background-color: rgba(211, 211, 211, 0.7);
		/*box-shadow: 0px 0px 0px 1px #333333; */
	}

	.plan-bar-sub1 {
		position: absolute;
		border-radius: 4px;
		background-color: rgba(30, 144, 255, 0.7);
	}

	.plan-bar-sub2 {
		position: absolute;
		border-radius: 4px;
		background-color: rgba(30, 144, 255, 0.7);
	}

	/* 
	.plan-bar-in {
		position: relative;
		width: 100%;
		height: 100%;
	} */
</style>