<template>

	<div v-if="model.type=='imgcode'" :style="compStyle">
		<imgcode :panel-width="model.width" :panel-height="model.height" :many="toInt(model.charLength)"
			:font-size.num="toInt(model.fontSize)" :case-mix="model.caseMix=='Y'" @init="onCheckCodeInit"
			@update="imgCodeUpdate" />
	</div>
	<div v-else-if="model.type=='smscode'" :style="compStyle">
		<el-input v-model="mobile" type="num" maxlength="11" placeholder="请输入手机号码" prefix-icon="Iphone"
			:size="model.size">
			<template #append>
				<el-button v-if="canSend" @click="smsSend">
					<i class="fad fa-paper-plane"></i>
					发送
				</el-button>
				<el-button v-else disabled icon="Clock">{{seconds}}S</el-button>
			</template>
		</el-input>
	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	import ImgCode from '../../../../comm/checkcode/CheckCode.vue'
	import CommEvent from '../../../commevent.js'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {
				drawFunc: null,
				mobile: '',
				canSend: true,
				seconds: 0,

				smsLog: {
					sendTime: 0,
					receiveTime: 0,
					code: ''
				},

			}
		},

		methods: {
			codeCheck(code) {
				let now = new Date().getTime()
				let dif = (now - this.smsLog.sendTime) / 1000
				if (dif > 60 * 5) {
					this.$logic.tip.error('有效期超过5分钟，请重试')
					return false
				}
				let md5 = this.$logic.encoder.md5(this.smsLog.mobile + code)
				return (md5 == this.smsLog.code)

			},
			smsSend() {
				if (this.isedit) {
					return
				}
				let model = this.model
				if (!this.canSend) {
					this.$logic.tip.error('请稍候重试')
					return
				}
				if (!model.accessKeyId || !model.templateCode || !model.paramName || !model.signName) {
					this.$logic.tip.error('短信帐号ID、模板ID、验证码参数名称、短信签名不允许为空,请设置')
					return
				}

				if (this.mobile.length != 11) {
					this.$logic.tip.error('非法手机号码，请重新输入')
					return
				}


				let params = null
				try {
					params = JSON.parse(model.templateParam)
				} catch (ex) {
					this.$logic.tip.error('模板参数设置内容格式错误')
					return
				}

				let smsData = {
					mobile: this.mobile,
					codeLength: model.many,
					accessKeyId: model.accessKeyId,
					templateCode: model.templateCode,
					paramName: model.paramName,
					signName: model.signName,
					paramList: params
				}

				let check = this.doEvent({
					eventName: 'smsbefore',
					smsData: smsData
				}, '$comp_smsbefore')
				if (check === false) {
					return
				}
				this.smsLog.mobile = this.mobile
				this.smsLog.sendTime = new Date().getTime()
				this.smsLog.receiveTime = 0
				this.smsLog.code = ''
				this.seconds = this.toInt(this.model.seconds)
				this.canSend = false
				this.$logic.http.post(true, '/api/unit/smssend/', null, smsData, res => {
					this.smsLog.receiveTime = new Date().getTime()
					this.smsLog.code = res.data.data //md5值				

					if (this.checkEvent('$comp_smsafter')) {
						this.doEvent({
							eventName: 'smsafter',
							receive: this.smsLog
						}, '$comp_smsafter')
					} else {
						this.$logic.tip.success('手机短信发送成功')
					}
					let seconds = this.seconds
					let laterFunc = () => {
						this.seconds--
						if (this.seconds > 0) {
							setTimeout(laterFunc, 1000)
						} else {
							this.canSend = true
						}
					}
					laterFunc()

				}, err => {
					this.canSend = true
					if (this.checkEvent('$comp_smserror')) {
						this.doEvent({
							eventName: 'smserror',
							smsCode: smsCode
						}, '$comp_smserror')
					} else {
						this.$logic.tip.error('短信发送失败' + err.info || ',未知错误')
					}

				})
			},
			imgCodeUpdate(code) {
				this.model.value = code
				if (!this.isedit) {
					this.doEvent({
						eventName: 'codechange',
						codeValue: code
					}, '$comp_change')
				}
			},
			onCheckCodeInit(drawFunc) {
				this.drawFunc = drawFunc
				this.model.$draw = drawFunc.draw
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					if (model.type == 'imgcode') {
						return model.value
					} else {
						return this.mobile
					}

				} else {
					if (model.type == 'imgcode') {
						model.value = value
					} else {
						this.mobile = value
					}

				}
			}
		},
		computed: {
			imgCode() {
				return this.model.value
			},
		},
		watch: {
			imgCode(newValue, oldValue) {
				if (this.model.type == 'imgcode' && (!this.model.value || this.model.value == '')) {
					this.drawFunc()
				}
			},
		/* 	mobile(nv, ov) {
				this.model.mobile = nv
			} */

		},
		components: {
			imgcode: ImgCode
		},
		created() { 
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate=this.validate
				if (this.model.type == 'smscode') {
					this.model.$check = this.codeCheck
				}
			}


		}
	}
</script>

<style scoped>

</style>