<template>
	<div :style="baseStyle">
		<el-transfer v-model="transferResult" :style="transferStyle" :filterable="model.filter=='Y'"
			:left-default-checked="transferLeft" :right-default-checked="transferRight"
			:titles="[model.titleL, model.titleR]" :button-texts="['移出', '选入']" :target-order="model.order" :format="{
	      noChecked: '${total}',   hasChecked: '${checked}/${total}', }" :data="transferList">
			<template #default="{ option }">
				<span> {{ option.label }}</span>
			</template>
			<template #left-footer>

			</template>
			<template #right-footer>

			</template>
		</el-transfer>


	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				transferResult: null,
				transferLeft: [],
				transferRight: [],


			}
		},
		computed: {

			transferList() {

				let list = this.dataList

				let rs = []
				for (let i = 0; i < list.length; i++) {
					let item = list[i]
					rs.push({
						key: item.value,
						label: item.label,
						disabled: item.disabled == 'Y',
					})

				}
				return rs
			},
			transferStyle() {
				let css = this.compStyle
				css.textAlign = 'left'
				css.display = 'inline-block'
				css['--transfer-panel__body'] = 'calc(100% - 42px) '
				return css

			}
		},
		watch: {
			transferResult(nv, ov) {
				if (this.isedit) {
					this.model.value = JSON.stringify(nv)
				}
				/* else {
					this.model.value = nv
				} */
				if (ov == null) { //首次初始化不触发change
					return
				}
				this.onChange(nv)

			},
			'model.value': {
				handler: function(nv, ov) {
					this.initTransfer()
				}
			}

		},
		methods: {

			initTransfer() {
				this.$value(this.model.value)
			},
			$result() { //获取选中项的原始数据
				let rs = []
				if (this.transferResult && this.transferResult.length > 0) {
					let dataMap = {}
					for (let data of this.dataList) {
						dataMap[data.value] = data
					}
					for (let item of this.transferResult) {
						let data = dataMap[item]
						if (data) {
							rs.push(data)
						}
					}
				}
				return rs
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return this.transferResult
				} else {
					let rs = []
					if (value) {
						if (typeof(value) == 'string') { //支持数组、JSON数组、逗号分隔的多值传值
							if (value.indexOf('[') == 0) {
								try {
									rs = JSON.parse(value)
								} catch (ex) {
									this.$logic.tip.error('数据格式错误:' + ex)
								}
							} else {
								rs = value.split(',')
							}


						} else {
							rs = value
						}
					}
					this.transferResult = rs
				}

			}


		},
		created() {
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
				this.model.$result = this.$result

			}

			this.initTransfer()


		}
	}
</script>

<style scoped>
	:deep(.el-transfer) {
		width: 100%;
		/* height: calc(100% - 10px); */
	}

	:deep(.el-transfer-panel) {
		width: calc(50% - 60px);
		height: 100%;
	}

	:deep(.el-transfer__buttons) {
		text-align: center;
		width: 70px;
		padding-left: 15px;
	}

	:deep(.el-transfer__button) {
		margin: 10px 0px;
		display: block;
	}

	:deep(.el-transfer__buttons>button:last-child) {
		background-color: #67c23a;
		border-color: #67c23a;
		margin-top: -100px;
	}

	:deep(.el-transfer-panel__body) {
		height: var(--transfer-panel__body);
	}
</style>