<template>
	<el-drawer v-model="drawerShow" :close-on-click-modal="autoClose" :show-close="false" :with-header="false"
		:direction="direction" :size="drawerSize">
		<div class="dialog-head">
			<div style="display: flex;align-items: center">
				<i :class="icon" style="margin-right: 5px"></i>
				{{title}}
			</div>
			<div @click="dialogClose" class="title-button">
				<i class="far fa-times"></i>
			</div>
		</div>


		<div class="dialog-body">
			<slot></slot>
		</div>
		<div class="dialog-foot" :style="footStyle">
			<el-button v-if="showCancel" @click="dialogClose" :type="cancelType"><i
					:class="cancelIcon"></i>{{cancelText}}</el-button>
			<el-button v-if="showOk" :type="okType" @click="dialogOk">
				<i :class="okIcon"></i>{{okText}}</el-button>

		</div>

	</el-drawer>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			autoClose: {
				type: Boolean,
				default: true
			},
			dialogWidth: {
				type: String,
				default: '50%'
			},
			dialogHeight: {
				type: String,
				default: '50%'
			},
			dialogTop: {
				type: String,
				default: null
			},
			direction: {
				type: String,
				default: 'rtl'
			},
			buttonLayout: { //按钮排布
				type: String,
				default: 'right'
			},
			showCancel: { //取消按钮
				type: Boolean,
				default: true
			},
			cancelIcon: {
				type: String,
				default: ''
			},
			cancelText: {
				type: String,
				default: '取 消'
			},
			cancelType: {
				type: String,
				default: 'default'
			},

			showOk: {
				type: Boolean,
				default: true
			},
			okIcon: {
				type: String,
				default: ''
			},
			okText: {
				type: String,
				default: '确 定'
			},
			okType: {
				type: String,
				default: 'primary'
			},
		},
		data() {
			return {
				drawerShow: false
			}
		},
		emits: ['ok', 'close', 'open'],
		methods: {
			dialogClose(done) {
				if (this.autoClose) {
					this.dialog.visible = false

				}
				this.$emit("close", this.dialog) 
			},
			dialogOk(event) {
				this.$emit("ok", this.dialog, event)
			},
			show() {
				this.drawerShow = true
			},
			hide() {
				this.drawerShow = false
			}

		},
		computed: {
			drawerSize() {
				if (this.direction == 'ttb' || this.direction == 'btt') {
					return this.dialogHeight
				} else {
					return this.dialogWidth
				}

			},
			footStyle() {
				let css = {}
				css['--button-layout'] = this.buttonLayout
				return css
			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.dialog-head {
		height: 40px;
		font-size: 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;
		background-color: #e8e8e8;

	}

	.dialog-body {
		width: 100%;
		height: calc(100% - 90px);
		padding: 5px;
		min-height: 30px;
		overflow: auto;
	}

	.dialog-foot {
		height: 40px;
		padding: 5px 20px;
		border-top: solid 1px #cccccc;
		border-radius: 0px 0px 10px 10px;
		text-align: var(--button-layout);
	}

	.title-button {
		cursor: pointer;
	}

	.title-button:hover {
		color: #ff0000;
	}
</style>