<template>
	<div class="bread noselect" :style="baseStyle">
		<template v-for="(item,index) in dataList" :key="item.key">
			<span class="itemsplit">{{model.split}}</span>
			<span :class="item.url? 'breaditem':''" @click="itemClick(item)">
				<i v-if="model.showIcon=='Y' && item.icon" :class="item.icon" />
				{{item.label}}</span>
		</template>
		{{model.tick}}
	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				items: []

			}
		},

		methods: {
			itemClick(item) {

			},

			/* 	initItems() {
					let datas = this.model.dataList //|| this.dataList
					this.items = datas
				} */

		},
		watch: {
			/* 			 dataList(nv, ov) {
							 console.log(123) 
						} ,
						'model.jsonData':{
							handler:function (nv,ov){
								console.log(nv)
							}
							
						} */

		},
		created() {

			//	this.initItems()


		}
	}
</script>

<style scoped>
	.bread {
		display: flex;
		align-items: center;
	}

	.itemlink {
		cursor: pointer;
	}

	.itemlink:hover {
		text-decoration: underline;
	}

	.itemsplit {
		margin-left: var(--bread-margin);
		margin-right: var(--bread-margin);
	}
</style>
