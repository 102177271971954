<template>
	<div v-if="show" class="load content">
		<div :style="iconStyle">
			<i v-if="error" class="far fa-exclamation-triangle" style="color: #F56C6C;"></i>
			<el-icon v-else class="is-loading">
				<loading />
			</el-icon>
		</div>
		<div :style="labelStyle" class="loadlabel">{{error || message}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: true
			},
			message:{
				type: String,
				default: '数据加载中...'
			},
			iconSize: {
				type: Number,
				default: 80
			},
			labelSize: {
				type: Number,
				default: 30
			},
			error:{
				type: String,
				default: null
			}
		},
		data() {
			return {

			}
		},
		computed: {
			iconStyle() {
				let css = {
					fontSize: this.iconSize + 'px'
				}
				return css
			},
			labelStyle() {
				let css = {
					fontSize: this.labelSize + 'px'
				}
				return css
			}

		},
		methods: {
			 

		},
		created() {


		}
	}
</script>

<style scoped>
	.load {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 80px;
		color: #409eff;

	}

	.loadlabel {
		font-size: 30px
	}

	:deep(.el-icon) {
		font-size: unset;
	}
</style>
