export default [
{id: 1,pid: 0,label: '项目名称',planstart:'2023-05-31',planfinish:'2023-12-31',realstart:'2023-06-10',realfinish:'2023-11-31',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 2,pid: 1,label: '阶段一',planstart:'2023-05-31',planfinish:'2023-12-31',realstart:'2023-06-10',realfinish:'2023-11-31',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 3,pid: 1,label: '阶段二',planstart:'2023-05-31',planfinish:'2023-12-31',realstart:'2023-06-10',realfinish:'2023-11-31',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 4,pid: 1,label: '阶段三',planstart:'2023-05-31',planfinish:'2023-12-31',realstart:'2023-06-10',realfinish:'2023-11-31',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 5,pid: 1,label: '阶段四',planstart:'2023-05-31',planfinish:'2023-12-31',realstart:'2023-06-10',realfinish:'2023-11-31',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 6,pid: 2,label: '任务a1',planstart:'2023-05-31',planfinish:'2023-06-20',realstart:'2023-06-10',realfinish:'2023-07-10',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:'7,8'},
{id: 7,pid: 2,label: '任务a2',planstart:'2023-06-20',planfinish:'2023-07-31',realstart:'2023-06-10',realfinish:'2023-07-20',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 8,pid: 2,label: '任务a3',planstart:'2023-06-10',planfinish:'2023-07-20',realstart:'2023-06-20',realfinish:'2023-07-10',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:'9,10'},
{id: 9,pid: 3,label: '任务b1',planstart:'2023-06-15',planfinish:'2023-09-30',realstart:'2023-06-30',realfinish:null,leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 10,pid: 4,label: '任务c1',planstart:'2023-08-01',planfinish:'2023-09-31',realstart:'2023-08-10',realfinish:'2023-09-30',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 11,pid: 4,label: '任务c2',planstart:'2023-09-01',planfinish:'2023-10-20',realstart:'2023-09-10',realfinish:'2023-10-20',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 12,pid: 5,label: '任务d1',planstart:'2023-10-01',planfinish:'2023-11-25',realstart:'2023-10-10',realfinish:'2023-11-15',leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},
{id: 13,pid: 5,label: '任务d2',planstart:'2023-11-01',planfinish:'2023-12-31',realstart:'2023-11-10',realfinish:null,leadername:'郭靖',checkername:'杨康',taskprogress:50,toTasks:''},

] 
