import options from '../../public/options.js'
import font from '../../public/font.js'
import layout from '../../public/layout.js'
import extend from '../../public/extend.js'
let cs=[
{key:'title',text:'标签属性'},
{key:'key',text:'标签Key',type:'text',init:'tab',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'label',text:'标签文本',type:'text',init:'新标签',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'icon',text:'标签图标',type:'icona',init:'fas fa-edit',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'url',text:'链接页面',type:'page',init:'',target:'',bursh:true,bind:true,tip:'如设置了将嵌入外部链接或内部子页面',list: null},
//{key:'param',text:'链接参数',type:'text',init:'',target:'',bursh:true,bind:true,tip:'为链接地址投递参数',list: null},

] 
 
let tabItem= {
	data:{
			type: 'tabitem',
	},
	delete:'',
	hide:'contentD,width,height,tabs',//width,minW',
	base:cs ,
	special:extend.badge.concat( [
		]),
	action:[]
}

export default tabItem