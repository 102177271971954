import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
import extend from '../../public/extend.js'
let cs=[
	{key:'autoWidth',text:'响应式布局',type:'autowidth',init:{xs:"50%",sm:"50%",md:"50%",lg:"50%"},target:null,bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
	{key:'autoAlign',text:'水平对齐',type:'autoalign',init:{xs:"flex-start",sm:"flex-start",md:"flex-start",lg:"flex-start"},target:null,bursh:true,bind:true,tip:'flex-start、flex-end、center、space-between、space-around',list:null},
	
]
//响应式容器
let autocol= {
	data:{
		type:'autocol',height:'100%',alignV:'flex-start',minH:'20px',overH:'visible',overV:'visible',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'width,minW,alignH',
	hide:'display,flexW,position,paddingT,paddingB,paddingL,paddingR,marginL,marginT,marginR,marginB',
	base:cs.concat(layout).concat(font) ,
	special :[].concat(special),
	action:[
		{key:'enabled',text:'可用状态',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
		
	].concat(extend.link) // options.getEvents(null,'$timer,$event,$hook','$event_change,$event_input')
}

export default autocol