import compBase from '../../public/comp.js'
import options from '../../public/options.js'
let bars='CODE128,CODE39'//,CODE128A,CODE128B,CODE128C,EAN13,EAN8,UPC,ITF14,ITF,MSI,MSI10,MSI11,MSI1010,MSI1110,pharmacode
let barTypes=[]
for(let item of bars.split(',')){
	barTypes.push({label:item,value:item})
}
let base=[
	{key:'title',text:'条形码'},
	{key:'value',text:'内容',type:'area',init:'BarCode12345678',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'format',text:'条码类型',type:'select',init:'CODE128',target:'',bursh:true,bind:true,tip:'',list:barTypes},

	{key:'barWidth',text:'条码宽度',type:'number',init:'180',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'barHeigth',text:'条码高度',type:'number',init:'60',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'itemWidth',text:'线条宽度',type:'select',init:'1',target:'',bursh:true,bind:true,tip:'竖线单位宽度',list:options.toOptions('1px=1,2px=2,3px=3,4px=4,5px=5')},	 

	{key:'shwoText',text:'显示文本',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'fixText',text:'自定义文本',type:'text',init:'',target:'',bursh:true,bind:true,tip:'如有值，会将此设置内容替换条码内容文本',list:null},	 
	{key:'fontType',text:'字体',type:'select',init:'Microsoft YaHei',target:'',bursh:true,bind:true,tip:'文本字体',list:options.fonts},
	{key:'textSize',text:'字体大小',type:'number',init:15,target:'',bursh:true,bind:true,tip:'设置文本字号',list:null},	 
	
	{key:'fontOptions',text:'字体样式',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list:[{label:'默认',value:''},{label:'加粗',value:'bold'},{label:'斜体',value:'italic'},{label:'加粗斜体',value:'bold italic'}]},
	{key:'textAlign',text:'文本对齐',type:'select',init:'center',target:'',bursh:true,bind:true,tip:'设置文本的水平对齐方式',list:[{label:'左侧',value:'left'},{label:'中间',value:'center'},{label:'右侧',value:'right'}]},
	{key:'textPosition',text:'文本位置',type:'select',init:'bottom',target:'',bursh:true,bind:true,tip:'设置文本的垂直位置',list:[{label:'顶部',value:'top'},{label:'底部',value:'bottom'}]},
	{key:'textMargin',text:'字&码距离',type:'number',init:5,target:'',bursh:true,bind:true,tip:'设置条形码和文本之间的间距',list:null},	 
	{key:'backColor',text:'背景颜色',type:'color',init:'#eeeeee',target:'',bursh:true,bind:true,tip:'设置条形码的背景色',list:null},
	{key:'lineColor',text:'内容颜色',type:'color',init:'#000000',target:'',bursh:true,bind:true,tip:'设置线条和文本的颜色',list:null},
	{key:'barMargin',text:'周边距离',type:'number',init:5,target:'',bursh:true,bind:true,tip:'设置条形码周围的空白边距',list:null},	 
	

	
].concat(compBase.base)

let barcode= {
	data:{
		type:'barcode',
	},
	delete:'',
	hide:'barMargin',
	base:base ,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default barcode