import compBase from '../../public/comp.js'
import options from '../../public/options.js'



let base=[
{key:'title',text:'流程绑定'},

{key:'insId',text:'流程实例ID',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',read:true,list: null},
{key:'taskId',text:'流程任务ID',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',read:true,list: null},

{key:'flowId',text:'流程类型Id',type:'number',init:0,target:'',bursh:false,bind:false,tip:'',list: null},
{key:'flowName',text:'流程名称',type:'text',init:'',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'flowPath',text:'绑定流程',type:'flowselect',init:'未设置',target:'',bursh:false,bind:false,tip:'',list: null},

{key:'title',text:'列表联动'},
{key:'listId',text:'列表组件ID',type:'text',init:'',target:'',bursh:true,bind:true,tip:'如果设置组件ID，在流程 发起、待办处理、流程终止、节点开启、节点关闭、执行人变更操作后将自动刷新列',list:null},
{key:'flowStarted',text:'流程发起后',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'taskFinished',text:'待办处理后',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'flowCanceled',text:'流程终止后',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeOpened',text:'节点开启后',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeClosed',text:'节点关闭后',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'taskChanged',text:'任务换人后',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},

{key:'title',text:'弹出框'},
{key:'dialogMode',text:'弹出模式',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'dialogWidth',text:'弹出框宽度',type:'text',init:'80vw',target:'',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
{key:'dialogHeight',text:'弹出框高度',type:'text',init:'90vh',target:'',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'dialogTop',text:'顶边距',type:'text',init:'5vh',target:'',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

//{key:'Width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
//{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},

//{key:'formName',text:'表单名称',type:'text',init:'流程页面',target:'',bursh:false,bind:false,tip:'',list: null},
//{key:'formType',text:'表单类型',type:'text',init:null,target:'',bursh:false,bind:false,tip:'',read:true,list: null},
//{key:'accessGroups',text:'页面权限',type:'json',init:null,target:null,bursh:true,bind:true,tip:'',list:null},
//{key:'accessType',text:'权限场景',type:'access',init:'',target:null,bursh:true,bind:true,tip:'',list:null},

//{key:'content',text:'表单内容',type:'json',init:'',target:'',bursh:false,bind:false,tip:'',list: null}, 	

/* 
{key:'labelShow',text:'显示标签',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelText',text:'标签文本',type:'text',init:'条件',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelColor',text:'标签颜色',type:'color',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'label',text:'标签对象',type:'json',init:{positionX:0,positionY:0,style:{}},target:'',bursh:true,bind:true,tip:'',list: null},
{key:'condition',text:'路径条件',type:'text',init:'',target:'',bursh:true,bind:true,tip:'Javascript语法，逻辑表达式',list: null}, 	
 *//*{key:'positionX',text:'节点X位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'positionY',text:'节点Y位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
 */

]
let events=[
 	{key: '$comp_success',text: '提交成功后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
 	{key: '$comp_error',text: '提交失败后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},

	{key: '$comp_cancel',text: '取消操作后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_flowClose',text: '流程终止后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_taskChange',text: '待办变更后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_nodeClose',text: '节点终止后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_nodeOpen',text: '节点开启后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},


/*	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]

 
let flowform= {
	data:{
		type:'flowpage', 
	},
	delete:'',
	hide:'accessGroups',
	base:base,
	special :[],
	//action:options.getCommonEvents().concat(options.getEvents(null,'$hook')).concat(events)
	action:events.concat( options.getEvents(null,'$hook')).concat(events)
}

export default flowform