<template>
	<div class="menuback g-scrollbar" :style="menuStyle">
		<menuel :mode="model.mode" :items="dataList" :show-icon="model.showIcon=='Y'" :collapse="model.collapse=='Y'"
			:back-color="model.backColor" :text-color="model.textColor" :active-color="model.activeColor"
			:active='this.model.active' @menu-click="menuClick"></menuel>

	</div>
</template>

<script>
	import FlowUtil from '../../../../../plugins/flowUtil.js'
	import menuel from '../../../../comm/menu/MenuEl.vue'
	import formBase from '../../../formbase.js'
	import CommEvent from '../../../commevent.js'
	export default {
		mixins: [formBase, CommEvent],
		data() {
			return {

			}
		},

		methods: {
			setCurrentItem(itemKey, param, closeTag) { //如果联动了标签页，原标签页的当前卡片要关闭
				let preKey = this.model.active
				let model = this.model
				//查找菜单项
				let menuItem = null
				let menuMap = {}
				let sk = [].concat(this.dataList)
				while (sk.length > 0) { //查找节点，顺带将菜单项上级关系放到集合中,先要遍历，上级的一定会先进集合
					let node = sk.pop()
					if (node.key == itemKey) {
						menuItem = node
						break
					}
					if (node.items && Array.isArray(node.items)) {
						for (let sb of node.items) {
							menuMap[sb.key] = node
							sk.push(sb)
						}
					}
				}
				if (menuItem == null) {
					this.$logic.tip.error('不存在的菜单KEY：' + itemKey)
					return
				}
				//获取上级路径				
				let path = [menuItem]
				let parent = menuMap[menuItem.key]
				while (parent) {
					path.splice(0, 0, parent)
					parent = menuMap[parent.key]
				}
				if (closeTag && model.bindTab && model.bindTab.length > 0) {
					this.menuClick(menuItem, path, param, true)
				} else {
					this.menuClick(menuItem, path, param, false)
				}
				/* 	if (closeTag && model.bindTab && model.bindTab.length > 0) {
						let tabs = this.page.$(model.bindTab)
						if (tabs) {
							tabs.$close(preKey)
						}
					} */
				//this.model.active = itemKey


			},
			menuClick(item, itemPath, param, closePreTab) { //param用于外部调用时携带参数,

				if (!this.isedit) {
					this.model.active = item.key
					let model = this.model
					if (model.bindPath && model.bindPath.length > 1) {
						let path = this.getElement(model.bindPath) 
						if (path && path.type == 'bread') {
							path.$set('jsonData', itemPath)
						} else {
							this.$logic.tip.error('不存在的面包屑组件')
						}
					}
					if (model.bindPage && model.bindPage.length > 1) {
						let page = this.getElement(model.bindPage)
						if (page && page.type == 'subpage') {
							page.$set('url', item.url)
						} else {
							this.$logic.tip.error('不存在的页面容器组件')
						}
					}
					if (model.bindTab && model.bindTab.length > 1) {
						let tabs = this.getElement(model.bindTab)
						if (tabs && tabs.type == 'tabpage') {
							let idx = -1

							for (let i = 0; i < tabs.items.length; i++) {
								let tab = tabs.items[i]
								if (tab.key == item.key) {
									idx = i
									break
								}
							}
							let tab = null

							if (idx < 0) {
								let config = this.elementConfig
								tab = config.create('tabitem', tabs, this.page.formData)
								for (let key in item) {
									tab[key] = item[key]
								}
								//idx = tabs.items.length - 1
							} else {
								tab = tabs.items[idx]
							}
							if (param && item.url) { //如果指定了参数，转向时带参数,没有带参数使用菜单的网址
								let url = item.url
								if (url.indexOf('?') < 0) {
									url = url + '?$diyparam=Y'
								}
								for (let key in param) {
									url = url + '&' + key + '=' + param[key]
								}
								tab.url = url
							} else {
								tab.url = item.url
							}

							/* 						console.log(idx)
													if (idx > 0) {//往前移
														tab = tabs.items[idx]
														tabs.items.splice(idx, 1)
														tabs.items.splice(0, 0, tab)
														console.log(tabs.items)
													}
													
													 */
							//let items = [].concat(tabs.items)
							//tabs.$set('items', items)
							//console.log(tabs,tabs.$get)
							let preKey = tabs.$get('tabValue')
							tabs.$set('tabValue', item.key)
							if (item.isFresh === 'Y') {
								tabs.$fresh()
							}
							if (closePreTab && preKey != item.key) { //指定关闭的卡片
								tabs.$close(preKey)
							}


						} else {
							this.$logic.tip.error('不存在的标签页容器组件')
						}

					}
					this.doEvent({
						eventName: 'menuclick',
						menuItem: item,
						itemPath: itemPath
					}, '$comp_click')
				}
			},
			getElement(objId) {

				
				let obj = this.$logic.pageUtil.getElementById(this.page.formData,objId)    //this.page.$(objId)
				return obj
			}

		},
		watch: {
			'model.collapse': {
				handler: function(newValue, oldValue) {
					if (newValue == 'Y') {
						this.doEvent({
							eventName: 'collapse'
						}, '$comp_collapse')
					} else {
						this.doEvent({
							eventName: 'expand'
						}, '$comp_expand')
					}
				}

			}
			/* 			'model.collapse': {
							deep: true,
							handler: function(newV, oldV) {
								console.log(888)

							}
						} */
		},
		computed: {

			menuStyle() {
				let model = this.model
				let css = {
					backgroundColor: model.backColor,

					//borderRight: 'solid 1px ' + model.borderColor,
					//width: 'calc(100% - 1px )'
				}
				if (model.collapse == 'Y') {
					css.width = "65px"
				}
				return css
			}
		},
		created() {
			if (!this.isedit) {
				this.model.$select = this.setCurrentItem
				let user = this.$logic.getUser()
				let userId = '' + user.id
				let stack = [].concat(this.dataList)
				while (stack.length > 0) {
					let item = stack.pop()
					item.hide = false
					if (item.access && item.access.length > 0) { //没有设置访问权限不作处理
						let userIds = FlowUtil.getExpressUsers(item.access, this.project, {})
						if (userIds.indexOf(userId) < 0) {
							item.hide = true
						}
					}
					if (item.items) {
						for (let sub of item.items) {
							stack.push(sub)
						}
					}
				}

			} 

		},
		components: {
			menuel: menuel
		},
	}
</script>

<style scoped>
	.menuback {
		height: 100%;
		width: 100%;
		/* border-right: solid 1px #e6e6e6; */
		overflow: auto;

	}

	:deep(.el-menu) {
		/* 取消自带的右边框线颜色 */
		--el-menu-border-color: unset;
	}

	:deep(.el-menu--horizontal) {
		border-bottom: 0px;
	}
</style>