import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
import chart from  '../base/chartConfig.js'
let base=[
	{key:'title',text:'风铃词条'},
]
 
let rs={base:chart.base,timerEvents: chart.timerEvents ,commEvents:chart.commEvents}

export default rs