<template>
	<span :style="compStyle">
		<i :class="model.leftIcon"></i>{{model.textBefore}}
		<span>{{info}}</span>{{model.textAfter}}
	</span>


</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				timer: null,
				info: '',
				times: 0, //当前是第多少次
				timesTop: 0, ////定时总长度（秒）
				currentTime: 0, //用于倒计时
				endTime: 0,
				//timeLong:1 

			}
		},

		methods: {
			start() {
				if (this.model.run != 'Y') { //切换状态，监听器中回调用此方法,避免被重复执行
					this.setData('run', 'Y')
					return
				}

				this.initTimer()
				this.doEvent({
					eventName: 'taskStart'
				}, '$timer_taskstart')

			},
			stop() {
				clearInterval(this.timer)
				if (this.model.run != 'N') { //切换状态，监听器中回调用此方法,避免被重复执行
					this.setData('run', 'N')
					return
				}
				if (this.model.percentStop == 'bottom') {
					this.setData('percentage', 0)
				} else if (this.model.percentStop == 'top') {
					this.setData('percentage', 100)
				}
				this.doEvent({
					eventName: 'taskStop'
				}, '$timer_taskstop')
			},
			setInfo(ts) {
				let model = this.model
				let info = ''
				//let ts = model.timeType === 'interval' ? this.timer : this.timesTop - this.timer

				let s = parseInt(ts % 60)
				let m = (parseInt(ts / 60))
				if (model.timeUnit == 'S') {
					info = s > 9 ? s : '0' + s
				} else if (model.timeUnit == 'M') {
					info = (m > 9 ? m : ('0' + m)) + ':' + (s > 9 ? s : '0' + s)
				} else {
					let h = parseInt(m / 60)
					m = m % 60
					info = (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m) + ':' + (s > 9 ? s : '0' + s)
				}
				this.info = info
				this.setData('timeStatus', info)
				this.setData('percentage', Math.round(ts * 100.0 / this.timesTop, 2))
				this.setData('currentTime', this.$logic.date.getString(new Date()))


			},
			initTimer() {
				clearInterval(this.timer) //清除原计时器

				let model = this.model
				let timeLong = model.timeLong || 0
				let unit = model.timeUnit || 'M'
				this.timesTop = unit === 'S' ? timeLong : unit === 'H' ? timeLong * 60 * 60 : timeLong * 60

				this.times = 0
				this.currentTime = new Date().getTime()
				this.endTime = this.currentTime + this.timesTop * 1000
				this.setData('timeEnd', this.$logic.date.toDateTimeString(new Date(this.endTime)))
				this.timeTask(model.firstDo === 'Y')

			},
			async timeTask(isDo) { //时间间隔类的按次数，倒计时的按系统时间取值以避免时间误差
				let model = this.model
				if (model.run !== 'Y' || this.timesTop < 1) {
					return
				}

				let reach = false
				let tm = 0
				if (model.timeType === 'interval') {
					this.times++
					tm = this.times
					reach = this.times >= this.timesTop
				} else {
					this.currentTime = new Date().getTime()
					tm = (this.endTime - this.currentTime) / 1000
					reach = this.currentTime >= this.endTime
				}
				this.setInfo(tm)

				if (reach) { //计时重头开始
					this.times = 0
					this.endTime = this.currentTime + this.timesTop * 1000
				}
				if (!this.isedit && reach || isDo) {
					this.times = 0
					try {
						let check = await this.doEvent({
							eventName: 'timerbefor'
						}, '$timer_befor')
						if (check !== false) {
							await this.doEvent({
								eventName: 'timertask'
							}, '$timer_task')

						}
						if (reach && model.taskLoop === 'N') { //时间到达后且不循环
							return //跳过继续setTimeout
						}
					} catch (ex) {
						this.$logic.tip.error('自动加载数据异常：' + ex)
					}
				}

				if (model.timeLong > 0 && model.run === 'Y') { //未开始或未设置周期退出,设计模式只运行一次
					this.timer = setTimeout(() => {
						this.timeTask(false)
					}, 1000)
				}
			}
		},
		watch: {
			'model.run'(newValue, oldValue) {
				this.timer = 0
				if ('Y' === newValue) {
					this.start()
					//this.initTimer()
				} else {
					//clearInterval(this.timer)
					this.stop()
				}
			},
			/* 			'model.long'(newValue, oldValue) {
							if (newValue && newValue > 0) {
								if (this.timer == 0) { //未运行状态启动
									this.timeTask()
								} else {
									this.timer == 0 //已运行状态从头开始

								}
							}
						} */
		},
		computed: {

		},
		beforeUnmount() {
			clearInterval(this.timer)
			if (!this.isedit) {
				this.doEvent({
					eventName: 'afterDestroy'
				}, '$hook_destroy')
			}
		},
		created() {
			if (this.isedit) {

			} else {
				this.model.$start = this.start
				this.model.$stop = this.stop
			}
			if (this.model.run === 'Y') {
				this.start()
			}

			//this.initTimer()

		}
	}
</script>

<style scoped>

</style>