import config from './config/config.js'
import compBase from './config/public/comp.js'
import CommEvent from './commevent.js'
import elementBase from './elementBase.js'
export default {
	mixins: [CommEvent, elementBase],
	props: {
		model: {
			type: Object,
			default: null,
		},
		datas: {
			type: Object,
			default: null,
		},
		isedit: {
			type: Boolean,
			default: false,
		},
		host: { //父页面
			type: Object,
			default: null,
		},
		page: {
			type: Object,
			default: null,
		},
		project: {
			type: Object,
			default: null,
		},


	},
	data() {
		return {
			numFormat: new Intl.NumberFormat('en-US')
		}
	},

	methods: {
		valueValidate(dataName, value, validate) { //如果返回非空值即校验不通过
			let info = null
			let success = true
			//校验长度

			if (validate.type == 'int') {
				if (validate.range.min !== null) {
					if (value !== null && value !== '') {
						let iv = parseInt(value)
						if (isNaN(iv) || iv < validate.range.min) {
							success = false
							info = dataName + '必须是有效数据且不小于' + validate.range.min

						}

					} else {
						success = false
						info = dataName + '不允许为空'

					}

				}
				if (validate.range.max !== null) {
					if (value !== null && value !== '') {
						let ivm = parseInt(value)
						if (isNaN(ivm) || ivm > validate.range.max) {
							success = false
							info = dataName + '必须是有效数据且不大于' + validate.range.max

						}

					} else {
						success = false
						info = dataName + '不允许为空'

					}
				}
			} else if (validate.type == 'num') {
				if (validate.range.min !== null) {
					if (value !== null && value !== '') {
						let fv = parseFloat(value)
						if (isNaN(fv) || fv < validate.range.min) {
							success = false
							info = dataName + '必须是有效数据且不小于' + validate.range.min

						}

					} else {
						success = false
						info = dataName + '不允许为空'

					}
				}
				if (validate.range.max !== null) {
					if (value !== null && value !== '') {
						let fvm = parseFloat(value)
						if (isNaN(fvm) || fvm > validate.range.max) {
							success = false
							info = dataName + '必须是有效数据且不大于' + validate.range.max

						}

					} else {
						success = false
						info = dataName + '不允许为空'

					}
				}
			} else if (validate.type == 'array') { //复选框类
				if (validate.range.min !== null) {
					if (value == null || value.length < validate.range.min) {
						success = false
						info = dataName + '至少需要' + validate.range.min + '个选项' //+ value

					}
				}
				if (validate.range.max !== null) {
					if (value !== null && value.length > validate.range.max) {
						success = false
						info = dataName + '最多可选' + validate.range.max + '个选项'

					}
				}
			} else {
				if (validate.range.min !== null) {
					if (value === null || value === '' || value === undefined || typeof(value) == 'string' && value
						.length < validate.range.min) {
						success = false
						if (validate.range.min == 1) {
							info = dataName + '不允许为空' //+ value
						} else {
							info = dataName + '至少' + validate.range.min + '位长度' //+ value
						}


					}
				}
				if (validate.range.max !== null) {
					if (value !== null && typeof(value) == 'string' && value.length > validate.range.max) {
						success = false
						info = dataName + '当前长度' + value.length + '最多' + validate.range.max + '位长度'

					}
				}
			}
			if (!success) { //验证错误的情况下如果有自定义的提示用自定义的
				if (validate.baseInfo) {
					info = validate.baseInfo
				}
			}
			if (success && value && validate.reg.regexp && typeof(value) == 'string') { //检验文本
				let patt = new RegExp(validate.reg.regexp);
				let test = patt.test(value)
				if (!test) {
					success = false
					info = dataName + (validate.reg.regInfo ? validate.reg.regInfo : '内容格式错误')

				}
			}
			//自定义校验规则
			if (success && validate.customExpress && this.page && this.page.$page) {

				try {
					let express = validate.customExpress
					let nodeMap = this.page.$nodeMap
					let pageUtil = this.page.$page
					let checkResult = true
					let vars = ''
					//const descriptors = Object.getOwnPropertyDescriptors(pageUtil)
					for (let key in nodeMap) { //descriptors) {
						if (key.indexOf('$') < 0) {
							vars = vars + 'let ' + key + '=nodeMap[\'' + key + '\']._proxy;\n'
						}
					}
					let js = `let page = pageUtil; 
					${vars}
					checkResult=${express}; `
					eval(js)
					if (!checkResult) {
						success = false
						info = dataName + (validate.customInfo ? validate.customInfo : '自定义校验规则未通过')
					}
				} catch (ex) {
					success = false
					info = '自定义校验错误：' + ex
				}
				//console.log(success,info)

			}
			return {
				success: success,
				info: info
			}
		},
		validate() {
			let model = this.model
			/* 			if (!'validateSet' in model || this.model.status != '') { //没有校验规则或非编辑状态的不处理
							return null
						} */
			if (!model.isInput) { //非输入型元素不作处理
				return {
					success: true
				}
			}

			if (model.ignoreHide === 'Y') { //如果已开启可见状态才作校验validate
				let obj = model
				while (obj) { //向上推，只要父元素不可见自己就是不可见
					if (obj.show != 'show') {
						return {
							success: true
						}
					}
					obj = obj.parent
				}
			}

			let trigers = model.validateSet.triggers //如果没有触发器设置跳过验证
			if (trigers == null || trigers.length < 1) {
				return {
					success: true
				}
			}
			let configs = this.getConfigs('validate')
			//let check = 'Y'
			//let info = null
			let ck = {
				success: true,
				info: ''
			}
			for (let item of configs) {

				let value = model.isInput == 'M' ? model[item.attribute] : model.$value() //如果是单值，调用取值函数，否则取验证配置项指向的属性
				let validate = model[item.key]
				let dataName = item.name || model.name
				ck = this.valueValidate(dataName, value, validate)
				if (!ck.success) {
					break
				}
			}
			if (this.model.tipAllow == 'Y') {
				this.model.tipContent = ck.info
				this.model.tipShow = ck.success ? 'N' : 'Y'
				//	console.log(this.model.tipContent )
			}


			return ck
		},
		flushData(event, eventKey) { //有可能是成功也可能是失败,具体组件中自行处理逻辑
			if (this.model.$flushData) { //具体类型组件运行是时create事件中在模型中设置此事件,事件中应调用一次用户事件
				this.model.$flushData(event, eventKey)
			} else {
				this.doEvent(event, eventKey)
			}

		},
		getConfigs(type) {
			let confs = config.objs[this.model.type].configs
			if (type) {
				let rs = []
				for (let item of confs) {
					if (item.type == type) {
						rs.push(item)
					}
				}
				return rs
			} else {
				return confs
			}
		},
		$value(value) { //读取或设置输入型表单元素的值
			return '未实现的方法'
		},

		formatNum(num, fullTail) { //是否保留完整小数，对于超过三位以上的小数
			if (typeof(num) == 'number') {
				return this.numFormat.format(num)
			} else {
				let val = parseFloat(num)
				if (isNaN(val)) {
					return num //'NaN'
				} else {
					return this.numFormat.format(num)
				}
			}

		},
		toDate(dt) {
			if (typeof(dt) === 'string') {
				let ds = dt.replace(/-/g, '/') //兼容苹果浏览器的转换
				return new Date(ds)
			} else {
				return dt
			}

		},
		toDateString(dt) {
			if (dt == null) {
				return ''
			}
			let ds = dt.getFullYear()
			let m = dt.getMonth() + 1
			let d = dt.getDate()
			let ms = m < 10 ? '0' + m : m
			let Ds = d < 10 ? '0' + d : d
			return ds + '-' + ms + '-' + Ds

		},
		toTimeString(dt) {
			if (dt == null) {
				return ''
			}
			let h = dt.getHours()
			let M = dt.getMinutes()
			let s = dt.getSeconds()
			let hs = h < 10 ? '0' + h : h
			let Ms = M < 10 ? '0' + M : M
			let ss = s < 10 ? '0' + s : s
			return hs + ':' + Ms + ':' + ss
		},
		toDateTimeString(dt) {
			if (dt == null) {
				return ''
			}
			let dates = this.toDateString(dt)
			let times = this.toTimeString(dt)

			return dates + ' ' + times
		},
		toInt(str) {
			let v = str && str.length > 0 ? str : '0'
			let r = parseInt(v)
			return r
		},
		toFloat(str) {
			let v = str && str.length > 0 ? str : '0'
			let r = parseFloat(v)
			return r
		},

		beforCreate(newComp) {
			let group = config.compMap[newComp.type][3]
			if (this.model.notCreate || group != 'base') { //组件之上只允许创建简单组件
				return false
			}
			return true //组件元素上创建对象，在当元素之前插入元素，可以为表单元素可以为容器.//默认不可能在此上面创建元素，具体要接受创建的元素可覆盖此方法
		},
		afterCreate(newObj) {

		},

		createObj(newComp, parent) {
			if (this.isedit && newComp.show) {
				let p = parent || this.model
				if (this.beforCreate(newComp)) {
					let newObj = config.create(newComp.type, p, this.page.formData)
					this.afterCreate(newObj)
					return newObj
				} else {
					this.$logic.tip.warning('不允许在' + p.name + '类型元素上创建' + newComp.text + ',请在容器上创建表单元素')
					return null
				}
			}
		},
		toValidate(eventName) {

			if (this.model.validateSet && this.model.validateSet.triggers.indexOf(eventName) > -1) {
				this.validate()
			}


		},
		onChange(value, paramName = 'value') {
			if (this.isedit) {
				return
			}
			if (paramName in this.model) {
				this.$nextTick(() => {
					this.linkUpdate(paramName)
				})

			}
			if (this.model.tipShow == 'Y') {
				this.model.tipShow = 'N'
			}
			this.toValidate('change')
			this.doEvent({
				eventName: 'change',
				value: value
			}, '$event_change')
		},
		onInput(value) {
			if (this.isedit) {
				return
			}
			this.toValidate('input')
			this.doEvent({
				eventName: 'input'
			}, '$event_input')
		},

		onBlur(e) {
			if (this.isedit) {
				return
			}
			this.toValidate('blur')
			this.doEvent(e, '$event_blur')
		},
		onFocus(e) {
			if (this.isedit) {
				return
			}
			if (this.model.tipShow == 'Y') {
				this.model.tipShow = 'N'
			}
			this.doEvent(e, '$event_focus')
		},


		mouseClick(e) {
			if (this.isedit) {
				let el = this.model
				if (el.parentSlot) {
					el = el.parent
				}
				this.$store.commit('setElement', el)
			} else {
				if (this.model.tipShow == 'Y') {
					this.model.tipShow = 'N'
				}

				this.doEvent(e, '$event_click')
			}
		},
		onKeyDown(e) {
			if (this.isedit) {
				return
			}

			this.doEvent(e, '$event_keydown')
		},
		onKeyUp(e) {
			if (this.isedit) {
				return
			}
			this.doEvent(e, '$event_keyup')
		},
		onKeyPress(e) {
			if (this.isedit) {
				return
			}
			this.doEvent(e, '$event_keypress')
		},
		setCurrentElement(element) {
			if (this.currentElement === element) {

			} else {
				this.$store.commit('setElement', element)
			}
		},


		/* 		mouseDbClick(e) {
					if (this.isedit) {
						return
					}
					this.doEvent(e, '$event_dblclick')
				},

				mouseEnter(e) {
					if (this.isedit) {
						return
					}
					this.doEvent(e, '$event_mouseenter')
				}, */

		dataTo(targets, data) { //targets:#1,#2,#3 //此方法一般只对列表类组件有效，在文件上传和编辑表格中会调用
			if (!data || !targets) {
				return
			}

			if (targets.length > 0) {
				let ids = targets.split(',')
				for (let key of ids) {
					if (!(key && key.length > 0)) {
						continue
					}
					/* if (key.indexOf('#') !== 0) { //容错处理
						key = '#' + key
					} */
					//let obj = this.page.$(key)
					let obj = this.$logic.pageUtil.getElementById(this.page.formData, key)
					//console.log(obj,this.page.formData,key)
					if (obj) {
						if (obj.$setList) { //优先给列表类赋值
							obj.$setList(data)
						} else if (obj.$value) { //其次单值传值
							obj.$value(data)
						}


						/* else if (obj.$bind) {//其次
							obj.$bind(data)
						} */

					}
				}
			}
		},

		getDataList(data) {

			if (data) {
				if ((typeof data) == 'string') {
					try {
						return JSON.parse(data)
					} catch (e) {
						setTimeout(() => { //下一个周期弹出提示，否则VUE会出异常
							this.$logic.tip.error(this.model.name + '[' + (this.model.value || '') +
								']JSON数据格式错误')
						}, 100)
						return []
					}

				} else {
					return data
				}
			} else {
				return []
			}
		}

	},
	computed: {
		numberValue: {
			get() {
				return this.toFloat(this.model.value)
			},
			set(v) {
				this.model.value = v
			}

		},

		dataList() { //用计算属性使得既可前端静态设置又兼容API取值
			let data = this.model.listData || this.model.jsonData; //先兼容此配置，改完后去掉
			return this.getDataList(data)

		},

		baseStyle() { //组件外壳样式，只处理宽、高、边距
			let css = {}
			let model = this.model
			let confs = config.objs[model.type].configs //config.fontConfig.concat(compBase.base)
			for (let item of confs) { //只处理容器类属性
				if ('M' === model.mode) { //组元素不处理边距属性
					if ('width,height,'.indexOf(item.key) < 0) {
						continue
					}
				} else { //只处理容器类属性
					if ('width,minWidth,minHeight,maxWidth,maxHeight,height,marginT,marginB,marginR,marginL,overH,overV,position,borderS,borderL,borderT,borderR,borderB,borderColor'
						.indexOf(
							item.key) < 0) {
						continue
					}
				}

				if (item.target && item.target != '') { //可以直接输入样式的属性
					let val = model[item.key]
					if (val && val.length > 0) { //有值且不以0开头才进行设置&& val.indexOf('0') != 0
						css[item.target] = val
					}
				}

			}


			//组件宽度分三类：1、伸缩性如文本框，实际宽度由容器设置，组件宽度100%，2、固定尺寸，如图片，3无宽度如按钮
			//像素宽度原样输出,百分比宽度只输出100%忽略其它值，无宽度不输出
			if (model.width) {
				if (model.width.indexOf('%') > 0) {
					if (model.width == '100%') { //100%的情况下减去margin		
						let width = 'calc( ' + model.width
						if (model.marginL) {
							width = width + ' - ' + model.marginL
						}
						if (model.marginR) {
							width = width + ' - ' + model.marginR
						}
						width = width + ' ) '
						css.width = width
					} else { //不是象素值的忽略容器宽度,//.indexOf('px')<0		
						//delete css.width
					}
				} else { //指定象素宽度的情况加上margin,保证元素宽度为指定宽度
					let width = 'calc( ' + model.width
					if (model.marginL) {
						width = width + ' + ' + model.marginL
					}
					if (model.marginR) {
						width = width + ' + ' + model.marginR
					}
					width = width + ' ) '
					css.width = width
				}
				css['--element-width'] = css.width
			}
			if (model.height) {
				if (model.height.indexOf('%') > 0) {
					let height = 'calc( ' + model.height
					if (model.marginT) {
						height = height + ' - ' + model.marginT
					}
					if (model.marginB) {
						height = height + ' - ' + model.marginB
					}
					height = height + ' ) '
					css.height = height
				} else {
					let height = 'calc( ' + model.height
					if (model.marginT) {
						height = height + ' + ' + model.marginT
					}
					if (model.marginB) {
						height = height + ' + ' + model.marginB
					}
					height = height + ' ) '
					css.height = height
				}
			}

			if (this.model.show == 'hidden') {
				css['visibility'] = 'hidden'
			} else {
				css['visibility'] = 'visible'
			}
			return css
		},
		compStyle() {
			let css = {}
			let model = this.model
			let confs = config.objs[model.type].configs //config.fontConfig.concat(compBase.base)
			for (let item of confs) {
				//容器边距属性跳过

				if ('M' === model.mode) { //组元素带边距样式,不带大小样式,具有大小的组件应在数据中设置
					if ('width,height,'.indexOf(item.key) > -1) {
						continue
					}
				} else { //组件元素样式忽略容器类属性,如需要的组件中另外设置属性名称
					if ('marginT,marginB,marginR,marginL,margin,overH,overV,position,borderS,borderL,borderT,borderR,borderB,borderColor'
						.indexOf(item.key) > -1) {
						continue
					}
				}

				if (item.target && item.target != '') { //可以直接输入样式的属性					
					let val = model[item.key]
					if (val && val.length > 0) { //有值且不以0开头才进行设置&& val.indexOf('0') != 0
						css[item.target] = val
					}

				}

			}
			if (this.isedit) {
				css.margin = '1px' //
			}

			return css

		},


		isCurrent() {
			return this.currentElement === this.model
		},
		isHover() {
			return this.hoverElement === this.model
		},
		hoverElement() {
			return this.$store.state.hoverElement
		},
		newComp() {
			return this.$store.state.newComp
		},
		currentElement() {
			return this.$store.state.element
		},
		elementConfig() {
			return config
		}
	},
	watch: {
		datas(newValue, oldValue) {
			if (this.superComp) { //防止被父组件与子组件多次调用此方法 ,superCompd在BaseElement中定义
				return
			}

			//this.bindData = newValue 非容器组件不存在自身的数据，只从上级组件继承datas
			this.setBindData(newValue)
		},
		/* 	datas: {
				deep: true,
				handler(nv, ov) {
					if (this.superComp) { //防止被父组件与子组件多次调用此方法 ,superCompd在BaseElement中定义
						return
					}
					console.log('Y')
					this.setBindData(nv)
				} 

			}*/

	},
}