import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
let colls=[{label:'折叠',value:'collapse'},{label:'不折叠',value:''}] 
let leafTypes=[{label:'空格补齐',value:'fill'},{label:'跨列补齐',value:'span'}] 
let heads=JSON.stringify(
[{label:'一级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
{label:'二级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
{label:'三级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
{label:'四级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
{label:'五级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
{label:'六级',width:'100px',vAlign:'middle',hAlign:'center',bgColor:''},
]
)
let cs=[
{key:'title',text:'表格属性'},
/* {key:'width',text:'表格宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'表格高度',type:'text',init:'',target:'width',bursh:true,bind:true,tip:'',list: null}, */
{key:'trH',text:'行高',type:'text',init:'40px',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdP',text:'内间距',type:'text',init:'10px',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdH',text:'格内横向',type:'select',init:'center',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
{key:'tdV',text:'格内纵向',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
{key:'lineShow',text:'显示网格线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'lineColl',text:'线条折叠',type:'select',init:'collapse',target:'border-collapse',bursh:true,bind:true,tip:'',list: colls},
//{key:'leftAuto',text:'左侧动态',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
//{key:'leftHeads',text:'左侧动态列',type:'json',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'leafType',text:'对齐方式',type:'select',init:'fill',target:'',bursh:true,bind:true,tip:'',list: leafTypes},
{key:'backColor',text:'背景色',type:'color',init:'#90EE90',target:'backgroundColor',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},	 


]

let head= {
	data:{
		type:'head',width:'100%',height:'',display:'',fontBold:'bold'
	},
	delete:'lineColl,lineSize,lineShow,alignH,alignV,height,minH,overH,overV,paddingT,paddingB,paddingL,paddingR,',
	hide:'',
	base:cs.concat(font) ,
	special: [	{key: 'leftHeads',text: '左侧动态列',type: 'json',init: heads,	target: '',	extend: false,tip: '',treeMap:{id:'label',label:'label',icon:'icon'},dataMap: {label:'标题文本',width:'列宽',hAlign:'水平对齐',vAlign:'垂直对齐',bgColor:'背景色'}},],
	action:[]
}

export default head