class DateUtil {
	//返加当前时间偏若干天的日期
	getDate(offSet) {
		let today = new Date();
		let times = today.getTime() + offSet * 24 * 60 * 60 * 1000;
		today.setTime(times);
		return today;
	}
	getDateRange(startOffset, endOffset, mode) {
		//mode:0实际值，1至少当月的整月超出部分实际值，2截止日的整月
		let today = new Date();
		let month = today.getMonth();
		//let day = today.getDate();
		let dateS = this.getDate(startOffset);
		if (month == dateS.month) {
			//如果在同一个月内，起始日期从1开始
			dateS.setDate(1);
		}

		let dateE = this.getDate(endOffset);
		let monthE = dateE.getMonth();
		let dayE = dateE.getDate();
		let monthLast = this.getMonthLastDay(monthE + 1);

		if (mode == 1) {
			if (month == monthE && dayE < monthLast) {
				//如果截止日期是当月且日期不在最后一天，调到最后一天,保证本月完整
				dateE.setDate(monthLast);
			}
		} else if (mode == 2) {
			dateE.setDate(monthLast);
		}
		return {
			start: dateS,
			end: dateE
		};
	}
	getMonthLastDay(month) {
		let days = 0;
		switch (month) {
			case 1:
			case 3:
			case 5:
			case 7:
			case 8:
			case 10:
			case 12:
				days = 31;
				break;
			case 2:
				let date = new Date()
				let year = date.getFullYear();
				if (year % 4 == 0) {
					days = 29;
				} else {
					days = 28;
				}
				break;
			case 4:
			case 6:
			case 9:
			case 11:
				days = 30;
				break;
		}
		return days;
	}



	toDate(dt) {
		let ds = dt.replace(/-/g, '/') //兼容苹果浏览器的转换		
		return new Date(ds)
	}
	toDateString(dt) {
		if (dt == null) {
			return ''
		}
		if (typeof(dt) === 'string') {
			return dt
		}
		let ds = dt.getFullYear()
		let m = dt.getMonth() + 1
		let d = dt.getDate()
		let ms = m < 10 ? '0' + m : m
		let Ds = d < 10 ? '0' + d : d
		return ds + '-' + ms + '-' + Ds

	}
	toTimeString(dt) {
		if (dt == null) {
			return ''
		}
		if (typeof(dt) === 'string') {
			return dt
		}
		let h = dt.getHours()
		let M = dt.getMinutes()
		let s = dt.getSeconds()
		let hs = h < 10 ? '0' + h : h
		let Ms = M < 10 ? '0' + M : M
		let ss = s < 10 ? '0' + s : s
		return hs + ':' + Ms + ':' + ss
	}
	toDateTimeString(dt) {
		if (dt == null) {
			return ''
		}
		if (typeof(dt) === 'string') {
			return dt
		}
		let dates = this.toDateString(dt)
		let times = this.toTimeString(dt)

		return dates + ' ' + times
	}
	getString(date) {
		var year = date.getFullYear();

		// 注意：JavaScript的月份是从0开始的，所以需要+1  
		var month = (1 + date.getMonth()).toString().padStart(2, '0');

		// 获取日期并转换为两位数  
		var day = date.getDate().toString().padStart(2, '0');

		// 获取小时、分钟和秒，并转换为两位数  
		var hour = date.getHours().toString().padStart(2, '0');
		var minute = date.getMinutes().toString().padStart(2, '0');
		var second = date.getSeconds().toString().padStart(2, '0');

		// 返回格式化的字符串  
		return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
	}
	getFirstDayOfWeek(date) {
		// 复制传入的日期对象，避免修改原始对象  
		var firstDay = new Date(date.getTime());

		// 将日期设置为该周的第一天（周日）  
		// JavaScript中的getDay()方法返回的是0（周日）到6（周六）之间的数字  
		// 因此，如果getDay()返回的不是0（周日），我们需要减去相应的天数来得到周日  
		var day = firstDay.getDay();
		if (day !== 0) { // 如果不是周日  
			firstDay.setDate(firstDay.getDate() - day); // 减去天数以回到周日  
		}
		firstDay.setHours(0, 0, 0, 0);
		return firstDay;
	}
	getLastDayOfWeek(date) {
		// 复制传入的日期对象，避免修改原始对象  
		var lastDay = new Date(date.getTime());

		// 将日期设置为该周的最后一天（周日）  
		var day = lastDay.getDay();
		if (day !== 0) { // 如果不是周日（即不是一周的开始）  
			// 如果是周六（getDay()返回6），则需要加1天才能到达周日  
			// 否则，加上（7-当前星期几）来得到周日  

		}
		lastDay.setDate(lastDay.getDate() + (6 - day)); //周日开头的情况 ，始终算上偏移量
		lastDay.setHours(23, 59, 59, 999);

		return lastDay;
	}
	getFirstDateOfMonth(date) {
		// 复制传入的日期对象，避免修改原始对象  
		var firstDay = new Date(date.getTime());

		// 设置日期为1，即为该月的第一天  
		firstDay.setDate(1);
		firstDay.setHours(0, 0, 0, 0);
		return firstDay;
	}
	getLastDateOfMonth(date) {
		// 复制传入的日期对象，避免修改原始对象  
		var lastDay = new Date(date.getTime());

		// 设置日期为下一个月的第一天  
		lastDay.setMonth(lastDay.getMonth() + 1, 1); // 注意：月份是从0开始的，所以加1表示下个月  

		// 减去一天，得到当前月的最后一天  
		lastDay.setDate(lastDay.getDate() - 1);
		lastDay.setHours(23, 59, 59, 999);
		return lastDay;
	}
	addMonthsToDate(date, monthsToAdd) {
		// 创建一个新的Date对象，以免改变原始对象  
		let newDate = new Date(date);
		// 使用setMonth方法来设置新的月份  
		// 注意：月份是从0开始的，所以我们需要+1来得到实际的月份值  
		let newMonth = (newDate.getMonth() + monthsToAdd) % 12;
		let offYear = (newDate.getMonth() + monthsToAdd) / 12
		offYear = Math.floor(offYear)
		// 如果月份小于0，说明需要增加年份  

		newDate.setFullYear(newDate.getFullYear() + offYear); // 减少一年  
		if (newMonth < 0) {
			newMonth = 12 + newMonth; // 将月份回环到正确的值 
		}

		newDate.setMonth(newMonth); // 设置新的月份  
		return newDate;
	}
}
export default new DateUtil()