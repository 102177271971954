import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'

let datas =JSON.stringify( [
		{id:0,pid:null,label:'根节点',icon:'far fa-home-lg-alt',color:null,data:{} },
		{id:1,pid:0,label:'节点1',icon:'fad fa-user-alt',data:{} },
		{id:2,pid:0,label:'节点2',icon:'fas fa-user-alt',color:'#ff0000',data:{} },
		{id:3,pid:0,label:'节点3',icon:'far fa-user-alt',color:'#00ff00',data:{} },
		{id:4,pid:0,label:'节点4',icon:'far fa-user-alt',data:{} },
		{id:11,pid:1,label:'节点1.1',icon:'fas fa-user-alt',data:{} },
		{id:12,pid:1,label:'节点1.2',icon:'far fa-user-alt',data:{} },
		{id:21,pid:2,label:'节点2.1',icon:null,data:{} },
		{id:22,pid:2,label:'节点2.2',icon:null,data:{} },
	 	 
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'id',label:'节点ID',type:'num',default:null,list:[],field:'',width:'16%',unique:true},
	{key:'pid',label:'节点父ID',type:'num',default:null,list:[],field:'',width:'16%',unique:false},
	{key:'label',label:'节点文本',type:'text',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'icon',label:'节点图标',type:'icon',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'color',label:'节点颜色',type:'color',default:'',list:[],field:'',width:'16%',unique:false},
	//{key:'data',label:'自定义数据',type:'hide',default:null,list:[],field:'',width:'16%',unique:false}, 
 
]

let events=[
	{key: '$comp_nodeclick',text: '节点点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '元素销毁前触发',list: null	},
	{key: '$comp_nodeadd',text: '节点添加',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '应使用同步方法，返回新节点对象生效:{id:数字或字符串,label:可无,icon:可无,data:可无}',list: null	},
	{key: '$comp_noderename',text: '节点修改',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
	{key: '$comp_deletebefore',text: '节点删除前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '函数内涉及数据库交互的数据验证应使用同步方法,如果返回false将阻止节点删除',list: null	},

	{key: '$comp_nodedelete',text: '节点删除',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
	{key: '$comp_nodecheck',text: '节点勾选',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '发生勾选或取消时触发',list: null	},
	{key: '$comp_checkdrop',text: '拖动前检测',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '节点拖放前检测是否允许，返回false禁止拖放，不设置或true表示允许',list: null	},
	
	{key: '$comp_nodedroped',text: '节点拖动完',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '节点拖动完成后触发',list: null	},
	//细化的事件预留
	//{key: '$comp_nodedropend',text: '节点拖动后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '元素销毁前触发',list: null	},
	
]
let base = [
{key: 'title',text: '树型控件'	},
{key: 'nodeId',text: '当前节点Id',type: 'text',init: null,	target: '',bursh:true,bind:true,tip: '只读属性，JSON数据对象，运行时有效',list:null},	
{key: 'nodeLabel',text: '当前节点文本',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '只读属性，JSON数据对象，运行时有效',list:null},	

{key: 'currentNode',text: '当前节点',type: 'json',init: '',	target: '',bursh:true,bind:true,tip: '只读属性，JSON数据对象，运行时有效',list:null},	
{key: 'subSelf',text: '子节点排顶',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '点击节点时反馈子节点Id集体，是否排除当前节点自身',list: options.YON},	
{key: 'subIds',text: '子节点集合',type: 'json',init: '[]',	target: '',bursh:true,bind:true,tip: '当前节点下的所有子节点集合',list: options.YON},	

{key: 'allowSearch',text: '允许查找',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	

//{key:'edit',text:'编辑功能',type:'text',init:'add,rename,delete',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key: 'allowCheck',text: '允许勾选',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'checked',text: '默认勾选',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '逗号分隔字符串或数组',list:null},	
{key: 'expandAll',text: '展开全部',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'expanded',text: '默认展开',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '逗号分隔字符串或数组',list: null},	
{key: 'expandClick',text: '点击时展开',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'accordion',text: '手风琴模式',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'allowDrag',text: '允许拖拽',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'deleteEmpty',text: '仅删空节点',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
			
 
			
			




]
 
  
let treeview = {
	data: {
		type: 'treeview',fontColor:'#404040',marginT:'1px',marginB:'1px',marginL:'1px',marginR:'1px'
	},
	delete: '',
	hide: 'width,height,checked,expandAll,dataOption',
	base: base.concat(compBase.base),
	special: [
	//	{key: 'allowManage',text: '启用编辑',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},
		{key: 'allowAdd',text: '允许新增',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},
		{key: 'allowEdit',text: '允许修改',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},
		{key: 'allowDelete',text: '允许删除',type: 'switch',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},
        {key: 'title',text: '自动数据维护'	},
		{key: 'autoManage',text: '启用自维护',type: 'switch',init: 'N',	target: '',bursh:true,bind:true,tip: '如果开启此选项将自动实现增、删、改功能，按钮点击事件将失效',list: options.YON},
		{key:'pageKey',text:'编辑页面',type:'page',init:'',target:'',bursh:true,bind:true,tip:'如果开启增加、删除、修改,由平台自动实现的功能，必须设定数据编辑的表单页面',list: null},
		{key:'pidParam',text:'Pid参数名',type:'text',init:'pid',target:'',bursh:true,bind:true,tip:'由平台传递给编辑页面的上级id参数名，编辑页面中必须有一个对应上级ID的组件数据绑定此参数',list: null},
		{key:'exParam',text:'自定义传参',type:'text',init:'',target:'',bursh:true,bind:true,tip:'将节点数据中的自定义数据附加传递给编辑的子页面，数据名为数据库查询返回的字段名，多个数据名采用逗号分隔,向子页面传参时，父节点的参数名以$+字段名，当前节点参数名为字段字',list: null},

		{key:'editHeight',text:'编辑框高度',type:'text',init:'80vh',target:'',bursh:true,bind:true,tip:'',list: null},
		{key:'editWidth',text:'编辑框宽度',type:'text',init:'90vw',target:'',bursh:true,bind:true,tip:'',list: null},
	//	{key:'confirmInfo',text:'提交前提示',type:'text',init:'是否确定提交',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接提交表单',list: null},
	//	{key:'cancelInfo',text:'取消前提示',type:'text',init:'是否确定取消',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接退出编辑',list: null},
	//	{key:'deleteInfo',text:'删除前提示',type:'text',init:'是否确定删除',target:'',bursh:true,bind:true,tip:'如果不设置提示信息将直接删除数据',list: null},
		
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '树节点数据',type: 'jsonArray',init: datas,	target: '',bursh:false,bind:true,tip: '',attribute:'dataOption'},//treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',pKey:'上级节点标识',label:'标题文本',icon:'图标'}
		],
	action: events.concat([
		options.listLoadAfterEvents.dataListEvent,
		//options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default treeview
