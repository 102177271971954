import options from '../../public/options.js'

let list=[
	{key:'fieldName',text:'字段名称',type:'text',init:'',target:null,bursh:true,bind:false,tip:'',read:false,list: null},
	{key:'label',text:'标题文本',type:'text',init:'标题',target:null,bursh:true,bind:true,tip:'',list: null},
	{key:'width',text:'宽度',type:'text',init:'100px',target:null,bursh:true,bind:true,tip:'',list: null},
    {key:'minWidth',text:'最小宽度',type:'text',init:null,target:'--col-min-width',bursh:true,bind:true,tip:'',list: null},
 
	{key:'mode',text:'单元格类型',type:'select',init:'',target:null,bursh:true,bind:false,tip:'',read:true,list: options.toOptions('文本内容=text,数值内容=num,时间值内容=time,自定义=custom')},
	{key:'typeGroup',text:'字段类型',type:'select',init:'',target:null,bursh:true,bind:false,tip:'',read:true,list: options.toOptions('文本类型=text,数值类型=num,时间类型=time')},
	{key:'dataType',text:'数据类型',type:'text',init:'',target:null,bursh:true,bind:false,tip:'',read:true,list: null},
	{key:'totalType',text:'汇总方式',type:'select',init:'none',target:null,bursh:true,bind:false,tip:'只对树型报表且数值类型数据生效',list: options.toOptions('不汇总=none,求和=sum,最大值=max,最小值=min')},

	{key:'format',text:'数据格式化',type:'format',init:{before:'',after:''},target:'border-collapse',bursh:true,bind:true,tip:'',list: []},

	{key:'hAlign',text:'水平对齐',type:'select',init:'center',target:null,bursh:true,bind:true,tip:'',list: options.toOptions('左侧=left,中间=center,右侧=right')},
	{key:'vAlign',text:'垂直对齐',type:'select',init:'middle',target:null,bursh:true,bind:true,tip:'',list: options.toOptions('顶部=top,中间=middle,底部=bottom')},

//	{key:'before',text:'前置内容',type:'text',init:'',target:'',bursh:true,bind:true,tip:'对于非自定义类型生效',list: null},
//	{key:'after',text:'后置内容',type:'text',init:'',target:'',bursh:true,bind:true,tip:'对于非自定义类型生效',list: null},
	//{key:'color',text:'标题颜色',type:'color',init:'middle',target:'',bursh:true,bind:true,tip:'',list: null},
	//{key:'fontSize',text:'标题字号',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
	//{key:'template',text:'内容模板',type:'json',init:[],target:'',bursh:true,bind:true,tip:'',list: null},
    {key:'fontName',text:'标题字体',type:'select',init:'',target:'fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
    {key:'fontSize',text:'标题字号',type:'select',init:'',target:'fontSize',bursh:true,bind:true,tip:'',list:options.fontSizes},	
    {key:'fontColor',text:'标题颜色',type:'color',init:'',target:'color',tip:'',bursh:true,bind:true,list:null},	
    {key:'fontBold',text:'标题粗体',type:'select',init:'',target:'fontWeight',bursh:true,bind:true,tip:'',list:[{label:'默认',value:''},{label:'常规',value:'normal'},{label:'加粗',value:'bold'}]},
	{key:'order',text:'排序方向',type:'text',init:'asc',target:'',bursh:true,bind:true,tip:'',list: null},

	
]
	

let tableHead= {
	data:{
		type:'reportcol',display:'',width:'100%'
	},
	delete:'',
	hide:'template,order',
	base:list, 
	special: [	],

	action:[]
} 
export default tableHead