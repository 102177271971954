import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let labelTypes=[{label:'横向排列',value:'H'},{label:'纵向排列',value:'V'},{label:'不显示标签',value:'N'}]
let fieldConfigs=[
	{key:'title',text:'表单项属性'},
	{key:'autoWidth',text:'动态宽度',type:'autowidth',init:{xs:"50%",sm:"50%",md:"50%",lg:"50%"},target:null,bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
	
]
let formfield= {
	data:{
		type:'formfield',height:'',alignV:'stretch',minH:'20px',overH:'visible',overV:'visible',marginR:'20px',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'width',
	hide:'display,flexD,flexW,position,contentD,alignV,alignH,left,top,right,bottom,zIndex',
	base:fieldConfigs.concat( layout).concat(font) ,
	special :[



	
	 ],
	action:[]
}

export default formfield