<template>
	<div class="_wordsrotate" :style="wordsBack" ref="box" @mouseover="isStop= hoverStop? true:false"
		@mouseleave="isStop=false">
		<div :style="wordsBox">

			<div :class="'_word_item _rotbox'+ (isStop?' _rotstop':'')" :style="item.cssa" v-for="item in items"
				@click="itemClick(item,$event)">
				<div :data-name="item.text" data-code="8" :class="'_wordkey'+ (isStop?' _rotstop':'')"
					:style="item.cssb"></div>
			</div>


		</div>
	</div>

</template>

<script>
	import CommEvent from '../../../../commevent.js'
	import formBase from '../../../../formbase.js'
	export default {
		mixins: [formBase, CommEvent],
		props: ["words", "vertical", "hoverstop", "fontmax", "fontmin", "fontlight", "worditalic", "wordopacity",
			"speedmin", "speedmax",
			"backcolor", "chartid"
		],
		data() {
			return {
				isStop: false,
				hoverStop: true,
				wordsBack: {},
				wordsBox: {
					top: "50%",
					left: "50%"
				},
				keys: [
					["河北"],
					["山西"],
					["辽宁"],
					["吉林"],
					["黑龙江"],
					["江苏"],
					["浙江"],
					["安徽"],
					["福建"],
					["江西"],
					["山东"],
					["河南"],
					["湖北"],
					["湖南"],
					["广东"],
					["海南"],
					["四川"],
					["贵州"],
					["云南"],
					["陕西"],
					["甘肃"],
					["青海"],
					["台湾"],
					["内蒙古"],
					["广西"],
					["西藏"],
					["宁夏"],
					["新疆"],
					["北京"],
					["天津"],
					["上海"],
					["重庆"]
				],
				items: []

			}
		},

		methods: {
			itemClick(item, e) {
				if (this.isedit) {
					return
				}
				/* this.$emit("wordclick", {
					type: "word",
					word: item.text,
					data: item.data
				}) */
				//阻止冒泡
				if (e && e.stopPropagation) {
					e.stopPropagation(); // 非IE浏览器				
				} else {
					e.cancelBubble = true; //IE浏览器				
				}

				this.doEvent({
					eventName: 'wordClick',
					itemData: item.data
				}, '$comp_wordClick')
//console.log(this.model)


			},
			getFontSize(min, max) {
				if (min == max) {
					return min
				} else {
					return parseInt(min + Math.random() * (max - min))
				}

			},
			setItem() {
				let rs = []
				let keys = this.words ? this.words : this.keys
				/* console.log(this.words,keys)
				if (!keys || keys.length < 1) {
					
					return
				} */
				let div = this.$refs.box

				this.hoverStop = this.hoverstop == null || this.hoverstop ? true : false


				let wordVertical = !this.vertical || this.vertical == "vertical" ? true : false
				let fontMax = this.fontmax ? parseInt(this.fontmax) : 20
				let fontMin = this.fontmin ? parseInt(this.fontmin) : 20
				let wordItalic = this.worditalic || false
				let light = this.fontlight ? parseInt(this.fontlight) : 55
				let speedMax = this.speedmax ? parseInt(this.speedmax) : 55
				let speedMin = this.speedmin ? parseInt(this.speedmin) : 50
				let backColore = this.backcolor ? this.backcolor : "#000020"
				this.wordsBack = {
					background: backColore
				}


				let hsl = this.$logic.color.rgb16ToHsl(backColore)
				let bgColor = hsl[0]
				let vCenter = div.clientHeight / 2
				let heigth = parseInt((div.clientHeight));
				let width = parseInt(div.clientWidth / 4);
				let per = width * 4 / 680 //系数,转圈越大，圆顶底上下落差越大


				let maxFw = 0
				let minFw = 10000
				for (let i = 0; i < keys.length; i++) {
					let key = keys[i]
					let text = key[0]
					let fs = key.length > 2 ? key[2] == null ? this.getFontSize(fontMin, fontMax) : key[2] : this
						.getFontSize(fontMin, fontMax)
					let fh = wordVertical ? fs * text.length * per : fs * per
					let fw = wordVertical ? fs * per : fs * text.length * per
					maxFw = maxFw < fw ? fw : maxFw
					minFw = minFw > fw ? fw : minFw
					let hStep = (heigth - fh - heigth / 800 * 160 * per) / keys.length //预留最下部文字高度与圆高度的偏离量，与容器宽高相关
					let color = null
					if (Array.isArray(key) && key[1] && key[1].length > 0) {
						color = key[1]
					} else {
						let c = parseInt(Math.random() * 360)
						if (Math.abs(c - bgColor) < 40) { //避免与背景颜色相近
							if (c > bgColor) {
								c = (360 + (c + 40)) % 360
							} else {
								c = (360 + (c - 40)) % 360
							}

						}
						let bhd = parseInt(90 + 10 * Math.random())
						let ld = parseInt((light - 10) + 10 * Math.random())
						color = "hsl(" + c + "," + bhd + "%," + ld + "%)"
					}
					let sp = parseInt(speedMin + Math.random() * (speedMax - speedMin))
					sp = sp > 60 ? 60 : sp < 1 ? 1 : sp
					sp = 61 - sp //1秒到60秒
					let delay = -parseInt(Math.random() * (400 + keys.length * 10)) / 100
					let css = {
						animationDuration: sp + "s",
						animationDelay: delay + "s",
					}

					let top = parseInt(i * hStep + hStep * Math.random())
					top = top + heigth / 850 * 50 * per //旋转的上部圆高度与容器高度和宽度相关

					top = top - vCenter//+(Math.floor( vCenter*0.2))
					let css1 = {
						...css,
						marginTop: top + "px",
					}
					//let mg = wordVertical ? 0 : text.length * fs
					let left = -parseInt(width + width * Math.random() - fw) //+ 
					let css2 = {
						...css,
						marginLeft: left + "px",
						color: color,
						fontSize: fs + "px",
						opacity: this.wordopacity
					}
					if (wordVertical) {
						css2.width = "1px"
						css2.wordBreak = "break-all"

					} else {
						css2.width = "1px" // keys[i][0].length * fw * 1.5 + "px"
						css2.whiteSpace = "nowrap"
					}
					if (wordItalic) {
						css2.fontStyle = "italic"
					}
					rs.push({
						text: text,
						cssa: css1,
						cssb: css2,
						data: keys[i]
					})
				}

				this.wordsBox.left = "calc(50% - " + (maxFw + minFw) / 4 + "px)" //X轴心并不在中间，取平均内容宽度作偏移
				this.items = rs

			}

		},
		watch: {
			words(nv, ov) {
				this.setItem()
			},

			chartid(ov, nv) { //设计时刷新
				if (this.isedit) {
					this.setItem()
				}
			}
		},
		mounted() {
			//words()
			this.setItem()
		},
		created() {

		}
	}
</script>

<style>
	._word_item:hover{
		cursor: pointer;
	}
	._wordsrotate {
		overflow: hidden;
		height: 100%;
		width: 100%;
		min-height: 20px;
		perspective: 80em;
		/* 		background: #000; */
		font-family: "microsoft yahei";

	}

	._wordsrotate div {
		transform-style: preserve-3d;
	}

	._wordsrotate div,
	._wordkey :after {
		position: absolute;
	}

	._rotbox,
	._wordkey {
		animation: _rotY 0s linear infinite;
	}

	._wordkey:hover {
		font-style: italic;
		font-weight: bold;
	}

	._rotstop {
		animation-play-state: paused;
		-webkit-animation-play-state: paused;
	}


	._wordkey {
		animation-direction: reverse;
		/* 	animation-direction:normal; 设置成这个变成卡片效果*/
		letter-spacing: 0px;
	}

	._wordkey:after {
		transform: translate(-50%, -50%);
		content: attr(data-name);

	}

	@keyframes _rotY {
		to {
			transform: rotateY(1turn);
		}
	}
</style>