import options from '../../../../../public/options.js'
import initColor from '../../initColor.js'
import mapPoint from './mappoint.js'
 let initData=JSON.stringify( [
 	['名称', '类型', '颜色','大小','数值','经度','纬度','经度2','纬度2'],
 	['黑龙江', 'area', '#ffff00',null,,null,null,null,null,null],
// 	['江苏', 'area','#ffff00',null,null,null,null,null,null],
 	['吉林', 'area', '#00ff00',null,null,null,null,null,null],
// 	['云南', 'area', '#0000ff',null,null,null,null,null,null],
 	['台湾', 'area', '#ff00ff',null,null,null,null,null,null],
 	['北京', 'pin', '#ff0000',40,50,116.2529,39.5420,null,null], 
 	['南京', 'point','#ff0000',15,60,118.767413,32.041544,null,null], 
 	['西宁', 'wave', '#ffff00',20,70,101.778916,36.623178,null,null], 
 	['乌鲁木齐', 'wave', '#ff00ff',20,80,87.617733,43.792818,null,null], 
 	['长沙', 'pin', '#0000ff',30,90,112.982279,28.19409,null,null],
 	['拉萨', 'wave', '#00ff00',15,90,91.132212,29.660361,null,null],
	['拉萨-新疆', 'line', '#00ff00',0,90,91.132212,29.660361,87.617733,43.792818],
	['北京-拉萨', 'line', '#00ff00',0,90,116.2529,39.5420,91.132212,29.660361],
	['新疆-北京', 'line', '#ffff00',0,90,87.617733,43.792818,116.2529,39.5420],
	['西宁-北京', 'line', '#ff0000',0,90,101.778916,36.623178,116.2529,39.5420],
 ]).replaceAll('],','],\r\n')
 
let base = mapPoint.base
let special =[
 	{key:'title',text:'飞行线条'},
	{key: 'lineEffectShow',text: '开启飞行',type: 'switch',init: 'Y',path: '_line.effect.show',bursh: true,bind: true,tip: '',list: options.YON},
//	{key: 'linePolyline',text: '是否多线段',type: 'switch',init: 'Y',path: '_line.polyline',bursh: true,bind: true,tip: '',list: options.YON},
	{key:'lineEffectPeriod',text:'飞行周期',type:'number',init:4,path:'_line.effect.period',bursh:true,bind:true,tip:'',list:null},
	{key:'lineEffectSize',text:'飞行体大小',type:'number',init:5,path:'_line.effect.symbolSize',bursh:true,bind:true,tip:'',list:null},
	{key:'lineEffectColor',text:'飞行体颜色',type:'color',init:'#ffffff',path:'_line.effect.color',bursh:true,bind:true,tip:'',list:null},
	{key:'lineEffectTrailLength',text:'尾迹长度',type:'number',init:0.4,path:'_line.effect.trailLength',bursh:true,bind:true,tip:'',list:null},
	{key: 'lineEffectLoop',text: '循环飞行',type: 'switch',init: 'Y',path: '_line.effect.loop',bursh: true,bind: true,tip: '',list: options.YON},
 	{key: 'lineEffectRoundTrip',text: '往返飞行',type: 'switch',init: 'N',path: '_line.effect.roundTrip',bursh: true,bind: true,tip: '',list: options.YON},
 	{key: 'lineLarge',text: '启用优化',type: 'switch',init: 'N',path: '_line.large',bursh: true,bind: true,tip: '数据图形特别多的时候（>=5k）可以开启优化后不能自定义设置单个数据项的样式',list: options.YON},

	{key:'lineType',text:'线条类型',type:'select',init:'solid',path:'_line.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	{key:'lineWidth',text:'线条粗细',type:'number',init:1,path:'_line.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	{key:'lineCurveness',text:'线条弯曲度',type:'number',init:0.5,path:'_line.lineStyle.curveness',bursh:true,bind:true,tip:'',list:null},
	{key:'lineOpacity',text:'线条透明度',type:'number',init:0.5,path:'_line.lineStyle.opacity',bursh:true,bind:true,tip:'',list:null},


	{key: 'lineSymbol',text: '线端类型',type: 'select',init: 'circle',path: '_line.symbol',bursh: true,bind: true,tip: '',list: options.chartSymbol},
	{key: 'lineSymbolSize',text: '线端大小',type: 'number',init: 5,path: '_line.symbolSize',bursh: true,bind: true,tip: '',list: options.YON},


	
/*	{key:'seriesTypePoint',text:'图形类型',type:'text',init:'effectScatter',path:'_point.type',bursh:true,bind:true,tip:'',list:null},
	{key:'seriesTypePoint',text:'图形类型',type:'text',init:'effectScatter',path:'_point.type',bursh:true,bind:true,tip:'',list:null},
	{key:'coordinateSystemPoint',text:'图形类型',type:'text',init:'geo',path:'_point.coordinateSystem',bursh:true,bind:true,tip:'',list:null},
	{key:'encodePoint',text:'图形类型',type:'number',init:2,path:'_point.encode.value',bursh:true,bind:true,tip:'',list:null},
	{key:'pointColor',text:'标记颜色',type:'colorA',init:'#fff000',path:'_point.itemStyle.color',bursh:true,bind:true,tip:'数据中指定的颜色优先于此设置',list:null},
	{key:'pointSize',text:'标记大小',type:'number',init:10,path:'_point.symbolSize',bursh:true,bind:true,tip:'数据中指定的尺寸大小优先于此设置',list:null},
	
	{key: 'pointLabelShow',text: '显示标签',type: 'switch',init: 'Y',path: '_point.label.show',bursh: true,bind: true,tip: '',list: options.YON},
	{key:'pointLabelFontSize',text:'标签字号',type:'number',init:'12',path:'_point.label.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'pointLabelFontFamily',text:'标签字体',type:'select',init:'',path:'_point.label.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'pointLabelFontColor',text:'标签颜色',type:'color',init:'#000000',path:'_point.label.color',bursh:true,bind:true,tip:'',list:null},
	{key:'pointLabelFontPosition',text:'标签位置',type:'select',init:'top',path:'_point.label.position',bursh:true,bind:true,tip:'',list:options.chartPosition},
	{key: 'pointLabelRotate',text: '标签旋转',type: 'number',init: 0,path: '_point.label.rotate',bursh: true,bind: true,tip: '',list: null},
	{key:'title',text:'动感光波'}, 
	{key:'pointEffectColor',text:'波纹颜色',type:'color',init:null,path:'_point.rippleEffect.color',bursh:true,bind:true,tip:'',list:null},
    {key:'pointEffectPeriod',text:'波动周期',type:'number',init:4,path:'_point.rippleEffect.period',bursh:true,bind:true,tip:'',list:null},
    {key:'pointEffectScale',text:'放大比例',type:'number',init:5,path:'_point.rippleEffect.scale',bursh:true,bind:true,tip:'',list:null},
	{key:'pointEffectBrushType',text:'波纹类型',type:'select',init:'fill',path:'_point.rippleEffect.brushType',bursh:true,bind:true,tip:'',list:options.toOptions('空心圈=stroke,实心圈=fill')},
	{key:'pointEffectNumber',text:'波纹数量',type:'number',init:3,path:'_point.rippleEffect.number',bursh:true,bind:true,tip:'',list:null},
 */
	
].concat().concat(mapPoint.special)
let action = mapPoint.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_mapLine',initData:initData,mapLabelShow:'N',
		 mapAreaBorderWidth:1, color: initColor.mapPoint,
		chartClan: 'map',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'width,height,seriesTypePoint,coordinateSystemPoint,encodePoint,manualSeries,dataOption',
	base:[].concat(base) ,
	special: special,
	action: [].concat(action)
}
export default cfg
