import font from '../../public/font.js'
import layout from '../../public/layout.js'
let datas =JSON.stringify(
  [
	{label:'文本1',color:'#ff0000'},
	{label:'文本2',color:'#00ff00'},
	{label:'文本3',color:'#0000ff'},
] )
let clone= {
	data:{
		type:'clone',width:'200px',height:'',alignV:'flex-start',minH:'100px',overH:'auto',overV:'auto',marginT:'5px',marginB:'5px',marginL:'5px',marginR:'5px',paddingT:'0px',paddingB:'0px',paddingL:'0px',paddingR:'0px',
	},
	delete:'',
	hide:'',
	base:layout.concat(font) ,
		special: [	{key: 'dataList',text: 'JSON数据',type: 'jsonArray',init: datas,	target: '',bursh:true,bind:true,tip: '',list: null},],
	action:[]
}

export default clone