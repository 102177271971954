import options from '../../../../public/options.js'
let fontStyle=[

	{key:'Color',text:'字体颜色',type:'color',init:'#333333',path:'textStyle.color',bursh:true,bind:true,tip:'',list:null},
	{key:'FontStyle',text:'字体风格',type:'select',init:'normal',path:'textStyle.fontStyle',bursh:true,bind:true,tip:'',list:options.toOptions('常规=normal,斜体=italic')},
	{key:'FontWeight',text:'字体粗细',type:'select',init:'normal',path:'textStyle.fontWeight',bursh:true,bind:true,tip:'',list:options.toOptions('常规=normal,加粗=bold,更粗=bolder,细体=lighter')},
	{key:'FontFamily',text:'字体类型',type:'select',init:'',path:'textStyle.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'FontSize',text:'字体大小',type:'number',init:'18',path:'textStyle.fontSize',bursh:true,bind:true,tip:'',list:null},
	{key:'LineHeight',text:'行高',type:'number',init:'18',path:'textStyle.lineHeight',bursh:true,bind:true,tip:'',list:null},
	{key:'FontWidth',text:'宽度',type:'number',init:null,path:'textStyle.lineHeight',bursh:true,bind:true,tip:'',list:null},
	{key:'fontHeight',text:'高度',type:'number',init:null,path:'textStyle.height',bursh:true,bind:true,tip:'',list:null},
	{key:'Overflow',text:'超出宽度时',type:'select',init:'none',path:'textStyle.overflow',bursh:true,bind:true,tip:'',list:options.toOptions('默认=none,截断=truncate,换行=break,强制换行=breakAll')},
	{key:'Ellipsis',text:'截断替代符',type:'text',init:'...',path:'textStyle.ellipsis',bursh:true,bind:true,tip:'',list:null},

	{key:'TextBorderColor',text:'边框颜色',type:'color',init:null,path:'textStyle.textBorderColor',bursh:true,bind:true,tip:'',list:null},
	{key:'TextBorderWidth',text:'边框宽度',type:'number',init:null,path:'textStyle.textBorderWidth',bursh:true,bind:true,tip:'',list:null},
	{key:'TextBorderType',text:'边框类型',type:'select',init:'',path:'textStyle.textBorderType',bursh:true,bind:true,tip:'',list:options.BorderType},
	{key:'TextShadowColor',text:'阴影颜色',type:'color',init:null,path:'textStyle.textShadowColor',bursh:true,bind:true,tip:'',list:null},
	{key:'TextShadowBlur',text:'阴影长度',type:'number',init:null,path:'textStyle.textShadowBlur',bursh:true,bind:true,tip:'',list:null},
	{key:'TextShadowOffsetX',text:'阴影X偏移',type:'number',init:null,path:'textStyle.textShadowOffsetX',bursh:true,bind:true,tip:'',list:null},
	{key:'TextShadowOffsetY',text:'阴影Y偏移',type:'number',init:null,path:'textStyle.textShadowOffsetY',bursh:true,bind:true,tip:'',list:null},
	
	
]

export default {list:fontStyle}