<template>
	<div class="box-content">
		<div class="area-tree">
			<logictree ref="treefef" :nodes="nodeList" :allow-edit="false" :allowDrag="false" :allow-search="true"
				@nodeclick="nodeClick">
			</logictree>

		</div>
		<div class="area-tosel noselect">
			<div class="area-search">
				<el-input v-model="leftSearchKey" placeholder="查找" size="default" suffix-icon="Search" clearable />
			</div>
			<div class="area-list">
				<template v-for="(item,index) in toSelList">
					<div @click="itemClick('left',item,index)" @dblclick="itemDblClick('left',item,index)"
						:title="item.tip" :class="item===leftSel?'item item-sel':'item'">
						{{item.label}}
					</div>
				</template>

			</div>
		</div>
		<div class="area-tool">
			<div class="area-tool-item">

				<el-checkbox v-model="withSub" label="含下级" size="large" title="是否列出当前节点所有子节点下的数据" />

				<el-button type="primary" @click="toMove('left',selectList,resultList,false)" class="tool-button"
					title="左侧选择项移入(或双击)">
					<i class="fal fa-arrow-alt-right"></i>
				</el-button>

				<el-button type="primary" @click="toMove('left',selectList,resultList,true)" class="tool-button"
					title="右侧全部移入">
					<i class="fas fa-arrow-alt-from-left"></i>
				</el-button>

				<el-button type="primary" @click="toMove('right',resultList,selectList,false)" class="tool-button"
					title="右侧选择项移出(或双击)">
					<i class="fal fa-arrow-alt-left"></i>
				</el-button>
				<el-button type="primary" @click="toMove('right',resultList,selectList,true)" class="tool-button"
					title="右侧全部移出">
					<i class="fas fa-arrow-alt-from-right"></i>
				</el-button>
				<div style="width: 100%;text-align: center;color: #409EFF;">
					{{resultList.length}}<i class="fas fa-check" style="margin-left: 5px;"></i>
				</div>

			</div>
		</div>
		<div class="area-tosel noselect">
			<div class="area-search">
				<el-input v-model="rightSearchKey" placeholder="查找" size="default" suffix-icon="Search" clearable />
			</div>
			<div class="area-list">
				<template v-for="(item,index) in selectedList">
					<div @click="itemClick('right',item,index)" @dblclick="itemDblClick('right',item,index)"
						:title="item.tip" :class="item===rightSel?'item item-sel':'item'">
						{{item.label}}
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			nodeList: {
				type: Object,
				default: []
			},
			dataList: {
				type: Object,
				default: [],
				//{key,label,tip}
			},
			initResult: {
				type: Object,
				default: [],
			},
			maxSize: { //0不限制个数
				type: Number,
				default: 0,
			}


		},
		emits: ['nodeclick', 'change', 'init'],
		data() {
			return {
				withSub: false,
				leftSel: {}, //左侧当前选择项
				rightSel: {},
				selectList: [],
				resultList: [],
				leftSearchKey: '',
				rightSearchKey: ''


			}
		},

		methods: {
			clearResult() {
				this.resultList = []
			},
			getResult() {
				return this.resultList
			},
			toMove(type, src, target, isAll) {

				if (isAll) { //全移
					if (this.itemMove(src, target)) {
						src.splice(0, src.length)
					}


				} else { //单移
					let srcItem = type === 'left' ? this.leftSel : this.rightSel
					for (let i = 0; i < src.length; i++) {
						let item = src[i]
						if (item === srcItem) { //当前选项在列表中存在才处理
							if (this.itemMove([srcItem], target)) {
								src.splice(i, 1)
							}

							break
						}
					}
				}
				this.$emit('change', this.resultList)
			},
			itemMove(src, target) { //过滤重复项
				if (this.maxSize > 0 && target === this.resultList && (target.length + src.length) > this.maxSize) {
					this.$logic.tip.warning('最多允许选择' + this.maxSize + '项，已超过限制')
					return false
				}
				let map = {}
				for (let item of target) {
					map[item.key] = item
				}
				for (let item of src) {
					if (!map[item.key]) {
						target.push(item)
					}
				}
				this.leftSel = {}
				this.rightSel = {}
				return true
			},
			itemDblClick(type, item, idx) {
				if (type === 'left') {
					if (this.itemMove([item], this.resultList)) {
						this.selectList.splice(idx, 1)
					}

				} else if (type === 'right') {
					if (this.itemMove([item], this.selectList)) {
						this.resultList.splice(idx, 1)
					}

				}
				this.$emit('change', this.resultList)
			},
			itemClick(type, item) {
				if (type === 'left') {
					this.leftSel = item
				} else if (type === 'right') {
					this.rightSel = item
				}
			},
			nodeClick(nodeObj) {
				this.$emit('nodeclick', nodeObj, this.withSub)
			}

		},
		watch: {
			dataList(newValue, oldValue) {
				this.selectList = [].concat(newValue)
			},
			initResult(nv, v) {
				this.resultList = this.initResult
			}

		},
		computed: {
			toSelList() {
				let list = []
				for (let item of this.selectList) {
					if (item.label.indexOf(this.leftSearchKey) > -1) {
						list.push(item)
					}

				}
				return list
			},
			selectedList() {
				let list = []
				for (let item of this.resultList) {
					if (item.label.indexOf(this.rightSearchKey) > -1) {
						list.push(item)
					}
				}
				return list
			}

		},
		created() {
			this.resultList = this.initResult
			let this_ = {
				clearResult: this.clearResult,
				getResult: this.getResult
			}
			this.$emit('init', this_)

		}
	}
</script>

<style scoped>
	.box-content {
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		border: solid 1px #cccccc;
		display: flex;
		background-color: #ffffff;

	}

	.area-tree {
		width: 300px;
		height: 100%;
		border-right: solid 1px #cccccc;
	}

	.area-tosel {
		width: calc((100% - 400px - 4px)/2);
		min-width: 100px;
		height: 100%;
		border-right: solid 1px #cccccc;
	}

	.area-search {
		height: 30px;
		background-color: #f8f8f8;
		display: flex;
		align-items: center;
	}

	.area-list {
		height: calc(100% - 30px);
		overflow: auto;
	}

	.area-tool {
		width: 120px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: solid 1px #cccccc;
	}

	.area-tool-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.area-result {
		width: calc((100% - 400px - 4px)/2);
		min-width: 100px;
		height: 100%;
		border-right: solid 1px #cccccc;
	}

	.tool-button {
		margin: 10px 0px;
		display: block;

	}


	.item {
		padding: 2px 5px;
		cursor: pointer;
	}

	.item:hover {
		border: solid 1px #409EFF;
	}

	.item:nth-child(odd) {
		background-color: rgb(250, 250, 250);
	}

	.item-sel {
		background-color: #ffff7f !important;
	}
</style>
