import axios from "axios";
import Ajax from './ajax.js'
import clone from './deepClone.js'
import pageUtil from './pageutil.js'
import security from './logicSecurity.js'
import DB from './logicDB.js'

function logicHttp(baseUrl, timeOut, devServer) {

	this.baseUrl = baseUrl;
	this.token = null;
	this.timeEnd = 7200;
	this.userName = '';
	this.passWord = '';
	this.devServer = devServer


	axios.defaults.baseURL = baseUrl
	axios.defaults.timeout = timeOut


	return {
		baseUrl: this.baseUrl,
		devServer: this.devServer,
		getBaseRequestParam(param, _thisRef) {
			let _this = _thisRef || this
			let user = _this.$logic.getUser()
			param = param || {}
			param.$$unitGroup = user.unitGroup
			param.$$unitId = user.unitId
			param.$$userId = user.id
			param.$$userName = user.userName
			param.$$tick = new Date().getTime()
			param.$$serial = _this.getToken(param)
			return param
		},
		async get(showCover, url, param, successRecall, errorRecall) {
	
			param = this.getBaseRequestParam(param)
				
			if (successRecall) { //如果指定了回调函数，自动替应用端处理异常和失败,如不指定，返回原样的axios对象
				if (showCover) {
					this.$logic.showLayer(true)
				}
				
				let rs = axios.get(url, {
					params: param
				}).then(res => {
						if (showCover) {
							this.$logic.showLayer(false)
						}
						if (!res.data) {
							this.$logic.tip.warning("请求数据失败:非法请求")
							return
						}
						if ('code' in res.data) { //有返回对象
							if (res.data.code == 0) {
								successRecall(res)
							} else {
								if (errorRecall) {
									errorRecall(res.data)
								} else {
									let err = res.data.info
									if (err) {
										err = err.replace('java.lang.RuntimeException:', '')
									}
									this.$logic.tip.warning("请求数据失败:" + err)
								}

							}
						} else { //不带返回对象的直接回调
							successRecall(res)
						}
					},
					err => {
						if (showCover) {
							this.$logic.showLayer(false)
						}
						let msg = err ? err.message ? err.message : err : '未知错误'
						if (errorRecall) {

							errorRecall({
								code: 1,
								info: msg,
								data: err
							})
						} else {

							this.$logic.tip.error("请求数据失败:" + err)
						}

					}
				)
				return rs;
			} else {
				return await axios.get(url, {
					params: param
				})
			}
		},

		async post(showCover, url, param, data, successRecall, errorRecall) {
			/* 	let user = this.$logic.getUser()
				param = param || {}
				param.$$unitGroup = user.unitGroup
				param.$$unitId = user.unitId
				param.$$userId = user.id
				param.$$userName = user.userName
				param.$$token = this.getToken() */
			param = this.getBaseRequestParam(param)
			let formData = new FormData()
			let hasFile = false
			for (let key in data) {
				formData.append(key, data[key])
				if (key == 'file') {
					hasFile = true
				}
			}
			let config = {
				params: param
			}
			if (hasFile) {
				config.headers = {
					'Content-Type': 'multipart/form-data'
				}
			}
			if (showCover) {
				this.$logic.showLayer(true)
			}

			if (successRecall) { //如果指定了回调函数，自动替应用端处理异常和失败,如不指定，返回原样的axios对象

				let rs = axios.post(url, data, config).then(res => {
						if (showCover) {
							this.$logic.showLayer(false)
						}
						if (!res.data) {
							this.$logic.tip.warning("请求数据失败:非法请求")
							return
						}
						if ('code' in res.data) {

							if (res.data.code == 0) {
								successRecall(res)
							} else {
								if (errorRecall) {
									errorRecall(res.data)
								} else {
									let err = res.data.info
									if (err) {
										err = err.replace('java.lang.RuntimeException:', '')
									}
									this.$logic.tip.warning("提交失败:" + res.data.info)
								}

							}
						} else { //不带返回对象的直接回调
							successRecall(res)
						}
					},
					err => {
						if (showCover) {
							this.$logic.showLayer(false)
						}
						let msg = err ? err.message ? err.message : err : '未知错误'
						if (errorRecall) {
							errorRecall({
								code: 1,
								info: msg,
								data: err
							})
						} else {
							this.$logic.tip.error("提交异常:" + err)
						}

					}
				);
				return rs;
			} else {
				let logic = this.$logic
				let ps = new Promise(function(resolve, reject) {

					axios.post(url, data, config).then(res => {
						logic.showLayer(false)
						if (!res.data) {
							this.$logic.tip.warning("请求数据失败:非法请求")
							return
						}
						if ('data' in res.data && res.data.code != 0) { //有datas一定是系统内的接口，值不为空为错误代码
							reject(res.data)
						} else {
							resolve(res)
						}
					}).catch(err => {
						logic.showLayer(false)
						let msg = err ? err.message ? err.message : err : '未知错误'
						let es = {
							code: 1,
							info: msg,
							datas: err
						}
						reject(es)
					})
				})
				return ps
			}

		},
		getToken(param) {
			//param.$$unitGroup =''+ user.unitGroup
			//param.$$unitId =''+ user.unitId
			//param.$$userId =''+ user.id
			//param.$$userName =''+ user.userName

			let ps = '' + param.$$tick + '_' + param.$$unitGroup + '_' + param.$$unitId + '_' + param.$$userId
			let rd = '' + Math.floor(Math.random() * 10000);
			let token = security.getMd5(ps) + rd
			return token
		}, //sourceId 数值以id值或id值+('a'|'s')的格式，表示数据源，纯数值的表示应用实例自身的数据源
		sqlExport(sourceId, sql, params = [],
			option = {}) {
			sql = security.encodeSql(sql)
			let showLayer = 'showLayer' in option ? option.showLayer : true
			let data = {
				data: sql,
				params: params,
				size: 'size' in option ? option.size : 60000,
				name: option.name || '',
				cols: option.cols || [],
				recall: ''
			}

			let rs = this.post(showLayer, '/api/ds/doexport/', {
				sourceId: sourceId,
			}, data)
			return rs
		},
		sqlQuery(sourceId, sql, params = [],
			option = {}) { // isJson = false, total = false, size = 10000, pageNo = 0, showLayer =true

			sql = security.encodeSql(sql)
			let isJson = 'isJson' in option ? option.isJson : false
			let showLayer = 'showLayer' in option ? option.showLayer : true
			let data = {
				opration: 'query',
				data: sql,
				size: 'size' in option ? option.size : 10000,
				pageNo: 'pageNo' in option ? option.pageNo : 0,
				params: params,
				format: isJson ? 'json' : 'array',
				total: 'total' in option ? option.total : false,
				/**/
			}

			let rs = this.post(showLayer, '/api/ds/dodata/', {
				sourceId: sourceId,
			}, data)
			return rs
		},
		init(logic) {
			this.$logic = logic
		},
		getUrl(baseUrl, url, param) {
			let path = null

			//alert(this.baseUrl)
			if (url.toLowerCase().indexOf('http') < 0) { //非绝对地址加前缀
				path = baseUrl + url
			} else {
				path = url
			}
			if (param) {
				let c = 0
				for (let key in param) {
					c++
					if (c == 1) { //
						if (path.indexOf('?') < 0) {
							path = path + '?'
						}
					} else {
						path = path + '&'
					}
					path = path + key + '=' + encodeURIComponent(param[key])
				}

			}

			return path

		},

		async submit(withToken, url, param, data) {
			let formData = new FormData()
			for (let key in data) {
				formData.append(key, data[key])
			}
			/* let user = this.$logic.getUser()
			param = param || {}
			param.$$unitGroup = user.unitGroup
			param.$$unitId = user.unitId
			param.$$userId = user.id
			param.$$userName = user.userName */
			param = this.getBaseRequestParam(param)
			let config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				params: param
			}
			return await axios.post(url, formData, config)

		},
		flushToken() {

		},
		setAccount(userName, passWord) {
			this.userName = userName
			this.passWord = passWord
		},
		checkToken() {
			if (!this.token) {
				return false
			}

			let time = new Date().getTime()
			if (this.timeEnd < time) {
				return false
			}

		},

		pageLoad(project, config, url, params, reCall, errCall, isEdit) {
			let path = url || '/api/dev/form/getform/'
			let datas = params
			if (typeof(params) == 'string') {
				alert('此参数已失效')
				return null
				let ps = params.split('_')
				if (ps.length < 3 || !ps[2]) {
					errCall({
						info: 'ID错误'
					})
					return
				}
				datas = {
					appId: ps[0],
					appVersion: ps[1],
					formId: ps[2]
				}
			}
			this.get(true, path, params, res => {

				let pageModel = res.data.data
				if (pageModel.formData == null || pageModel.formData.length < 10) { //空页面
					this.$logic.tip.error('加载的空页面')
					reCall({
						formData: null
					})
					return
				}

				let pageObj = this.parsePage(config, pageModel, !isEdit, project)
				/* 
								pageModel.formData = pageObj.page
								for (let key in pageObj.util) {
									pageModel[key] = pageObj.util[key]
									pageObj.thisRef[key] = pageObj.util[key]
								}

				 */

				reCall(pageModel)


			}, err => {
				errCall(err)
			})
		},
		flowModelLoad(config, withEvent, project, flowDefId, flowVersion, okRecall = null,
			errRecall = null) { //加载流程模型，版本为0按最新已激活状态的版本
			let rs = null
			this.get(true, '/api/flow/getflowversion/', {
				appId: project.id,
				sourceId: project.dbSourceId,
				flowId: flowDefId,
				versionId: flowVersion || 0
			}, res => {
				let versionInfo = res.data.data
				let result = this.initFlowVersion(versionInfo, config, withEvent)
				if (okRecall) {
					okRecall(result)
				}



			}, err => {
				if (errRecall) {
					errRecall(err)
				} else {
					if (err.info) {
						this.$logic.tip.error(err.info)
					} else {
						this.$logic.tip.error('无效的流程模型')
					}
				}

			})
			return rs

		},
		initFlowVersion(versionItem, config, withEvent) {
			let versionContent = versionItem.versionData
			let flowContent = JSON.parse(versionContent)
			//找到第一个流程组件
			let flowModel = null
			let sk = [].concat(flowContent.items)
			while (sk.length > 0) {
				let node = sk.pop()
				if (node.type === 'flow') {
					flowModel = node
					break
				}
				if (node.items) {
					for (let item of node.items) {
						sk.push(item)
					}
				}
			}
			if (flowModel == null) {
				this.$logic.tip.error('流程版本中无效的流程设计内容')
				return
			}
			//初始化流程设计器中的节点、线条元素
			this.initModel(config, flowModel, withEvent, {})
			let nodes = {}
			let startNode = null
			let forms = {}
			for (let form of flowModel.formList) {
				//let pageObj = this.parsePage(config, form, withEvent, project)
				forms[form.id] = form
			}

			for (let node of flowModel.items) {
				if (node.type === 'flownode') {
					if (node.formId) {
						let form = forms[node.formId]
						if (!form) {
							this.$logic.tip.error('流程节点：' + node.nodeName + '指定的表单不存在,节点ID：' + node.id +
								',表单ID：' + node.formId)
							return
						}
						node.nodeForm = form
					}
					nodes[node.id] = node
				}
				if (node.nodeType === 'start') {
					startNode = node
				}
			}

			let result = {
				flowVersion: versionItem, //当前流程版本信息
				flowContent: flowContent, //流程设计器容器的全面内容
				flowModel: flowModel, //容器中提取出的流程设计器组件
				flowForms: forms, //流程所有的表单
				flowNodes: nodes, //流程所有的节点
				startNode: startNode //流程开始节点，只可能有一个
			}
			return result
		},
		initModel(config, nodeStack, withEvent,
			hostPage
		) { //hostPage表示在此页面下动态生成出来的元素事件，将继承公共this对象//  pagePanel =null pagePanel页面容器，于支持模板元素联动引用页面上的元素,如果当前根元素下找不到目标元素就从页面上找
			/* 			let handler = {
							get(target, propKey, receiver) {
								console.log(`正在获取 ${propKey} 属性`);
								return Reflect.get(...arguments);
							},
							set(target, propKey, value, receiver) {
								console.log(`${propKey} 属性已经被修改为 ${value}`);
								return Reflect.set(...arguments);
							}
						} */
			/* 		let handler = {
						get() {
							console.log(`读取属性 ${key}`);
							return obj[key];
						},
						set(value) {
							console.log(`写入属性 ${key}，值为 ${value}`);
							obj[key] = value;
						}
					} */

			/* let templatePanelIds = null
			if (templatePanels) {
				templatePanelIds = ','
				for (let item of templatePanels) { //模板作用域内的容器ID集合，可能多个容器，例如一行内多个自定义的列
					templatePanelIds = templatePanelIds + item.id + ','
				}
			} */
			let maxId = 0 //config.maxId
			let stack = Array.isArray(nodeStack) ? [].concat(nodeStack) : [nodeStack]
			let _this = hostPage && hostPage.$thisRef ? hostPage.$thisRef : {} //this单独放在一个空集合内,避免暴露内置事件函数
			//let nodes=[]
			let nodeMap = {}
			while (stack.length > 0) {
				let node = stack.pop()
				if (nodeMap[node.id]) {
					alert('检测到重复的页面元素ID:' + node.id)
				}
				nodeMap[node.id] = node
				//如果是带事件的模式，构建镜像对象
				if (withEvent) {
					/* let obj = {
						bind: node
					}
					let proxyObj = new Proxy(obj, {
						get(target, prop) {
							console.log('读取属性',prop)
							return target.bind.$get(prop)
						},
						set(target, prop, value) {
							console.log(`写入属性 ${prop}，值为 ${value}`)
							target.bind.$set(prop, value)

							return true
						}
					}); */
					//设置代理对象，由于组件会在运行时给model添加自己的方法，因此在ementbase.js需再添加方法
					pageUtil.setModelProxy(node)

				}

				if (node.items) { //含子元素
					for (let item of node.items) {
						item.parent = node
						stack.push(item)
					}
					if (node.type === 'flow') { //如果当前是流程设计器，需增加线条与表单的初始化,在流程开发组件中调用
						for (let item of node.lineList) {
							stack.push(item)
						}
						/* 	for (let form of node.formList) {
								for (let item of form.items) {
									stack.push(item)
								}
							} */

					}
				} else if (node.modelTree) { //如果含有层级子元素,用于文档容器,modelTree为数组
					//取出每层下的model对象加到堆栈中
					let sk = [].concat(node.modelTree)
					while (sk.length > 0) {
						let treeNode = sk.pop()
						let model = treeNode.model
						//this.$logic.http.initModel(config, [doc], !this.isedit)
						model.parent = node
						//console.log(model)
						stack.push(model)
						if (treeNode.children) {
							for (let nd of treeNode.children) {
								sk.push(nd)
							}
						}


					}
				}
			}
			for (let key in nodeMap) {
				let node = nodeMap[key]
				//初始化老版数据中在新版配置中不存在项的空值的默认值
				let modelConfig = config.objs[node.type]
				if (!modelConfig) {
					continue
				}
				let sqlConfigs = [] //所有与SQL配置相关的属性
				let cf = modelConfig.configs //获取每类对象的配置项
				for (let c of cf) {
					if (typeof(c.init) == 'undefined') { //没有初始化值的跳过，如title
						continue
					}
					let key = c.key
					if (key in node) {

					} else {
						let init = c.init
						if (init && typeof(init) == 'object') { //如果是对象类型克隆新对象赋值
							init = clone.deepClone(c.init)
						}
						node[key] = init
					}
					if (c.type === 'inputset' || c.type === 'report') { //动态数据列表以及报表设置中与SQL相关的配置项
						sqlConfigs.push(c)
					}
					if (withEvent && c.type === 'json') { //运行状太下检测各组件下的值类型如果是json 但值是文本型的，转换数值
						let pvalue = node[key]
						if (pvalue && typeof(pvalue) === 'string') {
							try {
								node[key] = JSON.parse(pvalue)
								//console.log('组件：' + node.name + '[' + node.id + '].' + key + ' 已转换',node[key])

							} catch (ex) {
								console.log('组件：' + node.name + '[' + node.id + '].' + key + ' 是错误的json文本内容值', pvalue)
								//node[key]=null
							}
						}
					}
				}
				/* if (node.id > maxId) { //已废除，直接使用页面数据
					maxId = node.id
				} */
				//实例化事件

				if (withEvent) {
					let funcs = {}

					let evts = node.$events
					if (evts) {
						for (let key in evts) {
							if ('$user_funcs' == key) { //用户自定义函数
								for (let item of evts[key]) {
									var func = null //vale中赋值的变量必须用var声明
									eval('func=function ' + item.name + '(' + item.params[0] + '){' +
										'let page=this.$page \r\n let $=this.$ \r\n' +
										item.code + '}')
									func = func.bind(_this)
									//func.funcName=evt.name
									_this[item.name] = func
								}
							} else { //系统事件函数
								let evt = evts[key]
								if (evt.status == 'Y' && evt.code && evt.code.length > 3) {
									var func = null
									eval('func= function ' + key + '(action){' +
										'let page=this.$page \r\n let $=this.$ \r\n' +
										evt.code + '}')
									func = func.bind(_this)
									func.bubble = evt.bubble == 'Y'
									func.funcName = evt.name
									funcs[key] = func
								}
							}
						}
					} else {

					}
					node.$functions = funcs

					//创建组件联动关系 
					if (node.$linkMap) {
						/* 	console.log(node, templatePanelIds, node.parent)
						//判断当前元素是否模板容器内的元素
						if (templatePanelIds) {
							let pnode = node
							while (pnode) {
								if (templatePanelIds.indexOf(',' + pnode.id + ',') > -1) {
									node.$templateIds = templatePanelIds //标识此节点是模板内的
									console.log('Tempate', node)
									break
								}
								pnode = pnode.parent
							}
						}
 */
						for (let key in node.$linkMap) {
							let link = node.$linkMap[key]
							let objMap = {}
							let js = ''
							for (let comp of link.comps) {
								let isTemplateObj = false //
								let obj = nodeMap[comp.compId] //联动取值的数据源元素	
								if (!obj && hostPage) { //如果是初始化模板内元素的联动关系且引用了页面元素，此对象找不到，需再试试到页面上找
									obj = this.$logic.pageUtil.findFirstElementById([hostPage.formData], comp.compId)
								}
								/* console.log('Find:', obj)
								if (!obj && templatePanels) { //页面中未获取到就从模板容器中取
									console.log('TTTT')
									let stack = [].concat(templatePanels)
									while (stack.length > 0) {

										let objItem = stack.pop()
										if (comp.compId === objItem.id) {
											isTemplateObj = true
											obj = objItem
											obj.$templateIds = templatePanelIds //标记一下此元素为模板编辑容器内的动态元素
											console.log('Tempate', node)
											break
										}
										if (objItem.items) {
											for (let sub of objItem.items) {
												stack.push(sub)
											}
										}

									}
								} */
								if (!obj) {
									alert(node.name + '[' + node.id + ']属性' + key + '联动配置失效,不存在的关联对象ID:' + comp
										.compId)
									continue
								}
								if (obj && !(comp.param in obj)) { //目标组件不存在的情况下（模板内容中的元素）不作此判断
									alert(node.name + '[' + node.id + ']属性联动配置失效' + key + '不存在的关联对象属性:' + comp
										.param)
									continue
								}
								if (comp.compId in objMap) { //已经存在的目标组件不作处理

								} else { //不存在的加入集合且声明变量  // isTemplateObj? comp.compId :
									objMap[comp.compId] = obj //如果是模板容器时存元素ID，否则存元素的实例 
									js = js + 'let ' + comp.compId + '=varMap["' + comp.compId + '"];'
								}
								//在源组件中标记此组件实例,相关联的其它参与计算的元素存在objMap中 
								if (obj && !obj.$linkObj) { //key为下级联动对象ID
									obj.$linkObj = {}
								}
								if (!(node.id in obj.$linkObj)) { //isTemplateObj?node.id:
									obj.$linkObj[node.id] = {
										target: node,
										objMap: objMap, //计算所需相关联的组件对象
										params: {} //组件的属性
									}
								}
								let objParams = obj.$linkObj[node.id].params
								objParams[key] = {
									link: link,
									js: null
								}
							}
							js = js + 'result=' + link.express
							for (let objId in objMap) {
								let p = objMap[objId].$linkObj[node.id].params[key]
								p.js = js
							}

						}
					}
					//创建SQL依赖关系,如果是有类型为inputset的属性，取SQL中存在的组件依赖
					if (sqlConfigs.length > 0) {
						for (let cfg of sqlConfigs) {
							let obj = node[cfg.key]
							if (!obj || typeof(obj) != 'object') {
								continue
							}
							let sql = null
							if ('sql' in obj) { //直接有SQL属性的配置数据直接取值
								sql = obj.sql
							} else { //否则是在成员属性中配置
								for (let key in obj) {
									let item = obj[key]
									if (typeof(item) == 'object' && 'sql' in item) {
										if ('load' in item) { //如果含有加载开关的，只取开启状态的
											if (item.load === 'Y' && item.sql) {
												sql = item.sql
											}
										} else { //没有开关的直接取
											if (item.sql) {
												sql = item.sql
											}
										}
									}
								}
							}
							//提取SQL中包含的组件
							if (sql) {
								let params = DB.getSqlVars(sql)
								for (let item of params) {
									if (item.key.startsWith('@')) { //页面元素取值方式
										let compId = item.key.substring(1)
										let x = compId.indexOf('.')
										let paramName = compId.substring(x + 1)
										compId = compId.substring(0, x).trim()

										let srcObj = nodeMap[compId]
										if (srcObj) {
											if (!srcObj.$sqlLink) { //$sqlLink={key1:{id1:obj1,id2:obj2}}
												srcObj.$sqlLink = {}
											}
											if (!(paramName in srcObj.$sqlLink)) {
												srcObj.$sqlLink[paramName] = {}
											}
											srcObj.$sqlLink[paramName][node.id] = node


										} else {
											alert('SQL中设置的变量从页面组件取值，但不存在此组件ID:', sql, compId)
										}

									}
								}
							}

						}
					}
					//console.log(node)

				}
			}

			let hasError = false
			for (let nodeId in nodeMap) { //为所有节点初始化联动数据
				let node = nodeMap[nodeId]
				if (!node.$linkObj) {
					continue
				}
				let linkObj = node.$linkObj
				for (let key in linkObj) {
					let toLink = linkObj[key]
					let toObj = toLink.target
					for (let param in toLink.params) {
						if (!(param in toObj)) { //不存在的属性跳过
							continue
						}
						let item = toLink.params[param]
						try {
							let varMap = toLink.objMap
							let result = null
							eval(item.js)
							toObj[param] = result
						} catch (ex) {
							hasError = false
							alert('向' + toObj.id + '.' + param + '联动赋值异常：' + ex)
						}
					}
				}
			}
			if (hasError) {
				setTimeout(() => {
					this.$logic.tip.error('页面加载时组件数据联动初始化异常，详情见控制台')
				}, 2000)

			}
			//config.maxId = maxId //最大值已改到页面
			return {
				maxId,
				_this,
				nodeMap
			}
		},
		parsePage(config, pageModel, withEvent, project = null) {

			let pageData = pageModel.formData
			if (!(pageData && ((typeof pageData) === 'object' || pageData.length > 2))) {
				pageModel.formData = null
				return {
					page: null,
					maxId: 0,
					thisRef: {
						$param: {
							get: () => {}
						}
					},
					util: {
						$: {}

					}

				}
			}
			let page = pageData
			if (typeof(pageData) == 'string') {
				if (pageData.indexOf('$') === 0) { //编码后的内容需解码
					pageData = security.fromBase64(pageData.substring(1))
				}
				page = JSON.parse(pageData)
			}
			//page.maxId = 0 //初始化为0
			//设置对象的父节点
			let stack = [page]
			let pageCfg = this.initModel(config, stack, withEvent)
			let thisRef = pageCfg._this
			let maxId = pageCfg.maxId
			//page.maxId = maxId //更新最大ID值 //最大值直接使用表单静态属性
			//添加扩展功能
			//let params = this.getRequestParam
			let util = {
				//$params: params(),
				$dataSourceList: project.dataSourceList,
				$dataSource: project.dataSource

				/* $param: {
					get: params
				} */
			} //...this.$logic,
			//page.params=params
			for (let key of 'store,layerShow,arrayUtil,http,message,tip,color,date,form,clone,user'
					.split(',')) {
				let kn = '$' + key
				util[kn] = this.$logic[key]
			}
			let getElement = function(filter) { //按类型查找或指定ID查找页面元素
				/* 	let x = filter.indexOf('#')
					let rs = pageUtil.getElements(page, filter)
					if (x > -1) {
						if (rs.length > 0) {
							return rs[0]
						} else {
							return null
						}
					} else {
						return rs
					} */
				//return pageUtil.getElement(page, filter)
				//如果需要按类型查所有元素,以后扩展以.typeName 的方式查
				if (!filter) {
					return null
				}
				let x = filter.indexOf('.')
				if (x === 0) { //.开头表示查一某对象
					return pageUtil.getElements(page, filter)
				}
				x = filter.indexOf('#')
				let objId = x == 0 ? filter.substring(1) : filter
				let obj = pageCfg.nodeMap[objId]
				return obj ? obj._proxy : null
			}
			getElement = getElement.bind(page)
			util.$ = getElement
			util.$page = {
				$pageObj: page,
				$: util.$
			}
			util.$page.$params = this.getRequestParam()
			pageModel.$params = util.$page.$params
			pageModel.$nodeMap = pageCfg.nodeMap
			page._util_page = util.$page //page模型中取编程封闭的page对象			
			/* if (withEvent) {
				
			} */



			let getFormData = function(formId) { //参数如果是对象直接使用
				/* 	let rs = null
					if (typeof(formId) == 'object') {
						rs = pageUtil.getFormData(null, null, formId, null)
					} else {
						rs = pageUtil.getFormData(page, formId)
					}

					return {
						forms: rs
					} */
				return pageUtil.getFormData(page, formId)
			}
			util.$formPick = getFormData.bind(page)
			let clearValidate = function(formId) {
				return pageUtil.clearValidate(page, formId)
			}
			let formValidate = function(formId) {
				return pageUtil.formValidate(page, formId)
			}
			util.$clearValidate = clearValidate.bind(page)
			util.$formValidate = formValidate.bind(page)
			/* util.$pageTo = function(url) { //页面转向
				if ('$hostElement' in page) {
					let el = page.$hostElement
					el.$set('url', url)
				} else {
					_this.$logic.tip.error('当前页面非子页面，无法转向')
				}

			}.bind(page) */
			util.$reload = function(url = null, act = null) { //页面重新加载，如果不传参数，重新加载本页面，有参数为转向

				page.$reload(url, act)
				/* if ('$hostElement' in page) {
					let el = page.$hostElement
					if (url) {
						el.$set('url', url)
					} else {
						el.$fresh()
					}

				} else {
					//不是子页面，页面向外触发重加载事件
					
					_this.$logic.tip.error('当前页面非子页面，无法转向')
				} */
			}.bind(page)
			util.$menuTo = function(menuItemKey, param, closeTab, menuId) { //菜单转向,如果不指定菜单取页面第一个元素，否则对指定菜单操作
				let menu = null
				let menus = getElement(menuId || '.menu')
				if (menus) {
					if (Array.isArray(menus)) {
						if (menus.length > 0) {
							menu = menus[0]
						} else {
							menu = null
						}

					} else {
						menu = menus
					}
				}
				if (!menu) {
					_this.$logic.tip.error('当前页中不存在菜单组件，可尝试在父级页面调用此方法')
				} else {

					menu.$select(menuItemKey, param, closeTab) //携带参数
				}
			}.bind(page)

			//数据请求方法
			let getUrl = this.getUrl
			let baseUrl = this.baseUrl
			let _this = this
			let post = function(url, param, data) {
				let promise = new Promise(function(resolve, reject) {
					_this.post(true, url, param, data, res => {
						let rs = getResult(res)
						if (rs.code == 0) {
							resolve(rs)
						} else {
							reject(rs)
						}
					}, err => {
						reject(getResult(err))
					})
				})
				return promise
			}

			let getResult = function(res) {
				if (!res) {
					return res
				}
				if ('data' in res && res.data && 'data' in res.data) {
					return res.data
					/* return {
						code: res.data.code,
						msg: res.data.info,
						data: res.data.data
					} */
				} else {
					return res
					/* return {
						code: res.code,
						msg: res.info,
						data: res.data
					} */
				}
			}

			let get = function(url, param, isAsync = false) {
				let path = getUrl(baseUrl, url, param)
				if (isAsync) {
					let rs = null
					Ajax.get(path, null, res => {
						rs = res
					}, err => {
						_this.$logic.tip.error('数据请求异常：' + err.info || '未知错误')
					})
					return rs
				} else {
					let promise = new Promise(function(resolve, reject) {
						
						_this.get(true, url, param, res => {
							let rs = getResult(res)
							if (rs.code == 0) {
								resolve(rs)
							} else {
								reject(rs)
							}
						}, err => {
							reject(getResult(err))
						})
					})
					return promise
				}
			}
			let read = function(filePath, codeType = 'utf-8') {
				return get('/api/ds/readfile/', {
					filePath: filePath,
					codeType: codeType
				}, false)
			}
			util.$http = {
				post: post,
				get: get,
				read: read
			}
			//SQL方法,默认最大一万行,数组,params防注入
			let sqlQuery = function(sql, params = [], option = {
				sourceId: 0
			}) {
				if (!option) {
					option = {
						sourceId: 0
					}
				}
				if (!('sourceId' in option) || option.sourceId == 0) { //使用当前应用系统的的数据源,-1使用主数据源
					option.sourceId = project.dataSource.id
				}
				if (!('isJson' in option)) {
					option.isJson = true
				}
				let promise = new Promise(function(resolve, reject) {
					_this.sqlQuery(option.sourceId, sql, params, option).then(
						res => {
							let rs = getResult(res)
							if (rs.code == 0) {
								resolve(rs)
							} else {
								reject(rs)
							}
						}).catch(err => {
						reject(getResult(err))
					})
				})

				return promise
			}
			//同步方法只获取结果,带事件的简单模式
			let _thisRef = this
			let baseRequestParamFunc = this.getBaseRequestParam
			let sqlSelect = (sql, params = [], option = {
				sourceId: 0
			}) => {
				sql = security.encodeSql(sql)
				let rs = null
				let isJson = 'isJson' in option ? option.isJson : true
				let data = { //非有效数值置为默认数据源 
					opration: 'query',
					data: sql,
					size: 'size' in option ? option.size : 10000,
					pageNo: 'pageNo' in option ? option.pageNo : 0,
					params: params,
					format: isJson ? 'json' : 'array',
					total: 'total' in option ? option.total : false,
					/**/
				}
				let requestParam = baseRequestParamFunc({
					sourceId: option.sourceId ? option.sourceId : project.dataSource.id
				}, _thisRef)
				Ajax.post(baseUrl + '/api/ds/dodata/', requestParam, data, res => {
					rs = getResult(res)
				}, err => {
					rs = getResult(err)
				})
				return rs


			}

			let sqlUpdate = function(sqlCommands, sourceId) {
				if (!Array.isArray(sqlCommands)) {
					sqlCommands = [sqlCommands]
				}

				let sqlCommands2 = []
				for (let item of sqlCommands) {
					sqlCommands2.push({ //作编码处理
						data: security.encodeSql(item.sql),
						values: item.values ? item.values : item.params ? item.params : []

					})
				}

				let dbSorceId = sourceId ? sourceId : project.dataSource.id //默认本系统数据源
				let requestParam = baseRequestParamFunc({
					sourceId: dbSorceId
				}, _thisRef)

				let promise = new Promise(function(resolve, reject) {
					_this.post(true, '/api/ds/batchdata/', requestParam,
						sqlCommands2
					).then(
						res => {
							let rs = getResult(res)
							if (rs.code == 0) {
								resolve(rs)
							} else {
								reject(rs)
							}

						}).catch(err => {
						reject(getResult(err))
					})
				})

				return promise

			}
			let sqlExport = function(sql, values, options = null) {

				values = values || []
				options = options || {}
				let dbSorceId = options.sourceId ? options.sourceId : project.dataSource.id //默认本系统数据源
				let download = 'download' in options ? options.download : true
				let promise = new Promise(function(resolve, reject) {
					_this.sqlExport(dbSorceId, sql, values,
						options
					).then(
						res => {
							let rs = getResult(res)
							if (rs.code == 0) {
								if (download) {
									window.open(rs.data)
								}

								resolve(rs)
							} else {
								reject(rs)
							}

						}).catch(err => {
						reject(getResult(err))
					})
				})

				return promise

			}
			util.$sql = {
				query: sqlQuery,
				do: sqlUpdate,
				get: sqlSelect,
				export: sqlExport
			}
			//内置 api
			let qywx = {

			}
			let ai = {
				faceMatch(apiKey, filePath1, filePath2) {
					let promise = new Promise(function(resolve, reject) {
						_this.post(true, '/api/client/ai/facematch/', {
								apiKey,
								filePath1,
								filePath2
							},
							null
						).then(
							res => {
								let rs = getResult(res)
								if (rs.code == 0) {
									resolve(rs)
								} else {
									reject(rs)
								}

							}).catch(err => {
							reject(getResult(err))
						})
					})
					return promise
				}
			}
			let gongAn = {
				cardCheck(apiKey, userName, cardId, imgPath) { //身份证验证
					let promise = new Promise(function(resolve, reject) {
						_this.post(true, '/api/client/gongan/cardcheck/', {
								apiKey,
								userName,
								cardId,
								imgPath
							},
							null
						).then(
							res => {
								let rs = getResult(res)
								if (rs.code == 0) {
									resolve(rs)
								} else {
									reject(rs)
								}

							}).catch(err => {
							reject(getResult(err))
						})
					})
					return promise
				}
			}
			util.$api = {
				qywx: qywx,
				ai: ai,
				gongAn: gongAn
			}

			pageModel.formData = page
			pageModel.$thisRef = thisRef
			for (let key in util) { //方法赋值给this对象
				pageModel[key] = util[key]
				thisRef[key] = util[key]
			}
			if (project) {

				if (project.orgList == null) {
					this.$logic.tip.error('数据加载失败，请刷新系统重试')
					return null
				}
				this.initUnitData(project)
				thisRef.$orgTree = project.orgTree
				thisRef.$roleTree = project.roleTree
			}
			util.$page.$menuTo = util.$menuTo //页面相关的方法给页面
			util.$page.$reload = util.$reload
			for (let key in thisRef) {
				if (key.length < 2) {
					continue
				}
				let pname = key
				if (key.indexOf('$') === 0) { //去掉前导$,简化名称
					pname = key.substring(1)
				}
				thisRef.$[pname] = thisRef[key]
				//保留前导$，防止名称与组件ID有冲突
				//thisRef.$[key] = thisRef[key]
			}
			/* 			thisRef.$.$help = () => {}
						thisRef.$.$help = () => {
							for (let key in thisRef.$) {
								let ot = typeof(thisRef[key])
								if (ot == 'object') {
								 
								} else {
									 
								}

							}
						} */
			for (let key in pageCfg.nodeMap) {
				Object.defineProperty(thisRef.$page, key, {
					get() {
						return pageCfg.nodeMap[key]._proxy
						/* console.log(key, thisRef.$page.$pageObj)
						if (key in thisRef.$page.$pageObj) { //如果是方法调用
							let item = thisRef.$page.$pageObj[key]
							if (typeof(item) === 'function') {
								return thisRef.$page.$pageObj[key]
							} else {
								return pageCfg.nodeMap[key]._proxy
							}
						} else {
							
						} */

					},
					set(value) { //不允许Set
						//console.log('不允许设值')
					}
				})

			}

			return {
				page,
				maxId,
				util,
				thisRef

			}
		},
		initUnitData(project) {
			let getNodePath = (tree, nodeId) => {
				let path = ''
				let list = this.$logic.arrayUtil.getTreeNodePath(tree, nodeId)
				for (let item of list) {
					if (path.length > 0) {
						path = path + '/' + item.label
					} else {
						path = item.label
					}
				}
				return path
			}

			project.orgTree = this.$logic.arrayUtil.parseTree(project.orgList)
			project.orgTree.getPath = (nodeId) => {
				return getNodePath(project.orgTree, nodeId)
			}
			project.orgTree.getSubNodes = (nodeId) => {
				return this.$logic.arrayUtil.getSubNodes(project.orgTree, nodeId)
			}
			project.orgTree.getNode = (nodeId) => {
				return this.$logic.arrayUtil.getTreeNode(project.orgTree, nodeId)
			}
			project.roleTree = this.$logic.arrayUtil.parseTree(project.roleList)
			project.roleTree.getPath = (nodeId) => {
				return getNodePath(project.roleTree, nodeId)
			}
			project.roleTree.getSubNodes = (nodeId) => {
				return this.$logic.arrayUtil.getSubNodes(project.roleTree, nodeId)
			}
			project.roleTree.getNode = (nodeId) => {
				return this.$logic.arrayUtil.getTreeNode(project.roleTree, nodeId)
			}
		},

		formSubmit(params = {
			formId: null,
			_this: null,
			recall: null

		}, action) {
			if (!params || !params.formId) {
				if (_this) {
					_this.$logic.tip.error('参数错误，未指定表单ID，无法进行此操作')
				} else {
					alert('参数错误，未指定表单ID，无法进行此操作')
				}
				return null
			}
			let _this = params._this
			let url = params.url ? params.url : '/api/ds/formsubmit/'
			let check = null
			/* 		let ck = _this.doEvent({
						eventName: 'validatecustom',
					}, '$comp_validateCustom')

					if (ck === false) {
						check = {
							success: false,
							info: []
						}
					} else { //没有失败继续进行其它规则校验 
						check = _this.page.$formValidate(params.formId) //_this.page.$checkFormData(_this.page,formId)	
					} */
			if ('clear' === action) {
				_this.page.$clearValidate(params.formId)
				return true
			}
			let data = _this.page.$formPick(params
				.formId) //pageUtil.getFormData(_this.page.formData, params.formId)
			if ('pick' === action) {
				return data
			}
			check = _this.page.$formValidate(params.formId) //_this.page.$checkFormData(_this.page,formId)	
			if ('validate' === action) {
				return check
			}
			let formModel = _this.model
			if (check.success === false) { //数据验证失败,用户设置了验证事件用验证事件，没设置用默认提示
				/* if (formModel.$functions && formModel.$functions.$comp_validateFailed) {
					_this.doEvent({
						eventName: 'validatefailed',
						error: check.info,
					}, '$comp_validateFailed')
				} else {
					let tips = ''
					for (let info of check.info) {
						tips = tips + info + ' 。 '
					}
					_this.$logic.tip.error('表单验证失败：' + tips)
				} */
				formModel._validateInfo(check)
				return
			}
			if (data.dataId == null) {
				_this.$logic.tip.error('无效的表单ID数据，请确认页面请求地址中存在表单容器中设置的参数名')
				return
			}


			/*	if (cs.code != 0) {
					_this.$logic.tip.error(cs.info)
					return 
				} */
			let ok = formModel._submitBefor(data)
			//console.log(ok)
			if (ok === false) {
				return
			}
			//console.log(data)
			_this.$logic.http.post(true, url, {
				$dbSourceId: _this.project.dataSource.id //project.dbSourceId
			}, data, res => {
				//console.log(res)
				let ds = {
					code: res.data.code,
					info: res.data.info,
					data: res.data.data
				}
				formModel._submitAfter(ds)
				if (params.recall) {
					params.recall()
				}
			}, err => {
				let rs = {
					code: err.code,
					message: err.info,
					data: err.data
				}
				if (formModel.$functions && formModel.$functions.$comp_submitError) {
					formModel._submitError(rs)

				} else {
					_this.$logic.tip.error('提交失败 ' + rs.message)
				}

			})
		},
		getRequestParam(param, urlSrc) { //param 返回指定参数名的值，如不指定返回所有参数,url不指定提取浏览器网址
			let url = urlSrc || decodeURI(window.location.search)
			let params = {}
			let x = url.indexOf('?')
			if (x > -1) {
				url = url.substr(x + 1)
				let ps = url.split('&')
			
				for (let i = 0; i < ps.length; i++) {
					let x = ps[i].indexOf('=')
					if (x > 0) {
						let key=ps[i].substring(0, x)
						let value= ps[i].substring(x + 1)
						params[key] =value
					}
					//let part = ps[i].split('=')
					//params[part[0]] = part[1]
				}
			}
			if (param == null) {
				return params
			} else {
				if (param in params) { //避免返回undfinded
					return params[param]
				} else {
					return null
				}

			}

		},
		/* 		async getNextId() {
					let newId = -1
					await this.get(true, '/api/ds/getnextid/', null, res => {
						console.log(res.data.data)
						newId = res.data.data
					
					}, err => {
						newId = NaN
						this.$logic.tip.error('序列号获取失败：' + err.info || '未知错误')
			 
					})
					return newId
				}, */
		getNextId() {
			let group = this.$logic.user.unitGroup
			let unit = this.$logic.user.unitId
			let newId = ''

			//this.$logic.showLayer(true)
			Ajax.get(this.baseUrl + '/api/ds/getnextid/?unitGroup=' + group + '&unitId=' + unit, null,
				res => {
					if (res.code == 0) {
						newId = res.data
					} else {
						this.$logic.tip.error('序列号获取失败：' + res.info || '未知错误')
					}
					//	this.$logic.showLayer(false)
				}, err => {
					//	this.$logic.showLayer(false)
					this.$logic.tip.error('序列号获取失败：' + err.info || '未知错误')
				})
			return newId
		}


	}

}
export default logicHttp