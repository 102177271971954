import compBase from '../../public/comp.js'
import options from '../../public/options.js'



let base=[
{key:'title',text:'流程节点'},
{key:'objClass',text:'节点类型',type:'text',init:'node',target:'',bursh:true,bind:true,tip:'标识流程图中的元素类，连接点、线、节点',list: null},
{key:'nodeType',text:'节点类型',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeMode',text:'节点模式',type:'text',init:null,target:'',bursh:true,bind:true,tip:'有名称的方块还是只有图标的方式',list: null},
{key:'nodeIcon',text:'节点图标',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},

//{key:'nodeName',text:'节点名称',type:'text',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeWidth',text:'节点宽度',type:'number',init:0,target:'--node-width',bursh:true,bind:true,tip:'',list: null},
{key:'nodeHeight',text:'节点高度',type:'number',init:0,target:'--node-height',bursh:true,bind:true,tip:'',list: null},
{key:'nodeColor',text:'节点颜色',type:'color',init:null,target:'--node-color',bursh:true,bind:true,tip:'',list: null},
{key:'nodeFontSize',text:'节点字号',type:'select',init:'',target:'--node-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
{key:'nodeFontColor',text:'节点字色',type:'color',init:'#000000',target:'--node-font-color',bursh:true,bind:true,tip:'',list: null},

{key:'selected',text:'已选中状态',type:'text',init:false,target:'',bursh:true,bind:true,tip:'',list: null},

{key:'positionX',text:'节点X位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'positionY',text:'节点Y位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},

{key:'style',text:'节点样式',type:'json',init:{},target:'',bursh:true,bind:true,tip:'',list: null},

{key:'flowDefId',text:'流程定义ID',type:'select',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'versionId',text:'流程版本ID',type:'select',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'flowInsId',text:'流程实例ID',type:'select',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeInsId',text:'节点实例ID',type:'select',init:0,target:'',bursh:true,bind:true,tip:'',list: null},

{key:'formId',text:'节点表单',type:'select',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'accessGroup',text:'权限场景',type:'access',init:'',target:null,bursh:true,bind:true,tip:'',list:null},

{key:'variableMap',text:'变量映射',type:'json',init:[],target:'',bursh:true,bind:true,tip:'',list: null},

{key:'title',text:'流程规则'}, 	
{key:'nodeRule',text:'节点规则',type:'noderule',init:null,target:null,bursh:true,bind:true,tip:'',list: null},


]
let special=[
{key:'nodeEvent',text:'后端事件',type:'nodeevent',init:null,target:null,bursh:true,bind:true,tip:'',list: null},
	
]


let events=[
/* 	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]



let flownode= {
	data:{
		type:'flownode', 
	},
	delete:'',
	hide:'objClass,nodeType,selected,nodeMode,nodeIcon,style,nodeWidth,nodeHeight,positionX,positionY,formId,variableMap,flowDefId,versionId,flowInsId,nodeInsId,accessGroup',
	base:base,
	special :special,
	action: []  //options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default flownode