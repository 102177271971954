import config from '../components/form/config/config.js'
class PageUtil {

	setModelProxy(node) { //如果不存在代理对象，增加代理，如果存在，只增加组件的特有方法
		let noProxy = node._proxy ? false : true
		let proxyObj = node._proxy
		if (noProxy) { //
			proxyObj = {}
			node._proxy = proxyObj
		}

		for (let key in node) {
			if (key.indexOf('_') === 0) { //系统内部对象，忽略
				continue
			}
			let value = node[key]
			let x = key.indexOf('$')
			if (x === 0 && typeof(value) != 'function') { //只添加$开头的函数，组件自有的方法
				continue
			} else if (x === 0 && typeof(value) == 'function') { //

			}
			if (x === 0) {
				if (typeof(value) == 'function') { //组件的扩展方法加入对象			
					let pname = key.substring(1)
					if (pname in node) { //方法名如有冲突使用带$的方法名
						pname = key
					}
					proxyObj[pname] = node[key]
				} else { //非函数不作处理,组件内部的属性，跳过
					continue
				}
			} else { //加入代理属性
				if (noProxy || !(key in proxyObj)) {

					Object.defineProperty(proxyObj, key, { //Proxy被VUE劫持，换这种写法
						get() {
							return node[key]
						},
						set(value) {
							//如果是value属性赋值，调用赋值方法，解决obj.value=value,组件内部值并不生效的问题
							if (key === 'value' && node.$value) {
								node.$value(value)
							} else {
								if (node.$set) {
									node.$set(key, value)
								} else {
									node[key] = value
								}
							}

						}
					})
				}
			}



		}

	}


	getFrom(pageModel, formId) {
		let formObj = null
		if (typeof(formId) == 'object' && formId.type === 'form') { //如果传入的是表单组件，直接返回
			return formId
		}
		let id = formId.indexOf('#') == 0 ? formId.substring(1) : formId
		let stack = [].concat(pageModel.items)

		while (stack.length > 0) {
			let item = stack.pop()
			if (item.id == id) {
				formObj = item
				break
			}
			if (item.items) {
				for (let nd of item.items) {
					stack.push(nd)
				}
			}

		}
		return formObj
	}
	clearValidate(pageModel, formId) {
		let form = this.getFrom(pageModel, formId)
		if (!form) {
			return
		}
		let stack = [].concat(form.items)
		while (stack.length > 0) {
			let item = stack.pop()
			if ('tipShow' in item) {
				item.tipShow = 'N'
			} else if (item.$tipHide) {
				item.$tipHide()
			}
			if (item.items) {
				for (let sub of item.items) {
					stack.push(sub)
				}
			}
		}

	}
	formValidate(pageModel, formId) {
		let check = true
		let infoList = []
		let form = this.getFrom(pageModel, formId)
		if (!form.dbTable) {
			check = false
			infoList.push('未指定表单的数据库表名')
		}
		let tips = ''
		let stack = [].concat(form.items)
		while (stack.length > 0) {
			let item = stack.pop()
			if (item.isInput) {
				if (item.$validate) {
					let ck = item.$validate()
					if (ck.success === false) {
						check = false
						infoList.push(ck.info)
						tips = tips + ck.info + ' 。 '
					}
				}
			}

			if (item.items && item.items.length > 0) {
				for (let c of item.items) {
					stack.push(c)

				}

			}
		}
		return {
			success: check,
			info: infoList,
			describe: tips
		}

	}
	/* 
			checkFormData2(formData) {
				let result = {
					code: 0,
					info: null
				}
				return
				for (let form of formData.forms) {
					if (form.headList.length < 1) {
						return {
							code: 1,
							info: '数据表[' + form.table + '] 空数据提交，请确认'
						}
					}
					for (let row of form.dataList) { //检查每行数据是否符合数据表字段要求
						for (let i = 0; i < form.headList.length; i++) {
							let head = form.headList[i]
							let value = row.dataCols[i]
							if (head.refuseNull == 1) { //非空字段
								if (value == null) {
									return {
										code: 1,
										info: '数据表[' + form.table + ']字段[' + head.fieldName + ']不允许为空，请确认'
									}
								} else {
									if (value == '' && (head.unionGroup == 'num' || head.unionGroup == 'time')) {
										//空内容作null处理
										row.dataCols[i] = null
										
									}
								}
							}
						}
					}
				}
				return result
			}
	 */
	getFormData(pageModel, formId, sqlAdd = []) { //fromId:#12或12,如果不传，
		let fields = []
		let formData = {
			fields: [],
			subTables: [],
			uploads: [],
			workAdd: sqlAdd
		}
		//let id = formId.indexOf('#') == 0 ? formId.substring(1) : formId
		let formObj = this.getFrom(pageModel, formId)
		if (formObj == null) {
			alert('不存在的表单ID')
			return formData
		}
		//
		formData = {
			...formData,
			compName: formObj.name,
			tableName: formObj.dbTable,
			dataId: formObj.dataId,
			dataPid: formObj.dataPid,
			idField: formObj.idField,
			pidField: formObj.pidField,


		}
		let stack = [].concat(formObj.items)
		while (stack.length > 0) {
			let item = stack.pop()
			if ((item.isInput === 'S' || item.isInput === 'L') && item.save === 'Y') { //S单值，M多值，L数组,T表格
				let field = {}
				for (let key in item) {
					let prop = item[key]
					if (prop && typeof prop == 'object' && 'fieldName' in prop) { //找到数据表字段的属性项
						field = prop
						break
					}
				}
				if (item.isInput === 'S') {
					//单值类型分普通值和数组对象,例如图片上传组件，如果是数组，转成字符串

					let value = item.$value()
					if (Array.isArray(value)) {
						if (item.type === 'imgupload') { //增加删除的文件
							let deletes=[]
							let list = item.$deleteList()
							for (let item of list) {
								item = {
									...item
								}
								item.url = '-' + item.url//加删除标记
								deletes.push(item)
							}
							value = [].concat(value).concat(deletes)
						}
						value = JSON.stringify(value)
					}
					fields.push(this.getFieldData(item, value, field))
				} else {
					let value = item.$value()
					let rs = ''
					if (value) {
						for (let li of value) {
							if (li === null || li === '') { //排除空值,复选框组件有自动产生空项结果的问题
								continue
							}
							if (rs.length > 0) {
								rs = rs + ','
							}
							rs = rs + li
						}
					}
					fields.push(this.getFieldData(item, rs, field))
				}


			} else if (item.isInput === 'M' && item.save === 'Y') {
				let cfgs = config.getDef(item.type)
				for (let cfg of cfgs.base) {
					if (cfg.type === 'field') {
						let field = item[cfg.key]
						if (field) { //设置过字段&& field.fieldName
							let value = item[cfg.attribute]
							fields.push(this.getFieldData(item, value, field, cfg.attribute))
						}
					}
				}

			} else if (item.type === 'upload' && item.save === 'Y') { //文件上传数据

				let upload = {
					compName: item.name,
					dbTable: item.dbTable,
					idField: item.idField,
					pidField: item.pidField,
					groupName: item.groupName,
					fileNameField: item.fileNameField,
					localPathField: item.localPathField,
					fileSizeField: item.fileSizeField,
					createTimeField: item.createTimeField,
					creatorIdField: item.creatorIdField,
					groupNameField: item.groupNameField,
					fileList: item.$value()
				}

				formData.uploads.push(upload)

			} else if (item.type === 'inputlist' && item.save === 'Y') { //子表数据
				let list = {
					compName: item.name,
					tableName: item.dbTable,
					dataPid: formObj.dataId,
					idField: item.idField,
					pidField: item.pidField,
					rows: [],
					deleteList: []
				}

				let hasCol = false //是否具有可提交保存的列,如果没有不作处理
				for (let row of item.$value()) { //多行
					let rowData = {
						dataId: row.dataId$,
						fields: []
					}
					for (let col of item.items) { //只加入允许保存的列
						if (item.save === 'Y') {
							hasCol = true
							let value = row[col.dataName]
							let colItem = this.getFieldData(col, value, col['fieldName'])
							rowData.fields.push(colItem)
							/* if (col.fieldName) { //含有字段属性的才提交
							
							} */
						}
					}
					list.rows.push(rowData)


				}
				//加入待删除的数据ID
				let deleteList = item.$getDeleteList()

				list.deleteList = [].concat(deleteList)
				if (hasCol) { //有列数据的情况下才提交子表数据
					formData.subTables.push(list)
				}

			} else {

			}
			if (item.items) {
				for (let nd of item.items) {
					stack.push(nd)
				}
			}
		}
		formData.fields = fields
		return formData

	}
	getFieldData(item, value, field, paramName = null) {
		return {
			id: item.id,
			name: item.name,
			value: value,
			paramName: paramName,
			compType: item.type, //组件类型 
			fieldName: field.fieldName,
			fieldType: field.fieldType,
			fieldUnionGroup: field.unionGroup,
			fieldUnionName: field.unionName,
			refuseNull: field.refuseNull //字段是否允许为空
		}
	}




	/* 	getFormData(rootElements = null, formId = null, formModel = null, parentForm = null) { //获取指定表单容器内的数据
			let form = null
			if (formModel) {
				form = formModel
			} else {
				let objs = this.getElements(rootElements, formId.indexOf('#') == 0 ? formId : '#' + formId)
				if (objs.length < 1) {
					return null
				}

				form = objs[0]
			}
			let forms = [{
				table: form.dbTable,
				parentTable: parentForm ? parentForm.dbTable : null,
				fieldId: form.dbTableId, //ID字段名称 
				fieldPid: form.dbTablePid, //父Id值字段名称，如果此值为空，保存时忽略此字段
				dataPid: parentForm ? parentForm.dataPid : '0', //父Id值，如父Id值字段名称有效，此值不可为0
				headList: [], //一维数据表
				dataList: [ //二维数据表,可能多行数据,表单本身只有一维，子表数据二维
					{
						id: form.dataId,
						dataCols: []
					}
				],

			}]
			let formItem = forms[0]
			let formData = formItem.dataList[0]
			let sk = [].concat(form.items)
			while (sk.length > 0) {
				let el = sk.pop()
				if (el.type == 'form') {
					let subData = this.getFormData(null, null, el, form)
					for (let item of subData) {
						forms.push(item)
					}

				} else { //主表数据
					if (el.$getValue) { //如果是可取数的元素
						let valueItems = el.$getValue()
						for (let item of valueItems) {
							if (!item.fieldName) { //没有配置过字段关系的忽略
								continue
							}
							let head = {
								...item
							}
							delete head.dataValue
							formItem.headList.push(head)
							formData.dataCols.push(item.dataValue)
						}


					}
					if (el.items) {
						for (let nd of el.items) {
							sk.push(nd)
						}
					}
				}
			}
			return forms
		}
		
		 */
	//取单元素,返加代理对象
	getElement(rootElements, id) {

		let rs = null
		let sk = [].concat(rootElements)
		while (sk.length > 0) {
			let node = sk.pop()
			if (node.id == id) {
				rs = node
				break
			}
			if (node.items) {
				for (let nd of node.items) {
					sk.push(nd)
				}
			}
		}
		return rs ? rs._proxy : null

	}
	//在父元素下查找指定的子元素,返回原生对象
	getElementById(parentObj, id) {
		let obj = null
		if (parentObj && ('items' in parentObj)) {
			let list = this.getElements(parentObj.items, id, true)
			if (list.length > 0) {
				obj = list[0]
			}
		}
		return obj

	}

	findElementFromBindData(bindData, id) {
		let panels = []
		for (let pkey in bindData.$cellModel) {
			let item = bindData.$cellModel[pkey]
			if (item && (typeof item === "object") && item.type) {
				panels.push(item)
			}
		}
		let obj = this.findFirstElementById(panels, id)
		return obj
	}
	//从一组容器中查找指定ID的对象
	findFirstElementById(panels, id, widthTemplate = false) { //是否包括模板容器
		let stack = [].concat(panels)
		while (stack.length > 0) {
			let item = stack.pop()
			if (item.id === id) {
				return item
			}
			if (item.items) {
				for (let sub of item.items) {
					stack.push(sub)
				}
			}
			if (widthTemplate) {
				if (item.$getSubPanels) { //具有内部自定义容器的的元素
					for (let sub of item.$getSubPanels()) {
						stack.push(sub)
					}
				}
			}
		}
		return null

	}
	//支持按类型获取一组元素或按ID获取唯一的元素，.开头表示取些类对象，规则同css,onlyFirst按类型查找有效只返回第一个，如果是ID取元素只返回一个
	getElements(rootElements, filter, onlyFirst = false) {
		let rs = []
		filter = filter.trim()
		let x = filter.indexOf('.')
		let filterType = x == 0 ? 'type' : 'id'
		let fielerValue = x == 0 ? filter.substring(1) : filter
		if (filterType == 'id') { //如果按ID查找只找一个
			onlyFirst = true
		}
		let sk = [].concat(rootElements)
		while (sk.length > 0) {
			let node = sk.pop()
			if (node[filterType] == fielerValue) {
				rs.push(node)
				if (onlyFirst) { //如果是按ID找元素，找到即退出
					break
				}
			}
			if (node.items) {
				for (let nd of node.items) {
					sk.push(nd)
				}
			}
		}
		return rs
	}
}
export default new PageUtil()