<template>
<div class="container">
    <div class="calendar">
        <template v-for="month in calendar.months" >
            <div class="month">{{month.year}}年{{month.month}}月</div>
            <div class="weekbar">
                <template v-for="item in ['日','一','二','三','四','五','六']" >
                    <div>{{item}}</div>
                </template>
            </div>
            <template v-for="week in month.weeks" >
                <div class="week">
                    <template v-for="item in week" >
                        <div  v-if="item.day==0"> </div>
                        <div v-else :class="item.date<calendar.today.date?'dayitem before':'dayitem' " :style=" item.color? 'color:'+ item.color:'' "  @click="dayclick(item)">
                           <div class="head" :style="item.head?'color:'+item.head.color:''">{{item.head?item.head.text:''}}</div>
                           <template v-if="item.num">
                                <div style="position: relative;">
                                    {{ item.day }}
                                    <div class="noclass num">
                                        {{item.num}}
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.point">
                                <div style="position: relative;">
                                    {{ item.day }}
                                    <div :class="item.point.icon +' point' " :style="item.point.color?'color:'+item.point.color:''"></div>
                                </div>
                            </template>
                            <template v-else>{{ item.day }}</template>
                             <div class="foot" :style="item.foot?'color:'+item.foot.color:''">{{item.foot?item.foot.text:''}}</div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </div>
</div>
</template>

<script>
	export default {
		props: {
			dateRange: {
				type: Object,
				default: {}
			},
			styleMode:{
				type:String,
				default:'transparent'
			}
		},
		data() {
			return {
				calendar: []
			}
		},

		methods: {
			loadData() {
				let dateRange = this.dateRange
				if (!dateRange.startDate) {
					this.calendar = []
					return
				}

				let now = new Date();
				let today = {
					year: now.getFullYear(),
					month: now.getMonth() + 1,
					day: now.getDate(),
					week: now.getDay(),
					date: 0,
				};
				today.date = today.year * 10000 + today.month * 100 + today.date;
			let dateS = new Date(dateRange.startDate); //组件传值如果是日期对象会丢
				let dateE = new Date(dateRange.endDate);
				let ymS = dateS.getFullYear() * 100 + dateS.getMonth();
				let ymE = dateE.getFullYear() * 100 + dateE.getMonth();

				let yearS = dateS.getFullYear();
				let monthS = dateS.getMonth();
				let dayS = dateS.getDate();
				let weekS = dateS.getDay(); //起始星期,0-6

				let yearE = dateE.getFullYear();
				let monthE = dateE.getMonth();
				let dayE = dateE.getDate();
				let dayCount = weekS; //顺序累加取余计算星期几
				let items = [];
				for (let month = ymS; month <= ymE; month++) {
					let year = parseInt(month / 100);
					let m = month % 100;
					let lastDay = this.$logic.date.getMonthLastDay(m + 1);
					for (let day = 1; day <= lastDay; day++) {
						if (year == yearS && m == monthS && day < dayS) {
							//过滤掉开始日期之前的
							continue;
						}
						if (year == yearE && m == monthE && day > dayE) {
							//超出截止日期退出
							break;
						}
						items.push({
							year: year,
							month: m + 1,
							day: day,
							week: dayCount % 7,
							date: null,
							point: null,
							color: null,
							num: null,
							head: null,
							foot: null,
							exData: null
						});
						dayCount++;
					}
				}

				let days = [];
				let month = -1;
				let pday = null;
				for (let item of items) {
					//每个月填充空白星期数据
					if (month != item.month) {
						//月份切换
						if (pday) {
							//填满上个月的尾巴空白
							for (let i = pday.week + 1; i < 7; i++) {
								days.push({
									year: pday.year,
									month: pday.month,
									day: 0,
									week: i,
									date: null,
									point: null,
									color: null,
									num: null,
									head: null,
									foot: null,
									exData: null
								});
							}
						}
						//填满当前月的头部空白
						for (let i = 0; i < item.week; i++) {
							days.push({
								year: item.year,
								month: item.month,
								day: 0,
								week: i,
								date: null,
								point: null,
								color: null,
								num: null,
								head: null,
								foot: null,
								exData: null
							});
						}

						month = item.month;
					}
					days.push(item);
					pday = item;
				}
				//填满最后一个月的尾巴空白
				for (let i = pday.week + 1; i < 7; i++) {
					days.push({
						year: pday.year,
						month: pday.month,
						day: 0,
						week: i,
						date: null,
						point: null,
						color: null,
						num: null,
						head: null,
						foot: null,
						exData: null
					});
				}
				//按每月每周分组
				let calendar = {
					today: today,
					months: []
				};
				let mItem = null;
				let weekItems = null;
				month = -1;
			
				let ct = 0;
				for (let item of days) {
					if (item.month != month) {
						mItem = {
							year: item.year,
							month: item.month,
							weeks: []
						};
						calendar.months.push(mItem);
						month = item.month;
					}
					if (item.week == 0) {
						weekItems = [];
						mItem.weeks.push(weekItems);
					}
				
					item.date = item.year * 10000 + item.month * 100 + item.day;
					item.point = null
					item.color = null
					item.num = null
					item.head = null
					item.foot = null
					item.exData = null

					weekItems.push(item);
					for (let tag of dateRange.dates) {
						//特殊标记
						if (tag.date == item.date) {
							ct++
							if (tag.color) {
								item.color = tag.color
							}
							if (tag.point) {
								item.point = tag.point //'iconfont icon-postgresql'
							}
							if (tag.num) { //数字比图标point优先
								item.num = tag.num
							}
							if (tag.head) {
								item.head = tag.head
							}
							if (tag.foot) {
								item.foot = tag.foot
							}
							if (tag.exData) {
								item.exData = tag.exData
							}
						}
					}
				}

				this.calendar=calendar
				
			}

		},
		watch: {
			dateRange(newValue, oldValue) {
				this.loadData()
			}
		},
		created() {
			this.loadData()


		}
	}
</script>

<style scoped>
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
	 color: #333333;
}
.calendar{
    width:calc(100% - 20px) ;
    
    
}
.month{
    
    text-align: center;
    font-size: 30px;
    color: #333333;
   border: solid 1px #eeeeee;
    padding: 5px;
	background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
	
}
.weekbar{
    background-color: #eeeeee;
    display: flex;
    font-size: 25px;
    padding: 0px;
	background-color: rgba(255, 255, 255, 0.8);
	border-left: solid 1px #eeeeee;
	border-right: solid 1px #eeeeee;
	
}
.weekbar div{
    flex: 1;
    padding: 5px;
    text-align: center;
	
}
.week{
    display: flex;
    color: #333333;
    font-size: 18px;    
    

}
.week>div{
   flex: 1; 
   text-align: center;
   padding: 0px;
   border: solid 1px #eeeeee;
 /*   height: 100px; */
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);

}
.dayitem{
	  cursor: pointer;
}
.dayitem:hover{
	color: #409EFF;
	 background-color: rgba(235, 238, 245, 0.8);
}
.before{
    color: #888888;
}
.head{
    height: 16px;
font-size: 14px;
line-height: 16px;
overflow: hidden;
}
.foot{
    height: 16px;
    font-size: 14px;
    line-height: 16px;
	overflow: hidden;
}
.point{
position: absolute;
top:-10px ;
right: -30px ;
}
.num{
    top:5px ;
    right: -20px ;
}
</style>
