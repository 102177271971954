
export default [
{id: 1,pid: 0,label: '江苏',price: 4355,count: 2232,total: 97310,pay:66888,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 2,pid: 0,label: '上海',price: 46463,count: 783,total: 4879,pay:66447,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 3,pid: 0,label: '广州',price: 4653,count: 99386,total: 33918,pay:74634,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 4,pid: 1,label: '南京',price: 43564,count: 8377,total: 8828,pay:9732,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 5,pid: 1,label: '苏州',price: 3466,count: 7393,total: 9918,pay:82762,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 6,pid: 2,label: '徐汇区',price: 3462,count: 9229,total: 4418,pay:8276,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 7,pid: 2,label: '浦东区',price: 5327,count: 234944,total: 5528,pay:93731,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 8,pid: 2,label: '静安区',price: 83737,count: 99864,total: 2228,pay:8651,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 9,pid: 3,label: '开发区',price: 837458,count: 7366,total: 345648,pay:4564,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 10,pid: 4,label: '江宁',price: 93828,count: 63536,total: 23548,pay:7537,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 11,pid: 4,label: '雨花',price: 273688,count: 3566,total: 4348,pay:4357,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 12,pid: 7,label: '沪东街道',price: 847355,count: 93833,total: 54318,pay:8566,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
{id: 13,pid: 7,label: '张江镇',price: 22766.1244,count: 73666,total: 23628,pay:5588,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},

] 


				/*

								 				[
													{id: 1,pid: 0,label: '江苏',price: 4355,count: 2232,total: 97310,pay:66888,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 2,pid: 0,label: '上海',price: 46463,count: 783,total: 4879,pay:66447,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 3,pid: 0,label: '广州',price: 4653,count: 99386,total: 33918,pay:74634,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 4,pid: 1,label: '南京',price: 43564,count: 8377,total: 8828,pay:9732,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 5,pid: 1,label: '苏州',price: 3466,count: 7393,total: 9918,pay:82762,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 6,pid: 2,label: '徐汇区',price: 3462,count: 9229,total: 4418,pay:8276,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 7,pid: 2,label: '浦东区',price: 5327,count: 234944,total: 5528,pay:93731,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 8,pid: 2,label: '静安区',price: 83737,count: 99864,total: 2228,pay:8651,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 9,pid: 3,label: '开发区',price: 837458,count: 7366,total: 345648,pay:4564,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 10,pid: 4,label: '江宁',price: 93828,count: 63536,total: 23548,pay:7537,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 11,pid: 4,label: '雨花',price: 273688,count: 3566,total: 4348,pay:4357,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 12,pid: 7,label: '沪东街道',price: 847355,count: 93833,total: 54318,pay:8566,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
													{id: 13,pid: 7,label: '张江镇',price: 22766.1244,count: 73666,total: 23628,pay:5588,ex0:0,ex1:0,ex2:0,ex3:0,ex4:0,ex5:0,ex6:0,ex7:0,ex8:0,ex9:0},
												] 
				*/