<template>
	<div class="contentflex">
		<div class="leftpanel">
			<logictree ref="dataTree" :nodes="nodes" :allow-edit="true" :allowDrag="true" :allowSearch="true"
				@init="dataTreeInit" @nodeclick="dataNodeClick" @add="dataNodeAdd" @rename="dataNodeRename"
				@nodedrop="dataNodeDrop" @delete="dataNodeDelete">
			</logictree>

		</div>
		<div class="rightpanel">
			<titlebar :title="nodeObj.textPath" icon="far fa-layer-group"></titlebar>
			<div class="rightbody">
				<div class="editarea">
					<template v-for="item in items">
						<div v-if="item.type=='access'" style="width:100%;display: flex;">
							<div style="width: 100px;text-align: right;padding-right: 10px; ">{{item.label}}</div>
							<div style="width:calc(100% - 110px);">
								<div
									style="border: solid 1px #cccccc;border-radius: 5px;padding: 10px;background-color: #ffffff; ">


									<access-objs :access-name="nodeObj.textPath" :data-item="dataItem"
										:param-name="item.key" :project="project" emptyText="不限" />
								</div>

							</div>

						</div>
						<template v-else-if="item.type=='json'">
							<!-- <div style="width:100%;display: flex;">
								<div style="width: 100px;text-align: right;padding-right: 10px; ">{{item.label}}</div>
								<div style="width:calc(100% - 110px);">
									json

								</div>

							</div> -->
						</template>

						<div v-else class="edititem">
							<div class="editlabel">{{item.label}}</div>
							<div class="editinput">
								<el-input v-if="item.type==='icon'" type="text" @click="tosetIcon(item)"
									v-model="dataItem[item.key]" class="datainput" prefix-icon="Aim" />
								<div v-else-if="item.type=='color'"
									style="display: flex;justify-content: space-between">
									<input type="text" v-model="dataItem[item.key]" class="datainput"
										:style="{width:'calc(100% - 40px )',height:'20px'}" />
									<inputcolor v-model="dataItem[item.key]" size="small" :alpha="false" />
								</div>

								<!-- 约定规则，如果是is开头的key值，为开关类型输入 -->
								<el-switch v-else-if="item.type=='switch'" v-model="dataItem[item.key]" inline-prompt
									:width="60" active-color="#13ce66" inactive-color="#C0C4CC" active-text="是"
									inactive-text="否" active-value="Y" inactive-value="N" size="default" />

								<input v-else type="text" v-model="dataItem[item.key]" class="datainput" />

							</div>
						</div>
					</template>

				</div>
				<div v-show="showData" class="dataarea">
					<codeeditor v-model="jsonResult" :themetool="false" :fontsize="'16px'" :readonly="true">
					</codeeditor>
				</div>
			</div>
		</div>
	</div>
	<iconfont ref="iconfont" @select="faIconSelect"></iconfont>
</template>

<script>
	import AccessObjs from '../form/AccessObjs.vue'
	export default {
		props: {
			showData: {
				type: Boolean,
				default: true
			},
			project: {
				type: Object
			},
			title: {
				type: String,
				default: '数据管理'
			},
			jsontype: {
				type: String
			},
			'treemap': {
				type: Object
			},
			'datamap': {
				type: Object
			},
			'datas': {
				default: []
			}
		}, //treemap:{id:'',label:'',icon:''},keymap:{XX:'文本'}
		emits: ['init'],
		data() {
			return {
				nodes: [],
				dataTreeObj: {},
				nodeObj: {},
				keyIdx: 0

			}
		},

		methods: {
			faIconSelect(value) {
				this.dataItem.icon = value
				this.$refs.iconfont.hide()
			},
			tosetIcon(item) {
				this.$refs.iconfont.show()
			},
			getDatas() {
				let rs = null

				if (this.nodes.length > 0) {
					rs = JSON.stringify(this.nodes[0].dataItem.items)
				} else {
					rs = ''
				}
				return rs
			},
			findDataItem(dataItem) { //返回数据对象所在的list和索引地址
				let result = {
					list: null,
					idx: -1
				}
				let stack = [].concat(this.nodes)
				while (stack.length > 0) {
					let node = stack.pop()

					let items = node.dataItem.items || []
					result.idx = items.indexOf(dataItem)
					if (result.idx > -1) {
						result.list = items
						return result
					}

					/* for (let i = 0; i < items.length; i++) {
						let item = items[i]
						if (item === dataItem) {
							result.list = items
							result.idx = i

						}
					} */

					for (let sub of node.children) {
						stack.push(sub)
					}
				}
				return result
			},
			dataNodeDrop(nodeObj, target, place, event) { //place是node和target之间的关系

				let srcItem = nodeObj.data.dataItem
				let targetItem = target.data.dataItem

				let srcObj = this.findDataItem(srcItem)
				srcObj.list.splice(srcObj.idx, 1) //数据项先在原有的集合中移除
				let targetObj = this.findDataItem(targetItem) //先删除再查找，取到新数据下的目标节点的位置

				if (place == 'inner') { //放到目标节点内，直接加入
					let item = targetObj.list[targetObj.idx]
					if (item.items) {
						item.items.push(srcItem)
					} else {
						item.items = [srcItem]
					}

				} else {
					if (place == 'before') {
						targetObj.list.splice(targetObj.idx, 0, srcItem)
					} else if (place == 'after') {
						targetObj.list.splice(targetObj.idx + 1, 0, srcItem)
					} else {
						alert('未知操作：' + place)
					}

				}

			},
			dataNodeDelete(nodeObj) {
				let nodeId = nodeObj.node.data.id
				let parent = nodeObj.node.parent
				if (parent.level == 0) {
					this.$logic.tip.error('不允许删除根节点')
					return
				}
				//删除数据项
				let items = parent.data.dataItem.items
				for (let i = 0; i < items.length; i++) {
					let item = items[i]
					if (item === nodeObj.data.dataItem) {
						items.splice(i, 1)
						break
					}
				}
				//删除节点
				items = parent.data.children
				for (let i = 0; i < items.length; i++) {
					let item = items[i]
					if (item === nodeObj.data) {
						items.splice(i, 1)
						break
					}
				}




			},
			dataNodeRename(nodeObj, newName) {
				let nodeId = nodeObj.node.data.id
				nodeObj.data.dataItem[this.treemap.label] = newName
				nodeObj.data.label = newName

			},
			dataNodeAdd(nodeObj, newName) {
				let nodeId = nodeObj.node.data.id
				this.keyIdx++

				let newData = {}
				for (let item of this.items) {
					if (item.init) {
						if (typeof(item.init) == 'object') {
							newData[item.key] = this.$logic.clone.deepClone(item.init)
						} else {
							newData[item.key] = item.init
						}
					} else {
						newData[item.key] = null
					}


				}
				if (this.jsontype == 'jsontree') {
					newData.items = []
				}

				let items = []
				let newNode = {
					id: 'key' + this.keyIdx,
					label: newName,
					icon: '',
					dataItem: newData,
					items: items,
					children: items
				}
				let treeMap = this.treemap
				for (let key in treeMap) { //反向将树节点属性写入数据对象
					if (treeMap[key]) {
						newData[treeMap[key]] = newNode[key]
					}

				}
				if (this.jsontype == 'jsonarray') {
					let root = this.nodes[0]
					root.children.push(newNode)
					root.dataItem.items.push(newData)
				} else {
					if (nodeObj.data.dataItem.items) {

						nodeObj.data.dataItem.items.push(newData)
					} else {
						nodeObj.data.dataItem.items = [newData]
					}
					nodeObj.data.children.push(newNode)
				}
				this.$refs.dataTree.nodeClick(newNode.id)

			},
			dataNodeClick(nodeObj) {
				this.nodeObj = nodeObj

				//this.dataItem = nodeObj.data.dataItem

			},
			dataTreeInit(treeObj) {
				this.dataTreeObj = treeObj
			},
			flush() {
				this.setTreeDatas()
			},
			setTreeDatas() {
				//初始化清空当前数据项
				this.nodes = []
				this.nodeObj = {
					data: {
						dataItem: {}
					}
				}
				//this.dataItem = {} 
				let treeMap = this.treemap
				//增加一个根节点
				let ds = null
				try {
					ds = JSON.parse(this.datas) //必定是个字符串
				} catch (ex) {
					this.$logic.tip.error('数据错误，不是合法的JSON格式')
					ds = []
				}
				let list = ds.items ? ds.items : ds
				let rs = this.$logic.clone.treeClone(list, item => {
					let items = []
					return {
						id: item[treeMap.id],
						label: item[treeMap.label],
						icon: item[treeMap.icon],
						dataItem: item,
						items: items,
						children: items
					}
				})

				let root = {
					id: 'root____',
					label: this.title,
					icon: 'fas fa-cog',
					dataItem: {
						items: list
					},
					items: rs,
					children: rs
				}
				this.nodes = [root]
			}
		},
		computed: {
			dataItem() {
				return this.nodeObj.data.dataItem
			},
			jsonResult: {
				get() {
					let item = this.nodeObj.data.dataItem
					let rs = item && ('items' in item || 'key' in item) ? JSON.stringify(item) : ''

					return rs
				},
				set(val) { //禁止编辑器可编辑，实际执行不到
					/* 	let dataObj = null
						try {
							dataObj = JSON.parse(val)
						} catch (ex) {
							this.$logic.tip.error('JSON数据格式错误')
							return
						}
						let dataItem = this.nodeObj.data.dataItem
						let map = this.datamap
						for (let key in map) {
							dataItem[key] = dataObj[key]
						} */


				}
			},
			items() {
				let rs = []
				let map = this.datamap
				for (let key in map) {
					let value = map[key]
					let item = null
					if (typeof(value) == 'string') { //如果是字符串可以直接简定的方式，否则要用对象方式
						item = {
							key: key,
							type: 'text',
							label: value
						}
					} else {
						item = {
							...value,
							key: key
						}
					}
					rs.push(item)
				}
				return rs
			}

		},
		watch: {
			'nodeObj.data.dataItem': {
				deep: true,
				handler(nv, ov) {
					let nodeObj = this.nodeObj
					if (!nodeObj.node || nodeObj.node.level == 1) {
						return
					}
					//标签
					let node = nodeObj.node
					let label = nv[this.treemap.label] || '!' //必有
					if (label != node.data.label) {
						node.data.label = label
					}
					let icon = null //不一定有
					if (this.treemap.icon) {
						icon = nv[this.treemap.icon] || ''
						if (icon != node.data.icon) {
							node.data.icon = icon
						}
					}

				}
			},
			datas(newValue, oldValue) {
				this.setTreeDatas()
			}
		},
		components: {
			accessObjs: AccessObjs
		},
		created() {
			this.$emit('init', {
				getDatas: this.getDatas,
				flushDatas: this.flush
			})
			this.setTreeDatas()

		}
	}
</script>

<style scoped>
	.leftpanel {
		width: 300px;
		overflow: auto;
		height: calc(100% - 4px);
		background-color: #FFFFFF;
		border: solid 1px #cccccc;
		border-radius: 5px;
	}

	.rightpanel {
		width: calc(100% - 300px);
		overflow: auto;
		height: 100%;
	}

	.rightbody {
		height: calc(100% - 40px);
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.editarea {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		padding: 10px;
		width: calc(100% - 40px);
		height: 250px;
		background-color: #F0f0f0;
		border-radius: 20px;
		margin-top: 10px;
		/* height: calc(50% - 10px); */
		overflow-y: auto;
	}

	.dataarea {
		margin-top: 20px;
		margin-bottom: 0;
		padding: 10px;
		height: calc(100% - 250px - 40px);
		width: calc(100% - 40px);
		background-color: #F0f0f0;
		border-radius: 20px;
		overflow-y: auto;
	}

	.edititem {
		width: 50%;
		height: 30px;
		margin-top: 5px;
		margin-bottom: 5px;
		display: flex;
		align-items: center;
	}

	.editlabel {
		width: 100px;
		text-align: right;
		padding-right: 10px;
		font-weight: bold;
		/* height: 100%;
		line-height: 30px; */
	}

	.editinput {
		width: calc(100% - 100px);

	}

	.datainput {
		outline: none;
		width: 99%;
		border: solid 1px #CCCCCC;
		border-radius: 5px;
		height: 25px;
	}

	.datainput:focus {
		border: solid 1px #00aaff;
	}
</style>