import button from './button.js'
import deepClone from '../../../../../plugins/deepClone.js'
let sb=deepClone.deepClone(button)
sb.data.type='submit'
sb.data.ico='far fa-check'
sb.data.value='提交'
sb.data.color='success'
sb.hide='mode,listData'
sb.base[0].title='提交按钮'
for(let i=sb.action.length-1;i>-1;i--){//移除鼠标点击相关的事件
	let item=sb.action[i] 
	if('$event_click,$event_dblclick,$event_mousedown,$event_mouseup'.indexOf(item.key)>-1){
		sb.action.splice(i,1)
	}
}
let bases=[
	{key: 'title',text: '表单提交'	},
	{key: 'formId',text: '表单容器ID',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '指定用于提交的表单',list: null},
/* 	{key: 'ask',text: '提交前确认',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '提交前是否开启对话确认',list: null},
	{key: 'message',text: '确认提示',type: 'text',init: '是否确定提交',	target: '',	bursh: true,bind: true,tip: '提交前的对话确认提示消息',list: null},
	{key: 'tell',text: '提交后提示',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '提交后是否提示成功信息',list: null},
	{key: 'tip',text: '成功提示',type: 'text',init: '已成功提交',	target: '',	bursh: true,bind: true,tip: '提交成功后的提示消息',list: null},
	{key: 'menuTo',text: '提交后菜单',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '只适用于子页面的场景,提交成功后模拟点击指定的菜单项，转向到菜单对应的页面。请输入菜单项的key,如设定了此属性，[提交后转向]设置失效',list: null},
	{key: 'goto',text: '提交后转向',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '只适用于子页面的场景,提交成功后自动转向到指定的页面(当前页面是子页面时生效)，格式：#key',list: null},
	 */
]
let actions=[
	{key: 'title',text: '表单提交事件'	},
//	{key: '$comp_validateCustom',text: '自定义校验',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交前的用户自定义数据校验，返回false 将阻止提交并触发校验失败 ，返回 true 继续进行设定的其它规则校验',list: null	},
/* 	{key: '$comp_validateFailed',text: '验证失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '表单提交前的数据校验失败',list: null	},
	{key: '$comp_submitBefore',text: '提交前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '可进行提交前的处理（如数据校验），如返回false将阻止提交.',list: null	},
	{key: '$comp_submitAfter',text: '提交成功后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '提交成功后的处理(如页面转向)',list: null	},
	{key: '$comp_submitError',text: '提交失败后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '提交失败后的处理',list: null	},
	 */
]
sb.base=bases.concat(sb.base)
sb.action= actions.concat(sb.action)

export default sb
