import compBase from '../../public/comp.js'
import options from '../../public/options.js'
import extend from '../../public/extend.js'
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='change,input,blur' 
let base=[
	{key:'title',text:'签字板'},
	{key:'allowEdit',text:'开启手写',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'开启手写后点击弹出手写板，设计模式需双击打开',list:null},	 	
	{key: 'width',text: '内容区宽度',type: 'text',init: '100%',	target: 'width',bursh: true,bind: true,tip: '',list: null},
	{key: 'height',text: '内容区高度',type: 'text',init: '100px',	target: 'height',bursh: true,bind: true,tip: '',list: null},
	{key: 'minHeight',text: '最小高度',type: 'text',init: '50px',	target: 'min-height',bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '必须使用大文本类型字段保存签名图形数据内容',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

//	{key:'dotSize',text:'条码高度',type:'number',init:'60',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'minWidth',text:'最小线宽',type:'number',init:1,target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'maxWidth',text:'最大线宽',type:'number',init:10,target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'throttle',text:'节流',type:'number',init:16,target:'',bursh:true,bind:true,tip:'节流（每次绘制两个点之间的时间，单位为毫秒，默认值为16，注意设置过大会导致绘制卡顿',list:null},	 
	{key:'minDistance',text:'细腻度',type:'number',init:5,target:'',bursh:true,bind:true,tip:'两个点之间的最小距离',list:null},	 
	{key:'backgroundColor',text:'背景颜色',type:'color',init:'#ffffff',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'penColor',text:'字体颜色',type:'color',init:'#000000',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'velocityFilterWeight',text:'甩线拉细',type:'number',init:0.7,target:'',bursh:true,bind:true,tip:'根据速度控制线的粗细（默认值为0.7）',list:null},	 

 
	{key: 'padWidth',text: '画板宽度',type: 'text',init: '90vw',	target: '',bursh: true,bind: true,tip: '',list: null},
	{key: 'padHeight',text: '画板高度',type: 'text',init: '90vh',	target: '',bursh: true,bind: true,tip: '',list: null},
	{key: 'userInit',text: '用户初始化',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '开启后默认初始化为当前登录用户的签名信息',list: null},
	
/* 	
	{key:'value',text:'内容',type:'area',init:'BarCode12345678',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'format',text:'条码类型',type:'select',init:'CODE128',target:'',bursh:true,bind:true,tip:'',list:barTypes},

	{key:'barWidth',text:'条码宽度',type:'number',init:'180',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'barHeigth',text:'条码高度',type:'number',init:'60',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'itemWidth',text:'线条宽度',type:'select',init:'1',target:'',bursh:true,bind:true,tip:'竖线单位宽度',list:options.toOptions('1px=1,2px=2,3px=3,4px=4,5px=5')},	 

	{key:'shwoText',text:'显示文本',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'fixText',text:'自定义文本',type:'text',init:'',target:'',bursh:true,bind:true,tip:'如有值，会将此设置内容替换条码内容文本',list:null},	 
	{key:'fontType',text:'字体',type:'select',init:'Microsoft YaHei',target:'',bursh:true,bind:true,tip:'文本字体',list:options.fonts},
	{key:'textSize',text:'字体大小',type:'number',init:15,target:'',bursh:true,bind:true,tip:'设置文本字号',list:null},	 
	
	{key:'fontOptions',text:'字体样式',type:'select',init:'',target:'',bursh:true,bind:true,tip:'',list:[{label:'默认',value:''},{label:'加粗',value:'bold'},{label:'斜体',value:'italic'},{label:'加粗斜体',value:'bold italic'}]},
	{key:'textAlign',text:'文本对齐',type:'select',init:'center',target:'',bursh:true,bind:true,tip:'设置文本的水平对齐方式',list:[{label:'左侧',value:'left'},{label:'中间',value:'center'},{label:'右侧',value:'right'}]},
	{key:'textPosition',text:'文本位置',type:'select',init:'bottom',target:'',bursh:true,bind:true,tip:'设置文本的垂直位置',list:[{label:'顶部',value:'top'},{label:'底部',value:'bottom'}]},
	{key:'textMargin',text:'字&码距离',type:'number',init:5,target:'',bursh:true,bind:true,tip:'设置条形码和文本之间的间距',list:null},	 
	{key:'backColor',text:'背景颜色',type:'color',init:'#eeeeee',target:'',bursh:true,bind:true,tip:'设置条形码的背景色',list:null},
	{key:'lineColor',text:'内容颜色',type:'color',init:'#000000',target:'',bursh:true,bind:true,tip:'设置线条和文本的颜色',list:null},
	{key:'barMargin',text:'周边距离',type:'number',init:5,target:'',bursh:true,bind:true,tip:'设置条形码周围的空白边距',list:null},	 
	
 */
	
].concat(compBase.base)

let signpad= {
	data:{
		type:'signpad',isInput:'S',
	},
	delete:'',
	hide:'throttle,minDistance',
	base:base ,
	special : extend.tip,
	action:[
		{key: '$comp_sign',text: '签字完成',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '返回签字内容的图形数据',list: null	},
		
	].concat(options.getEvents(null,'$hook'))//options.getCommonEvents()
}

export default signpad