import font from './textStyle.js'
import util from '../util.js'
import options from '../../../../public/options.js'

let titleConfig=[
	{key:'title',text:'图表标题' },
	{key:'titleShow',text:'是否显示',type:'switch',init:'Y',path:'title.show',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'titleText',text:'标题文本',type:'text',init:'图表标题',path:'title.text',bursh:true,bind:true,tip:'',list:null},
	 {key:'titleTextStyleFontSize',text:'标题字号',type:'number',init:'16',path:'title.textStyle.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'titleTextStyleFontFamily',text:'标题字体',type:'select',init:'',path:'title.textStyle.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'titleTextStyleColor',text:'标题颜色',type:'color',init:options.initColor.text.dark,path:'title.textStyle.color',bursh:true,bind:true,tip:'',list:null},

	//{key:'titleLink',text:'标题链接',type:'text',init:'',path:'title.link',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
	//{key:'titleLink',text:'链接目标',type:'text',init:'',path:'title.target',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
/* 	{key:'titleAlign',text:'水平对齐',type:'select',init:'center',path:'title.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	{key:'titleVerticalAlign',text:'垂直对齐',type:'select',init:'top',path:'title.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
	{key:'titleLeft',text:'左侧偏移',type:'number',init:null,path:'title.left',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:null},
	{key:'titleRight',text:'右侧偏移',type:'number',init:null,path:'title.right',bursh:true,bind:true,tip:'绝对定位方式右侧定位',list:null},
	{key:'titleTop',text:'顶部偏移',type:'number',init:null,path:'title.top',bursh:true,bind:true,tip:'绝对定位方式顶定位',list:null},
	{key:'titleBottom',text:'底部偏移',type:'number',init:null,path:'title.bottom',bursh:true,bind:true,tip:'绝对定位方式底部定位',list:null},
 */
	{key:'titleAlign',text:'水平对齐',type:'select',init:'center',path:'title.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	{key:'titleVerticalAlign',text:'垂直对齐',type:'select',init:'top',path:'title.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},
//	{key:'titleTriggerEvent',text:'标题可点击',type:'switch',init:'Y',path:'title.triggerEvent',bursh:true,bind:true,tip:'',list:options.YON},
//	{key:'titlePadding',text:'标题内边距',type:'number',init:5,path:'title.padding',bursh:true,bind:true,tip:'',list:null},
//	{key:'titleItemGap',text:'主副间距',type:'number',init:10,path:'title.itemGap',bursh:true,bind:true,tip:'',list:null},
	 
	
]
/* .concat(util.getCloneConfig('title','title.textStyle',font.list)).concat([
	{key:'title',text:'副标题' },
	{key:'titleSubtext',text:'副标题文本',type:'text',init:'副标题',path:'title.subtext',bursh:true,bind:true,tip:'',list:null},
	{key:'titleSubAlign',text:'水平对齐',type:'select',init:'center',path:'title.subtextStyle.align',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	{key:'titleSubVerticalAlign',text:'垂直对齐',type:'select',init:'bottom',path:'title.subtextStyle.verticalAlign',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},

]).concat(util.getCloneConfig('titleSub','title.subtextStyle',font.list))
 */
export default {list:titleConfig} 