<template>
	<treechoose ref="choose" @nodeclick="nodeClick" @change="listChange" :node-list="nodeList" :data-list="dataList"
		:init-result="initResult" :max-size="maxSize" />
</template>

<script>
	export default {
		props: {
			initResult: {
				type: Object,
				default: [],
			},
			maxSize: { //0不限制个数
				type: Number,
				default: 0,
			}
		},
		emits: ['change', 'init'],
		data() {
			return {
				nodeList: [],
				dataList: []

			}
		},

		methods: {
			clearResult() {
				this.$refs.choose.clearResult()
			},
			getResult() {
				return this.$refs.choose.getResult()
			},
			listChange(list) {

				this.$emit('change', list)
			},
			nodeClick(node, withSub) {
				this.$logic.http.get(true, '/api/unit/manage/orguserlist/', {
					orgId: node.data.id,
					withSub: withSub ? 1 : 0,
					orderField: 'userName',
					orderType: 'asc'

				}, res => {
					let list = []
					for (let item of res.data.data.dataList) {
						list.push({
							key: item.id,
							label: item.userName,
							tip: item.userName + '[' + item.empCode + ']'
						})
					}
					this.dataList = list

				})
			},

			loadOrgTree() {
				this.$logic.http.get(true, '/api/unit/manage/gettreenodes/', {
					groupType: 'org'
				}, res => {
					this.nodeList = res.data.data


				})
			}

		},
		created() {
			this.loadOrgTree()
			let this_ = {
				clearResult: this.clearResult,
				getResult: this.getResult
			}
			this.$emit('init', this_)

		}
	}
</script>

<style scoped>

</style>
