<template>

	<div :class="model.showGrid==='Y'?'flow-panel flow-panel-grid':'flow-panel' " @mouseup="mouseUp"
		@mousemove.stop="mouseMove" :style="flowPanelStyle">

		<table :border="1" class="flow-lane noselect" cellspacing="0" cellpadding="0">
			<tr v-if="model.showLaneH==='Y'"
				:style="{height:model.laneHeightH,backgroundColor:model.laneBgColorH,fontSize:model.laneFontH}">
				<td v-for="(col,index) in flowLane.heads" :style="{width:col.width}"> {{col.text}} </td>

			</tr>

			<template v-for="row in flowLane.rows">
				<tr :style="{height:row.height}">
					<td
						:style="{width:model.laneWidthV, backgroundColor:model.showLaneV==='Y'?model.laneBgColorV:null,fontSize:model.laneFontV}">
						{{row.text}}
					</td>
					<td v-for="idx in flowLane.heads.length-1" class="lane-td"> </td>

				</tr>
			</template>



		</table>


		<svg class="flow-svg" @click.self="toCreate" @mousedown.self.stop="dragBoxBegin">
			<defs>
				<marker id="currentflowArrow" markerUnits="strokeWidth" markerWidth="8" markerHeight="5" refX="0"
					refY="2" orient="auto">
					<path d="M 0 0 L 5 2 L 0 4 z" stroke="#c71585" />
				</marker>

				<!-- 	<marker id="workflowarrow" markerUnits="strokeWidth" markerWidth="8" markerHeight="5" refX="0" refY="2"
					orient="auto">
					<path d="M 0 0 L 5 2 L 0 4 z" :stroke="model.lineColor" :fill="model.lineColor"  />
				 
				</marker>
				 -->

				<!-- 为了线条与箭头颜色一致，一对一定制箭头 -->
				<marker v-for="line in model.lineList" :key="line.id" :id="'arrow'+line.id" markerUnits="strokeWidth"
					markerWidth="10" markerHeight="10" refX="0" refY="2" orient="auto">
					<path d="M 0,0 L 5,2 L 0,4 L 2,2 L 0,0 z" :stroke="line.lineColor?line.lineColor:model.lineColor"
						:fill="line.lineColor?line.lineColor:model.lineColor" />

				</marker>

			</defs>

			<rect v-show="dragBox.show" :x="dragBox.left" :y="dragBox.top" :width="dragBox.width"
				:height="dragBox.height" style="stroke:#409EFF;stroke-width:1;fill:rgba(160,207,255,0.5);" />

			<path v-show="drawLine.show" :d="drawLine.path" stroke="#c71585" stroke-width="2" fill="none"
				style="marker-end: url(#currentflowArrow);" />
			<template v-for="line in model.lineList">
				<path :d="line.path" :stroke="line.lineColor?line.lineColor:model.lineColor"
					:class="line===currentObj?'flow-line-current':'flow-line'" stroke-width="2" fill="none"
					:style="'marker-end: url(#arrow'+line.id+');'" @mousedown.stop="lineSelect($event,line)"
					@click.stop="true" />

			</template>

		</svg>
		<!-- 		<div class="node-panel">
			

		</div> -->
		<div v-if="isedit" class="flow-tool  noselect" data-tag="item" :style="toolBar.style"
			@mousedown="dragStart($event,toolBar)">

			<template v-for="item in toolItems">
				<div :class="item===drawMode?'tool-item tool-mode': 'tool-item'" @mousedown.stop="setMode(item)">
					<div :title="item.tip">
						<i :class="item.icon"></i>
					</div>
					<!-- 	<div>{{item.label}}</div> -->
				</div>
			</template>


		</div>
		<template v-for="point in model.pointList">
			<div class="join-point" :ref="'node'+point.id" @mousedown.stop="pointSelect($event,point)"
				@click.stop="true" :style="point.style">

			</div>

		</template>

		<template v-for="label in labelList">
			<span :style="label.style"
				:class="currentObj && label.lineId===currentObj.id? 'line-label noselect label-current':'line-label noselect'"
				@mousedown.stop="labelSelect($event,label)" @click.stop="true">{{label.text}}</span>

		</template>


		<template v-for="node in nodeList">
			<div v-if="node.nodeMode==='task' && node.nodeType !='start'" :ref="'node'+node.id"
				:class="node===currentObj?'node-task noselect node-current':'node-task noselect' "
				@mousedown.stop="dragStart($event,node)" @mouseup="nodeMouseUp(node)" @click.stop="objSelect(node)"
				:style="node.style">
				<div class="node-content">

					<div class="node-icon">
						<i :class="node.nodeIcon"></i>

						<i v-if="node.nodeRule && node.nodeRule.upLoop==='Y'" title="循环向上审批"
							class="far fa-retweet-alt"></i>

						<i v-if="node.nodeRule && node.nodeRule.timeAllow==='Y'" title="限时处理任务"
							class="far fa-clock"></i>

						<!-- <i v-if="isedit && node.formId " class="fas fa-file-check" style="margin-left:5px;"></i>
				 -->
						<template v-if="!isedit">
							<!-- v-if="node.nodeInsId  v-else" -->
							<i @click="nodeAdmin(node,'close')" class="fas fa-stop-circle node-tool"></i>
							<i @click="nodeAdmin(node,'open')" class="fas fa-play-circle node-tool"></i>
						</template>
					</div>
					<div class="node-name">
						{{node.name}}
					</div>
				</div>

			</div>
			<div v-else :style="node.style" :ref="'node'+node.id"
				:class="node===currentObj || node.selected?'node-auto noselect node-current':'node-auto noselect' "
				@mousedown.stop="dragStart($event,node)" @mouseup="nodeMouseUp(node)" @click.stop="objSelect(node)">
				<span class="node-auto-icon">
					<i :class="node.nodeIcon"></i>
				</span>
			</div>
		</template>


	</div>
</template>


<script>
	import flowDesign from './FlowDesign.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase, flowDesign],
		emits:['nodeopen','nodeclose'],
		data() {
			return {

				selectedObjs: [],
				joinPointSize: 10,
				dragBox: {
					x: 0, //事件页面位置坐标，用于计算偏移量
					y: 0,
					x1: 0,
					y1: 0,
					top: 0,
					left: 0,
					width: 0,
					height: 0,
					show: false
				}
			}
		},
		methods: {
			nodeAdmin(node, opt) {
				this.$emit('node' + opt, node)
			},
			labelSelect(event, label) {
				let line = this.lineMap[label.lineId]
				this.dragStart(event, label)
				/* 	this.currentObj = line
					this.setCurrentElement(line) */
				this.objSelect(line)
			},
			pointSelect(event, point) {
				let line = this.lineMap[point.lineId]

				this.dragStart(event, point)
				/* this.currentObj = point
				this.setCurrentElement(line) */
				this.objSelect(line)
			},
			dragBoxBegin(e) {
				if (this.drawMode.key !== 'select') { //只在鼠标模式下生效
					return
				}
				this.dragBox.x = e.pageX
				this.dragBox.y = e.pageY
				this.dragBox.x1 = e.offsetX
				this.dragBox.y1 = e.offsetY
				this.dragBox.left = 0
				this.dragBox.top = 0
				this.dragBox.width = 0
				this.dragBox.height = 0
				this.dragBox.show = true

			},
			lineSelect(e, line) {
				if (e.ctrlKey) {
					let x = e.offsetX - this.joinPointSize / 2
					let y = e.offsetY - this.joinPointSize / 2
					let joinPoint = {
						objClass: 'point',
						//line: line, //归属于哪条线
						lineId: line.id, //用于查找线对象
						id: new Date().getTime(),
						positionX: x,
						positionY: y,
						selected: false,
						style: {
							left: x + 'px',
							top: y + 'px',
							opacity: 1
						}
					}
					this.pointMap[joinPoint.id] = joinPoint
					line.points.push(joinPoint)
					this.model.pointList.push(joinPoint)
					this.currentNode = joinPoint
					this.point.x = event.pageX
					this.point.y = event.pageY
					this.point.nodeX = x
					this.point.nodeY = y
					this.drawMode = this.toolItems[0] //切换鼠标模式

				}
				this.objSelect(line)
			},
			objSelect(obj) {
				if (!this.isedit) {
					return
				}
				this.currentObj = obj
				this.setCurrentElement(obj)
			},
			toCreate(event) {
				this.currentObj = null //面板上单击取消当前选择的对象
				let config = this.elementConfig
				if (!this.drawMode || (this.drawMode.mode !== 'task' && this.drawMode.mode !== 'auto')) {
					return
				}

				let obj = config.create('flownode', this.model, this.page.formData)
				this.nodeMap[obj.id] = obj
				obj.nodeType = this.drawMode.type
				obj.name = this.drawMode.label
				obj.nodeMode = this.drawMode.mode
				obj.nodeIcon = this.drawMode.nodeIcon
				obj.nodeFontColor = this.drawMode.color ? this.drawMode.color : null
				obj.positionX = event.offsetX - obj.nodeWidth / 2
				obj.positionY = event.offsetY - obj.nodeHeight / 2

				obj.style.left = obj.positionX + 'px'
				obj.style.top = obj.positionY + 'px'



				/* 	if (obj.nodeMode === 'auto') {
						obj.style.color = obj.nodeFontColor
					} */

				/* 		obj.style.width = obj.nodeWidth + 'px'
						obj.style.height = obj.nodeHeight + 'px' */
				//this.model.nodeList.push(obj)
				this.currentObj = obj
				//console.log(obj, this.model) 
			},

		},


		computed: {
			labelList() {
				let labels = []
				for (let line of this.model.lineList) {
					if (line.labelShow === 'Y') {
						let label = line.label
						label.text = line.labelText
						if (line.labelColor) {
							label.style.color = line.labelColor
						}
						labels.push(label)
					}

				}
				return labels
			},
			/* 		lineList() {
						let lines = []
						for (let line of this.model.lineList) {
							lines.push(line)
						}
						return lines
					}, */
			/* 			pointList() {
							let list = []
							let model = this.model
							for (let obj of model.pointList) {
								if (obj.selected) {
									obj.style.opacity = 1
								}
							}
							return list
						}, */
			nodeList() {
				let list = []
				let model = this.model
				for (let obj of model.items) {
					obj.style.left = obj.positionX + 'px'
					obj.style.top = obj.positionY + 'px'
					obj.style.fontSize = obj.nodeFontSize ? obj.nodeFontSize : model.nodeFontSize
					obj.style.color = obj.nodeFontColor ? obj.nodeFontColor : model.nodeFontColor
					if (obj.nodeMode === 'task') { //矩形节点						 
						let nodeColor = obj.nodeColor ? obj.nodeColor : model.nodeColor
						let color = this.$logic.color.getLightColor(nodeColor, 0.5)
						color = 'linear-gradient(0deg,' + color + ',' + nodeColor + ',' + color + ')'
						//color = 'radial-gradient( '+ nodeColor + ',' + color + ')'
						obj.style['--node-color'] = color
						//obj.style.background = obj.nodeColor ? obj.nodeColor : model.nodeColor
					}
					if (obj.selected) {
						obj.style.borderColor = 'red'
					} else {
						obj.style.borderColor = ''
					}
					list.push(obj)
				}
				return list
			},
			flowPanelStyle() {
				let css = this.compStyle
				let model = this.model
				let color = this.$logic.color.getLightColor(model.nodeColor, 0.5)
				css['--node-color'] = 'linear-gradient(0deg,' + color + ',' + model.nodeColor + ',' +
					color + ')'
				css['--node-width'] = model.nodeWidth + 'px'
				css['--node-height'] = model.nodeHeight + 'px'
				css['--flow-line-color'] = model.lineColor
				css['--lane-line-type'] = model.laneLineType
				css['--lane-line-color'] = model.laneLineColor
				css['--lane-line-width'] = model.laneLineWidth

				return css
			},
			flowLane() {
				let model = this.model
				let lane = {
					heads: [],
					rows: []
				}
				let laneCols = null
				let laneRows = null
				try {
					laneCols = typeof(model.laneH) == 'string' ? JSON.parse(model.laneH) : model.laneH
					laneRows = typeof(model.laneV) == 'string' ? JSON.parse(model.laneV) : model.laneV


				} catch (ex) {
					this.$nextTick(() => {
						this.$logic.tip.error('JSON字符串格式错误：' + ex)
					})
					return lane
				}
				if (model.showLaneH === 'Y') {
					for (let col of laneCols) {
						lane.heads.push(col)
					}
				} else { //不显示行泳道时只有一列					 
					lane.heads = [{
						width: null,
						text: ''
					}]
				}
				if (model.showLaneV === 'Y') { ////添加左上角空格子
					lane.heads.splice(0, 0, {
						text: '',
						width: model.laneWidthV
					})
					for (let row of laneRows) {
						lane.rows.push(row)
					}
				} else { //不显示列泳道时只有一行
					lane.rows = [{
						text: '',
						height: null
					}]
				}
				return lane


			}


		},
		watch: {

			currentObj(nv, ov) {

				if (ov) {
					if (ov.objClass === 'line') {
						for (let point of ov.points) {
							point.style.opacity = 0
						}
					} else if (ov.objClass === 'point') {
						ov.style.opacity = 0
					}

				}
				if (nv) {
					if (nv.objClass === 'line') {
						for (let point of nv.points) {
							point.style.opacity = 1
						}
					} else if (nv.objClass === 'point') {
						//point.selected = true
						nv.style.opacity = 1
						//this.selectedObjs.push(point)
					}

				}

			},
			/* 		currentNode(nv, ov) {
						if (ov && ov.objClass === 'point') {
							ov.style.opacity = 0
						}
						if (nv && nv.objClass === 'point') {
							nv.style.opacity = 1
						}
					} */
		},
		created() {
			this.initObject()


		}
	}
</script>

<style scoped>
	.flow-panel {
		/* 	width: var(--panel-width);
		height: var(--panel-height); */
		position: relative;

	}

	.flow-panel-grid {
		background-image: linear-gradient(90deg, rgba(241, 243, 244, 1) 5%, transparent 0),
			linear-gradient(rgba(241, 243, 244, 1) 5%, transparent 0);
		background-size: 20px 20px;
	}

	.flow-lane {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 0;
		border-color: #cccccc;
		border-collapse: collapse;

	}

	.flow-lane td {
		text-align: center;
	}

	/* 	.lane-head {
		background-color: rgba(232, 232, 232, 0.5);
		height: 40px;
	}

	.lane-head>td {
		text-align: center;
	}

	.lane-row {}

	.lane-row>td {
		border-style: dashed;
	}

	.lane-col0 {
		width: 50px;
		text-align: center;
		background-color: rgba(232, 232, 232, 0.5);
	}
 */
	.lane-td {
		border-style: var(--lane-line-type);
		border-color: var(--lane-line-color);
		border-width: var(--lane-line-width);
	}

	.flow-svg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 2;
	}

	.flow-tool {
		position: absolute;

		width: 36px;
		/*	height: 200px; */
		display: flex;
		flex-direction: column;
		align-items: center;
		border: solid 1px #cccccc;
		border-radius: 5px;
		padding: 20px 2px 0px 2px;
		cursor: move;
		z-index: 5;
	}

	/* .flow-tool:hover {
		background-color: #fbffaf;
	} */

	.tool-item {
		width: calc(100% - 10px);
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: solid 1px #cccccc;
		/* 		border-left: 0px;
		border-right: 0px; */
		/* 	border-radius: 5px; */
		background-color: #f5f5f5;
		color: #606266;
		cursor: pointer;
		padding: 5px 5px;
		margin-top: 0px;
	}

	.tool-item:hover {
		background-color: #fbffaf;
	}

	.tool-item i {
		font-size: 20px;
		/* margin-bottom: 5px; */
	}

	.tool-mode {
		color: #409EFF;
		background-color: #fbffaf;
	}

	.node-panel {
		width: 100%;
		height: 100%;
		position: relative;

		z-index: 1;

	}

	.node-task {
		position: absolute;

		width: var(--node-width);
		height: var(--node-height);
		/* padding: 5px 5px; */

		border: solid 1px #cccccc;
		border-radius: 5px;
		color: var(--node-font-color);
		background: var(--node-color);
		z-index: 3;
		--node-tool-show: none;
	}

	.node-task:hover {
		--node-tool-show: inline;
		background: linear-gradient(#fbffaf, #fbffaf, #fbffaf);
		color: #303133 !important;
	}

	.node-tool {
		display: var(--node-tool-show);
		margin-left: 5px;
		cursor: pointer;
		color: rgb(255, 69, 0);

	}



	.node-auto {
		position: absolute;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		z-index: 3;
	}

	.node-content {
		/*	position: relative;
	 	display: flex;
		align-items: center;
		justify-content: center; */
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.node-icon {
		/* position: absolute;
		left: 2px;
		top: 0px; */

		font-size: 16px;
		color: var(--node-icon-color);
		z-index: 0;
	}

	.node-icon i {
		margin-left: 5px;
	}

	/* 	.node-form{
		position: absolute;
		right: 2px;
		top: 0px;
		font-size: 16px;
		color: var(--node-icon-color);
		z-index: 0;
	} */

	.node-auto-icon {
		font-size: 32px;
	}

	.node-name {
		/* z-index: 10;
		 */
		line-height: 16px;
		margin-top: -4px;
		text-align: center
	}

	.node-current {
		background: linear-gradient(#fbffaf, #ffff7f, #fbffaf);
		border: solid 1px #ff0000;
	}



	.join-point {
		position: absolute;
		background: radial-gradient(#ff0000, #ff007f, #ffaaff);
		width: 10px;
		height: 10px;
		border-radius: 100%;
		z-index: 3;

	}

	.flow-line {
		/* stroke: var(--flow-line-color); */
	}

	.flow-line:hover {
		stroke: #c71585;
	}

	.flow-line-current {
		stroke: #c71585 !important;
	}

	.line-label {
		position: absolute;
		z-index: 4;

	}

	.label-current {
		color: #c71585 !important;
	}
</style>