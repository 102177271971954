<template>
	<template v-if="model.type=='part_flct'">
		<wordrotate   :chartid="chartId" :words="chartData" :vertical="model.vertical"
			:hoverstop="model.hoverStop=='Y'" :fontmin="model.fontMin" :fontmax="model.fontMax"
			:fontlight="model.fontLight" :worditalic="model.wordItalic=='Y'" :wordopacity="model.wordOpacity"
			:speedmin="model.speedMin" :speedmax="model.speedMax" :backcolor="model.wordBack"
			 :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
			 	:datas="datas"
			 
			 >

		</wordrotate>
	</template>
</template>

<script>
	import WordRotate from './WordRotate.vue'

	import CommEvent from '../../../../commevent.js'
	import formBase from '../../../../formbase.js'
	export default {
		mixins: [formBase, CommEvent],
		props: {
			dataSet: null,
		},
		data() {
			return {
				chartId: 0,
				chartData: []
			}
		},

		methods: {
			/* wordClick(e) {
				console.log(e)
			}, */
			setData(dataSet) {

				if (typeof(dataSet) === 'string') {
					try {
						dataSet = JSON.parse(dataSet)
					} catch (ex) {
						this.$logic.tip.error('错误的JSON格式数据')
						return
					}
				}

				if (this.model.type === 'part_flct') { //词条去掉标题行
					let ds = [].concat(dataSet)
					ds.splice(0, 1)
					this.chartData = ds
				} else {
					this.chartData = dataSet
				}
			}


		},
		watch: {
			'model': {
				deep: true,
				handler(nv, ov) { //所有的组件都会被触发
					if (this.isedit && this.isCurrent) {
						this.chartId = new Date().getTime()
					}
				}

			},
			dataSet(nv, ov) {
				this.setData(nv)

			},
			'model.initData': {
				handler(nv, ov) {
					this.setData(nv)
				}
			}
		},

		created() {
			//this.dataList = this.model.initData
			this.setData(this.model.initData)
		},
		components: {
			wordrotate: WordRotate
		},
	}
</script>

<style scoped>

</style>