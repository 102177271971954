
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
	['选项文本','选项值', '是否禁用', ],
	['选项一', 'A',0],
	['选项二', 'B', 0 ],
	['选项三', 'C', 1 ],
	['选项三', 'D', 0 ],
	 
])
let styles=compBase.toOptions('方框=A,按钮=B')
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'
let base = [
	{key: 'title',text: '开关选择'	},

{key: 'value',text: '初始值',type: 'text',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: null},
 {key: 'label',text: '选项文本',type: 'text',init: '',	target: '',bursh: true,bind: true,read:true,tip: '',list: null},
 {key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
 {key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 {key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:options.YON},
 {key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},


{key: 'activeV',text: '选中时值',type: 'text',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: null},
{key: 'activeI',text: '选中时图标',type: 'icon',init: 'Check',	target: '',	bursh: true,bind: true,tip: '优先显示图标',list: null},
{key: 'activeT',text: '选中时标签',type: 'icon',init: '同意',	target: '',	bursh: true,bind: true,tip: '未设置图标时显示文本标签',list: null},
{key: 'activeC',text: '选中时颜色',type: 'color',init: '#409EFF',	target: '',	bursh: true,bind: true,tip: '',list: null},
{key: 'inactiveV',text: '未选中值',type: 'text',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
{key: 'inactiveI',text: '未选中图标',type: 'icon',init: 'close',	target: '',	bursh: true,bind: true,tip: '优先显示图标',list: null},
{key: 'inactiveT',text: '未选中标签',type: 'icon',init: '拒绝',	target: '',	bursh: true,bind: true,tip: '未设置图标时显示文本标签',list: null},
{key: 'inactiveC',text: '未选中颜色',type: 'color',init: '#C0CCDA',	target: '',	bursh: true,bind: true,tip: '',list: null},
 {key: 'prompt',text: '内部提示',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
{key: 'wide',text: '宽度',type: 'number',init: '60',	target: '',	bursh: true,bind: true,tip: '',list: null},
 {key: 'loading',text: '加载中状态',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
 compBase.status2, 

]


let switchE = {
	data: {
		type: 'switch',isInput:'S',
	},
	delete: '',
	hide: '',
	base: base.concat(compBase.base),
	special:extend.tip, //[	{key: 'jsonData',text: 'JSON数据',type: 'json',init: datas,	target: '',	extend: false,tip: '',list: null},],
	action:  options.getEvents(null,'$event_click,$event_change').concat(options.getEvents(null,'$hook'))
}

export default switchE
 