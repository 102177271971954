import Event from './config/public/event.js'
export default {
	data() {
		return {
			action: null,
			hasKeyEvent: false,
			tabindex: null,
			eventsMap: {
				$event_click: {},
				$event_dblclick: {},
				$event_mousedown: {},
				$event_mouseup: {},
				$event_mouseenter: {},
				$event_mousemove: {},
				$event_mouseleave: {},
				$event_mouseover: {},
				$event_keypress: {},
				$event_keydown: {},
				$event_keyup: {},
				$event_change: {},
				$event_input: {},
				$event_focus: {},
				$event_blur: {},
				$event_resize: {},
				$event_scroll: {},
			}


		}
	},
	methods: {
		openDialog() {
			if (this.model.dialogId) { //弹出关联的对话框
				//let dialog = this.page.$(this.model.dialogId) 
				let dialog = this.$logic.pageUtil.getElementById(this.page.formData, this.model.dialogId)
				if (dialog && dialog.$open) {
					dialog.$open()
				}
			}
		},
		$event_click(event) {
			this.doEvent(event, '$event_click')
		},
		$event_mousedown(event) {
			this.doEvent(event, '$event_mousedown')
		},
		$event_mouseup(event) {
			this.doEvent(event, '$event_mouseup')
		},
		$event_mouseleave(event) {
			this.doEvent(event, '$event_mouseleave')
		},
		$event_mouseover(event) {
			this.doEvent(event, '$event_mouseover')
		},
		$event_dblclick(event) {
			this.doEvent(event, '$event_dblclick')
		},
		$event_mouseenter(event) {
			this.doEvent(event, '$event_mouseenter')
		},
		$event_mousemove(event) {
			this.doEvent(event, '$event_mousemove')
		},
		$event_keypress(event) { //对于Div类非输出型元素响应键盘事件需添加 tabindex='-1'属性
			this.doEvent(event, '$event_keypress')
		},
		$event_keydown(event) {
			this.doEvent(event, '$event_keydown')
		},
		$event_keyup(event) {
			this.doEvent(event, '$event_keyup')
		},
		$event_change(event) {
			this.doEvent(event, '$event_change')
		},
		$event_input(event) {
			this.doEvent(event, '$event_input')
		},
		$event_focus(event) {
			this.doEvent(event, '$event_focus')
		},
		$event_blur(event) {
			this.doEvent(event, '$event_blur')
		},
		$event_resize(event) {
			this.doEvent(event, '$event_resize')
		},
		$event_scroll(event) {
			this.doEvent(event, '$event_scroll')
		},
		checkEvent(eventName) {
			let func = Event.getEvent(this.model, eventName)
			return func
		},
		doEvent(event, eventName, exData = {}) {
			let result = null
			let func = Event.getEvent(this.model, eventName)

			if (func) {
				//是否按下的三个键
				let check = true
				//if (func.group == '$event') { //如果是系统事件判定是否满足触发条件
				if (eventName.indexOf('$event') === 0) {
					if (!func.bubble) {
						if (event && event.stopPropagation) {
							event.stopPropagation()
						}

					}
					if (func.withKeys && func.withKeys.length > 0) {
						for (let key of func.withKeys) {

							switch (key) {
								case 'ctrl':
									check = event.ctrlKey
									break;
								case 'shift':
									check = event.shiftKey
									break;
								case 'alt':
									check = event.altKey
									break;
								case 'meta':
									check = event.metaKey
									break;
							}
							if (!check) {
								break
							}
						}
					}
					if (check && func.mouseButton) {
						switch (func.mouseButton) {
							case 'left':
								check = event.button == 0
								break;
							case 'middle':
								check = event.button == 1
								break;
							case 'right':
								check = event.button == 2
								break;
						}

					}
					if (check && func.keyCodes && func.keyCodes.length > 0) {
						let check = false
						for (let key of func.keyCodes) {
							if (key == event.keyCode) {
								check = true
							}
						}


					}
				}
				if (check) {
					if (this.action) { //缓存action下次直接取用

					} else {
						let user =this.$logic.getSimpleUser()
					/* 	{
							...this.$logic.getUser()
						}
						let keys = []
						for (let key in user) {
							let value=user[key]
							if(value==null){
								continue
							}
							if(typeof value === 'object' ){//数组、函数、对象等属性全排除掉
								keys.push(key)
							}

						}
						for(let key of keys){
							delete user[key]
						}
 */
						let system = {
							user: user,
							orgTree: this.project.orgTree,
							roleTree: this.project.roleTree,
							users: this.project.userList,
							project: this.project,
						}
						if ('global' in this.project) {

						} else {
							this.project.global = {}
						}
						this.action = {
							element: this.model._proxy,
							global: this.project.global,
							pageContent: this.page.formData,
							container: this.host, //父页面：页面容器 和 页面组件	
							parentPage: this.host && this.host.parentPage && this.host.parentPage.formData ? this
								.host.parentPage.formData._util_page : null,
							system: system

						}



					}
					let action = {
						...this.action,
						exData: exData,
						bindData: this.datas,
						event: event
					}

					try {
						result = func(action)
					} catch (ex) {
						this.$logic.tip.error('执行( ' + func.funcName + ' )事件发生程序异常：[' + ex + '] 详见控制台输出')
						console.log(ex)
					}

				}

			}
			return result
		},
		initEvents() { //容器组件调用此方法，初化通用事件 用于页面中事件变量赋值
			if (!this.isedit) {
				this.eventsMap = Event.getEventMap(this.model, this)
				for (let key in this.eventsMap) {
					//console.log(key)
					//具有键盘输入或焦点事件时容器设置为可响应焦点状态，tabindex属性为div添加键盘响应功能
					if ((key.indexOf('$event_key') > -1 || key.indexOf('_focus') > -1 || key.indexOf('_blur') > -1) &&
						this.eventsMap[key].name) {
						this.hasKeyEvent = true
						this.tabindex = 'tabindex'
						break
					}
				}
				//console.log(this.eventsMap)
			}
		},
		initCompEvents() { //非容器类组件初始化事件注册信息，用于BaseElement中事件变量赋值
			if (!this.isedit) {
				this.eventsMap = {}
				let privates = Event.privateEvent[this.model.type]
				let commonEvents = Event.commonEvents
				let eventsMap = Event.getEventMap(this.model, this)
				for (let key in eventsMap) { //公共组件方法中只处理鼠标通用事件,其它通用事件在formbase中内置，组件专有事件在组件中设置
					if (commonEvents.indexOf(key) > -1) {
						if (privates && privates.indexOf(key) > -1) { //如果是组件内部私有方法不加入公共方法中
							eventsMap[key].handler = null
						}
						this.eventsMap[key] = eventsMap[key]
					}
				}
			}

		},

	}
}