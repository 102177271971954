import font from '../../public/font.js'
import layout from '../../public/layout.js'
import special from '../../public/layoutspecial.js'
import options from '../../public/options.js'
let colls=[{label:'折叠',value:'collapse'},{label:'不折叠',value:''}] 

let cs=[
{key:'title',text:'表格属性'},
/* {key:'width',text:'表格宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'表格高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null}, */
{key:'trH',text:'行高',type:'text',init:'50px',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdP',text:'内间距',type:'text',init:'5px',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'tdH',text:'格内横向',type:'select',init:'left',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
{key:'tdV',text:'格内纵向',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
{key:'lineShow',text:'显示网格线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'lineColl',text:'线条折叠',type:'select',init:'collapse',target:'border-collapse',bursh:true,bind:true,tip:'',list: colls},

]

let table= {
	data:{
		type:'table',width:'100%',height:'',display:''
	},
	delete:'alignH,alignV,minH,overH,overV,paddingT,paddingB,paddingL,paddingR,',//height,
	hide:'display,flexD,flexW,position,contentD,',
	base:cs.concat(layout).concat(font) ,
	special :[].concat(special),
	action:[].concat( options.getEvents(null,'$event_click,$event_mousedown,$event_mouseup,$event_mouseleave,$event_mouseover',null))//,$event_dblclick,$event_mouseenter,$hook,'$event_change,$event_input'

}

export default table