import compBase from '../../public/comp.js'
import options from '../../public/options.js' 
let laneH= [
		{text:'职能A',width:'200px'},
		{text:'职能B',width:'300px'},
		{text:'职能C',width:null},		
	] 
laneH=JSON.stringify(laneH)
let laneV= [
		{text:'部门一',height:'150px'},
		{text:'部门二',height:'200px'},
		{text:'部门三',height:null},		
	] 
laneV=JSON.stringify(laneV) 
 
let base=[
{key:'title',text:'流程设计器'},
/* 
{key:'dataSet',text:'数据集',type:'report',init:dataSet,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'queryItemWidth',text:'查询项宽度',type:'text',init:'100px',target:'',bursh:true,bind:true,tip:'文本框等输入元素的宽度，日期时间输入框不受此影响',list: options.YON},
{key:'allowOrder',text:'允许排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'advanceQuery',text:'可高级查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'headCustom',text:'定制标题栏',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'opration',text:'操作方式',type:'select',init:'read',target:'',bursh:true,bind:true,tip:'',list: oprations},
{key:'total',text:'数据汇总',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},

 */
/*
{key:'flowName',text:'流程名称',type:'text',init:'',target:'',bursh:true,bind:true,tip:'',list: null},
 
{key:'panelWidth',text:'画布宽度',type:'text',init:'800px',target:'--panel-width',bursh:true,bind:true,tip:'',list: null},
{key:'panelHeight',text:'画布高度',type:'text',init:'500px',target:'--panel-height',bursh:true,bind:true,tip:'',list: null},
 */
{key:'width',text:'画布宽度',type:'text',init:'800px',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'画布高度',type:'text',init:'500px',target:'height',bursh:true,bind:true,tip:'',list: null},

{key:'showGrid',text:'显示网格',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},


{key:'nodeWidth',text:'节点宽度',type:'number',init:100,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeHeight',text:'节点高度',type:'number',init:50,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'nodeColor',text:'节点颜色',type:'color',init:'#E8E8E8',target:'',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'nodeFontSize',text:'节点字号',type:'select',init:'',target:'--node-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
{key:'nodeFontColor',text:'默认字颜色',type:'color',init:'#606266',target:'--node-font-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'nodeStatusColor',text:'状态字颜色',type:'color',init:'#606266',target:'--node-font-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 

{key:'workColor',text:'处理中颜色',type:'color',init:'#1E90FF',target:'',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'finishColor',text:'已完成颜色',type:'color',init:'#67C23A',target:'',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'errorColor',text:'异常颜色',type:'color',init:'#FF4500',target:'',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'iconColor',text:'图标颜色',type:'color',init:'#FFD700',target:'--node-icon-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 

{key:'lineColor',text:'线条颜色',type:'color',init:'#606266',target:'--flow-line-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 

{key:'title',text:'泳道'},
{key:'laneLineType',text:'泳道线类型',type:'select',init:'dashed',target:'',bursh:true,bind:true,tip:'无边框:none,实线:solid,虚线:dashed,点线:dotted,双实线:double,立体凸线:outset,立体凹线:inset',list:options.BorderType},	
{key:'laneLineColor',text:'泳道线颜色',type:'color',init:'#cccccc',target:'',bursh:true,bind:true,tip:'',list:null},	
{key:'laneLineWidth',text:'泳道线粗细',type:'text',init:'1px',target:'',bursh:true,bind:true,tip:'',list:null},	

{key:'showLaneH',text:'横向泳道',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'laneHeightH',text:'横向标题高',type:'text',init:'40px',target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'laneBgColorH',text:'横向背景色',type:'colorA',init:'rgba(232, 232, 232, 0.5)',target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'laneFontH',text:'横向字号',type:'text',init:'14px',target:'',tip:'',bursh:true,bind:true,list:null},
{key:'laneH',text:'横向泳道列',type:'json',init:laneH,target:'',tip:'',bursh:true,bind:true,list:null},	

{key:'showLaneV',text:'纵向泳道',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'laneWidthV',text:'纵向标题宽',type:'text',init:'80px',target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'laneBgColorV',text:'纵向背景色',type:'colorA',init:'rgba(232, 232, 232, 0.5)',target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'laneFontV',text:'纵向字号',type:'text',init:'14px',target:'',tip:'',bursh:true,bind:true,list:null},
{key:'laneV',text:'纵向泳道行',type:'json',init:laneV,target:'',tip:'',bursh:true,bind:true,list:null},


/* 

{key:'minW',text:'最小宽度',type:'text',init:'500px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
{key:'minH',text:'最小高度',type:'text',init:'100px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
 */	
{key:'items',text:'节点集合',type:'json',init:[],target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'lineList',text:'线条集合',type:'json',init:[],target:'',tip:'',bursh:true,bind:true,list:null},
{key:'pointList',text:'连接点集合',type:'json',init:[],target:'',tip:'',bursh:true,bind:true,list:null},
{key:'formList',text:'表单集合',type:'json',init:[],target:'',tip:'',bursh:true,bind:true,list:null},
{key:'editObj',text:'编辑对象',type:'json',init:{},target:'',tip:'流程还是表单设计',bursh:true,bind:true,list:null},
]
let events=[
/* 	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]






let special=[
//{key:'title',text:'流程配置'},
{key:'flowSet',text:'流程配置',type:'flowset',init:{variables:[],flowEvents:[]},target:'',tip:'',bursh:true,bind:true,list:null}, 
//{key:'flowEvent',text:'后端事件',type:'flowevent',init:flowEvent,target:null,bursh:true,bind:true,tip:'',list: null},

] 
let flow= {
	data:{
		type:'flow',notCreate:true
	},
	delete:'',
	hide:'items,lineList,pointList,formList,editObj',
	base:base ,
	special :special,
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default flow