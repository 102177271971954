<template>
	<div class="barcontent" :style="barStyle">
		<!-- <div :id="barId" class="barcode" ref="barcode"></div> -->
		<svg ref="barcode" class="barcode"></svg>
	</div>
</template>

<script>
	import formBase from '../../../formbase.js'
	import BarCode from 'jsbarcode'
	export default {
		mixins: [formBase],
		data() {
			return {
				barOptions: {}

			}
		},

		methods: {
			drawBarCode() {
				let obj = this.$refs.barcode
				if (obj) {
					try {
						BarCode(obj, this.model.value, this.barOptions)
					} catch (err) {
						if (err.indexOf(' valid ') > 0) {
							this.$logic.tip.error('当前内容[' + this.model.value + ']不适合采用此编码类型')
						}

					}

				}

			}
		},
		computed: {
			barStyle() {
				let model = this.model
				let bs = this.baseStyle
				let barOption = this.barSet //只为调用一次计算属性更新计算
				bs.width = model.barWidth + 'px'
				bs.height = model.barHeigth + 'px'
				//bs.height =  '300px'
				//	border: model.borderT + ' ' + model.borderS + 'px ' + model.borderB
				return bs
			},
			barSet() { //通过计算属性监听model属性值的变化自动重新绘制
				let model = this.model
				let lineWidth = parseInt(model.itemWidth)
				if (isNaN(lineWidth)) {
					lineWidth = 1
				}
				let textMargin = parseInt(model.textMargin)
				if (isNaN(textMargin)) {
					textMargin = 0
				}
				let barHeigth = parseInt(model.barHeigth)
				if (isNaN(barHeigth)) {
					barHeigth = 20
				}
				let textSize = parseInt(model.textSize)
				if (isNaN(textSize)) {
					textSize = 20
				}
				let showText=model.shwoText==='Y'
				let lineHeight = barHeigth - textSize - textMargin - model.barMargin * 2 // 总高度-文字高度-上下边距-文字线条间距
				if(!showText){
					lineHeight = barHeigth - model.barMargin * 2 
				}
				if (isNaN(lineHeight) || lineHeight < 5) {
					lineHeight = 5
				}


				let option = {
					format: model.format, //选择要使用的条形码类型
					width: lineWidth, //设置条之间的宽度
					height: lineHeight,
					displayValue: showText, //是否在条形码下方显示文字					 
					text: model.fixText, //覆盖显示的文本
					fontOptions: model.fontOptions, //使文字加粗体或变斜体
					font: model.fontType, //设置文本的字体
					textAlign: model.textAlign, //设置文本的水平对齐方式
					textPosition:showText? model.textPosition:'bottom', //设置文本的垂直位置,不显示文本时底对齐方式
					textMargin:  textMargin, //设置条形码和文本之间的间距
					fontSize:  textSize, //设置文本的大小
					background: model.backColor, //设置条形码的背景
					lineColor: model.lineColor, //设置条和文本的颜色。
					margin: parseInt(model.barMargin) //设置条形码周围的空白边距 5
				}
				this.barOptions = option
				this.drawBarCode()
				//console.log(option)
				return option

			}


		},
		watch: {
			'model.value'(newValue, oldValue) {
				this.drawBarCode()
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.drawBarCode()
			})
		},
		created() {


		}
	}
</script>

<style scoped>
	.barcontent {
	/* 	position: relative;
 */
	}

	.barcode {
		/* width: 100%; */

	}
</style>