import options from '../../../../../public/options.js'
import polarConfig from '../polarConfig.js'
let base = polarConfig.base
let special = [
	 {key:'title',text:'饼图系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'pie',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesPieRoseType',text:'图形样式',type:'select',init:'false',path:'_series.roseType',bursh:true,bind:true,tip:'',list:options.toOptions('扇形图=false,玫瑰图=radius,等角玫瑰图=area')},
/* 	 {key:'seriesPieCenterX',text:'中心位置X',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'seriesPieCenterY',text:'中心位置Y',type:'text',init:'50%',bursh:true,bind:true,tip:'直接输入数值绝对定位或百分比相对定位',list:null},
	 {key:'seriesPieRadiusI',text:'空心圆半径',type:'text',init:'0%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	 {key:'seriesPieRadiusO',text:'外圆半径',type:'text',init:'75%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
 */	 {key:'seriesPieRadiusS',text:'环间距',type:'text',init:'0%',bursh:true,bind:true,tip:'百分比,相对长度以水平垂直方向中最小值为基准',list:null},
	 {key:'seriesPieBorderRadius',text:'描边颜色',type:'colorA',init:'rgba(255, 255, 255, 0.1)',path:'_series.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesPieBorderColor',text:'描边宽度',type:'number',init:'0',path:'_series.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesPieBorderWidth',text:'描边圆角',type:'number',init:'0',path:'_series.itemStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},

 
	 {key:'seriesPiePercentPrecision',text:'百分比精度',type:'number',init:2,path:'_series.percentPrecision',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesPieMnAngle',text:'起始角度',type:'number',init:90,path:'_series.startAngle',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesPieMinAngle',text:'扇区最小角',type:'number',init:1,path:'_series.minAngle',bursh:true,bind:true,tip:'为确保数据过小时也可显示以进行交互',list:null},
	 {key:'seriesPieMinShowLabelAngle',text:'标签最小角',type:'number',init:1,path:'_series.minShowLabelAngle',bursh:true,bind:true,tip:'小于此角度时不显示标签',list:null},
 
 	  {key:'seriesPieLabelShow',text:'显示标签',type:'switch',init:'Y',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'seriesPieLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'seriesPieLabelRotate',text:'标签角度',type:'number',init:0,path:'_series.label.rotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesPieLabelPosition',text:'标签位置',type:'select',init:'outside',path:'_series.label.position',bursh:true,bind:true,tip:'',list:options.toOptions('外部引线=outside,扇形内部=inside,圆心中间=center')},
	 {key:'seriesPieLabelAlign',text:'标签对齐',type:'select',init:'none',path:'_series.label.alignTo',bursh:true,bind:true,tip:'',list:options.toOptions('自动排列=none,引线对齐=labelLine,标签对齐=edge')},
	 {key:'seriesPieLabelFormat',text:'标签模板',type:'area',init:'{b}',path:'_series.label.formatter',bursh:true,bind:true,tip:'可组合使用此变量：{a}系列名{b}数据名{c}数据值{d}百分比',list:null},

 	 {key:'seriesPieLabelLineShow',text:'显示引线',type:'switch',init:'Y',path:'_series.labelLine.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'seriesPieLabelLineWidth',text:'引线粗细',type:'number',init:1,path:'_series.labelLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesPieLabelLineAbove',text:'显示引线上',type:'switch',init:'Y',path:'_series.labelLine.showAbove',bursh:true,bind:true,tip:'',list:options.YON},

 	 {key:'seriesPieLabelLineColor',text:'引线颜色',type:'colorA',init:'',path:'_series.labelLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
 	 {key:'seriesPieLabelLineLength',text:'引线长度1',type:'number',init:null,path:'_series.labelLine.length',bursh:true,bind:true,tip:'',list:null},
 	 {key:'seriesPieLabelLineLength2',text:'引线长度2',type:'number',init:null,path:'_series.labelLine.length2',bursh:true,bind:true,tip:'',list:null},

	 



].concat(polarConfig.special)
let action = polarConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_pie',polarAxisLineShow:'N',polarRadiusI:'0%',color:options.chartColor.bar5,
		chartClan: 'polar',legendOrient:'vertical'//默认垂直方向图例
	},
	delete: '',
	hide: 'width,height,seriesType,seriesPieRadiusI,seriesPieRadiusS,polarStartAngle,polarRadiusAxis,dataOption',
	base:[].concat(base) ,
	special: special,
	action: polarConfig.action.timer.concat(polarConfig.action.comm)
}
export default cfg
