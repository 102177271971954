<template>

	<!-- 方型四角 -->
	<div class="svg-container"
		:style="'box-shadow: '+color1+' 0px 0px 10px inset; border: 1px solid '+color1+'; background-color: transparent;'">
		<svg v-for="block in ['datav_left-top','datav_left-bottom','datav_right-top','datav_right-bottom']"
			:class="block+' datav_block'" :style="hornStyle">
			<polyline class="stroke-width2" :stroke="color1" points="0, 25 0, 0 25, 0"></polyline>
			<polyline class="stroke-width2" :stroke="color1" points="-25, 0 0, 0 0, 25"></polyline>
			<polyline class="stroke-width2" :stroke="color1" points="-25, 0 0, 0 0, -25"></polyline>
			<polyline class="stroke-width2" :stroke="color1" points="0, -25 0, 0 25, 0"></polyline>
			<polyline class="stroke-width5" :stroke="color2" points="0, 10 0, 0 10, 0"></polyline>
			<polyline class="stroke-width5" :stroke="color2" points="-10, 0 0, 0 0, 10"></polyline>
			<polyline class="stroke-width5" :stroke="color2" points="-10, 0 0, 0 0, -10"></polyline>
			<polyline class="stroke-width5" :stroke="color2" points="0, -10 0, 0 10, 0"></polyline>
		</svg>
	</div>

 
</template>

<script>
	export default {
		props: {
			color1: {
				type: String,
				default: '#4fd2dd'
			},
			color2: {
				type: String,
				default: '#235fa7'
			},
			panelWidth: {
				type: Number,
				default: 200
			},
			panelHeight: {
				type: Number,
				default: 100
			},
			maxHornWidth: {
				type: Number,
				default: 150
			},
			maxHornHeight: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {

			}
		},

		methods: {

		},
		computed: {
			hornStyle() {
				/* 	let minSize = this.panelWidth > this.panelHeight ? this.panelHeight : this.panelWidth
					let hornSize=minSize/2 */
				let hornHeight = this.panelHeight / 2 - 10 //留点空隙
				let hornWidth = this.panelWidth / 2
				/* if (hornHeight > this.maxHornHeight) {
					hornHeight = this.maxHornHeight
				} */
				if (hornWidth > this.maxHornWidth) {
					hornWidth = this.maxHornWidth
				}
				let css = {
					'--horn-width': hornWidth + 'px',
					'--horn-height': hornHeight + 'px'
				}
				return css

			}
		},
		created() {


		}
	}
</script>

<style scoped>
	.svg-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		pointer-events: none;
	}

	.stroke-width2 {
		stroke-width: 2px;
		fill: none;
	}

	.stroke-width5 {
		stroke-width: 5px;
		fill: none;
	}

	.datav_block {
		width: var(--horn-width);
		height: var(--horn-height);
		position: absolute; 
	}


	.datav_right-top {
		right: 0px;
		transform: rotateY(180deg);
	}

	.datav_left-bottom {
		bottom: 0px;
		transform: rotateX(180deg);
	}

	.datav_right-bottom {
		right: 0px;
		bottom: 0px;
		transform: rotateX(180deg) rotateY(180deg);
	}
</style>