<template>
	<div class="login-content">

		<div class="sysinfo linear-text">

			<!-- 	<div class="logo-area">
				<div>
					<img v-if="sysInfo.sysLogo" :src="'/upload/sysicon/'+sysInfo.sysLogo"
						style="width: 100%;height: 100%;" />
					<i v-else class="iconfont icon-logo logo"></i>
				</div>
				<div class="logo-label">
					{{sysInfo.sysShort}}
				</div>
			</div>
			<div>

				{{sysInfo.sysTitle}}
				<span v-if="sysInfo.regLevel==0">(注册失效)</span>
				<span v-else-if="sysInfo.regLevel==10">(试用版)</span>
				<span v-else-if="sysInfo.regLevel==100">(免费版)</span>
				<span v-else-if="sysInfo.regLevel==1000">(铁粉尊享版)</span>



			</div> -->

			<div>
				<div style="display:flex;">
					<div>
						<img v-if="sysInfo.sysLogo" :src="'/upload/sysicon/'+sysInfo.sysLogo"
							style="width: 100%;height: 100%;" />
						<i v-else class="iconfont icon-logo logo"></i>
					</div>
					<div>
						{{sysInfo.sysShort}}
					</div>
					<div style="margin-left: 10px;" class="">
						{{sysInfo.sysTitle}}
						<span v-if="sysInfo.regLevel==10000"></span>
						<span v-else-if="sysInfo.regLevel==10">-试用版</span>
						<span v-else-if="sysInfo.regLevel==100">-免费版</span>
						<span v-else-if="sysInfo.regLevel==500">-欠费版</span>
						<span v-else-if="sysInfo.regLevel==1000">-铁粉免费版</span>
						<span v-else>-注册失效</span>
					</div>
				</div>

			</div>
		</div>
		<div class="compinfo">
			<a :href="sysInfo.sysUrl && sysInfo.sysUrl.indexOf('http')===0?  sysInfo.sysUrl:'http://'+ sysInfo.sysUrl"
				target="_blank" style="color:#ffffff;overflow: hidden;">
				©{{currentYear}} {{sysInfo.support}} </a>

		</div>
		<div v-show="loginShow" :class="loginCss">
			<!-- <div>
				<span style="font-size: 16px;">{{sysInfo.sysTitle}}</span>
			</div> -->
			<div class="blockhead">
				<i class="far fa-user-alt icon"></i>

				登录
				<!-- <span v-if="sysInfo.sysMode=='S'" > - 公司名称公司名</span> -->
				<!-- <span class="sysname">XXX开发平台</span> -->

			</div>
			<div class="infoitem" style="border-bottom: 0px;">
				<div class="infolabel"> 组织</div>
				<div class="infoinput">
					<inputsuggest v-model="loginForm.orgName" @query="orgQuery" @choose="orgChoose" @change="orgChange"
						:options="orgOptions" size="default" icon-it="CaretBottom" :status="unitLock?'disabled':''" />



					<!-- <i class="fal fa-users infoicon"></i>
					<input type="text" @focus="orgFocus=true" @blur="orgFocus=false" /> -->
				</div>
				<!-- 		<div :class="orgFocus?'actline inputactive':'actline'"></div> -->
			</div>
			<div class="infoitem">
				<div class="infolabel"> 用户名</div>
				<div class="infoinput">
					<i class="fal fa-user infoicon"></i>
					<input v-model="loginForm.userName" type="text" @focus="userFocus=true" @blur="userFocus=false" />
				</div>
				<div :class="userFocus?'actline inputactive':'actline'"></div>
			</div>
			<div class="infoitem">
				<div class="infolabel"> 密码</div>
				<div class="infoinput">
					<i class="fal fa-lock infoicon"></i>
					<input v-model="loginForm.passWord" type="password" @focus="pwdFocus=true" @blur="pwdFocus=false"
						@keydown.enter="doLogin" />
				</div>
				<div :class="pwdFocus?'actline inputactive':'actline'"></div>
			</div>
			<div v-if="checkCodeShow" class="infoitem">
				<div class="infolabel"> 验证码</div>
				<div class="infoinput">
					<i class="fal fa-key infoicon"></i>
					<input v-model="loginForm.checkCode" type="text" @focus="checkWord=true" @blur="checkWord=false"
						@keydown.enter="doLogin" class="checkword-input" />
					<div class="checkword-code">
						<imgcode :many="4" font-size.num="25" :case-mix="false" @init="onCheckCodeInit"
							@update="imgCodeUpdate" />
					</div>
				</div>
				<div :class="checkWord?'actline inputactive':'actline'"></div>
			</div>

			<div class="button-group">
				<button class="button button2" @click="doLogin">登 录</button>
				<button v-if="sysInfo.sysMode==='M'" class="button button3" @click="toReg">注 册</button>
			</div>


		</div>
		<div v-show="! loginShow" :class="regCss">
			<div class="blockhead">
				<i class="far fa-home-lg-alt icon"></i>注册
			</div>
			<div class="reginfo">
				提示：此功能仅用于注册入驻平台的公司或其它组织单位，内部用户登录账号由该单位的管理员自行创建分配。
			</div>
			<!-- 				<div class="regitem">
					<div class="reglabel"> 单位名称</div>
					<div class="reginput">						 
						<input type="text"  />
					</div>				 
				</div> -->
			<template v-if="!regResultShow">
				<el-form ref="regFormRef" :model="regForm" :rules="regRules" label-width="120px">
					<el-form-item label="单位名称" prop="unitName">
						<el-input v-model.trim="regForm.unitName" />
					</el-form-item>
					<el-form-item label="单位简称" prop="unitShort">
						<el-input v-model.trim="regForm.unitShort" />
					</el-form-item>
					<el-form-item label="社会信用代码" prop="unitCode">
						<el-input v-model.trim="regForm.unitCode" />
					</el-form-item>
					<el-form-item label="单位类型" prop="unitType">
						<el-radio-group v-model="regForm.unitType">
							<el-radio label="公司">公司</el-radio>
							<el-radio label="学校">学校</el-radio>
							<el-radio label="政府">政府</el-radio>
							<el-radio label="其它">其它</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-divider>
						<el-icon>
							<User />
						</el-icon>管理员信息
					</el-divider>
					<el-form-item label="姓名" prop="adminName">
						<el-input v-model.trim="regForm.adminName" />
					</el-form-item>
					<el-form-item label="手机" prop="adminMobile">
						<el-col :span="10">
							<el-input v-model.trim="regForm.adminMobile" title="可用作用户名登录平台" />
						</el-col>
						<el-col :span="14">
							<el-form-item label="邮箱" prop="adminEmail" label-width="60px">
								<el-input v-model.trim="regForm.adminEmail" title="可用作用户名登录平台" />
							</el-form-item>
						</el-col>


					</el-form-item>

					<el-form-item label="初始密码" prop="adminPassword">
						<el-col :span="10">
							<el-input v-model.trim="regForm.adminPassword" type="password" />
						</el-col>
						<el-col :span="14">
							<el-form-item label="确认" prop="adminPassword2" label-width="60px">
								<el-input v-model.trim="regForm.adminPassword2" type="password" />
							</el-form-item>
						</el-col>

					</el-form-item>



				</el-form>

				<div class="button-group">
					<button class="button button3" @click="doReg()">注 册</button>
					<button class="button button2" @click="toLogin">返 回</button>
				</div>
			</template>
			<template v-if="regResultShow">
				<div class="regresult">
					<div class="regresult-icon">
						<i class="far fa-envelope-open-text"></i>
					</div>
					<div>
						已成功接收到您的注册申请
					</div>
					<div>
						信息审核通过后将在平台开发群里通知您<!-- 以电话或邮件的方式通知您 -->
					</div>
					<div>
						服务支持：service@logicpaas.com
					</div>
				</div>
			</template>
		</div>

	</div>
</template>

<script>
	import ImgCode from '../../components/comm/checkcode/CheckCode.vue'

	export default {
		data() {
			return {
				currentYear: 2024,
				sysInfo: {},
				unitLock: true,
				orgOptions: [

				],
				orgSelect: null,

				userFocus: false,
				pwdFocus: false,
				orgFocus: false,
				checkWord: false,
				drawFunc: null,
				checkCode: '',

				loginShow: true,
				regResultShow: false,

				checkCodeShow: false, //登录失败后显示

				loginCss: ['loginblock'], //['loginblock', 'loginshow'],
				regCss: ['loginblock'], //['loginblock', 'reghide'],
				loginForm: {
					orgId: 0,
					orgName: '',
					userName: '',
					passWord: '',
					checkCode: ''
				},

				regForm: {
					unitName: '',
					unitShort: '',
					unitCode: '',
					unitType: '',
					adminName: '',
					adminMobile: '',
					adminEmail: '',
					adminPassword: '',
					adminPassword2: ''
				},
				regRules: {
					unitName: [{
							required: true,
							min: 5,
							message: '请输入正确的单位名称',
							trigger: 'change'
						},

					],
					unitShort: [{
						required: true,
						min: 2,
						message: '请输入正确的单名简称',
						trigger: 'change'
					}, ],
					unitCode: [{
						required: true,
						min: 18,
						max: 18,
						message: '请输入18位正确的代码',
						trigger: 'change'
					}, ],
					unitType: [{
						required: true,
						message: '请选择单位类型',
						trigger: 'change'
					}, ],
					adminName: [{
						required: true,
						min: 2,
						message: '请输入正确的姓名',
						trigger: 'change'
					}, ],
					adminMobile: [{
						required: true,
						min: 11,
						max: 11,
						message: '请输入正确的手机号码',
						trigger: 'change'
					}, ],
					adminEmail: [{
						required: false,
						message: '请输入管理员邮箱',
						trigger: 'blur'
					}, ],
					adminPassword: [{
						required: true,
						min: 8,
						message: '请输入8位以上的密码',
						trigger: 'change'
					}, ],
					adminPassword2: [{
						required: true,
						validator: null,
						message: '两次输入密码必须一致',
						trigger: 'change'
					}],

				},
				regOutInfo: '平台比较旧了，请升级免费版或正式版'


			}
		},
		/* 		beforeRouteEnter(to, from, next) {
					console.log(from.fullPath)
					next()
				}, */
		methods: {
			checkPassword() {

				return this.regForm.adminPassword == this.regForm.adminPassword2
			},
			doLogin() {
			

				if (this.checkCodeShow && this.loginForm.checkCode.toLocaleLowerCase() != this.checkCode
					.toLocaleLowerCase()) {
					this.$logic.tip.error('验证码错误，请重试')
					return
				}
				if (this.loginForm.userName != '$$ROOT' && this.loginForm.orgId < 1) {
					this.$logic.tip.error('请选择平台所在的单位')
					return
				}
				if (this.loginForm.userName.length < 1 || this.loginForm.passWord.length < 1) {
					this.$logic.tip.error('请输入用户名密码')
					return
				}
				let data = {
					...this.loginForm
				}
				data.passWord = this.$logic.encoder.md5(data.passWord + 'logic')
				let userBag = this.$logic.security.encodeSql(JSON.stringify(data))
				this.$logic.http.post(true, '/api/unit/login/', {
					data: userBag
				}, null, res => {
					//this.$logic.user = res.data.data
	//console.log(new Date('2024-11-01').getTime())  试用版10
				let tm=new Date().getTime()
				if (tm > 1731254400000 && this.sysInfo.regLevel < 100 ) { //new Date('2024-11-01').getTime()
					this.$logic.tip.error(this.regOutInfo)
					return
				}

					let us = this.$logic.encoder.fromBase642(res.data.data)
					let user = JSON.parse(us)
					if (user.adminCapacity && user.adminCapacity.length > 4) {
						user.adminCapacity = JSON.parse(user.adminCapacity)
					} else {
						user.adminCapacity = null
					}
					if (user.devCapacity && user.devCapacity.length > 1) {
						user.devCapacity = JSON.parse(user.devCapacity)
					} else {
						user.devCapacity = null
					}

					user.loginTime = (new Date()).getTime()
					user.endTime = user.loginTime + 86400000 //* 1000 //增加过期时间无限
					user.$sysInfo = this.sysInfo
					//console.log(user.unitItem)
					//this.$logic.commitStore('setUser', user)
					this.$logic.saveUser(user)
					let unitName = this.loginForm.orgName
					if (user.unitItem && user.unitItem.unitName && user.unitItem.unitLevel == 10000) {
						unitName = user.unitItem.unitName //正式版本下改回单位名称
					}
					let unit = {
						unitId: this.loginForm.orgId,
						unitName: unitName
					}


					localStorage.setItem("$$userUnit", JSON.stringify(unit))
					//let to = this.$logic.http.getRequestParam('to') || ''
					let to = ''
					let t = window.location.href.indexOf('?to=')
					if (t > -1) {
						to = window.location.href.substring(t + 4)
					}
					/* if (this.loginForm.userName == '$$ROOT') {
						this.$router.push({
							name: 'SysInfo'
						})
						return
					} */
					//console.log(to,to.indexOf('login') < 0)
					if (to.length > 1 && to.indexOf('login') < 0) { //有转向地址且不含自身
						let ts = to.split('to=') //过滤掉多个转向的情况 
						to = ts[0]
						to = decodeURIComponent(to)
						//window.location.href = to
						let x = to.indexOf('/', to.indexOf('//') + 2) //提取网址后缀
						to = x > -1 ? to.substring(x) : to
						this.$router.push(to)
					} else {
						this.$router.push({
							name: 'DeskTop'
						})
					}


				}, err => {

					this.loginForm.passWord = ''
					this.$logic.tip.error('登录失败：' + err.info)
					if (this.checkCodeShow) {
						this.drawFunc()
					} else {
						this.checkCodeShow = true
					}


					return
				})
			},
			async doReg() {
				let form = this.$refs.regFormRef
				let check = await form.validate((valid, fields) => {

				})
				if (!check) {
					this.$logic.tip.error('请核对正确的注册信息')
					return
				}

				this.$logic.http.post(true, '/api/unit/unitreg/', null, this.regForm, res => {
					this.regResultShow = true
				})

			},
			toLogin(init) {
				this.loginShow = true
				setTimeout(() => {
					//this.loginCss.splice(2, 1)
					//this.regCss.splice(2, 1)
					this.loginCss = ['loginblock', 'loginshow']
					this.regCss = ['loginblock', 'reghide']
				}, 50)
				/* this.$nextTick(() => {
					
				}) */
				setTimeout(() => { //transform属性会破坏子元素的display:fixed样式，实现翻转后移除带引样式的类
					this.loginCss.splice(1, 1)
				}, init ? 100 : 1200)

			},
			toReg() {
				this.loginShow = false
				setTimeout(() => {
					//this.loginCss.push('loginhide')
					//this.regCss.push('regshow')
					this.loginCss = ['loginblock', 'loginhide']
					this.regCss = ['loginblock', 'regshow']
				}, 100)

			},
			orgChange(value) {
				if (!value) {
					this.loginForm.orgId = 0
				}
			},
			orgChoose(item) {
				this.orgSelect = item
				this.loginForm.orgId = item.unitId
				this.loginForm.orgName = item.unitName
			},
			orgQuery(orgName) {
				this.orgSelect = null
				this.loginForm.orgId = 0
				this.$logic.http.get(true, '/api/unit/listorg/', {
					orgName: orgName
				}, res => {
					let list = []
					for (let item of res.data.data) {
						list.push({
							label: item.unitName,
							data: item
						})
					}
					this.orgOptions = list

				})

			},
			imgCodeUpdate(code) {
				this.checkCode = code

			},
			onCheckCodeInit(drawFunc) {
				this.drawFunc = drawFunc
			},
			loadFace() {
				this.$logic.http.get(true, '/api/unit/face/', {
					unitId: this.loginForm.orgId //预留，根据指定的ID返回带状态的单位名称,在info中返回
				}, res => {

					try {
						let cs = res.data.data
						cs = this.$logic.encoder.fromBase642(cs)
						let info = JSON.parse(cs)

						let baseInfo = info.sysMode + info.level + info.orgCode + info.orgName + info.orgShort +
							info.addModule + info.userSize + info.expiration +
							info.sysTitle + info.sysShort + info.sysLogo + info.support;

						if (info.serial && info.serial.length > 66) { //平台一定有注册码
							let versin = info.serial.substring(0, 2)
							let checkCode1 = info.serial.substring(2, 34)
							let checkCode2 = this.$logic.encoder.md5(baseInfo)
							if (checkCode1 == checkCode2) {
								this.sysInfo = info

							} else {
								info.sysMode = 'S'
							}

						}


						let orgName = info.orgName
						let reg = '未注册'
						let level = '' + info.level

						if (level in this.$logic.regInfo) {
							reg = this.$logic.regInfo[level]
						}
						if (info.level == 10000) {
							reg = ''
						}
						if (reg) {
							reg = ' - ' + reg
						}
						this.sysInfo.regLevel = info.level
						this.sysInfo.regTag = reg
						document.title = info.sysShort + info.sysTitle + reg

						if (info.sysMode === 'M') {
							this.unitLock = false
						} else {
							if (res.data.info && res.data.info > 0) { //有值表示是单组织模式 //'M' != info.sysMode
								this.loginForm.orgId = res.data.info
								this.loginForm.orgName = info.orgName + reg
							}
							this.unitLock = true
						}

						//回写信息到公共数据，用于其它地方调用
						/* this.$logic.sysInfo.sysMode = this.sysInfo.sysMode
						this.$logic.sysInfo.sysTitle = this.sysInfo.sysTitle
						this.$logic.sysInfo.sysShort = this.sysInfo.sysShort
						this.$logic.sysInfo.sysLogo = this.sysInfo.sysLogo
						this.$logic.sysInfo.sysUrl = this.sysInfo.sysUrl
						this.$logic.sysInfo.support = this.sysInfo.support

 */

						//let ss=info.serial
					} catch (ex) {
						//console.log(ex)
					}


				}, err => {
					//console.log(err)
				})
			}
		},
		components: {
			imgcode: ImgCode
		},
		created() {
			this.currentYear = new Date().getFullYear()
			this.sysInfo = this.$logic.clone.deepClone(this.$logic.sysInfo)
			this.loadFace()
			this.toLogin(true)
			this.regRules.adminPassword2[0].validator = this.checkPassword
			let us = localStorage.getItem("$$userUnit")
			if (us && us.length > 10) {
				let unit = JSON.parse(us)
				this.loginForm.orgId = unit.unitId
				this.loginForm.orgName = unit.unitName
			}

		}
	}
</script>

<style scoped>
	.login-content {
		width: 100%;
		height: 100%;
		min-height: 600px;
		display: flex;
		/* flex-direction: column; */
		justify-content: center;
		align-items: center;
		background-image: url('/public/img/desk/088.jpg');
		background-size: cover;
	}

	.loginblock {
		/*position: absolute;
		 margin-left: -250px;
		margin-top: -25%; */
		width: 98%;
		max-width: 500px;
		padding: 20px;
		background-color: #ffffff;
		border-radius: 20px;
		z-index: 10;
		-webkit-transition: all 1s;
		-o-transition: all 1s;
		-moz-transition: all 1s;
		transition: all 2s;


	}



	.blockhead {
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		background: linear-gradient(to right, #FEC163 0%, #DE4313 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

	}


	.icon {
		margin-right: 10px;
	}

	.infoitem {
		width: 100%;
		border-bottom: solid 1px #cccccc;
		margin-bottom: 10px;

	}

	.infolabel {
		font-size: 16px;
	}

	.infoicon {
		width: 40px;
		font-size: 20px;
		text-align: right;
	}

	.infoinput {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.infoinput input {
		width: calc(100% - 60px);
		height: 30px;
		margin-left: 10px;
		font-size: 18px;
		border: 0px;
		outline: none;

	}

	.reginfo {
		padding: 10px;
		border-bottom: solid 1px #cccccc;
		margin-bottom: 10px;
		color: #409EFF;
	}

	.regitem {
		width: 100%;
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.regresult {
		font-size: 20px;
		text-align: center;
		color: #67C23A;
	}

	.regresult div {
		margin: 10px 0px;
	}

	.regresult-icon {
		font-size: 50px;
	}

	.checkword-input {
		width: calc(100% - 60px - 150px) !important;

	}

	.checkword-code {
		width: 150px;
		height: 30px;
	}

	.actline {
		/* height: 1px; */
		width: 0px;
		padding-bottom: 2px;
		border-bottom: solid 1px #cccccc;
	}

	.inputactive {
		width: 100%;
		border-color: #F56C6C;
		-webkit-transition: all 0.5s;
		-o-transition: all 0.5s;
		-moz-transition: all 0.5s;
		transition: all 0.5s;
	}




	.button-group {
		display: flex;
		justify-content: center;
	}

	.button {
		width: 100px;
		height: 40px;
		margin: 10px 10px;
		border-radius: 30px;
		outline: none;
		border: none;
		cursor: pointer;
		font-size: 18px;
		color: white;
		transition: all .3s ease-in-out;
	}

	.button1 {


		background-image: linear-gradient(135deg, #E2B0FF 0%, #9F44D3 100%);
		box-shadow: 0 20px 30px -6px rgba(171, 88, 219, 0.5);

	}

	.button2 {

		background-image: linear-gradient(135deg, #81FBB8 0%, #28C76F 100%);
		box-shadow: 0 10px 20px -6px rgba(60, 211, 128, 0.5);

	}

	.button3 {

		background-image: linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%);
		box-shadow: 0 20px 30px -6px rgba(30, 161, 255, 0.5);

	}

	.button4 {

		background-image: linear-gradient(135deg, #FEB692 0%, #EA5455 100%);
		box-shadow: 0 20px 30px -6px rgba(238, 103, 97, 0.5);

	}

	.button:hover {
		-webkit-transform: translatey(5px);
		transform: translatey(5px);
		box-shadow: none;
	}

	.button:active {
		opacity: 0.5;
	}

	.loginshow {
		transform: rotateY(0deg);

	}

	.loginhide {
		transform: rotateY(180deg);

	}

	.reghide {
		transform: rotateY(180deg);


	}

	.regshow {
		transform: rotateY(0deg);


	}

	.sysinfo {
		display: flex;
		align-items: center;
		position: fixed;
		top: 0px;
		left: 5px;
		font-size: 30px;
		color: #FFFFFF;


	}

	.linear-text {
		/* 		background: linear-gradient(to right, #FEC163 0%, #DE4313 100%);
		background: linear-gradient(to right, rgb(253, 0, 255) 0%, #FEC163 100%);
		background: linear-gradient(45deg, #FFFFFF, rgba(254, 172, 94, 1), rgba(199, 121, 208, 1), rgba(75, 192, 200, 1));
		background: linear-gradient(90deg, #FFFFFF 35%, rgba(255, 205, 255, 1.0), rgba(255, 255, 205, 1.0), rgba(205, 253, 255, 1.0));
 */
		background: linear-gradient(90deg, #FFFFFF, rgba(255, 205, 255, 1.0), rgba(205, 253, 255, 1.0), rgba(255, 255, 165, 1.0));
		/* 		background: linear-gradient(90deg,#FFFFFF, rgba(255, 170, 255, 0.8), rgba(170, 255, 127, 0.8), rgba(255, 255, 127, 0.8), rgba(170, 255, 255, 0.8));
 */
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.logo-area {
		width: 50px;
		text-align: center;
	}

	.logo {
		font-size: 30px;
	}

	.logo-label {
		font-size: 12px;
		margin-top: -5px;
	}

	.compinfo {
		position: fixed;
		bottom: 5px;
		0: 5px;
		width: 100%;
		text-align: center;
		font-size: 20px;
		color: #FFFFFF;
	}
</style>