<template>

	<qiyeweixin v-if="model.type=='qywx' " :model="model" :project="project" :page="page" :host="host" :isedit="isedit"
		:datas="datas" />



	<div v-else>未知交互元素{{model.type}}</div>
</template>

<script>
	import QiYeWeiXin from './QiYeWeiXin.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {

			}
		},

		methods: {

		},
		components: {
			qiyeweixin: QiYeWeiXin
		},
		created() {


		}
	}
</script>

<style scoped>

</style>