import event from './event.js'
import chartColor from '../form/chart/echarts/initColor.js'
import deepClone from '../../../../plugins/deepClone.js'
// import logic from '../../../../plugins/logic.js'

/*logic.http.get(true, '/api/ds/getmaptree/', null, res => {
	console.log(this.mapTree)
}) */
let toOptions = (ex) => {
	let items = []
	let parts = ex.split(',')
	for (let part of parts) {
		let x = part.indexOf('=')
		let item = {
			label: part.substring(0, x),
			value: part.substring(x + 1)
		}
		items.push(item)
	}
	return items
}


let getInputOption = (key,data) => {
	let jsonArray={heads:[],sql:'',list:[],load:'N'}//load 是否开启动态数据加载,列表型数据对于每种组件定义数据成员{key:'',label:'',type:'',list:[],field:''}
	//express:{content:'',bolanshi:null,vars:{},empty:} empty零值处理方式，n为空，z按0处理
	let inputOption= {seted:false,sourceId:0,default:'',textAction:[],decimal:2,numAction:'num',dateInit:'',dateType:'datetime',selectOptions:[],jsonArray:jsonArray,express:{content:'',bolanshi:null,vars:{},empty:'z'},tree:{width:'800px',height:'80vh',char:'/',nodes:null,sql:'',id:'',pId:'',label:'',textPath:'',idPath:''},treedata:{sql:'',tableStyle:{heads:[]},ListResult:[]} ,load:{sql:'',fields:[],list:[],fill:[],tableMenu:'N',maxSize:20,menuWidth:'',menuHeight:'200',tableStyle:  {heads: []}}}//dataSql:'',listSql:''
	
	 let options=inputOption //deepClone.deepClone(inputOption)

	return options
}
let fonts = [{label: '默认',value: ''}, {label: '宋体',value: 'SimSun'}, {label: '黑体',value: 'SimHei'},	{label: '微软雅黑',value: 'Microsoft YaHei'}, {label: '微软正黑',value: 'Microsoft JhengHei'},	{label: '楷体',value: 'KaiTi'}, {label: '仿宋',value: 'FangSong'}, {label: '新宋体',value: 'NSimSun'},	{label: '仿宋_GB2312',value: 'FangSong_GB2312'}, {label: '楷体_GB2312',value: 'KaiTi_GB2312'},	{label: '新细明体',value: 'PMingLiU'}, {label: '细明体',value: 'MingLiU'}, {label: '标楷体',value: 'DFKai-SB'	}
]

let fontSizes=[{label:'默认',value:''}]
for(let i=8;i<90;i++){
	fontSizes.push({label:i+'号',value:i+'px'})
}

let LCRS = [{label: '左侧',value: 'left'}, {label: '中间',value: 'center'}, {label: '右侧',value: 'right'}]
let LCR = [{label: '左侧',value: 'left'}, {label: '中间',value: 'center'}, {label: '右侧',value: 'right'}, {label: '自动',value: ''}, ]
let TMB = [{label: '顶部',value: 'top'}, {label: '中间',value: 'middle'}, {label: '底部',value: 'bottom'}, {label: '自动',value: ''}, ]
let alignsH = [{label: '左侧',value: 'flex-start'}, {label: '居中',value: 'center'}, {label: '右侧',value: 'flex-end'}, {label: '两端',value: 'space-between'}, {label: '等间距',value: 'space-around'}]
let alignsV = [{label: '顶部',value: 'flex-start'}, {label: '中间',value: 'center'}, {label: '底部',value: 'flex-end'}]
let alignContents = [{label: '顶部',value: 'flex-start'}, {label: '中间',value: 'center'}, {label: '底部',value: 'flex-end'}, {label: '两端',value: 'space-between'}, {label: '等间距',value: 'space-around'}, {label: '拉伸',value: 'stretch'}]
let Positions = [{label: '左侧',value: 'left'}, {label: '右侧',value: 'right'}, {label: '顶部',value: 'top'}, {label: '底部',value: 'bottom'}]
let TipPositions = [{label: '左侧上',value: 'left-start'}, {label: '左侧中',value: 'left'}, {label: '左侧下',value: 'left-end'}, {label: '右侧上',value: 'right-start'}, {label: '右侧中',value: 'right'}, {label: '右侧下',value: 'right-end'}, {label: '顶部前',value: 'top-start'}, {label: '顶部中',value: 'top'}, {label: '顶部后',value: 'top-end'}, {label: '底部前',value: 'bottom-start'}, {label: '底部中',value: 'bottom'}, {label: '底部后',value: 'bottom-end'}]

let YON = [{label: '是',value: 'Y'}, {label: '否',value: 'N'}]
let scroll = [{label: '自动',value: 'auto'}, {label: '始终',value: 'scroll'}, {label: '不滚动可溢出',value: 'visible'}, {label: '不滚动不溢出',
	value: 'hidden'}]

let BorderS = [{label: '无',value: 'N'}, {label: '全边框',value: 'A'}, {label: '上边框',value: 'T'}, {label: '下边框',value: 'B'}, {label: '左边框',value: 'L'}, {label: '右边框',value: 'R'}]
let BorderType = [{label: '无',value: 'none'}, {label: '实线',value: 'solid'}, {label: '虚线',value: 'dashed'}, {label: '点线',value: 'dotted'}, {label: '双实线',value: 'double'}, {label: '立体凸线',value: 'outset'}, {label: '立体凹线',value: 'inset'}]
let ChartLineType = [{label: '实线',value: 'solid'}, {label: '虚线',value: 'dashed'}, {label: '点线',value: 'dotted'}]
let Directions = [{label: '水平',value: 'horizontal'}, {label: '垂直',value: 'vertical'}]
let imgModes = [{label: '等比缩放填充',value: 'cover'}, {label: '自由拉伸填充',value: 'fill'}, {label: '原始大小',value: 'no-repeat'}, {label: 'XY平铺',value: ''}, {label: 'X平铺',value: 'repeat-x'}, {label: 'Y平铺',value: 'repeat-y'}]
let showTypes = [{label: '显示',value: 'show'}, {label: '隐藏（消除）',value: 'none'}, {label: '隐藏（占位）',value: 'hidden'}]
let inputTypes = [{label: '文本输入',value: 'text'}, {label: '数值输入',
		value: 'num'}, {label: '下拉选择',value: 'select'}, {label: '日期时间选择',value: 'date'}, {label: '公式计算',value: 'express'}, {label: '树目录对话框选择',value: 'tree'}, {label: '树列表数据对话框',value: 'leaf'}, {label: '联动加载',value: 'load'	} //,{label:'多行文本输入',value:'area'},{label:'逐渐提示',value:'list'},{label:'对话框扩展数据回填',value:'fill'}
]
let orients = [{label: '水平',value: 'horizontal'}, {label: '垂直',value: 'vertical'}]
let dataTypes = toOptions('文本字符=text,整数=int,浮点数=num,日期时间=datetime,日期=date,时间=time,数组=array')
let rotateTypes = toOptions('不旋转=,顺时针旋转=rotate-forward,逆时针旋转=rotate-reverse')
let chartPosition=toOptions('图形外部=outside,图形内部=inside,图形内部左侧=insideLeft,图形内部右侧=insideRight,图形左侧=left,图形左侧上部=leftTop,图形左侧下部=leftBottom,图形右侧=right,图形右侧上部=rightTop,图形顶部=top,图形底部=bottom,图形右侧下部=rightBottom')
let chartSymbol=toOptions('圆点=circle,矩形=rect,圆角矩形=roundRect,三角形=triangle,菱形=diamond,标记=pin,箭头=arrow,无=none')
let initColor = {
	text: {
		dark: '#fffffe',
		light: '#333332'
},
	line: {
		dark: '#fffffe',
		light: '#333332'
	}
}

let insertConfig = (list, sub, idx) => {
	for (let i = 0; i < sub.length; i++) { //坐标系插入基本属性中
		list.splice(i + idx, 0, sub[i])
	}
}
let insertBaseConfig = (list, sub) => {
	insertConfig(list, sub, 4)
}
let getConfigs = (configs, keys) => {
	let rs = []
	for (let key of keys.split(',')) {
		for (let item of configs) {
			if (item.key === key) {
				rs.push(item)
			}
		}
	}
	return rs
}
let getCommonEvents = () => {
	let events = event.getEvents(null, event.commonEvents, null)
	return events
}
let getDataSet=(heads)=>{
	let dataSet={dataSourceId:0,sql:'',headItems:heads,queryItems:[],enumItems:[],rangeItems:[],rangeUse:false,rangeField:null,orderItems:[],foldPanel:null,tick:0}
	return dataSet
}
let orgSql="select id,pid, orgname as label,'fal fa-user-friends' as icon from sys_org where status=0 order by pid, idx"
let roleSql="select id,pid,rolename as label ,'fal fa-user-tag' as icon from sys_role where status=0 order by pid,idx"
let userSql="select id,username as label,empcode,mobile   from sys_user where userstatus !=2 and id in (select userid from sys_orguser where orgid=$id and username like '%$keyword%' ) order by  empcode,useridx"

export default {
	LCR,
	LCRS,
	alignsH,
	alignsV,
	alignContents,
	Positions,
	TipPositions,
	TMB: TMB,
	YON: YON,
	Scroll: scroll,
	BorderS: BorderS,
	BorderType: BorderType,
	Directions: Directions,
	orients,
	ImgModes: imgModes,
	showTypes,
	getConfigs,
	getEvents: event.getEvents,
	listLoadAfterEvents:event.listLoadAfterEvents,
	getCommonEvents,
	getDataSet,
	toOptions,
	inputTypes,
	dataTypes,
	rotateTypes,
	fonts,
	fontSizes,
	initColor,
	ChartLineType,
	chartPosition,
	chartSymbol,
	chartColor,
	insertBaseConfig,
	orgSql,
	roleSql,
	userSql,
	//inputOption,
	getInputOption,
}
