<template>

	<div ref="pb" v-print="printOption" v-show="false"></div>

	<div v-show="model.show !=='none' " style="width:100%;overflow:auto;">
		<div v-show="isedit" class="tickbar" :style="{width:pageSize.width+'px'}">
			<div v-for="item in sizeTick" :style="item">
				<div :style="{marginLeft:item.ml}">{{item.label}}</div>
				<div style="height:5px;width:1px" class="printtick"></div>
			</div>
		</div>

		<div :id="areaId" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave" @mouseup="mouseUp"
			:style="printStyle" :class="printcss">
			<!-- 			<table border="1px" class="printgrid">
				<tr v-for="row in 10">
					<td v-for="col in 10">
						{{col}}
					</td>

				</tr>

			</table> -->

			<template v-for="item in model.items">
				<container v-if="item.group==='layout'" :model="item" :project="project" :page="page" :host="host"
					:isedit="isedit" :datas="bindData" />
				<base-element v-else :model="item" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="bindData" />

			</template>

		</div>
	</div>
</template>

<script>
	//import containerBase from '../containerBase.js'
	import layoutBase from './layoutBase.js'
	//import VuePrintNb from 'vue3-print-nb'

	export default {
		mixins: [layoutBase],
		data() {
			return {
				headTop: 6, //页面头部留出6毫米空白
				areaId: null,
				bindData: null,
				pageSizeMap: {
					A3: {
						width: 297,
						height: 420
					},
					A4: {
						width: 210,
						height: 297
					},
					A5: {
						width: 148,
						height: 210
					},
					A6: {
						width: 105,
						height: 144
					}
				}

			}
		},

		methods: {
			delay(ms) {
				return new Promise(resolve => {
					setTimeout(resolve, ms);
				})
			},
			async autoLayout() { //调整表格到合适的位置，避免跨行
				if (this.model.autoRow != 'Y') {
					return
				}
				var panel = document.getElementById(this.areaId);
				if (!panel) {
					return
				}
				let dpi = this.model.dpi || 96
				let padV = this.headTop * 2 * 1123 / 297 * (dpi / 96)
				let pageHeight = this.pageSize.height - padV //打印页内容区总高度
				var elements = panel.querySelectorAll('tr');
				//let elementsHeight=[]

				for (let i = 0; i < elements.length; i++) {
					let xPosition = 0;
					let yPosition = 0;
					let element = elements[i]
					let curentNode = element
					while (element && element != panel) {
						xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
						yPosition += (element.offsetTop - element.scrollTop + element.clientTop);

						element = element.offsetParent;
					}

					let rowHeightMin = curentNode.offsetHeight - 10
					let ph = yPosition % pageHeight
					if ((pageHeight - ph) < rowHeightMin) { //当前行的位置不足以完整显示全自己

						//curentNode.style.backgroundColor = 'red'
						if (i > 0) {
							let preTr = elements[i - 1]
							let oldHeight = preTr.offsetHeight
							let newHeigtht = parseInt(oldHeight + (pageHeight - ph))
							preTr.style.height = newHeigtht + 'px'
							//console.log(i + 1, oldHeight, newHeigtht, (pageHeight - ph))
							//await this.delay(500); //延时等页面渲染,实际并没有发生作用，后面把preTr.style.height移动定时器内切换线程执行
						}
						//break;

					}

				}

			},
			async toPrint() { //berforeRecall,afterRecall
				/* 	let ck = this.doEvent({
						eventName: 'printbefore',
						printId: '#' + this.model.id
					}, '$comp_printBefore', this.bindData)
					 */
				let ck = this.doEvent({
					eventName: 'printbefore',
					printId: '#' + this.model.id
				}, '$comp_printBefore')
				if (ck === false) {
					return
				}



				if (this.model.show === 'show') {
					await this.autoLayout()
					this.$refs.pb.click()
				} else {
					this.model.show = 'show'
					this.$nextTick(async () => {
						await this.autoLayout()
						this.$refs.pb.click()
					})
				}



			},

			beforeOpenCallback(param) { //弃用

			},
			openCallback(param) { //弃用

			},
			closeCallback(param) {
				if (this.model.autoHide === 'Y') {
					this.model.show = 'none'
				}
				this.doEvent({
					eventName: 'printafter',
					printId: '#' + this.model.id
				}, '$comp_printAfter', this.bindData)
			},
			setPageSize() {
				let page = this.pageSizeMap[this.model.pageType]
				if (page) {
					if (this.model.direction == 'V') {
						this.model.pageWidth = page.width
						this.model.pageHeight = page.height
					} else {
						this.model.pageWidth = page.height
						this.model.pageHeight = page.width
					}

				}
			}



		},
		computed: {
			printStyle() {
				let model = this.model
				let style = this.layoutstyle
				let size = this.pageSize
				//计算宽度
				let pl = parseInt(model.paddingL || '0px')
				let pr = parseInt(model.paddingR || '0px')
				let pt = parseInt(model.paddingT || '0px')
				let pb = parseInt(model.paddingB || '0px')
				pl = isNaN(pl) ? 0 : pl
				pr = isNaN(pr) ? 0 : pr
				pt = isNaN(pt) ? 0 : pt
				pb = isNaN(pb) ? 0 : pb

				style.width = (size.width - pl - pr) + 'px'
				//style.minHeight = (size.height - pt - pb  ) + 'px'

				let padV = this.headTop * 2 //页眉、页脚 高度
				let dpi = model.dpi || 96
				//A4纸96dpi下的尺寸210*297 分辨率是794×1123				 
				padV = padV * 1123 / 297 * (dpi / 96)
				style.minHeight = (size.height - pt - pb - padV) + 'px'

				style.position = 'relative'

				return style
			},
			printcss() {
				let css = this.layoutcss
				if (this.isedit) {
					css.push('printcontainer')
				}
				return css
			},
			printOption() {
				let model = this.model
				let printObj = {
					id: this.areaId,
					beforeOpenCallback: this.beforeOpenCallback,
					openCallback: this.openCallback,
					closeCallback: this.closeCallback,
					//其他配置项，
					extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>', //model.extraHead,
					//header: '<div class="header">我的页眉</div>',
					/* printerName :'Solid Edge Velocity PS Printer 2.0',
					 popTitle: model.popTitle,
					preview: model.preview === 'Y',
					previewTitle: model.previewTitle,
					previewPrintBtnLabel: model.previewPrintBtnLabel */
				}
				return printObj

			},
			/* 	pageLines() {
					let lines = []
					pageSize
				}, */
			sizeTick() {
				let ts = []
				let size = this.model.pageWidth || 210
				let width = this.pageSize.width
				for (let i = 10; i < size; i = i + 10) {
					let item = {
						left: parseInt(i * width / size) + 'px',
						ml: parseInt(-(width * 10 / 2 / size)) + 'px',
						width: parseInt(width * 10 / size) + 'px',
						label: i,
						position: 'absolute'
					}
					/* if (i % 10 === 0) {
						item.t = i / 10 + 'mm'
					} */
					ts.push(item)
				}
				return ts
			},
			pageSize() {
				let model = this.model
				let width = model.pageWidth || 210
				let height = model.pageHeight || 297
				let dpi = model.dpi || 96
				//A4纸96dpi下的尺寸210*297 分辨率是794×1123
				width = parseInt(794 / 210 * width * (dpi / 96)) //等比例换算象素值
				height = parseInt(1123 / 297 * height * (dpi / 96))

				return {
					width: width,
					height: height
				}
			}

		},
		watch: {
			'model.pageType'(newValue, oldValue) {

				this.setPageSize()
			},
			'model.direction'(newValue, oldValue) {
				this.setPageSize()
			}
		},
		mounted() {
			/* 	this.doEvent({
					eventName: 'mounted'
				}, '$comp_loadAfter') */
		},
		created() {
			this.areaId = 'print' + new Date().getTime()
			if (!this.isedit) {
				this.model.$print = this.toPrint
				if (this.model.autoHide === 'Y') {
					this.model.show = 'none'
				}
				/* 	this.doEvent({
						eventName: 'create'
					}, '$comp_loadBefore') */

			}
			this.createInit()

		}
	}
</script>

<style scoped>
	.printcontainer {
		box-shadow: 0px 0px 0px 1px #cccccc inset;
		background-image: linear-gradient(90deg, rgba(241, 243, 244, 1) 10%, transparent 0),
			linear-gradient(rgba(241, 243, 244, 1) 10%, transparent 0);
		background-size: 10px 10px;
	}

	.tickbar {
		display: flex;
		position: relative;
		height: 25px;
		overflow: hidden;
		background-color: #e8e8e8;
	}



	.printtick {
		background-color: #ccccc;
		border-left: 1px solid #000000;
	}
</style>
<style scoped media="print">
	@page {
		size: auto;
		margin-top: 6mm;
		margin-bottom: 6mm;
		margin-left: 0mm;
		margin-right: 0mm;
		page-break-before: always;


	}
</style>