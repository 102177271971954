class Color {
	//Echarts的渐变色Json表达式转CSS
	constructor() {
		this.eyeDropper = ('EyeDropper' in window) ? new EyeDropper() : null
	}
	getColorFromEcharts(colorExp) { //可能是颜色字符串，也可能是Json
		let item = colorExp
		let color = colorExp
		if (typeof(item) === 'object' && 'type' in item) { //渐变色类型,转css格式
			if (item.type == 'linear' || item.type == 'radial') {
				if (item.type == 'linear') {
					let deg = this.getDegFormPoint(item)
					color = 'linear-gradient(' + deg + 'deg'
				} else {
					color = 'radial-gradient( at '
					let x = item.x == 0 ? 'left' : item.x == 1 ? 'right' : 'center'
					let y = item.y == 0 ? 'top' : item.y == 1 ? 'bottom' : 'center'
					color = color + x + ' ' + y
				}

				for (let stop of item.colorStops) {
					color = color + ',' + stop.color + ' ' + stop.offset * 100 + '%'
				}
				color = color + ')'
			} else {
				color = '#000000'
			}

		}
		return color
	}
	////////////////////CSS渐变色与Echarts的Json表达式转换
	getEchartsColor(color) {
		let rs = null
		let a = color.indexOf('(')
		if (a < 0) {
			return color
		}

		let type = color.substring(0, a)
		let b = color.lastIndexOf(')')
		let content = color.substring(a + 1, b)
		// 提取 linear-gradient(180deg,rgba(64, 158, 255, 1),rgba(175, 4, 254, 1))
		let parts = [] // content.split(',')
		let stack = []
		let part = ''
		for (let char of content.split('')) {
			if (char === '(') {
				stack.push(char)
				part = part + char
			} else if (char === ')') {
				stack.pop()
				part = part + char
			} else if (char === ',' && stack.length === 0) { //最外层的,表示内容结束，添加到结果列表
				parts.push(part.trim())
				part = ''

			} else {
				part = part + char
			}

		}
		parts.push(part.trim())

		if ((type === 'linear-gradient' || type === 'radial-gradient') && parts.length > 2) { //至少有三段
			let deg = parseInt(parts[0])
			let point = this.getPointFormDeg(deg)
			let positions = ['center', 'center']
			if (type === 'radial-gradient') {
				positions = parts[0].substring(3).split(' ')
			}
			rs = type === 'linear-gradient' ? {
				type: 'linear',
				x: point.x1,
				y: point.y1,
				x2: point.x2,
				y2: point.y2,
				colorStops: []
			} : {
				type: 'radial',
				x: positions[0] == 'center' ? 0.5 : positions[0] == 'left' ? 0 : 1,
				y: positions[1] == 'center' ? 0.5 : positions[1] == 'left' ? 0 : 1,
				r: positions[0] == 'center' && positions[1] == 'center' ? 0.5 : 1,
				colorStops: []
			}
			for (let i = 1; i < parts.length; i++) { //如果没有百分比数据，按出现的顺序算比值
				let part = parts[i]
				let a = part.lastIndexOf(' ')
				let b = part.lastIndexOf(')')
				let stop = {
					color: part
				}
				if (a > b) { //有百分比值
					stop.color = part.substring(0, a)
					stop.offset = parseInt(part.substring(a + 1)) / 100.0 //百分比转小数
				} else {
					stop.offset = (i - 1) * (100 / (parts.length - 2)) / 100.0 //分段 
				}
				rs.colorStops.push(stop)

			}
			//console.log(deg, rs, color)
		} else {
			return color
		}
		return rs

	}
	//坐标与角度换算,y2-y1 / x2-x1 取反tan
	getDegFormPoint(point) {
		let h = point.y2 - point.y
		let w = point.x2 - point.x
		let deg = Math.atan(h / w)
		if (point.x > 0.5) { //角度超过180度起点在右半圈范围内
			deg = deg * 180 / Math.PI + 180 + 90
		} else {
			deg = deg * 180 / Math.PI + 90
		}
		return deg

	}

	///角度与坐标换算，穿过 1 X 1 的正方形中心的直线角度，垂直底部向上方向为0度，依次旋转45度为一个区间，分8种情况计算直线与边框的交界点
	getPointFormDeg(deg) {
		let p1 = {
			x: 0,
			y: 0
		}
		let p2 = {
			x: 0,
			y: 0
		}
		let x = 0,
			y = 0
		if (deg >= 0 && deg < 45) { //上左方式一区
			x = Math.tan(deg * Math.PI / 180) * 0.5 //X偏移量
			p1.x = 0.5 - x
			p1.y = 1
			p2.x = 0.5 + x
			p2.y = 0
		} else if (deg >= 45 && deg < 90) { //二区
			y = Math.tan((90 - deg) * Math.PI / 180) * 0.5 //Y偏移长度
			p1.x = 0
			p1.y = 0.5 + y
			p2.x = 1
			p2.y = 0.5 - y
			console.log(p1, p2)
		} else if (deg >= 90 && deg < 135) { //三区
			y = Math.tan((deg - 90) * Math.PI / 180) * 0.5 //Y偏移长度
			p1.x = 0
			p1.y = 0.5 - y
			p2.x = 1
			p2.y = 0.5 + y
		} else if (deg >= 135 && deg < 180) { //四区 。下左
			x = Math.tan((180 - deg) * Math.PI / 180) * 0.5 //x偏移长度
			p1.x = 0.5 - x
			p1.y = 0
			p2.x = 0.5 + x
			p2.y = 1
		} else if (deg >= 180 && deg < 225) { //五区
			x = Math.tan((deg - 180) * Math.PI / 180) * 0.5 //x偏移长度
			p1.x = 0.5 + x
			p1.y = 0
			p2.x = 0.5 - x
			p2.y = 1

		} else if (deg >= 225 && deg < 270) { //六
			y = Math.tan((270 - deg) * Math.PI / 180) * 0.5 //Y偏移长度
			p1.x = 1
			p1.y = 0.5 - y
			p2.x = 0.5 - x
			p2.y = 0.5 + y
		} else if (deg >= 270 && deg < 315) {
			y = Math.tan((deg - 270) * Math.PI / 180) * 0.5 //Y偏移长度
			p1.x = 1
			p1.y = 0.5 + y
			p2.x = 0
			p2.y = 0.5 - y
		} else if (deg >= 315 && deg <= 360) {
			x = Math.tan((360 - deg) * Math.PI / 180) * 0.5 //Y偏移长度
			p1.x = 0.5 + x
			p1.y = 1
			p2.x = 0.5 - x
			p2.y = 0
		}
		return {
			x1: p1.x,
			y1: p1.y,
			x2: p2.x,
			y2: p2.y
		}
	}

	/////////////////////////////////////////////////////////////
	async getScreenColor(alpha = false) { //获取屏幕颜色
		let color = null
		try {

			//if (this.eyeDropper) {
			if (window.EyeDropper) {
				const result = await this.eyeDropper.open()
				const colorHexValue = result.sRGBHex

				let color = colorHexValue
				if (alpha) {
					color = 'rgba(' + this.hexToRgbArray(color) + ',1)'
				}
				//console.log(result, colorHexValue, alpha, color)
				return color
			} else {
				alert('您的浏览器不支持此功能，谷歌浏览器内核请升级至94以上的版本')
			}
		} catch (e) {
			console.log(e)
			let info = '' + e
			if (info.indexOf(' canceled ') < 0) {
				console.log(e)
			}

			//console.log('用户取消了取色')

		}
		return color

	}

	///////////////////////////////////
	randomHex() {
		return '#' + ('00' + Math.floor(Math.random() * 16777216).toString(16)).substr(-6)
	}
	hexArray(hex) {
		return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	}
	rgbObjectFromHex(hex) {
		const result = this.hexArray(hex)
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		} : null;
	}
	rgbArrayFromHex(hex) {
		const rgb = this.rgbObjectFromHex(hex)
		return [rgb.r, rgb.g, rgb.b]
	}
	hslObjectFromHex(hex) {
		const hsl = {}
		const rgb = this.rgbObjectFromHex(hex)

		rgb.r /= 255, rgb.g /= 255, rgb.b /= 255
		let max = Math.max(rgb.r, rgb.g, rgb.b)
		let min = Math.min(rgb.r, rgb.g, rgb.b)

		hsl.l = (max + min) / 2

		if (max == min) {
			hsl.h = hsl.s = 0 // achromatic
		} else {
			let d = max - min

			hsl.s = hsl.l > 0.5 ? (d / (2 - max - min)) : (d / (max + min))

			switch (max) {
				case rgb.r:
					hsl.h = (rgb.g - rgb.b) / d + (rgb.g < rgb.b ? 6 : 0)
					break
				case rgb.g:
					hsl.h = (rgb.b - rgb.r) / d + 2
					break
				case rgb.b:
					hsl.h = (rgb.r - rgb.g) / d + 4
					break
			}

			hsl.h /= 6;
		}

		return {
			h: Math.round(hsl.h * 360),
			s: Math.round(hsl.s * 100),
			l: Math.round(hsl.l * 100),
		}
	}
	hslArrayFromHex(hex) {
		const hsl = this.hslObjectFromHex(hex)
		return [hsl.h, hsl.s, hsl.l]
	}
	hexToRgb(hex) {
		const rgb = this.rgbObjectFromHex(hex)
		return `rgb(${rgb.r},${rgb.g},${rgb.b})`
	}
	hexToRgbArray(hex) {
		const rgb = this.rgbObjectFromHex(hex)
		return `${rgb.r}, ${rgb.g}, ${rgb.b}`
	}
	hexToHsl(hex) {
		const hsl = this.hslObjectFromHex(hex)
		return `hsl(${hsl.h},${hsl.s}%,${hsl.l}%)`
	}
	rgbChannelToHex(channel) {
		return channel.toString(16).padStart(2, '0');
	}
	rgbToHex(r, g, b) {
		return "#" + [r, g, b].map(x => Math.round(x).toString(16).padStart(2, 0)).join('');
	}
	rgbArrayToHex(color) {
		return this.rgbToHex(color[0], color[1], color[2])
	}
	hslToRgbObject(h, s, l) {
		let r, g, b;

		if (s == 0) {
			r = g = b = l; // achromatic
		} else {
			const hue2rgb = function hue2rgb(p, q, t) {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return p + (q - p) * 6 * t;
				if (t < 1 / 2) return q;
				if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

				return p;
			}

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;


			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}

		console.log(r, g, b);

		return {
			r: Math.round(r * 255),
			g: Math.round(g * 255),
			b: Math.round(b * 255)
		}
	}
	hslToRgb(h, s, l) {
		let a = s * Math.min(l, 1 - l);
		let f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return [f(0), f(8), f(4)];
	}
	hslToHex(h, s, l) {
		l /= 100;

		const a = s * Math.min(l, 1 - l) / 100;

		const hex = n => {
			const k = (n + h / 30) % 12;
			const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

			return Math.round(255 * color).toString(16).padStart(2, '0');
		}

		return `#${hex(0)}${hex(8)}${hex(4)}`;
	}
	brightness(hex) {
		const rgb = this.rgbObjectFromHex(hex)
		return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
	}
	foregroundAdjust(hex, test, lightHex, darkHex) {
		test = test || 154
		lightHex = lightHex || '#fff'
		darkHex = darkHex || '#000'
		return this.brightness(hex) < test ? lightHex : darkHex
	}
	interpolateColor(color1, color2, factor) {
		if (arguments.length < 3) {
			factor = 0.5
		}
		const result = color1.slice()

		for (var i = 0; i < 3; i++) {
			result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
		}
		return result
	}
	interpolateColors(color1, color2, steps) {
		let stepFactor = 1 / (steps - 1)
		let interpolatedColorArray = []

		color1 = this.rgbArrayFromHex(color1).map(Number)
		color2 = this.rgbArrayFromHex(color2).map(Number)

		for (var i = 0; i < steps; i++) {
			interpolatedColorArray.push(this.interpolateColor(color1, color2, stepFactor * i))
		}

		return interpolatedColorArray
	}






	//老方法==================================================================================

	getLightColor(hex, deep) { //一个简单的算法，颜色转数值乘陪数的倒数即可，如加深一倍1/2
		let rgb = this.hexToRgb(hex)
		let per = deep
		/* rgb[0]=parseInt(rgb[0]*per % 256)
		rgb[1]=parseInt(rgb[1]*per % 256)
		rgb[2]=parseInt(rgb[2]*per % 256) */

		rgb[0] = parseInt((255 - rgb[0]) * per) + rgb[0]
		rgb[1] = parseInt((255 - rgb[1]) * per) + rgb[1]
		rgb[2] = parseInt((255 - rgb[2]) * per) + rgb[2]


		let color = this.rgbToHex(rgb[0], rgb[1], rgb[2])

		return color
	}
	//凸起效果，中间深两头浅,
	getConvex(hex, per) {
		let hsl = this.rgb16ToHsl(hex)
		let light = parseInt(hsl[2] * per + 0.5)
		let rgb = this.hslToRgb(hsl[0], hsl[1], light)
		let color = this.rgbToHex(rgb[0], rgb[1], rgb[2])
		return color
	}


	rgb16ToHsl(colore) { //#ffaa00
		let x = colore.indexOf("#")
		if (x > -1) {
			colore = colore.substring(x + 1)
		}
		let r = parseInt(colore.substring(0, 2), 16)
		let g = parseInt(colore.substring(2, 4), 16)
		let b = parseInt(colore.substring(5), 16)
		return this.rgbToHsl(r, g, b)
	}
	rgbToHsl(r, g, b) {
		r = r / 255;
		g = g / 255;
		b = b / 255;

		var min = Math.min(r, g, b);
		var max = Math.max(r, g, b);
		var l = (min + max) / 2;
		var difference = max - min;
		var h, s, l;
		if (max == min) {
			h = 0;
			s = 0;
		} else {
			s = l > 0.5 ? difference / (2.0 - max - min) : difference / (max + min);
			switch (max) {
				case r:
					h = (g - b) / difference + (g < b ? 6 : 0);
					break;
				case g:
					h = 2.0 + (b - r) / difference;
					break;
				case b:
					h = 4.0 + (r - g) / difference;
					break;
			}
			h = Math.round(h * 60);
		}
		s = Math.round(s * 100); //转换成百分比的形式
		l = Math.round(l * 100);
		return [h, s, l];
	}
	hslToRgb(h, s, l) {
		var h = h / 360;
		var s = s / 100;
		var l = l / 100;
		var rgb = [];
		if (s == 0) {
			rgb = [Math.round(l * 255), Math.round(l * 255), Math.round(l * 255)];
		} else {
			var q = l >= 0.5 ? (l + s - l * s) : (l * (1 + s));
			var p = 2 * l - q;
			var tr = rgb[0] = h + 1 / 3;
			var tg = rgb[1] = h;
			var tb = rgb[2] = h - 1 / 3;
			for (var i = 0; i < rgb.length; i++) {
				var tc = rgb[i];
				if (tc < 0) {
					tc = tc + 1;
				} else if (tc > 1) {
					tc = tc - 1;
				}
				switch (true) {
					case (tc < (1 / 6)):
						tc = p + (q - p) * 6 * tc;
						break;
					case ((1 / 6) <= tc && tc < 0.5):
						tc = q;
						break;
					case (0.5 <= tc && tc < (2 / 3)):
						tc = p + (q - p) * (4 - 6 * tc);
						break;
					default:
						tc = p;
						break;
				}
				rgb[i] = Math.round(tc * 255);
			}
		}
		return rgb;
	}
	rgbToHex(r, g, b) {
		let hr = r.toString(16)
		let hg = g.toString(16)
		let hb = b.toString(16)
		hr = hr.length > 1 ? hr : '0' + hr
		hg = hg.length > 1 ? hg : '0' + hg
		hb = hb.length > 1 ? hb : '0' + hb
		return '#' + hr + hg + hb
	}
	hexToRgb(hex) { //#FF00BB
		return [parseInt(hex.substr(1, 2), 16), parseInt(hex.substr(3,
			2), 16), parseInt(hex.substr(5, 2), 16)]
	}

}

export default new Color()