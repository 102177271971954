<template>
	<div>
		<el-upload ref="uploadObj" :auto-upload="autoUpload" :action="uploadUrl" :data="uploadDataEx"
			:before-upload="beforeUpload" :multiple="false" :limit="1" :file-list="selectedFiles" :on-exceed="onExceed"
			:on-success="onSuccess" :on-error="onError" :show-file-list="false">
			<slot></slot>

		</el-upload>
	</div>
</template>

<script>
	import fileUpload from './upload.js'
	export default {
		props: {
			autoUpload: {
				type: Boolean,
				default: true
			},
			beforeRecall: {
				type: Function,
				default: null
			},
			dataEx: { //获取扩展数据
				type: Object,
				default: null
			}
		},
		mixins: [fileUpload],
		/* props: {
			serverUrl: {
				type: String,
				default: null
			}
		}, */
		emits: ['success', 'before'],

		data() {
			return {

			}
		},

		methods: {
			/* upload(){
				this.$refs.uploadObj.submit()
			}, */
			beforeUpload(file) {
				if (this.beforeRecall) {
					let rs = this.beforeRecall(file)
					if (rs === false) {
						this.uploading = false
						return this.uploading
					}
				}
				this.uploading = true
				return this.uploading
			},
			/* 	upload(){
					console.log(this.$refs.uploadObj)
					this.$refs.uploadObj.handleStart()
					//this.$refs.uploadObj.submit()
				} */
		},
		computed: {
			uploadDataEx() {
				let data = {
					...this.uploadData
				}
				if (this.dataEx) {
					for (let key in this.dataEx) {
						data[key] = this.dataEx[key]
					}
				}
				//console.log(data)
				return data
			}
		},

		created() {


		}
	}
</script>

<style scoped>

</style>