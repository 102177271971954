import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import layout from '../../public/layout.js'

let templateCode=JSON.stringify([{paramName:'param1',value:'content1'},{paramName:'param2',value:'content2'}])
templateCode=templateCode.replace('[','[\r\n')
templateCode=templateCode.replace(']','\r\n]')
templateCode=templateCode.replace('},','},\r\n')
let base = [
	{key: 'title',text: '短信验证码'	},	
//	{key: 'mobile',text: '手机号码',type: 'text',init: '',	target: '',bursh: true,bind: false,tip: '只用于获取手机号',list: null},
	//{key: 'code',text: '验证码',type: 'text',init: '',	target: '',bursh: true,bind: false,tip: '只用于获取返回的验证码',list: null},

	{key: 'accessKeyId',text: '短信帐号ID',type: 'text',init: '',	target: '',bursh: true,bind: false,tip: '阿里云短信平台账号，密码信息请在系统后台配置',list: null},
	{key: 'templateCode',text: '模板ID',type: 'text',init: '',	target: '',	bursh: true,bind: false,tip: '阿里云短信平台消息模板ID',list: null},
	{key: 'paramName',text: '验证码名称',type: 'text',init: '',	target: '',	bursh: true,bind: false,tip: '验证码内容在模板中的参数名称名称',list: null},
	{key: 'templateParam',text: '模板参数',type: 'json',init: templateCode,	target: '',	bursh: true,bind: false,tip: '根据消息模板中参数名称设置固定内容',list: null},

	{key: 'signName',text: '短信签名',type: 'text',init: '',	target: '',	bursh: true,bind: false,tip: '',list: null},

	{key: 'many',text: '数字位数',type: 'icon',init: '6',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'seconds',text: '重发等待秒',type: 'text',init: '60',	target: '',	bursh: true,bind: false,tip: '',list: null},
	{key: 'title',text: '组件样式'	},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},

]
let codeCfgs=options.getConfigs(layout,"display,alignV,width,height,borderS,borderL,borderT,borderR,borderB,borderColor,marginL,marginT,marginR,marginB")

let events=[	
	{key: '$comp_smsbefore',text: '短信发送前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '短信码发送前触发，返回false阻止发送',list: null	},
	{key: '$comp_smsafter',text: '发送成功',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '短信码发送成功后触发',list: null	},
	{key: '$comp_smserror',text: '发送失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '短信码发送失败后触发',list: null	},

] 
let smscode = {
	data: {
		type: 'smscode',isInput:'S',alignV:'center',width:'calc(100% - 10px)',marginT:'0px',marginB:'0px',marginL:'5px',
		marginR:'0px',borderS:'none',borderL:'1px',borderT:'1px',borderR:'1px',borderB:'1px'
	},
	delete: '',
	hide: 'mobile,width,height',
    base: base.concat(codeCfgs),
	special: [],
	action: events.concat(options.getEvents(null,'$hook'))
}

export default smscode
