<template>
<!--为被内层的layoutblock覆盖，外层容器事件起不了作用 @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave" -->
	<template v-if="model.type=='panel_wryd'">
		<div v-show="model.show !=='none'" :class="panelcss " :style="wrydStyle" 
			@mouseup="mouseUp">
			<div class="panel_wryd_patho">
				<div class="panel_wryd_pathi">
					<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="datas" /> 
				</div>
			</div>
		</div>

	</template>
	<template v-else-if="model.type=='panel_mlqq'">
		<div v-show="model.show !=='none'" :class="panelcss " :style="panelstyle" 
			@mouseup="mouseUp">
			<div class="panel_mlqq_rate  panel_mlqq_rate1"></div>
			<div class="panel_mlqq_rate  panel_mlqq_rate2"></div>
			<div class="panel_mlqq_rate  panel_mlqq_rate3"></div>
			<div class="panel_mlqq_rate  panel_mlqq_rate4"></div>

			<div class="panel_mlqq_content">
				<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
					:datas="datas" /> 
			</div>

		</div>

	</template>
	<template v-else-if="model.type=='panel_fkgsc'">
		<div v-show="model.show !=='none'" ref="fkgsc" :style="fkgsc_style" :class="panelcss ">
			<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" /> 
			<div class="panel_fkgsc_dot"></div>
		</div>
	</template>
	<template v-else-if="model.type=='panel_dzxy'">

		<div v-show="model.show !=='none'" ref="dzxy" :style="dzxy_style" :class="panelcss ">
			<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" /> 
			<div class="panel_dzxy_dot"></div>
		</div>

	</template>

	<template v-else>
		<div v-show="model.show !=='none'" :style="panelstyle" :class="panelcss " >
			<container :model="model.items[0]" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="datas" /> 
		</div>
	</template>



</template>

<script>
	//import layoutbase from '../../../layoutbase.js'
	import layoutbase from './sub/layoutBase.js'
	import CommEvent from '../../../commevent.js'
	export default {
		mixins: [layoutbase, CommEvent],
		data() {
			return {
				fkgscPoints: {},
				dzxySize: '0px'

			}
		},

		methods: {

			initFkgscPoints() {
				let model = this.model

				if (model.type == 'panel_fkgsc') {
					let ref = this.$refs.fkgsc
					if (!ref) {
						return
					}
					let h = ref.offsetHeight
					let w = ref.offsetWidth
					let r = model.radius || 20
					let b = parseInt(model.borderSize)
					model.items[0].borderRadius = (model.radius - b) + 'px'
					let points = {} //共8个点,会有个问题，无法根据长宽距离实际定义时间比例，只能大约设置
					points.x1 = '0px'
					points.y1 = '0px'
					points.r1 = '0deg'

					points.x2 = (w - r * 2 - b * 2) + 'px' //右上拐角开始
					points.y2 = '0px'
					points.r2 = '0deg'

					points.x3 = (w - r * 2 - b * 2) + 'px' //右上拐角完成
					points.y3 = 0 + 'px'
					points.r3 = '90deg'

					points.x4 = (w - r * 2 - b * 2) + 'px' //右下拐角开始
					points.y4 = (h - r * 2 - b * 2) + 'px'
					points.r4 = '90deg'

					points.x5 = (w - r * 2 - b * 2) + 'px' //右下拐角完成
					points.y5 = (h - r * 2 - b * 2) + 'px'
					points.r5 = '180deg'

					points.x6 = (0) + 'px' //左下拐角开始
					points.y6 = (h - r * 2 - b * 2) + 'px'
					points.r6 = '180deg'

					points.x7 = (0) + 'px' //左下拐角完成
					points.y7 = (h - r * 2 - b * 2) + 'px'
					points.r7 = '270deg'

					points.x8 = (0) + 'px' //左上拐角开始
					points.y8 = (0 + b * 2) + 'px'
					points.r8 = '270deg'

					points.x9 = (0) + 'px' //左上拐角完成
					points.y9 = (0) + 'px'
					points.r9 = '360deg'

					this.fkgscPoints = points

					//console.log(h, w, r)

				} else if (model.type == 'panel_dzxy') {
					model.items[0].borderRadius = '50%'
					let ref = this.$refs.dzxy
					if (!ref) {
						return
					}
					ref = ref.parentElement
					let h = ref.offsetHeight
					let w = ref.offsetWidth
					this.dzxySize = (w > h ? h : w) + 'px'
				} else if (model.type == 'panel_mlqq') {
					model.items[0].borderRadius = '50%'
				}
			}

		},
		computed: {
			panelstyle() {
				let model = this.model
				let css = this.layoutstyle


				switch (model.type) {
					case 'panel_lgyc':
						let speed = 20 - 0.2 * (model.flowSpeed ? model.flowSpeed : 50)
						if (speed < 0.1) {
							speed = 0.1
						}
						css['--background-color'] = model.backColor || '#ffffff'
						css['--animation'] = 'rotate ' + speed + 's linear infinite'
						css.width = 'calc(' + model.width + ' - ' + model.borderSize + ' * 2 )'
						css.height = 'calc(' + model.height + ' - ' + model.borderSize + ' * 2 )'

						break

				}
				//如果有转速的属性，添加旋转变量
				if (model.rotateTime) {
					css['--animation'] = 'rotate ' + model.rotateTime + 's linear infinite'
				}
				//公共阴影效果
				if (model.shadowColor) {
					css['--shadow-style'] = 'drop-shadow(0px 0px ' + model.shadowSize + 'px ' + model.shadowColor + ')'
				}
				if (model.twinkling == 'Y' && model.duration) {
					let color = this.$logic.color.hexToRgb(model.shadowColor)
					let rgb = 'rgba(' + color[0] + ',' + color[1] + ',' + color[2]
					css['--shadow-style1'] = 'drop-shadow(0px 0px ' + model.shadowSize + 'px ' + rgb + ',0.1)' +
						')'
					css['--shadow-style2'] = 'drop-shadow(0px 0px ' + model.shadowSize + 'px ' + rgb + ',1)' + ')'
					css['--shadow-duration'] = model.duration + 's'
				} else {
					css['--shadow-duration'] = '0'
				}

				return css
			},
			panelcss() {
				let model = this.model
				let css = this.layoutcss
				css.push(model.type)
				//公共阴影效果
				if (model.shadowColor) {
					css.push('filter-shadow')
				}
				if (model.twinkling == 'Y' && model.duration) {
					css.push('shadow-twinkling')
				}

				//css.push('xqdj')
				return css
			},

			wrydStyle() {
				let model = this.model
				let css = this.panelstyle
				let b = model.borderSize
				let w = model.cutWidth
				let h = model.cutHeight
				// polygon(0px 20%, 20% 0px, 80% 0px, 100% 20%, 100% 100%, 0px 100%)
				let pathO = ' polygon('
				let pathI = ' polygon('
				if (model.cutLT == 'Y') { //左上斜角
					pathO = pathO + '0px ' + h + ',' + w + ' 0px,'
					pathI = pathI + b + ' calc(' + h + ' + ' + b + '), calc(' + w + ' + ' + b + ') ' + b + ','
				} else { //左上直角
					pathO = pathO + ' 0px 0px,'
					pathI = pathI + ' calc(0px + ' + b + ')  calc(0px + ' + b + '),'
				}
				if (model.cutRT == 'Y') { //右上斜角
					pathO = pathO + 'calc(100% - ' + w + ') 0px' + ',100% ' + h + ','
					pathI = pathI + 'calc(100% - ' + w + ' - ' + b + ') ' + b + ',calc(100% - ' + b + ') calc(' + h +
						' + ' +
						b + '),'
				} else { //右上直角
					pathO = pathO + '100% 0px,'
					pathI = pathI + 'calc(100% - ' + b + ') ' + b + ' ,'
				}
				if (model.cutRB == 'Y') { //右下斜角
					pathO = pathO + '100% calc(100% - ' + h + '),calc(100% - ' + w + ') 100%,'
					pathI = pathI + 'calc(100% - ' + b + ') calc(100% - ' + h + ' - ' + b + '),calc(100% - ' + w + ' - ' +
						b + ') calc(100% - ' + b + '),'
				} else { //右下直角
					pathO = pathO + '100% 100%,'
					pathI = pathI + 'calc(100% - ' + b + ') calc(100% - ' + b + '),'
				}
				if (model.cutLB == 'Y') { //左下斜角
					pathO = pathO + w + ' 100% , 0px calc(100% - ' + h + ')'
					pathI = pathI + 'calc(' + w + ' + ' + b + ') calc(100% - ' + b + ') , ' + b + ' calc(100% - ' + h +
						' - ' + b + ')'
				} else { //左下直角
					pathO = pathO + '0px 100%'
					pathI = pathI + b + ' calc(100% - ' + b + ')'
				}
				pathO = pathO + ')'
				pathI = pathI + ')'

				css['--wryd-path-o'] = pathO
				//css['--wryd-path-i'] = pathI 用不到了，已经通过两层DIV的padding 实现了边框模拟
				css['--border-color'] = model.borderColor
				css['--border-size'] = model.borderSize
				css['--back-color'] = model.backColor



				//drop-shadow(0px 0px 10px #00ff00)
				return css
			},
			fkgsc_style() {
				let model = this.model
				let css = this.panelstyle
				css['--fkgsc-radius'] = model.radius + 'px'
				css['--fkgsc-rect-width'] = model.radius * 2 + 'px'
				css['--fkgsc-rect-height'] = model.radius * 2 + 'px'
				css['--round-time'] = model.roundTime + 's'
				this.initFkgscPoints()
				for (let key in this.fkgscPoints) {
					css['--fkgsc-' + key] = this.fkgscPoints[key]
				}

				return css
			},
			dzxy_style() {
				let css = this.fkgsc_style
				css['--dzxy-width'] = this.dzxySize

				return css
			}

		},
		mounted() { //此事件的执行顺序晚于计算属性样式，在些获取已确定的容器尺寸，改变尺寸值后样式值会触发更新
			this.initFkgscPoints()
		},
		created() {


		}
	}
</script>

<style scoped>


	.item7 {
		width:100%;
		height:100%;
		border: 4px solid #FFFF66;
		padding-top: 0px;
		-webkit-animation: twinkling2 1s infinite ease-in-out;
		/*1秒钟的开始结束都慢的无限次动画*/
	}

	@-webkit-keyframes twinkling2 {

		/*透明度由0到1*/
		0% {
			border-color: #FFFF66;
			box-shadow: 0 0 10px rgba(0, 255, 0, .2), inset 0 0 10px rgba(0, 255, 0, .1), 0 1px 0 #393;
		}

		100% {
			border-color: #6f6;
			box-shadow: 0 0 25px rgba(0, 255, 0, .6), inset 0 0 15px rgba(125, 231, 125, 0.4), 0 1px 0 #6f6;
		}
	}

	.item8 {
		position: relative;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		padding: 10px;
		background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgb(228, 161, 85) 3px, rgb(240, 118, 118) 8px);
		animation: shine 1s infinite linear;
		overflow: hidden;
	}
	.item8::after{
		content:'';
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		left:10px;
		top:10px; 
		 
		position: absolute; 
		
		background-color: red;
	}	 

	@-webkit-keyframes shine {
		0% {
			background-position: -1px -1px;
		}
		100% {
			background-position: -12px -12px;
		}
	}

	.panel_sjjq {
		width: calc(100% - var(--border-size) * 2 -  (var(--cape-line) - var(--border-size)) * 2 );
		height: calc(100% - var(--border-size) * 2 -  (var(--cape-line) - var(--border-size)) * 2 );
		padding: calc( var(--cape-line) - var(--border-size));
		background-color: var(--back-color); 
		border: var(--border-size) solid var(--border-color);
		position: relative;
		 
	}

	.panel_sjjq::before {
		content: '';
		width:calc( 100% +  var( --border-size ) * 2   );
		height: calc( 100% +  var( --border-size ) * 2  );
		position: absolute;
		left:calc( 0px -  var( --border-size ));
		top:calc(0px -  var( --border-size )   );
		z-index: -1;
		background-color: var(--cape-color);
		background: linear-gradient(to left, var(--cape-color), var(--cape-color)) left top no-repeat,
			linear-gradient(to bottom, var(--cape-color), var(--cape-color)) left top no-repeat,
			linear-gradient(to left, var(--cape-color), var(--cape-color)) right top no-repeat,
			linear-gradient(to bottom, var(--cape-color), var(--cape-color)) right top no-repeat,
			linear-gradient(to left, var(--cape-color), var(--cape-color)) left bottom no-repeat,
			linear-gradient(to bottom, var(--cape-color), var(--cape-color)) left bottom no-repeat,
			linear-gradient(to left, var(--cape-color), var(--cape-color)) right bottom no-repeat,
			linear-gradient(to left, var(--cape-color), var(--cape-color)) right bottom no-repeat;
		background-size:  var(--cape-line) var(--cape-size ), var(--cape-size)  var(--cape-line),  var(--cape-line) var(--cape-size), var(--cape-size)  var(--cape-line);
	
	}

	/* 	.panel_sjjq span {
		display: inline-block;
		width: 35%;
		background: #05286C;
		font-size: 14px;
		position: absolute;
		top: 0;
		left: 32%;
		padding: 4px 0px;
		color: #19E8FE;
		border-radius: 0 0 10px 10px;

	} */






	/*：这只是演示代码。源码下载链接: http://www.bootstrapmb.com/item/4558(这个说明仅在演示里会看到,下载的源码里没有)*/

	/* 	@-webkit-keyframes rotate {
		100% {
			-webkit-transform: rotate(1turn);
			transform: rotate(1turn);
		}
	}

	@keyframes rotate {
		100% {
			-webkit-transform: rotate(1turn);
			transform: rotate(1turn);
		}
	}
 */
	.panel_lgyc {

		position: relative;
		z-index: 0;

		border-radius: var(--border-radius);
		overflow: hidden;
		padding: var(--border-size);
	}

	.panel_lgyc::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(to right, #fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		-webkit-animation: rotate 4s linear infinite;
		animation: var(--animation);
		/* animation: rotate 4s linear infinite; */
	}

	.panel_lgyc::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: var(--border-size);
		top: var(--border-size);
		width: calc(100% - var(--border-size) * 2);
		height: calc(100% - var(--border-size) * 2);
		background: var(--background-color);
		border-radius: calc(var(--border-radius) / 2);
	}






	/*：       温柔一刀    */

	.panel_wryd {
		width: 100%;
		height: 100%;
		/* box-shadow: 10px 10px 50px #00ff00; */
		/* filter: drop-shadow(0px 0px 10px #00ff00); */



	}

	.panel_wryd_patho {
		width: calc(100% - var(--border-size) * 2);
		height: calc(100% - var(--border-size) * 2);
		background-color: var(--border-color);
		padding: var(--border-size);
		clip-path: var(--wryd-path-o);
	}

	.panel_wryd_pathi {
		width: 100%;
		height: 100%;
		background-color: var(--back-color);
		clip-path: var(--wryd-path-o);
	}


	/* 魔力圈圈 */
	.panel_mlqq {
		width: 100%;
		height: 100%;
		position: relative;


	}

	.panel_mlqq_rate {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
		border-radius: 50%;
		animation: var(--animation);
	}

	.panel_mlqq_rate1 {
		filter: blur(5px);
	}

	.panel_mlqq_rate2 {
		filter: blur(15px);
	}

	.panel_mlqq_rate3 {
		filter: blur(25px);
	}

	.panel_mlqq_rate4 {
		filter: blur(50px);
	}

	.panel_mlqq_content {

		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		/* background-color: #ffffff; */
	}

	/* 疯狂过山车                                                  */
	.panel_fkgsc {
		position: relative;
		width: calc(100% - var(--border-size) * 2);
		height: calc(100% - var(--border-size) * 2);
		border: solid var(--border-size) var(--border-color);
		background-color: var(--back-color);
		/* background: linear-gradient(360deg, transparent, #3a86ff); */
		border-radius: var(--fkgsc-radius);

	}

	.panel_fkgsc_dot {
		position: absolute;
		width: calc(var(--fkgsc-rect-width));
		height: calc(var(--fkgsc-rect-height));
		top: 0px;
		/* border: solid 1px #ffff00; */
		animation: round var(--round-time) infinite linear;

	}

	.panel_fkgsc_dot::after {
		content: '';
		position: absolute;
		left: calc(50% - var(--ball-size) - var(--border-size) / 2);
		top: calc(0px - var(--ball-size) - var(--border-size) / 2);
		height: calc(var(--ball-size) * 2 - var(--ball-line-size) * 2);
		width: calc(var(--ball-size) * 2 - var(--ball-line-size) * 2);
		background: var(--ball-back-color);
		border-radius: 50%;
		border: var(--ball-line-size) solid var(--ball-border-color);
		box-shadow: 0px 0px 2px var(--ball-border-color), 0px 0px var(--ball-shadow-size) var(--ball-shadow-color);

	}

	@keyframes round {
		0% {
			-webkit-transform: translateX(var(--fkgsc-x1)) translateY(var(--fkgsc-y1)) rotate(var(--fkgsc-r1));
			transform: translateX(var(--fkgsc-x1)) translateY(var(--fkgsc-y1)) rotate(var(--fkgsc-r1));
		}

		25% {
			-webkit-transform: translateX(var(--fkgsc-x2)) translateY(var(--fkgsc-y2)) rotate(var(--fkgsc-r2));
			transform: translateX(var(--fkgsc-x2)) translateY(var(--fkgsc-y2)) rotate(var(--fkgsc-r2));
		}

		30% {
			-webkit-transform: translateX(var(--fkgsc-x3)) translateY(var(--fkgsc-y3)) rotate(var(--fkgsc-r3));
			transform: translateX(var(--fkgsc-x3)) translateY(var(--fkgsc-y3)) rotate(var(--fkgsc-r3));
		}

		45% {
			-webkit-transform: translateX(var(--fkgsc-x4)) translateY(var(--fkgsc-y4)) rotate(var(--fkgsc-r4));
			transform: translateX(var(--fkgsc-x4)) translateY(var(--fkgsc-y4)) rotate(var(--fkgsc-r4));
		}

		50% {
			-webkit-transform: translateX(var(--fkgsc-x5)) translateY(var(--fkgsc-y5)) rotate(var(--fkgsc-r5));
			transform: translateX(var(--fkgsc-x5)) translateY(var(--fkgsc-y5)) rotate(var(--fkgsc-r5));
		}

		75% {
			-webkit-transform: translateX(var(--fkgsc-x6)) translateY(var(--fkgsc-y6)) rotate(var(--fkgsc-r6));
			transform: translateX(var(--fkgsc-x6)) translateY(var(--fkgsc-y6)) rotate(var(--fkgsc-r6));
		}

		80% {
			-webkit-transform: translateX(var(--fkgsc-x7)) translateY(var(--fkgsc-y7)) rotate(var(--fkgsc-r7));
			transform: translateX(var(--fkgsc-x7)) translateY(var(--fkgsc-y7)) rotate(var(--fkgsc-r7));
		}

		95% {
			-webkit-transform: translateX(var(--fkgsc-x8)) translateY(var(--fkgsc-y8)) rotate(var(--fkgsc-r8));
			transform: translateX(var(--fkgsc-x8)) translateY(var(--fkgsc-y8)) rotate(var(--fkgsc-r8));
		}

		100% {
			-webkit-transform: translateX(var(--fkgsc-x9)) translateY(var(--fkgsc-y9)) rotate(var(--fkgsc-r9));
			transform: translateX(var(--fkgsc-x9)) translateY(var(--fkgsc-y9)) rotate(var(--fkgsc-r9));
		}

	}

	@-webkit-keyframes round {
		0% {
			-webkit-transform: translateX(var(--fkgsc-x1)) translateY(var(--fkgsc-y1)) rotate(var(--fkgsc-r1));
			transform: translateX(var(--fkgsc-x1)) translateY(var(--fkgsc-y1)) rotate(var(--fkgsc-r1));
		}

		25% {
			-webkit-transform: translateX(var(--fkgsc-x2)) translateY(var(--fkgsc-y2)) rotate(var(--fkgsc-r2));
			transform: translateX(var(--fkgsc-x2)) translateY(var(--fkgsc-y2)) rotate(var(--fkgsc-r2));
		}

		30% {
			-webkit-transform: translateX(var(--fkgsc-x3)) translateY(var(--fkgsc-y3)) rotate(var(--fkgsc-r3));
			transform: translateX(var(--fkgsc-x3)) translateY(var(--fkgsc-y3)) rotate(var(--fkgsc-r3));
		}

		45% {
			-webkit-transform: translateX(var(--fkgsc-x4)) translateY(var(--fkgsc-y4)) rotate(var(--fkgsc-r4));
			transform: translateX(var(--fkgsc-x4)) translateY(var(--fkgsc-y4)) rotate(var(--fkgsc-r4));
		}

		50% {
			-webkit-transform: translateX(var(--fkgsc-x5)) translateY(var(--fkgsc-y5)) rotate(var(--fkgsc-r5));
			transform: translateX(var(--fkgsc-x5)) translateY(var(--fkgsc-y5)) rotate(var(--fkgsc-r5));
		}

		75% {
			-webkit-transform: translateX(var(--fkgsc-x6)) translateY(var(--fkgsc-y6)) rotate(var(--fkgsc-r6));
			transform: translateX(var(--fkgsc-x6)) translateY(var(--fkgsc-y6)) rotate(var(--fkgsc-r6));
		}

		80% {
			-webkit-transform: translateX(var(--fkgsc-x7)) translateY(var(--fkgsc-y7)) rotate(var(--fkgsc-r7));
			transform: translateX(var(--fkgsc-x7)) translateY(var(--fkgsc-y7)) rotate(var(--fkgsc-r7));
		}

		95% {
			-webkit-transform: translateX(var(--fkgsc-x8)) translateY(var(--fkgsc-y8)) rotate(var(--fkgsc-r8));
			transform: translateX(var(--fkgsc-x8)) translateY(var(--fkgsc-y8)) rotate(var(--fkgsc-r8));
		}

		100% {
			-webkit-transform: translateX(var(--fkgsc-x9)) translateY(var(--fkgsc-y9)) rotate(var(--fkgsc-r9));
			transform: translateX(var(--fkgsc-x9)) translateY(var(--fkgsc-y9)) rotate(var(--fkgsc-r9));
		}

	}


	/* 	斗转星移//////////////////////////////////////////////////////////////// */


	.panel_dzxy {
		position: relative;
		width: calc(var(--dzxy-width) - var(--border-size) * 2);
		height: calc(var(--dzxy-width) - var(--border-size) * 2);
		border: solid var(--border-size) var(--border-color);
		background-color: var(--back-color);
		/* background: linear-gradient(360deg, transparent, #3a86ff); */
		border-radius: 50%;

	}

	.panel_dzxy_dot {
		position: absolute;
		width: 1px;
		height: calc(100% + var(--ball-size) * 2);
		top: calc(0px - var(--ball-size));
		left: 50%;
		/* border: solid 1px #ffff00; */
		animation: round-circle var(--round-time) infinite linear;

	}

	.panel_dzxy_dot::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		height: calc(var(--ball-size) * 2 - var(--ball-line-size) * 2);
		width: calc(var(--ball-size) * 2 - var(--ball-line-size) * 2);
		background: var(--ball-back-color);
		border-radius: 50%;
		border: var(--ball-line-size) solid var(--ball-border-color);
		box-shadow: 0px 0px 2px var(--ball-border-color), 0px 0px var(--ball-shadow-size) var(--ball-shadow-color);

	}

	@keyframes round-circle {
		0% {
			-webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
			transform: translateX(0px) translateY(0px) rotate(0deg);
		}

		100% {
			-webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
			transform: translateX(0px) translateY(0px) rotate(360deg);
		}
	}





	/* 	公共效果//////////////////////////////////////////////////////////////// */

	.filter-shadow {
		filter: var(--shadow-style);
	}

	/* 需要闪烁效果需设置阴影颜色变量和周期变量 */
	.shadow-twinkling {
		-webkit-animation: twinkling var(--shadow-duration) linear infinite;
		animation: twinkling var(--shadow-duration) linear infinite;
	}


	/* 	闪光效果 */
	@keyframes twinkling {
		0% {
			filter: var(--shadow-style1);
		}

		50% {
			filter: var(--shadow-style2);
		}

		100% {
			filter: var(--shadow-style1);
		}
	}

	@-webkit-keyframes twinkling {
		0% {
			filter: var(--shadow-style1);
		}

		50% {
			filter: var(--shadow-style2);
		}

		100% {
			filter: var(--shadow-style1);
		}
	}
</style>
