import font from '../../../public/font.js'
import layout from '../../../public/layout.js'
import options from '../../../public/options.js'
import partConfig from './partConfig.js'
let base = [{
	key: 'title',
	text: '风铃词条'
}, ].concat(partConfig.base).concat(
	[
 {key:'vertical',text:'文字排列',type:'select',init:'vertical',target:'',bursh:true,bind:true,tip:'文字排列方向',list:options.orients},
 {key:'hoverStop',text:'悬停静止',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.orients},
 {key:'fontMin',text:'最小字体',type:'number',init:'16',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'fontMax',text:'最大字体',type:'number',init:'20',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'wordItalic',text:'斜体字体',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list:options.YON},
 {key:'fontLight',text:'字体亮度',type:'number',init:'50',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'wordOpacity',text:'透明度',type:'number',init:'0.99',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'speedMin',text:'最小转速',type:'number',init:'50',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'speedMax',text:'最大转速',type:'number',init:'55',target:'',bursh:true,bind:true,tip:'',list:null},
 {key:'wordBack',text:'背景颜色',type:'colorA',init:'rgba(0, 0, 32, 0.7)',target:'--border-color',bursh:true,bind:true,tip:'选择颜色或输入背景色，支持16进制、rgb、rgba颜色',list:null},
	


	])
let words =JSON.stringify( [
	["文本","颜色","字号"],
	["河北","#ff0000",30],
	["山西",null,null],
	["辽宁",null,null],
	["吉林",null,null],
	["黑龙江",null,null],
	["江苏",null,null],
	["浙江",null,null],
	["安徽",null,null],
	["福建",null,null],
	["江西",null,null],
	["山东",null,null],
	["河南",null,null],
	["湖北",null,null],
	["湖南",null,null],
	["广东",null,null],
	["海南",null,null],
	["四川",null,null],
	["贵州",null,null],
	["云南",null,null],
	["陕西",null,null],
	["甘肃",null,null],
	["青海",null,null],
	["台湾",null,null],
	["内蒙古",null,null],
	["广西",null,null],
	["西藏",null,null],
	["宁夏",null,null],
	["新疆",null,null],
	["北京",null,null],
	["天津",null,null],
	["上海",null,null],
	["重庆",null,null]
]).replaceAll(']',']\r\n')

let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
	let action= [
		{key: '$comp_wordClick',text: '词条点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '鼠标点击词条时触发,可获取当前点击词条的数据项',list: null	},
		
	]
let part = {
	data: {
		type: 'part_flct',
		chartClan: 'part',
	},
	delete: '',
	hide: 'width,height,dataOption',
	base: base,
	special: [
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key:'initData',text:'初始数据',type:'jsonArray',init:words,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	 
	],
	action: partConfig.timerEvents.concat(action).concat(partConfig.commEvents)
}
export default part