import options from '../../../public/options.js'


//色系

let base=[
	{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
//	{key:'minW',text:'最小宽度',type:'text',init:'20px',target:'minWidth',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},
	{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
//	{key:'minH',text:'最小高度',type:'text',init:'20px',target:'',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
].concat([

	
	
])
	let action= [
		{key: '$comp_click',text: '图表上点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
		{key: '$comp_dblclick',text: '图表上双击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
		{key: '$comp_mouseover',text: '图表上悬停',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
		{key: '$comp_mouseleave',text: '从图表离开',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
		
	]
let timerEvents=options.getEvents(null,'$timer')
//let commEvents=options.getEvents(null,'$event_click,$event_dblclick,$event_mouseover,$event_mouseleave,$hook',)
let commEvents=action.concat( options.getEvents(null,'$hook'))

/* let chartEvents=timerEvents.concat(
[
	
 ]).concat(commEvents) 
let partEvents=timerEvents.concat(
[
	
 ]).concat(commEvents) */
 
 
 
 
 
let rs={base,timerEvents,commEvents}

export default rs