 
import options from  '../../../../public/options.js'
import echartConfig from '../echartConfig.js'
let base=[].concat( echartConfig.base)

 //[//	{key:'title',text:'柱形图'},].concat()  //.concat([ ])
let grid=[
	{key:'seriesBarColorBy',text:'取色方式',type:'select',init:'series',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	
	{key:'title',text:'直角坐标系'},
	 {key:'gridShow',text:'显示坐标系',type:'switch',init:'Y',path:'grid.show',bursh:true,bind:true,tip:'',list:options.YON},
	// {key:'gridAlign',text:'水平对齐',type:'select',init:'center',path:'grid.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	// {key:'gridVerticalAlign',text:'垂直对齐',type:'select',init:'',path:'grid.top',bursh:true,bind:true,tip:'',list:options.toOptions('默认=,顶部对齐=top,中间对齐=middle,底部对齐=middle')},
	 {key:'gridLeft',text:'左边距',type:'number',init:'5',path:'grid.left',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:null},
	 {key:'gridRight',text:'右边距',type:'number',init:'5',path:'grid.right',bursh:true,bind:true,tip:'绝对定位方式右侧定位',list:null},
	 {key:'gridTop',text:'顶边距',type:'number',init:'40',path:'grid.top',bursh:true,bind:true,tip:'绝对定位方式顶定位',list:null},
	 {key:'gridBottom',text:'底边距',type:'number',init:'5',path:'grid.bottom',bursh:true,bind:true,tip:'绝对定位方式底部定位',list:null},
	 {key:'gridContainLabel',text:'刻度标签',type:'switch',init:'Y',path:'grid.containLabel',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'gridBackgroundColor',text:'背景颜色',type:'colorA',init:null,path:'grid.backgroundColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'gridBorderColor',text:'边框颜色',type:'colorA',init:null,path:'grid.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'gridBorderWidth',text:'边框粗细',type:'number',init:1,path:'grid.borderWidth',bursh:true,bind:true,tip:'',list:null},
	
]
/* for(let i=0;i<grid.length;i++){ //坐标系插入基本属性中
	base.splice(i+4,0,grid[i])
} */
options.insertBaseConfig(base,grid)
let action={timer: echartConfig.timerEvents,comm:echartConfig.commEvents}
 let initData=JSON.stringify( [
 	['日期', 'A类', 'B类', 'C类'],
 	['周一', 50, 150, 70],
 	['周二', 150, 80, 130],
 	['周三', 90, 130, 150],
 	['周四', 110, 100, 60],
 	['周五', 70, 140, 120]
 ]).replaceAll('],','],\r\n')

let inputOption=options.getInputOption() 
inputOption.jsonArray.dataType='array'
 



 let special=[
	 
	 {key:'title',text:'X轴'},
	 {key:'xAxisShow',text:'显示X轴',type:'switch',init:'Y',path:'xAxis.show',bursh:true,bind:true,tip:'',list:options.YON},

     {key:'xAxisPosition',text:'X轴位置',type:'select',init:'bottom',path:'xAxis.position',bursh:true,bind:true,tip:'',list:options.toOptions('顶部=value,底部=bottom')},
	 {key:'xAxisType',text:'X轴类型',type:'select',init:'category',path:'xAxis.type',bursh:true,bind:true,tip:'',list:options.toOptions('数值型=value,类目型=category,时间型=time')},
	 {key:'xAxisName',text:'X轴名称',type:'text',init:'X轴',path:'xAxis.name',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisNameFontSize',text:'名称字号',type:'number',init:'12',path:'xAxis.nameTextStyle.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisNameFontFamily',text:'名称字体',type:'select',init:'',path:'xAxis.nameTextStyle.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'xAxisAxisNameColor',text:'名称颜色',type:'color',init:options.initColor.text.dark,path:'xAxis.nameTextStyle.color',bursh:true,bind:true,tip:'',list:null},

	 {key:'xAxisNameLocation',text:'名称位置',type:'select',init:'end',path:'xAxis.nameLocation',bursh:true,bind:true,tip:'',list:options.toOptions('起始=start,中间=middle,末尾=end')},
	 {key:'xAxisNameGap',text:'距离偏移',type:'number',init:15,path:'xAxis.nameGap',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisNameRotate',text:'名称旋转',type:'number',init:null,path:'xAxis.nameRotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisLineShow',text:'显示轴线',type:'switch',init:'Y',path:'xAxis.axisLine.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'xAxisAxisLineLineStyleColor',text:'轴线颜色',type:'color',init:options.initColor.text.dark,path:'xAxis.axisLine.lineStyle.color',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'xAxisAxisLineLineStyleWidth',text:'轴线粗细',type:'number',init:1,path:'xAxis.axisLine.lineStyle.width',bursh:true,bind:true,tip:'',list:options.YON},

	 {key:'xAxisAxisTickShow',text:'显示刻度',type:'switch',init:'Y',path:'xAxis.axisTick.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'xAxisAxisTickInside',text:'刻度内置',type:'switch',init:'Y',path:'xAxis.axisTick.inside',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'xAxisAxisTickLength',text:'刻度线长度',type:'number',init:5,path:'xAxis.axisTick.length',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisTickLineStyleColor',text:'刻度线颜色',type:'color',init:options.initColor.line.dark,path:'xAxis.axisTick.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisTickLineStyleWidth',text:'刻度线粗细',type:'number',init:1,path:'xAxis.axisTick.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	 
	 {key:'xAxisAxisLabelShow',text:'显示标签',type:'switch',init:'Y',path:'xAxis.axisLabel.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'xAxisAxisLabelFontSize',text:'标签字号',type:'number',init:'12',path:'xAxis.axisLabel.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisAxisLabelFontFamily',text:'标签字体',type:'select',init:'',path:'xAxis.axisLabel.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'xAxisAxisLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'xAxis.axisLabel.color',bursh:true,bind:true,tip:'',list:null},

	 {key:'xAxisAxisLabelInside',text:'标签内置',type:'switch',init:'N',path:'xAxis.axisLabel.inside',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'xAxisAxisLabelRotate',text:'标签角度',type:'number',init:null,path:'xAxis.axisLabel.rotate',bursh:true,bind:true,tip:'',list:null},

	 {key:'xAxisSplitLineShow',text:'显示分隔线',type:'switch',init:'Y',path:'xAxis.splitLine.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'xAxisSplitLineStyleColor',text:'分隔线颜色',type:'color',init:options.initColor.line.dark,path:'xAxis.splitLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisSplitLineStyleWidth',text:'分隔线粗细',type:'number',init:1,path:'xAxis.splitLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	 {key:'xAxisSplitLineType',text:'分隔线类型',type:'select',init:'dashed',path:'xAxis.splitLine.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},


	 {key:'title',text:'Y轴'},
	 {key:'yAxisShow',text:'显示Y轴',type:'switch',init:'Y',path:'yAxis.show',bursh:true,bind:true,tip:'',list:options.YON},
     {key:'yAxisPosition',text:'Y轴位置',type:'select',init:'left',path:'yAxis.position',bursh:true,bind:true,tip:'',list:options.toOptions('左侧=left,右侧=right')},
	 {key:'yAxisType',text:'Y轴类型',type:'select',init:'value',path:'yAxis.type',bursh:true,bind:true,tip:'',list:options.toOptions('数值型=value,类目型=category,时间型=time')},
	 {key:'yAxisName',text:'Y轴名称',type:'text',init:'Y轴',path:'yAxis.name',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisNameFontSize',text:'名称字号',type:'number',init:'12',path:'yAxis.nameTextStyle.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisNameFontFamily',text:'名称字体',type:'select',init:'',path:'yAxis.nameTextStyle.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'yAxisAxisNameColor',text:'名称颜色',type:'color',init:options.initColor.text.dark,path:'yAxis.nameTextStyle.color',bursh:true,bind:true,tip:'',list:null},

	 {key:'yAxisNameLocation',text:'名称位置',type:'select',init:'end',path:'yAxis.nameLocation',bursh:true,bind:true,tip:'',list:options.toOptions('起始=start,中间=middle,末尾=end')},
	 {key:'yAxisNameGap',text:'名称偏移',type:'number',init:15,path:'yAxis.nameGap',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisNameRotate',text:'名称旋转',type:'number',init:null,path:'yAxis.nameRotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisLineShow',text:'显示轴线',type:'switch',init:'Y',path:'yAxis.axisLine.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'yAxisAxisLineLineStyleColor',text:'轴线颜色',type:'color',init:options.initColor.line.dark,path:'yAxis.axisLine.lineStyle.color',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'yAxisAxisLineLineStyleWidth',text:'轴线粗细',type:'number',init:1,path:'yAxis.axisLine.lineStyle.width',bursh:true,bind:true,tip:'',list:options.YON},

	 {key:'yAxisAxisTickShow',text:'显示刻度',type:'switch',init:'Y',path:'yAxis.axisTick.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'yAxisAxisTickInside',text:'刻度内置',type:'switch',init:'Y',path:'yAxis.axisTick.inside',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'yAxisAxisTickLength',text:'刻度线长度',type:'number',init:5,path:'yAxis.axisTick.length',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisTickLineStyleColor',text:'刻度线颜色',type:'color',init:options.initColor.line.dark,path:'yAxis.axisTick.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisTickLineStyleWidth',text:'刻度线粗细',type:'number',init:1,path:'yAxis.axisTick.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	 
	 {key:'yAxisAxisLabelShow',text:'显示标签',type:'switch',init:'Y',path:'yAxis.axisLabel.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'yAxisAxisLabelFontSize',text:'标签字号',type:'number',init:'12',path:'yAxis.axisLabel.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisAxisLabelFontFamily',text:'标签字体',type:'select',init:'',path:'yAxis.axisLabel.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'yAxisAxisLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'yAxis.axisLabel.color',bursh:true,bind:true,tip:'',list:null},

	 {key:'yAxisAxisLabelInside',text:'标签内置',type:'switch',init:'N',path:'yAxis.axisLabel.inside',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'yAxisAxisLabelRotate',text:'标签角度',type:'number',init:null,path:'yAxis.axisLabel.rotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisSplitLineShow',text:'显示分隔线',type:'switch',init:'Y',path:'yAxis.splitLine.show',bursh:true,bind:true,tip:'',list:options.YON},
 	 {key:'yAxisSplitLineStyleColor',text:'分隔线颜色',type:'color',init:options.initColor.line.dark,path:'yAxis.splitLine.lineStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisSplitLineStyleWidth',text:'分隔线粗细',type:'number',init:1,path:'yAxis.splitLine.lineStyle.width',bursh:true,bind:true,tip:'',list:null},
	 {key:'yAxisSplitLineType',text:'分隔线类型',type:'select',init:'dashed',path:'yAxis.splitLine.lineStyle.type',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	 
	 {key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	  {key:'initData',text:'初始数据',type:'jsonArray',init:initData,bursh:true,bind:true,tip:'',attribute:'dataOption',list:null},
	// {key:'initData',text:'初始数据',type:'json',init:initData,bursh:true,bind:true,tip:'',list:null},
 
	 
	 
 ]
 
export default {base,special,action,initData}



 