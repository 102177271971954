import compBase from '../../public/comp.js'
import options from '../../public/options.js'
let base=[
	{key:'title',text:'二维码'},
	{key:'value',text:'内容',type:'area',init:'http://yourdomain.com/id=${1}',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'size',text:'长宽尺寸',type:'number',init:'100',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'correctLevel',text:'容错等级',type:'select',init:'H',target:'',bursh:true,bind:true,tip:'',list:[{label:'低，7%容错',value:'L'},{label:'中，15%容错',value:'M'},{label:'中高，25%容错',value:'Q'},{label:'高，30%容错',value:'H'}]},

	{key:'colorF',text:'二维码颜色',type:'color',init:'#000000',target:'',bursh:true,bind:true,tip:'',list:null},	 
	{key:'colorB',text:'背景颜色',type:'color',init:'#FFFFFF',target:'',bursh:true,bind:true,tip:'',list:null},	
	{key:'borderB',text:'边框颜色',type:'color',init:'#959595',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'borderS',text:'边框线宽',type:'number',init:'2',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'borderT',text:'边框线型',type:'select',init:'solid',target:'',bursh:true,bind:true,tip:'',list:options.BorderType},

    {key:'title',text:'居中图标'},
	{key:'logoV',text:'显示图标',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},
	{key:'logoF',text:'图标文件',type:'file',init:'',target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'logoS',text:'图标尺寸',type:'number',init:'40',target:'',bursh:true,bind:true,tip:'设置为0不显示图标',list:null},
    {key:'logoR',text:'边框圆角',type:'text',init:'50%',target:'',bursh:true,bind:true,tip:'可设置为象素',list:null},
	{key:'logoC',text:'边框颜色',type:'color',init:'#FFD700',target:'',bursh:true,bind:true,tip:'',list:null}, 
	{key:'logoL',text:'边框线宽',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list:null},

].concat(compBase.base)

let qr= {
	data:{
		type:'qr',
	},
	delete:'',
	hide:'',
	base:base ,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default qr