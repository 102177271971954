<template>

	<router-view />
	<coverlayer />
</template>

<script>
	import CoverLayer from './components/comm/coverlayer/CoverLayer.vue'

	export default {
		data() {
			return {
				timer: null
			}
		},
		methods: {
			reSize() {
				clearTimeout(this.timer) //防止拖动窗口时频繁连续触发
				this.timer = setTimeout(() => {
					this.$store.commit('updateResizeTick')
				}, 200)


			}
		},
		components: {
			coverlayer: CoverLayer
		},
		created() {
			window.onresize = this.reSize
			//优先清除body样式,//由于加载中样式渲染阻塞，采用body作背景，加载完后清除body的背景样式
			document.body.style.background = ''
			document.getElementById('systemloading').remove()//.style.display='none'
		}

	}
</script>