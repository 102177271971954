<template>

	<div v-if="model.editorMode=='Y'" :id="'editor_'+model.id" :style="editStyle">
		<template v-if="model.type=='ckdocument'">
			<div
				style="width:calc(100% - 60px);height:calc(100% - var(--document-top-height) - 62px);padding: 30px;background-color:#f2f2f2;margin-top: 2px;overflow: auto;">

				<ckeditor :editor="editorType" v-model="editorData" :config="editorConfig"
					:disabled="model.editAble=='N'" @ready="onReady">
				</ckeditor>
			</div>
		</template>
		<template v-else>
			<ckeditor :editor="editorType" v-model="editorData" :config="editorConfig" :disabled="model.editAble=='N'"
				@ready="onReady"></ckeditor>
		</template>
	</div>
	<div v-else :id="'editor_'+model.id" :style="editStyle" class="ck-content ck-content-view" v-html="editorData">

	</div>
	<!-- <ckeditor :editor="editorView" v-model="editorData" :config="editorConfig" :disabled="true" @ready="true">
	</ckeditor> -->
</template>

<script>
	import hightLight from 'highlight.js/lib/common';
	import MyUploadAdapter from './MyUploadAdapter.js'
	import * as CkEditor from '../../../../../plugins/ckeditor5/ckeditor.js'
	import '../../../../../plugins/ckeditor5/translations/zh-cn.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				barHeight: 0,
				editorView: CkEditor.InlineEditor,
				editorType: CkEditor.ClassicEditor,
				editorData: '<p>请编辑内容.</p>',
				/* editorConfig: {
					language: 'zh-cn'
				}, */
				classicToolbar: {
					items: ['sourceEditing'],
				}

			}
		},

		methods: {
			getToolBarItems() {
				let items = [
					'undo',
					'redo',
					'|',
					'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
					'heading',
					'|',
					'link',
					'insertImage',
					'mediaEmbed',
					'insertTable',
					'specialCharacters',
					'codeBlock',
					'blockQuote',
					'|',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'subscript', 'superscript',
					'|',
					'bulletedList',
					'numberedList',
					'outdent',
					'indent',
				]
				if (this.model.type == 'ckclassic') {
					items.push('|')
					items.push('sourceEditing')
				}
				let model = this.model
				let rs = []
				let isSplit = false //判断前面是分隔符后面就不连续出现分隔符
				for (let item of items) {
					if (item == '|') {
						if (!isSplit) {
							rs.push(item)
							isSplit = true
						}
					} else {
						if (model['_' + item] == 'Y') {
							rs.push(item)
							isSplit = false
						}
					}
				}
				return rs
			},
			onReady(editor) {
				if (this.model.type == 'ckdocument') { //文档类型需手工添加工具栏
					//editor.ui.getEditableElement().parentElement.insertBefore(
					editor.ui.getEditableElement().parentElement.parentElement.insertBefore(
						editor.ui.view.toolbar.element,
						editor.ui.getEditableElement().parentElement
					)
					//container.insertBefore(editor.ui.view.toolbar.element,)
				}
				// 自定义上传图片插件

				editor.plugins.get("FileRepository").createUploadAdapter = loader => {
					return new MyUploadAdapter(loader, this.$logic, this.project.id);
				}


				setTimeout(() => { //延迟执行，避免页面还没渲染
					//let obj = document.querySelector('#editor_' + this.model.id).getElementsByClassName('ck-editor__top')
					let obj = document.querySelector('#editor_' + this.model.id + ' .ck-toolbar')
					if (obj) {
						this.barHeight = obj.offsetHeight
					}

				}, 100)

			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return this.editorData
				} else {
					this.editorData = value
					this.model.value = value
				}
			}

		},
		computed: {
			editorConfig() {
				let config = {
					language: 'zh-cn',
					fontFamily: {
						options: [
							'微软雅黑, Microsoft YaHei',
							'黑体, SimHei',
							'楷体,KaiTi_GB2312',
							'宋体,SimSun, Arial',
							'仿宋,FangSong,NSimSun,FangSong_GB2312',
							'细体,PMingLiU, MingLiU, DFKai-SB'
						],

					},
					fontSize: {
						options: [
							9,
							11,
							13,
							'default',
							16,
							19,
							21,
							36,
							48

						]
					},
				}
				let items = this.getToolBarItems()
				config.toolbar = {
					shouldNotGroupWhenFull: false,
					items: items,

				}
				return config
			},
			editStyle() {
				/* let model = this.model
				let css = {
					width: model.width,
					height: model.height,
					overflow: 'auto'
				} */
				let css = {
					...this.baseStyle
				}
				if (this.isedit) { //编辑模式下外层还有一个包装层，只需填满就行
					css.height = '100%'
				}

				//css.overflow = 'auto'
				css.overflow = 'visible';
				//css['overflow-y']= 'auto';
				css['--ckedit-top-height'] = (this.barHeight) + 'px'
				css['--ck-content-minheight'] = 'calc(100% - 2px)'
				css['--ck-content-height'] = this.model.type == 'ckdocument' ? 'auto' : 'calc(100% - 2px)'

				css['--document-top-height'] = this.model.type == 'ckdocument' ? (this.barHeight) + 'px' : '0px'

				if (this.model.editorMode != 'Y') { //内容呈现模式自由高度
					css.height = 'auto'
					css['--ckedit-top-height'] = 'auto'
					css['--ck-content-minheight'] = 'auto'
					css['--ck-content-height'] = 'auto'
					css['--document-top-height'] = 'auto'

				}


				return css
			}
		},
		watch: {
			editorData(newValue, oldValue) {
				this.model.value = newValue
			},



			'model.value': {
				handler(newValue, oldValue) {
					this.editorData = newValue

				}
			}

		},
		mounted() {
			if (this.model.editorMode == 'N') { //只读情况下作高亮处理
				// setTimeout(() => { //延迟执行，避免页面还没渲染



				// }, 100)
				let objs = document.querySelectorAll('#editor_' + this.model.id + ' pre code')

				for (let obj of objs) {
					hightLight.highlightElement(obj)
				}
			}

		},

		created() {
			//console.log(CkEditor)
			let model = this.model
			//this.editorData = this.editorData + model.type 
			let initContent = '<p>请编辑内容.</p>'
			switch (model.type) {
				case 'ckclassic':
					this.editorType = CkEditor.ClassicEditor
					break

				case 'ckinline':
					initContent = '<p>编辑内容区自动出现工具栏，离开编辑自动隐藏工具栏</p>',
						this.editorType = CkEditor.InlineEditor
					break
				case 'ckballoon':
					initContent = '<p>选定内容时自动出现工具栏，无选区时自动隐藏工具栏</p>',
						this.editorType = CkEditor.BalloonEditor
					break
				case 'ckblock': //暂未实现，官方未提供此类引用
					this.editorType = CkEditor.BalloonEditor
					break
				case 'ckdocument':
					this.editorType = CkEditor.DocumentEditor
					break
			}
			if (this.isedit && this.model.value == null) {
				this.model.value = initContent
			}
			if (!this.isedit) {
				this.model.$value = this.$value
				this.model.$validate = this.validate
			}
			this.editorData = this.model.value
		}
	}
</script>

<style scoped>
	:deep(.ck-editor) {
		height: 100%;
	}

	:deep(.ck-editor__main) {
		height: calc(100% - var(--ckedit-top-height));
	}

	:deep(.ck-content) {
		height: var(--ck-content-height);
		min-height: var(--ck-content-minheight);
		background-color: #ffffff;
		/* 为兼容文档型编辑器 */
	}


	:deep(.ck-source-editing-area) {
		height: var(--ck-content-height);

	}

	:deep(.ck-source-editing-area textarea) {
		overflow: scroll;
	}




	/* 	:deep(.ck-editor__main .ck-content) { 
		height: 100%;
	} */
</style>