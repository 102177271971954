<template>

	<el-input v-model="model.userName" :style="compStyle" :size="model.size" readonly :disabled="model.disabled=='Y'"
		placeholder="" />

</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {

			}
		},

		methods: {
			initUser() {
				let user = this.$logic.clone.deepClone(this.$logic.getUser())
				delete user.accountName
				delete user.passWord

				let model = this.model
				model.userId = user.id
				model.userName = user.userName
				/* this.linkUpdate('userId')
				this.linkUpdate('userName') */
				this.linkUpdate()
				this.doEvent({
					eventName: 'userinfo',
					user: this.user
				}, '$comp_userInfo')
			}


		},
		watch: {

		},

		computed: {

		},
		created() {
			if (!this.isedit) {
				this.model.$init = this.initUser
				if (this.model.auto == 'Y') {
					this.initUser()
				}

			}

		}
	}
</script>

<style scoped>
	.select-container {
		position: relative;
	}

	.select-text {
		position: absolute;
		left: 0px;
		top: 2px;
		width: calc(100% - 32px);
	}
</style>