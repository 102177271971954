//import font from '../../../public/font.js'
//import layout from '../../../public/layout.js'
import chart from '../base/chartConfig.js'
import options from '../../../public/options.js'
import textStyle from './subconfig/textStyle.js'
import title from './subconfig/title.js'
import legend from './subconfig/legend.js'
import toolTip from './subconfig/toolTip.js'
import toolBox from './subconfig/toolBox.js'
import util from './util.js'
let specials = [
	//	
]
let colors = ['#37a2da', '#32c5e9', '#67e0e3', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e062ae', '#e690d1',
	'#e7bcf3', '#9d96f5', '#8378ea', '#96bfff',
	'#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'
]





let base = chart.base.concat([
	{key:'darkMode',text:'暗黑模式',type:'switch',init:'Y',path:'darkMode',bursh:true,bind:true,tip:'',list:options.YON},
    {key:'color',text:'调色板',type:'colorB',init:colors,path:'color',bursh:true,bind:true,tip:'',list:null},
 //   {key:'backgroundColor',text:'背景颜色',type:'colorA',init:null,path:'backgroundColor',bursh:true,bind:true,tip:'',list:null},
 //   {key:'animation',text:'开启动画',type:'switch',init:'Y',path:'animation',bursh:true,bind:true,tip:'',list:options.YON},
 //   {key:'animationThreshold',text:'动画阀值',type:'number',init:2000,path:'animationThreshold',bursh:true,bind:true,tip:'为考虑性能，当系统图形数量超过些阀值即关闭动画',list:null},
   // {key:'title',text:'全局字体样式'},
	
	])//.concat(util.getCloneConfig('','',textStyle.list))


	.concat(title.list)//标题配置省略，在容器中设置
	.concat(legend.list)
	.concat(util.getCloneConfig('','',toolTip.list)).concat(util.getCloneConfig('','',toolBox.list))

let rs = {
	base: base,
	timerEvents: chart.timerEvents,
	commEvents: chart.commEvents
}

export default rs
