import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
let datas =JSON.stringify( [
		{key:'home',label:'首页',icon:'far fa-home',color:null,url:'',target:'',isFresh:'N',items:null},
		
	 	{key:'sale',label:'营销管理',icon:'far fa-globe',color:null,url:'',target:'',isFresh:'N',items:[
			{key:'group1',label:'销售管理',icon:'far fa-user-tie',color:null,url:'',target:'',group:'N',isFresh:'N',items:[
				{key:'chance',label:'商机管理',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
				{key:'contract',label:'合同管理',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
				{key:'service',label:'客服管理',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
			]},
			{key:'group2',label:'货款管理',icon:'far fa-hand-holding-usd',color:null,url:'',target:'',group:'N',isFresh:'N',items:[
				{key:'checkin',label:'到款登记',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
				{key:'query',label:'货款查询',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
				{key:'total',label:'货款分析',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
			]},
			{key:'policy',label:'销售政策',icon:'far fa-tag',color:null,url:'',target:'',isFresh:'N',items:null}
		]},
		{key:'product',label:'生产管理',icon:'far fa-user-robot',color:null,url:'',target:'',isFresh:'N',items:[
			{key:'poplan',label:'生产计划',icon:'far fa-calendar-check',color:null,url:'',target:'',disabled:true,isFresh:'N',items:null},
			{key:'po',label:'生产下单',icon:'far fa-user-edit',color:null,url:'',target:'',isFresh:'N',items:null}
		]},
		{key:'purchase',label:'采购管理',icon:'far fa-sack-dollar',color:null,url:'',target:'',isFresh:'N',items:[
			{key:'prplan',label:'采购计划',icon:'far fa-calendar-edit',color:null,url:'',target:'',disabled:true,isFresh:'N',items:null},
			{key:'pr',label:'采购下单',icon:'far fa-file-edit',color:null,url:'',target:'',isFresh:'N',items:null}
		]},
		
		{key:'report',label:'运营分析',icon:'far fa-chart-line',color:null,url:'',target:'',isFresh:'N',items:[
			{key:'gtotal',label:'集团运营报告',icon:'far fa-analytics',color:null,url:'',target:'',disabled:false,isFresh:'N',items:null},
			{key:'ptotal',label:'产能分析',icon:'far fa-luggage-cart',color:null,url:'',target:'',isFresh:'N',items:null},
			{key:'mtotal',label:'利润分析',icon:'far fa-chart-scatter',color:null,url:'',target:'',isFresh:'N',items:null}
		]}, 
		{key:'set',label:'系统管理',icon:'far fa-cog',color:null,url:'',target:'',isFresh:'N',items:[
			{key:'org',label:'组织管理',icon:'',color:null,url:'',target:'',disabled:true,isFresh:'N',items:null},
		    {key:'role',label:'角色管理',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
		    {key:'popedom',label:'权限管理',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},
		    {key:'sys',label:'系统设置',icon:'',color:null,url:'',target:'',isFresh:'N',items:null},

		]},
])
let base = [
	{key: 'title',text: '菜单'	},
//{key: 'jsonData',text: '菜单项',type: 'json',init: datas,	target: '',	extend: false,tip: '',hide:true,treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',label:'标题文本',target:'链接目标',url:'链接地址',icon:'图标',color:'颜色',isFresh:'总是刷新',access:'权限'}},
{key: 'jsonData',text: '菜单项',type: 'jsontree',init: datas,	target: '',	extend: false,tip: '',hide:true,treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:{label:'唯一标识',type:'text'},label:{label:'标题文本',type:'text'},target:{label:'链接目标',type:'text'},url:{label:'链接地址',type:'text'},icon:{label:'图标',type:'icon'},color:{label:'文本颜色',type:'color'},isFresh:{label:'总是刷新',type:'switch'},access:{label:'权限',type:'access'}}},

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'height',text:'高度',type:'text',init:'100%',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

{key: 'mode',text: '菜单方向',type: 'select',init: 'vertical',	target: '',bursh:true,bind:true,tip: '',list:  options.Directions},	

{key: 'bindPath',text: '联动面包屑',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '输入面包屑组件的ID',list: null},	
{key: 'bindTab',text: '联动标签页',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '输入标签面容器的ID，默认标签页的key值应与菜单项的key值一致',list: null},	
{key: 'bindPage',text: '联动子页面',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '输入子页面容器的ID',list: null},	

{key: 'showIcon',text: '显示图标',type: 'select',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'active',text: '默认选项',type: 'text',init: '',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'collapse',text: '是否折叠',type: 'select',init: 'N',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	
{key: 'backColor',text: '背景颜色',type: 'colorA',init: '#0b0927',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'textColor',text: '文本颜色',type: 'color',init: '#f4f4f5',	target: '',bursh:true,bind:true,tip: '',list: null},	
{key: 'activeColor',text: '选中文本色',type: 'color',init: '#ff9538',	target: '',bursh:true,bind:true,tip: '',list: null},	
//{key: 'borderColor',text: '侧边框颜色',type: 'color',init: '#e6e6e6',	target: '',bursh:true,bind:true,tip: '',list: null},	


]
 
 let events=[
	 {key: '$comp_click',text: '菜单点击',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单项被点击时触发',list: null	},
	 {key: '$comp_expand',text: '菜单展开',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单展开时触发',list: null	},
	 {key: '$comp_collapse',text: '菜单折叠',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单折叠时触发',list: null	},
 ]
  
let menu = {
	data: {
		type: 'menu',
	},
	delete: '',
	hide: 'width,height',
	base: base,//.concat(compBase.base),
	//属性名以is开头的，以switch组件呈现，以color为属性名的，以颜色选择框方式呈现
	special: [	],
	action: events
}

export default menu
