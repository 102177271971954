<template>
	<span @click="toSetActor(null)">
		<slot></slot>
	</span>
	<logicdialog ref="actorSet" title="参与人设置" @ok="actorSetOk" dialog-height="600px" dialog-width="900px"
		icon="far fa-edit">
		<div class="content">
			<table style="width:100%;border:solid 1px #cccccc;">
				<tr class="actor-type-row">
					<td class="row-label">组名称 </td>
					<td colspan="2"><el-input v-model="actorGroup.groupName" placeholder="输入参与人组名称" clearable /></td>
				</tr>
				<tr v-for="item in actorTypes" class="actor-type-row">
					<td class="row-label">{{item.title}}</td>
					<td>
						<div v-if="item.type==='J'">
							<div>
								<roledialog :role-node="item.roleNode" placeholder="请选择职能角色" />
							</div>

							<div class="type-row">
								<div class="type-from">
									<el-radio-group v-model="item.fromType">
										<el-radio label="A">变量</el-radio>
										<el-radio label="S">定值</el-radio>
									</el-radio-group>

								</div>
								<div v-show="item.fromType=='A'" class="type-obj">
									<el-input v-model="item.from" size="small" placeholder="变量名称,注意大小写" />
								</div>
								<div v-show="item.fromType==='S'" class="type-obj">
									<orgdialog :org-node="item.orgNode" />
								</div>
							</div>


						</div>

						<div v-else-if="item.type==='L'" class="type-row">
							<div class="type-from">
								<el-radio-group v-model="item.fromType">
									<el-radio label="A">变量</el-radio>
									<el-radio label="S">定值</el-radio>
								</el-radio-group>

							</div>
							<div style="display:flex;justify-content:space-between;" class="type-obj">
								<div>
									<el-radio-group v-model="item.compare">
										<el-radio v-for="comp in levelCompareList"
											:label="comp.value">{{comp.label}}</el-radio>

									</el-radio-group>
								</div>
								<div style="width:120px;">
									<el-input v-show="item.fromType=='S'" v-model="item.layer" type="number"
										placeholder="" />
									<el-input v-show="item.fromType=='A'" v-model="item.from" size="small"
										placeholder="变量名称,注意大小写" />

								</div>
							</div>
						</div>


						<div v-else class="type-row">
							<div class="type-from">
								<el-radio-group v-model="item.fromType">
									<el-radio label="A">变量</el-radio>
									<el-radio label="S" v-if="item.type !='S'">定值</el-radio>
								</el-radio-group>

							</div>
							<div v-show="item.fromType=='A'" class="type-obj">
								<el-input v-model="item.from" size="small" placeholder="变量名称,注意大小写" />
							</div>
							<div v-show="item.fromType==='S'" class="type-obj">
								<orgdialog v-if="item.type==='U'" :org-node="item.orgNode"
									:tree-nodes="project.orgTree" />
								<roledialog v-else-if="item.type==='R'" :role-node="item.roleNode"
									:tree-nodes="project.roleTree" />
								<orguserdialog v-else-if="item.type==='P'" :user-item="item.user" />

								<div v-else-if="item.type==='A'">
									<span style="padding-right:10px;"> 流程变量：applyUserId</span>
								</div>
								<div v-else-if="item.type==='S'">
									<span style="padding-right:10px;">关系属性：leaderId</span>
								</div>
							</div>
						</div>




						<!-- <div v-else-if="item.type==='V'">
						<el-input v-model="item.express" placeholder="输入参与者表达式变量名" clearable />
					</div> -->
					</td>
					<td style="width:60px;vertical-align: top;">
						<el-button @click="addActorItem(item,true)" type="info" plain size="default" icon="plus" />
					</td>
				</tr>
				<tr class="actor-type-row">
					<td class="row-label">集合运算符</td>
					<td>
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div>
								<el-tag v-for="join in joinTypes" @click="addActorItem(join,false)" :title="join.title"
									type="" effect="light" size="large" class="operator-item">
									<span v-html="join.type" />
								</el-tag>
							</div>
							<div style="display: flex;justify-content: space-between;align-items: center;">
								<div style="200px">
									<orguserdialog :user-item="testUser" />

								</div>
								<div>
									<el-button @click="userTest" type="info" plain size="default">
										<i class="far fa-stethoscope"></i>
										测试</el-button>

								</div>
							</div>
						</div>



					</td>
					<td>
						<el-button @click="actorGroup.actorItems=[]" type="warning" plain size="default"
							icon="DeleteFilled" title="清除集合中所有元素" />
					</td>
				</tr>
				<tr class="actor-type-row">
					<td class="row-label">参与人集合 </td>
					<td colspan="2">
						<div
							style="width:calc(100% - 4px) ;height:115px;padding:0px;border:solid 1px #cccccc;border-radius: 5px; background-color: #f8f8f8;overflow: auto;">
							<template v-for="(item,index) in actorGroup.actorItems">

								<el-tag :type="item.isObj?'success':''" effect="dark" size="large" class="actor-obj"
									:title="item.tip">
									{{item.name}}
									<span :class="item.isObj?'actor-obj-tool' : 'actor-obj-tool actor-obj-tool-join'">
										<i class="fas fa-arrow-alt-left " @click="moveActorObj(index,-1)"
											title="前移"></i>
										<i class="fas fa-arrow-alt-right" @click="moveActorObj(index,1)" title="后移"></i>
										<i class="fal fa-times" title="删除" @click="deleteActorObj(index)"></i>
									</span>


								</el-tag>

							</template>

						</div>
					</td>
				</tr>
			</table>


		</div>
	</logicdialog>
</template>

<script>
	import FlowUtil from '../../../plugins/flowUtil.js'
	import orgDialog from '../treedialog/OrgDialog.vue'
	import roleDialog from '../treedialog/RoleDialog.vue'
	import orgUserDialog from '../treedialog/OrgUserDialog.vue'
	export default {
		props: {
			project: {
				type: Object,
				default: {}
			},


		},
		emits: ['ok'],
		data() {
			return {
				testUser: {},
				editActorItem: null, //当前编辑的对象
				actorGroup: {
					groupName: '',
					actorItems: []
				},

				actorTypes: [{
						title: '职能',
						type: 'J', //join
						fromType: 'A', // 默认发起人部门变量
						roleNode: {},
						orgNode: {},
						from: 'applyOrgId'

					},
					{
						title: '角色',
						type: 'R',
						fromType: 'S',
						from: '',
						roleNode: {}
					},
					{
						title: '组织',
						type: 'U',
						fromType: 'S',
						from: 'applyOrgId',
						orgNode: {}
					},

					{
						title: '用户',
						type: 'P', //person		
						user: {},
						fromType: 'S', //默认指定用户
						from: 'applyUserId'
					},
					{
						title: '职级',
						type: 'L', //Level，参数二0 //等于，-1小于等于,-2小于,1大于等于，2大于等于
						compare: '0',
						layer: 0,
						fromType: 'S', //默认指定职级
						from: 'applyUserLevel'
					},

					/* {
						title: '发起人',
						type: 'A', //applyUser 
					}, */
					{
						title: '上级',
						type: 'S', //superior 
						fromType: 'A', //默认指定变量
						from: 'applyUserId'
					},

					/* 
										{
											title: '变量表达式',//暂不启用
											type: 'V', //variable
											express: ''
										} */

				],
				levelCompares: {
					'0': '=', //等于
					'1': '>', //大于
					'2': '>=', //大于等于
					'3': '<', //小于
					'4': '<=', //小于等于

				},
				joinTypes: [{
						title: '相加',
						type: '+'
					},
					{
						title: '相减',
						type: '-'
					},
					{
						title: '相交',
						type: '&'
					},
					{
						title: '优先',
						type: '('
					},
					{
						title: '优先',
						type: ')'
					},

				],



			}
		},

		methods: {
			userTest() {
				let user = this.testUser
				if (!user.userId) {
					this.$logic.tip.error('请选择需测试的用户')
					return
				}
				if (this.actorGroup.actorItems.length < 1) {
					this.$logic.tip.error('请添加参与人表达式')
					return
				}
				if (FlowUtil.checkExpress(this.actorGroup.actorItems) > 0) {
					this.$logic.tip.error('参与人对象表达式语法错误，请确认')
					return
				}

				let check = FlowUtil.checkUserAccess(user.userId, this.actorGroup.actorItems, this.project, {})
				if (check) {
					this.$logic.tip.success(user.nodeTextPath + ' / ' + user.userName + ':存在于当前表达式中，如果表达式中使用了变量参数，以实际运行结果为准')
				} else {
					this.$logic.tip.warning(user.nodeTextPath + ' / ' + user.userName + ':不存在于当前表达式中，如果表达式中使用了变量参数，以实际运行结果为准')
				}


			},
			addActorItem(actorType, isObj) {
				if (actorType.fromType == 'A' && !actorType.from) {
					this.$logic.tip.error('请填写变量名称')
					return
				}
				let item = {
					isObj: isObj, //是否参与者对象
					type: actorType.type,
					fromType: actorType.fromType,
					from: actorType.from,
					name: isObj ? actorType.title : actorType.type
				}
				let objName = ''
				switch (actorType.type) {
					case 'R':
						if (actorType.fromType == 'S') {
							if (!actorType.roleNode.id) {
								this.$logic.tip.error('请选择角色')
								return
							}
							objName = actorType.roleNode.text + ''
							item.param1 = actorType.roleNode.id
							item.tip = actorType.roleNode.textPath
						} else {
							objName = '[' + item.from + ']'
							item.param1 = 0
							item.tip = '角色ID来自变量：' + item.from
						}



						break
					case 'U':
						if (actorType.fromType == 'S') {
							if (!actorType.orgNode.id) {
								this.$logic.tip.error('请选择组织')
								return
							}
							objName = actorType.orgNode.text + ''
							item.param1 = actorType.orgNode.id
							item.tip = actorType.orgNode.textPath
						} else {
							objName = '[' + item.from + ']'
							item.param1 = 0
							item.tip = '组织ID来自变量：' + item.from
						}
						break
					case 'J':

						if (!actorType.roleNode.id) {
							this.$logic.tip.error('请选择角色')
							return
						}
						item.param1 = actorType.roleNode.id
						if (actorType.fromType == 'S') {
							if (!actorType.orgNode.id) {
								this.$logic.tip.error('请选择组织')
								return
							}
							objName = actorType.roleNode.text + ',' + actorType.orgNode.text

							item.param2 = actorType.orgNode.id
							item.tip = '职能角色：' + actorType.roleNode.textPath + ' , 组织来自： ' + actorType.orgNode.textPath

						} else {
							objName = actorType.roleNode.text + ',[' + item.from + ']'
							item.param2 = 0
							item.tip = '职能角色：' + actorType.roleNode.textPath + ' , 组织来自变量：' + item.from
						}
						break
					case 'P':
						if (actorType.fromType == 'S') {
							if (!actorType.user.userId) {
								this.$logic.tip.error('请用户')
								return
							}
							objName = actorType.user.userName
							item.param1 = actorType.user.userId
							item.tip = actorType.user.nodeTextPath + ' / ' + actorType.user.userName
						} else {
							objName = '[' + item.from + ']'
							item.param1 = 0
							item.tip = '指定用户，用户ID来自变量：' + item.from
						}
						break
					case 'L':
						if (actorType.fromType == 'S') {
							if (actorType.layer === null) {
								this.$logic.tip.error('请输入职级')
								return
							}
							item.param1 = actorType.layer
							item.param2 = actorType.compare
							item.tip = item.name + this.levelCompares[item.param2] + ' ' + actorType.layer
							objName = this.levelCompares[item.param2] + ' ' + actorType.layer
						} else {
							item.param1 = 0
							item.param2 = actorType.compare
							item.tip = item.name + this.levelCompares[item.param2] + ' 变量：' + item.from
							objName = this.levelCompares[item.param2] + ' ' + '[' + item.from + ']'
						}
						break
					case 'A': //发起人，已废除
						item.param1 = 0
						item.tip = item.name
						break
					case 'S': //上级领导
						objName = '[' + item.from + ']'
						item.param1 = 0
						item.tip = 'ID为变量[' + item.from + ']值的用户的上级领导'
						break
						/* 	case 'V':
								item.name = item.name + ':' + actorType.express
								item.param1 = actorType.express
								item.tip = item.name
								break */
					case '+':
					case '-':
					case '&':
					case '(':
					case ')':
						item.tip = actorType.title
						break

				}
				if (item.isObj) {
					item.name = item.name + ':' + objName
				} 
				this.actorGroup.actorItems.push(item)
			},
			moveActorObj(idx, offset) {
				this.moveListItem(this.actorGroup.actorItems, idx, offset)

			},
			moveListItem(list, idx, offset) {
				let x = idx + offset
				if (x < 0 || x >= list.length) {
					return
				}

				let obj = list[idx]
				list.splice(idx, 1)
				list.splice(x, 0, obj)

			},
			deleteActorObj(idx) {
				let obj = this.actorGroup.actorItems[idx]
				this.$logic.message.confirm('确认', '是否确定删除当前对象：' + obj.tip, () => {
					this.actorGroup.actorItems.splice(idx, 1)
				})
			},


			actorSetOk() {
				if (!this.actorGroup.groupName || this.actorGroup.groupName.length < 1) {
					this.$logic.tip.error('请填写参与人组名称')
					return
				}
				/* if (this.actorGroup.actorItems.length < 1) {
					this.$logic.tip.error('至少需要有一项参与人对象')
					return
				}

				let bls = FlowUtil.getExpressBlans(this.actorGroup.actorItems)
				console.log(bls) */
				if (FlowUtil.checkExpress(this.actorGroup.actorItems) > 0) {
					this.$logic.tip.error('参与人对象表达式语法错误，请确认')
					return
				}


				/* 				let item = null
								if (this.editActorItem) {
									item = this.editActorItem
								} else {
									item = {
										id: new Date().getTime(),
										idx: 0,
										primaryGroup: false,
										finishMode: 'one', //每组只要有一个处理完即可
										actorLock: false, //默认按逻辑找人
									}
									this.nodeRule.actors.push(item)
									this.actorGroupSequence()
								} */
				let item = {}
				item.groupName = this.actorGroup.groupName
				item.actorItems = this.actorGroup.actorItems //[].concat(this.actorGroup.actorItems)
				this.actorGroup.actorItems = []
				//this.editActorItem = null
				this.$emit('ok', item, this.editActorItem)
				this.$refs.actorSet.hide()
			},
			toSetActor(item = null) {
				this.editActorItem = item
				if (item) { //编辑
					this.actorGroup.groupName = item.groupName
					this.actorGroup.actorItems = [].concat(item.actorItems)
				} else {
					this.actorGroup.groupName = ''
					this.actorGroup.actorItems = []
				}
				//初始化配置数据
				for (let cfg of this.actorTypes) {
					for (let key in cfg) {
						if (typeof(cfg[key]) === 'object') {
							cfg[key] = {}
						}
					}

				}
				this.$refs.actorSet.show()

			},

		},
		computed: {

			levelCompareList() {
				let list = []
				for (let key in this.levelCompares) {
					list.push({
						value: key,
						label: this.levelCompares[key]
					})
				}
				return list
			}
		},
		components: {
			orgdialog: orgDialog,
			roledialog: roleDialog,
			orguserdialog: orgUserDialog,

		},
		created() {


		}
	}
</script>

<style scoped>
	.actor-type-row:nth-of-type(odd) {
		background-color: #f4f4f4;
	}

	.actor-type-row:hover {
		background-color: rgb(250, 250, 210);
	}

	.row-label {
		width: 100px;
		text-align: right;
		padding-right: 10px;
		background-color: #f0f0f0;
	}

	.type-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.type-from {
		padding-left: 10px;
	}

	.type-obj {
		width: 480px;
	}

	.operator-item {
		cursor: pointer;
		margin: 0px 10px;
		font-size: 18px;
	}

	.actor-item {
		--actor-group-tool: none;
	}

	.actor-item:hover {
		--actor-group-tool: '';
	}

	.actor-primary {
		margin: 0px 5px !important;
		font-size: 14px !important;
		color: #409EFF !important;
	}

	.actor-group-tool {
		cursor: pointer;
		display: var(--actor-group-tool);
	}

	.actor-group-tool>i {
		cursor: pointer;
		margin: 0px 2px;
	}

	.actor-group-tool>i:hover {
		color: #FF4500;
	}

	.actor-obj {
		position: relative;
		margin: 5px;
		--tool-show: none;
	}

	.actor-obj:hover {
		--tool-show: '';
	}

	.actor-obj-tool {
		position: absolute;
		top: -5px;
		left: 0px;
		z-index: 10;
		min-width: 100%;
		border-radius: 4px 4px 0px 0px;
		background-color: #67c23a;
		display: var(--tool-show);
	}

	.actor-obj-tool-join {
		left: -20px;
		padding-right: 5px;
		background-color: #409eff;
	}


	.actor-obj-tool>i {
		cursor: pointer;
		margin-left: 10px;
	}

	.actor-obj-tool>i:hover {
		color: #FF4500;

	}

	:deep(.el-tag__content) {
		/* 去除父组件中的样式属性覆盖 */
		max-width: unset !important;
		text-overflow: unset !important;
	}
</style>