<template>
	<div class="flow-panel" @mouseup="mouseUp" @mousemove.stop="mouseMove">
		<svg class="flow-svg">
			<defs>
				<marker id="taskflowArrow" markerUnits="strokeWidth" markerWidth="8" markerHeight="5" refX="0" refY="2"
					orient="auto">
					<path d="M 0 0 L 5 2 L 0 4 z" stroke="#c71585" />
				</marker>

			</defs>






			<path v-if="drawLine.show" :d="drawLine.path" stroke="#c71585" stroke-width="2" fill="none"
				style="marker-end: url(#taskflowArrow);" />

			<path v-for="line in lineList" :d="line.path" stroke="#c71585" stroke-width="2" fill="none"
				style="marker-end: url(#taskflowArrow);" />

		</svg>
		<div class="node-panel">
			<template v-for="node in leafNodes">
				<div :ref="'task'+node.id" class="node-item noselect" @mousedown="nodeSelect($event,node)"
					@mouseup="nodeMouseUp(node)" :style="node.style">
					{{node.label}}
				</div>
			</template>


		</div>
		<div class="flow-tool  noselect" data-tag="item" :style="toolBar.style" @mousedown="nodeSelect($event,toolBar)">
		 
			<template v-for="item in toolItems">
				<div :class="item===drawMode?'tool-item tool-mode': 'tool-item'" @click.stop="setMode(item)">
					<div>
						<i :class="item.icon"></i>
					</div>
					<div>{{item.label}}</div>
				</div>
			</template>


		</div>
	</div>
</template>

<script>
	export default {
		props: ['nodeList'],
		data() {
			return { 
				currentNode: null,
				point: {
					x: 0,
					y: 0,
					offsetX: 0, //在元素内的偏移量
					offsetY: 0,
					startX: 0,
					startY: 0,
					nodeX: 0,
					nodeY: 0,
					width: 0, //元素的宽度，高度
					height: 0,
				},
				toolBar: {
					positionX: 5,
					positionY: 50,
					style: {
						left: '5px',
						top: '50px'
					}
				},
				drawMode: null,
				drawLine: {
					show: false,
					path: null
				},
				toolItems: [{
						key: 'select',
						label: '选择',
						icon: 'fal fa-mouse-pointer'
					},
					{
						key: 'line',
						label: '直线',
						icon: 'fal fa-horizontal-rule rotate45'
					},
					{
						key: 'polygon ',
						label: '折线',
						icon: 'fal fa-draw-polygon'
					},
					{
						key: 'delete',
						label: '删除',
						icon: 'fal fa-times'
					},
				],
				lineList: []
			}
		},

		methods: {
			setMode(mode) {
				this.drawMode = mode
			},
			nodeMouseUp(node) {
				if (this.currentNode === node) {
					return
				}
				for (let line of this.lineList) {
					if (line.from === this.currentNode && line.to === node) {
						return
					}
				}
				this.lineList.push(this.getLine(this.currentNode, node))

			},
			getLine(node1, node2) {
				let obj1 = this.$refs['task' + node1.id]
				let obj2 = this.$refs['task' + node2.id]
				let react1 = obj1.getBoundingClientRect()
				let react2 = obj2.getBoundingClientRect()
				let x1 = node1.positionX + react1.width / 2
				let y1 = node1.positionY + react1.height / 2
				let x2 = node2.positionX + react2.width / 2
				let y2 = node2.positionY + react2.height / 2

				let endPoint = this.getLineEndPoint(x1, y1, x2, y2, react2.width, react2.height, 15)
				x2 = endPoint.x
				y2 = endPoint.y
				let path = 'M ' + x1 + ',' + y1 + ' L ' + x2 + ',' + y2
				let line = {

					from: node1,
					to: node2,
					points:[],
					path: path
				}
				return line


			},
			getLineEndPoint(x1, y1, x2, y2, width, height, arrowLength) { //目标对象宽度、高度

				//先算出直线角度，再计算角度区间，用于判断是竖边相交还是横边相交
				let x = x2 - x1
				let y = y2 - y1
				//let l=Math.sqrt(x*x + y*y) 
				let angle = Math.atan(y / x) //Math.asin(y/l)  

				let angleRect = Math.atan(height / width) //对角线角度 
				let angleMin = -angleRect //与竖线相交的最小角度
				let angleMax = angleRect //与竖线相交的最大角度
				//在此区间即是与竖线相交，采用宽度的一半用反算出斜边长度，再算箭头长度的偏移长度
				let w = 0,
					h = 0,
					l = 0
				if (angle > angleMin && angle < angleMax) {
					l = Math.abs(width / 2 / Math.cos(angle))
				} else {
					l = Math.abs(height / 2 / Math.sin(angle))
				}
				l = l + arrowLength
				x = Math.abs(Math.cos(angle) * l)
				y = Math.abs(Math.sin(angle) * l)

				x = x2 > x1 ? x2 - x : x2 + x
				y = y2 > y1 ? y2 - y : y2 + y
 
				return {
					x: x,
					y: y
				}

				//this.angle = angle / (Math.PI * 2) * 360



			},
			mouseUp() {
				this.currentNode = null
				this.drawLine.show = false
			},
			mouseMove(e) {

				if (!this.currentNode) {
					return
				}
				let point = this.point
				let node = this.currentNode
				let offsetX = e.x - this.point.x
				let offsetY = e.y - this.point.y
				let x = this.point.nodeX + offsetX
				let y = this.point.nodeY + offsetY
				if (this.drawMode.key === 'select') {

					node.positionX = x >= 0 ? x : 0
					node.positionY = y >= 0 ? y : 0
					node.style.left = node.positionX + 'px'
					node.style.top = node.positionY + 'px'
					for (let line of this.lineList) {
						if (line.from === node || line.to === node) {
							let temp = this.getLine(line.from, line.to)
							line.path = temp.path
						}
					}



				} else if (this.drawMode.key === 'line' || this.drawMode.key === 'polygon') {
					let px = this.point.offsetX + x // 线段长度减掉箭头开度
					let py = this.point.offsetY + y
					let endPoint = this.getLineEndPoint(this.point.startX, this.point.startY, px, py, 1, 1, 15)
					px = endPoint.x
					py = endPoint.y
					this.drawLine.path = 'M ' + this.point.startX + ',' + this.point.startY + ' L ' + px + ',' + py


				}


			},
			nodeSelect(event, node) {
				this.point.x = event.x
				this.point.y = event.y
				this.point.offsetX = event.offsetX
				this.point.offsetY = event.offsetY
				this.point.nodeX = node.positionX
				this.point.nodeY = node.positionY


				//node.id 排除掉工具栏拖动的情况 
				if (node.id && this.drawMode.key === 'line' || this.drawMode.key === 'polygon') {
					let obj = this.$refs['task' + node.id]

					let react = obj.getBoundingClientRect()
					this.point.startX = this.point.nodeX + react.width / 2
					this.point.startY = this.point.nodeY + react.height / 2
					this.point.width = react.width
					this.point.height = react.height
					this.drawLine.show = true
				}
				this.currentNode = node
			}


		},
		computed: {
			leafNodes() {
				let nodes = []
				let nodeMap = {}
				for (let node of this.nodeList) {
					nodeMap['' + node.id] = node
				}
				for (let node of this.nodeList) {
					let key = '' + node.pid
					let item = nodeMap[key]
					if (item) {
						delete nodeMap[key]
					}

				}
				for (let key in nodeMap) {
					let item = nodeMap[key]
					//item.label = '基本面阿斯蒂芬阿斯基'
					if (!item.positionX) {
						item.positionX = 0
					}
					if (!item.positionY) {
						item.positionY = 0
					}
					item.style = {
						left: item.positionX + 'px',
						top: item.positionY + 'px'
					}
					nodes.push(item)

				}
				return nodes
			}
		},
		created() {
			this.drawMode = this.toolItems[0]


		}
	}
</script>

<style scoped>
	.flow-panel {
		width: 100%;
		height: 100%;
		position: relative;

	}

	.flow-svg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 0;
	}

	.flow-tool {
		position: absolute;

		width: 40px;
		/*	height: 200px; */
		display: flex;
		flex-direction: column;
		align-items: center;
		border: solid 1px #cccccc;
		border-radius: 5px;
		padding: 20px 2px;
		cursor: move;
		z-index: 2;
	}

	/* .flow-tool:hover {
		background-color: #fbffaf;
	} */

	.tool-item {
		width: calc(100% - 10px);
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: solid 1px #cccccc;
		/* 		border-left: 0px;
		border-right: 0px; */
		/* 	border-radius: 5px; */
		background-color: #f5f5f5;
		cursor: pointer;
		padding: 5px;
		margin-top: 0px;
	}

	.tool-item:hover {
		background-color: #fbffaf;
	}

	.tool-item i {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.tool-mode {
		color: #409EFF;
	}

	.node-panel {
		width: 100%;
		height: 100%;
		position: relative;

		z-index: 1;

	}

	.node-item {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 40px;
		padding: 0px 10px;
		overflow: hidden;
		border: solid 1px #cccccc;
		border-radius: 5px;

		background: linear-gradient(#fcfcfc, #f5f5f5, #fcfcfc);
		/* linear-gradient(rgb(164,210,255), rgb(64,158,255), rgb(164,210,255)); */
	}

	.node-item:hover {
		background: linear-gradient(#fbffaf, #fbffaf, #fbffaf);
	}
</style>