<template>
	<template v-for="item in items">
		<template v-if="item.hide !==true ">
			<el-sub-menu v-if="item.items && item.items.length>0 && item.group !='Y'" :index="item.key">
				<template #title>
					<i v-if="showIcon" :class="'menuicon '+item.icon"></i>
					<span :style="{color:item.color}">{{item.label}}</span>
				</template>
				<subitem :items="item.items" :show-icon="showIcon"></subitem>
			</el-sub-menu>
			<el-menu-item-group v-else-if="item.items && item.items.length>0 && item.group=='Y'">
				<template #title>
					<i v-if="showIcon" :class="'menuicon '+item.icon"></i>
					<span :style="{color:item.color}">{{item.label}}</span>
				</template>
				<subitem :items="item.items" :show-icon="showIcon"></subitem>
			</el-menu-item-group>
			<el-menu-item v-else :index="item.key" :disabled="item.disabled=='Y'">
				<i v-if="showIcon" :class="'menuicon '+item.icon"></i>

				<span :style="{color:item.color}">{{item.label}}</span>

			</el-menu-item>
		</template>
	</template>

</template>

<script>
	export default {
		name: 'subitem',
		props: ['items', 'showIcon'],
		//	emits:['menuClick'],
		data() {
			return {

			}
		},

		methods: {
			/* 	menuClick(item){
				this.$emit('menuClick',item)
				console.log(item)
			}
 */
		},
		created() {

		}
	}
</script>

<style scoped>
	.menuicon {
		width: 22px;
		text-align: center;
		font-size: 18px;
		margin-right: 5px;
	}
</style>