import compBase from '../../public/comp.js'
import options from '../../public/options.js'



let base=[
{key:'title',text:'流程表单'},
//{key:'formName',text:'表单名称',type:'text',init:'流程页面',target:'',bursh:false,bind:false,tip:'',list: null},
{key:'formType',text:'表单类型',type:'text',init:null,target:'',bursh:false,bind:false,tip:'',read:true,list: null},
{key:'accessGroups',text:'页面权限',type:'json',init:null,target:null,bursh:true,bind:true,tip:'',list:null},
//{key:'accessType',text:'权限场景',type:'access',init:'',target:null,bursh:true,bind:true,tip:'',list:null},

//{key:'content',text:'表单内容',type:'json',init:'',target:'',bursh:false,bind:false,tip:'',list: null}, 	

/* 
{key:'labelShow',text:'显示标签',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelText',text:'标签文本',type:'text',init:'条件',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'labelColor',text:'标签颜色',type:'color',init:null,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'label',text:'标签对象',type:'json',init:{positionX:0,positionY:0,style:{}},target:'',bursh:true,bind:true,tip:'',list: null},
{key:'condition',text:'路径条件',type:'text',init:'',target:'',bursh:true,bind:true,tip:'Javascript语法，逻辑表达式',list: null}, 	
 *//*{key:'positionX',text:'节点X位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'positionY',text:'节点Y位置',type:'number',init:0,target:'',bursh:true,bind:true,tip:'',list: null},
 */

]
let events=[
/* 	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
 */
]

 
let flowform= {
	data:{
		type:'flowform', 
	},
	delete:'',
	hide:'accessGroups',
	base:base,
	special :[],
	action:options.getCommonEvents().concat(options.getEvents(null,'$hook'))
}

export default flowform