<template>
	<el-menu @select="menuClick" :mode="mode" :default-active="active" :collapse="collapse"
		:background-color="backColor" :text-color="textColor" :active-text-color="activeColor" style="height:100%">

		<subitem :items="items" :show-icon="showIcon"></subitem>

	</el-menu>
</template>

<script>
	import subItem from './MenuItemEl.vue'

	export default {
		props: {
			mode: {
				type: String,
				default: 'vertical'
			},
			showIcon: {
				type: Boolean,
				default: true
			},
			items: {
				type: Array,
				default: []
			},
			active: {
				type: String,
				default: ''
			},
			collapse: {
				type: Boolean,
				default: false
			},
			backColor: {
				type: String,
				default: '#0b0927'
			},
			textColor: {
				type: String,
				default: '#ffffff'
			},
			activeColor: {
				type: String,
				default: '#ff9538'

			}

		},
		emits: ['menuClick'],
		data() {
			return {


			}
		},

		methods: {
			menuClick(index, indexPath, item) { //path里不带group类型数据

				let itemObj = null
				let datas = [].concat(this.items)
				let list = []
				let find = false
				while (datas.length > 0) {
					let data = datas.pop() //如果存在于indexPath中就记录下来，最终必然是对象路径						
					if (indexPath.includes(data.key)) {
						list.push(data)
						if (data.key == index) { //如果找到了当前项退出
							itemObj = data
							break
						}
					}
					if (data.items) {
						for (let sub of data.items) {
							datas.push(sub)
						}
					}
				}

				if (itemObj && itemObj.key == index) { //确保找到此项

					this.$emit('menuClick', itemObj, list)
				} else {
					this.$logic.tip.error('菜单数据错误，请刷新系统重试')
				}

			}

		},
		created() {
		

		},
		components: {
			subitem: subItem
		},

	}
</script>

<style scoped>
	:deep(.el-menu-item:hover) {
		color: #409EFF
	}
</style>