<template>

	<input type="hidden" v-model="tick" @click="test" />
	<table :border="model.lineShow=='Y'?1:0" class="tablelist" :style="tableStyle" cellspacing="0" cellpadding="0"
		@click="tipShow=false">
		<tr class="tabletop">
			<td class=" colfirst">
				<div>
					<i v-if="isedit" class="fad fa-plus" title="插入新列" @click="addNewColnum"></i>

					<el-popover placement="bottom-start" :visible="tipShow " :width="200" trigger="hover"
						:popper-style="tipColor">
						<template #reference>
							<span> </span>
						</template>
						<div @click="tipShow=false">
							<div>温馨提示</div>
							<div>{{tipContent}}</div>
						</div>

					</el-popover>
				</div>
			</td>
			<template v-for="col in model.items">
				<td v-if="isedit || col.show=='show'" @click.stop="headClick(col)"
					:style="{minWidth:col.minWidth,width:col.headWidth,textAlign:col.headAlign}">

					<div :class="isedit && currentElement===col? 'current-col' :''">
						<i v-if="col.show !='show'" class="fal fa-eye-slash"></i>
						<i v-if="col.status =='disabled'" style="margin-right: 5px;" class="far fa-lock"></i>
						<span v-if="! col.dataName" style="color: #ff0000;" title="请设置数据名称">*</span>
						{{col.headText || col.headName}}

					</div>


				</td>
			</template>
			<td class=" col-last">
				<div>
					<i @click="addDataRow('add')" class="fas fa-plus-square colbutton"></i>
				</div>
			</td>
		</tr>







		<template v-for="(row,i) in dataRows">
			<tr v-if="row.hide$ !==true" class="trbody" :key="row">
				<td class="tdhead colfirst" @click="toEditDate(i)">
					<!-- <i class="far fa-edit list-row-edit" @click="toEditDate(i)" /> -->
					{{i+1}}


					<i class="fal fa-arrow-alt-from-top" @click.stop="dataDown(i)" style="display:var(--data-down);"
						title="点击复制此行数据到下一行"></i>
				</td>
				<template v-for="(col,j) in model.items">
					<td v-if="isedit || col.show=='show'" @click="cellClick(i,j,col)" :align="col.tdH"
						:valign="col.tdV">


						<template v-if="col.dataName ">
							<template v-if="col.inputType=='text'">
								<input :ref="'c_'+i+'_'+j" v-model="row[col.dataName]" type="text"
									:class="'cell-text '+col.inputAlign" @keyup="gotoCell($event,i,j,col)"
									@focus="inputFocus('text',i,j,row,col)" @blur="inputBlur('text',i,j,row,col)"
									:readonly="model.status=='disabled' || col.status=='disabled'" />
								<!-- model.opration=='read' -->

							</template>
							<template v-else-if="col.inputType=='num'">
								<input :ref="'c_'+i+'_'+j" v-model="row[col.dataName]" type="number"
									:class="'cell-text '+col.inputAlign" @keydown="gotoCell($event,i,j,col)"
									@focus="inputFocus('num',i,j,row,col)" @blur="inputBlur('num',i,j,row,col)"
									:readonly="model.status=='disabled' || col.status=='disabled'" />
							</template>
							<template v-else-if="col.inputType=='select'">
								<select :ref="'c_'+i+'_'+j" v-model="row[col.dataName]" class="cell-select"
									@keydown="gotoCell($event,i,j,col)" @focus="inputFocus('select',i,j,row,col)"
									@blur="inputBlur('select',i,j,row,col)" placeholder="请选择"
									:readonly="model.status=='disabled'"
									:disabled="model.status=='disabled' || col.status=='disabled'">
									<!-- <option v-for="item in col.inputOption.selectOptions" :value="item.value">
										{{item.label}}
									</option> -->
									<option v-for="item in col.inputOption.jsonArray.list" :value="item.value">
										{{item.label}}
									</option>

								</select>
							</template>
							<template v-else-if="col.inputType=='date'">
								<div class="cell-date">
									<div :class="rowIndex==i && colIndex==j?'cell-date-hide':'cell-date-panel '">
										<div class="cell-date-icon">
											<i class="far fa-calendar"></i>
										</div>
										<input :ref="'c_'+i+'_'+j" :value="row[col.dataName]"
											@keyup="gotoCell($event,i,j,col)" @focus="inputFocus('date',i,j,row,col)"
											placeholder="请选择日期" :class="'cell-date-input '+col.inputAlign" />
									</div>
									<div v-if="rowIndex==i && colIndex==j" style="width: 100%;height: 100%;">
										<el-time-picker v-if="col.inputOption.dateType=='time'" v-model="timeValue"
											style="width: 100%;height:100%;border: solid 1px #409eff;"
											:disabled="model.status=='disabled' || col.status=='disabled'"
											@change="setDateTime($event,row,col)" size="default" placeholder="请选择时间" />
										<el-date-picker v-else v-model="dateTimeValue"
											:disabled="model.status=='disabled' || col.status=='disabled'"
											style="width: 100%;height:100%;border: solid 1px #409eff;"
											@change="setDateTime($event,row,col)" :type="col.inputOption.dateType"
											placeholder="请选择" size="default" />
									</div>

								</div>
							</template>
							<template v-if="col.inputType=='express'">
								<input :ref="'c_'+i+'_'+j" v-model="row[col.dataName]" type="text" readonly
									:class="'cell-text '+col.inputAlign" @keyup="gotoCell($event,i,j,col)"
									@focus="inputFocus('text',i,j,row,col)" @blur="inputBlur('text',i,j,row,col)" />

							</template>
							<template v-else-if="col.inputType=='tree'">
								<div class="cell-date">
									<div :class="rowIndex==i && colIndex==j?'cell-date-hide':'cell-date-panel'">

										<input :ref="'c_'+i+'_'+j" :value="row[col.dataName]"
											@keyup="gotoCell($event,i,j,col)" @focus="inputFocus('tree',i,j,row,col)"
											placeholder="请选择" class=" cell-date-input cell-tree"
											:readonly="model.status=='disabled' || col.status=='disabled'" />
										<div class="cell-date-icon">
											<i class="far fa-folder"></i>
										</div>
									</div>
									<div v-if="rowIndex==i && colIndex==j" style="width: 100%;height: 100%;">
										<treeviewdialog style="border-color: #409eff;"
											:node-list="col.inputOption.tree.nodes || []" :text-path="row[col.dataName]"
											:resume="true" :size="'small'" :title="'请选择'" icon="far fa-folder"
											:disabled="model.status=='disabled' || col.status=='disabled'"
											:width="col.inputOption.tree.width" :height="col.inputOption.tree.height"
											:split-char="col.inputOption.tree.char" @open="treeViewOpen(col,null)"
											@select="nodeSelect($event,row,col)" />
									</div>

								</div>
							</template>
							<template v-else-if="col.inputType=='leaf'">
								<div class="cell-date">
									<div :class="rowIndex==i && colIndex==j?'cell-date-hide':'cell-date-panel'">

										<input :ref="'c_'+i+'_'+j" :value="row[col.dataName]"
											@keyup="gotoCell($event,i,j,col)" @focus="inputFocus('leaf',i,j,row,col)"
											placeholder="请选择" class=" cell-date-input"
											:readonly="model.status=='disabled' || col.status=='disabled'" />
										<div class="cell-date-icon">
											<i class="far fa-folder-tree"></i>
										</div>
									</div>
									<div v-if="rowIndex==i && colIndex==j" style="width: 100%;height: 100%;">
										<treelistdialog style="border-color: #409eff;"
											:node-list="col.inputOption.tree.nodes || []"
											:query-result="col.inputOption.treedata.ListResult"
											:data-text="row[col.dataName]" text-field="label" :resume="true"
											:size="'small'" :title="'请选择'" icon="far fa-folder-tree"
											:disabled="model.status=='disabled' || col.status=='disabled'"
											:width="col.inputOption.tree.width" :height="col.inputOption.tree.height"
											:split-char="col.inputOption.tree.char" @open="treeViewOpen(col,$event)"
											@select="treeListSelect($event,row,col)"
											@query="treeListQuery($event,col)" />


									</div>

								</div>
							</template>
							<template v-else-if="col.inputType=='load'">
								<div class="cell-date">
									<div :class="rowIndex==i && colIndex==j?'cell-date-hide':'cell-date-panel'">
										<input :ref="'c_'+i+'_'+j" :value="row[col.dataName]"
											:readonly="model.status=='disabled' || col.status=='disabled'"
											@keyup="gotoCell($event,i,j,col)" @focus="inputFocus('load',i,j,row,col)"
											placeholder="请输入" class=" cell-date-input" />
										<div class="cell-date-icon">
											<i class="fal fa-comment-dots"></i>
										</div>
									</div>
									<div v-if="rowIndex==i && colIndex==j" style="width: 100%;height: 100%;">
										<inputsuggest v-model="row[col.dataName]" :options="col.inputOption.load.list"
											@query="onQuery($event,col)" @choose="onChoose($event,row,col)"
											@keyup="gotoCell($event,i,j,col)" :tick="loadTick"
											:status="(model.status =='disabled'  || col.status=='disabled')?'disabled':''"
											:table-menu="col.inputOption.load.tableMenu==='Y'"
											:menu-width="col.inputOption.load.menuWidth"
											:menu-height="col.inputOption.load.menuHeight"
											:table-style="col.inputOption.load.tableStyle" :maxlength="0"
											placeholder="请输入" size="small" :icon-ih="null" icon-it="ChatDotRound"
											:icon-oh="null" :text-oh="null" :icon-ot="null" :text-ot="null"
											:disabled="false" status="" :clearable="true" />

									</div>

								</div>
							</template>
						</template>
						<template v-else>

						</template>

					</td>
				</template>
				<td class="">
					<div v-show="model.status !='disabled' " class="coltool">
						<i @click="addDataRow('add',i)" title="前面插入行" class="fas fa-plus-square colbutton"></i>
						<i @click="addDataRow('del',i)" title="删除当前行"
							class="fas fa-minus-square colbutton buttonwarning"></i>
					</div>

				</td>
			</tr>
		</template>


		<tr v-if="model.showFoot=='Y'" class="trbody foot-tr">
			<td class="tdhead ">
				<div>
					<i class="fas fa-sigma" title=""></i>
				</div>

			</td>
			<template v-for="col in footColList">
				<td :colspan="col.span" :align="col.foot.alignH" @click.stop="headClick(col.foot)"
					:style="getFootStyle(col.foot)" class="foot-cell">


					<i v-if="isedit" @click="footSplit(false,col.foot)" class="fas fa-arrow-alt-to-left foot-button"
						title="合并到左侧单元格"></i>

					<span class="cell-span-left">{{col.foot.beforeText}}</span>
					<span class="foot-total">{{col.foot.total}}</span>
					<span class="cell-span-right">{{col.foot.afterText}}</span>
					<i v-if="isedit && col.span>1" class="fas fa-arrow-alt-to-right foot-button"
						@click="footSplit(true,col.foot)" title="拆分合并单元格"></i>
				</td>
			</template>
			<td class=" tdhead">
				<div>
					<!-- <i class="fas fa-plus-square colbutton"></i> -->
				</div>
			</td>
		</tr>
	</table>
	<!-- 
	<div style="width: 100%;height: 100%;overflow: auto;">
	</div>
 -->

	<logicdialog ref="rowEditDialog" @buttonclick="dialogButtonClick" @close="rowEditDialogClose" :auto-close="false"
		:title="'数据编辑,第'+editRowNo+'行'" dialog-height="90vh" dialog-width="99%" icon="far fa-edit"
		:buttons="editButtons" :show-ok="false" :show-cancel="false">
		<table border="0" class="tablelist" cellspacing="0" cellpadding="0">
			<template v-for="(col,j) in model.items">
				<tr>
					<td class="row-edit-label">
						{{col.headText || col.headName}}
					</td>
				</tr>
				<tr>
					<td v-if="isedit || col.show=='show'">

						<template v-if="col.dataName ">
							<template v-if="col.inputType=='text'">
								<el-input v-model="editRowData[col.dataName]"
									@blur="inputBlur(col.inputType,editRowNo-1,j,editRowData,col)" style="width: 100%;"
									:readonly="model.status=='disabled' || col.status=='disabled'" />
							</template>
							<template v-else-if="col.inputType=='num'">
								<el-input v-model="editRowData[col.dataName]"
									@blur="inputBlur(col.inputType,editRowNo-1,j,editRowData,col)" type="number"
									style="width: 100%;"
									:readonly="model.status=='disabled' || col.status=='disabled'" />
							</template>
							<template v-else-if="col.inputType=='select'">

								<el-select v-model="editRowData[col.dataName]"
									@change="inputBlur(col.inputType,editRowNo-1,j,editRowData,col)"
									:readonly="model.status=='disabled' || col.status=='disabled'"
									:disabled="model.status=='disabled' || col.status=='disabled'" style="width: 100%;">
									<el-option v-for="item in col.inputOption.jsonArray.list" :value="item.value"
										:label="item.label" />


								</el-select>

							</template>
							<template v-else-if="col.inputType=='date'">
								<div class="cell-date">

									<el-time-picker v-if="col.inputOption.dateType=='time'"
										v-model="editRowData[col.dataName]"
										@change="inputBlur(col.inputType,editRowNo-1,j,editRowData,col)"
										style="width: 100%;height:100%;border: solid 1px #409eff;"
										:disabled="model.status=='disabled' || col.status=='disabled'" size="default"
										value-format="HH:mm:ss" placeholder="请选择时间" />
									<el-date-picker v-else v-model="editRowData[col.dataName]"
										@change="inputBlur(col.inputType,editRowNo-1,j,editRowData,col)"
										:disabled="model.status=='disabled' || col.status=='disabled'"
										style="width: 100%;height:100%;border: solid 1px #409eff;"
										:value-format="col.inputOption.dateType=='date'?'YYYY-MM-DD':'YYYY-MM-DD HH:mm:ss'"
										:type="col.inputOption.dateType" placeholder="请选择" size="default" />

								</div>
							</template>
							<template v-if="col.inputType=='express'">
								<el-input v-model="editRowData[col.dataName]" style="width: 100%;" readonly />
							</template>
							<template v-else-if="col.inputType=='tree'">
								<div class="cell-date">
									<treeviewdialog style="border-color: #409eff;"
										:node-list="col.inputOption.tree.nodes || []"
										:text-path="editRowData[col.dataName]" :resume="true" :size="'small'"
										:title="'请选择'" icon="far fa-folder"
										:disabled="model.status=='disabled' || col.status=='disabled'"
										:width="col.inputOption.tree.width" :height="col.inputOption.tree.height"
										:split-char="col.inputOption.tree.char" @open="treeViewOpen(col,null)"
										@select="nodeSelect($event,editRowData ,col)" />
								</div>
							</template>
							<template v-else-if="col.inputType=='leaf'">
								<div class="cell-date">

									<treelistdialog style="border-color: #409eff;"
										:node-list="col.inputOption.tree.nodes || []"
										:query-result="col.inputOption.treedata.ListResult"
										:data-text=" editRowData[col.dataName]" text-field="label" :resume="true"
										:size="'small'" :title="'请选择'" icon="far fa-folder-tree"
										:disabled="model.status=='disabled' || col.status=='disabled'"
										:width="col.inputOption.tree.width" :height="col.inputOption.tree.height"
										:split-char="col.inputOption.tree.char" @open="treeViewOpen(col,$event)"
										@select="treeListSelect($event,editRowData ,col)"
										@query="treeListQuery($event,col)" />



								</div>
							</template>
							<template v-else-if="col.inputType=='load'">
								<div class="cell-date">

									<inputsuggest v-model="editRowData[col.dataName]"
										:options="col.inputOption.load.list" @query="onQuery($event,col)"
										@choose="onChoose($event,editRowData ,col)" :tick="loadTick"
										:status="(model.status=='disabled'  || col.status=='disabled')?'disabled':''"
										:table-menu="col.inputOption.load.tableMenu==='Y'"
										:menu-width="col.inputOption.load.menuWidth"
										:menu-height="col.inputOption.load.menuHeight"
										:table-style="col.inputOption.load.tableStyle" :maxlength="0" placeholder="请输入"
										size="small" :icon-ih="null" icon-it="ChatDotRound" :icon-oh="null"
										:text-oh="null" :icon-ot="null" :text-ot="null" :disabled="false" status=""
										:clearable="true" />


								</div>
							</template>
						</template>
						<template v-else>

						</template>

					</td>

				</tr>

			</template>


		</table>
	</logicdialog>


</template>

<script>
	import TreeListDialog from '../../../../comm/treedialog/TreeListDialog.vue'
	import TreeViewDialog from '../../../../comm/treedialog/TreeViewDialog.vue'
	import Bolanshi from '../../../../../plugins/Bolanshi.js'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				deleteList: [], //已删除的行ID
				loadTick: 0,
				editRowNo: 0, //当前编辑的行号，从1开始
				editRowData: {},
				dataRows: [],
				rowIndex: -1,
				colIndex: -1,
				dateTimeValue: null,
				timeValue: null,
				tipShow: false,
				tipContent: '按Ctrl + 方向键 可直接全表编辑数据',
				tipColor: null,
				maxColId: 0,
				tick: '', //用于解决数据不更新的问题,如果数据中对象属性变化不能被触发视图更新，设置一个变量，关联到一个hidden元素，改变此变量值即可实现刷新

				editButtons: [{
						key: 'pre',
						text: '上一行',
						icon: 'far fa-chevron-up',
						type: 'primary'
					},
					{
						key: 'next',
						text: '下一行',
						icon: 'far fa-chevron-down',
						type: 'primary'
					},
					/* {
						key: 'copy',
						text: '',
						icon: 'far fa-level-down-alt',
						type: 'danger'
					}, */
				]
			}
		},

		methods: {
			test() {
				let vars = {}
				//let rs = Bolanshi.getNiBolanshi(' abc-123+( max (a*b,a+c)/d)/sum   (a,b,c)', vars)
				let rs = Bolanshi.getNiBolanshi('d+1', vars)


				//console.log(rs, vars)
			},
			dialogButtonClick(button) {
				if (button.key === 'pre') {
					this.changeEditRow(-1)
				} else if (button.key === 'next') {
					this.changeEditRow(1)
				} else if (button.key === 'copy') {
					if (this.editRowNo < 2) {
						this.$logic.tip.error('无法复制上一行的数据，当前是第一行')
						return
					}
					let preRow = this.dataRows[this.editRowNo - 2]
					for (let col of this.model.items) {
						this.editRowData[col.dataName] = preRow[col.dataName]
					}


				}
			},
			rowEditDialogClose() {
				//this.autoUpdate()
				//this.colTotal()
				this.$refs.rowEditDialog.hide()
			},

			changeEditRow(offset) {
				if (this.editRowNo < 2 && offset < 0) {
					this.$logic.tip.info('已经是第一行')
					return
				} else if (this.editRowNo >= this.dataRows.length && offset > 0) {
					this.$logic.tip.info('已经是最后一行')
					return
				}
				this.editRowNo = this.editRowNo + offset
				this.editRowData = this.dataRows[this.editRowNo - 1]
			},
			toEditDate(idx) {
				if (!this.isedit) {
					this.editRowNo = idx + 1
					this.changeEditRow(0)
					this.$refs.rowEditDialog.show()
				}


			},
			dataDown(idx) {
				let src = this.dataRows[idx]
				let target = this.dataRows.length > idx + 1 ? this.dataRows[idx + 1] : this.addDataRow('add')
				for (let col of this.model.items) { //只向下复制带数据名称的列
					if (col.dataName) {
						target[col.dataName] = src[col.dataName]
					}
				}
				this.colTotal()
			},
			getColFieldMap() {
				let colMap = {}
				for (let colItem of this.model.items) {
					colMap[colItem.headName] = colItem.dataName
				}
				return colMap
			},
			onQuery(keyWord, col) {
				let load = col.inputOption.load
				if (load.sql) {
					let sql = load.sql.replace(/\$keyword/g, keyWord)
					sql = this.$logic.db.getParamSql(sql, this.page.$params || {}, this.page, null)
					load.list = null
					let maxSize = load.maxSize > 0 ? load.maxSize > 100 ? 100 : load.maxSize : 20
					let sourceId = this.getSourceId(col.inputOption.sourceId)
					this.$logic.http.sqlQuery(sourceId, sql, [], {
						isJson: true,
						size: maxSize
					}).then(res => {
						let list = []
						for (let item of res.data.data.dataList) {
							list.push({
								label: item.label ? item.label.replace(keyWord,
										'<span style="color:#F56C6C;">' + keyWord + '</span>') :
									'查询中无label字段',
								data: item
							})
						}
						load.list = list
					}).catch(err => {
						load.list = []
						this.$logic.tip.error('获取获取异常：' + err.info)
					})
				}
			},
			onChoose(item, row, col) {
				let load = col.inputOption.load
				let colMap = this.getColFieldMap()
				let total = false //
				for (let fill of load.fill) {
					let dataName = colMap[fill.col]
					if (dataName) {
						for (let col of this.model.items) { //找到数据名的所在列
							if (col.dataName == dataName) {
								let value = item[fill.field]
								row[dataName] = value
								if (col.inputType == 'num') {
									this.autoUpdate(value, row, col, null, null) //自动公式计算更新数据	
									total = true //如果是数值型列被更新，需要重新汇总一次
								}

								continue
							}
						}

					}
				}
				if (total) {
					this.colTotal() //自动汇总一次
				}

			},
			treeListSelect(result, row, col) {
				let colMap = this.getColFieldMap()
				/*let tree = col.inputOption.tree
				 if (tree.dataId && tree.idField) { //设置了回填Id
					let dataName = colMap[tree.dataId]
					if (dataName) {
						row[dataName] = result.listItem[tree.idField]
					}

				}
				if (tree.dataText && tree.textField) { //设置了回填文本
					let dataName = colMap[tree.dataText]

					if (dataName) {
						row[dataName] = result.listItem[tree.textField]
					}
				} */
				for (let head of col.inputOption.treedata.tableStyle.heads) {
					if (head.col) {
						let dataName = colMap[head.col]
						if (dataName) {
							row[dataName] = result.listItem[head.field]
						}
					}

				}
				this.nodeSelect(result.treeNode, row, col)


			},
			nodeSelect(result, row, col) {
				let colMap = {}
				for (let colItem of this.model.items) {
					colMap[colItem.headName] = colItem.dataName
				}
				for (let key of ['id', 'pId', 'label', 'textPath', 'idPath']) {
					let colName = col.inputOption.tree[key]
					if (colName) {
						let dataName = colMap[colName]
						//console.log(dataName)
						if (dataName) {
							row[dataName] = result[key]
						}
					}
				}

				//console.log(result,row,col)
			},
			treeListQuery(param, col) {
				if (this.isedit) {
					return
				}
				col.inputOption.treedata.ListResult = []
				if (col.inputOption.treedata.sql) {
					let sql = col.inputOption.treedata.sql
					sql = sql.replace(/\$id/g, param.id).replace(/\$keyword/g, param.keyWord)
					let sourceId = this.getSourceId(col.inputOption.sourceId)
					this.$logic.http.sqlQuery(sourceId, sql, [], {
						isJson: false
					}).then(res => {
						col.inputOption.treedata.ListResult = res.data.data
						for (let head of col.inputOption.treedata.tableStyle.heads) {
							let item = col.inputOption.treedata.ListResult.heads.find((element, index, array) => {
								return element.label == head.field
							})
							if (item) {
								item.label = head.label
								item.fieldName = head.field
							}
						}
					})
				} else {
					this.$logic.tip.error('未配置此列表数据查询SQL')
				}

			},
			treeViewOpen(col, dialog) {
				if (this.isedit) {
					//col.inputOption.tree.nodes=null
					return
				}
				let nodes = col.inputOption.tree.nodes || []
				if (col.inputOption.tree.nodes == null) { //弹出时如果没有加载过数据，加载
					if (col.inputOption.tree.sql) {
						let sourceId = this.getSourceId(col.inputOption.sourceId)
						this.$logic.http.sqlQuery(sourceId, col.inputOption.tree.sql, [], {
							isJson: true
						}).then(res => {
							col.inputOption.tree.nodes = res.data.data.dataList
							if (dialog) {
								dialog.show()
							}
						}).catch(err => {
							this.$logic.tip.error('获取树节点数据异常：' + err.info)
						})
					} else {
						this.$logic.tip.error('未配置此列树节点的查询SQL')
					}

				} else {
					if (dialog) {
						dialog.show()
					}
				}

			},
			colTotal() { //所有列全部汇总
				let totalMap = {}
				for (let col of this.model.items) {
					col.foot.total = ''
					if (col.foot.show != 'show' || col.foot.totalType == 'none') { //不显示或不汇总时跳过此列
						continue
					}
					let max = null
					let min = null
					let sum = 0


					for (let row of this.dataRows) {
						let cv = row[col.dataName]
						let value = null
						if (col.inputType === 'num' || col.inputType === 'express') { //数值型和公式型作整数处理

							if (typeof(cv) == 'number') {
								value = cv
							} else if (cv != null && cv != '') {
								try {
									value = parseFloat(cv)
									if (isNaN(value)) {
										value = 0
									}

								} catch (err) {
									continue
								}
							} else {
								continue
							}
						} else {

							value = cv ? cv : ''

						}
						if (max === null || max < value) {
							max = value
						}
						if (min === null || min > value) {
							min = value
						}
						if (typeof(value) == 'number') {
							sum = sum + value
						}
					}

					switch (col.foot.totalType) {
						case 'sum':
							col.foot.total = sum
							break
						case 'avg':
							col.foot.total = this.dataRows.length > 0 ? sum / this.dataRows.length : 0
							break
						case 'max':
							col.foot.total = max
							break
						case 'min':
							col.foot.total = min
							break
						case 'rows':
							col.foot.total = this.dataRows.length
							break
					}
					if (typeof(col.foot.total) == 'number') {
						col.foot.total = Math.round(col.foot.total * Math.pow(10, col.foot.decimal)) / Math.pow(10, col
							.foot.decimal)
					}
					totalMap[col.headName] = col.foot.total
					if (col.foot.setObj) {
						col.foot.setObj.$value(col.foot.total)
					}
				}
				if (!this.isedit) {
					this.doEvent({
						eventName: 'total',
						total: totalMap,
						rows: this.dataRows,
					}, '$comp_total')
				}
			},
			autoUpdate(value, row, col, i, j) { //自动计算关联公式列
				if (!col.actionCols || Object.keys(col.actionCols).length < 1) {
					return
				}

				if (true || value || value == 0) { //始终处理//有值才处理
					let cols = {}
					let vars = {}
					let js = ''

					for (let item of this.model.items) {
						let headName = item.headName //.toUpperCase()
						cols[headName] = item
						//vars[headName] = row[item.dataName] //行数据赋值给变量集
						let value = row[item.dataName]
						//console.log(item)
						if (item.inputType === 'num' || item.inputType === 'express') {
							js = js + ',' + headName + '=' + (value || 0)
						} else {
							if (value == null || typeof value === 'undefined') {
								value = ''
							} else if (item.inputType === 'date' && item.inputOption.dateType === 'time') {
								if (value) {
									value = '0000-01-01 ' + value
								}
							} else if (typeof(value) === 'string') {
								value = value.replace(/\'/g, "\'")
							}
							js = js + "," + headName + "='" + value + "'"
						}

					}

					js = 'let ' + js.substring(1) + ';'

					for (let key in col.actionCols) { //处理所有关联计算的列						 

						let linkCol = cols[key]
						if (linkCol && linkCol.inputType === 'express') {
							let check = true
							let expressVars = linkCol.inputOption.express.vars
							for (let head in expressVars) { //检查所有参与运算的变量是否都有值
								let varCol = cols[head]
								let value = row[varCol.dataName]
								if (typeof value === 'undefined' || value === null || value === '') {
									check = false
									break
								}
							}
							if (!check && linkCol.inputOption.express.empty == 'n') { //变量数据不完整取消计算
								row[linkCol.dataName] = null
								//console.log('空值不计算')
								continue
							}


							let rs = null
							try {
								let express = js + 'rs=' + linkCol.inputOption.express.content + ';'
								eval(express)
								//console.log(rs, Number.isNaN(rs), express)
								/*
								console.log(linkCol.decimal)
								rs = Bolanshi.getNiBolanshiValue(linkCol.inputOption.express.bolanshi, vars, linkCol
									.decimal) */
							} catch (ex) {
								this.$logic.tip.error('公式[' + linkCol.inputOption.express.content + ']计算错误：' + ex.message)
								throw ex
								//if (ex.message.indexOf('空值') < 0) { //忽略空值

								//}
							}
							row[linkCol.dataName] = Number.isNaN(rs) ? null : rs

							this.numCellFormat(row, linkCol)


							//console.log(linkCol.inputOption.express.bolanshi, linkCol.headName, row[linkCol.headName])

						}

					}
				}
			},
			inputFocus(type, i, j, row, col) {
				if (type == 'text' || type == 'num' || type == 'area' || type == 'select') {
					if (col.inputOption.default) {
						if (row[col.dataName]) {} else {
							row[col.dataName] = col.inputOption.default

						}
					}
				} else if (type == 'date') {
					if (row[col.dataName]) { //已有值更新日期组件为当前值
						if (col.inputOption.dateType == 'time') { //时间型的构造一个时间对象值
							this.timeValue = '2020-01-01 ' + row[col.dataName]
						} else {
							this.dateTimeValue = row[col.dataName]
						}

					} else { //没有值设置当前值
						if (col.inputOption.dateInit == 'current') {
							let dt = new Date()
							switch (col.inputOption.dateType) {
								case 'datetime':
									row[col.dataName] = this.toDateTimeString(dt)
									this.dateTimeValue = dt
									break
								case 'date':
									row[col.dataName] = this.toDateString(dt)
									this.dateTimeValue = dt
									break
								case 'time':
									row[col.dataName] = this.toTimeString(dt)
									this.timeValue = dt
									break
							}
						} else {
							if (col.inputOption.dateType == 'time') {
								if (col.inputOption.default) {
									row[col.dataName] = col.inputOption.default
									this.timeValue = '2000-01-01 ' + col.inputOption.default
								} else {
									//this.timeValue = null
								}

							} else {
								if (col.inputOption.default) {
									row[col.dataName] = col.inputOption.default
									this.dateTimeValue = col.inputOption.default
								} else {
									//this.dateTimeValue = null
								}

							}

						}
					}
				}

				this.tick = new Date().getTime()
				//this.$refs['c_' + i + '_' + j].value = row[col.dataName] //vue不能自动更新，手动更新页面数据

			},
			numCellFormat(row, col) { //if (typeof(rs) === 'number') {}增加此判断
				let dataName = col.dataName
				if (row[dataName] && col.inputOption.numAction == 'int') {
					row[dataName] = parseInt(row[dataName])
				} else if (row[dataName] && col.inputOption.numAction == 'num') {
					let dc = parseInt(col.inputOption.decimal)
					if (dc < 0) {
						dc = 0
					}
					let scal = Math.pow(10, dc)
					//console.log(row[dataName],dc,scal)
					let num = Math.round(row[dataName] * scal) / scal
					let ns = '' + num
					row[dataName] = ns
					/* 	let x = ns.indexOf('.')
						if (x > -1) {
							ns = ns + '00000000000000000000000'
							row[dataName] = ns.substring(0, x + dc + 1)
						} else {
							row[dataName] = ns + '.' + '00000000000000000000000'.substring(0, dc)
						} */
				}
			},
			inputBlur(type, i, j, row, col) {
				//console.log(Math.round([9.5]))
				let dataName = col.dataName
				if (type == 'text') {
					if (row[dataName] && col.inputOption.textAction.indexOf('trim') > -1) {
						row[dataName] = row[dataName].trim()
					}
					if (row[dataName] && col.inputOption.textAction.indexOf('low') > -1) {
						row[dataName] = row[dataName].toLowerCase()
					}
					if (row[dataName] && col.inputOption.textAction.indexOf('up') > -1) {
						row[dataName] = row[dataName].toUpperCase()
					}
				} else if (type == 'num') {
					this.numCellFormat(row, col)
				} else if (type == 'xxxx') {


				}
				let value = row[dataName]
				let jcol = col
				if (!this.isedit) { //可在计算前进行数据处理					
					let rs = this.doEvent({
						eventName: 'blurbefore',
						headName: col.headName,
						dataRow: row,
						dataName: dataName,
						rows: this.dataRows,
					}, '$comp_blurBefore')
					if (rs) { //如返回列名，设置为此列执行公式计算更新
						for (let item of this.model.items) {
							if (rs === item.headName) {
								value = row[item.dataName]
								jcol = item
								break
							}
						}
					}
				}
				this.autoUpdate(value, row, jcol, i, j)
				this.colTotal()
				if (!this.isedit) { //编辑模式不汇总，以防产生数据保存在配置中

					this.doEvent({
						eventName: 'blurafter',
						headName: col.headName,
						dataRow: row,
						dataName: dataName,
						rows: this.dataRows,
					}, '$comp_blurAfter')
				}

				this.tick = new Date().getTime()
				//this.$refs['c_' + i + '_' + j].value = row[col.dataName]


			},
			setDateTime(value, row, col, i, j) {
				if (col.inputOption.dateType == 'time') {
					row[col.dataName] = this.toTimeString(value)
				} else if (col.inputOption.dateType == 'date') {
					row[col.dataName] = this.toDateString(value)
				} else {
					row[col.dataName] = this.toDateTimeString(value)
				}
				this.autoUpdate(row[col.dataName], row, col, i, j)
				this.colTotal()
				this.cellClick(-1, -1, col)
			},
			cellClick(i, j, col) {
				/* if(this.rowIndex === i && this.colIndex === j && col.inputType=='load'){//当前单元格点击不作处理
					return
				} */
				if (i >= 0) {
					this.rowIndex = i
				}
				if (j >= 0) {
					this.colIndex = j
				}
				//console.log(j)
				if (col.inputType == 'load') {

					setTimeout(() => { //等页面元素生成后设置焦点
						this.loadTick = new Date().getTime()
					}, 50)

				} else {
					let ref = this.$refs['c_' + this.rowIndex + '_' + this.colIndex]
					if (ref) {
						setTimeout(() => { //等页面元素生成后设置焦点
							ref.focus()
						}, 50)

					}
				}
			},
			gotoCell(event, rowI, colJ, col) {
				let i = rowI
				let j = colJ
				let isArrow = false
				switch (event.key) {
					case 'ArrowLeft':
						isArrow = true
						j--
						if (j < 0) {
							return
						}
						break
					case 'ArrowRight':
						isArrow = true
						j++
						if (j >= this.model.items.length) {
							return
						}
						break
					case 'ArrowUp':
						isArrow = true
						i--
						if (i < 0) {
							return
						}
						break
					case 'ArrowDown':
						isArrow = true
						i++
						if (i >= this.dataRows.length) {
							return
						}
						break
					case 'Enter': //回车键作换行处理,下移一行并焦点到第一列
						i++ //始终到下一行
						switch (this.model.enterType) {
							case 'tonext':
							case 'tofirst':
								if (i >= this.dataRows.length) {
									return
								} else {
									if (this.model.enterType === 'tofirst') { //下移并到第一列
										j = this.firstEditColIndex
									}
								}
								break
							case 'addnext':
								this.addDataRow('add', i)
								j = this.firstEditColIndex
							case 'addlast':
								if (i >= this.dataRows.length) { //最后一行的情况下，插入一行
									this.addDataRow('add')
									j = this.firstEditColIndex
							
								}
								break

						}

						break;
					default:
						return
				}
				let targCol = this.model.items[j]
				if (targCol && targCol.dataName) {
					this.$nextTick(() => { //兼容 新创建的行上
						this.cellClick(i, j, targCol)
					})

				}

				if (isArrow || col.inputType == 'select') { //阻止下拉框的默认行为
					event.preventDefault();
					event.stopPropagation();
				}




			},
			headClick(col) {
				if (!this.isedit) {
					return
				}
				this.setCurrentElement(col)
			},
			getColName(x) { //0起 25:Z,26:AA
				let n1 = parseInt(x / 26)
				if (n1 > 0) {
					return String.fromCharCode(64 + n1) + String.fromCharCode(65 + (x % 26))
				} else {
					return String.fromCharCode(65 + (x % 26))
				}


			},
			addNewColnum() {
				let config = this.elementConfig
				let head = config.create('inputhead', this.model, this.page.formData)
				head.headName = this.getColName(this.maxColId++)
				head.headText = ''
				let foot = config.create('listfoot', head, this.page.formData)
				head.foot = foot
			},

			addDataRow(type, index = -1, isAsk = true) {
				let row = null
				if (type == 'del') {
					//检测此行是空行直接删除，否则询问
					let rowData = this.dataRows[index]
					let isEmpty = true
					if (isAsk) {
						for (let col of this.model.items) {
							let value = rowData[col.dataName]
							if (value) {
								isEmpty = false
								break
							}
						}
					}

					if (isEmpty) {
						row = this.dataRows.splice(index, 1)
						if (!this.isedit) {
							this.colTotal()
							this.doEvent({
								eventName: 'rowchange',
								opration: 'delete',
								dataRow: rowData,
								rows: this.dataRows,
								index: index
							}, '$comp_rowChange')
						}
					} else {
						this.$logic.message.confirm('删除确认', '是否确定删除第' + (index + 1) + '行', () => {

							row = this.dataRows.splice(index, 1)[0] //返回的是数组

							if (row.dataId$ && row.dataId$ > 0) { //有效ID加入删除数据集合
								this.deleteList.push(row.dataId$)
							}
							if (!this.isedit) {
								this.colTotal()
								this.doEvent({
									eventName: 'rowchange',
									opration: 'delete',
									dataRow: rowData,
									rows: this.dataRows,
									index: index
								}, '$comp_rowChange')
							}
						})
					}


				} else if (type == 'add') {
					row = {
						dataId$: 0,
						hide$: false
					} //此属性值提交时作为该数据表中的的Id字段值
					for (let col of this.model.items) {
						if (col.inputType == 'date') {
							if (col.inputOption.dateInit == 'current') {
								let time = new Date()
								if (col.inputOption.dateType == 'datetime') {
									row[col.dataName] = this.$logic.date.toDateTimeString(time)
								} else if (col.inputOption.dateType == 'date') {
									row[col.dataName] = this.$logic.date.toDateString(time)
								} else if (col.inputOption.dateType == 'time') {
									row[col.dataName] = this.$logic.date.toTimeString(time)
								}
							} else {
								row[col.dataName] = col.inputOption.default
							}

						} else {
							if (col.dataName) {
								row[col.dataName] = col.inputOption.default
							}
						}

					}
					if (index > -1) {
						this.dataRows.splice(index, 0, row)
					} else {
						index = this.dataRows.length
						this.dataRows.push(row)
					}
					if (!this.isedit) {
						this.colTotal()
						this.doEvent({
							eventName: 'rowchange',
							opration: 'add',
							dataRow: row,
							rows: this.dataRows,
							index: index
						}, '$comp_rowChange')
					} else {
						this.model.initRows = this.dataRows.length
					}

					return row
				}



			},
			deleteRow(index) { //值为-1全部删除，值为0删除指定行
				if (index > -1) {
					this.addDataRow('del', index, false)
				} else {
					this.dataRows = []
					if (!this.isedit) {
						this.colTotal()
						this.doEvent({
							eventName: 'rowchange',
							opration: 'clear',
							dataRow: null,
							rows: this.dataRows,
							index: index
						}, '$comp_rowChange')
					}
				}


			},
			getList() {
				return this.dataRows
			},
			initDataRows() {
				//console.log(this.model.listData)
				/* 		if (this.model.listData == null) { //空数据时生成默认行，0行数据为赋值数据保持状态
							for (let i = 0; i < this.model.initRows; i++) {
								this.addDataRow('add')
							}
						} else {
							//this.dataRows = this.model.listData
							
						} */
				let size = this.model.initRows
				for (let i = 0; i < size; i++) {
					this.addDataRow('add')
				}
				//console.log(size, this.dataRows)
			},
			footSplit(split, foot) {
				if (split) { //拆散
					let find = false
					for (let item of this.model.items) { //从找到的此列起，所有后面所有非显示的恢复，直到遇到第一个为显示状态的为止
						if (item.foot === foot) {
							item.foot.show = 'show'
							find = true
							continue
						}
						if (find) {
							if (item.foot.show == 'show') {
								return
							} else {
								item.foot.show = 'show'
							}
						}
					}
				} else {
					foot.show = 'none'
				}
			},
			getFootStyle(foot) {
				let css = {}
				let configs = this.elementConfig.objs[foot.type].configs
				for (let item of configs) {
					if (item.target) {
						css[item.target] = foot[item.key]
					}
				}
				if (this.currentElement === foot) {
					css.backgroundColor = '#FF4500'
					css.color = '#ffffff'
				}
				return css
			},
			$value(value) {
				let model = this.model
				if (typeof(value) === 'undefined') { //读取值
					return this.dataRows
				} else {
					this.doEvent({
						eventName: 'loadbefore',
						rows: value,
					}, '$comp_loadBefore')
					this.dataRows = value
					for (let row of this.dataRows) {
						if (!('dataId$' in row)) {
							row.dataId$ = 0
							row.hide$ = false
						}
					}
					this.doEvent({
						eventName: 'loadafter',
						rows: this.dataRows,
					}, '$comp_loadAfter')
				}

			},
			$load(formId) {
				this.loadList(formId)
			},
			loadList(formId) {
				this.deleteList = [] //先清除删除列表集合
				let model = this.model
				if (!model.dbTable) {
					this.$logic.message.message('错误', model.name + '数据库表名未设置')
					return
				}
				if (!model.idParam) {
					this.$logic.message.message('错误', model.name + '的父级ID参数名未设置')
					return
				}
				if (!(model.idParam in this.page.$params)) {
					this.$logic.message.message('错误', model.name + '未获取到父表ID值的参数值，请确认该页面地址参数名与设置值[' + model
						.idParam +
						']一致')
					return
				}
				if (!model.pidField) {
					this.$logic.message.message('错误', model.name + '的父表ID参数名未设置')
					return
				}
				let pid = this.page.$params[model.idParam]
				pid = formId || pid

				if (parseInt(pid) < 1) { //无效ID值不查询数据
					return
				}
				this.doEvent({
					eventName: 'loadbefore',
					pid: pid
				}, '$comp_loadBefore')

				let params = []
				let sql = this.doEvent({
					eventName: 'sql',
					pId: pid
				}, '$comp_sql')
				if (!(sql && sql.length > 10)) {
					sql = "select * from " + model.dbTable + ' where ' + model.pidField.fieldName +
						'=? order by ' + model
						.idField.fieldName
					params.push(pid)
				}

				this.$logic.http.sqlQuery(this.project.dataSource.id, sql, params, {
					isJson: true
				}).then(res => {
					let rows = res.data.data.dataList
					for (let row of rows) {
						row.hide$ = false
						row.dataId$ = row[model.idField.fieldName]
						if (row.dataId$ == null) { //加载的数据一定要有值
							this.$logic.tip.error('无效的ID数据，请确定ID字段设置的正确性，大小写一致')
							return
						}
					}
					this.dataRows = rows
					this.colTotal()

					this.dataTo(this.model.dataTo, rows)

					this.doEvent({
						eventName: 'loadafter',
						cols: this.items,
						rows: this.dataRows,
					}, '$comp_loadAfter')
				}).catch(err => {
					this.$logic.tip.error('数据请求异常：' + err.info)
				})


			},
			validate() {
				let check = true
				let rowIdx = 1
				let ck = {
					success: true,
					info: ''
				}
				for (let row of this.dataRows) {
					for (let col of this.model.items) {
						let trigers = col.validateSet.triggers
						if (trigers == null || trigers.length < 1) {
							continue
						}
						ck = this.valueValidate(this.model.name + '第' + rowIdx + '行 ' + col.headText + '列', row[col
								.dataName], col
							.validateRule)
						if (!ck.success) {
							check = false
							break
						}
					}
					if (!check) {
						this.tipContent = ck.info
						this.tipColor = 'color:#ff0000'
						this.tipShow = true
						break
					}
					rowIdx++
				}
				return ck
			},
			getSourceId(sourceId) {
				return sourceId == 0 ? this.project.dataSource.id : sourceId

			},
			tipHide() {
				this.tipShow = false
			},
			getDeleteList() {
				return this.deleteList
			}



		},
		computed: {
			firstEditColIndex() {
				let idx = 0
				for (let i = 0; i < this.model.items.length; i++) {
					let head = this.model.items[i]
					if (head.status === 'edit') {
						idx = i
						break
					}
				}
				return idx
			},
			footColList() {
				//从右往左推,不显示的列合并到左侧第一个显示的列上
				let foots = []
				let span = 1
				for (let i = this.model.items.length - 1; i > -1; i--) {
					let col = this.model.items[i]
					let foot = col.foot
					if (col.show == 'show' && (foot.show == 'show' || i == 0)) { //第一列必须为可见
						foots.splice(0, 0, {
							foot: foot,
							span: span
						})
						span = 1
					} else {
						if (col.show == 'show') { //可见列才累计跨列个数
							span++
						}

					}
				}
				return foots
			},

			tableStyle() {
				let css = {
					...this.compStyle
				}
				let tbWidth = 0
				for (let col of this.model.items) {
					let width = col.headWidth || col.minWidth || '100px'
					if (this.isedit || col.show == 'show') { //如果显示状态加宽度
						tbWidth = tbWidth + parseInt(width)
					}

				}
				tbWidth = tbWidth + 100 //加头尾两列宽度+余量
				css.minWidth = 'calc(100% - 2px)'
				//css.width = tbWidth + 'px' //加了表格宽度后会导致page容器出现滚动条
				delete css.height
				delete css.minHeight
				delete css.overflowX
				delete css.overflowY
				return css
			}
		},
		watch: {
			'model.listData': {
				handler: function(newValue, oldValue) {
					this.initDataRows()
				}
			}
		},

		created() {
			this.maxColId = this.model.items.length
			this.initDataRows()
			if (!this.isedit) {
				this.model.$tipHide = this.tipHide
				this.model.$value = this.$value
				this.model.$validate = this.validate
				this.model.$load = this.$load
				this.model.$total = this.colTotal
				this.model.$getList = this.getList
				this.model.$getDeleteList = this.getDeleteList
				this.model.$deleteRow = this.deleteRow
				this.model.$addRow = (index = -1) => {
					return this.addDataRow('add', index)
				}
				let info = ''
				for (let col of this.model.items) { //清除设计时的数据
					col.foot.total = null
					if (col.fieldName.fieldName != null && col.fieldName.fieldName.length > 0 && col.fieldName
						.fieldName !=
						col.dataName) {
						info = info + col.headText + '列中设置的数据名：' + col.dataName + '与数据表字段：' + col.fieldName.fieldName +
							'不一致'
					}
					//设置统计数值的联动目标
					let st = col.foot.setTo
					if (st) {
						/* if (!st.startsWith('#')) {
							st = '#' + st
						} */
						//let el = this.page.$(st)
						let el = this.$logic.pageUtil.getElementById(this.page.formData, st)
						if (el) {
							col.foot.setObj = el
						} else {
							this.$logic.tip.error('子表编辑器的汇总列[' + col.headText + ']不存在联动赋值对象:' + st)
						}

					}
					//设置动态SQL联动加载
					if (col.inputType == 'select' && col.inputOption && col.inputOption.jsonArray.load == 'Y') {
						col.$dataLoad = () => { //调用父组件的方法加载数据，在BaseElement中
							this.model.$dataLoad(null, (dataList) => {
								this.doEvent({
									eventName: 'listLoadAfter',
									colId: col.id,
									list: dataList,
								}, '$comp_listLoadAfter')
								col.inputOption.jsonArray.list = dataList
							}, col)
						}
						col.$dataLoad()
					}


				}
				if (info.length > 0) {
					this.$logic.message.message('确认', info)
				}
				this.loadList()
			}
		},
		components: {
			treeviewdialog: TreeViewDialog,
			treelistdialog: TreeListDialog
		},
	}
</script>

<style scoped>
	.tabletop {
		position: sticky;
		position: -webkit-sticky;
		top: 0px;
		background-color: #e7e7e7;
		z-index: 100;
	}

	.tabletop>td {
		height: var(--head-height);
		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
		border-left: solid 1px #CCCCCC;
		cursor: pointer;

	}

	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.current-col {
		background: linear-gradient(#ffdddd, #FF4500, #ffdddd);
		color: #ffffff;
	}

	.tablelist {
		min-width: 100%;
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: #cccccc;
	}

	.tdhead {
		text-align: center;
		background-color: #eaeaea;

		border-left: solid 1px #EEEEEE;
		border-top: solid 1px #EEEEEE;
		border-right: solid 1px #CCCCCC;
		border-bottom: solid 1px #CCCCCC;

	}

	.colfirst {
		position: sticky;
		position: -webkit-sticky;
		left: 0px;
		width: 50px;
		min-width: 50px;
		box-shadow: 1px 0px #dfdfdf;
		--data-down: none;
	}

	.colfirst:hover {
		--data-down: inline;
	}

	.colfirst>i {
		margin-left: 5px;
		font-size: 16px;
		cursor: pointer;
	}

	.col-last {
		width: 50px;
		min-width: 50px;
		box-shadow: 1px 0px #dfdfdf;
	}

	.headcell {
		position: relative;
		width: 100%;
		height: 100%;
		/* display: flex;
		justify-content: space-between;
		align-items: center; */
		text-align: center;

	}

	.coltool {
		width: calc(100% - 10px);
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		justify-content: space-between;

	}

	.colbutton {
		color: #a6a9b0;
		cursor: pointer;
		/* 	background-color: #f1f1f1; */
	}

	.colbutton:hover {
		color: var(--el-color-primary);
	}


	.buttonwarning:hover {
		color: red;
	}

	.trbody {
		height: var(--row-height);
	}

	.trbody:nth-child(odd) {
		background-color: var(--line-color);
	}

	.trbody:hover {
		background-color: var(--hover-color);
	}


	.cell-text {
		/* font-size: 14px; */
		width: calc(100% - 6px);
		height: calc(var(--row-height) - 6px);
		outline: none;
		/* border: solid 1px #cccccc;
		border-radius: 4px; */
		border: 0px;
		background-color: rgba(255, 255, 255, 0.5);

		/*  */
		/* direction: rtl; */
	}

	/*  去掉数据输入框的小下箭头
	.cell-text::-webkit-outer-spin-button,
	.cell-text::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
 */
	.cell-text:focus {
		border: solid 1px #409eff;
	}

	.input-left {}

	.input-right {
		text-align: right;
	}

	.input-center {
		text-align: center;
	}

	.cell-select {
		width: calc(100% - 6px);
		height: calc(var(--row-height) - 6px);
		max-height: 25px;
		border: solid 1px #cccccc;
		background-color: rgba(255, 255, 255, 0.5);
		color: #606266;
		outline: none;
	}

	.cell-select:focus {
		border: solid 1px #409eff;
	}

	.cell-date {
		display: flex;
		align-items: center;
		width: calc(100% - 6px);
		height: calc(var(--row-height) - 6px);
		max-height: 25px;
		border: solid 1px #cccccc;
		background-color: rgba(255, 255, 255, 0.5);
		color: #CCCCCC;
		outline: none;
	}

	.cell-date:focus {
		border: solid 1px #409eff;
	}

	.cell-date-hide {
		width: 1px;
		overflow: hidden;
	}

	/* 	.cell-date-hide>div {
		display: none;
	} */

	.cell-date-panel {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.cell-date-icon {
		width: 20px;
		height: 22px;
		color: #909399;
		text-align: center;
	}

	.cell-date-input {
		width: calc(100% - 6px - 22px);
		height: 22px;
		outline: none;
		border: 0px;
		color: #606266;
		background-color: rgba(255, 255, 255, 0.5);

	}

	.cell-date-input:focus {
		color: #409eff;
	}

	.cell-tree {
		text-align: left;
		direction: rtl;
	}

	.foot-tr {
		background-color: #f0f0f0;
	}

	.foot-cell {
		--button-show: none;
	}

	.foot-cell:hover {
		--button-show: visible;
	}

	.foot-button {
		cursor: pointer;
		color: #606266;
		margin: 0px 5px;
		display: var(--button-show);
	}

	.cell-span-left {
		margin-left: 5px;
	}

	.cell-span-right {
		margin-right: 5px;
	}

	.foot-total {
		margin: 0px 5px;
		color: var(--total-font-color);
		font-size: var(--total-font-size);
	}

	.row-edit-label {
		padding: 2px;
	}
</style>