import compBase from '../../public/comp.js'
import options from '../../public/options.js'

let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
validate.validateSet.exclude='input,blur'


let base=[
	{key:'title',text:'万能扫码'},
	{key: 'scanType',text: '扫码类型',type: 'select',init: 'qr',target: '',	bursh: true,bind: true,tip: '',list: options.toOptions('条形码=bar,二维码=qr')},//,全部=all
	{key: 'scanLoop',text: '连续扫码',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '如果本选项开启且扫码事件函数中返回true，将继续开启扫码，直到调用用户事件未返回true或用户关闭后终止',list: null},
	
//	{key:'value',text:'内容',type:'area',init:'',target:'',bursh:true,bind:true,tip:'',list:null},	 

// 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
//	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'dataTo',text: '组件传值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '扫描结果给其它组件传值，例如向另一个文本框传值，扫描完成后触发，多组件ID请用逗号分隔，格式：#1,#2,#3',list: null},
	 
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 
   // {key:'minW',text:'最小宽度',type:'text',init:'100px',target:'minWidth',	bursh: true,bind: true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
	//{key: 'length',text: '限定字数',type: 'number',init: '0',	target: '',	bursh: true,bind: false,tip: '',list: null},
    {key: 'showInput',text: '显示输入框',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: null},

    {key: 'showDevice',text: '设备提示',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'title',text: '条形码扫描类型(建议只选一种)'	},
    {key: 'code_128',text: 'Code128',type: 'switch',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'ean',text: 'Ean',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'ean_8',text: 'Ean8',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'code_39',text: 'Code39',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'code_39_vin',text: 'Code39vin',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'codabar',text: 'Codabar',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'upc',text: 'Upc',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'upc_e',text: 'Upc_e',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'i2of5',text: 'I2of5',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: '2of5',text: '2of5',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},
   {key: 'code_93',text: 'Code93',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: null},

	 
	
	
	{key: 'title',text: '按钮样式'	},
	{key: 'label',text: '文本',type: 'text',init: '扫描',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'ico',text: '图标',type: 'icona',init: 'fal fa-expand-wide',	target: '',	bursh: true,bind: true,tip: '',list: null},
    compBase.color,
	{key: 'loading',text: '加载中状态',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	


 
].concat(compBase.base)
let events=[
	{key: '$comp_scanAfter',text: '扫描成功后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '扫描成功后触发，如返回true(函数内如有网络访问，请使用同步方法)且开启了连续扫描将继续扫描，且再次调用此方法',list: null	},
	
]
let qrread= {
	data:{
		type:'qrread',//isInput:'S'
	},
	delete:'',
	hide:'width,'+compBase.HideFields,
	base:base ,
	special :[],
	action:events.concat(options.getEvents(null,'$hook'))
}

export default qrread