import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import layout from '../../public/layout.js'
import extend from '../../public/extend.js'
let inputTypes=compBase.toOptions('文本=text,数字=number,密码=password,电子邮箱=email,网址=url')
let boderStyles= options.getConfigs(layout,'backColor,opacity,borderColor,borderS,borderL,borderT,borderR,borderB,borderRadius')
//dataName指向属性名，fieldName字段名，unionType,refuseNull 这三个从数据表对话框中回写; check多值类型暂时以逗号隔的字符串返回，后期可扩展增加设置返回值方式，数组还是逗号隔的字符串
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate() 
let base = [
	{key: 'title',text: '原生输入框'	},
	{key: 'inputType',text: '输入类型',type: 'select',init: 'text',	target: '',	bursh: true,bind: false,tip: '',list: inputTypes},
	{key: 'value',text: '文本内容',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '此属性用于初始化组件值，运行时获取实际内容请通过$value函数',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},
	 
    {key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 
    {key:'height',text:'高度',type:'text',init:'20px',target:'height',	bursh: true,bind: true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
  ].concat(boderStyles,).concat(  
  [ 
	{key:'bgColorF',text:'焦点背景色',type:'colorA',init:'rgba(255, 255, 255, 1)',target:'--input-text-bgcolorf',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},	 
    {key:'bdColorF',text:'焦点边框色',type:'color',init:'#0086B3',target:'--input-text-bdcolorf',	bursh: true,bind: true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},

     {key: 'tip',text: '提示信息',type: 'text',init: '请输入',	target: '',	bursh: true,bind: true,tip: '',list: null},
 	 compBase.status,      
])
	 
let input = {
	data: {
		type: 'input',isInput:'S',backColor:'rgba(255, 255, 255, 1)',borderColor:'#dcdfe6',borderRadius:'4px',borderL:'1px',borderT:'1px',borderR:'1px',borderB:'1px',marginL:'2px',marginR:'0px'
	},
	delete:'', //'fontName,fontColor,fontBold,fontItalic,fontHeight',
	hide: ''+compBase.HideFields,
	base: base.concat(font).concat(compBase.base),
	special: extend.tip.concat(extend.menu).concat(extend.badge),
	action:  options.getEvents(null,'$event','$event_mousemove,$event_mouseover,$event_scroll').concat([compBase.menuClick]).concat([
		//options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default input
