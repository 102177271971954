import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'

let datas =JSON.stringify( [
		{key:'home',label:'首页',icon:'far fa-home-lg-alt',color:null,url:'',target:'',param:'' },
		{key:'bi',label:'BI中心',icon:'far fa-analytics',color:null,url:'',target:'' ,param:''},
		{key:'query',label:'统计报表',icon:'far fa-file-chart-line',color:null,url:'',target:'',param:'' },
	 	 
])

let base = [
{key: 'title',text: '菜单'	},
{key:'width',text:'宽度',type:'text',init:'100%',target:'width',bursh:true,bind:true,tip:'象素、百分比、函数类型',list:null},	 
{key:'height',text:'高度',type:'text',init:'40px',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'split',text:'分隔符',type:'text',init:'/',target:'',tip:'',bursh:true,bind:true,list:null},	
{key:'margin',text:'分隔间距',type:'text',init:'10px',target:'--bread-margin',tip:'',bursh:true,bind:true,list:null},	

{key: 'showIcon',text: '显示图标',type: 'select',init: 'Y',	target: '',bursh:true,bind:true,tip: '',list: options.YON},	


]
 
  
let bread = {
	data: {
		type: 'bread',fontColor:'#404040'
	},
	delete: '',
	hide: 'datas',
	base: base.concat(font),
	special: [	{key: 'jsonData',text: 'JSON数据',type: 'json',init: datas,	target: '',bursh:false,bind:false,tip: '',treeMap:{id:'key',label:'label',icon:'icon'},dataMap: {key:'唯一标识',label:'标题文本',icon:'图标',color:'颜色',target:'链接目标',url:'链接地址',param:'参数'}},],
	action: []
}

export default bread
