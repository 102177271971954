<template>
	<div :style="compStyle" class="select-container">
		<el-select style="width:100%;" v-model="model.orgId" @click.stop="mouseClick" @change="orgChange"
			:size="model.size" :multiple="false" :disabled="model.status=='disabled'" :placeholder="model.tip">
			<template v-for="(item,index) in orgList" :key="item.value">
				<el-option :label="item.label" :value="item.value">
				</el-option>
			</template>

		</el-select>
		<div class="select-text">
			<el-input v-model="model.orgText" style="width:100%" :size="model.size" readonly
				:disabled="model.disabled=='Y'" placeholder="" />
		</div>
	</div>


</template>

<script>
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				orgList: []
			}
		},

		methods: {
			orgChange(nv) {
				this.model.orgText = ''
				for (let item of this.orgList) {
					if (nv == item.value) {
						this.model.orgText = item.label 
						break
					}
				}
				this.linkUpdate('orgText')
				this.linkUpdate('orgId')
				this.onChange(nv)
			},
			flushOrgList() {
				let list = []
				let orgMap = {} //过滤重复数据
				for (let item of this.project.orgUsers) { // userOrgList	废弃			
					if (item.userId == this.model.userId) { //this.project.orgList中ID是字符类型，数值需转
						let org = this.$logic.arrayUtil.itemByKey(this.project.orgList, 'id', '' + item.orgId)

						if (org && !orgMap[org.id]) {
							let option = {
								label: org.label,
								value: org.id
							}
							if (this.model.format === 'path') {
								let path = this.project.orgTree.getPath(item.orgId)
								if (path) {
									option.label = path
								}
							}
							list.push(option)
							orgMap[org.id] = true
						}
					}
				}
				this.orgList = list
			}

		},
		watch: {
			'model.userId'(newValue, oldValue) {

				this.flushOrgList()
				let check = false
				for (let item of this.orgList) { //如果当前选项存在于列表中保留值，否则清空当前值
					if (item.value == this.model.orgId) {
						check = true
						break
					}
				}
				if (!check) {
					if (this.orgList.length == 1) { //如果只有唯一的选项，设置默认值为此选项，否则需要手工选择
						this.model.orgId = this.orgList[0].value
						this.model.orgText = this.orgList[0].label
					} else {
						this.model.orgId = null
						this.model.orgText = ''
					}
					this.linkUpdate('orgText')
					this.linkUpdate('orgId')

				}
			}
		},

		computed: {

		},
		created() {
			if (!this.isedit) {
				this.flushOrgList()
			}
			//this.model.userId = '3'
			//console.log(this.project)

		}
	}
</script>

<style scoped>
	.select-container {
		position: relative;
	}

	.select-text {
		position: absolute;
		left: 0px;
		top: 0px;
		width: calc(100% - 32px);
	}
</style>