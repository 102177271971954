import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'
let fits=[{label:'全图自由缩放',value:'fill'},{label:'全图等比缩放',value:'contain'},{label:'等比缩放填充',value:'cover'},{label:'原始大小超出剪裁',value:'none'},{label:'向下拉伸',value:'scale-down'},]
 
let base = [
	{key: 'title',text: '图片'	},
	//compBase.mode,
	{key: 'value',text: '图片地址',type: 'file',init: '/img/filetypes/bmp.png',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'width',text: '宽度',type: 'text',init: '120px',	target: 'width',bursh: true,bind: true,tip: '',list: null},
	{key: 'height',text: '高度',type: 'text',init: '100px',	target: 'height',bursh: true,bind: true,tip: '',list: null},
	{key: 'fit',text: '适配方式',type: 'select',init: 'fill',	target: '',bursh: true,bind: true,tip: '',list: fits},
	{key: 'view',text: '开启预览',type: 'select',init: 'Y',	target: '',bursh: true,bind: true,tip: '',list: options.YON},
 	{key: 'rotate',text: '开启旋转N',type: 'select',init: '',	target: '',bursh: true,bind: true,tip: '不旋转=,顺时针旋转=rotate-forward,逆时针旋转=rotate-reverse',list: options.rotateTypes},
  	{key: 'seconds',text: '转速(秒)',type: 'number',init: '4',	target: '',bursh: true,bind: true,tip: '',list: null},
  
	{key:'borderS',text:'边框线型',type:'select',init:'none',target:'borderStyle',bursh:true,bind:true,tip:'边框线粗，象素',list:options.BorderType},
    {key:'borderL',text:'左边框',type:'text',init:'1px',target:'borderLeftWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderT',text:'上边框',type:'text',init:'1px',target:'borderTopWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderR',text:'右边框',type:'text',init:'1px',target:'borderRightWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderB',text:'下边框',type:'text',init:'1px',target:'borderBottomWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
    {key:'borderColor',text:'边框颜色',type:'color',init:'#cccccc',target:'borderColor',bursh:true,bind:true,tip:'边框颜色',list:null},	 
    {key:'borderRadius',text:'圆角大小',type:'text',init:'0px',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比，可通过组合方式分别设置上下左右圆角，宽高相等的容器50%时为正圆',list:null},	 
    {key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
    
]

let img = {
	data: {
		type: 'image',
	},
	delete: '',
	hide: '',
	base: base.concat(compBase.base),
	special: extend.badge.concat(extend.menu).concat(extend.tip),
	action:  options.getCommonEvents().concat([compBase.menuClick]).concat([
	//	options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(extend.dialog).concat(options.getEvents(null,'$hook'))
}

export default img
