import options from '../../../../../public/options.js'
import gridConfig from '../gridConfig.js'
let base = gridConfig.base
let special = [
	 {key:'title',text:'柱条系列'},
	 {key:'seriesType',text:'图形类型',type:'text',init:'bar',path:'_series.type',bursh:true,bind:true,tip:'',list:null},
	 
	 {key:'seriesBarWidth',text:'柱条宽度',type:'text',init:null,path:'_series.barWidth',bursh:true,bind:true,tip:'可指定柱条宽度，直接输入数值为象素值，支持百分比如10%',list:null},
	 {key:'seriesBarborderRadius',text:'柱条圆角',type:'text',init:1000,path:'_series.itemStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarborderWidth',text:'描边粗细',type:'number',init:1,path:'_series.itemStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarborderColor',text:'描边颜色',type:'color',init:null,path:'_series.itemStyle.borderColor',bursh:true,bind:true,tip:'',list:null},

	 {key:'seriesBarLabelShow',text:'显示标签',type:'switch',init:'N',path:'_series.label.show',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBarLabelColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'_series.label.color',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBarLabelRotate',text:'标签角度',type:'number',init:0,path:'_series.label.rotate',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBarLabelPosition',text:'标签位置',type:'select',init:'top',path:'_series.label.position',bursh:true,bind:true,tip:'',list:options.toOptions('顶部=top,中间=inside,底部=insideBottom')},

	 {key:'seriesBarStack',text:'柱条堆叠',type:'select',init:'after',bursh:true,bind:true,tip:'',list:options.toOptions('不堆叠=none,全部堆叠=all,第一个之后=after,最后一个之前=befor')},

//	 {key:'seriesBarColorBy',text:'取色方式',type:'select',init:'series',path:'_series.colorBy',bursh:true,bind:true,tip:'',list:options.toOptions('按系列取色=series,按数据项取色=data')},
	 {key:'seriesShowBackground',text:'启用背景色',type:'switch',init:'N',path:'_series.showBackground',bursh:true,bind:true,tip:'',list:options.YON},
	 {key:'seriesBackgroundColor',text:'柱条背景色',type:'colorA',init:'rgba(180, 180, 180, 0.2)',path:'_series.backgroundStyle.color',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderColor',text:'背景描边色',type:'colorA',init:null,path:'_series.backgroundStyle.borderColor',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderType',text:'描边线类型',type:'select',init:'solid',path:'_series.backgroundStyle.borderType',bursh:true,bind:true,tip:'',list:options.ChartLineType},
	 {key:'seriesBackgroundBorderWidth',text:'描边线粗细',type:'number',init:0,path:'_series.backgroundStyle.borderWidth',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesBackgroundBorderRadius',text:'圆角大小',type:'text',init:1000,path:'_series.backgroundStyle.borderRadius',bursh:true,bind:true,tip:'',list:null},
	 {key:'seriesRealtimeSort',text:'实时排序',type:'switch',init:'N',path:'_series.realtimeSort',bursh:true,bind:true,tip:'',list:options.YON},



].concat(gridConfig.special)
let action = gridConfig.action

let actionComp = []


let cfg = {
	data: {
		type: 'chart_barC', color:options.chartColor.bar3,
		chartClan: 'grid',
	},
	delete: '',
	hide: 'dataOption,width,height,seriesType,seriesBarborderRadius,seriesBackgroundBorderRadius',
	base:[].concat(base) ,
	special: special,
	action: gridConfig.action.timer.concat(gridConfig.action.comm)
}
export default cfg
