<template>
	<work-flow v-if="editObj.type ==='flow'" :model="model" :project="project" :page="page" :host="host"
		:isedit="isedit" :datas="datas" />
	<template v-else-if="editObj.type ==='form'">
		<container v-if="editObj.obj && editObj.obj.items" :model="editObj.obj.items[0]" :project="project"
			:page="page" :host="host" :isedit="isedit" :datas="{}" />

	</template>

</template>

<script>
	import workFlow from './WorkFlow.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				editObj: {
					type: 'flow'
				}, //编辑对象
			}
		},

		methods: {
		
		},
		watch: {
			'model.editObj'(nv, ov) {
				let editObj = {
					type: 'flow',
					obj: null
				}
				for (let item of this.model.formList) {
					if (item.id == nv.objId) {
						editObj.type = 'form'
						editObj.obj = item
					}
				}
				this.editObj = editObj
				if (editObj.type === 'form') {
					this.$store.commit('setElement', this.editObj.obj)
				} else {
					this.$store.commit('setElement', this.model)
				} 
			},
		},
		computed: {

		},
		components: {
			workFlow: workFlow
		},
		created() {

		}
	}
</script>

<style scoped>

</style>