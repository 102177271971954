import clone from '../../../../../plugins/deepClone.js'
import options from '../../public/options.js'
import treeDiaolog from './TreeListDialog.js'
let userDiaolog = clone.deepClone(treeDiaolog)

userDiaolog.data.type = 'userdialog'
userDiaolog.hide = userDiaolog.hide + ',listData'
let rowIdx=3
/*for (let item of userDiaolog.base) {
	item.text = item.text.replace('节点', '组织')
 	if (item.key === 'idField') {
		item.init = 'id'
		rowIdx=userDiaolog.base.indexOf(item)
	} else if (item.key === 'textField') {
		item.init = 'username'
	} else if (item.key === 'itemId') {
		item.text = '用户ID'
	}else if (item.key === 'itemText') {
		item.text = '用户名称'
	} else if (item.key === 'dialogIcon') {
		item.init = 'far fa-user-check'
	} 

}*/
userDiaolog.base.splice(rowIdx,0,
{key: 'initType',text: '初始化方式',type: 'select',init: 'set',	target: '',	bursh: true,bind: true,tip:'组件生成时的初始值方式，运行时可用户编程调用组件.$init()方法加载当前用户信息，并触发完成选择后的事件',list: options.toOptions('设计值=set,当前用户=user') },

)
 for (let item of userDiaolog.base) {
	 item.text = item.text.replace('节点', '组织')
	 if (item.key === 'inputOption') {
		 let init=item.init
		 init.seted=true
		 init.sourceId=-1//主数据库
		 init.tree.width='1000px'
		 init.tree.height='80vh'
	 	init.tree.sql =  options.orgSql
	 	init.treedata.sql= options.userSql
		init.treedata.tableStyle.heads=[
			{field:'id',label:'ID',width:null,col:null},
			{field:'label',label:'姓名',width:null,col:null},
			{field:'empcode',label:'编号',width:null,col:null},
			{field:'mobile',label:'手机',width:null,col:null}
		]
		 	
	 }else if (item.key === 'dialogIcon') {
		item.init = 'far fa-user-check'
	}
 }
/* for (let item of userDiaolog.special) {
	if (item.key === 'treeSql') {
		item.text = '组织SQL'
		item.init = options.orgSql
	} else if (item.key === 'listSql') {
		item.text = '用户SQL'
		item.init = options.userSql
	} else if (item.key === 'queryResult') {

		item.init = JSON.stringify({
			"heads": [{
					"label": "ID",
					"fieldName": "id",
					"with": "100px"
				}, {
					"label": "姓名",
					"fieldName": "username",
					"with": ""
				}, {
					"label": "编号",
					"fieldName": "empcode",
					"with": ""
				}, {
					"label": "手机",
					"fieldName": "mobile",
					"with": ""
				}

			]
		})

	}
} */
export default userDiaolog