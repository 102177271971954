class FlowUtil {
	//表单组件的数据加载完成后的事件中调用此方法,如果指定场景就用场景，没有就用容器的场景属性值
	accessFilter(accessGroupName, element, userId, project, dataMap) { //data参数与表单数据对象，用于将数据赋值给表达式的变量参数
		/* console.log(userId)
		if (!userId) {
			return
		} */
		if (!userId) {
			userId = 0
		}
		userId = '' + userId //转成字符串类型
		//console.log(accessGroupName, element)
		let root = null
		//let container = null
		let node = element
		//console.log(node)
		while (node) {

			/* if (node.group == 'layout') {
				container = node
			} */
			if (node.accessGroups) {
				root = node
				break
			}
			node = node.parent
		}
		//root = container

		if (!root) {
			return
		}
		let groupName = accessGroupName ? accessGroupName : root.accessGroup
		if (!groupName) { //没有场景退出
			return
		}
		let compCfgs = null
		for (let group of node.accessGroups) {
			if (group.key == groupName) {
				compCfgs = group.comps
				break
			}
		}

		if (!compCfgs) { //无权限配置退出
			return
		}
		let stack = [].concat(root.items)
		while (stack.length > 0) {
			let item = stack.pop()
			if (item.type === 'print') { //打印容器不受权限控制
				continue
			}
			let cfg = compCfgs[item.id]
			if (cfg) {

				//	console.log(cfg)
				if (cfg.show.use) {
					if (!cfg.show.obj || cfg.show.obj.length == 0) { //没有设置即为允许
						this.setItemValue(item, 'show', 'show')
					} else { //如果设置了，校验权限
						let users = this.getExpressUsers(cfg.show.obj, project, dataMap)

						if (users.indexOf(userId) > -1) {
							this.setItemValue(item, 'show', 'show')
						} else {
							this.setItemValue(item, 'show', 'none')
						}
					}

				} else {
					this.setItemValue(item, 'show', 'none')
				}
				if ('status' in item) {
					if (cfg.edit.use) {
						if (!cfg.edit.obj || cfg.edit.obj.length == 0) { //没有设置就是正常状态 
							this.setItemValue(item, 'status', '')
						} else { //如果设置了，校验权限
							let users = this.getExpressUsers(cfg.edit.obj, project, dataMap)
							if (users.indexOf(userId) > -1) {
								this.setItemValue(item, 'status', '')
							} else {
								this.setItemValue(item, 'status', 'disabled')

							}
						}

					} else {
						this.setItemValue(item, 'status', 'disabled')
					}

				}
				if ('save' in item) {
					if (cfg.save.use) {
						this.setItemValue(item, 'save', 'Y')
					} else {
						this.setItemValue(item, 'save', 'N')
					}
				}



			} else { //默认可见可编辑可提交
				item.show = 'show'
				if ('save' in item) {
					if ('status' in item) {
						this.setItemValue(item, 'status', '')
					}
					this.setItemValue(item, 'save', 'Y')
				}

			}

			if (item.items) {
				for (let c of item.items) {
					stack.push(c)
				}
			}
		}




	}
	setItemValue(item, key, value) { //采用函数赋值用以触发组件级联赋值
		if (item.$set) {
			item.$set(key, value)
		} else {
			if (key in item) {
				item[key] = value
			}
		}
	}
	checkUserAccess(userId, actorItems, project, data) { //判定用户是还有此权限
		let uid = '' + userId
		let users = this.getExpressUsers(actorItems, project, data)
		return users.indexOf(uid) > -1
	}
	getExpressUsers(actorItems, project, data) {
		let orgTree = project.orgTree
		let roleTree = project.roleTree
		let userList = project.userList
		let orgUsers = project.orgUsers
		let roleUsers = project.roleUsers
		let roleOrgs = project.roleOrgs
		let bls = this.getExpressBlans(actorItems)
		let p = 0
		let dataMap = data || {}
		while (p < bls.length) {
			let item = bls[p]
			if (item.isObj) { //是单元表达式，解析结果

				item.users = this.getPartUsers(item, orgTree, roleTree, userList, orgUsers, roleUsers, roleOrgs,
					dataMap)
				//console.log(item.name,item,item.users)
				p++
			} else { //运算符,计算结果放到前面的单元表达式
				let a = p - 2
				let b = p - 1
				let p1 = bls[a]
				let p2 = bls[b]
				let us = {}

				switch (item.type) {
					case '+':
						us = p1.users
						for (let key in p2.users) {
							us[key] = key
						}
						break
					case '-':
						us = p1.users

						for (let key in p2.users) {
							if (key in us) {
								delete us[key]
							}
						}
						break
					case '&':
						for (let key in p1.users) {
							if (key in p2.users) {
								us[key] = key
							}
						}
						break
				}
				p1.users = us
				bls.splice(b, 2)
				p = b

			}

		}
		//波兰式中必然只剩一个表达式，取出来就是最后的结果
		//console.log(bls)
		let rs = []
		for (let key in bls[0].users) {
			rs.push(key)
		}
		return rs



	}

	getPartUsers(actorItem, orgTree, roleTree, userList, orgUsers, roleUsers, roleOrgs, dataMap) {
		let users = {}
		let topNode = null
		let stack = []
		if (actorItem.fromType == 'A') {
			let value = null
			//变量名称大小写容错处理
			if (actorItem.from in dataMap) {
				value = dataMap[actorItem.from]
			} else { //如果不存在就忽略大小写查找
				for (let key in dataMap) {
					if (key.toLowerCase() == actorItem.from.toLowerCase()) {
						value = dataMap[key]
						break
					}
				}
			}
			if (!value) { //参数没有有效值直接返回空结果
				return users
			} else {
				if (actorItem.type == 'J') { //只有职能类型是变量参数2
					actorItem.param2 = value
				} else {
					actorItem.param1 = vlaue
				}
			}
		}
		switch (actorItem.type) {
			case 'R':
			case 'U':

				topNode = actorItem.type == 'R' ? roleTree.getNode(actorItem.param1) : orgTree.getNode(actorItem
					.param1)
				if (!topNode) {
					return users
				}
				stack.push(topNode)
				while (stack.length > 0) { //
					let node = stack.pop()
					if (actorItem.type == 'R') {
						for (let ru of roleUsers) {
							if (ru.roleId == node.id) {
								users[ru.userId] = ru.userId
							}
						}

					} else if (actorItem.type == 'U') {
						for (let ou of orgUsers) {
							if (ou.orgId == node.id) {
								users[ou.userId] = ou.userId
							}
						}
					}

					if (node.children) {
						for (let item of node.children) {
							stack.push(item)
						}
					}
				}
				break
			case 'J':
				//1获得所有子角色集合（含自身），2获取组织全路径，3找出角色集合中与路径任何节点都有关系的配置数据
				//获取配置数据中的所有角色集合，取角色集合中及各子角色下的所有用户

				let roleNode = roleTree.getNode(actorItem.param1)
				if (!roleNode) {
					return users
				}
				let orgNode = orgTree.getNode(actorItem.param2)
				let orgPath = {}
				if (!orgNode) {
					return users
				} else {
					while (orgNode) {
						orgPath[orgNode.id] = orgNode.id
						orgNode = orgNode.$parent
					}
				}
				let roles = {}
				stack = [roleNode]
				while (stack.length > 0) {
					let role = stack.pop()
					for (let ro of roleOrgs) {
						if (ro.roleId == role.id && (ro.orgId in orgPath)) {
							roles[ro.roleId] = role //获取在角色组织配置数据中存在的角色
						}
					}
					if (role.children) {
						for (let item of role.children) {
							stack.push(item)
						}
					}
				}

				//获取角色集合中的子角色
				stack = []
				for (let r in roles) {
					stack.push(roles[r])
				}
				roles = {}
				while (stack.length > 0) {
					let role = stack.pop()
					roles[role.id] = role.id
					if (role.children) {
						for (let item of role.children) {
							stack.push(item)
						}
					}
				}

				//取角色用户配置中的用户结果
				for (let ru of roleUsers) {
					if (ru.roleId in roles) {
						users[ru.userId] = ru.userId
					}
				}
				break

			case 'L': //按级别
				for (let user of userList) {

					switch (actorItem.param2) {
						case 0: //相等
							if (user.tag == actorItem.param1) {
								users[user.id] = user.id
							}
							break
						case 1: //大于
							if (user.tag > actorItem.param1) {
								users[user.id] = user.id
							}
							break
						case 2: //大于等于
							if (user.tag >= actorItem.param1) {
								users[user.id] = user.id
							}
							break
						case 3: //小于
							if (user.tag < actorItem.param1) {
								users[user.id] = user.id
							}
							break
						case 4: //小于等于
							if (user.tag <= actorItem.param1) {
								users[user.id] = user.id
							}
							break
					}
				}

				break
			case 'P': //指定用户
				users[actorItem.param1] = actorItem.param1
				break
			case 'S': //领导,获取所有领导的路径
				let first = null
				for (let user of userList) {
					if (user.id == actorItem.param1) {
						first = user
					}
				}
				if (!first) {
					return users
				}
				let p = first
				for (let i = 0; i < 30; i++) { //防止死循环，最大30层
					let leader = null
					for (let user of userList) {
						if (user.id == p.tag) {
							leader = user
						}
					}
					if (leader) {
						users[leader.id] = leader.id
						p = leader
					} else { //只要某层断掉了上级关系就不再处理
						break
					}
				}

				break
			case 'A': //param1申请人,param2制单人
				users[actorItem.param1] = actorItem.param1
				users[actorItem.param2] = actorItem.param2
				break


		}
		return users
	}

	checkExpress(actorItems) {
		if (!actorItems || actorItems.length == 0) {
			return 0
		}
		//先检查表达式运算符两边都是操作数
		for (let i = 0; i < actorItems.length; i++) {
			let item = actorItems[i]
			if (!item.isObj && item.type != '(' && item.type != ')') {
				if (i < 1 || i > actorItems.length - 2) {
					return 1
				} else {
					let p1 = actorItems[i - 1]
					let p2 = actorItems[i + 1]
					if (!p1.isObj && p1.type != ')' || !p2.isObj && p2.type != '(') {
						return 1
					}
				}
			}

		}
		// 转换后检查
		let bls = this.getExpressBlans(actorItems)
		let p = 0
		while (p < bls.length) {
			let item = bls[p]
			if (!item.isObj) { // 如果是运算符,找前面的两个操作数，如果操作数不足，报错
				let a = p - 2
				let b = p - 1

				if (a < 0) {
					return 1
				}
				let d1 = bls[a]
				let d2 = bls[b]
				if (!d1.isObj || !d2.isObj) { //前面两个如果不全是操作数，报错

					return 2
				}
				bls.splice(b, 2) //移除第一个操作数之后的两个元素，指针指向数数组的第一个操作数的后而元素
				p = b

			} else { //非操作符，继续往后
				p++
			}


		}
		if (bls.length != 1) { //最后的结果一定是一个操作数
			return 3
		}
		return 0
	}
	getExpressBlans(actorItems) {
		let opMap = {
			'(': 0,
			')': 0, //遇到这个，(前面所有的操作符都出栈
			'+': 1,
			'-': 1,
			'&': 2
		}

		//除以上之外的都是操作数
		//算法：如果当前项是操作符，将栈中优先级大于、等于当前操作符的都弹出来，直到遇到有小于自身的为止，然后当前操作符入栈,如果当前是)，遇到(为止
		let stack = []
		let result = [];
		for (let item of actorItems) {
			let op = item.type;
			if ('(' === op) { // 如果是左括号直接入栈
				stack.push(item);
			} else if (!item.isObj) { //是操作符
				let priority = opMap[op];

				while (stack.length > 0 && opMap[stack[stack.length - 1].type] >= priority) {
					let p = stack.pop();
					if ('(' === p.type) {
						break;
					} else {
						result.push(p);
					}

				}
				if (')' != item.type) {
					stack.push(item);
				}


			} else { //操作数直接添加
				result.push(item);
			}
		}
		while (stack.length > 0) {
			result.push(stack.pop());
		}
		return result;



	}



}

export default new FlowUtil()