import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
 

let types=[{label:'暗色',value:'dark'},{label:'亮色',value:'light'},{label:'朴素',value:'plain'}]
let datas =JSON.stringify( [ 
	{key:'tag1',text:'标记1', icon:'far fa-plus',type: '' },
	{key:'tag2',text:'标记2', icon: 'far fa-edit',type:  'success' },
	{key:'tag3',text:'标记3', icon: 'far fa-eraser',type:  'warning'  },
	{key:'tag4',text:'标记4', icon: 'far fa-file-search',type:  'danger' },
	{key:'tag5',text:'标记5', icon: 'far fa-upload',type:  'info',   },
	
	 
	 
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'key',label:'标记标识',type:'text',default:'',list:[],field:'',width:'20%',unique:true},
	{key:'text',label:'标记文本',type:'text',default:'',list:[],field:'',width:'20%',unique:false},
	{key:'icon',label:'标记图标',type:'icon',default:'',list:[],field:'',width:'20%',unique:false},
	{key:'type',label:'标记样式',type:'select',default:'',list:compBase.colorTypes,field:'',width:'',unique:false},
	
 
]
//let colors=[{label:'默认',value:''},{label:'成功（清纯绿）',value:'success'},{label:'警告（沉稳黄）',value:'warning'},{label:'危险（辣目红）',value:'danger'},{label:'信息（低调灰）',value:'info'}]
let colors=[].concat( compBase.colorTypes2)
colors.splice(0,1,{label:'默认',value:''})


let base = [
	{key: 'title',text: '标记'	},
	compBase.mode,
	{key: 'value',text: '文本',type: 'text',init: '标记',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'ico',text: '图标',type: 'icona',init: '',	target: '',	bursh: true,bind: true,tip: '',list: null},

{key: 'color',text: '样式类型',type: 'select',init: 'success',target: '',bursh: true,bind: true,tip: '',list: colors},
    {key: 'size',text: '大小',type: 'select',init: 'large',target: '',bursh: true,bind: true,tip: '',list: compBase.sizes},
	{key: 'effect',text: '主题',type: 'select',init: 'light',	target: '',bursh: true,bind: true,tip: '',list: types},
	{key: 'closeAble',text: '允许关闭',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '标记组时生效',list: options.YON},
    {key: 'addAble',text: '允许添加',type: 'select',init: 'N',	target: '',	bursh: true,bind: true,tip: '标记组时生效',list: options.YON},
    {key: 'toggle',text: '轮换状态',type: 'switch',init: 'N',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
      
]
 

let tag = {
	data: {
		type: 'tag',
	},
	delete: '',
	hide: 'dataOption',
	base: base.concat(font).concat(compBase.base),
	special:extend.badge.concat(extend.menu).concat(extend.tip).concat( [
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '标记组数据',type: 'jsonArray',init: datas,	target: '',	bursh:true,bind:true,tip: '',attribute:'dataOption'},//treeMap:{id:'key',label:'text',icon:''},dataMap: {key:'唯一标识',text:'按钮文本',type:'外观样式'}
		]),
	action:  options.getCommonEvents().concat([compBase.closeBefore,compBase.closeAfter,compBase.addBefore,compBase.addAfter,compBase.menuClick]).concat([
		options.listLoadAfterEvents.dataListEvent,
		options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}
 

export default tag
