<template>
	<div class="fieldcontent" :style="style">
		<div class="left" :title="textPath">
			<input type="text" style="padding-left:11px;" :value="textPath" :placeholder="placeholder" @focus="onFocus"
				@blur="onBlur" readonly class="input-text" />
			<i v-show="! disabled && isFocus" @click="clearResult" class="fal fa-times-circle button-icon"
				style="margin-left: -32px;" title="清除当前已选择的数据"></i>
		</div>
		<div class="righ" @click.stop="toSelectTree">
			<i v-if="disabled" :class="icon "></i>
			<i v-else :class="icon + ' button-icon'"></i>
		</div>
		<logicdialog @ok="treeDialogOk" @init="treeDialogInit" :title="title" :icon="icon">
			<div class="content">
				<div style="height: calc(100% - 40px) ;overflow: auto;">
					<treeview :nodes="nodes" :allow-search="true" :allow-edit="false" :allow-check="false"
						:allow-drag="false" :expand-all="false" :expand-click="false" :expanded="expandedNodes"
						:accordion="false" bar-height="34px" :split-char="splitChar" @nodeclick="nodeClick"
						@init="treeInit">
					</treeview>
				</div>

				<titlebar :title="nodeObj.textPath" icon="far fa-hand-point-right">

				</titlebar>
				<!-- <div class="treeleft">
					

				</div>
				<div class="treeright">
					
				</div> -->
			</div>

		</logicdialog>
	</div>

</template>

<script>
	import Treeview from '../treeview/TreeView.vue'
	export default {
		props: {
			size: {
				type: String,
				default: 'default'
			},
			width: { //对话框宽
				type: String,
				default: '60%'
			},
			height: { //对话框高度
				type: String,
				default: '80vh'
			},
			title: { //对话框标题
				type: String,
				default: '树选择'
			},
			icon: { //对话框图标
				type: String,
				default: 'far fa-folder-open'
			},
			placeholder: {
				type: String,
				default: '请选择'
			},
			textPath: { //路径文本内容
				type: String,
				default: ''
			},
			splitChar: {
				type: String,
				default: '/'
			},
			reset: { //打开对话框前是否清除当前已选的结果
				type: Boolean,
				default: true
			},
			nodeList: { //传入树节点数据
				type: Array,
				default: []
			},
			expandedNodes: {
				type: Array,
				default: []
			},
			disabled: {
				type: Boolean,
				default: false
			},
			beforeOK: { //点击确定按钮时的前置函数，如设置或返回false阻止选择
				type: Function,
				default: null
			}
		},
		emits: ['select', 'focus', 'blur', 'choose', 'open'], //select已确定选择结果时，choose点击树节点时
		data() {
			return {
				treeDialog: null,
				treeObj: {

				},
				nodeObj: {
					textPath: ''
				},
				result: {
					textPath: '',
				},
				isFocus: false


			}
		},

		methods: {
			clearResult() {
				let result = {
					id: null,
					pId: null,
					label: '',
					textPath: '',
					idPath: '',
					node: null,
					parent: null,
					level: [],
				}
				this.result = result
				this.$emit('select', result)

			},
			onFocus() {
				this.isFocus = true
				this.$emit('focus')
			},
			onBlur() {
				setTimeout(() => { //下一个时间生效，否则清除的按钮被隐藏发生不了点击事件
					this.isFocus = false
				}, 1000)

				this.$emit('blur')
			},
			nodeClick(nodeObj) {
				this.nodeObj = nodeObj
				this.$emit('choose', nodeObj)
			},
			treeInit(treeObj) {
				this.treeObj = treeObj
			},
			toSelectTree() {
				if (this.disabled) {
					return
				}
				if (this.reset) {
					this.nodeObj = {
						textPath: ''
					}
				}
				this.$emit('open')
				this.treeDialog.visible = true
			},
			treeDialogOk(dialog) {
				if (this.nodeObj.data) {
					let ls = []
					for (let node of this.nodeObj.nodes) {
						ls.push(node.data)
					}
					let parent = ls.length > 1 ? ls[ls.length - 2] : null
					let result = {
						id: this.nodeObj.data.id,
						label: this.nodeObj.data.label,
						pid: parent ? parent.id : null,
						textPath: this.nodeObj.textPath,
						idPath: this.nodeObj.idPath,
						data: this.nodeObj.data,
						parent: parent,
						level: ls,
					}
					if (this.beforeOK) {
						let ck = this.beforeOK(result)
						if (ck === false) { //明确返回false才阻止选择
							return
						}
					}
					this.result = result
					this.$emit('select', result)
					dialog.visible = false
				} else {
					this.$logic.tip.error('您未选择任何一个节点')
				}

			},
			treeDialogInit(dialog) {
				this.treeDialog = dialog
				dialog.width = this.width
				dialog.height = this.height
				//dialog.fullHeight = true
			}
		},
		computed: {
			nodes() {
				let rs = []
				rs = this.$logic.arrayUtil.parseTree(this.nodeList)

				/* 			let nodeMap = {}
							let list = this.nodeList
							for (let data of list) {
								nodeMap[data.id] = {
									id: data.id,
									parent: null,
									label: data.label,
									icon: data.icon,
									color: data.color,
									children: [],
									data: data.data
								}
							}
							for (let item of list) {
								let key = item.id
								let pkey = 'pId' in item ? item.pId : item.pid
								let node = nodeMap[key]

								let pnode = nodeMap[pkey]
								if (pnode) {
									node.parent = pnode
									pnode.children.push(node)
								} else {
									rs.push(node)
								}
							} */
				//console.log(rs)
				return rs

			},
			style() {
				let css = {
					height: this.size == 'large' ? '40px' : this.size == 'small' ? '22px' : '32px'
				}
				css['--buton-icon-size'] = this.size == 'large' ? '18px' : this.size == 'small' ? '14px' : '16px'
				return css
			}
		},
		created() {


		},
		components: {
			treeview: Treeview
		},
	}
</script>

<style scoped>
	.fieldcontent {
		display: flex;
		align-items: center;
		width: 100%;
		height: 35px;
		min-width: 100px;

	}

	.left {
		width: calc(100% - 43px);
		height: calc(100% - 2px);
		padding-left: 3px;
	}

	.righ {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: calc(100% - 3px);
		border: solid 1px #cccccc;
		border-left: 0px;
		border-radius: 0px 4px 4px 0px;
		background-color: #f0f0f0;
		color: #303133;
	}

	.input-text {
		width: calc(100% - 6px);
		height: calc(100% - 1px);
		border-radius: 4px 0px 0px 4px;
		padding: 0px 2px;
		text-align: left;
		direction: rtl;
	}

	.button-icon {
		color: ##606266;
		cursor: pointer;
		font-size: var(--buton-icon-size);
	}

	.button-icon:hover {
		color: #409eff;
	}

	.treeleft {
		width: 300px;
		border: solid 1px #cccccc;
	}

	.treeright {
		width: calc(100% - 300px);
		border: solid 1px #cccccc;
		border-left: 0px;
	}
</style>