import util from '../util.js'
import options from '../../../../public/options.js'

let list=[
	{key:'title',text:'图例' },
	{key:'legendShow',text:'显示图例',type:'switch',init:'Y',path:'legend.show',bursh:true,bind:true,tip:'',list:options.YON},
// 	{key:'legendWidth',text:'图例宽度',type:'number',init:null,path:'legend.width',bursh:true,bind:true,tip:'',list:null},
//	{key:'legendHeight',text:'图例高度',type:'number',init:null,path:'legend.height',bursh:true,bind:true,tip:'',list:null},
	{key:'legendOrient',text:'布局方向',type:'select',init:'horizontal',path:'legend.orient',bursh:true,bind:true,tip:'',list:options.toOptions('水平方向=horizontal,垂直方向=vertical')},
//align
	{key:'legendAlign',text:'水平对齐',type:'select',init:'right',path:'legend.left',bursh:true,bind:true,tip:'',list:options.toOptions('左对齐=left,居中=center,右对齐=right')},
	{key:'legendVerticalAlign',text:'垂直对齐',type:'select',init:'top',path:'legend.top',bursh:true,bind:true,tip:'',list:options.toOptions('顶部对齐=top,中间对齐=middle,底部对齐=bottom')},

//如果没有图例的样式设置，点击图例就会报错
	 {key:'legendTextStyleFontSize',text:'标签字号',type:'number',init:'12',path:'legend.textStyle.fontSize',bursh:true,bind:true,tip:'',list:null},
	 {key:'legendTextStyleFontFamily',text:'标签字体',type:'select',init:'',path:'legend.textStyle.fontFamily',bursh:true,bind:true,tip:'',list:options.fonts},
	 {key:'legendTextStyleColor',text:'标签颜色',type:'color',init:options.initColor.text.dark,path:'legend.textStyle.color',bursh:true,bind:true,tip:'',list:null},

/*	{key:'legendLeft',text:'左侧偏移',type:'text',init:'center',path:'legend.left',bursh:true,bind:true,tip:'绝对定位方式左侧定位',list:null},
	{key:'legendRight',text:'右侧偏移',type:'number',init:null,path:'legend.right',bursh:true,bind:true,tip:'绝对定位方式右侧定位',list:null},
	{key:'legendTop',text:'顶部偏移',type:'number',init:null,path:'legend.top',bursh:true,bind:true,tip:'绝对定位方式顶定位',list:null},
	{key:'legendBottom',text:'底部偏移',type:'number',init:null,path:'legend.bottom',bursh:true,bind:true,tip:'绝对定位方式底部定位',list:null},
	//这两项会导致图例显示异常
	{key:'legendPadding',text:'内边距',type:'number',init:'5',path:'legend.padding',bursh:true,bind:true,tip:'',list:null},
	{key:'legendItemGap',text:'各项间距',type:'number',init:'10',path:'legend.itemGap',bursh:true,bind:true,tip:'各项之间的相邻距离',list:null},

	{key:'legendItemWidth',text:'各项宽度',type:'number',init:null,path:'legend.itemWidth',bursh:true,bind:true,tip:'默认值25',list:null},
	{key:'legendItemHeight',text:'各项高度',type:'number',init:null,path:'legend.itemHeight',bursh:true,bind:true,tip:'默认值14',list:null},
 */
 
]

export default {list}
