<template>

	<canvas ref="canvas" class="checkcode"  @click="draw" title="点击更新"></canvas>

</template>

<script>
	export default {
		props: {
			many: {
				type: Number,
				default: 4
			},
			fontSize: {
				type: Number,
				default: 24
			},
			mode: {
				type: String,
				default: ''
			},
			caseMix:{
				typeof:Boolean,
				default:false
			},
			panelWidth: {
				type: String,
				default: ''
			},
			panelHeight: {
				type: String,
				default: ''
			}
		}, //'many','font','mode',//出现多少个，纯字母，纯数字，纯大写，大小写
		emits: ['init', 'update'],
		data() {
			return {
				width: 1,
				height: 1,

			}
		},

		methods: {
			draw(newCode = null) {
				let canvas = this.$refs.canvas; //获取到canvas的对象，演员	
				let canvas_width = this.width
				let canvas_height = this.height

				let context = canvas.getContext("2d"); //获取到canvas画图的环境，演员表演的舞台
				canvas.width = canvas_width;
				canvas.height = canvas_height;

				let allCode =
					"a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
				let upCode='A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0'
				let aCode =null
				if(this.caseMix){
					 aCode = allCode.split(",");
				}else{
					 aCode = upCode.split(",");
				} 
				let aLength = aCode.length; //获取到数组的长度
				let rs = ""
				for (let i = 0; i < this.many; i++) { //这里的for循环可以控制验证码位数（如果想显示6位数，4改成6即可）
					let txt = null
					if (newCode && i < newCode.length) { //指定了内容并且在总长度以内
						txt = newCode.substring(i, i + 1)
					} else {
						let j = Math.floor(Math.random() * aLength); //获取到随机的索引值
						
						txt = aCode[j]//.toUpperCase(); //得到随机的一个内容
					}
					// let deg = Math.random() * 30 * Math.PI / 180;//产生0~30之间的随机弧度
					let deg = Math.random() - 0.5; //产生一个随机弧度
					//show_num[i] = txt.toLowerCase();
					rs = rs + txt
					let x = 5 + i * this.fontSize; //文字在canvas上的x坐标
					let y = this.fontSize -8 + Math.random() * 8; //文字在canvas上的y坐标
					
					context.font = 'bold ' + this.fontSize + 'px 微软雅黑';
					context.translate(x, y);
					context.rotate(deg);
					context.fillStyle = this.randomColor();
					context.fillText(txt, 0, 0);
					context.rotate(-deg);
					context.translate(-x, -y);
				}
				for (let i = 0; i <= 3; i++) { //验证码上显示线条5
					context.strokeStyle = this.randomColor();
					context.beginPath();
					context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
					context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
					context.stroke();
				}
				for (let i = 0; i <= 20; i++) { //验证码上显示小点30
					context.strokeStyle = this.randomColor();
					context.beginPath();
					let x = Math.random() * canvas_width;
					let y = Math.random() * canvas_height;
					context.moveTo(x, y);
					context.lineTo(x + 1, y + 1);
					context.stroke();
				}
				this.$emit("update", rs)
			},
			//得到随机的颜色值
			randomColor() {
				let r = Math.floor(Math.random() * 256);
				let g = Math.floor(Math.random() * 256);
				let b = Math.floor(Math.random() * 256);
				return "rgb(" + r + "," + g + "," + b + ")";
			},
			initPanel() {
				let canvas = this.$refs.canvas;
				let myCanvas_rect = canvas.getBoundingClientRect();
				this.width = Math.trunc(myCanvas_rect.width);
				this.height = Math.trunc(myCanvas_rect.height);
				this.charts = this.many || 4
				this.$emit("init", this.draw)
				setTimeout(() => {
					this.draw()
				}, 100)
			}
		},
		watch: {
			panelWidth(newValue, oldValue) {
				this.initPanel()
			},
			panelHeight(newValue, oldValue) {
				this.initPanel()
			},
			many(nv, ov) {
				this.initPanel()
			}

		},
		mounted() {
			this.initPanel()

		},
		created() {
			this.$emit('init',{draw:this.draw})


		}
	}
</script>

<style scoped>
	.checkcode {
		height: 100%;
		width: 100%;
		cursor: pointer;		
	}
	.checkcode:hover{
		border: 1px solid #28C76F;
		background-color: #81FBB8;
		/* background-image: linear-gradient(135deg, #81FBB8 0%, #28C76F,#ABDCFF ); */
	}
</style>
