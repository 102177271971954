export default [{
						name: "cobalt",
						label: "浅蓝中亮"
					},
					{
						name: "colorforth",
						label: "黑底蓝序号高亮"
					},
					{
						name: "darcula",
						label: "灰黑亚光"
					},
					{
						name: "eclipse",
						label: "白底灰序号中亮"
					},
					{
						name: "idea",
						label: "白底灰序号中亮"
					},
					{
						name: "liquibyte",
						label: "黑底灰序号高亮"
					},
					{
						name: "mbo",
						label: "黑灰底灰序号亚光"
					},
					{
						name: "midnight",
						label: "蓝底中亮"
					},
					{
						name: "night",
						label: "深蓝高亮"
					},
					{
						name: "rubyblue",
						label: "深蓝浅蓝序号高亮"
					},
					{
						name: "shadowfox",
						label: "深灰黑序号中亮"
					},
					{
						name: "solarized",
						label: "白底灰序号中亮"
					},
					{
						name: "ssms",
						label: "白底黄序号分隔线中亮"
					},
					{
						name: "twilight",
						label: "黑底灰序号亚光"
					},
					{
						name: "xq-dark",
						label: "深蓝中亮"
					},
					{
						name: "yeti",
						label: "灰色亚光"
					},
					{
						name: "zenburm",
						label: "灰黑亚光"
					},
				]