
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
let datas =JSON.stringify( [
    {label:'选项一',value:'A',disabled:'N'},
	{label:'选项二',value:'B',disabled:'N'},
	{label:'选项三',value:'C',disabled:'Y'},
	{label:'选项四',value:'D',disabled:'N'}, 
	 
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'value',label:'选项值',type:'text',default:'',list:[],field:'',width:'30%',unique:true},
	{key:'label',label:'选项文本',type:'text',default:'',list:[],field:'',width:'30%',unique:false},
	{key:'disabled',label:'禁用状态',type:'switch',default:'N',list:[],field:'',width:'',unique:false},
 
]
let styles=compBase.toOptions('方框=A,按钮=B')
let dataConfig=compBase.getDataConfig()
let validate=compBase.getValidate('array') 
validate.validateSet.exclude='input,blur'
let base = [
	{key: 'title',text: '下拉列表'	},
	{key: 'values',text: '选项值串',type: 'text',init: '',	target: '',	bursh: true,bind: true,read:true,tip: '以逗号分隔的多值字符串',list: null},

	{key: 'value',text: '选项值',type: 'json',init: [],	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
	{key: 'fieldName',text: '数据表字段',type: 'field',init: dataConfig,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
 	{key: 'validateSet',text: '校验时机',type: 'triger',init: validate.validateSet,	target: '',	bursh: true,bind: false,tip: '',list:null},
	{key: 'validateRule',text: '校验规则',type: 'validate',init: validate.validateRule,	target: '',	bursh: true,bind: false,tip: '',attribute:'value'},
	{key: 'ignoreHide',text: '隐藏时忽略',type: 'switch',init: 'N',target: '',bursh: true,bind: true,tip: '元素为不可见状态时不进行数据校验',list: null},

    {key: 'multiple',text: '是否多选',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},	
    {key: 'max',text: '最多选择数',type: 'number',init: '0',	target: '',	bursh: true,bind: true,tip: '值为0不限制数量',list: null},
	{key: 'size',text: '大小样式',type: 'select',init: 'default',target: '',	bursh: true,bind: true,tip: '',list: compBase.sizes},
    {key: 'tip',text: '空值提示',type: 'text',init: '请选择',	target: '',	bursh: true,bind: true,tip: '',list: null},
	{key: 'clearable',text: '清空按钮',type: 'select',init: 'Y',	target: '',	bursh: true,bind: true,tip: '',list: options.YON},
	{key: 'filtable',text: '启用过滤',type: 'select',init: 'N',	target: '',	bursh: true,bind: false,tip: '',list: options.YON},

{key:'width',text:'宽度',type:'text',init:'100%',target:'width',	bursh: true,bind: true,tip:'象素、百分比、函数类型',list:null},
   
   compBase.status2


]

let selectM = {
	data: {
		type: 'selectM',isInput:'L'
	},
	delete: '',
	hide: 'multiple,width,dataOption',
	base: base.concat(compBase.base),
	special:extend.tip.concat( [
		{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
		{key: 'listData',text: '选项数据',type: 'jsonArray',init: datas,	target: '',	bursh: true,bind: true,tip: '',list: null,attribute:'dataOption'},]),
	action:  options.getEvents(null,'$event_click,$event_change').concat([
		options.listLoadAfterEvents.dataListEvent,
		//options.listLoadAfterEvents.menuListEvent
	]).concat(options.getEvents(null,'$hook'))
}

export default selectM
 