import font from '../../public/font.js'
import layout from '../../public/layout.js'
import options from '../../public/options.js'
let cs=[
{key: 'title',text: '上下容器'	},
{key:'initHeight',text:'顶端默认高',type:'text',init:'40px',target:'',bursh:true,bind:true,tip:'象素、百分比',list:null},
{key:'topHeight',text:'顶端当前高',type:'text',init:'40px',target:'--lr-top-height',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'topMinHeight',text:'顶端最小高',type:'text',init:'20px',target:'',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'topMaxHeight',text:'顶端最大高',type:'text',init:'80px',target:'',bursh:true,bind:true,tip:'象素、百分比',list:null},	
{key:'showButton',text:'显示按钮',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list:options.YON},	
{key:'minH',text:'最小高度',type:'text',init:'100px',target:'--lr-min-height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'splitColor',text:'分隔线颜色',type:'color',init:'#CCCCCC',target:'--tr-split-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'splitWidth',text:'分隔线粗细',type:'text',init:'1px',target:'--tr-split-width',bursh:true,bind:true,tip:'选择颜色',list:null},	 

{key:'backColor',text:'背景色',type:'colorA',init:null,target:'backgroundColor',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},	 
{key:'backImg',text:'背景图片',type:'file',init:'',target:null,bursh:true,bind:true,tip:'背景图片覆盖背景颜色',list:null},	
{key:'backMode',text:'背景模式',type:'select',init:'cover',target:null,bursh:true,bind:true,tip:'默认平铺填满容器',list:options.ImgModes},	 
{key:'borderS',text:'边框线型',type:'select',init:'solid',target:'borderStyle',bursh:true,bind:true,tip:'边框线粗，象素',list:options.BorderType},	
{key:'borderL',text:'左边框',type:'text',init:'1px',target:'borderLeftWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderT',text:'上边框',type:'text',init:'1px',target:'borderTopWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderR',text:'右边框',type:'text',init:'1px',target:'borderRightWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderB',text:'下边框',type:'text',init:'1px',target:'borderBottomWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderColor',text:'边框颜色',type:'color',init:'#CCCCCC',target:'borderColor',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'borderRadius',text:'圆角大小',type:'text',init:'',target:'borderRadius',bursh:true,bind:true,tip:'象素或百分比',list:null},	 


] 
let topBottom= {
	data:{
		type:'topbottom'
	},
	delete:'',
	hide:'',//width,minW',
	base:cs,//.concat(layout),//.concat(font) ,
	special :[],
	action:[]
}

export default topBottom